// Modal dialogue that shows tracking raw data as modal

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Snackbar from '@material-ui/core/Snackbar';

import firebase from '../../helpers/firebaseApp';


// Import helper functions, constant etc.
import { getTrackingData } from '../../helpers/firebaseHelper';
import { marketPlaces } from '../../helpers/constants';
import { getMediumDate } from '../../helpers/utility';

class RawDataModal extends React.Component {

  state = {
    trackingData: [],
    isProcessing: false,
    dataFetched: false,

    currentProcessingIndex: -1,
  }

  componentDidMount = () => {
    //console.log('RawDataModal - componentDidMount()');

    // Set processing on when component load
    this.setState({
      isProcessing: true,
    });

    // Load tracking data
    this.loadTrackingData();
  }

  componentWillUnmount = () => {
    //console.log('RawDataModal - componentWillUnmount()');
  }


  // Fetch data for given market + asin
  loadTrackingData = () => {
    //console.log('RawDataModal - loadTrackingData()');

    const { asin, market } = this.props;
    getTrackingData(asin, market, this.getTrackingData_Success, this.getTrackingData_Error);
  }

  getTrackingData_Success = (data) => {
    //console.log('RawDataModal - getTrackingData_Success()');
    //console.log('RawDataModal - getTrackingData_Success() data:', data);

    this.setState({
      trackingData: data,
      isProcessing: false,
      isRefreshing: false,
      dataFetched: true,
      currentProcessingIndex: -1,
    });
  }

  getTrackingData_Error = (err) => {
    //console.log('RawDataModal - getTrackingData_Error() err:', err);
    
    this.setState({
      isProcessing: false,
      isRefreshing: false,
      currentProcessingIndex: -1,
    });
  }

  // Refresh the data
  handleRefresh = () => {
    
    this.setState({
      isRefreshing: true,
    });

    // Load tracking data
    this.loadTrackingData();
  }

  // Inform parent to close the dialogue
  handleClose = () => {
    this.props.onClose();
  };

  // Called when sanckbar message closed after time out
  handleMessageClose = () => {
    //console.log('handleMessageClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }  


  // Process clicked row data.
  // We have some old data that is not processed. So we will show process button with
  // that record, so admin can process it manually by clicking it.
  onClickProcessRawData = (docId, index) => {
    console.log('onClickProcessRawData() docId:', docId, ' index:', index);

    // 1 - Check that next index (record) exist after clicked index.
    // If next record exist then check it is processed or not, If not processed 
    // then show message to process it first and then process this (go from bottom to top).
    const { trackingData } = this.state;
    const lastIndex = trackingData.length - 1;
    console.log('lastIndex:', lastIndex);
    if ( index !== lastIndex) { // clicked recrod is not last record then valiate it.
      // We will check that older record processed or not, if not processed
      // then we will show message and return. Note: here we are creating the 
      // nextIndex because record is sorted by Date Desc (latest date on top, old 
      // date at bottom, so older record exist at next index.)
      const nextIndex = index + 1; 
      const nextData = trackingData[nextIndex];
      if ( nextData.is_valid === undefined) { // is_valid field not exist means record not processed
        // Show message to process old record first.
        this.setState({
          showMessage: true,
          messageText: 'Please process older data first, Go from bottom to top.',
        });
        return; // IMPORTANT
      }
    }
    console.log('Processing data.');

    // 2 - If some index processing in progress then return
    // We need to wait until the processing done.
    if (this.state.currentProcessingIndex !== -1) {
      console.log('Processing in progress, so return');
      return;
    }

    // 3 - Set current processing index, so it will disable button in ui.
    this.setState({
      currentProcessingIndex: index,
    });

    // 4 - Fetch current asin and market from props
    const { asin, market } = this.props;
    //console.log('onClickProcessRawData() asin:', asin, ' market:', market.toLowerCase(), ' docId:', docId, ' index:', index);

    // 5 - Prepare data to pass into cloud function
    const data = {
      asin: asin, 
      market: market.toLowerCase(),   // maket must in lowercase
      docId: docId
    }
    console.log('data:', data);

    // 6 - Create cloud function reference
    var processQtySoldManually = firebase.functions().httpsCallable('processQtySoldManually');

    // 7 - Call the function
    processQtySoldManually(data)
    .then( (result) => { // Read the result received from cloud function
      console.log('result.data:', result.data);

      // If success status then refresh list 
      if (result.data.status === 'success') {
        this.handleRefresh();
      } else { // show message
        this.setState({
          showMessage: true,
          messageText: 'Error while processing data, please try again.',
          currentProcessingIndex: -1,
          isProcessing: false,
        });
      }

    }).catch( (error) => { // Error while calling the cloud function
      console.log("Error while processing error:", error);

      this.setState({
        showMessage: true,
        messageText: 'Error while processing. Please try again.',
        currentProcessingIndex: -1,
        isProcessing: false,
      });

    });

  }



  renderTrackingData = (trackingData) => {
    const { currentProcessingIndex } = this.state;
    const { classes } = this.props;
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="sales history">
          <TableHead>
            <TableRow>
              <TableCell align="left">DocId</TableCell>
              <TableCell align="left">Scrap Date</TableCell>
              <TableCell align="left">Process Status</TableCell>
              <TableCell align="right">Best Seller Rank</TableCell>
              <TableCell align="right">Available Qty</TableCell>
              <TableCell align="right">Sold Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { trackingData.map( (row, index) => (
                <TableRow key={row.id} >
                  <TableCell align="left">
                    { row.id }
                  </TableCell>
                  <TableCell align="left">
                    { getMediumDate(row.process_date) } &nbsp;
                  </TableCell>
                  <TableCell align="left">
                    { // If is_valid field exist within data then show that field value.
                      row.is_valid !== undefined && 'is_valid: ' + row.is_valid.toString() 
                    }
                    { // If is_valid field not exist means tracking raw data not processed yet.
                      // We will show button so admin can process it by clicking it.
                      row.is_valid === undefined && 
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() => this.onClickProcessRawData(row.id, index) }
                        disabled={currentProcessingIndex === index}
                      >
                        Process Now 
                      </Button>
                    }
                  </TableCell>
                  <TableCell align="right">{ row.bsr }</TableCell>
                  <TableCell align="right">{ row.qty }</TableCell>
                  <TableCell align="right">{ row.qty_sold }</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  render() {
    //const { classes } = this.props;
    const { show, imageUrl } = this.props;
    const { isProcessing, isRefreshing, dataFetched, trackingData } = this.state;

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'xl'}
          open={show}
          onClose={this.handleClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true} 
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title" >
            <img src={imageUrl} width="50" alt="..." /> Tracking Data (Raw) - Market: { marketPlaces[this.props.market].country_name }  ASIN: {this.props.asin}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              { isProcessing && 
                <React.Fragment>Loading...</React.Fragment>
              }
              { isRefreshing && 
                <React.Fragment>Refreshing...</React.Fragment>
              }
              { dataFetched && trackingData.length === 0 && !isRefreshing &&
                <React.Fragment>
                  Tracking data (raw) not available, please try again...
                </React.Fragment>
              }
            </DialogContentText>
            { trackingData.length > 0 &&
              this.renderTrackingData(trackingData) 
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRefresh} color="primary">
              Refresh
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={3000}
          onClose={this.handleMessageClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </React.Fragment>
    );
  }
}


const styles = (theme) => ({

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },

});


RawDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  asin: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
};

export default withStyles(styles)(RawDataModal);
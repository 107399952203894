import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
//import Button from '@material-ui/core/Button';
//import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import EditProductIcon from '@material-ui/icons/List';
import EditKeywordIcon from '@material-ui/icons/Keyboard';
import EditCampaignIcon from '@material-ui/icons/Edit';


// Import custom ui component
// ...

// Import constant and other custom lib
import { getAmazonUserProfile } from '../../helpers/amazonAdHelper';
import { getMediumDate } from '../../helpers/utility';

// Import firebase 
import firebase from '../../helpers/firebaseApp';
const db = firebase.firestore();


class SpAdAutomationList extends React.Component { 

  // Default state value
  state = { 
    // We will fetch current logged in amazon user_id and email and set it here.
    amazonUserId: '',
    amazonEmail: '',

    // Flow list for ad automation flow
    // We will fetch flow list from db and set it list here
    flowList: null,

    // true - processing in progress.
    isProcessing: true,

    // If any error while fetching data we will set value here.
    // So it will show this message within snackbar component.
    messageText: '',
    showMessage: false,
  }


  componentDidMount = () => {
    console.log('SpAdAutomationList - componentDidMount()');

    // How many times we auto refresh the list.
    // We will not auto refresh list after certain count.
    this.autoRefreshCount = 0;

    // Fetch amazon user profile info
    this.fetchAmazonUserProfile();
  }

  componentWillUnmount = () => {
    console.log('SpAdAutomationList - componentWillUnmount()');

    // If if any timer exist then clear it before unmount component
    if (this.timerAutoRefresh) { 
      clearInterval(this.timerAutoRefresh);
      console.log('Cleared timer - timerAutoRefresh');
    }

  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    //console.log('SpAdAutomationList - UNSAFE_componentWillReceiveProps()');
    //console.log('nextProps:', nextProps);

    // Refresh flow list if true property found
    if( nextProps.shouldRefresh === true) {
      this.refreshFlowList();
    }
  }

  
  //-----------------------------------------------------------------
  // Start: Fetch amazon user profile info
  //-----------------------------------------------------------------
  fetchAmazonUserProfile = () => {

    // 1 - Set processing on
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get amazon user profile (name, email, user_id)
    getAmazonUserProfile(this.getAmazonUserProfile_Success, this.getAmazonUserProfile_Error);
  }
   
  // Amazon user profile fetched successfully
  // e.g. result: { 
  //   status: 'success', 
  //   data: { 
  //     user_id: 'amzn1.account.AG3OPETKMBVUEROxxxxxxxxxxxx',
  //     name: 'Firstname Lastname',
  //     email: 'email@gmail.com' 
  //   }
  //   error: null 
  // }  
  getAmazonUserProfile_Success = (result) => {
    console.log('getAmazonUserProfile_Success()');
    //console.log('getAmazonUserProfile_Success() result:', result);
     
    // If success state, we will receive data within result.
    if (result.status === 'success') {
      const { user_id, email } = result.data;
      this.setState({
        amazonUserId: user_id,
        amazonEmail: email,
      });

      // 2 - Fetch flow list 
      this.fetchFlowList();
    }
    
    // If error status, show appropriate message.
    if (result.status === 'error') {
      const message = "Error while fetch amazon user id, please refresh page."
      this.setState({
        //messageText: result.error,
        messageText: message,
        showMessage: true,
      });
    }
  }  

  // If any error occur while calling api etc. this function will be called via callback.
  getAmazonUserProfile_Error = (error) => {
    console.log('getAmazonUserProfile_Error() error:', error);

    this.setState({
      messageText: error.toString(),
      showMessage: true,
      //isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch amazon user profile info
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Fetch/Refresh flow list
  //-----------------------------------------------------------------
  // Refresh flow list.
  refreshFlowList = () => {
    console.log('refreshFlowList()');
    
    // Fetch the flow list again
    this.fetchFlowList();
  }

  // Fetch flow list
  fetchFlowList = async () => {
    console.log('fetchFlowList()');

    const { amazonUserId } = this.state;

    // 1 - If amazon user id empty within state then we can not fetch flow list.
    // Note: Current firebase user may logout from amazon and login with different
    // amazon login id. So within db we stored amazon_user_id field when flow created, 
    // So we will show flow list that belongs to current logged in amazon user.
    if( amazonUserId === '' ) {
      console.log('amazonUserId empty, so can not fetch flow list.');
      return;
    }

    // 2 - Set processing on
    this.setState({
      isProcessing: true,
    });

    // 3 - Fetch current logged in user id token
    const uid = await firebase.auth().currentUser.uid;

    // 4 - Fetch flow list for current user
    db.collection('sponsored_product_ad')
      .where('uid', '==', uid)
      .where('amazon_user_id', '==', amazonUserId)
      .orderBy('flow_create_date', 'desc')
      .get()
      .then( (querySnapshot) => {
        
        const flowListNew = [];
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          const data = doc.data();
          
          // Create data object for single flow
          const flow = {
            id: doc.id,
            flow_create_date: data.flow_create_date,
            api_mode: data.api_mode,
            flow_name: data.flow_name,
            profile_id: data.profile_id,
            profile_type: data.profile_type,
            status: data.status,
          }
          if (data.auto_campaign_id) {
            flow['auto_campaign_id'] = data.auto_campaign_id;
          } else {
            flow['auto_campaign_id'] = null;
          }
          if (data.manual_campaign_id) {
            flow['manual_campaign_id'] = data.manual_campaign_id;
          } else {
            flow['manual_campaign_id'] = null;
          }
          if (data.manual_broad_ad_group_id) {
            flow['manual_broad_ad_group_id'] = data.manual_broad_ad_group_id;
          } else {
            flow['manual_broad_ad_group_id'] = null;
          }
          if (data.manual_phrase_ad_group_id) {
            flow['manual_phrase_ad_group_id'] = data.manual_phrase_ad_group_id;
          } else {
            flow['manual_phrase_ad_group_id'] = null;
          }
          if (data.manual_exact_ad_group_id) {
            flow['manual_exact_ad_group_id'] = data.manual_exact_ad_group_id;
          } else {
            flow['manual_exact_ad_group_id'] = null;
          }

          // Push flow data to array
          flowListNew.push(flow);
        });

        // Debug
        console.log('flowListNew:', flowListNew);

        // Set fetched flow list array within state
        this.setState({
          flowList: flowListNew,
          isProcessing: false,
        });

        // Check that list auto refresh need or not. 
        // If need it will auto refresh list after few seconds.
        this.checkForRefreshNeed();

      })
      .catch( (error) => {
        console.log("Error getting flow documents: ", error);

        this.setState({
          messageText: 'Error getting flow documents',
          showMessage: true,
          isProcessing: false,
        });

      });

  }


  // If any record have 'creating' status exist then we will 
  // set timer to auto refresh list after 10 seconds etc.
  checkForRefreshNeed = () => {
    console.log('checkForRefreshNeed()');

    // 1 - Remove previous timer if already set before
    if (this.timerAutoRefresh) { 
      clearInterval(this.timerAutoRefresh);
      console.log('Cleared previous timer to refresh list');
    }

    // 2 - Check that any record have status === 'creating'
    const { flowList } = this.state;
    let creatingStatusExist = false;
    flowList.forEach((item, index) => {
      if ( item.status === 'creating' ) {
        creatingStatusExist = true;
      }
    });

    // 3 - Set timer to refresh list after 10 seconds if need
    // We will do auto refresh 4 times only to prevent refresh continue if
    // due to some reason it will have some record exist with creating status.
    if (creatingStatusExist && this.autoRefreshCount <= 4 ) {
      console.log('New timer set to refresh list');

      // Set timer for auto refresh
      this.timerAutoRefresh = setInterval( () => { this.refreshFlowList() }, 10000);
      
      // Increment counter (how many times auto refresh timer set)
      this.autoRefreshCount++;

    } else {
      console.log('No need to refresh list');
    }
  }

  //-----------------------------------------------------------------
  // End: Fetch/Refresh flow list
  //-----------------------------------------------------------------


  // When clicked edit button from flow list, this function called.
  onClick_Edit = (flowDocId, index) => {
    //console.log('onClick_Edit() flowDocId:', flowDocId, ' index:', index);
      
    // Inform parent component about clicked flow id, so parent component 
    // can show edit flow modal form etc.
    if (this.props.onClickEditFlow) {
      this.props.onClickEditFlow(flowDocId, index);
    }
    
  }

  // When clicked edit keyword button from flow list, this function called.
  onClick_EditKeyword = (flowDocId, index) => {
    //console.log('onClick_EditKeyword() flowDocId:', flowDocId, ' index:', index);

    // Inform parent component about clicked flow id, so parent component 
    // can show edit keyword modal screen etc.
    if (this.props.onClickEditKeyword) {
      this.props.onClickEditKeyword(flowDocId, index);
    }
  }

  // When clicked edit product button from flow list, this function called.
  onClick_EditProduct = (flowDocId, index) => {
    //console.log('onClick_EditProduct() flowDocId:', flowDocId, ' index:', index);

    // Inform parent component about clicked flow id, so parent component 
    // can show edit product modal screen etc.
    if (this.props.onClickEditProduct) {
      this.props.onClickEditProduct(flowDocId, index);
    }
  }

  // When clicked edit campaign button from flow list, this function called.
  onClick_EditCampaign = (flowDocId, index) => {
    //console.log('onClick_EditCampaign() flowDocId:', flowDocId, ' index:', index);
    
    // Inform parent component about clicked flow id, so parent component 
    // can show edit component modal screen etc.
    if (this.props.onClickEditCampaign) {
      this.props.onClickEditCampaign(flowDocId, index);
    }
  }



  // Render flow list data within ui (as a tabular format)
  renderFlowList = () => { 
    
    const { flowList, isProcessing } = this.state;
    const { classes } = this.props;

    return(
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="search result"> 
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: 40 }} >Sr.</TableCell>
              <TableCell align="left" style={{ width: 120 }} >Create Date</TableCell>
              <TableCell align="left" style={{ }} >Flow Information</TableCell>
              <TableCell align="left" style={{ width: 50 }} >Profile</TableCell>
              <TableCell align="center" style={{ width: 50 }} >Api Mode</TableCell>
              <TableCell align="center" style={{ width: 60 }} >Status</TableCell>
              <TableCell align="center" style={{ width: 190 }} >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { // If flow list array empty then show message
              flowList && flowList.length === 0 && 
              <TableRow key={ 'flow-not-exist-message' } >
                <TableCell align="center" colSpan="7" >
                  <Typography variant="subtitle1" style={{ marginTop: 10, marginBottom: 10 }} >
                    Flow not created yet.
                  </Typography>
                </TableCell>
              </TableRow>
            }
            { // If flow list array exist (not null) then render record from that.
              flowList &&
              flowList.map( (row, index) => {
              const createDateString = getMediumDate(row.flow_create_date.toDate());
              return(
                <TableRow key={ 'flow__' + row.id } >
                  <TableCell align="left" style={{ }} >
                    { index + 1 }
                  </TableCell>
                  <TableCell align="left" style={{ }} >
                    { createDateString }
                  </TableCell>
                  <TableCell align="left" style={{ }} >
                    { row.flow_name }
                    &nbsp; (
                    { row.auto_campaign_id && 'Auto' } 
                    { row.auto_campaign_id && row.manual_campaign_id && ', ' }
                    { row.manual_campaign_id && 'Manual' }
                    { row.manual_broad_ad_group_id && ' Broad' }
                    { row.manual_phrase_ad_group_id && ' Phrase' }
                    { row.manual_exact_ad_group_id && ' Exact' }
                    )
                  </TableCell>
                  <TableCell align="left" style={{ }} >
                    { row.profile_type }
                  </TableCell>
                  <TableCell align="center" style={{ }} >
                    { row.api_mode } 
                  </TableCell>
                  <TableCell align="center" style={{ }} >
                    { row.status === 'creating' &&
                      <LinearProgress />
                    }
                    { row.status }
                  </TableCell>
                  <TableCell align="right" style={{ }} >

                    { row.status === 'created' &&
                    <IconButton 
                      size="small"
                      aria-label="Edit Keyword" 
                      disabled={ isProcessing } 
                      onClick={ (e) => this.onClick_EditKeyword(row.id, index) }
                      title="Edit Keyword"
                      style={{ marginRight: 15, }}
                    >
                      <EditKeywordIcon fontSize="small" />
                    </IconButton>
                    }

                    { row.status === 'created' &&
                    <IconButton 
                      size="small"
                      aria-label="Edit Product" 
                      disabled={ isProcessing } 
                      onClick={ (e) => this.onClick_EditProduct(row.id, index) }
                      title="Edit Product"
                      style={{ marginRight: 15, }}
                    >
                      <EditProductIcon fontSize="small" />
                    </IconButton>
                    }

                    { row.status === 'created' &&
                    <IconButton 
                      size="small"
                      aria-label="Edit Campaign" 
                      disabled={ isProcessing } 
                      onClick={ (e) => this.onClick_EditCampaign(row.id, index) }
                      title="Edit Campaign"
                      style={{ marginRight: 15, }}
                    >
                      <EditCampaignIcon fontSize="small" />
                    </IconButton>
                    }

                    { row.status === 'created' &&
                    <IconButton 
                      size="small" 
                      aria-label="Edit" 
                      disabled={ isProcessing } 
                      onClick={ (e) => this.onClick_Edit(row.id, index) }
                      title="Edit Flow (Deprecated)"
                      style={{ marginRight: 0, }}
                    >
                      <EditIcon fontSize="small" style={{ color: '#cdcdcd' }} />
                    </IconButton>
                    }

                    { row.status === 'creating' &&
                    <IconButton size="small" aria-label="Edit" disabled={true} style={{ marginRight: 15, }} >
                      <EditKeywordIcon fontSize="small" />
                    </IconButton>
                    }

                    { row.status === 'creating' &&
                    <IconButton size="small" aria-label="Edit" disabled={true} style={{ marginRight: 15, }} >
                      <EditProductIcon fontSize="small" />
                    </IconButton>
                    }

                    { row.status === 'creating' &&
                    <IconButton size="small" aria-label="Edit" disabled={true} style={{ marginRight: 15, }} >
                      <EditCampaignIcon fontSize="small" />
                    </IconButton>
                    }

                    { row.status === 'creating' &&
                    <IconButton size="small" aria-label="Edit" disabled={true} >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    }
                  </TableCell>
                </TableRow>
              )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  // Called when message close after time out (snackbar message)
  handleClose = () => {
    console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }

  
  // Render ui
  render() {
    const { isProcessing } = this.state;

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return(
      <React.Fragment>
        { // Render flow list
          this.renderFlowList() 
        }
        { // Show progress if some processing on
          isProcessing && <LinearProgress /> 
        }

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={5000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </React.Fragment>
    );
  }
}

const styles = (theme) => ({

});


SpAdAutomationList.propTypes = {
  shouldRefresh: PropTypes.bool.isRequired, 
  onClickEditKeyword: PropTypes.func.isRequired, 
  onClickEditProduct: PropTypes.func.isRequired, 
  onClickEditCampaign: PropTypes.func.isRequired, 
  onClickEditFlow: PropTypes.func.isRequired, 
  classes: PropTypes.object.isRequired, 
}


//export default App;
export default withStyles(styles)(SpAdAutomationList);


// Get competitive price for given asin.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Import helper functions, constant etc.
import { getCompetitivePricingForASIN } from '../../helpers/mwsHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);


class GetCompetitivePricingForASIN extends React.Component {
  
  state = {
    // Form input
    market: 'CA',
    asins: 'B015CH1PJU, B015CH1JIW', // e.g. 'B015CH1PJU,B015CH1JIW'

    status: '',
    error: '',

    headers: null,
    products: [],
    responseMetadata: null,
    statusCode: 0,

    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('GetCompetitivePricingForASIN - componentDidMount()');
  }


  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      headers: null,
      products: [],
      responseMetadata: null,
      statusCode: 0,
    });
  }


  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asins } = this.state;
    if (!market || market === '') { return };
    if (!asins || asins === '') { return };

    // 2 - Make value empty
    this.setState({
      status: '',
      data: {},
      error: '',
      isProcessing: true,
    });

    // 3 - Call api
    getCompetitivePricingForASIN(market, asins, this.getCompetitivePricingForASIN_Success, this.getCompetitivePricingForASIN_Error);
  }

  // Called when api call success
  getCompetitivePricingForASIN_Success = (result) => {
    console.log("getCompetitivePricingForASIN_Success() result:", result);
    
    const { status, data, error } = result;
    
    const stateUpdates = {};

    // If multiple product then data will be array of products
    if ( Array.isArray(data) ) { // Multiple product result
      const productsTemp = [];
      data.forEach( (singleData, index) => {
        if (singleData.Product) {
          productsTemp.push(singleData.Product);
        }
      });
      stateUpdates['products'] = productsTemp;
    } else { // Single Product result
      if (data.Headers) {
        stateUpdates['headers'] = data.Headers;
      }
      if (data.ResponseMetadata) {
        stateUpdates['responseMetadata'] = data.ResponseMetadata;
      }
      if (data.statusCode) {
        stateUpdates['statusCode'] = data.statusCode;
      }
      if (data.Product) {
        stateUpdates['products'] = [data.Product];
      }
    }

    // Udpate value to state
    this.setState({
      ...stateUpdates,
      status: status,
      error: error,
      isProcessing: false,
    });
  }

  // Called if error while api call
  getCompetitivePricingForASIN_Error = (error) => {
    console.log("getCompetitivePricingForASIN_Error() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }


  // Render single product element
  renderSingleProduct = (product, index) => {
    const { CompetitivePrices, NumberOfOfferListings } = product.CompetitivePricing;
    const { SalesRank } = product.SalesRankings;

    // CompetitivePrice may be single or multiple array 
    const { CompetitivePrice } = CompetitivePrices;
    let competitivePriceEl;
    if (Array.isArray(CompetitivePrice)) { // Array object with multiple record
      competitivePriceEl = CompetitivePrices.CompetitivePrice.map( (price, index) => {
        return (
          <div key={'CompetitivePrice-' + index } >{JSON.stringify(price)} <br /><br /></div>
        )
      });
    } else { // Single data object
      competitivePriceEl = <div>{JSON.stringify(CompetitivePrice)} <br /><br /></div>;
    }


    return (
      <div key={ 'product-' + index } style={{ margin: 10 }} >
        <b># {index + 1}</b> <br />
        
        <b>Product.Identifiers: </b> <br />
        { JSON.stringify(product.Identifiers) } <br /><br />
        
        <b>Product.SalesRankings.SalesRank:</b> <br />
        { JSON.stringify(SalesRank) } <br /><br />
        
        <b>Product.CompetitivePricing.CompetitivePrices:</b> <br />
          { competitivePriceEl }

        <b>Product.CompetitivePricing.NumberOfOfferListings:</b> <br />
        { JSON.stringify(NumberOfOfferListings) } <br /><br />
        
        <hr />
      </div>
    );
  }

  // Render all product list
  renderProductList = (products) => {
    
    const allProductsEl = products.map( (product, index) => {
      return this.renderSingleProduct(product, index);
    });

    // Return all product element
    return(<>{ allProductsEl }</>);
  }


  renderHeaderInfo = (headers) => {
    const keys = Object.keys(headers);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {headers[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderResponseMetaData = (responseMetadata) => {
    const keys = Object.keys(responseMetadata);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {responseMetadata[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }


  renderForm = () => {
    const { isProcessing } = this.state;
    const { classes } = this.props;
    
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    markePlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <TextField 
                  id="asins" 
                  label="ASINS (max 20) Comma Separated" 
                  placeholder="" 
                  value={this.state.asins} 
                  onChange={(e) => this.setState({ asins: e.target.value })} 
                  size="small" 
                  disabled={isProcessing}
                />
              </FormControl>
          </Grid>
        </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        {isProcessing &&
          <b>Processing... &nbsp;&nbsp;&nbsp; </b>
        }
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={isProcessing}
        >Submit</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }

  renderResult = () => {
    const { status, error, headers, responseMetadata, products } = this.state;
    return (
      <div align='left'>
        { status !== '' && 
          <><b>Status:</b> {status}<hr /></>
        }
        { products.length > 0 && 
          <><h3>Product List: ({products.length})</h3>{ this.renderProductList(products) }</>
        }
        { responseMetadata && 
          <><h3>Response Header:</h3>{ this.renderHeaderInfo(headers) }<hr /></>
        }
        { responseMetadata && 
          <><h3>Response Metadata:</h3>{ this.renderResponseMetaData(responseMetadata) }<hr /></>
        }
        { error &&
          <>
            <b>Error:</b>
            <div style={{width: '100%', overflow: 'scroll', border: '0px solid #cdcdcd' }}>
              { error && error.toString() }
            </div>
          </>
        }
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>MWS - GetCompetitivePricingForASIN</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            { this.renderFormButton() }
            { this.renderResult() }
          </Box>
        </AccordionDetails>
      </Accordion>
    );

  }
}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


GetCompetitivePricingForASIN.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(GetCompetitivePricingForASIN);
// Check Competition component
// It consist multiple component, i.e. form, list etc.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import custom component
import CompetitionForm from './CompetitionForm';
//import ProductList from './ProductList';
import ProductListBrand from './ProductListBrand';
import ProductListSeller from './ProductListSeller';

import { listMatchingProducts, getCompetitivePricingForASIN } from '../../helpers/mwsHelper';
import { getProductReviewInfo } from '../../helpers/apiHelper';


class Competition extends React.Component { 

  // Data received from the api will be stored here. We will not directly show data 
  // received from the api to ui. So data received from api will be stored here, 
  // we will create simplified data from this and save to state, so that data 
  // will render within the ui.
  productsRaw = [];

  // This variable used to fetch review/rating for each asin added to ASIN_REVIEW_LIST.
  ASIN_REVIEW_LIST = [];
  ASIN_REVIEW_CURRENT_INDEX = -1;


  state = { 

    // Whenever user will fillup form and click submit button
    // we will save value within this variable.
    market: '',  // e.g. 'US'
    query: '',   // e.g. 'Pen Drive'

    // Tab related settings
    // brand - Show brand competition tab, seller - Show seller competition tab
    competitionType: 'seller',  // 'brand' OR 'seller'
    brandCount: 0,
    brandNamesText: '',    // e.g. 'Brand1, Brand2, Brand3, and Other Brands.'
    sellerCount: 0,
    sellerNamesText: '',   // e.g. 'Seller1, Seller2, Seller2, and Other Sellers.'

    // true - processing in progress, false - not processing anything
    isProcessing: false,

    // true - Product review fetching in progress, false - Product review not fetching at present.
    isFetchingReview: false,

    // Processed data i.e. converted to proper structure to display within list.
    products: [],
    productsSellerComp: [], // Product list to show for seller competition list
    productsBrandComp: [],  // Product list to show for brand competition list

    // It consist dictionary for asin and its corresponding review info
    // e.g. asinReviewDict: {
    //   asin1 : { review_count: 450, review_star: 4.5 },
    //   asin2 : { review_count: 300, review_star: 4.2 }
    // }
    asinReviewDict: {}

  }

  // Whenever user click submit button from CompetitionForm component,
  // at that time this function will be called with market and asin parameter.
  // We passed this function 
  onSubmitForm = (market, query) => {
    //console.log('onSubmitForm() market:', market, 'query:', query);
    
    // 1 - Start processing
    // Also save market and query value within state, so we can use it later on.
    // Also reset some state variable so it will show updated ui.
    this.setState({
      isProcessing: true,
      
      products: [],
      productsSellerComp: [],
      productsBrandComp: [],
      brandCount: 0,
      brandNamesText: '',
      sellerCount: 0,
      sellerNamesText: '',
      asinReviewDict: {},

      market: market, 
      query: query, 
    });

    // 2 - Call api to fetch matching product
    listMatchingProducts(query, market, this.listMatchingProductsSuccess, this.listMatchingProductsError);
  }

  // Called if api call success (listMatchingProducts)  
  listMatchingProductsSuccess = (result) => {
    console.log("listMatchingProductsSuccess()");
    //console.log("listMatchingProductsSuccess() result:", result);
    
    const { status, data, error } = result;
    console.log('status:', status);
    console.log('data:', data);
    console.log('error:', error);

    if ( data.StatusCode === 200 ) {
      // Save data received from api to class (this.) variable. We will not show this 
      // data directly to ui element, So no need to save it within state.
      this.productsRaw = data.Products.Product;
      
      // Prepare products data to show within ui.
      this.processRawProducts();

    } else {
      this.setState({
        isProcessing: false,
      });
    }
  }

  // Called if error while api call (listMatchingProducts)
  listMatchingProductsError = (error) => {
    console.log("listMatchingProductsError() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }

  // This function will process product raw data (received from api) 
  // and prepare products that can be displayed within ProductList component. 
  processRawProducts = () => {
    //console.log('processRawProducts()');

    const products = [];
    const { productsRaw } = this;
    productsRaw.forEach( (product, index) => {

      const { ASIN, MarketplaceId } = product.Identifiers.MarketplaceASIN;
      //const { SalesRank } = product.SalesRankings;
      const { ItemAttributes } = product.AttributeSets;
      const { Brand, Binding, ListPrice, Manufacturer, Model, PartNumber, 
        ProductGroup, ProductTypeName, Publisher, ReleaseDate, Size, SmallImage, 
        Title, Warranty,
      } = ItemAttributes;

      const tempProduct = {
        ASIN, 
        MarketplaceId,
        Brand,
        Binding,
        ListPrice,
        Manufacturer,
        Model,
        PartNumber,
        ProductGroup, 
        ProductTypeName,
        Publisher, 
        ReleaseDate,
        Size,
        SmallImageUrl: SmallImage.URL,
        SmallImageWidth: SmallImage.Width.Value,
        SmallImageHeight: SmallImage.Height.Value,
        Title,
        Warranty,
      };

      products.push(tempProduct);
    });

    // Debug
    console.log('products:', products);

    // Remove duplicate seller (publisher) record from list, we will show it for seller competition list.
    // i.e. we are creating product list for seller competition.
    const productsSellerComp = [];
    const tempDict2 = { };
    products.forEach( (product, index) => {
      // If publisher not exist then add to productsSellerComp list.
      const key = product.Publisher;
      if (!tempDict2.hasOwnProperty(key)) {
        tempDict2[key] = true;
        productsSellerComp.push(product);
      }
    });
    console.log('productsSellerComp:', productsSellerComp);

    // Remove duplicate brand record from list, we will show it for brand competition list.
    // i.e. we are creating product list for seller competition.
    const productsBrandComp = [];
    const tempDict = { };
    products.forEach( (product, index) => {
      // If brand not exist then add to productsBrandComp list.
      const key = product.Brand;
      if (!tempDict.hasOwnProperty(key)) {
        tempDict[key] = true;
        productsBrandComp.push(product);
      }
    });
    console.log('productsBrandComp:', productsBrandComp);

    // Prepare asinReviewDict with default value. Combine asin from both
    // Seller competition and Brand competition product list.
    // e.g. asinReviewDict: {
    //   asin1 : { review_count: 0, review_star: 0 },
    //   asin2 : { review_count: 0, review_star: 0 }
    // }
    let asinReviewDict = {};
    productsSellerComp.forEach((product, index) => {
      asinReviewDict[product.ASIN] = { review_count: 0, review_star: 0 };
    });
    productsBrandComp.forEach((product, index) => {
      asinReviewDict[product.ASIN] = { review_count: 0, review_star: 0 };
    });
    console.log('asinReviewDict:', asinReviewDict);  

    // Save data to state.
    this.setState({
      products: products,
      productsSellerComp: productsSellerComp,
      productsBrandComp: productsBrandComp, 
      asinReviewDict: asinReviewDict, 
      isProcessing: false,
    });

    // Prepare statistical data for brand competition
    this.prepareDataForBrandCompetition();

    // Prepare statistical data for seller competition
    this.prepareDataForSellerCompetition();

    // Calculate total number of seller based on the GetCompetitivePricingForAsin() mws api.
    this.calculateTotalNumberOfSeller();

    // Fetch Rating/review details for each asin one by one and set info within state.
    this.fetchAllAsinReviewInfo(asinReviewDict);
  }


  // Prepare statistical data to show within brand competition box.
  // e.g. Number Of Brands: 4
  // e.g. Brand1, Brand2, Brand3, Brand4 etc.
  prepareDataForBrandCompetition = () => {
    console.log('prepareDataForBrandCompetition()');
    //console.log('prepareDataForBrandCompetition() products:', this.state.products);
    
    // 1 - Prepare dictionary for brand names. We are using brand name as a key for dictionary, 
    // So it will not include duplicate record for the same brand name.
    // e.g. { 'Brand1': true, 'Brand2': true, ... };
    let brandNamesDict = {};
    this.state.products.forEach( (product, index) => {
      brandNamesDict[product.Brand] = true;
    });
    //console.log('brandNamesDict:', brandNamesDict);    

    // 2 - Count brand names
    const brandCount = Object.keys(brandNamesDict).length;
    //console.log('brandCount:', brandCount);

    // 3 - Prepare brand name string
    // e.g. 'Brand1, Brand2, Brad3'
    let brandNamesText = '';
    Object.keys(brandNamesDict).forEach( (brand, index) => {
      brandNamesText += brand;
      if ( index < brandCount-1 ) {
        brandNamesText += ', ';
      }
    });
    //console.log('brandNamesText:', brandNamesText);

    // 4 - Save info to state
    this.setState({
      brandCount: brandCount,
      brandNamesText: brandNamesText,
    });
  }

  // Prepare data to show within seller competition box
  // e.g. Number Of Seller: 4
  // e.g. Seller1, Seller2, Seller3, Seller4, and other sellers.
  prepareDataForSellerCompetition = () => {
    console.log('prepareDataForSellerCompetition()')
    //console.log('prepareDataForSellerCompetition() products:', this.state.products);

    // 1 - Prepare dictionary for seller names. We are using seller name as dict key, 
    // So it will not include duplicate record for the same seller name.
    // e.g. { 'Seller1': true, 'Seller2': true, ... };
    let sellerNamesDict = {};
    this.state.products.forEach( (product, index) => {
      sellerNamesDict[product.Publisher] = true;
    });
    //console.log('sellerNamesDict:', sellerNamesDict);

    // 2 - Prepare seller name string
    // e.g. 'Seller1, Seller2, Seller3'
    let sellerNamesText = '';
    Object.keys(sellerNamesDict).forEach( (brand, index) => {
      sellerNamesText += brand + ', ';
    });
    sellerNamesText += 'and Other Sellers.';
    //console.log('sellerNamesText:', sellerNamesText);

    // 3 - Save info to state
    this.setState({
      sellerNamesText: sellerNamesText,
    });
  }

  // This function will collect asin from product list and call getCompetitivePricingForAsin()
  // mws api and find out OfferListingCount maximum value from result. So OfferListingCount 
  // maximum value will be the total (or max.) number of seller selling (competiting for) 
  // that product.
  calculateTotalNumberOfSeller = () => {
    console.log('calculateTotalNumberOfSeller()');

    // 1 - Fetch maket value from state.
    const { market } = this.state;

    // 2 - Prepare asins string from products data. (comma separated asins)
    // e.g. 'asin1,asin2,asin3' etc.
    let asinList = '';  
    const count = this.state.products.length;
    this.state.products.forEach( (product, index) => {
      asinList += product.ASIN;
      if (index < count-1) {
        asinList += ',';
      }
    });
    console.log('asinList:', asinList);

    // 3 - Call mws api getCompetitivePricingForASIN()
    getCompetitivePricingForASIN(market, asinList, this.getCompetitivePricingForASIN_Success, this.getCompetitivePricingForASIN_Error);
  };

  // If api call success, this function will be called with result
  getCompetitivePricingForASIN_Success = (result) => {
    console.log("getCompetitivePricingForASIN_Success()");
    //console.log("getCompetitivePricingForASIN_Success() result:", result);

    // 1 - Get necessary data from the result
    //const { status, error } = result;
    const { data } = result;

    // 2 - If multiple asin supplied to api then result.data consist array of products, 
    // otherwise it have single product object.
    // i.e. we may received multiple products or single product under result.data so we 
    // have handle both situation as under.
    let productsTemp = [];
    if ( Array.isArray(data) ) { // Multiple product received within result.data
      data.forEach( (singleData, index) => {
        if (singleData.Product) {
          productsTemp.push(singleData.Product);
        }
      });
    } else { // Single Product received within result.data
      if (data.Product) {
        productsTemp = [data.Product];
      }
    }
    //console.log("productsTemp:", productsTemp);

    // 3 - Find max value for OfferListingCount.
    let offerListingCountMax = 0; 
    productsTemp.forEach( (product, index) => {
      // Fetch offer listing count array for current product.
      // e.g. OfferListingCount = [{"Value":"16","condition":"New"},{"Value":"16","condition":"Any"}]}
      const { OfferListingCount } = product.CompetitivePricing.NumberOfOfferListings;
      //const { ASIN } = product.Identifiers.MarketplaceASIN; // Fetch asin (Debug purpose)
      
      // Loop each record from OfferListingCount array.
      OfferListingCount.forEach((offer, index) => { 
        //console.log(ASIN, JSON.stringify(offer)); // Debug
        // If offer.Value is higher than offerListingCountMax then make it max.
        const offerValue = parseInt(offer.Value); // Convert to number, because Value in string format.
        if ( offerValue > offerListingCountMax ) { offerListingCountMax = offerValue; }
      });
    });
    //console.log('offerListingCountMax:', offerListingCountMax);

    // 4 - Set value to state
    this.setState({
      sellerCount: offerListingCountMax,
    });
  } 
  
  // If error while callling api then this function called.
  getCompetitivePricingForASIN_Error = (error) => {
    console.log("getCompetitivePricingForASIN_Error() error: ", error);
    // Add code here if need
  }

  // This function will fetch review/rating for each asin and update it within state.
  fetchAllAsinReviewInfo = (asinReviewDict) => {
    console.log('fetchAllAsinReviewInfo()');
    
    // Save data within class varible, so we can fetch review info one by one.
    this.ASIN_REVIEW_LIST = Object.keys(asinReviewDict);
    this.ASIN_REVIEW_CURRENT_INDEX = -1;
    console.log('this.ASIN_REVIEW_LIST:', this.ASIN_REVIEW_LIST);

    // Set isFetching review true so it will disable text input and submit button.
    this.setState({
      isFetchingReview: true,
    });

    // Fetch next asin review info
    this.fetchNextAsinReviewInfo(); 
  }

  // Fetch next asin revie info from 
  fetchNextAsinReviewInfo = () => {
    //console.log('fetchNextAsinReviewInfo()');
    
    // 1 - If asin list array not extist then return
    const asinCount = this.ASIN_REVIEW_LIST.length;
    if ( asinCount === 0 ) {
      
      // Set fetching review off
      this.setState({
        isFetchingReview: false,
      });

      return; // IMPORTANT
    }

    // 2 - Fetch next index 
    const nextIndex = this.ASIN_REVIEW_CURRENT_INDEX + 1;
    if ( nextIndex >= asinCount) {
      console.log('Done - All Asin Review info fetched');
      
      // Set fetching review off
      this.setState({
        isFetchingReview: false,
      });

      return; // IMPORTANT
    }

    // 3 - Upate current index within state
    this.ASIN_REVIEW_CURRENT_INDEX = nextIndex;

    // 4 - Fetch market and current asin
    const { market } = this.state;
    const asin = this.ASIN_REVIEW_LIST[nextIndex];
    console.log('Fetching Asin Review: ', nextIndex, ' | ', asin, ' | ', market);

    // 5 - Call api to fetch review for asin + market
    getProductReviewInfo(asin, market, this.getProductReviewInfo_Success, this.getProductReviewInfo_Error);
  }

  getProductReviewInfo_Success = (result, asin) => {
    console.log('getProductReviewInfo_Success() result:', result, ' asin', asin);

    // 1 - Get a copy of asinReviewDict from state
    const { asinReviewDict } = this.state;
    let asinReviewDictTemp = { ...asinReviewDict };
    
    // 2 - Update received review info for asin. 
    asinReviewDictTemp[asin] = result.data;

    // 3 - Save updates info within state.
    this.setState({
      asinReviewDict: asinReviewDictTemp
    });

    // 4 - Fetch review for next asin
    this.fetchNextAsinReviewInfo();
  }
  
  getProductReviewInfo_Error = (error) => {
    console.log('getProductReviewInfo_Error() error:', error);
    
    // Just processed asin may have problem in fetching review info, so it come 
    // to this error callback, so we will continue to fetch review info for 
    // next asin.

    // Fetch review for next asin 
    this.fetchNextAsinReviewInfo();
  }


  // This function called when tab changed (Seller Competition, Brand Competition)
  handleChange = (event, newValue) => { 
    //console.log('newValue:', newValue);

    this.setState({
      competitionType: newValue,
    });
  }


  render() {
    const { competitionType, isProcessing, isFetchingReview, products, 
            productsBrandComp, productsSellerComp, asinReviewDict } = this.state;
    
    return(
      <React.Fragment>
        <CompetitionForm 
          onSubmit={this.onSubmitForm} 
          isProcessing={ isProcessing || isFetchingReview }
        />
        
        <div style={{height: 10}} ></div>
        
        { // If product list exist  then show tab
          products.length > 0 && 
          <Tabs
            variant="fullWidth"
            value={competitionType}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
            aria-label="switch between seller or brand competition"
            centered
          >
            <Tab label="Competition Between Seller" value="seller" />
            <Tab label="Competition Between Brands" value="brand" />
          </Tabs>
        }
        
        { // Show seller competition box if that tab is active.
          competitionType === 'seller' && products.length > 0 &&
          <Paper variant="elevation" elevation={4} style={{padding: 12}} >
            <b>Total Number Of Seller: </b> { this.state.sellerCount > 0 ? this.state.sellerCount : '--' } <br />
            { this.state.sellerNamesText }
          </Paper>
        }
        
        { // Show brand competition box if that tab is active.
          competitionType === 'brand' && products.length > 0 &&
          <Paper variant="elevation" elevation={4} style={{padding: 12}} >
            <b>Number Of Brands: </b> { this.state.brandCount > 0 ? this.state.brandCount : '--' } <br />
            { this.state.brandNamesText }
          </Paper>
        }

        <br />
        { isProcessing && <LinearProgress /> }

        { // We placed seller and brand competition tab so this list not used now.
          //products.length > 0 && 
          //<ProductList products={products} isProcessing={isProcessing} />
        }
        { // If seller competition tab selected then show product list for seller
          competitionType === 'seller' && productsSellerComp.length > 0 && 
          <ProductListSeller 
            products={productsSellerComp} 
            asinReviewDict={asinReviewDict}
            isProcessing={isProcessing} 
          />
        }
        { // If brand competition tab selected then show product list for brands
          competitionType === 'brand' && productsBrandComp.length > 0 && 
          <ProductListBrand 
            products={productsBrandComp} 
            asinReviewDict={asinReviewDict}
            isProcessing={isProcessing} 
          />
        }

        <br /><br />
      </React.Fragment>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


Competition.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Competition);
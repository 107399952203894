// Tacker form to create new tacker for given asin+market.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import firebase from '../../helpers/firebaseApp';

// Import helper functions, constant etc.
import { createTrackerRequest } from '../../helpers/firebaseHelper';
import { createAsinTrackingInfo, processTrackingDataOnce, getMatchingProductForId } from '../../helpers/mwsHelper';
import { marketPlaces } from '../../helpers/constants';

const marketPlacesKeys = Object.keys(marketPlaces);

const db = firebase.firestore();


class TrackerForm extends React.Component {


  state = {
    // Form input
    market: '',  // CA, US etc. 
    asin: '',   // single ASIN e.g. B07T2Z2KSM

    // As soon as new tracker created, we will save that tracker id here 
    // for further use until all process finished. e.g. Once tracker created
    // we need to fetch product thumb image url and update it within the 
    // newly created tracker, so we are saving newly created trackerId within 
    // state, we will clean up it once image url saved within db.
    newTrackerId: '',

    // true - some processing on
    isProcessing: false,

    // Message text
    showMessage: false,
    messageText: "",
  }

  componentDidMount = () => {
    console.log('TrackerForm - componentDidMount()');
  }


  onClickSubmit = () => {
    //console.log('TrackerForm - onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asin } = this.state;
    if (!market || market === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please select Marketplace',
      });
      return;
    }
    if (!asin || asin === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please enter ASIN',
      });
      return;
    }

    // Check that given tracker request exist for maket+asin
    // If already exist then show message.
    const { trackerList } = this.props;
    let isExist = false;
    trackerList.forEach( (tracker, index) => {
       if (tracker.market === market && tracker.asin === asin) {
        isExist = true;
       }
    })
    if (isExist) {
      const countryName = marketPlaces[market].country_name;
      this.setState({
        showMessage: true,
        messageText: 'Tracker Already Exist for Market: ' + countryName + '  ASIN: ' + asin,
      });
      return;
    }


    // 2 - Set processing on
    this.setState({
      isProcessing: true,
    })

    // 3 - Create tracker request via helper function
    createTrackerRequest(market, asin, this.createTrackingRequest_Success, this.createTrackingRequest_Error);

    // 4 - Create asin tracking 
    createAsinTrackingInfo(market, asin);
  }

  
  // New Tracker created successfully
  createTrackingRequest_Success = (market, asin, trackerId) => {
    console.log("createTrackingRequest_Success()");
    
    // 1 - Save newly created tracker id within state for further use.
    // We have to also fetch image url for the product so we need to do 
    // further api call, so we saved newly created tracker id here, so once
    // image url fetched then we will inform parent component about 
    // tracker created successfully.
    this.setState({
      newTrackerId: trackerId,
    });

    // 2 - We need product small image url so calling this api, once this api success then 
    // we will inform parent component about tracker created successfully.
    getMatchingProductForId(market, 'ASIN', asin, this.getMatchingProductForId_Success, this.getMatchingProductForId_Error);

    // 3 - process tracking data once for newly created trackerId (i.e. first time)
    processTrackingDataOnce(market, asin, trackerId);
  }

  // Error while creating new tracker
  createTrackingRequest_Error = (error) => {
    console.log("createTrackingRequest_Error() error: ", error);
    
    this.setState({
      isProcessing: false,
      showMessage: true,
      messageText: error.toString(),
    });
  }

  // Product image related data fetched via mws api.
  getMatchingProductForId_Success = async (result) => {
    console.log('getMatchingProductForId_Success() result:', result);

    // 1 - Fetch newly created tracker id from state, as soon as new tracker 
    // created we saved newTrackerId value within state, so we have that value.
    const { market, asin, newTrackerId } = this.state;

    // 2 - Parse small image url from result and update it within db for newly created tracker.
    const { data } = result;  // We will get one record within data because we passed one asin to api
    const { ItemAttributes } = data.Products.Product.AttributeSets;
    let imageUrl = '';
    let imageWidth = 0;
    let imageHeight = 0;
    if (ItemAttributes.SmallImage) {
      const { URL, Width, Height } = ItemAttributes.SmallImage;
      imageUrl = URL;
      imageWidth = Width.Value;
      imageHeight = Height.Value;
    }
    
    // 3 - Update small image url within db.
    const updateData = {
      small_image_url: imageUrl,
      small_image_width: imageWidth,
      small_image_height: imageHeight,
    }
    //console.log('updateData:', updateData);
    await db.collection('tracking_request').doc(newTrackerId.toString()).update(updateData);

    // 4 - Infom parent with newly created tracker id, so they can refresh tracker list.
    this.props.onCreatedNew(newTrackerId);

    // 5 - Show success message
    const countryName = marketPlaces[market].country_name;
    const message = "Tracker Created for  MARKET: " + countryName + '  ASIN: ' + asin;
    this.setState({
      isProcessing: false,
      showMessage: true,
      messageText: message,
      asin: '',
      market: '',
      newTrackerId: '',
    });
  }

  // If error while getting product image related info
  getMatchingProductForId_Error = (error) => {
    console.log('getMatchingProductForId_Error()')
  
    // 1 - If we got error while fetch image url, so in this case we inform parent about 
    // new tracker created. In this case image not show with tracker record. User can 
    // delete tracker and create again if he wish to show image.
    const { market, asin } = this.state;
    const countryName = marketPlaces[market].country_name;
    const message = "Tracker Created for  MARKET: " + countryName + '  ASIN: ' + asin;

    // 2 - Infom parent with newly created tracker id, so they can refresh tracker list.
    const { newTrackerId } = this.state;
    this.props.onCreatedNew(newTrackerId);

    // 3 - Show success message
    this.setState({
      isProcessing: false,
      showMessage: true,
      messageText: message,
      asin: '',
      market: '',
      newTrackerId: '',
    });
  }


  // Render form content 
  renderForm = () => { 
    const { isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" component="h1">
              Create Tracker
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    marketPlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={3}>
              <FormControl className={classes.formControl} >
                <TextField 
                  //id="asin" 
                  label="ASIN (single)"
                  placeholder=""
                  value={this.state.asin} 
                  onChange={(e) => this.setState({ asin: e.target.value })} 
                  size="small"
                  disabled={isProcessing}
                />
              </FormControl>
          </Grid>
          <Grid item xs={12} sm={2} style={{ textAlign: 'left'}} >
            <Button 
              variant="contained" 
              size="medium"
              onClick={this.onClickSubmit} 
              style={{marginRight: 20 }} 
              disabled={isProcessing}
            >Create</Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  // Called when message close after time out
  handleClose = () => {
    //console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }  

  render() {
    //const { classes } = this.props;
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <>
        <Paper elevation={2} style={{padding: 20}} >
          { this.renderForm() }
        </Paper >
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


TrackerForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(TrackerForm);
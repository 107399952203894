// Amazon Advertising Api: 
// Sponsored Products - Requests a Sponsored Products report.
// 
// POST '/v2/sp/{recordType}/report';
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/requestReport

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import helper functions, constant etc.
import { getProfiles, spRequestReport } from '../../helpers/amazonAdHelper';

import { getCurrentDate_YYYYMMDD, getFutureDate_YYYYMMDD, getPastDate_YYYYMMDD } from '../../helpers/utility';


class SPRequestReport extends React.Component {
  
  state = {

    // Start - Form input data
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/requestReport
    // number: profile id under which report will be requested
    profileId: "",
    
    // string: The type of campaign. 
    // Only required for asins report (i.e. recordType = 'asin') - don't use with other report types.
    campaignType: 'sponsoredProducts', 
    
    // string:
    // The type of entity for which the report should be generated.
    // Valid values: campaigns | adGroups | keywords | productAds | asins | targets
    recordType: "keywords", 

    // String: Dimension on which the report is segmented.
    //  Valid values: 'query' | 'placement'
    segment: 'query', 

    // The date for which to retrieve the performance report in YYYYMMDD format. 
    // The time zone is specified by the profile used to request the report. 
    // If this date is today, then the performance report may contain partial 
    // information. Reports are not available for data older than 60 days. F
    // or details on data latency, see the Service Guarantees in the developer notes section.    
    // 
    // The format of the date is YYYYMMDD for passing to api.
    // Date input control expect format in YYYY-MM-DD
    reportDate: "",  // e.g. YYYY-MM-DD
    
    // string
    // ReportMetrics i.e. 
    // A comma-separated list of the metrics to be included in the report. 
    // The following tables summarize report metrics which can be requested via 
    // the reports interface. Different report types can use different metrics. 
    // Note that ASIN reports only return data for either keywords or targets, 
    // but not both. 
    // 
    metrics: "campaignId,campaignName,adGroupId,adGroupName,keywordId,keywordText,matchType,impressions,clicks,cost",

    // End - Form input data
    
    // Once report request we will set repsonse here
    // e.g.
    // reportResults: { 
    //     reportId : "amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa",
    //     recordType : "keyword",
    //     status : "IN_PROGRESS",
    //     statusDetails : "Generating report"
    // },
    reportResults: null,


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],


    // If error occur during any operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SPRequestReport - componentDidMount()');

    // 1 - Set default value for report date
    const reportDate = getPastDate_YYYYMMDD(4); // Current Date - 4 days 
    this.setState({
      reportDate: reportDate,
    });

    // 1 - Fetch amazon profile list to fillup dropdown
    // Commented: because we passed profileList as props from parent component
    //this.fetchProfileList();
  }

  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass the profile Id to get campain list api, so we 
  // first will fetch profile list and fillup drop down, so user 
  // can choose profile from there and click Get Camapaign List button
  // to fetch campain list for the selected profile.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }    
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      
      status: '',
      error: '',
      isProcessing: false,
      
      profileId: '',
      recordType: "keywords", 
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Request a report
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    console.log('onClickSubmit()');

    // Reset error message
    this.setState({ error: ''});

    // 1 - Read form data from state
    const { profileId, recordType, campaignType, segment, reportDate, metrics } = this.state;

    // 2 - If mandatory info not selected then return
    if (!profileId || profileId === '') { 
      console.log('profileId not selected, so return');
      this.setState({ error: 'Please select profileId.'});
      return;
    }
    if (!campaignType || campaignType === '') { // e.g. campaignType = 'sponsoredProducts'
      console.log('campaignType empty, so return');
      this.setState({ error: 'campaignType empty, so return'});
      return;
    }
    if (!segment || segment === '') { 
      console.log('segment empty, so return');
      this.setState({ error: 'Enter segment value (query or placement)'});
      return;
    }
    if (!recordType || recordType === '') { 
      console.log('recordType empty, so return');
      this.setState({ error: 'Select record type'});
      return;
    }
    if (!reportDate || reportDate === '') { 
      console.log('reportDate empty, so return');
      this.setState({ error: 'Please enter report date (past date)'});
      return;
    }
    if (!metrics || metrics === '') { 
      console.log('report metrics empty, so return');
      this.setState({ error: 'Please enter report metrics'});
      return;
    }

    // 3 - Prere data to request report
    //    e.g. reportData = {
    //            "campaignType": "sponsoredProducts",
    //            "segment": "query",                       // 'query' or 'placement'
    //            "reportDate": "string",                   // 'YYYYMMDD'
    //            "metrics": "string"                       
    //         }
    // 
    // Convert date in to YYYYMMDD format (server side api expect date as YYYYMMDD string)
    const reportDateFinal = reportDate.replace(/-/g, ""); // e.g. 20201021
    const reportData = {
      segment: segment,
      reportDate: reportDateFinal,
      metrics: metrics, 
    }
    // Note: campaignType required for 'asins' report, so if asin report 
    // then add campaignType key:value within data.
    if ( recordType === 'asins' ) {
      reportData['campaignType'] = campaignType; // // e.g. campaignType = 'sponsoredProducts' 
    }

    // debug
    console.log('profileId:', profileId);
    console.log('recordType:', recordType);
    console.log('reportData:', reportData);
    
    // 4 - Show processing
    this.setState({
      isProcessing: true,
    });
    
    // 5 - Call api to request report
    spRequestReport(profileId, recordType, reportData, this.spRequestReport_Success, this.spRequestReport_Error);
  }

  // Called when api call success
  spRequestReport_Success = (result) => {
    console.log('spRequestReport_Success() result:', result);
  
    if (result.status === 'success') {
      this.setState({
        reportResults: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }

  // Called if any error while calling api
  spRequestReport_Error = (error) => {
    console.log('spRequestReport_Error() error:', error);
    
    this.setState({
      error: error.toString(),
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Request a report
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-profile-label">Choose Profile</InputLabel>
            <Select
              id="id-profile-id"
              labelId="choose-profile-label"
              value={profileId}
              onChange={(e) => this.setState({ profileId: e.target.value })}
              disabled={isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              Reports will be requested for selected profile.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <InputLabel id="record-type-label">Record Type</InputLabel>
            <Select
              id="id-record-type"
              labelId="record-type-label"
              value={this.state.recordType}
              onChange={(e) => this.setState({ recordType: e.target.value })}
              disabled={isProcessing}
            >
              <MenuItem value="campaigns" >campaigns</MenuItem>
              <MenuItem value="adGroups" >adGroups</MenuItem>
              <MenuItem value="keywords" >keywords</MenuItem>
              <MenuItem value="productAds" >productAds</MenuItem>
              <MenuItem value="asins" >asins</MenuItem>
              <MenuItem value="targets" >targets</MenuItem>
            </Select>
          </FormControl>  
        </Grid>

        <Grid item xs={6} sm={6}>
          <form className={classes.formControl} >
            <TextField
              id="reportDate"
              label="Report Date"
              type="date"
              value={this.state.reportDate}
              onChange={(e) => this.setState({ reportDate: e.target.value })}
              className={classes.textField}
              InputLabelProps={{ shrink: true, }}
            />
            <FormHelperText>
              Please select past date.
            </FormHelperText>
          </form>
        </Grid>
    
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="id-metrics" 
              label="Report metrics" 
              placeholder="" 
              value={this.state.metrics} 
              onChange={(e) => this.setState({ metrics: e.target.value })} 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
              helperText="Comma separated metrics to generate report"
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        (<a href="https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/requestReport" target="_blank" >View API Help</a>)
        &nbsp;&nbsp;&nbsp;
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' }
        >Request/Create Report</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }
  
  // Render result received
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { reportResults } = this.state;
    if ( !reportResults ) { return null; }

    // Render result
    // e.g. reportResults = {
    //        "reportId" : "amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa",
    //        "recordType" : "keyword",
    //        "status" : "IN_PROGRESS",
    //        "statusDetails" : "Generating report"
    // }
    // 
    const resultEl = Object.keys(reportResults).map((key, index) => {
      return (
        <React.Fragment key={ 'report-result-' + index } >
          <b>{key}:</b> { reportResults[key] } <br />
        </React.Fragment>
      );
    });

    return (
      <div align='left'>
        <b>Result:</b>
        <hr />
        { resultEl }
        <br />
        Note: Please use reportId within <b>Get Report</b> Api to get report info.
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion elevation={4} >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Request Report</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h4 style={{ color: 'red' }} >{error}</h4> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SPRequestReport.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SPRequestReport);


// Amazon Advertising Api: 
// Sponsored Products - Gets a previously requested report specified by identifier (reportId)

// POST '/v2/reports/{reportId}';
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/getReport

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import helper functions, constant etc.
import { getProfiles, spGetReport } from '../../helpers/amazonAdHelper';
//import { getCurrentDate_YYYYMMDD, getFutureDate_YYYYMMDD, getPastDate_YYYYMMDD } from '../../helpers/utility';


class SPGetReport extends React.Component {
  
  state = {

    // Start - Form input data
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/requestReport
    // number: profile id under which report will be requested
    profileId: "",
    
    // previously requested report Id
    // e.g. reportId: 'amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa', 
    reportId: '', 

    // End - Form input data
    

    // Once report received we will set repsonse here
    // e.g.
    // results: { 
    //   fileSize : 2938, 
    //   location : "https://advertising-api-test.amazon.com/v1/reports/amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa/download", 
    //   reportId : "amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa", 
    //   status : "SUCCESS", 
    //   statusDetails : "Report successfully generated",
    // },
    results: null, 


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],


    // If error occur during any operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SPGetReport - componentDidMount()');

    // 1 - Fetch amazon profile list to fillup dropdown
    // Commented: because we passed profileList as props from parent component
    //this.fetchProfileList();
  }

  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass the profile Id to get campain list api, so we 
  // first will fetch profile list and fillup drop down, so user 
  // can choose profile from there and click Get Camapaign List button
  // to fetch campain list for the selected profile.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }    
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      
      status: '',
      error: '',
      isProcessing: false,
      
      profileId: '',
      reportId: '', 
      results: '',

    });
  }

  
  //-----------------------------------------------------------------
  // Start: Get a report
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    console.log('onClickSubmit()');

    // Reset error message
    this.setState({ error: ''});

    // 1 - Read form data from state
    const { profileId, reportId } = this.state;

    // 2 - If mandatory info not selected then return
    if (!profileId || profileId === '') { 
      console.log('profileId not selected, so return');
      this.setState({ error: 'Please select profileId.'});
      return;
    }
    if (!reportId || reportId === '') { 
      console.log('reportId empty, so return');
      this.setState({ error: 'Please Enter Report Id'});
      return;
    }

    // debug
    console.log('profileId:', profileId);
    console.log('reportId:', reportId);
    
    // 4 - Show processing
    this.setState({
      isProcessing: true,
    });
    
    // 5 - Call api to get report
    spGetReport(profileId, reportId, this.spGetReport_Success, this.spGetReport_Error);
  }

  // Called when api call success
  spGetReport_Success = (result) => {
    console.log('spGetReport_Success() result:', result);
  
    if (result.status === 'success') {
      this.setState({
        results: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }

  // Called if any error while calling api
  spGetReport_Error = (error) => {
    console.log('spGetReport_Error() error:', error);
    
    this.setState({
      error: error.toString(),
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Request a report
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-profile-label">Choose Profile</InputLabel>
            <Select
              id="id-profile-id"
              labelId="choose-profile-label"
              value={profileId}
              onChange={(e) => this.setState({ profileId: e.target.value })}
              disabled={isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="report-id" 
              label="Report Id" 
              placeholder="" 
              value={this.state.reportId} 
              onChange={(e) => this.setState({ reportId: e.target.value })} 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
              helperText="Please enter previously requested report id (via Request Report api)"
            />
          </FormControl>
        </Grid>

      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        (<a href="https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/getReport" target="_blank" >
          View API Help
        </a>)
        &nbsp;&nbsp;&nbsp;
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' }
        >Get Report</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }
  
  // Render result received
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { results } = this.state;
    if ( !results ) { return null; }

    // Render result
    // e.g. results = {
    //    fileSize : 2938, 
    //    location : "https://advertising-api-test.amazon.com/v1/reports/amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa/download", 
    //    reportId : "amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa", 
    //    status : "SUCCESS", 
    //    statusDetails : "Report successfully generated",
    // }
    const resultEl = Object.keys(results).map((key, index) => {
      return (
        <React.Fragment key={ 'result-' + index } >
          <b>{key}:</b> { results[key] } <br />
        </React.Fragment>
      );
    });

    return (
      <div align='left'>
        <b>Result:</b>
        <hr />
        { resultEl }
        <br />
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion elevation={4} >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Get Report</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h4 style={{ color: 'red' }} >{error}</h4> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SPGetReport.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SPGetReport);


// Sponsored Product - Ad Automation

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Import ui component
import Paper from '@material-ui/core/Paper';
//import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
//import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


// Import helper functions, constant etc.
import { marketPlaces } from '../../helpers/constants';
import { listMatchingProducts, getMatchingProductForId } from '../../helpers/mwsHelper';

//import { spGetCampaigns } from '../../helpers/amazonAdHelper';
import { 
  getAmazonUserProfile, getProfiles, 
  spGetSuggestedKeywordsForAsins,
  createFlowSponsoredProduct,
} from '../../helpers/amazonAdHelper';

import { getCurrentDate_YYYYMMDD, getFutureDate_YYYYMMDD, getPastDate_YYYYMMDD } from '../../helpers/utility';

import firebase from '../../helpers/firebaseApp';


const db = firebase.firestore();
const markePlacesKeys = Object.keys(marketPlaces);


class SpAdAutomationUi extends React.Component { 


  state = {
    // Start: ---- Form input data ----
    
    // When set DUMMY it will not call amazon ad api but call the 
    // logic to return dummy result. So we can test the whole flow.
    // During dummy it will not any create any campaign etc. within amazon
    // account. It will create data within db and run necessary flow.
    // Note: When set REAL it will call real api and run the flow, and save 
    // necessary data within db also.
    API_MODE: 'DUMMY',   // 'DUMMY' or 'REAL'

    // We will fetch current logged in amazon user profile info and set it here.
    amazonUserId: '',
    amazonEmail: '',

    // If select 'seller' then we will fillup seller profile within profile dropdown.
    // If select 'venfor' then we will fillup vendor profile within profile dropdown.
    runAdFor: '',    // 'seller' or 'vendor' 

    // Selected profile id
    profileId: '',

    // Market place from which search product
    market: '', 

    // Search product by
    searchProductBy: '',    // 'SellerSKU' or 'ASIN' or 'SearchQuery'
    
    // Text input value based on the searchProductBy.
    // If searchProductBy = 'SellerSKU' then it is sku of the product.
    // If searchProductBy = 'ASIN' then it is ASIN of the product search.
    // If searchProductBy = 'SearchQuery' then search terms for product search.
    searchProductValues: '', 

    // Once user will search product via asin, sku, or Search query we will
    // fillup received data within this array.
    // e.g. searchProductResults = [ { key: value, key: value }, ... ]
    // i.e. searchProductResults: [{
    //   SellerSKU: '',
    //   ASIN: 'ASIN1', 
    //   MarketplaceId: 'MarketplaceId1', 
    //   Title: 'Title1',
    //   SmallImage: null,
    //   productCost: '', 
    // }],
    searchProductResults: null,
    
    // From the product search result, user will select product, so we will save the 
    // selected product's data in this array variable for later use etc.
    // e.g. selectedProducts = [{
    //   SellerSKU: '',
    //   ASIN: ASIN, 
    //   MarketplaceId: MarketplaceId, 
    //   Title: Title,
    //   SmallImage: SmallImage,
    //   productCost: '',
    // }]
    selectedProducts: [],


    // Once user will select product and confirm it, we will set true.
    // So we can show next step related ui element.
    productConfirmed: false, 

    // Type of the campaign to be created
    // Note: atleaset one should be checked to proceed further
    automaticCampaignChecked: true,  // Default: false (unchecked)
    broadAdGroupChecked: true,        // Default: true (checked)
    phraseAdGroupChecked: false,      // Default: false (unchecked)
    exactAdGroupChecked: true,        // Default: true (checked)

    // Bid amount for each group type
    bidAmountAutomatic: 0.5,
    bidAmountBroad: 0.5,    
    bidAmountPhrase: 0.5,
    bidAmountExact: 0.8,

    // Name of the flow (input by user)
    flowName: '',
    
    // string: Campaign Start Date
    // A starting date for the campaign to go live. 
    // The format of the date is YYYYMMDD for passing to api.
    // Date input control expect format in YYYY-MM-DD
    startDate: "",  // e.g. YYYY-MM-DD

    // string: Campaign End Date
    // An ending date for the campaign to stop running. 
    // The format of the date is YYYYMMDD for passing to api
    // Date input control expect format in YYYY-MM-DD
    endDate: "",  // e.g. YYYY-MM-DD

    // Budget for automatic campaign (input by user)
    // Note: required is automatic campaign selected.
    campaignBudgetAutomatic: '', 

    // Budget for manual campaign (input by user)
    // Note: Required if manual campain selected
    campaignBudgetManual: '', 

    // Suggested keyword result
    // We fetch suggested keyword for asin via api and fillup result here.
    // We will render this keyword with checkbox, so user can select it.
    // e.g. suggestedKeywords: [
    //   {
    //     keywordText: "Keyword1",
    //     matchType: "broad",
    //   }
    // ]
    suggestedKeywords: null,
    
    // Keyword selected from suggested keyword list will be stored here.
    // And manually added keyword also stored here, both keyword are 
    // separated by 'source' field as explained below.
    // 
    // source: 'suggested' (Taken from suggested keyword list) 
    //          'manual'  (Manually added by user) 
    // e.g. 
    // selectedKeywords: [
    //   {
    //     keywordText: "Keyword1",
    //     matchType: "broad",
    //     source: 'suggested',
    //   }
    // ],
    selectedKeywords: [],

    // We will show textbox and add button to create keyword list manually. 
    // This variable will store manually added (single) keyword text temporary until
    // user tap the add button. So once use tap add button we will add this 
    // text within selected keyword list.
    manualKeywordText: '',

    // flow mode
    // edit - Edit mode i.e. allow to edit the input field etc
    // review - Review mode i.e. Do not allow to edit any input field.
    mode: 'edit',

    // End: ---- Form input data ----


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [], 


    // If any error occur during some operation we will fillup error info here.
    status: '', 
    error: '', 

    // Some processing in progress or not.
    isProcessing: false, 

    // Message text
    showMessage: false,
    messageText: "",
    errorMessage: "",

    // Start: ---- Data generated during flow creation ---- 
    
    // String: DocId generated by firebase db whenever we create Automatic and manual flow.
    flowDocId: '', 

    // Below variable used for automatic campaign creation flow
    autoCampaignId: 0, // Number: Campaign Id generated (for Automatic Campaign flow)
    autoAdGroupId: 0,  // Number: Ad Group Id generated (for Automatic Campaign flow)

    // Below variable used for Manual campaign creation flow
    manualCampaignId: 0,      // Number: Campaign Id generated (for Manual Campaign Flow)
    manualBroadAdGroupId: 0,  // Number: Ad Group Id Generated (for Manual Campaign Flow)
    manualPhraseAdGroupId: 0, // Number: Ad Group Id Generated (for Manual Campaign Flow)
    manualExactAdGroupId: 0,  // Number: Ad Group Id Generated (for Manual Campaign Flow)

    // Below capital letter variable used during flow creation steps.
    // Before we start creating flow (either auto or manual) we will
    // set this variable to default state as under and then we will 
    // set value true for the task that is done. So based on this 
    // we can decide that all task is done or not.
    SHOULD_CREATE_AUTO_FLOW: false, 
    SHOULD_CREATE_MANUAL_FLOW: false,

    AUTO_CAMPAIGN_CREATED: false,
    AUTO_ADGROUP_CREATED: false, 
    AUTO_NEGATIVE_KEYWORDS_CREATED: false, 
    AUTO_PRODUCT_AD_CREATED: false, 
    AUTO_FLOW_ALL_DONE: false,

    MANUAL_CAMPAIGN_CREATED: false, 
    MANUAL_BROAD_ADGROUP_CREATED: false, 
    MANUAL_PHRASE_ADGROUP_CREATED: false, 
    MANUAL_EXACT_ADGROUP_CREATED: false, 
    MANUAL_KEYWORDS_CREATED: false, 
    MANUAL_NEGATIVE_KEYWORDS_CREATED: false, 
    MANUAL_PRODUCT_AD_CREATED: false,
    MANUAL_FLOW_ALL_DONE: false,
    // End: ---- Data generated during flow creation ---- 
  }

  componentDidMount = () => {
    console.log('SpAdAutomationUi - componentDidMount()');

    // 1 - Set default value for campaign start, end date
    const startDate = getFutureDate_YYYYMMDD(1); // current date + 1 day
    const endDate = getFutureDate_YYYYMMDD(7);   // current date + 7 day
    this.setState({
      startDate: startDate,
      endDate: endDate, 
      
      //flowName: 'Flow5',
      //campaignBudgetAutomatic: 10,
      //campaignBudgetManual: 11,
    });

    // 2 - Fetch amazon user profile info
    this.fetchAmazonUserProfile();

    // 3 - Fetch amazon profile list for current user
    this.fetchProfileList();

    //console.log('this.state.API_MODE: ', this.state.API_MODE);
  }

  componentWillUnmount = () => {
    console.log('SpAdAutomationUi - componentWillUnmount()');
  }

  //-----------------------------------------------------------------
  // Start: Fetch amazon user profile info
  //-----------------------------------------------------------------
  fetchAmazonUserProfile = () => {
    // 1 - Call api to get amazon user profile (name, email, user_id)
    getAmazonUserProfile(this.getAmazonUserProfile_Success, this.getAmazonUserProfile_Error);
  }
   
  // Amazon user profile fetched successfully
  // e.g. result: { 
  //   status: 'success', 
  //   data: { 
  //     user_id: 'amzn1.account.AG3OPETKMBVUEROxxxxxxxxxxxx',
  //     name: 'Firstname Lastname',
  //     email: 'email@gmail.com' 
  //   }
  //   error: null 
  // }  
  getAmazonUserProfile_Success = (result) => {
    console.log('getAmazonUserProfile_Success()');
    //console.log('getAmazonUserProfile_Success() result:', result);
     
    if (result.status === 'success') {
      const { user_id, email } = result.data;
      this.setState({
        amazonUserId: user_id,
        amazonEmail: email,
      });
    }
     
    if (result.status === 'error') {
      this.setState({
        messageText: result.error,
        showMessage: true,
      });
    }
  }  

  // Error while fetching profile list
  getAmazonUserProfile_Error = (error) => {
    console.log('getAmazonUserProfile_Error() error:', error);

    this.setState({
      messageText: error.toString(),
      showMessage: true,
      //isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch amazon user profile info
  //-----------------------------------------------------------------



  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass the profile Id to get campain list api, so we 
  // first will fetch profile list and fillup drop down, so user 
  // can choose profile from there and click Get Camapaign List button
  // to fetch campain list for the selected profile.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    console.log('getProfiles_Success()');
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // START: Seach product list
  //-----------------------------------------------------------------
  // Called when search product button tapped 
  onClickSearchProduct = () => {
    //console.log('onClickSearchProduct()');
    
    const { market, searchProductBy, searchProductValues, selectedProducts } = this.state;
    console.log('searchProductBy: ', searchProductBy);
    console.log('searchProductValues: ', searchProductValues);

    // 1 - Call amazon api ListMatchingProduct
    if (searchProductBy === 'SearchQuery') {
      console.log('Call amazon api ListMatchingProduct');

      // 1.1 - Set processing on 
      // Also keep the selected product within a list.
      this.setState({
        searchProductResults: selectedProducts,
        isProcessing: true,
        productConfirmed: false,
      });

      // 1.2 - Prepare data to pass the api
      const query = searchProductValues;   // Search terms 'Pen Drive'

      // 1.3 - Call api
      listMatchingProducts(query, market, this.listMatchingProductsSuccess, this.listMatchingProductsError);
    }

    // OR 
    // 2 - Call amazon api GetMatchingProductForId
    if (searchProductBy === 'SellerSKU' || searchProductBy === 'ASIN') {
      console.log('Call amazon api GetMatchingProductForId');

      // 2.1 - Prepare data to pass the apo
      const idType = searchProductBy;
      const idValues = searchProductValues;  // e.g. 'asin1,asin2,asin3' etc.

      // 2.2 - Set processing on 
      this.setState({
        searchProductResults: selectedProducts,
        isProcessing: true,
        productConfirmed: false,
      });

      // 2.3 - Call api
      getMatchingProductForId(market, idType, idValues, this.getMatchingProductForId_Success, this.getMatchingProductForId_Error);
    }

  }

  // Called when api call success (listMatchingProducts)
  // e.g. Sample Data 
  // data = {
  //  Products: { 
  //    Product: [ {Object}, {Object}, ... ]
  //  },
  //  ResponseMetadata: { RequestId: '5b8c173c-7476-4c42-8381-e9241b5d7b78' },
  //  Headers: { 
  //    'x-mws-quota-max': '720.0',
  //    'x-mws-quota-remaining': '720.0',
  //    'x-mws-quota-resetson': '2020-07-13T08:38:00.000Z',
  //    'x-mws-timestamp': '2020-07-13T08:34:01.802Z',
  //    'content-type': 'text/xml',
  //    'content-charset': 'unknown',
  //    'content-length': '23738',
  //    'content-md5': 'unknown',
  //    date: 'Mon, 13 Jul 2020 08:34:01 GMT' 
  //  }
  // }
  listMatchingProductsSuccess = (result) => {
    console.log("listMatchingProductsSuccess() result:", result);

    // If error then show error messsage and return
    const { status, error } = result;
    if ( status === 'error' ) { 
      this.setState({
        messageText: error,
        showMessage: true, 
        isProcessing: false, 
      });
      return; // Important
    }

    // 1 - Read data from result
    const { data } = result;
    //console.log('data.Products.Product:', data.Products.Product);

    // 2 - Convert received result to general data format.
    const searchProductResultsNew = this.prepareProductResult(data.Products.Product);

    // 3 - If product not exist within result then show message and return.
    if ( searchProductResultsNew.length === 0 ) {
      this.setState({
        messageText: 'Product not found for search criteria',
        showMessage: true,
        isProcessing: false,
      });
      return;
    }

    // 4 - Prepare data for searchProductResults.
    // 4.1 - Clean up existing searchProductResults.
    // 4.2 - Set selected products as default list.
    // 4.3 - Append newly searched product within it (Do not append asin if already exist)
    const { selectedProducts } = this.state; 
    var searchProductResultsFinal = [ ...selectedProducts ]; // 4.1 and 4.2
    searchProductResultsNew.forEach( (product, index) => {  // 4.3
      const alreadyExist = this.isAsinExistInSelectedProducts(product.ASIN);
      if ( !alreadyExist ) {
        searchProductResultsFinal.push(product);
      }
    });

    // 5 - Udpate value within state
    this.setState({
      searchProductResults: searchProductResultsFinal,
      isProcessing: false,
    });
  }

  // Called if error while api call (listMatchingProducts)
  listMatchingProductsError = (error) => {
    console.log("listMatchingProductsError() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }

  // Called when api call success
  getMatchingProductForId_Success = (result) => {
    console.log("getMatchingProductForId_Success() result:", result);

    // If error then show error messsage and return
    const { status, error } = result;
    if ( status === 'error' ) { 
      this.setState({
        messageText: error,
        showMessage: true, 
        isProcessing: false, 
      });
      return; // Important
    }


    // 1 - Read data from result
    const { data } = result;

    // 2 - If multiple product then data will be array of products
    var productsTemp = [];
    if ( Array.isArray(data) ) { // Array
      data.forEach( (singleData, index) => {
        if (singleData.Products) {
          if (singleData.Products.Product) {
            let product = { ...singleData.Products.Product };
            // if IdType is SellerSKU then take value from there, otherwise keep SellerSKU empty.
            if ( singleData.IdType === 'SellerSKU' ) { 
              product['SellerSKU'] = singleData.Id;
            } else {
              product['SellerSKU'] = '';
            }
            productsTemp.push(product);
          }
        }
      });
    } else { // Not array
      if (data.Products) { 
        let product = { ...data.Products.Product };
        // if IdType is SellerSKU then take value from there, otherwise keep SellerSKU empty.        
        if ( data.IdType === 'SellerSKU' ) {
          product['SellerSKU'] = data.Id;
        } else {
          product['SellerSKU'] = '';
        }
        productsTemp = [product];
      }
    }
    console.log('productsTemp:', productsTemp);

    // 3 - Convert received result to general data format.    
    const searchProductResultsNew = this.prepareProductResult(productsTemp);

    // 4 - If product not exist within result then show message and return.
    if ( searchProductResultsNew.length === 0 ) {
      this.setState({
        messageText: 'Product not found for search criteria',
        showMessage: true,
        isProcessing: false,
      });
      return;
    }

    // 5 - Prepare data for searchProductResults.
    // 5.1 - Clean up existing searchProductResults.
    // 5.2 - Set selected products as default list.
    // 5.3 - Append newly searched product within it (Do not append asin if already exist)
    const { selectedProducts } = this.state;    
    var searchProductResultsFinal = [ ...selectedProducts ]; // 5.1 and 5.2
    searchProductResultsNew.forEach( (product, index) => {  // 5.3
      const alreadyExist = this.isAsinExistInSelectedProducts(product.ASIN);
      if ( !alreadyExist ) {
        searchProductResultsFinal.push(product);
      }
    });

    // 6 - Udpate value within state
    this.setState({
      searchProductResults: searchProductResultsFinal,
      isProcessing: false,
    });
  }

  // Called if error while api call
  getMatchingProductForId_Error = (error) => {
    console.log("getMatchingProductForId_Error() error: ", error);

    this.setState({
      isProcessing: false,
    });
  }

  // Convert received result to required data format.
  // Note: Mws api will return results in complex format, so we have to grab the 
  // required data from the result and prepare our own generlise data, So we can 
  // use those data while render product list etc.
  prepareProductResult = (products) => {
    console.log('prepareProductResult() products:', products);

    // 1 - Convert received products in to generalise format
    var productList = []; 
    products.forEach( (product, index) => {
      const { ASIN, MarketplaceId } = product.Identifiers.MarketplaceASIN;
      //const { SalesRank } = product.SalesRankings;
      const { ItemAttributes } = product.AttributeSets;
      const { Title, SmallImage } = ItemAttributes;
      
      // If SellerSKU field exist within data then grab its value, otherwise keep empty.
      let SellerSKU = '';
      if (product.SellerSKU) { SellerSKU = product.SellerSKU; }

      // Prepare product data in generalise format
      const data = { 
        SellerSKU: SellerSKU,
        ASIN: ASIN, 
        MarketplaceId: MarketplaceId, 
        Title: Title,
        SmallImage: SmallImage,
        productCost: '',   // Will be entered by user (Wholesale/Manufacturing + Shipping cost)
      }

      // Insert to array
      productList.push(data);
    });

    // Debug
    console.log('productList:', productList);

    // 2 - Return product list in generalised format
    return productList;
  }

  // Is given asin exist within selected product or not, 
  // Return true - If asin exist, otherwise false
  isAsinExistInSelectedProducts = (asin) => {
    var isExist = false;
    this.state.selectedProducts.forEach( (product, index) => {
      if (product.ASIN === asin) {
        isExist = true;
      }
    });
    return isExist;
  }
  //-----------------------------------------------------------------
  // END: Seach product list
  //-----------------------------------------------------------------
  

  //-----------------------------------------------------------------
  // START: Fetch suggested keyword list for asins
  //-----------------------------------------------------------------
  fetchSuggestedKeywordListForAsins = () => {
    //console.log('fetchSuggestedKeywordListForAsins()');

    // 1 - Get profileId from the state
    const { profileId, selectedProducts } = this.state;

    // 2 - Prepare array of asins 
    // e.g. asinArray = [ 'asin1', 'asin2' ] 
    const asinArray = [];
    selectedProducts.forEach( (item, index) => {
      asinArray.push(item.ASIN);
    });
    console.log('asinArray:', asinArray);

    // 3A - If asin array empty then return.
    if ( !asinArray || asinArray.length === 0 ) {
      console.log('Asin list empty, so can not proceed');
      return;
    }

    // 4 - Show processing
    this.setState({
      isProcessing: true,
      suggestedKeywords: null,
      error: '',
    });

    // 5 - Call api to get suggested keyword list for asins
    // @profileId: Number
    // @asinArray Array[String] e.g. [ 'asin1', 'asin2', ... ]
    // @maxNumSuggestions Integer (1-1000 ) default: 100
    const maxNumSuggestions = 50;
    spGetSuggestedKeywordsForAsins(profileId, asinArray, maxNumSuggestions, this.spGetSuggestedKeywordsForAsins_Success, this.spGetSuggestedKeywordsForAsins_Error);
  }

  // Called if suggested keyword list fetched successfully
  // e.g. result: { 
  //   status: 'success', 
  //   data: [
  //     {
  //       "keywordText": "string",
  //       "matchType": "exact"
  //     }
  //   ],
  //   error: null
  // }
  spGetSuggestedKeywordsForAsins_Success = (result) => {
    console.log('spGetSuggestedKeywordsForAsins_Success() result:', result);

    if ( result.status === 'success' ) {
      this.setState({
        suggestedKeywords: result.data,
        isProcessing: false,
      });
    }

    if ( result.status === 'error' ) {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }

  //-----------------------------------------------------------------
  // END: Fetch suggested keyword list for asins
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Create flow related functions 
  //-----------------------------------------------------------------
  
  // Whenever clicked edit flow button, this function called.
  // ie. We have to set the edit mode.
  onClickEditFlowButton = () => {
    this.setState({
      mode: 'edit',
    });
  }

  // Once flow created we will show 'OK' button to reset the all variable
  // used within flow, so user can start again to create new flow.
  onClickOkButton = (e) => {
    e.preventDefault();
    console.log('onClickOkButton()');

    this.setState({
      runAdFor: '',
      profileId: '',
      market: '',
      searchProductBy: '',
      searchProductValues: '',
      searchProductResults: null, 
      selectedProducts: [],
      productConfirmed: false,
      
      automaticCampaignChecked: true,
      broadAdGroupChecked: true,
      phraseAdGroupChecked: false,
      exactAdGroupChecked: true,
      
      bidAmountAutomatic: 0.5,
      bidAmountBroad: 0.5,
      bidAmountPhrase: 0.5,
      bidAmountExact: 0.8,
      
      flowName: '',
      campaignBudgetAutomatic: '', 
      campaignBudgetManual: '', 
      
      suggestedKeywords: null,
      selectedKeywords: [],
      mode: 'edit',
      
      flowDocId: '', 

      autoCampaignId: 0, 
      autoAdGroupId: 0, 

      manualCampaignId: 0,
      manualBroadAdGroupId: 0,
      manualPhraseAdGroupId: 0,
      manualExactAdGroupId: 0,
  
      SHOULD_CREATE_AUTO_FLOW: false,
      SHOULD_CREATE_MANUAL_FLOW: false,

      AUTO_CAMPAIGN_CREATED: false,
      AUTO_ADGROUP_CREATED: false,
      AUTO_NEGATIVE_KEYWORDS_CREATED: false,
      AUTO_PRODUCT_AD_CREATED: false,
      AUTO_FLOW_ALL_DONE: false,

      MANUAL_CAMPAIGN_CREATED: false, 
      MANUAL_BROAD_ADGROUP_CREATED: false, 
      MANUAL_PHRASE_ADGROUP_CREATED: false, 
      MANUAL_EXACT_ADGROUP_CREATED: false, 
      MANUAL_KEYWORDS_CREATED: false, 
      MANUAL_NEGATIVE_KEYWORDS_CREATED: false, 
      MANUAL_PRODUCT_AD_CREATED: false,
      MANUAL_FLOW_ALL_DONE: false, 
    });

    // Inform parent component that flow created, so parent component 
    // will refresh flow list to show newly created flow record.
    if ( this.props.onFlowCreated ) {
      this.props.onFlowCreated();
    }

  }

  // Cancel button clicked (close icon) at top right side of component.
  // So we have to close this component. So we will inform parent 
  // component that user clicked cancel button, so parenet component
  // will unload this child.
  onClickCancel = () => {
    console.log('onClickCancel() ');

    if (this.props.onFlowCancel) {
      this.props.onFlowCancel();
    }

  }


  // This function will reset all variale that is used in flow creation.
  resetFlowCreationStatus = () => {
    console.log('resetFlowCreationStatus()');

    this.setState({

      flowDocId: '', 

      autoCampaignId: 0, 
      autoAdGroupId: 0, 

      manualCampaignId: 0,
      manualBroadAdGroupId: 0,
      manualPhraseAdGroupId: 0,
      manualExactAdGroupId: 0,

      SHOULD_CREATE_AUTO_FLOW: false,
      SHOULD_CREATE_MANUAL_FLOW: false,

      AUTO_CAMPAIGN_CREATED: false,
      AUTO_ADGROUP_CREATED: false,
      AUTO_NEGATIVE_KEYWORDS_CREATED: false,
      AUTO_PRODUCT_AD_CREATED: false,
      AUTO_FLOW_ALL_DONE: false,

      MANUAL_CAMPAIGN_CREATED: false,
      MANUAL_BROAD_ADGROUP_CREATED: false,
      MANUAL_PHRASE_ADGROUP_CREATED: false,
      MANUAL_EXACT_ADGROUP_CREATED: false,
      MANUAL_KEYWORDS_CREATED: false, 
      MANUAL_NEGATIVE_KEYWORDS_CREATED: false, 
      MANUAL_PRODUCT_AD_CREATED: false,
      MANUAL_FLOW_ALL_DONE: false,
    });
  }


  // Whenever user click Confirm-Create button this function called.
  onClickConfirmCreateButton = (e) => { 
    e.preventDefault();
    console.log('onClickConfirmCreateButton()');

    const { runAdFor } = this.state;

    // 1 - If runAdFor = 'seller' then Seller SKU list need to continue, 
    // If seller sku list empty then return.
    if ( runAdFor === 'seller') {
      const sellerSkuArray = this.getSellerSkuArrayForSelectedProducts();
      console.log('sellerSkuArray:', sellerSkuArray);

      if ( sellerSkuArray.length === 0 ) {
        const msg = 'SellerSKU list require for seller profile, Please select some product that have SellerSKU.';
        this.setState({
          messageText: msg,
          showMessage: true,
          errorMessage: msg,
        });

        return; // IMPORTANT
      }
    }

    // 2 - if runAdFor = 'vendor' then ASIN need to continue, So if asins list empty then return.
    if (runAdFor === 'vendor') { 
      const asinArray = this.getAsinArrayForSelectedProducts();
      console.log('asinArray:', asinArray);

      if ( asinArray.length === 0 ) {
        const msg = 'ASIN list require for vendor profile, Please select product that have ASIN.';
        this.setState({
          messageText: msg,
          showMessage: true,
          errorMessage: msg,
        });

        return; // IMPORTANT
      }
    }

    // 3 - Set processing on 
    this.setState({
      isProcessing: true,
    });

    // 4 - Reset variable to default state that maintain flow creation steps
    this.resetFlowCreationStatus();

    // 5 - trigger logic to create automatic and manual campaign
    this.createNewFlow();
  }

  
  // We will create flow for sponsored product using server side api.
  // We will pass necessary data to server side api, so it will take care 
  // to create automatic and manual campaign related flow based on the given data.
  createNewFlow = async () => {

    // 1 - Fetch current logged in user uid
    //const uid = await firebase.auth().currentUser.uid;
    //console.log('uid:', uid);

    // 2 - Fetch data from state and prepare it in required format.
    const { profileId, runAdFor } = this.state;
    const profileType = runAdFor;  // e.g. 'vendor' or 'seller'

    // 2A - Prepare product list to pass as the api.
    const productList = this.getSelectedProductListForDb(); 
    
    // 2B - Decide that which type of flow needs to create (automatic and/or manual)
    const { automaticCampaignChecked, broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked } = this.state;
    const shouldCreateAutoFlow = automaticCampaignChecked;
    const shouldCreateManualFlow = (broadAdGroupChecked || phraseAdGroupChecked || exactAdGroupChecked) ? true : false;
    
    // 2C - Prepare campaign start and end date in format that server side endpoint expect.
    // i.e. remove '-' character from the date
    const { startDate, endDate } = this.state;
    const startDateFinal = startDate.replace(/-/g, "");     // e.g. 20201021
    const endDateFinal = endDate.replace(/-/g, "");         // e.g. 20201028

    // 2D - Prepare keywords array to pass the server side endpoint.
    // e.g. Array ['keyword1', 'keyword2', ... ] 
    const { selectedKeywords } = this.state;
    const selectedKeywordsFinal = selectedKeywords.map( (item, index) => { 
      return item.keywordText;
    });


    // 3 - Prepare data to pass the api, it will be used to create flow for sponsored product.
    // Important: Below data structure should be passed, otherwise server side api will not 
    // able to create automatic or manual flow properly.
    // apiCallMode = 'DUMMY' means i.e. Emitate amazon ad api call at server side (Used During Development).
    // apiCallMode = 'REAL' i.e. Call real amazon ad api at server side.
    const { flowName, amazonEmail, amazonUserId, API_MODE, market } = this.state;
    const marketplaceId = marketPlaces[market].market_place_id; // Fetch marketplace id for market (e.g. market='CA')
    const flowData = {
      apiCallMode: API_MODE,                          // 'DUMMY' or 'REAL'
      flowName: flowName,                             // e.g. 'Flow1'
      amazonEmail: amazonEmail,                       // Amazon logged in 'email id'
      amazonUserId: amazonUserId,                     // Amazon logged in 'user id'
      products: productList,                          // Array of product info []
      shouldCreateAutoFlow: shouldCreateAutoFlow,     // true or false
      shouldCreateManualFlow: shouldCreateManualFlow, // true or false 
      startDate: startDateFinal,                      // Campaign start date (auto and manual)
      endDate: endDateFinal,                          // Campaign end date (auto and manual)
      keywords: selectedKeywordsFinal,                // e.g. Array ['keyword1', 'keyword2', ... ]
      market: market,                                 // e.g. 'CA'
      marketplaceId: marketplaceId,                   // e.g. 'A2EUQ1WTGCTBG2'
    }
    
    // 3A - Add key:value data related to automatic flow creation
    if (shouldCreateAutoFlow) {
      const { campaignBudgetAutomatic, bidAmountAutomatic } = this.state;
      flowData['dailyBudgetAuto'] = parseFloat(campaignBudgetAutomatic);
      flowData['defaultBidAuto'] = parseFloat(bidAmountAutomatic);
    }

    // 3B - Add key:value data related to manual flow creation
    if (shouldCreateManualFlow) {
      const { campaignBudgetManual, bidAmountBroad, bidAmountPhrase, bidAmountExact } = this.state;
      flowData['dailyBudgetManual'] = parseFloat(campaignBudgetManual);
      flowData['shouldCreateBroadAdGroup'] = broadAdGroupChecked;   // true or false
      flowData['shouldCreatePhraseAdGroup'] = phraseAdGroupChecked; // true or false
      flowData['shouldCreateExactAdGroup'] = exactAdGroupChecked;   // true or false
      if (broadAdGroupChecked) {
        flowData['defaultBidBroad'] = parseFloat(bidAmountBroad);
      } 
      if (phraseAdGroupChecked) {
        flowData['defaultBidPhrase'] = parseFloat(bidAmountPhrase);
      } 
      if(exactAdGroupChecked){
        flowData['defaultBidExact'] = parseFloat(bidAmountExact);
      }
    } else { 
      // We are using below field at server side api for decision making while creating the 
      // negative keyword for Auto, Broad, Phrase Ad Group, so we must create some value.
      // Note: It will consider as null if we do not create some value, but it is not good 
      // idea to go with null value for decision making field. So If manual flow not creation 
      // not need then we will set all three value as false. 
      flowData['shouldCreateBroadAdGroup'] = false;
      flowData['shouldCreatePhraseAdGroup'] = false;
      flowData['shouldCreateExactAdGroup'] = false;
    }

    // Debug
    console.log('flowData:', flowData);

    // 4 - Call server side api to create flow for sponsored product.
    createFlowSponsoredProduct(profileId, profileType, flowData, this.createFlowSponsoredProduct_Success, this.createFlowSponsoredProduct_Error)
  }

  // Once response received from server side endpoint, it will run this callback.
  createFlowSponsoredProduct_Success = (response) => {
    console.log('createFlowSponsoredProduct_Success() response:', response);

    // 1 - If success status received from server endpoint
    if ( response.status === 'success' ) {
      // 1 - Stop processing
      this.setState({
        isProcessing: false,
      });

      // 2 - Inform parent component that flow created, so parent component 
      // will refresh flow list to show newly created flow record.
      if ( this.props.onFlowCreated ) {
        this.props.onFlowCreated();
      }
    }

    // 2 - If error status received from server endpoint.
    if ( response.status === 'error' ) {
      this.setState({
        messageText: 'Error while creating flow',
        showMessage: true,
        isProcessing: false
      });
    }

  }


  // If any error while calling server side api, this function called.
  createFlowSponsoredProduct_Error = (error) => {
    console.log('createFlowSponsoredProduct_Error() error:', error);

    this.setState({
      messageText: 'Error while creating flow, please try again.',
      showMessage: true,
      isProcessing: false
    });
  }


  // This function wil return seller sku array from selected product list.
  getSellerSkuArrayForSelectedProducts = () => {
    const { selectedProducts } = this.state;
    const sellerSkuArray = []; // e.g. ['sku1', 'sku2'] 
    selectedProducts.forEach( (item, index) => { 
      if( item.SellerSKU !== '' ) { 
        sellerSkuArray.push(item.SellerSKU); 
      }
    });
    return sellerSkuArray; // e.g. ['sku1', 'sku2'] 
  }

  // This function wil return asin array from selected product list.  
  getAsinArrayForSelectedProducts = () => {
    const { selectedProducts } = this.state;
    const asinArray = []; // e.g. ['asin1', 'asin2'] 
    selectedProducts.forEach( (item, index) => { 
      if( item.ASIN !== '' ) { 
        asinArray.push(item.ASIN);
      } 
    });
    return asinArray; // e.g. ['asin1', 'asin2'] 
  }

  // Create product list to save within db.
  // There are multiple field within selected product list, but we will 
  // use the field that need in future and related fields.
  getSelectedProductListForDb = () => {
    const { selectedProducts, market } = this.state;

    const productArray = [];
    selectedProducts.forEach( (item, index) => { 
      const product = {
        SellerSKU: item.SellerSKU,
        ASIN: item.ASIN, 
        MarketplaceId: item.MarketplaceId,
        productCost: parseFloat(item.productCost),
        market: market, 
      }
      productArray.push(product);
    });

    return productArray;
  }
  //-----------------------------------------------------------------
  // End: Create flow related functions 
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Create automatic flow
  //-----------------------------------------------------------------
  // For automatic flow we need to create below things
  // STEP-1: Create automatic campaign within amazon, and save auto_campaign_id within db.
  // STEP-2: Create ad group within amazon, and save auto_ad_group_id within db.
  // STEP-3: Create negative exact keyword within amazon. (No need to save any info within db)
  // STEP-4: Create product ad (using campaignId, groupId) within amazon. (No need to save any info within db)

  // Note: We are creating flow creation logic at server side, so code removed from here.

  //-----------------------------------------------------------------
  // End: Create automatic flow
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Create manual flow
  //-----------------------------------------------------------------
  // For automatic flow we need to create below things
  // STEP-1: Create manual campaign within amazon, and save manual_campaign_id within db.
  // 
  // STEP-2: 
  //  STEP-2.1: Create Broad Ad Group in amazon, and save manual_broad_ad_group_id within db.
  //  STEP-2.2: Create Phrase Ad Group in amazon, and save manual_phrase_ad_group_id within db.
  //  STEP-2.3: Create Exact Ad Group in amazon, and save manual_exact_ad_group_id within db.
  //   
  // STEP-3: 
  //  STEP-3.1A: Create Keywords for Broad match (using manual_broad_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //  STEP-3.1B: Create Negative Keywords for Negative Exact match (using manual_broad_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  // 
  //  STEP-3.2A: Create Keywords for Phrase match (using manual_phrase_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //  STEP-3.2B: Create Negative Keywords for Negative Exact match (using manual_phrase_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //
  //  STEP-3.3: Create Keywords for Exact match (using manual_exact_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //
  // STEP-4: 
  //  STEP-4.1: Create Product Ad for Broad Ad Group. (using manual_broad_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  //  STEP-4.2: Create Product Ad for Phrase Ad Group. (using manual_phrase_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  //  STEP-4.3: Create Product Ad for Exact Ad Group. (using manual_exact_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]

  // Note: We are creating flow creation logic at server side, so code removed from here.

  //-----------------------------------------------------------------
  // End: Create manual flow
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // START: Rendering related function 
  //-----------------------------------------------------------------
  // When user will select run ad for radio button, this function be called. 
  handleChangeRunAdFor = (value) => {
    console.log('handleChangeRunAdFor() value: ', value);

    // Set selected value within state, and clear dependable 
    // variable so user can select those variable again within a flow.
    this.setState({
      runAdFor: value, 
      profileId: '', 
      market: '', 
      searchProductBy: '', 
      searchProductValues: '', 
      searchProductResults: null, 
    });

  };

  // This function called whenever user will select option from search by drop down.
  handleChangeSearchProductBy = (value) => {
    this.setState({
      searchProductBy: value,
      searchProductValues: '',
    });
  }

  // This function called whenever user will select market from dropdown.
  onSelectMarket = (value) => {
    //console.log('onSelectMarket() value:', value);

    this.setState({
      market: value,
      searchProductResults: null,
      selectedProducts: [],
    });
  }

  // If necessary input not valid then disable search button.
  shouldDisableSearchButton = () => {
    const { isProcessing, market, searchProductBy, searchProductValues, mode } = this.state;

    var isDisabled = false;

    // 1 - If necessary value empty then disable button
    if ( isProcessing || mode === 'review' || market === '' || searchProductBy === '' || searchProductValues === '' ) { 
      isDisabled = true;
      return isDisabled;
    }

    // 2 - If searchProductBy ASIN or SellerSKU, then do not allow more than 5 comma separated value.
    if ( searchProductBy === 'ASIN' || searchProductBy === 'SellerSKU' ) {
      // e.g. searchProductValues = 'asin1,asin2,asin3,asin4,asin5'
      const listArray = searchProductValues.split(',');
      
      // Disable search button if more than 5 comma separated value exist.
      if ( listArray.length > 5 ) { 
        isDisabled = true;
        return isDisabled;
      }
    }

    // 3 - Return status
    return isDisabled;
  }

  // Called whenever user click Confirm Product button after selecting product.
  onClickConfirmProduct = () => {
    //console.log('onClickConfirmProduct()');
    
    // 1 - Render selected products within searchProductResults.
    const { selectedProducts } = this.state;
    const searchProductResultsNew = [ ...selectedProducts ];
    
    this.setState({
      productConfirmed: true, 
      searchProductResults: searchProductResultsNew,
      suggestedKeywords: null,
      selectedKeywords: [],
    });

    // 2 - Fetch suggested keyword for selected products (asins) and 
    // render suggested keyword list within flow.
    this.fetchSuggestedKeywordListForAsins();
  }

  // Called when automatic camapaign checkbox toggle done.
  handleChangeAutomatic = (isChecked) => {
    //console.log('handleChangeAutomatic() isChecked:', isChecked);

    this.setState({
      automaticCampaignChecked: isChecked,
    });
  }

  // Called when Broad Ad Group checkbox toggle done.
  handleChangeBroadAdGroup = (isChecked) => {
    //console.log('handleChangeBroadAdGroup() isChecked:', isChecked);

    this.setState({
      broadAdGroupChecked: isChecked,
    });
  }

  // Called when Phrase Ad Group checkbox toggle done.  
  handleChangePhraseAdGroup = (isChecked) => {
    //console.log('handleChangePhraseAdGroup() isChecked:', isChecked);

    this.setState({
      phraseAdGroupChecked: isChecked,
    });
  }

  // Called when Exact Ad Group checkbox toggle done.    
  handleChangeExactAdGroup = (isChecked) => {
    //console.log('handleChangeExactAdGroup() isChecked:', isChecked);

    this.setState({
      exactAdGroupChecked: isChecked,
    });
  }

  // If input not valid, it will return true i.e. disable the button
  // If input is valid, it will return false i.e. enable button to go next step.  
  shouldDisableReviewInfoButton = () => {
    
    const { isProcessing, mode, flowName } = this.state;    
    
    // 1 - If processing in progress or review mode then disable button
    if ( isProcessing || mode === 'review' || flowName === '' ) {
      return true; // disable button
    }

    // 1A - If any selected product cost empty or zero then disable review info button
    const { selectedProducts } = this.state;
    let isProductCostEmpty = false;
    selectedProducts.forEach( (item, index) => {
      if ( item.productCost === '' || item.productCost <= 0 ) {
        isProductCostEmpty = true;
      }
    });
    if (isProductCostEmpty) {
      return true; // disable button
    }

    // 2 - If any keyword not seleccted then disable button
    const { selectedKeywords } = this.state;
    if ( selectedKeywords.length === 0 ) {
      return true; // disable button
    }
     
    // 3 - If any campaign not selected then disable button.
    const { automaticCampaignChecked, broadAdGroupChecked, 
            phraseAdGroupChecked, exactAdGroupChecked } = this.state;

    if ( automaticCampaignChecked === false && broadAdGroupChecked === false && 
          phraseAdGroupChecked === false && exactAdGroupChecked === false ) { 
      return true; // Disable button
    }

    // 4 - Automatic campaign selected but automatic budget not entered then disable button.
    // Note: Minimum campaign budget should be $5 
    const { campaignBudgetAutomatic, bidAmountAutomatic } = this.state;
    if ( automaticCampaignChecked && (campaignBudgetAutomatic === '' || campaignBudgetAutomatic < 5) ) {
      return true; // Disable button
    }
    // If automatic campaign selected, but automatic bid amount not entered then disable button.
    if ( automaticCampaignChecked && (bidAmountAutomatic === '' || bidAmountAutomatic < 0.02) ) {
      return true; // Disable button 
    }

    // 5 - Manual (Broad or Phrase or Exact) selected but manual budget not entered 
    // then disable button.
    // Note: Minimum campaign budget should be $5
    const { campaignBudgetManual } = this.state;
    if ( (broadAdGroupChecked || phraseAdGroupChecked || exactAdGroupChecked) 
              && ( campaignBudgetManual === '' || campaignBudgetManual < 5 ) ) { 
      return true; // Disable button 
    }

    // 6 - If respective bid amount not entered then disable button
    const { bidAmountBroad, bidAmountPhrase, bidAmountExact } = this.state;
    if ( broadAdGroupChecked && ( bidAmountBroad === '' || bidAmountBroad < 0.02) ) {
      return true; // Disable button 
    }
    if ( phraseAdGroupChecked && ( bidAmountPhrase === '' || bidAmountPhrase < 0.02) ) {
      return true; // Disable button 
    }
    if ( exactAdGroupChecked && ( bidAmountExact === '' || bidAmountExact < 0.02) ) {
      return true; // Disable button 
    }

    // 7 - Enable button
    return false;
  }


  // When review button tapped this function called.
  // i.e. We have to set the review mode.
  onClickReviewInfo = () => {
    
    this.setState({
      mode: 'review',
    });
  }

  renderUiForm = () => {
    const { classes } = this.props;
    const { profileList } = this.state;
    const { 
            API_MODE,
            amazonUserId, amazonEmail,
            runAdFor, profileId, market, 
            searchProductBy, searchProductValues, searchProductResults, 
            selectedProducts, productConfirmed, 
            automaticCampaignChecked, 
            broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked,
            bidAmountAutomatic, bidAmountBroad, bidAmountPhrase, bidAmountExact,
            flowName, 
            startDate, endDate,
            campaignBudgetAutomatic, campaignBudgetManual,
            suggestedKeywords, selectedKeywords,
            showMessage, messageText, errorMessage, 
            isProcessing, mode,
            
            flowDocId, autoCampaignId, autoAdGroupId,
            manualCampaignId, manualBroadAdGroupId, manualPhraseAdGroupId, manualExactAdGroupId, 
            
            SHOULD_CREATE_AUTO_FLOW, SHOULD_CREATE_MANUAL_FLOW,
            
            AUTO_CAMPAIGN_CREATED, 
            AUTO_ADGROUP_CREATED, AUTO_NEGATIVE_KEYWORDS_CREATED, AUTO_PRODUCT_AD_CREATED, 
            
            MANUAL_CAMPAIGN_CREATED,
            MANUAL_BROAD_ADGROUP_CREATED, MANUAL_PHRASE_ADGROUP_CREATED, MANUAL_EXACT_ADGROUP_CREATED,
            MANUAL_KEYWORDS_CREATED, MANUAL_NEGATIVE_KEYWORDS_CREATED, MANUAL_PRODUCT_AD_CREATED,
            
            AUTO_FLOW_ALL_DONE, MANUAL_FLOW_ALL_DONE,
          } = this.state;

    // If processin in progress or review mode on, then disable every control
    let isDisabled = false;
    if ( isProcessing || mode === 'review' || amazonUserId === '' || amazonEmail === '' ) { isDisabled = true; }
    

    // Search Product value label
    var searchProductLabel = 'Search';
    if ( searchProductBy === 'SearchQuery') { searchProductLabel = 'Search Query (e.g. Pen Drive)' }
    if ( searchProductBy === 'ASIN') { searchProductLabel = 'ASIN (max. 5 comma separated)' }
    if ( searchProductBy === 'SellerSKU') { searchProductLabel = 'Seller SKU (max. 5 comma separated)' }

    return (
      <Grid container spacing={2}>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="apimode-label">CHOOSE API MODE</InputLabel>
            <Select
              id="apimode"
              labelId="apimode-label"
              value={API_MODE}
              onChange={ (e) => { this.setState({API_MODE: e.target.value}) } }
              disabled={ isDisabled }
            >
              <MenuItem value="DUMMY" >DUMMY ( Do Dummy Call - Development Purpose )</MenuItem>
              <MenuItem value="REAL" >REAL ( Call Real Amazon Ad Api - Use Carefully )</MenuItem>
            </Select>
          </FormControl>
          <br /><br /><br />
        </Grid>


        <Grid item xs={12} sm={12}>
          <FormControl component="fieldset" >
            <FormLabel component="legend">Running Ad For:</FormLabel>
            <RadioGroup style={{ flexDirection: 'row' }} aria-label="runAdFor" name="runAdFor" value={runAdFor} onChange={ (e) => this.handleChangeRunAdFor(e.target.value) }>
              <FormControlLabel value="seller" control={<Radio color="primary" disabled={isDisabled} />} label="Own Product (Seller)" />
              <FormControlLabel value="vendor" control={<Radio color="primary" disabled={isDisabled} />} label="Other Product (Vendor)" />
            </RadioGroup>
          </FormControl>
        </Grid>

        { // Show profile select drop down if runAdFor value selected.
          // If runAdFor is 'seller' then we will show seller profile only.
          // If runAdFor is 'vendor' then we will show vendor profile only.
        runAdFor !== '' &&  
        <Grid item xs={12} sm={8}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-profile-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="choose-profile-label"
              value={profileId}
              onChange={(e) => this.setState({ profileId: e.target.value })}
              disabled={ isDisabled || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { id, type, name } = accountInfo;
                  if ( type === runAdFor ) {
                    return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {id} / {type} / {name}</MenuItem>)
                  } else {
                    return null;
                  }
                })
              }
            </Select>
          </FormControl>
        </Grid>
        }

        { // Show below ui element if runAdFor value exist (i.e. not empty)
        runAdFor !== '' &&  
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-market-place-label">Choose Marketplace</InputLabel>
            <Select
              id="market"
              labelId="choose-market-place-label"
              value={market}
              onChange={ (e) => this.onSelectMarket(e.target.value) }
              disabled={ isDisabled }
            >
              { // Dynamically generate options
                markePlacesKeys.map((key, index) => {
                  const market = marketPlaces[key];
                  return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                })
              }
            </Select> 
          </FormControl>  
        </Grid>  
        }

        { // Show below ui element if runAdFor value exist (i.e. not empty)
        runAdFor !== '' &&  
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl} >
            <InputLabel id="searchproductby-label">Search Product By</InputLabel>
            <Select
              id="searchProductBy"
              labelId="searchproductby-label"
              value={searchProductBy}
              onChange={ (e) => this.handleChangeSearchProductBy(e.target.value) }
              disabled={ isDisabled }
            >
              <MenuItem value="SellerSKU" >SellerSKU</MenuItem>
              { runAdFor === 'vendor' && <MenuItem value="ASIN" >ASIN</MenuItem> }
              { runAdFor === 'vendor' && <MenuItem value="SearchQuery" >Search Query</MenuItem> }
            </Select>
          </FormControl>  
        </Grid>
        }

        { // Show below ui element if runAdFor value exist (i.e. not empty)
        runAdFor !== '' &&  
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="seachProductTerms" 
              //label="Values (max5) Comma Separated" 
              label={ searchProductLabel }
              placeholder={ searchProductLabel }
              value={searchProductValues} 
              onChange={(e) => this.setState({ searchProductValues: e.target.value })} 
              size="small" 
              disabled={ isDisabled || searchProductBy === ''} 
              InputLabelProps={{ shrink: true, }}  
            />
          </FormControl>
        </Grid>
        }

        { // Show below ui element if runAdFor value exist (i.e. not empty)
        runAdFor !== '' &&  
        <Grid item xs={12} sm={2} align='right' >
          <Button 
            variant="contained" 
            size="small" 
            onClick={ (e) => this.onClickSearchProduct() } 
            //style={{ marginRight: 20 }}
            disabled={ this.shouldDisableSearchButton() } 
          >Search</Button>
        </Grid>
        } 

        { // If search product result exist then render product list with minimum information.
        searchProductResults !== null && 
        <Grid item xs={12} sm={12} >
          { searchProductResults.length > 0 &&
            <React.Fragment>
              <Typography variant="subtitle1" gutterBottom>
                Select product(s) from result:
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                You can select product from below, and search another product if need.
              </Typography>
            </React.Fragment>
          }

          { searchProductResults.length > 0 &&
            this.renderProductList()
          }

          { productConfirmed &&
            <h4>Note: Include wholesale, manufacturing, and shipping within product cost.</h4>
          }
        </Grid>
        }

        { // If some product selected show next button
          //searchProductResults && searchProductResults.length > 0 && 
          selectedProducts.length > 0 && productConfirmed === false &&
          <Grid item xs={12} sm={12} align='left' >
            <Button 
              variant="contained" 
              size="small" 
              onClick={ (e) => this.onClickConfirmProduct() } 
              //style={{ marginRight: 20 }} 
              disabled={ isDisabled || selectedProducts.length === 0 } 
            >Confirm Product</Button>
          </Grid>
        }  

        { 
          // productConfirmed && 
          // <Grid item xs={12} sm={12} align='left' >
          //   <br />
          // </Grid>
        } 

        { // Once product confirmed, show suggested keyword list.
        productConfirmed && 
        <Grid item xs={12} sm={6} >
          <Typography variant="subtitle1" gutterBottom>
            <b>Suggested keywords:</b>
          </Typography>

          { // If suggested keyword list empty then show message
          (suggestedKeywords && suggestedKeywords.length === 0) && 
            <Typography variant="subtitle1" gutterBottom>
              Suggested keywords not found for the products.
            </Typography>
          }

          { // If suggested keyword list exist then render it.
          ( suggestedKeywords && suggestedKeywords.length > 0) &&
            this.renderSuggestedKeywordList()
          }
        </Grid>
        }

        { // Once product confirmed, show selected keyword list
        productConfirmed && 
        <Grid item xs={12} sm={6} >
          <Typography variant="subtitle1" gutterBottom>
            <b>Selected keywords:</b>
          </Typography>
          
          { // Render form to add keyword manually
            this.renderManualKeywordAddForm() 
          }

          <br />

          { // If selected keyword list empty then show message
          selectedKeywords.length === 0 && 
            <Typography variant="subtitle1" gutterBottom>
              <br />
              Keywords not selected yet.<br />
              Please add from suggested keyword or manually.
            </Typography>
          }
          
          { // If selected keyword list exist then render it.
            selectedKeywords.length > 0 &&
            this.renderSelectedKeywordList()
          }
        </Grid>
        }

        <Grid item xs={12} sm={12} align='left' >
        </Grid>

        { productConfirmed && 
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl} > 
            <TextField 
              id="flow-name" 
              label="Flow Name" 
              placeholder="enter flow name" 
              value={ flowName } 
              onChange={ (e) => this.setState({ flowName: e.target.value }) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }}  
              helperText="Please write short and sweet name that indicates your products or very similar group of products."
            />
          </FormControl>
        </Grid>
        }

        { productConfirmed && 
        <Grid item xs={6} sm={3}>
          <form className={classes.formControl} >
            <TextField
              id="startDate"
              label="Campaign Start Date"
              type="date"
              value={ startDate }
              onChange={(e) => this.setState({ startDate: e.target.value })}
              //className={classes.textField}
              InputLabelProps={{ shrink: true, }}
              disabled={ isDisabled }
            />
          </form>
        </Grid>
        }

        { productConfirmed && 
        <Grid item xs={6} sm={3}>
          <form className={classes.formControl} >
            <TextField
              id="endDate"
              label="Campaign End Date"
              type="date"
              value={ endDate }
              onChange={(e) => this.setState({ endDate: e.target.value })}
              //className={classes.textField}
              InputLabelProps={{ shrink: true, }}
              disabled={ isDisabled }
            />
          </form>
        </Grid>
        }

        { productConfirmed && 
          <Grid item xs={12} sm={12} align='left' >
            <br />
          </Grid>
        } 

        { productConfirmed && 
        <Grid item xs={12} sm={6} align='left' >
          <FormControl component="fieldset" className={classes.formControl} >
            <FormLabel component="legend">Automatic Campaign</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={ 
                        <Checkbox
                          color="primary" 
                          checked={automaticCampaignChecked} 
                          onChange={(e) => this.handleChangeAutomatic(e.target.checked) } 
                          name="automatic"
                          disabled={isDisabled}
                        />
                        }
                label="Automatic Campaign (Recommended)"
              />
            </FormGroup>
          </FormControl>

          { automaticCampaignChecked &&
          <TextField 
            variant="outlined"
            id="bid-amount-automatic" 
            label="Bid Amount Automatic" 
            value={ bidAmountAutomatic } 
            onChange={ (e) => this.onChangeBidAmountAutomatic(e.target.value) } 
            size="small" 
            disabled={ isDisabled } 
            InputLabelProps={{ shrink: true, }} 
            InputProps={{ 
              startAdornment: <InputAdornment position="start">$</InputAdornment>, 
            }}
            helperText="Minimum $0.02"
            style={{ maxWidth: 170 }}
          />
          }

          <br /><br />

          { automaticCampaignChecked &&
          <FormControl className={classes.formControl} >
            <TextField 
              id="campaign-budget-automatic" 
              label="Automatic Campaign Daily Budget"
              placeholder="enter budget"
              value={ campaignBudgetAutomatic } 
              //onChange={ (e) => this.setState({ campaignBudgetAutomatic: e.target.value }) } 
              onChange={ (e) => this.onChangeCampaignBudgetAutomatic(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              helperText="Minimum $5.0"
            />
          </FormControl>
          }
        </Grid>
        }
        
        { productConfirmed && 
        <Grid item xs={12} sm={6} align='left' >
          <FormControl component="fieldset" className={classes.formControl} >
              <FormLabel component="legend">Manual Campaign</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox 
                      color="primary" 
                      checked={broadAdGroupChecked} 
                      onChange={ (e) => this.handleChangeBroadAdGroup(e.target.checked)} 
                      name="broad-ad-group" 
                      disabled={isDisabled}
                    />
                  }
                  label="Broad Ad Group (Recommended)"
                /> 

                { broadAdGroupChecked &&
                <TextField 
                  variant="outlined"
                  id="bid-amount-broad" 
                  label="Bid Amount Broad" 
                  value={ bidAmountBroad } 
                  onChange={ (e) => this.onChangeBidAmountBroad(e.target.value) } 
                  size="small" 
                  disabled={ isDisabled } 
                  InputLabelProps={{ shrink: true, }} 
                  InputProps={{ 
                    startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                  }}
                  helperText="Minimum $0.02"
                  style={{ maxWidth: 150 }}
                />
                }

                <FormControlLabel
                  control={
                    <Checkbox 
                      color="primary" 
                      checked={phraseAdGroupChecked} 
                      onChange={ (e) => this.handleChangePhraseAdGroup(e.target.checked) } 
                      name="phrase-ad-group" 
                      disabled={isDisabled}
                    />
                  }
                  label="Phrase Ad Group"
                /> 

                { phraseAdGroupChecked &&
                <TextField 
                  variant="outlined"
                  id="bid-amount-phrase" 
                  label="Bid Amount Phrase" 
                  value={ bidAmountPhrase } 
                  onChange={ (e) => this.onChangeBidAmountPhrase(e.target.value) } 
                  size="small" 
                  disabled={ isDisabled } 
                  InputLabelProps={{ shrink: true, }} 
                  InputProps={{ 
                    startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                  }}
                  helperText="Minimum $0.02"
                  style={{ maxWidth: 150 }}
                />
                }

                <FormControlLabel
                  control={
                    <Checkbox 
                      color="primary" 
                      checked={exactAdGroupChecked} 
                      onChange={ (e) => this.handleChangeExactAdGroup(e.target.checked) } 
                      name="exact-ad-group" 
                      disabled={isDisabled}
                    />
                  }
                  label="Exact Ad Group (Recommended)"
                /> 

                { exactAdGroupChecked &&
                <TextField 
                  variant="outlined"
                  id="bid-amount-exact" 
                  label="Bid Amount Exact" 
                  value={ bidAmountExact } 
                  onChange={ (e) => this.onChangeBidAmountExact(e.target.value) } 
                  size="small" 
                  disabled={ isDisabled } 
                  InputLabelProps={{ shrink: true, }} 
                  InputProps={{ 
                    startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                  }}
                  helperText="Minimum $0.02"
                  style={{ maxWidth: 150 }}
                />
                }

              </FormGroup>
            </FormControl> 
            
            <br /><br /><br />

            { // If any ad group selected (broad, phrase, exact) then show campaign budget field.
            ( broadAdGroupChecked || phraseAdGroupChecked || exactAdGroupChecked ) && 
            <FormControl className={classes.formControl} >
              <TextField 
                id="campaign-budget-manual" 
                label="Manual Campaign Daily Budget" 
                placeholder="enter budget" 
                value={ campaignBudgetManual } 
                //onChange={ (e) => this.setState({ campaignBudgetManual: e.target.value }) } 
                onChange={ (e) => this.onChangeCampaignBudgetManual(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                helperText="Minimum $5.0"
              />
            </FormControl>
            }
        </Grid>
        }

        { // If product confirmed and edit mode then show review info button.
        // Note: We will disable the review info button until all input are valid and ready.
        (productConfirmed && mode === 'edit') &&
        <Grid item xs={12} sm={12} align='center' >
          <Button 
            variant="contained" 
            size="medium" 
            onClick={ (e) => this.onClickReviewInfo(e) } 
            //style={{ marginRight: 20 }} 
            disabled={ this.shouldDisableReviewInfoButton() }
          >REVIEW INFO</Button>
          <br />
          <Typography variant="subtitle1" gutterBottom>
            Please enter all info to enable button.
          </Typography>
        </Grid>
        }

        { mode === 'review' && 
        <Grid item xs={12} sm={12} align='center' > 
          <Typography variant="subtitle1" gutterBottom>
            <b>Review Information</b>
          </Typography>
        </Grid>
        }

        { (mode === 'review' && automaticCampaignChecked) &&
        <Grid item xs={12} sm={4} align='center' > 
          <Typography variant="subtitle1" gutterBottom>
            <b> {flowName} Auto Campaign</b>
          </Typography>
          { this.renderReviewInfoAutomaticCampaign() }
        </Grid>
        }

        { mode === 'review' && (broadAdGroupChecked || phraseAdGroupChecked || exactAdGroupChecked) &&
        <Grid item xs={12} sm={ automaticCampaignChecked ? 8 : 12 } align='center' > 
          <Typography variant="subtitle1" gutterBottom>
            <b>{flowName} Manual Campaign</b>
          </Typography>
          { this.renderReviewInfoManualCampaign() }
        </Grid>
        }

        { mode === 'review' &&
        <Grid item xs={12} sm={12} align='center' >
          <Button 
            variant="contained" 
            size="medium" 
            onClick={ (e) => this.onClickEditFlowButton(e) } 
            style={{ marginRight: 20 }} 
            disabled={ isProcessing || AUTO_FLOW_ALL_DONE || MANUAL_FLOW_ALL_DONE }
          >EDIT FLOW</Button>
          <Button 
            variant="contained" 
            size="medium" 
            onClick={ (e) => this.onClickConfirmCreateButton(e) }
            //style={{ marginRight: 20 }} 
            disabled={ isProcessing || AUTO_FLOW_ALL_DONE || MANUAL_FLOW_ALL_DONE } 
          >CONFIRM & CREATE</Button>
          &nbsp; (API_MODE: { API_MODE })
        </Grid>
        }

        { (AUTO_FLOW_ALL_DONE && MANUAL_FLOW_ALL_DONE) &&
          <Grid item xs={12} sm={12} align='center' > 
            <h2>Flow Created Successfully</h2>
            <Button 
              variant="contained" 
              size="medium" 
              onClick={ (e) => this.onClickOkButton(e) }
              //style={{ marginRight: 20 }} 
              //disabled={ isProcessing } 
            >  OK  </Button>
          </Grid>
        }

        { errorMessage !== '' &&
          <Grid item xs={12} sm={12} align='center' > 
            <h3>(( { errorMessage } ))</h3>
          </Grid>
        }

        { isProcessing && 
          <Grid item xs={12} sm={12}> 
            <LinearProgress /> 
          </Grid>
        }

        <Grid item xs={12} sm={12}>
          <br /><br />
          <hr />
        </Grid>

        <Grid item xs={12} sm={4}>
          <div>
            <b>Debug:</b> <br />
            API_MODE: { API_MODE } <br />
            runAdFor: { runAdFor } <br />  
            profileId: { profileId } <br />  
            market: { market } <br />
            searchProductBy: { searchProductBy } <br />
            searchProductValues: { searchProductValues } <br />
            <br />
            searchProductResults.length: { searchProductResults && searchProductResults.length } <br />
            selectedProducts.length: { selectedProducts && selectedProducts.length } <br />
            <br />
            flowName: { flowName } <br />
            startDate: { startDate } <br />
            endDate: { endDate } <br />
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            automaticCampaignChecked: { automaticCampaignChecked ? 'true' : 'false' } <br />
            broadAdGroupChecked: { broadAdGroupChecked ? 'true' : 'false' } <br />
            phraseAdGroupChecked: { phraseAdGroupChecked ? 'true' : 'false' } <br />
            exactAdGroupChecked: { exactAdGroupChecked ? 'true' : 'false' } <br />
            <br />
            bidAmountAutomatic: { bidAmountAutomatic } <br />
            bidAmountBroad: { bidAmountBroad } <br />
            bidAmountPhrase: { bidAmountPhrase } <br />
            bidAmountExact: { bidAmountExact } <br />
            <br />
            campaignBudgetAutomatic: { campaignBudgetAutomatic } <br />
            campaignBudgetManual: { campaignBudgetManual } <br />
            <br />
            suggestedKeywords.length: { suggestedKeywords && suggestedKeywords.length } <br />
            selectedKeywords.length: { selectedKeywords && selectedKeywords.length } <br />
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            mode: { mode } <br />
            <br />
            flowDocId: { flowDocId } <br />
            autoCampaignId: { autoCampaignId } <br />
            autoAdGroupId: { autoAdGroupId } <br />
            <br />
            manualCampaignId: { manualCampaignId } <br />
            manualBroadAdGroupId: { manualBroadAdGroupId } <br />
            manualPhraseAdGroupId: { manualPhraseAdGroupId } <br />
            manualExactAdGroupId: { manualExactAdGroupId } <br />
            <br />
            showMessage: { showMessage ? 'true' : 'false' } <br />
            messageText: { messageText } <br />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          SHOULD_CREATE_AUTO_FLOW: { SHOULD_CREATE_AUTO_FLOW ? 'true' : 'false' } <br />
          AUTO_CAMPAIGN_CREATED: { AUTO_CAMPAIGN_CREATED ? 'true' : 'false' } <br />
          AUTO_ADGROUP_CREATED: { AUTO_ADGROUP_CREATED ? 'true' : 'false' } <br />
          AUTO_NEGATIVE_KEYWORDS_CREATED: { AUTO_NEGATIVE_KEYWORDS_CREATED ? 'true' : 'false' } <br />
          AUTO_PRODUCT_AD_CREATED: { AUTO_PRODUCT_AD_CREATED ? 'true' : 'false' }  <br />
          AUTO_FLOW_ALL_DONE: { AUTO_FLOW_ALL_DONE ? 'true' : 'false' } <br />
        </Grid>
        <Grid item xs={12} sm={6}>
          SHOULD_CREATE_MANUAL_FLOW: { SHOULD_CREATE_MANUAL_FLOW ? 'true' : 'false' } <br />
          MANUAL_CAMPAIGN_CREATED: { MANUAL_CAMPAIGN_CREATED ? 'true' : 'false' } <br />
          MANUAL_BROAD_ADGROUP_CREATED: { MANUAL_BROAD_ADGROUP_CREATED ? 'true' : 'false' } <br />
          MANUAL_PHRASE_ADGROUP_CREATED: { MANUAL_PHRASE_ADGROUP_CREATED ? 'true' : 'false' } <br />
          MANUAL_EXACT_ADGROUP_CREATED: { MANUAL_EXACT_ADGROUP_CREATED ? 'true' : 'false' } <br />
          MANUAL_KEYWORDS_CREATED: { MANUAL_KEYWORDS_CREATED ? 'true' : 'false' } <br />
          MANUAL_NEGATIVE_KEYWORDS_CREATED: { MANUAL_NEGATIVE_KEYWORDS_CREATED ? 'true' : 'false' } <br />
          MANUAL_PRODUCT_AD_CREATED: { MANUAL_PRODUCT_AD_CREATED ? 'true' : 'false' } <br />
          MANUAL_FLOW_ALL_DONE: { MANUAL_FLOW_ALL_DONE ? 'true' : 'false' } <br />
        </Grid>

        <Grid item xs={12} sm={12}>
          amazonUserId: { amazonUserId } <br /> 
          amazonEmail: { amazonEmail } <br />
          <br />
        </Grid>

      </Grid>
    )
  }

  // This function called whenever user will check/uncheck product from search result.
  onSelectProduct = (asin, checked, index) => {
    //console.log('onSelectProduct() asin:', asin, ' checked:', checked, ' index: ', index);
    
    // Checked = true, Save checked product within selected product list.
    if ( checked ) {
      
      // 1 - Fetch data from state
      const { searchProductResults, selectedProducts } = this.state;
      
      // 2 - Fetch checked product from searched product list.
      const product = searchProductResults[index];

      // 3 - Add checked product to selected product list
      var selectedProductsTemp = [ ...selectedProducts ];
      selectedProductsTemp.push(product);

      // Debug
      console.log("selectedProductsTemp:", selectedProductsTemp);

      // 4 - Update info within state
      this.setState({
        selectedProducts: selectedProductsTemp
      });
    
    } else { // Checked = false, Remove unchecked product from selected product list
      
      // 1 - Fetch data from state
      const { selectedProducts } = this.state;

      // 2 - Remove unchecked product from selected list
      const selectedProductsTemp = [];
      selectedProducts.forEach( (product, index) => { 
        if ( product.ASIN !== asin ) { 
          selectedProductsTemp.push(product); 
        }
      });

      // Debug
      console.log("selectedProductsTemp:", selectedProductsTemp);

      // 3 - Update info within state
      this.setState({
        selectedProducts: selectedProductsTemp,
      }); 
    }

  }

  // This function will decide that given checkbox should be set 
  // as checked or not. It will return true (for checked), othewise false.
  shouldSetChecked = (asin, index) => {
    
    const { selectedProducts } = this.state;

    // 1 - Check that given asin exist within selected product list or not.
    var isAsinFound = false;
    selectedProducts.forEach( (product, index) => {
      if (product.ASIN === asin) {
        isAsinFound = true;
      }
    });

    // 2 - If asin found then set as selected, otherwise not.
    return isAsinFound;
  }


  // Render product list checkbox, so user can select product from it.
  renderProductList = () => {
    const { searchProductResults, isProcessing, mode, productConfirmed } = this.state;
    const { classes } = this.props;
    
    // If processin in progress or review mode on, then disable every control
    let isDisabled = false;
    if ( isProcessing || mode === 'review' ) { isDisabled = true; }

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="search result"> 
          { 
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left"></TableCell>
          //     <TableCell align="left">Image</TableCell>
          //     <TableCell align="left">Title</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
          { searchProductResults.map( (row, index) => (
              <TableRow key={row.ASIN + '__' + index}  >
                <TableCell align="left" style={{ padding: 0 }} >
                  <Checkbox
                    checked={ this.shouldSetChecked(row.ASIN, index) }
                    color="primary"
                    value={row.ASIN}
                    onChange={ (event) => this.onSelectProduct(event.target.value, event.target.checked, index) }
                    disabled={ isDisabled || productConfirmed }
                  /> 
                </TableCell>
                <TableCell align="left" style={{ padding: 0 }} >
                  { row.SmallImage &&
                    //<img src={row.SmallImage.URL} width={row.SmallImage.Width.Value} height={row.SmallImage.Height.Value} alt={row.Title} />
                    <img src={row.SmallImage.URL} width="50" alt={row.Title} />  
                  }
                </TableCell>
                <TableCell align="left" style={{ padding: 5, paddingLeft: 10, paddingRight: 10 }} >
                  <b>ASIN:</b> {row.ASIN} &nbsp; &nbsp; 
                  <b>SellerSKU:</b> {row.SellerSKU} &nbsp; &nbsp; 
                  <b>Title:</b> { row.Title }
                </TableCell>
                { productConfirmed &&
                <TableCell align="left" style={{ padding: 5, paddingLeft: 10, paddingRight: 10, width: 120, }} > 
                    <TextField 
                      variant="outlined"
                      id={"product-cost-" + index}
                      label="Product Cost" 
                      placeholder="" 
                      value={ row.productCost } 
                      onChange={ (e) => this.onChangeProductCost(e.target.value, index) }
                      size="small" 
                      disabled={ isDisabled } 
                      InputLabelProps={{ shrink: true, }}
                      //helperText="Include wholesale/manufacturing + shipping cost." 
                      InputProps={{ 
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                </TableCell>
                }
              </TableRow>
            ))}      
          </TableBody>
        </Table>
      </TableContainer>
    );

  }

  
  // This function called whenever user will input product cost within a product list.
  onChangeProductCost = (value, index) => {
    const { searchProductResults } = this.state;
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Copy existing product list to temp array
    const searchProductResultsTemp = searchProductResults.map((item, index) => { return item; });
    
    // 3 - Update product cost within temp array
    searchProductResultsTemp[index].productCost = value;

    // 4 - Set updated product list within state
    this.setState({
      searchProductResults: searchProductResultsTemp,
    });
  }


  // This function called when user enter bid amount for automatic campaign
  onChangeBidAmountAutomatic = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Set value within state
    this.setState({
      bidAmountAutomatic: value,
    });
  }


  // This function called when user enter bid amount for broad ad group
  onChangeBidAmountBroad = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Set value within state
    this.setState({
      bidAmountBroad: value,
    });
  }

  // This function called when user enter bid amount for phrase ad group
  onChangeBidAmountPhrase = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Set value within state
    this.setState({
      bidAmountPhrase: value,
    });
  }

  // This function called when user enter bid amount for exact ad group
  onChangeBidAmountExact = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Set value within state
    this.setState({
      bidAmountExact: value,
    });
  }
  
  // This function called when user enter manual campaign budget  
  onChangeCampaignBudgetManual = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Set value within state
    this.setState({
      campaignBudgetManual: value,
    });
  } 

  // This function called when user enter automatic campaign budget
  onChangeCampaignBudgetAutomatic = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Set value within state
    this.setState({
      campaignBudgetAutomatic: value,
    });
  } 


  // When user will add keyword manually, this function called
  onClickAddManualKeyword = () => { 
    //console.log('onClickAddManualKeyword()');

    const { manualKeywordText, selectedKeywords } = this.state;

    // 1 - If keyword empty then return
    const keywordText = manualKeywordText.trim();
    if ( keywordText.length === 0 ) { 
      return;
    }

    // 1A - If keyword already exist within selected keyword list then return.
    let isKeywordExist = false;
    selectedKeywords.forEach( (item, index) => {
      if ( item.keywordText === keywordText ) {
        isKeywordExist = true;
      }
    });
    if (isKeywordExist) { 
      return; 
    }

    // 2 - Prepare data for keyword
    const keywordNew = {
      keywordText: keywordText,
      matchType: '',
      source: 'manual'
    }

    // 3 - Add keyword at end of selected keyword list
    const selectedKeywordsTemp = selectedKeywords.map( (item,index) => { return item; }); // copy array
    selectedKeywordsTemp.push(keywordNew); 

    // 4 - Update state
    this.setState({
      manualKeywordText: '',
      selectedKeywords: selectedKeywordsTemp,
    });
  }

  // This function called whenever user will click Add button from 
  // suggested keyword list, So we have to add clicked keyword 
  // within selected keyword list and remove it from suggested list.
  onClickAddSuggestedKeyword = (index) => {
    //console.log('onClickAddSuggestedKeyword() index:', index);

    const { suggestedKeywords, selectedKeywords } = this.state;

    // 1 - Grab value for clicked keyword
    const keywordClicked = suggestedKeywords[index];

    // 1A - If keyword already exist within selected keyword list then return.
    let isKeywordExist = false;
    selectedKeywords.forEach( (item, index) => {
      if ( item.keywordText === keywordClicked.keywordText) {
        isKeywordExist = true;
      }
    });
    if (isKeywordExist) { 
      return; 
    }

    // 2 - Prepare keyword object to add within selected keyword list
    // source: 'suggested' (Taken from suggested keyword list) 
    //          'manual'  (Manually added by user) 
    // e.g. 
    // selectedKeywords: [
    //   {
    //     keywordText: "Keyword1",
    //     matchType: "broad",
    //     source: 'suggested',
    //   }
    // ],
    const keywordTemp = {
      keywordText: keywordClicked.keywordText,
      matchType: keywordClicked.matchType,
      source: 'suggested'
    }
    //console.log('keywordTemp:', keywordTemp);

    // 3 - Remove clicked keyword from suggested list.
    const suggestedKeywordsTemp = suggestedKeywords.map( (item, index) => { return item; }); // copy array
    suggestedKeywordsTemp.splice(index,1);

    // 4 - Add clicked keyword at end of selected keyword list
    const selectedKeywordsTemp = selectedKeywords.map( (item,index) => { return item; }); // copy array
    selectedKeywordsTemp.push(keywordTemp);

    // 5- Update state
    this.setState({
      suggestedKeywords: suggestedKeywordsTemp,
      selectedKeywords: selectedKeywordsTemp,
    });
  }

  // This function called whenever user will click remove button from the 
  // selected keyword list. Removed keword may be taken from suggested keyword list 
  // or added manually. So if keyword taken from the suggested keyword list then 
  // we will put it back to suggsted list. If user added keyword then we will 
  // remove it from the selected list.
  onClickRemoveSelectedKeyword = (index) => {
    //console.log('onClickRemoveSelectedKeyword() index:', index);

    const { suggestedKeywords, selectedKeywords } = this.state;

    // 1 - Grab value for keyword to remove
    const keywordToRemove = selectedKeywords[index];

    // 2 - If Keyword added from suggested list then put it back to 
    // suggested list array at first position.
    const suggestedKeywordsTemp = suggestedKeywords.map( (item, index) => { return item; }); // copy array
    if ( keywordToRemove.source === 'suggested' ) {
      const keywordAdd = {
        keywordText: keywordToRemove.keywordText,
        matchType: keywordToRemove.matchType
      }
      suggestedKeywordsTemp.unshift(keywordAdd);
    }
      
    // 3 - Remove it from selected keyword list 
    const selectedKeywordsTemp = selectedKeywords.map( (item,index) => { return item; });
    selectedKeywordsTemp.splice(index,1);

    // 5 - Update state
    this.setState({
      suggestedKeywords: suggestedKeywordsTemp,
      selectedKeywords: selectedKeywordsTemp,
    });
  }


  // This function will render suggested keyword list
  renderSuggestedKeywordList = () => { 

    //const { classes } = this.props;
    const { suggestedKeywords, isProcessing, mode } = this.state;

    let isDisabled = false;
    if ( isProcessing || mode === 'review' ) { isDisabled = true; }

    return (
      <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }} >
        <Table aria-label="suggested keyword list"> 
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left"></TableCell>
          //     <TableCell align="left"></TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { 
              suggestedKeywords.map( (item, index) => {
                return (
                <TableRow key={ 'suggested_keyword_' + index } >
                  <TableCell align="left" style={{ padding: 5, paddingLeft: 10, paddingRight: 10 }} >
                    { item.keywordText }  ({ item.matchType })
                  </TableCell>
                  <TableCell align="right" style={{ padding: 0 }} >
                    <Button 
                      variant="text" 
                      size="small" 
                      color="primary"
                      //style={{ marginRight: 20 }}
                      onClick={ (e) => this.onClickAddSuggestedKeyword(index) } 
                      disabled={ isDisabled }
                    >Add</Button>
                  </TableCell>
                </TableRow>  
                )
              })
            }
          </TableBody> 
        </Table>
      </TableContainer>
    );
  }


  // This function will render selected keyword list
  renderSelectedKeywordList = () => { 
    //const { classes } = this.props;
    
    const { selectedKeywords, isProcessing, mode } = this.state;

    let isDisabled = false;
    if ( isProcessing || mode === 'review' ) { isDisabled = true; }

    return (
      <TableContainer component={Paper} style={{ maxHeight: 340, overflow: 'auto' }} >
        <Table aria-label="selected keyword list"> 
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left"></TableCell>
          //     <TableCell align="left"></TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { 
              selectedKeywords.map( (item, index) => {
                return (
                <TableRow key={ 'selected_keyword_' + index } >
                  <TableCell align="left" style={{ padding: 5, paddingLeft: 10, paddingRight: 10 }} >
                    { item.keywordText } 
                    { item.source === 'suggested' && ' (suggested)' }
                  </TableCell>
                  <TableCell align="right" style={{ padding: 0 }} >
                    <Button 
                      variant="text" 
                      size="small" 
                      color="primary" 
                      //style={{ marginRight: 20 }} 
                      onClick={ (e) => this.onClickRemoveSelectedKeyword(index) } 
                      disabled={ isDisabled } 
                    >Remove</Button>
                  </TableCell>
                </TableRow>  
                )
              })
            }
          </TableBody> 
        </Table>
      </TableContainer>
    );
  }


  renderManualKeywordAddForm = () => {
    const { classes } = this.props;
    const { manualKeywordText, isProcessing, mode } = this.state;
    
    let isDisabled = false;
    if ( isProcessing || mode === 'review' ) { isDisabled = true; }

    return (
      <div style={{ display:'flex', flexDirection: 'row' }} >
        <FormControl className={classes.formControl} > 
          <TextField 
            //variant="outlined"
            id="manual-keyword" 
            label="Keyword" 
            placeholder="enter keyword" 
            value={ manualKeywordText } 
            onChange={ (e) => this.setState({ manualKeywordText: e.target.value }) } 
            size="small" 
            disabled={ isDisabled } 
            InputLabelProps={{ shrink: true, }}
            //helperText=""
          />
        </FormControl>
          <Button 
            variant="outlined" 
            size="small" 
            onClick={ (e) => this.onClickAddManualKeyword() } 
            style={{ marginLeft: 20 }}
            disabled={ isDisabled || manualKeywordText.length === 0 }
          >Add</Button>
      </div>
    );
  }

  // Render review information for automatic campaign
  renderReviewInfoAutomaticCampaign = () => {
    const { flowName, selectedKeywords, exactAdGroupChecked } = this.state;

    return(
      <TableContainer component={Paper} >
        <Table aria-label="review info automatic campaign"> 
          <TableHead>
            <TableRow>
              <TableCell align="left">{flowName} Auto Ad Group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { // If Manual Exact Ad Group Checked then we will create negative keywords 
              // under Auto ad Group, Otherwise not. So we will show message accordingly.
            exactAdGroupChecked && 
            <TableRow>
              <TableCell align="left">
                Below keywords will be added as <b>Negative Exact</b> match type.
                <br /><br />
                { 
                  selectedKeywords.map( (item, index) => {
                    return (<span key={'auto_exact_' + index} > { item.keywordText } <br /> </span>)
                  })
                }
              </TableCell>
            </TableRow>
            }
            { !exactAdGroupChecked && 
            <TableRow>
              <TableCell align="left">
                Any keywords will not added as <b>Negative Exact</b> match type.
              </TableCell>
            </TableRow>
            }
          </TableBody> 
        </Table>
    </TableContainer>
    );
  }

  // Render review information for manual campaign
  renderReviewInfoManualCampaign = () => {
    const { selectedKeywords, broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked } = this.state;
    const { flowName } = this.state;

    return(
      <TableContainer component={Paper} >
        <Table aria-label="review info manual campaign"> 
          <TableHead>
            <TableRow>
              { broadAdGroupChecked &&
              <TableCell align="left">{ flowName } Manual Broad Ad Group</TableCell>
              }
              { phraseAdGroupChecked && 
              <TableCell align="left">{ flowName } Manual Phrase Ad Group</TableCell>
              }
              { exactAdGroupChecked && 
              <TableCell align="left">{ flowName } Manual Exact Ad Group</TableCell> 
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              { broadAdGroupChecked && 
              <TableCell align="left">
                { exactAdGroupChecked && 
                  <React.Fragment>
                    Below keywords will be added as <b>Broad</b> and <b>Negative Exact</b> match type.
                  </React.Fragment>
                }
                { !exactAdGroupChecked && 
                  <React.Fragment>
                    Below keywords will be added as <b>Broad</b> match type.
                  </React.Fragment>
                }
                <br /><br />
                { 
                  selectedKeywords.map( (item, index) => {
                    return (<span key={'broad_' + index} > { item.keywordText } <br /> </span>)
                  })
                }
              </TableCell>
              }

              { phraseAdGroupChecked && 
              <TableCell align="left">
                { exactAdGroupChecked && 
                  <React.Fragment>
                    Below keywords will be added as <b>Phrase</b> and <b>Negative Exact</b> match type.
                  </React.Fragment>
                }
                { !exactAdGroupChecked && 
                  <React.Fragment>
                    Below keywords will be added as <b>Phrase</b> match type.
                  </React.Fragment>
                }
                <br /><br />
                { 
                  selectedKeywords.map( (item, index) => {
                    return (<span key={'phrase_' + index} > { item.keywordText } <br /> </span>)
                  })
                }
              </TableCell>
              }

              { exactAdGroupChecked && 
              <TableCell align="left">
                Below keywords will be added as <b>Exact</b> match type.
                <br /><br />
                { 
                  selectedKeywords.map( (item, index) => {
                    return (<span key={'exact_' + index} > { item.keywordText } <br /> </span>)
                  })
                }
              </TableCell>
              } 
            </TableRow>
          </TableBody> 
        </Table>
      </TableContainer>
    );
  }

  //-----------------------------------------------------------------
  // END: Rendering related function 
  //-----------------------------------------------------------------


  // Called when message close after time out (snackbar message)
  handleClose = () => {
    console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }


  render() {
    //const { classes } = this.props;
    const { isProcessing } = this.state;  

    const { showMessage, messageText, API_MODE } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    //console.log('showMessage:', showMessage);
    //console.log('messageText:', messageText);
    
    let apiModeStyle = {};
    if (API_MODE === 'REAL') { 
      apiModeStyle = { color: 'green', fontWeight: 'bold' };
    }

    return (
      <React.Fragment>
        <Paper elevation={2} style={{padding: 20}} >
          <Grid container spacing={2}>
            <Grid item xs={7} sm={10}>
              <Typography variant="h5" component="h1" >
                Create New Flow
                <Typography variant="caption" style={apiModeStyle} >
                 &nbsp; API MODE: { API_MODE }
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} sm={2} align='right' >
              <IconButton 
                aria-label="close" 
                disabled={ isProcessing } 
                onClick={ (e) => this.onClickCancel(e) }
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>

          { this.renderUiForm() }
        </Paper >

        { 
          //isProcessing && <LinearProgress /> 
        }
        
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </React.Fragment>
    );
  }


}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

  table: {
    minWidth: 650,
  },

});


SpAdAutomationUi.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(SpAdAutomationUi);

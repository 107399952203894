// Get lowest offer listing for asins.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Import helper functions, constant etc.
import { getLowestPricedOffersForASIN } from '../../helpers/mwsHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);


class GetLowestPricedOffersForASIN extends React.Component {
  
  state = {
    // Form input
    market: 'CA',
    itemCondition: 'New', // New, Used, Collectible, Refurbished, Club
    asin: 'B015CH1PJU', // e.g. 'B015CH1PJU' (Single Asin)

    status: '',
    error: '',

    identifier: null,
    offers: null,
    summary: null,

    headers: null,
    responseMetadata: null,
    statusCode: 0,

    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('GetLowestPricedOffersForASIN - componentDidMount()');
  }


  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      identifier: null,
      offers: null,
      summary: null,

      headers: null,
      products: [],
      responseMetadata: null,
      statusCode: 0,
    });
  }


  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asin, itemCondition } = this.state;
    if (!market || market === '') { return };
    if (!asin || asin === '') { return };

    // 2 - Make value empty
    this.setState({
      status: '',
      data: {},
      error: '',
      isProcessing: true,
    });

    // 3 - Call api
    getLowestPricedOffersForASIN(market, asin, itemCondition, this.getLowestPricedOffersForASIN_Success, this.getLowestPricedOffersForASIN_Error);
  }

  // Called when api call success
  getLowestPricedOffersForASIN_Success = (result) => {
    //console.log("getLowestPricedOffersForASIN_Success() result:", result);
    
    const { status, data, error } = result;
    
    const stateUpdates = {};
    if (data.Headers) {
      stateUpdates['headers'] = data.Headers;
    }
    if (data.Identifier) {
      stateUpdates['identifier'] = data.Identifier;
    }
    if (data.Offers) {
      stateUpdates['offers'] = data.Offers;
    }
    if (data.Identifier) {
      stateUpdates['summary'] = data.Summary;
    }    
    if (data.ResponseMetadata) {
      stateUpdates['responseMetadata'] = data.ResponseMetadata;
    }
    if (data.statusCode) {
      stateUpdates['statusCode'] = data.statusCode;
    }

    // Udpate value to state
    this.setState({
      ...stateUpdates,
      status: status,
      error: error,
      isProcessing: false,
    });
  }

  // Called if error while api call
  getLowestPricedOffersForASIN_Error = (error) => {
    console.log("getLowestPricedOffersForASIN_Error() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }


  renderOffers = () => {
    const { offers } = this.state;
    const { Offer } = offers;
    
    const offersEl = Offer.map( (offer, index)=> {
      const { IsBuyBoxWinner, IsFeaturedMerchant, IsFulfilledByAmazon,
              ListingPrice, SellerFeedbackRating, Shipping, ShippingTime,
              ShipsFrom, SubCondition } = offer;
      return (
        <div key={'offers-' + index}>
          #{index+1} <br />
          IsBuyBoxWinner: { IsBuyBoxWinner } <br />
          IsFeaturedMerchant: { IsFeaturedMerchant } <br />
          IsFulfilledByAmazon: { IsFulfilledByAmazon } <br />
          ListingPrice: { ListingPrice.CurrencyCode + ' ' + ListingPrice.Amount } <br />
          SellerFeedbackRating: { JSON.stringify(SellerFeedbackRating) } <br />
          Shipping: { JSON.stringify(Shipping) } <br />
          ShippingTime: { JSON.stringify(ShippingTime) } <br />
          ShipsFrom: { JSON.stringify(ShipsFrom) } <br />
          SubCondition: { SubCondition } <br />
          <br />
        </div>
      )
    });

    return offersEl;
  }


  renderSummary = () => {
    const { summary } = this.state;
    const { BuyBoxEligibleOffers, BuyBoxPrices, ListPrice, 
            LowestPrices, NumberOfOffers, TotalOfferCount } = summary;

    const { OfferCount } = BuyBoxEligibleOffers;
    const offerCountEl = OfferCount.map((offer, index) => {
      return <div key={'buybox-offer-' + index}>{JSON.stringify(offer)}<br /></div>
    });

    const { BuyBoxPrice } = BuyBoxPrices;
    const buyBoxPriceEl = Object.keys(BuyBoxPrice).map((key, index)=> {
      return <div key={key}>{key}: {JSON.stringify(BuyBoxPrice[key])}<br /></div>
    });

    const { LowestPrice } = LowestPrices;
    const lowestPriceEl = LowestPrice.map((price, index)=> {
      return (
        <div key={'LowestPrice' + index} >
          #{index+1}:
          LandedPrice: { price.LandedPrice.CurrencyCode + price.LandedPrice.Amount } &nbsp; 
          ListingPrice: { price.ListingPrice.CurrencyCode + price.ListingPrice.Amount } &nbsp;
          Shipping: { price.Shipping.CurrencyCode + price.Shipping.Amount } &nbsp;
          condition: { price.condition } &nbsp;
          fulfillmentChannel: { price.fulfillmentChannel } <br />
        </div>
        )
    });

    const numberOfOffersEl = NumberOfOffers.OfferCount.map((offer, index)=> {
      return <div key={'offercount-' + index}>{JSON.stringify(offer)}<br /></div>
    });

    return(
      <div>
        <b>Summary: </b> <br />
        <b>Summary.BuyBoxEligibleOffers:</b> <br /> { offerCountEl } 
        <b>Summary.BuyBoxPrices:</b> <br/> { buyBoxPriceEl }
        <b>Summary.ListPrice:</b> {JSON.stringify(ListPrice)} <br/> 
        <b>Summary.LowestPrices:</b> { lowestPriceEl }
        <b>Summary.NumberOfOffers:</b> { numberOfOffersEl }
        <b>Summary.TotalOfferCount:</b> { TotalOfferCount } <br/>
      </div>
    );
  }

  renderHeaderInfo = (headers) => {
    const keys = Object.keys(headers);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {headers[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderResponseMetaData = (responseMetadata) => {
    const keys = Object.keys(responseMetadata);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {responseMetadata[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderResult = () => {
    const { identifier, summary, offers, status, error, headers, responseMetadata } = this.state;
    return (
      <div align='left'>
        { status !== '' && 
          <><b>Status:</b> {status}<hr /></>
        }
        { identifier &&
          <>
            <b>Identifier: </b> <br />
            { JSON.stringify(identifier) } 
            <br /><br />
          </>
        }
        { offers && 
          <>
            <b>Offers: ({offers.Offer.length}) </b> <br />
            { this.renderOffers() }
          </>
        }
        { summary && this.renderSummary() }

        { responseMetadata && 
          <><h3>Response Header:</h3>{ this.renderHeaderInfo(headers) }<hr /></>
        }
        { responseMetadata && 
          <><h3>Response Metadata:</h3>{ this.renderResponseMetaData(responseMetadata) }<hr /></>
        }
        { error &&
          <>
            <b>Error:</b>
            <div style={{width: '100%', overflow: 'scroll', border: '0px solid #cdcdcd' }}>
              { error && error.toString() }
            </div>
          </>
        }
      </div>
    );
  }


  renderForm = () => {
    const { isProcessing } = this.state;
    const { classes } = this.props;
    
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    markePlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Item Condition</InputLabel>
                <Select
                  id="itemCondition"
                  labelId="Item Condition"
                  value={this.state.itemCondition}
                  onChange={(e) => this.setState({ itemCondition: e.target.value })}
                  disabled={isProcessing}
                >
                  <MenuItem value="New" >New</MenuItem>
                  <MenuItem value="Used" >Used</MenuItem>
                  <MenuItem value="Collectible" >Collectible</MenuItem>
                  <MenuItem value="Refurbished" >Refurbished</MenuItem>
                  <MenuItem value="Club" >Club</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <TextField 
                  id="asins2" 
                  label="ASIN (single)" 
                  placeholder="" 
                  value={this.state.asin} 
                  onChange={(e) => this.setState({ asin: e.target.value })} 
                  size="small" 
                  disabled={isProcessing} 
                />
              </FormControl>
          </Grid>
        </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        {isProcessing &&
          <b>Processing... &nbsp;&nbsp;&nbsp; </b>
        }
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={isProcessing}
        >Submit</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>MWS - GetLowestPricedOffersForASIN</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            { this.renderFormButton() }
            { this.renderResult() }
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
  
}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


GetLowestPricedOffersForASIN.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(GetLowestPricedOffersForASIN);
// Amazon Advertising Api: 
// Sponsored Products - Get an array of (list) of negative keywords
// GET: /v2/sp/negativeKeywords
// 
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/listNegativeKeywords


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';


// Import helper functions, constant etc.
import { spGetNegativeKeywords } from '../../helpers/amazonAdHelper';
//import { getProfiles } from '../../helpers/amazonAdHelper';


class SponsoredProductsGetNegativeKeywords extends React.Component {
  
  state = {

    // Form input data
    profileId: '',    // Selected profile id

    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],

    // We will fetch negative keyword list from amazon ad api and set it here.
    // e.g. 
    // keywordList: [
    //   {
    //     "keywordId": 0,
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ],
    keywordList: null, 

    // If any error occur during some operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SponsoredProductsGetNegativeKeywords - componentDidMount()');

    // Fetch amazon profile list
    // Commented: because we passed profileList as props from parent component 
    //this.fetchProfileList();
  }

  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass profile Id to server side api, so first 
  // we will fetch profile list and fillup drop down so user can choose it.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    //getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      profileId: '',
      keywordList: null, 

      isProcessing: false,
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Fetch negative keywords (list)
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Get profileId from the state
    const { profileId } = this.state;

    // 2 - If profileId not selected then return
    if ( !profileId || profileId === '' ) {
      return;
    }

    // 3 - Show processing
    this.setState({
      isProcessing: true,
      keywordList: null,
    });

    // 4 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/listNegativeKeywords
    const queryData = {
      startIndex: 0,
      count: 100,  
      //stateFilter: 'enabled,archived',
    }

    // 5 - Call api to Get negative keywords (list) for selected profile Id.
    spGetNegativeKeywords(profileId, queryData, this.spGetNegativeKeywords_Success, this.spGetNegativeKeywords_Error);
  }

  // Called when negative keywords (list) fetched successfully
  spGetNegativeKeywords_Success = (result) => {
    console.log('spGetNegativeKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        keywordList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while fetch negative keywords (list)
  spGetNegativeKeywords_Error = (error) => {
    console.log('spGetNegativeKeywords_Error() error:', error);
    
    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch negative keywords (list)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  // Called whenever user will select profile id from dropdown
  onChangeProfileId = (profileId) => {
    //console.log('onChangeProfileId() profileId:', profileId);

    this.setState({
      profileId: profileId,
      keywordList: null,
    });
  }
  
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-profile-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="choose-profile-label"
              value={profileId}
              onChange={ (e) => this.onChangeProfileId(e.target.value) }
              disabled={ isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { id, type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {id} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' } 
        >Get Negative Keyword List</Button>
        <Button  
          variant="contained"  
          size="small"  
          onClick={this.onClickClear}  
          disabled={isProcessing}  
        >Clear</Button>  
      </div> 
    );
  }
  
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { keywordList, profileId } = this.state;

    // If list variable not exist then do not show anything
    if ( !keywordList ) { return null; }

    // If list empty array then show message.
    if ( keywordList.length === 0 ) {
      return ( 
        <div align='left'>
          <h3>Negative Keyword list empty for profileId: { profileId }</h3>
        </div>
      );
    }

    // If list exist then render its content
    // e.g. 
    // keywordList = [
    //   {
    //     "keywordId": 0,
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ]
    return (
      <div align='left'>
        <b>Negative Keyword List for: { profileId } </b> <br />
        <hr />
        { 
          keywordList.map( (item, index) => {
            return (
              <div key={ 'keyword_' + index } >  
                <b>#{ index + 1 }</b> <br />  
                <b>keywordId:</b> { item.keywordId } <br />  
                <b>campaignId:</b> { item.campaignId } <br />  
                <b>adGroupId:</b> { item.adGroupId } <br />  
                <b>state:</b> { item.state } <br />  
                <b>keywordText:</b> { item.keywordText } <br />  
                <b>matchType:</b> { item.matchType } <br />  
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Get Negative Keywords</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SponsoredProductsGetNegativeKeywords.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SponsoredProductsGetNegativeKeywords);


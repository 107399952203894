import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";

// Import custom ui component
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';

// Call server side api to fetch amazon access token for a given auth code.
import { fetchAmazonAccessToken } from "../../helpers/apiHelper";


class AmazonAuth extends React.Component { 

  state = {

    // Set processing true by default
    isProcessing: true,

    // If auth code granted it consist code and scope value.
    regionId: '',     // Region id for which auth code granted
    code: '',         // Granted auth code (we will use this to get access token)
    scope: '',        // scope for which auth code granted.
    
    // If error while auth code grant, it will return error code and description.
    error: '', 
    errorDescription: '', 

    // Message text (show message via snackbar)
    showMessage: false,
    messageText: "",
  }

  componentDidMount = () => {
    console.log('AmazonAuth - componentDidMount()');
    
    // Process the data received within url
    // Once user will click accept or cancel from consent screen, user will be redirected to 
    // this url with some parameter depending on user clicked accept or cancel.
    this.processAuthResult();
  }

  componentWillUnmount = () => {
    //console.log('AmazonAuth - componentWillUnmount()');
  }

  
  // Process auth result received within query string parameter
  processAuthResult = () => {
    console.log('processAuthResult()');

    // 1 - Grab region id from url
    // Pattern: http://localhost:3000/amazon-auth/{region_id}
    // e.g. http://localhost:3000/amazon-auth/NA  (for North America)
    // e.g. http://localhost:3000/amazon-auth/EU  (for Europe)
    // e.g. http://localhost:3000/amazon-auth/FE  (for Far East)
    const regionId = this.props.match.params.region_id;
    console.log('regionId:', regionId);

    // 2 - Grab querystring parameter from current url in browser
    // e.g. url 
    // https://www.amazon.com/?code=xxxxxxxxxxxxxxxxxxx&scope=cpc_advertising%3Acampaign_management
    // http://localhost:3000/amazon-auth/NA?error_description=An+unknown+scope+was+requested&error=invalid_scope
    const params = new URLSearchParams(this.props.location.search); 
    const code = params.get('code');
    const scope = params.get('scope');
    const errorDescription = params.get('error_description');
    const error = params.get('error');

    // Debug
    console.log('code:', code);
    console.log('scope:', scope);
    console.log('errorDescription:', errorDescription);
    console.log('error:', error);

    // 3 - If error then show error message to user, stop processing.
    // Otheriwise call server side api to get access token from received code.
    const isProcessing = error ? false : true;
    this.setState({
      regionId: regionId,
      code: code,
      scope: scope,
      error: error,
      errorDescription: errorDescription,
      isProcessing: isProcessing,
    });

    // 4 - If error received then return, it will show error message within ui.
    if (error) { 
      return; // IMPORTANT
    }

    // 5 - If no error then call server side api to get access token and refresh token using auth code.
    // i.e. Pass response.code to your server, and use it to request refresh and access token.
    if ( code !== '' && regionId !== '' ) {
      fetchAmazonAccessToken(regionId, code, this.fetchAmazonAccessToken_Succes, this.fetchAmazonAccessToken_Errror);
    } else {
      // If auth code, or regionId empty then show message. 
      // We can not get access token for empty auth code.
      // Show error message via snackbar.
      this.setState({
        showMessage: true,
        messageText: 'Invalid auth parameter.',
      });
    }
  }

  // access token and refresh token generated and stored within db successfully 
  // via server side api (for current user) then this function called via callback.
  fetchAmazonAccessToken_Succes = (result) => {
    console.log('fetchAmazonAccessToken_Succes() result:', result);

    // If token generated successfully then redirect user to /ad-automation page
    window.location.replace('/ad-automation');
  }

  // Error while generate access token, then this function will be called.
  fetchAmazonAccessToken_Errror = (error) => {
    console.log('fetchAmazonAccessToken_Errror() error:', error);

    // Show error message via snackbar
    this.setState({
      showMessage: true,
      messageText: 'Error while processing, please try again.',
    });
  }

  // Called when message close after time out (snackbar message)
  handleClose = () => {
    //console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }


  render() {

    const { isProcessing, error, errorDescription } = this.state;    
    //const { regionId, code, scope } = this.state;

    // Used to show snackbar message
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return(
      <React.Fragment>
        <br />
        <Typography variant="h6" component="h1">
          Amazon Auth
        </Typography>

        <br />

        { isProcessing && 
          <Typography variant="body1" >
            Processing, please wait...
          </Typography>
        }

        { isProcessing && <LinearProgress /> }

        { error && 
          <Typography variant="body1" >
            { error }: { errorDescription }
          </Typography>
        }

        <br /><br />

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

        {
        // <b>Debug:</b> <br />
        // regionId: { regionId } <br /> 
        // code: { code } <br /> 
        // scope: { scope } <br />
        // error: { error } <br />
        // errorDescription: { errorDescription } <br />
        // <br />
        // Note: Once user click allow button from amazon consent form, it will be redirected to this page.
        }
      </React.Fragment>
    );
  }
}


const styles = (theme) => ({

});


//export default App;
export default withRouter(withStyles(styles)(AmazonAuth));

// Returns the estimated fees for a list of products.
// Help: https://docs.developer.amazonservices.com/en_UK/products/Products_GetMyFeesEstimate.html

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Import helper functions, constant etc.
import { getMyFeesEstimate } from '../../helpers/mwsHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);


class GetMyFeesEstimate extends React.Component {
  
  state = {
    // Form input
    market: 'CA',
    asin: 'B015CH1PJU', // e.g. 'B015CH1PJU' (single)
    isAmazonFulfilled: true, 
    identifier: 'hello',
    listPriceCurrency: 'CAD',
    listPriceAmount: 30,
    shippingCurrency: 'CAD',
    shippingAmount:  3.99,
    pointsNumber: 0,

    status: '',
    error: '',

    feesEstimateResultList: null,
    headers: null,
    products: [],
    responseMetadata: null,
    statusCode: 0,

    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('GetMyFeesEstimate - componentDidMount()');
  }


  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      headers: null,
      products: [],
      responseMetadata: null,
      statusCode: 0,
    });
  }


  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asin, isAmazonFulfilled, listPriceAmount, listPriceCurrency, 
      shippingAmount, shippingCurrency, identifier, pointsNumber } = this.state;
    if (!market || market === '') { return };
    if (!asin || asin === '') { return };

    // 2 - Make value empty
    this.setState({
      status: '',
      data: {},
      error: '',
      isProcessing: true,
    });

    // 3 - prepare data to pass the api
    const data = { 
      market: market, 
      idType: 'ASIN',
      asin: asin, 
      isAmazonFulfilled: isAmazonFulfilled, 
      listPriceAmount: listPriceAmount, 
      listPriceCurrency: listPriceCurrency, 
      shippingAmount: shippingAmount, 
      shippingCurrency: shippingCurrency, 
      identifier: identifier, 
      pointsNumber: pointsNumber, 
    };

    // 4 - Call api
    getMyFeesEstimate(data, this.getMyFeesEstimate_Success, this.GetMyFeesEstimate_Error);
  }

  // Called when api call success
  getMyFeesEstimate_Success = (result) => {
    console.log("getMyFeesEstimate_Success() result:", result);
    
    const { status, data, error } = result;
    
    const stateUpdates = {};
    if (data.Headers) {
      stateUpdates['headers'] = data.Headers;
    }
    if (data.ResponseMetadata) {
      stateUpdates['responseMetadata'] = data.ResponseMetadata;
    }
    if (data.statusCode) {
      stateUpdates['statusCode'] = data.statusCode;
    }
    if (data.FeesEstimateResultList) {
      stateUpdates['feesEstimateResultList'] = data.FeesEstimateResultList;
    }

    // Udpate value to state
    this.setState({
      ...stateUpdates,
      status: status,
      error: error,
      isProcessing: false,
    });
  }

  // Called if error while api call
  GetMyFeesEstimate_Error = (error) => {
    console.log("GetMyFeesEstimate_Error() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }


  renderHeaderInfo = (headers) => {
    const keys = Object.keys(headers);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {headers[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderResponseMetaData = (responseMetadata) => {
    const keys = Object.keys(responseMetadata);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {responseMetadata[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderFeeEstimateResult = () => {
    const { FeesEstimateResult } = this.state.feesEstimateResultList;
    const { FeesEstimateIdentifier, FeesEstimate } = FeesEstimateResult;
    
    // Create data for fee estimate identifier
    const FeesEstimateIdentifierElement = Object.keys(FeesEstimateIdentifier).map( (key, index) => {
      return (
        <div key={'FeeEstimateIdenfier-' + index} > 
          {key}: { JSON.stringify(FeesEstimateIdentifier[key]) } <br />
        </div>
      )
    });

    return(
      <div>
        <b>FeesEstimate:</b> <br />
        <b>TimeOfFeesEstimation:</b> { JSON.stringify(FeesEstimate.TimeOfFeesEstimation) } <br />
        <b>TotalFeesEstimate:</b> { JSON.stringify(FeesEstimate.TotalFeesEstimate) } <br />
        <b>FeeDetailList:</b> { JSON.stringify(FeesEstimate.FeeDetailList) }
        <br /><br />
        <b>FeesEstimateIdentifier:</b> <br />
        { FeesEstimateIdentifierElement }
      </div>
    );
  }


  renderResult = () => {
    const { status, error, headers, responseMetadata, feesEstimateResultList } = this.state;
    return (
      <div align='left'>
        { status !== '' && 
          <><b>Status:</b> {status}<hr /></>
        }

        { feesEstimateResultList &&
          <>
            <h3>FeesEstimateResult:</h3>
            { this.renderFeeEstimateResult() }
            <br />
          </>
        }

        { responseMetadata && 
          <><h3>Response Header:</h3>{ this.renderHeaderInfo(headers) }<hr /></>
        }
        { responseMetadata && 
          <><h3>Response Metadata:</h3>{ this.renderResponseMetaData(responseMetadata) }<hr /></>
        }
        { error &&
          <>
            <b>Error:</b>
            <div style={{width: '100%', overflow: 'scroll', border: '0px solid #cdcdcd' }}>
              { error && error.toString() }
            </div>
          </>
        }
      </div>
    );
  }

  renderForm = () => {
    const { isProcessing } = this.state;
    const { classes } = this.props;
    
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    markePlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={6} sm={6}>
              <FormControl className={classes.formControl} >
                <TextField 
                  //id="asin" 
                  label="ASIN (single)" 
                  placeholder="" 
                  value={this.state.asin} 
                  onChange={(e) => this.setState({ asin: e.target.value })} 
                  size="small" 
                  disabled={isProcessing}
                />
              </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl} >
              <FormGroup row>
              isAmazonFulfilled:
                <Switch
                  checked={this.state.isAmazonFulfilled}
                  //onChange={handleChange}
                  onChange={(e) => this.setState({ isAmazonFulfilled: !this.state.isAmazonFulfilled })} 
                  color="primary"
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl} >
              <TextField 
                //id="listing-amount" 
                label="Listing Price Amount" 
                placeholder="" 
                value={this.state.listPriceAmount} 
                onChange={(e) => this.setState({ listPriceAmount: e.target.value })} 
                size="small" 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl} >
              <TextField 
                //id="listing-currency" 
                label="Listing Price Currency" 
                placeholder="" 
                value={this.state.listPriceCurrency} 
                onChange={(e) => this.setState({ listPriceCurrency: e.target.value })} 
                size="small" 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl} >
              <TextField 
                //id="shipping-amount" 
                label="Shipping Amount" 
                placeholder="" 
                value={this.state.shippingAmount} 
                onChange={(e) => this.setState({ shippingAmount: e.target.value })} 
                size="small" 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl} >
              <TextField 
                //id="shipping-currency" 
                label="Shipping Currency" 
                placeholder="" 
                value={this.state.shippingCurrency} 
                onChange={(e) => this.setState({ shippingCurrency: e.target.value })} 
                size="small" 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl} >
              <TextField 
                //id="pointsNumber" 
                label="Points Number" 
                placeholder="" 
                value={this.state.pointsNumber} 
                onChange={(e) => this.setState({ pointsNumber: e.target.value })} 
                size="small" 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl className={classes.formControl} >
              <TextField 
                //id="identifies" 
                label="Indetifier" 
                placeholder="" 
                value={this.state.identifier} 
                onChange={(e) => this.setState({ identifier: e.target.value })} 
                size="small" 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>
        </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        {isProcessing &&
          <b>Processing... &nbsp;&nbsp;&nbsp; </b>
        }
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={isProcessing}
        >Submit</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }


  render() {
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>MWS - GetMyFeesEstimate</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            { this.renderFormButton() }
            { this.renderResult() }
          </Box>
        </AccordionDetails>
      </Accordion>
    );

  }
}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


GetMyFeesEstimate.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(GetMyFeesEstimate);
import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';


import * as firebase from 'firebase/app';
//import firebase from '../../helpers/firebaseApp';


// Import constant and helper functions
import { 
  createUserInfo,
} from '../../helpers/firebaseAuthHelper';


class LoginWithGoogle extends React.Component {

  // Set default state 
  state = {
    isProcessing: false, 

    showBanner: false,    // true - show message banner, false - hide message banner
    bannerTitle: '',      // Banner title to show
  };

  componentDidMount = () => {    
    console.log("LoginWithGoogle - componentDidMount()");
  }

  componentWillUnmount = () => {
    console.log("LoginWithGoogle - componentWillUnmount()");
  }


  //-----------------------------------------------------------------
  // Handle button click
  //-----------------------------------------------------------------  
  onClickLogin = () => {
    console.log("LoginWithGoogle - onClickLogin()");
    
    // 1 - Show loader within button
    this.setState({
      isProcessing: true
    });
    
    // 2 - Sign in to google
    this.doSignInWithPopUp();
  }
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // Do sign in with popup
  // Help: https://firebase.google.com/docs/auth/web/google-signin  
  //-----------------------------------------------------------------
  doSignInWithPopUp = () => {
    console.log("LoginWithGoogle - doSignInWithPopUp()");

    // Create auth provider
    var provider = new firebase.auth.GoogleAuthProvider();
    //console.log('provider:', provider);
    console.log('provider.providerId:', provider.providerId);

    // Call sign in api    
    firebase.auth().signInWithPopup(provider)
    .then( (result) => { // Sign in success
      //console.log("User Signed In - result: ", result);

      // Save logged in user info within db
      createUserInfo(result.user, "google.com");

      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = result.credential.accessToken;
      
      // The signed-in user info.
      //var user = result.user;
    })
    .catch( (error) => { // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("errorCode:", errorCode);
      console.log("errorMessage:", errorMessage);

      // Show error message
      this.showError(errorMessage);
      
      // The email of the user's account used.
      //var email = error.email;
      
      // The firebase.auth.AuthCredential type that was used.
      //var credential = error.credential;
    });
  }
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Message banner
  //-----------------------------------------------------------------
  // Show error banner with given title (message text)
  showError = (title) => {
    this.setState({
      showBanner: true,
      bannerTitle: title,
      isProcessing: false,
    });
  }

  // Hide message banner
  hideBanner = () => {
    this.setState({
      showBanner: false,
      bannerTitle: '',
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Render
  //-----------------------------------------------------------------
  render() {

    const { isProcessing } = this.state;

    const { showBanner, bannerTitle } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <Paper elevation={2} style={{padding: 20}} align='center' >
        <Button 
          variant="contained"
          color="default"
          size="large"
          disabled={isProcessing} 
          onClick={this.onClickLogin} >
          &nbsp;&nbsp; Login with Google &nbsp;
        </Button>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showBanner}
          autoHideDuration={8000}
          onClose={this.hideBanner}
          message={bannerTitle}
          severity="success"
          key={vertical + horizontal}
        />
      </Paper>
    );
  }
  //-----------------------------------------------------------------

}

export default LoginWithGoogle;
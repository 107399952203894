import React from "react";
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import custom component
import PotentialProductAsin from './PotentialProductAsin';
import PotentialProductSearch from './PotentialProductSearch';
import CustomSettings from './CustomSettings';

class PotentialProduct extends React.Component { 

  state = {
    activeTab: 'asin', // 'asin' or 'search' or 'settings'
  }

  // This function called when tab changed (Seller Competition, Brand Competition)
  handleChangeTab = (event, newValue) => { 
    //console.log('newValue:', newValue);
    this.setState({
      activeTab: newValue,
    });
  }


  componentDidMount = () => {
    //console.log('PotentialProduct - componentDidMount()');
  }

  componentWillUnmount = () => {
    //console.log('PotentialProduct - componentWillUnmount()');
  }

  render() {
    const { activeTab } = this.state;
    
    // Show content for active tab, other tab content will be hidden.    
    let styleAsin = { display: 'none' };
    let styleSearch = { display: 'none' };
    let styleSettings = { display: 'none' };
    if (activeTab === 'asin')     { styleAsin = { display: 'block' } }
    if (activeTab === 'search')   { styleSearch = { display: 'block' } }
    if (activeTab === 'settings') { styleSettings = { display: 'block' } }
    
    return(
      <React.Fragment>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChangeTab}
          aria-label=""
          centered
        >
          <Tab label="via ASIN" value="asin" />
          <Tab label="via SEARCH" value="search" />
          <Tab label="Custom Settings" value="settings" />
        </Tabs>
        
        <div style={styleAsin} >
          <PotentialProductAsin />
        </div>

        <div style={styleSearch}>
          <PotentialProductSearch />
        </div>

        <div style={styleSettings}>
          <CustomSettings />
        </div>

      </React.Fragment>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(PotentialProduct);


import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Import firebase
import firebase from '../../helpers/firebaseApp';


class PageHeading extends React.Component { 

  render() {
    return(
      <React.Fragment>
        <Typography component="h2" variant="h6" display="inline" >
          { this.props.title } &nbsp;
        </Typography>
        { firebase.auth().currentUser && 
        <Typography variant="caption" display="inline" >
          { firebase.auth().currentUser.email }
        </Typography>
        }
        <div style={{ height: 10 }} ></div>
      </React.Fragment>
    )
  }

}


const styles = (theme) => ({

})

//export default App;
export default withStyles(styles)(PageHeading);

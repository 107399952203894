import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';


// Import constant 
import { isLoggedInToAmazon, deleteAmazonLoginDataFromDb, getAmazonAccessToken } from '../../helpers/firebaseHelper';

import { 
  LOGIN_WITH_AMAZON_AUTH_RETURN_URL,
} from '../../helpers/constants';



class LoginWithAmazon extends React.Component { 

  state = {

    // Amazon login status
    // At component load, we will check within db that current user is logged in 
    // to amazon or not and set this flag accordingly
    // '' - Not decided (default) , true - Logged in, false - Not logged in
    isAmzonLoggedIn: '',

    // Current logged in user profile info
    customerId: '',
    name: '',
    primaryEmail: '',

    // Message text
    showMessage: false,
    messageText: "",

    isProcessing: true,
  }

  componentDidMount = () => {
    console.log('LoginWithAmazon - componentDidMount()');

    // Check that current user logged in to amazon or not.
    this.checkAmazonLoginStatus();
  }

  
  //-----------------------------------------------------------------
  // START: Check amazon login status for current user
  //-----------------------------------------------------------------  
  // This function will check that current user is logged in with amazon or not.
  checkAmazonLoginStatus = () => {
    //console.log('checkAmazonLoginStatus()');

    // Check that current user logged in to amazon or not via helper function.
    isLoggedInToAmazon(this.isLoggedInToAmazon_Success, this.isLoggedInToAmazon_Error);
  }

  // It will give status either current user logged in to amazon or not.
  // true - logged in to amazon, false - not logged in to amazon.
  isLoggedInToAmazon_Success = (isLoggedIn) => {
    console.log('isLoggedInToAmazon_Success() isLoggedIn:', isLoggedIn);

    // Set login status
    this.setState({
      isAmzonLoggedIn: isLoggedIn,
      isProcessing: false,
    });

    // If logged in to amazon then show logged in user profile info
    if (isLoggedIn) {
      //this.showAmazonUserProfile();
    }
  }
  
  // If any error occur while checking the amazon login status, this function called.
  isLoggedInToAmazon_Error = (error) => {
    console.log('isLoggedInToAmazon_Error() error:', error);

    // Set login status false and show message
    this.setState({
      isAmzonLoggedIn: '',
      isProcessing: false,
      messageText: 'Error while checking login status, please refresh the page.',
      showMessage: true,
    });
  }
  //-----------------------------------------------------------------
  // END: Check amazon login status for current user
  //-----------------------------------------------------------------  

  
  //-----------------------------------------------------------------  
  // Show currently logged in amazon user profile
  //-----------------------------------------------------------------  
  showAmazonUserProfile = async () => {
    console.log('showAmazonUserProfile()');

    // 1 - Fetch current logged in user amazon access token
    const accessToken = await getAmazonAccessToken();
    //console.log('accessToken:', accessToken);

    // 2 - If access token empty then return
    if (accessToken === '') { 
      return;  // IMPORTANT
    } 

    // 3 - Fetch profile for current logged in amazon user.
    window.amazon.Login.retrieveProfile(accessToken, (response) => {
      //console.log('response:', response);

      if (response.success === true) {
        const { CustomerId, Name, PrimaryEmail } = response.profile;
        this.setState({
          customerId: CustomerId,
          name: Name,
          primaryEmail: PrimaryEmail
        });
      }

    });

  }
  //-----------------------------------------------------------------  
  

  //-----------------------------------------------------------------
  // START: logout from amazon related functions
  //-----------------------------------------------------------------  
  // Logout from amazon and delete amazon related login info from db
  // https://developer.amazon.com/docs/login-with-amazon/log-out-users.html
  onClickAmazonLogout = () => {
    console.log('onClickAmazonLogout()');

    // 1 - Set processing on 
    this.setState({
      isProcessing: true,
    })

    // 2 - Logout from amazon using amazon sdk
    if (window.amazon) {
      window.amazon.Login.logout();
      console.log('Logged out from amazon via amazon sdk.');
    }

    // 3 - Delete amazon login related data from db because we logged out from amazon 
    // via sdk, so access_token and refresh_token stored in our database is invalid now.
    deleteAmazonLoginDataFromDb(this.deleteAmazonLoginDataFromDb_Success, this.deleteAmazonLoginDataFromDb_Error);
  }

  // Amazon login related data deleted from db successfully.
  deleteAmazonLoginDataFromDb_Success = () => {
    console.log('deleteAmazonLoginDataFromDb_Success()');

    // Set login status false and show message
    this.setState({
      isAmzonLoggedIn: false,
      isProcessing: false,
      messageText: 'Logged out from amazon successfully.',
      showMessage: true,
    });
  }
  
  // Error while deleting amazon login related data from db.
  deleteAmazonLoginDataFromDb_Error = (error) => {
    console.log('deleteAmazonLoginDataFromDb_Error() error:', error);

    // Show error message
    this.setState({
      isAmzonLoggedIn: false,
      isProcessing: false,
      messageText: 'Error while amazon logout, please try again.',
      showMessage: true,
    });
  }
  //-----------------------------------------------------------------
  // END: logout from amazon related functions
  //-----------------------------------------------------------------  

  
  //-----------------------------------------------------------------
  // START: Amazon login related functions
  //-----------------------------------------------------------------  
  // Do amazon login via amazon login sdk
  // Help: https://developer.amazon.com/docs/login-with-amazon/web-docs.html
  onClickAmazonLogin = () => {
    console.log('onClickAmazonLogin()');

    // 1 - Prepare return url:
    // Once user will tap accept or cancel button from amazon consent screen, it will be 
    // redirected to this url with auth code or error information etc.
    // e.g. LOGIN_WITH_AMAZON_AUTH_RETURN_URL = 'http://localhost:3000/amazon-auth/NA' (dev)
    // OR  LOGIN_WITH_AMAZON_AUTH_RETURN_URL = 'https://plusamaze.com/amazon-auth/NA'  (live)
    // We will append region to return url NA for North America region
    const returnUrl = LOGIN_WITH_AMAZON_AUTH_RETURN_URL + '/NA';
    console.log('returnUrl:', returnUrl);

    // 2 - Create options for amazon login sdk.
    var options = {}
    options.scope = 'profile cpc_advertising:campaign_management';
    //options.scope = 'profile cpc_advertising:campaign_management advertising::campaign_management';
    options.response_type = 'code';
    options.popup = false;  // false - Auth via redirect, true - Auth via popup

    // 3 - Amazon Auth via redirect method.
    // It will redirect user to amazon consent screen, once user approve or cancel from consent screen,
    // it will come back to return url with appropriate parameter as shown under.
    // Below is the sample data we will receive at return url.
    // NA - North America region
    // e.g. http://plusamaze.com/amazon-auth/NA?code=ANpZvlMDMrLlxxxxx&scope=cpc_advertising%3Acampaign_management
    // e.g. http://plusamaze.com/amazon-auth/NA?error_description=An+unknown+scope+was+requested&error=invalid_scope
    //
    // Here amazon sdk doc says that, it will redirect to this url for auth: https://www.amazon.com/ap/oa
    // https://developer.amazon.com/docs/login-with-amazon/authorization-code-grant.html
    window.amazon.Login.authorize(options, returnUrl);
    
    // 4 - Return false
    // Actually #3 will redirect user to amazon auth consent screen, so here we return false.
    return false;
  }
  //-----------------------------------------------------------------
  // END: Amazon login related functions
  //-----------------------------------------------------------------  


  // Called when message close after time out
  handleClose = () => {
    //console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }


  // Render form content 
  renderForm = () => { 
    const { isAmzonLoggedIn, isProcessing } = this.state;
    //const { classes } = this.props;
  
    return(
      <React.Fragment>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" component="h1">
                Advertising Automation
              </Typography>
            </Grid>

            <Grid item xs={12} sm={8} style={{ textAlign: 'left' }} >
              
              { /* If current user amazon login status not decided yet, then show processing message with wait */
                isAmzonLoggedIn === '' && 
                <React.Fragment>
                  <Typography variant="body1" >
                    Checking amazon login status, please wait...
                  </Typography>
                </React.Fragment>
              }
              
              { /* If current user not logged into amazon, show amazon login button. */ 
                isAmzonLoggedIn === false &&
                <img
                  border="0" 
                  alt="Login with Amazon" 
                  src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gry_312x64.png" 
                  width="200" 
                  onClick={ () => this.onClickAmazonLogin() } 
                  id="LoginWithAmazon" 
                />
              }

              { /* If current user logged in to amazon, show amazon user profile. */ 
                isAmzonLoggedIn === true && this.state.name !== '' &&
                <React.Fragment>
                  <Typography variant="body1" >
                    You are logged in to Amazon. &nbsp;
                    <br /><br />
                    <b>Name:</b> { this.state.name } <br />
                    <b>PrimaryEmail:</b> { this.state.primaryEmail } <br />
                    <b>CustomerId:</b> { this.state.customerId } <br />
                  </Typography>
                </React.Fragment>
              }
            </Grid>

            <Grid item xs={12} sm={4} style={{ textAlign: 'right' }} >
            { /* If current user logged in to amazon, show amazon logout button. */ 
                isAmzonLoggedIn === true &&
                <Button 
                  variant="outlined"
                  size="small"
                  onClick={ () => this.onClickAmazonLogout() } 
                  //style={{marginRight: 0, marginBottom: 0, display: 'inline-block' }} 
                  disabled={isProcessing}
                >Logout from Amazon</Button>
              }
            </Grid>

          </Grid>
        </form>
      </React.Fragment>
    );
  }


  render() {
    //const { classes } = this.props;
    const { isProcessing } = this.state;  

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <React.Fragment>
        <Paper elevation={2} style={{padding: 20}} >
          { this.renderForm() }
        </Paper >

        { isProcessing && <LinearProgress /> }

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </React.Fragment>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


LoginWithAmazon.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(LoginWithAmazon);
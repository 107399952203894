import React from "react";
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Import custom ui component
//import PageHeading from './common/PageHeading';
import TopNavBar from './common/TopNavBar';
import Typography from '@material-ui/core/Typography';

// Puppeteer api related component
import ScrapViaPuppeteer from './others/ScapViaPuppeteer';
import Get999QtyForASIN from './others/Get999QtyForASIN';

// Import component related to MWS api
import ListMatchingProducts from './others/ListMatchingProducts';
import GetMatchingProductForId from './others/GetMatchingProductForId';
import GetCompetitivePricingForASIN from './others/GetCompetitivePricingForASIN';
import GetLowestOfferListingsForASIN from './others/GetLowestOfferListingsForASIN';
import GetLowestPricedOffersForASIN from './others/GetLowestPricedOffersForASIN';
import GetMyFeesEstimate from './others/GetMyFeesEstimate';
import GetMyPriceForASIN from './others/GetMyPriceForASIN';
import GetProductCategoriesForASIN from './others/GetProductCategoriesForASIN';

// Import component related to amazon advertisement api
import SponsoredProductsGetCampaigns from './others/SponsoredProductsGetCampaigns';
import SponsoredProductsCreateCampaign from './others/SponsoredProductsCreateCampaign';

import SponsoredProductsGetAdGroups from './others/SponsoredProductsGetAdGroups';
import SponsoredProductsCreateAdGroup from './others/SponsoredProductsCreateAdGroup';

import SponsoredProductsGetProductAds from './others/SponsoredProductsGetProductAds';
import SponsoredProductsCreateProductAd from './others/SponsoredProductsCreateProductAd';

import SponsoredProductsGetKeywords from './others/SponsoredProductsGetKeywords';
import SponsoredProductsCreateKeyword from './others/SponsoredProductsCreateKeyword';

import SponsoredProductsGetNegativeKeywords from './others/SponsoredProductsGetNegativeKeywords';
import SponsoredProductsCreateNegativeKeyword from './others/SponsoredProductsCreateNegativeKeyword';

import DspGetAdvertisers from './others/DspGetAdvertisers';

import SPGetSuggestedKeywordsForAsins from './others/SPGetSuggestedKeywordsForAsins';
import SPGetKeywordsRecommendationForAsins from './others/SPGetKeywordsRecommendationForAsins';
import SPGetBidRecommendationsForKeywords from './others/SPGetBidRecommendationsForKeywords';

import SPRequestReport from './others/SPRequestReport';
import SPGetReport from './others/SPGetReport';
import SPDownloadReport from './others/SPDownloadReport';

// Import helper function
import { getProfiles } from '../helpers/amazonAdHelper';


class OthersPage extends React.Component { 

  state = {
    // We will fillup profile list and set it here
    // It is used for Amazon advertisement related module.
    profileList: [],
  }

  componentDidMount = () => {
    console.log('OthersPage - componentDidMount()');

    // Call api to get profile list for current amazon user.
    // This profile list used by multiple component added on this page 
    // so we put common logic here. So every child component dont need to 
    // call fetch profiles api. We are passing profileList as a props
    // to child component who need it it.
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  componentWillUnmount = () => {
    console.log('OthersPage - componentWillUnmount()');
  }


  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    console.log('OthersPage - getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({ 
        profileList: result.data 
      });
    }

    if (result.status === 'error') {
      console.log('OthersPage - Error while fetch profiles');
    }
  }

  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('OthersPage - getProfiles_Error() error:', error);
  }


  render() {
    return(
      <div>
        <TopNavBar activeTab='others' />
        { /* <PageHeading title="Others" />  */ }
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ScrapViaPuppeteer />
            <Get999QtyForASIN />
            <GetMyFeesEstimate />
            <GetMyPriceForASIN />
            <GetProductCategoriesForASIN />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListMatchingProducts />
            <GetMatchingProductForId />
            <GetCompetitivePricingForASIN />
            <GetLowestOfferListingsForASIN />
            <GetLowestPricedOffersForASIN />
          </Grid>
        </Grid>
        
        <br />
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <h3>Amazon Advertising</h3>
            <Typography variant="subtitle2" gutterBottom>
              Amazon login required to test below api:
            </Typography>
          </Grid>
        </Grid>
        
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SponsoredProductsGetCampaigns profileList={this.state.profileList} />
            <SponsoredProductsCreateCampaign profileList={this.state.profileList} />
            <br />
            <SponsoredProductsGetAdGroups profileList={this.state.profileList} />
            <SponsoredProductsCreateAdGroup profileList={this.state.profileList} />
            <br />
            <SponsoredProductsGetProductAds profileList={this.state.profileList} />
            <SponsoredProductsCreateProductAd profileList={this.state.profileList} />
            <br />
            <SponsoredProductsGetKeywords profileList={this.state.profileList} />
            <SponsoredProductsCreateKeyword profileList={this.state.profileList} />
            <br />
            <SponsoredProductsGetNegativeKeywords profileList={this.state.profileList} />
            <SponsoredProductsCreateNegativeKeyword profileList={this.state.profileList} />
            <br />
          </Grid>
          <Grid item xs={12} sm={6}>
            <br />
            <DspGetAdvertisers profileList={this.state.profileList} />
            <br />
            <SPGetSuggestedKeywordsForAsins profileList={this.state.profileList} />
            <SPGetKeywordsRecommendationForAsins profileList={this.state.profileList} />
            <SPGetBidRecommendationsForKeywords profileList={this.state.profileList} />
            <br />
            <SPRequestReport profileList={this.state.profileList} /> 
            <SPGetReport profileList={this.state.profileList} /> 
            <SPDownloadReport profileList={this.state.profileList} /> 
          </Grid>
        </Grid>
        
        <br /><br /><br />

      </div>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(OthersPage);


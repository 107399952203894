
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyBoOJVdUE7wdkybSO08MBM2nW9LSAj5IWo",
  authDomain: "plusamaze.firebaseapp.com",
  databaseURL: "https://plusamaze.firebaseio.com",
  projectId: "plusamaze",
  storageBucket: "plusamaze.appspot.com",
  messagingSenderId: "461298308827",
  appId: "1:461298308827:web:3fb304f25425b12d571efe",
  measurementId: "G-JTTKNB9YQ2"
};


let defaultApp; 
if (!firebase.apps.length) {
  defaultApp = firebase.initializeApp(firebaseConfig);
  console.log("Firebase Project Initialized");  
}else {
  defaultApp = firebase.app();
}

// Export firebase app
export default defaultApp;

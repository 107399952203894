// Amazon Advertising Api: Sponsored Products - Get an array of campaigns
// GET: /v2/sp/campaigns
// 
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';


// Import helper functions, constant etc.
//import { marketPlaces } from '../../helpers/constants';
import { spGetCampaigns } from '../../helpers/amazonAdHelper';
//import { getProfiles } from '../../helpers/amazonAdHelper';

class SponsoredProductsGetCampaigns  extends React.Component {
  
  state = {

    // Form input data
    profileId: '',    // Selected profile id

    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],

    // We will fetch campaign list from amazon and set it here.
    // e.g. campaignList: [
    //  {
    //    "portfolioId": 0,
    //    "campaignId": 0,
    //    "name": "string",
    //    "campaignType": "sponsoredProducts",
    //    "targetingType": "manual",
    //    "state": "enabled",
    //    "dailyBudget": 0,
    //    "startDate": "string",
    //    "endDate": "string",
    //    "premiumBidAdjustment": true,
    //    "bidding": {
    //      "strategy": "legacyForSales",
    //      "adjustments": [
    //        {
    //          "predicate": "placementTop",
    //          "percentage": 0
    //        }
    //      ]
    //    }
    //  }
    // ]
    campaignList: null,
    
    // If any error occur during some operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SponsoredProductsGetCampaigns - componentDidMount()');

    // Fetch amazon profile list
    // Commented: because we passed profileList as props from parent component 
    //this.fetchProfileList();
  }

  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass the profile Id to get campain list api, so we 
  // first will fetch profile list and fillup drop down, so user 
  // can choose profile from there and click Get Camapaign List button
  // to fetch campain list for the selected profile.
  fetchProfileList = () => {
    console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    // Commented: because we passed profileList as props from parent component     
    //getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }    
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------

  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',
      campaignList: null,
      profileId: '',
      isProcessing: false,
    });
  }

  //-----------------------------------------------------------------
  // Start: Fetch campaign list
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    console.log('onClickSubmit()');

    // 1 - Get profileId from the state
    const { profileId } = this.state;

    // 2 - If profileId not selected then return
    if (!profileId || profileId === '') {
      return;
    }

    // 3 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 4 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/listCampaigns
    const queryData = {
      startIndex: 0,
      count: 100,
      stateFilter: 'enabled,paused,archived',
    }

    // 5 - Call api to Get sponsored products campaign list
    spGetCampaigns(profileId, queryData, this.spGetCampaigns_Success, this.spGetCampaigns_Error);
  }

  // Called when campaign list fetched successfully
  spGetCampaigns_Success = (result) => {
    console.log('spGetCampaigns_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        campaignList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while fetch campaign list
  spGetCampaigns_Error = (error) => {
    console.log('spGetCampaigns_Error() error:', error);
    
    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch campaign list
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="demo-simple-select-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="Profile"
              value={profileId}
              onChange={(e) => this.setState({ profileId: e.target.value })}
              disabled={isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { id, type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {id} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          { 
            //profileId !== '' && this.renderSelectedProfileInfo() 
          }
        </Grid>
      </Grid>
    );
  }

  // Show currently selected profile info
  renderSelectedProfileInfo = () => {
    console.log('renderSelectedProfileInfo()');

    const { profileId, profileList } = this.state;
    
    let selectedProfile;
    profileList.forEach( (profile, index) => {
      if (profile.profileId === profileId) {
        selectedProfile = profile;
      }
    });

    if (!selectedProfile) { return null; }

    return (
      <React.Fragment>
        profileId: { selectedProfile.profileId } <br />
        countryCode: { selectedProfile.countryCode } <br />
        currencyCode: { selectedProfile.currencyCode } <br />
        dailyBudget: { selectedProfile.dailyBudget } <br />
        timezone: { selectedProfile.timezone } <br />
        accountInfo.marketplaceStringId: { selectedProfile.accountInfo.marketplaceStringId } <br />
        accountInfo.id: { selectedProfile.accountInfo.id } <br />
        accountInfo.type: { selectedProfile.accountInfo.type } <br />
        accountInfo.name: { selectedProfile.accountInfo.name } <br />
      </React.Fragment>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' }
        >Get Campaign List</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }
  
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { campaignList, profileId } = this.state;

    // CampaignList not exist then do not show anything
    if (!campaignList) { return null; }

    // If campaign list empty then show message.
    if (campaignList.length === 0) {
      return ( 
        <div align='left'>
          <h3>Campaign list empty for profileId: { profileId }</h3>
        </div>
      );
    }

    // If campaign list exist then render its content
    return (
      <div align='left'>
        <b>Campaign List for: { profileId } </b> <br />
        <hr />
        { 
          campaignList.map( (campaign, index) => {
            return (
              <div key={ 'campain_' + index } >
                <b>#{ index + 1}</b> <br />
                <b>portfolioId:</b> { campaign.portfolioId } <br />
                <b>campaignId:</b> { campaign.campaignId } <br />
                <b>name:</b> { campaign.name } <br />
                <b>campaignType:</b> { campaign.campaignType } <br />
                <b>targetingType:</b> { campaign.targetingType } <br /> 
                <b>state:</b> { campaign.state } <br />
                <b>dailyBudget:</b> { campaign.dailyBudget } <br />
                <b>startDate:</b> { campaign.startDate } <br />
                <b>endDate:</b> { campaign.endDate } <br />
                <b>premiumBidAdjustment:</b> { campaign.premiumBidAdjustment } <br />
                <b>bidding.strategy:</b> { campaign.bidding.strategy } <br />
                <b>bidding.adjustments:</b> { JSON.stringify(campaign.bidding.adjustments) } <br />
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - GetCampaigns</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SponsoredProductsGetCampaigns.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SponsoredProductsGetCampaigns);


// List (Search) matching product via amazon mws api created on cloud run

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


// Import helper functions, constant etc.
import { listMatchingProducts } from '../../helpers/mwsHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);


class ListMatchingProducts extends React.Component {
  
  state = {
    // Marketplace selection
    market: 'CA',

    // Search query
    query: 'Pen Drive',   // e.g. Pen Drive

    status: '',
    error: '',

    headers: null,
    products: [],
    responseMetadata: null,
    statusCode: 0,

    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('ListMatchingProducts - componentDidMount()');
  }


  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      headers: null,
      products: [],
      responseMetadata: null,
      statusCode: 0,
    });
  }


  onClickSubmit = () => {
    console.log('onClickSubmit()');

    // 1 - Fetch data from state
    const { market, query } = this.state;
    if (!market || market === '') { return };
    if (!query || query === '') { return };

    // 2 - Make value empty
    this.setState({
      status: '',
      data: {},
      error: '',
      isProcessing: true,
    });

    // 3 - Call api to scrap url
    listMatchingProducts(query, market, this.listMatchingProductsSuccess, this.listMatchingProductsError);
  }

  // Called when api call success (listMatchingProducts)
  // e.g. Sample Data 
  // data = {
  //  Products: { 
  //    Product: [ {Object}, {Object}, ... ]
  //  },
  //  ResponseMetadata: { RequestId: '5b8c173c-7476-4c42-8381-e9241b5d7b78' },
  //  Headers: { 
  //    'x-mws-quota-max': '720.0',
  //    'x-mws-quota-remaining': '720.0',
  //    'x-mws-quota-resetson': '2020-07-13T08:38:00.000Z',
  //    'x-mws-timestamp': '2020-07-13T08:34:01.802Z',
  //    'content-type': 'text/xml',
  //    'content-charset': 'unknown',
  //    'content-length': '23738',
  //    'content-md5': 'unknown',
  //    date: 'Mon, 13 Jul 2020 08:34:01 GMT' 
  //  }
  // }
  listMatchingProductsSuccess = (result) => {
    console.log("listMatchingProductsSuccess() result:", result);
    
    const { status, data, error } = result;
  
    this.setState({
      status: status,
      error: error,
      
      headers: data.Headers,
      products: data.Products.Product,
      responseMetadata: data.ResponseMetadata,
      statusCode: data.StatusCode,

      isProcessing: false,
    });
  }

  // Called if error while api call (listMatchingProducts)
  listMatchingProductsError = (error) => {
    console.log("listMatchingProductsError() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }


  // Return single product element
  renderSingleProduct = (product, index) => {

    // Fetch product info
    const { ASIN, MarketplaceId } = product.Identifiers.MarketplaceASIN;
    const { SalesRank } = product.SalesRankings;
    const { ItemAttributes } = product.AttributeSets;

    // Prepare attribute element
    const attributeKeys = Object.keys(ItemAttributes);
    const attributesEl = attributeKeys.map((key, index) => {
      
      const singleAttr = ItemAttributes[key];
      let attrValue;
      if (typeof singleAttr === "object") { // Json data object
        attrValue = JSON.stringify(singleAttr);
      } else {  // Single value
        attrValue = singleAttr;
      } 

      return(
        <div key={'attribute-' + key}>
          <b>{key}</b>: { attrValue } <br />
        </div>);
    });

    // Return single product element
    return (
      <div key={ 'product-' + index } style={{ margin: 10 }} >
        <b># {index + 1}</b> <br />
        <b>ASIN:</b> {ASIN} &nbsp;&nbsp;&nbsp; <b>MarketplaceId:</b> {MarketplaceId} <br />
        <b>SALES RANKINGS:</b> { JSON.stringify(SalesRank) } <br />
        <br />
        { attributesEl }
        <br />
        <hr />
      </div>
    );
  }

  // Render all product list
  renderProductList = (products) => {
    
    const allProductsEl = products.map( (product, index) => {
      return this.renderSingleProduct(product, index);
    });

    // Return all product element
    return(<>{ allProductsEl }</>);
  }


  renderHeaderInfo = (headers) => {
    const keys = Object.keys(headers);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {headers[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderResponseMetaData = (responseMetadata) => {
    const keys = Object.keys(responseMetadata);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {responseMetadata[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }


  renderForm = () => {
    const { isProcessing } = this.state;
    const { classes } = this.props;
    
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    markePlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <TextField 
                  id="query" 
                  label="Search Query" 
                  placeholder="" 
                  value={this.state.query} 
                  onChange={(e) => this.setState({ query: e.target.value })} 
                  size="small" 
                  disabled={isProcessing}
                />
              </FormControl>
          </Grid>
        </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        {isProcessing &&
          <b>Processing... &nbsp;&nbsp;&nbsp; </b>
        }
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={isProcessing}
        >Submit</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }

  renderResult = () => {
    const { status, error, headers, responseMetadata, products } = this.state;
    return (
      <div align='left'>
        { status !== '' && 
          <><b>Status:</b> {status}<hr /></>
        }
        { products.length > 0 && 
          <><h3>Product List: ({products.length})</h3>{ this.renderProductList(products) }</>
        }
        { responseMetadata && 
          <><h3>Response Header:</h3>{ this.renderHeaderInfo(headers) }<hr /></>
        }
        { responseMetadata && 
          <><h3>Response Metadata:</h3>{ this.renderResponseMetaData(responseMetadata) }<hr /></>
        }
        { error &&
          <>
            <b>Error:</b>
            <div style={{width: '100%', overflow: 'scroll', border: '0px solid #cdcdcd' }}>
              { error && error.toString() }
            </div>
          </>
        }
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>MWS - ListMatchingProducts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            { this.renderFormButton() }
            { this.renderResult() }
          </Box>
        </AccordionDetails>
      </Accordion>
    );

  }
}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


ListMatchingProducts.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(ListMatchingProducts);
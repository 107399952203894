import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import EmailPasswordLogin from './EmailPasswordLogin';
import EmailPasswordRegister from './EmailPasswordRegister';


class EmailPassword extends React.Component {

  // Set default state 
  state = {
    activeTab: 'login',   // 'login' or 'register'
  };

  handleTabChange = (event, newValue) => {
    //console.log('newValue:', newValue);
    this.setState({
      activeTab: newValue,
    });
  };


  render() {
    const { activeTab } = this.state;
    return (
      <Paper elevation={2} >
        <Tabs
          variant="fullWidth"
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
          aria-label="Login/Register with email/password"
        >
          <Tab label="Login" value='login' />
          <Tab label="Register" value='register' />
        </Tabs>
        { activeTab === 'login'    && <EmailPasswordLogin /> }
        { activeTab === 'register' && <EmailPasswordRegister /> }
      </Paper>
    );
  }

}

export default EmailPassword;
// This component will used to find the potential product using asin:
//---------------------------------------------------------------
// How to decide potential product
//---------------------------------------------------------------
// 1) Does the product have a BSR between 500 - 2,000? YES / NO
// 2) Is the product’s selling price between $20 - $65? YES / NO
// 3) Are the total number of reviews less than 1,000? YES / NO
// 4) Is the product weight less than 5 lbs? YES / NO
// 5) Is the product category in our list of Unrestricted Categories? YES / NO
// 6) Number of sellers selling this products?  5 - 10?  YES / NO 
// RESULT:
// 6/6 - High Potential
// 1/6 - Low Potential
//---------------------------------------------------------------

import React from "react";
import { withStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

// Import custom component
import PotentialProductAsinForm from './PotentialProductAsinForm';

// Import helper functions
import { getCompetitivePricingForASIN, getProductCategoriesForASIN, getMatchingProductForId } from '../../helpers/mwsHelper';
import { getProductReviewInfo } from '../../helpers/apiHelper';
import { getToolSettings, getToolSettingsForUser, getUserType } from '../../helpers/firebaseHelper';
import { restrictedCategoriesDefault } from './restricted-category';
import { marketPlaces } from '../../helpers/constants';

const sliderMarks = [ { value: 0, label: '0' },
      { value: 10, label: '' }, { value: 20, label: '20' }, 
      { value: 30, label: '' }, { value: 40, label: '40' }, 
      { value: 50, label: '' }, { value: 60, label: '60' },
      { value: 70, label: '' }, { value: 80, label: '80' },
      { value: 90, label: '' }, { value: 100, label: '100' },
    ];


class PotentialProductAsin extends React.Component { 

  // Class level variable
  toolSettingsDefault = null;   // Default Settings for potential product
  toolSettingsCustom = null;    // User level/custom settings for potential product

  // Grab the default restritcted category list.
  // If some category approved by user then we will remove it from this list.
  // e.g. restrictedCategories = { 1: 'Category1', 2: 'Category2', ... }
  restrictedCategories = { ...restrictedCategoriesDefault };


  state = { 

    // Current user type 
    userType: '',   // '' or 'admin'

    // Settings we have to consider while calculate potential product.
    // Note: we will fetch default and User level Settings for potential product tool.
    // Thereafter we will override default settings with user specific/custon settings 
    // and common data object and set it here.
    // e.g. toolSettings = { 
    //   bsr: { min: 500, max: 2000 },
    //   review_count: { min: 0, max: 1000 },
    //   seller_count: { min: 1, max: 10 },
    //   selling_price: { min: 20, max: 65 },
    //   weight: { min: 0, max: 5, unit: 'lbs' },
    // }
    toolSettings: null, 
    toolSettingsFetched: false, 

    // form data will be stored here
    asin: '',
    market: '',

    // We will do various api call and find below value for the asin.
    // Does the product have a BSR between 500 - 2,000? YES / NO
    bsr: null,
    sellingPrice: null,
    reviewCount: null,
    weight: null,
    productCategory: null,
    numberOfSellers: null,

    // Once each value fetched we will decide yes/no for each status.
    bsrStatus: null,              // 'yes' or 'no'
    sellingPriceStatus: null,     // 'yes' or 'no'
    reviewCountStatus: null,      // 'yes' or 'no'
    weightStatus: null,           // 'yes' or 'no'
    productCategoryStatus: null,  // 'yes' or 'no'
    numberOfSellersStatus: null,  // 'yes' or 'no'

    // We will fetch this value via mws api and set it here.
    title: null,          // Product title
    imageUrl: null,       // Product image url
    imageWidth: null,     // Product image width
    imageHeight: null,    // Product image height

    // true - processing in progress, false - not processing anything
    // It will show progress bar when processing true.
    isProcessing: false,

    // Message text
    showMessage: false, // true - show message, false - hide message
    messageText: '',    // message test supose to show
  }

  componentDidMount = () => {
    console.log('PotentialProductAsin - componentDidMount()');

    // Fetch default settings for potential calculator
    // Note: wll not enable form until tooSettings data ready.
    getToolSettings('potential_product', this.getToolSettings_Success, this.getToolSettings_Error);

    // Fetch current user type
    getUserType(this.getUserTypeSuccess, this.getUserTypeError);
  }

  componentWillUnmount = () => {
    console.log('PotentialProductAsin - componentWillUnmount()');
  }

  getUserTypeSuccess = (userType) => { this.setState({ userType: userType }) }
  getUserTypeError = (error) => { /* Do nothing */ }

  
  // Default settings fetched for potential product tool.
  getToolSettings_Success = (data) => {
    //console.log('getToolSettings_Success() data:', data);
    
    // 1 - Set value within class variable, no need to set within state, 
    // because we will not show this value within ui directly.
    this.toolSettingsDefault = data;

    // 2 - Fetch potential product tool settings for current user
    getToolSettingsForUser('potential_product', this.getToolSettingsForUser_Success, this.getToolSettingsForUser_Error);
  }
  
  // Error while fetching default settings
  getToolSettings_Error = (error) => {
    console.log('getToolSettings_Error() error:', error);
  }

  // Tool settings fetched success (for current user)
  getToolSettingsForUser_Success = (data) => {
    //console.log('getToolSettingsForUser_Success() data:', data);
    
    // 1 - Set value within class variable, no need to set within state, 
    // because we will not show this value within ui directly.
    if (data) {
      this.toolSettingsCustom = data;
    }

    // 2 - Combine value received from default and user level settings for potential product tool.
    this.combineDefaultAndCustomSettings();
  }

  // Tool settings fetch error (for current user)
  getToolSettingsForUser_Error = (error) => {
    console.log('getToolSettingsForUser_Error() error:', error);
  }

  // This function will combine Default and User level settings for 
  // potential product tool and make common value for it.
  // i.e. It will take default settings and override with user level settings. 
  combineDefaultAndCustomSettings = () => {
    //console.log('combineDefaultAndCustomSettings()');

    // 1 - Fetch custom and default settings from class
    const defaultSettings = this.toolSettingsDefault;
    const customSettings  = this.toolSettingsCustom;

    // 2 - Assign default settings to local variable 
    let bsrMin = defaultSettings.bsr.min;
    let bsrMax = defaultSettings.bsr.max;
    let reviewCountMin = defaultSettings.review_count.min;
    let reviewCountMax = defaultSettings.review_count.max;
    let sellerCountMin = defaultSettings.seller_count.min;
    let sellerCountMax = defaultSettings.seller_count.max;
    let sellingPriceMin = defaultSettings.selling_price.min;
    let sellingPriceMax = defaultSettings.selling_price.max;
    let weightMin = defaultSettings.weight.min;
    let weightMax = defaultSettings.weight.max;
    let weightUnit = defaultSettings.weight.unit;
    let approvedCategory = {};

    // 3 - Override default settings with custom settings if custom settings data exist.
    if (customSettings) {
      if (customSettings.bsr) {
        if (customSettings.bsr.min) { bsrMin = customSettings.bsr.min; }
        if (customSettings.bsr.max) { bsrMax = customSettings.bsr.max; }
      }
      if (customSettings.review_count) {
        if (customSettings.review_count.min) { reviewCountMin = customSettings.review_count.min; }
        if (customSettings.review_count.max) { reviewCountMax = customSettings.review_count.max; }
      }
      if (customSettings.seller_count) {
        if (customSettings.seller_count.min) { sellerCountMin = customSettings.seller_count.min; }
        if (customSettings.seller_count.max) { sellerCountMax = customSettings.seller_count.max; }
      }
      if (customSettings.selling_price) {
        if (customSettings.selling_price.min) { sellingPriceMin = customSettings.selling_price.min; }
        if (customSettings.selling_price.max) { sellingPriceMax = customSettings.selling_price.max; }
      }
      if (customSettings.weight) {
        if (customSettings.weight.min) { weightMin = customSettings.weight.min; }
        if (customSettings.weight.max) { weightMax = customSettings.weight.max; }
        if (customSettings.weight.unit) { weightUnit = customSettings.weight.unit; }
      }
      // e.g. customSettings.approved_category = { 1: 'Category1', 2: 'Category2', ... }
      if (customSettings.approved_category) {
        approvedCategory = customSettings.approved_category;
      }
    }

    // 4 - Prepare data for settings
    const toolSettingsCombined = {
      bsr: { min: bsrMin, max: bsrMax },
      review_count: { min: reviewCountMin, max: reviewCountMax },
      seller_count: { min: sellerCountMin, max: sellerCountMax },
      selling_price: { min: sellingPriceMin, max: sellingPriceMax },
      weight: { min: weightMin, max: weightMax, unit: weightUnit },
      approved_category: approvedCategory,
    }
    console.log('toolSettingsCombined:', toolSettingsCombined);

    // 5 - Set settings data within state
    this.setState({
      toolSettings: toolSettingsCombined,
      toolSettingsFetched: true,
    });

    // 6 - Remove approved category from default restricted category list
    // and save it within class level variable.
    const filteredCategory = {};
    Object.keys(this.restrictedCategories).forEach( (key, index) => {
      if( approvedCategory[key] === undefined ) {
        filteredCategory[key] = this.restrictedCategories[key];
      }
    });
    this.restrictedCategories = filteredCategory;
    //console.log('this.restrictedCategories:', this.restrictedCategories);
  }


  // Called when submit button tapped via form component, this function will be called 
  // with market and asin value.
  onSubmitForm = (market, asin) => {
    console.log('onSubmitForm() asin:', asin, ' market:', market);

    // Note: Here we have to call multiple api, but we must call it sequential.
    // So we will call first api, once it's results come we will call second api, then third etc.
    // We need to find below information using multiple api call:
    // Call First Api - Fetch Review count (#1)
    // Call Second Api - Fetch Selling Price, Sellers Count, and BSR (#2-3-4)
    // Call Third Api - Fetch product weight (#5)
    // Call Fourth Api - Fetch category name (#6)

    // Set processing true and call various api.
    // Also Save form data to state, so we can use it later on.
    this.setState(
      {
        isProcessing: true,
        asin: asin, 
        market: market,

        bsr: null,
        sellingPrice: null,
        reviewCount: null,
        weight: null,
        productCategory: null,
        numberOfSellers: null,
  
        bsrStatus: null,
        sellingPriceStatus: null,
        reviewCountStatus: null,
        weightStatus: null,
        productCategoryStatus: null,
        numberOfSellersStatus: null,

        title: null,          // Product title
        imageUrl: null,       // Product image url
        imageWidth: null,     // Product image width
        imageHeight: null,    // Product image height    
      }, 
      // Wait until state value set, thereafter Call First Api - Fetch Review count (#1)
      this.callFirstApi
    );

  }

  // First Api - Fetch review count (#1)
  callFirstApi = () => {
    console.log('callFirstApi() - Fetch Review Count');
    const { asin, market } = this.state;
    getProductReviewInfo(asin, market, this.getProductReviewInfo_Success, this.getProductReviewInfo_Error);
  }

  // Second Api - Fetch Selling Price, Sellers Count, and BSR (#2-3-4)
  callSecondApi = () => { 
    console.log('callSecondApi() - Fetch Selling Price, Sellers Count, BSR');
    const { asin, market } = this.state;
    getCompetitivePricingForASIN(market, asin, this.getCompetitivePricingForASIN_Success, this.getCompetitivePricingForASIN_Error);
  }

  // Third Api - Call api to fetch product weight (#5)
  callThirdApi = () => {
    console.log('callThirdApi() - Fetch Weight');
    const { asin, market } = this.state;
    const idType = 'ASIN';
    const idValues = asin;
    getMatchingProductForId(market, idType, idValues, this.getMatchingProductForId_Success, this.getMatchingProductForId_Error);
  }

  // Fourth Api - Fetch category name (#6)
  callFourthApi = () => {
    console.log('callFourthApi() - Fetch Category Name');
    const { asin, market } = this.state;
    getProductCategoriesForASIN(market, asin, this.getProductCategoriesForASIN_Success, this.getProductCategoriesForASIN_Error);    
  }


  //-----------------------------------------------------------------
  // START: 1 - Fetch review count
  //-----------------------------------------------------------------  
  getProductReviewInfo_Success = (result, asin) => {
    console.log('getProductReviewInfo_Success() result:', result, ' asin', asin);

    const { data } = result;
    const { toolSettings } = this.state;

    // Fetch review count and its status
    let reviewCountStatus = 'no';
    if ( data.review_count > toolSettings.review_count.min && data.review_count < toolSettings.review_count.max ) { 
      reviewCountStatus = 'yes'; // reviews less than 1,000, so yes
    }

    // Set value within state
    this.setState({
      reviewCount: data.review_count,
      reviewCountStatus: reviewCountStatus,
    });

    // Check that all status fetched or not
    this.checkAllStatusDoneOrNot();

    // Call Second Api - Fetch Selling Price, Sellers Count, and BSR (#2-3-4)
    setTimeout(this.callSecondApi, 200);
  }

  getProductReviewInfo_Error = (error) => {
    console.log('getProductReviewInfo_Error() error:', error);
    
    // Show error message, but do not stop processing
    this.setState({
      showMessage: true,
      messageText: 'BSR/Review info fetch error, please try again.',
      //isProcessing: false,
    });

    // Call Second Api - Fetch Selling Price, Sellers Count, and BSR (#2-3-4)
    setTimeout(this.callSecondApi, 200);
  }
  //-----------------------------------------------------------------
  // END: 1 - Fetch review count
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: 2-3-4 Fetch Selling Price, Sellers Count, and BSR
  //-----------------------------------------------------------------  
  getCompetitivePricingForASIN_Success = (result) => {
    //console.log("getCompetitivePricingForASIN_Success()");
    console.log("getCompetitivePricingForASIN_Success() result:", result);

    // 1 - Get necessary data from the result
    //const { data, status, error } = result;
    const { data } = result;
    const { toolSettings } = this.state;

    // 2 - In our case we will get single product data object within result,
    // because we passed one asin during api call.
    const product = data.Product;

    // 3 - Find listing price and number of sellers for each product
    if (product) {
      // A - Grab listing price for product and decide Selling price
      // Sometime product listing price not available due to item currently unavailable, 
      // So we will show message if price not avalilable. We will continue operation
      // to show potential product although selling price not available.
      let sellingPrice = 0;
      let sellingPriceCurrencyCode = '';
      const { CompetitivePrice } = product.CompetitivePricing.CompetitivePrices;
      console.log('CompetitivePrice:', CompetitivePrice);
      if (!CompetitivePrice) { // CompetitivePrice not exist
        console.log('CompetitivePrice Not Exist');
        this.setState({
          showMessage: true,
          messageText: "Selling Price is not exist because product may be unavailable at this moment.",
        });
      } else { // CompetitivePrice exist
        console.log('CompetitivePrice Exist');
        // CompetitivePrice may be single data object or array, So we have to handle it. 
        // If array then it may have 'Used' or 'New' condition item price so we have to 
        // find out price for a 'New' codition.
        // CompetitivePrice may be single data object or array, So we have to handle it. 
        // If array then it may have 'Used' or 'New' condition item price so we have to 
        // find out price for a 'New' codition.
        let ListingPrice;
        if (Array.isArray(CompetitivePrice)) { // If array (multiple price)
          console.log('CompetitivePrice Is Array');
          // Loop array and find item ListingPrice whose condition is 'New'.
          CompetitivePrice.forEach( (item, index) => { 
            if (!ListingPrice ) { // listing price not found yet then grab its value.
              if (item.condition === 'New') { // If item condition 'New' then grab price info.
                ListingPrice = item.Price.ListingPrice;
              }
            }
          });
        } else { // Single data object (not array)
          console.log('CompetitivePrice Not array');
          ListingPrice = CompetitivePrice.Price.ListingPrice;
        }
        console.log('ListingPrice:', ListingPrice);
      
        sellingPrice = ListingPrice ? ListingPrice.Amount : 0;
        sellingPriceCurrencyCode = ListingPrice ? ListingPrice.CurrencyCode : '';
        console.log('sellingPrice:', sellingPrice);
        console.log('sellingPriceCurrencyCode:', sellingPriceCurrencyCode);
      }

      // B - Find number of sellers count (Highest offer is number of seller count)
      // Loop each record from OfferListingCount array and find the highest value
      let offerListingCountMax = 0; 
      const { OfferListingCount } = product.CompetitivePricing.NumberOfOfferListings;
      console.log('OfferListingCount:', OfferListingCount);
      if (!OfferListingCount) { // OfferListingCount not exist
        this.setState({
          showMessage: true,
          messageText: "Number of seller info not available at present",
        });
      } else {
        OfferListingCount.forEach((offer, index) => { 
          // console.log(JSON.stringify(offer)); // Debug
          // If offer.Value is higher than offerListingCountMax then make it max.
          const offerValue = parseInt(offer.Value); // Convert to number, because Value in string format.
          if (offerValue > offerListingCountMax) { 
            offerListingCountMax = offerValue; 
          }
        });  
      }

      // C - Find best seller rank from data
      // e.g. [ {ProductCategoryId: "ce_display_on_website", Rank: "24"}, 
      //       {ProductCategoryId: "3312811011", Rank: "2"} ]
      // SalesRank may be single data object or Array type so we have to check type 
      // of product.SalesRankings.SalesRank and parse the data accordingly.
      let rankData;
      let bsr = 0;
      const { SalesRank } = product.SalesRankings;
      if (!SalesRank) { // Sales rank data not exist
        this.setState({
          showMessage: true,
          messageText: "Best seller rank not available for the product",
        });
      } else { // Sales rank data exist
        if ( Array.isArray(product.SalesRankings.SalesRank) ) { // Array
          rankData = product.SalesRankings.SalesRank[0];
        } else { // Single data object
          rankData = product.SalesRankings.SalesRank;
        }
        if (rankData.Rank) { bsr = parseInt(rankData.Rank) }  
      }

      // D - Decide status for selling price and number of seller and set to state
      let sellingPriceStatus = 'no';
      if (parseFloat(sellingPrice) >= toolSettings.selling_price.min && parseFloat(sellingPrice) <= toolSettings.selling_price.max) {
      sellingPriceStatus = 'yes'; // Selling price between $20 - $65 so yes
      }
      let numberOfSellersStatus = 'no';
      if ( offerListingCountMax >= toolSettings.seller_count.min && offerListingCountMax <= toolSettings.seller_count.max) {
        numberOfSellersStatus = 'yes'; // # of sellers between 5 - 10 so yes
      }
      let bsrStatus = 'no';
      if ( bsr >= toolSettings.bsr.min && bsr <= toolSettings.bsr.max ) { // if BSR between 500 - 2,000 then yes
        bsrStatus = 'yes';
      }
      this.setState({
        sellingPrice: sellingPrice,
        sellingPriceStatus: sellingPriceStatus,
        sellingPriceCurrencyCode: sellingPriceCurrencyCode,
        numberOfSellers: offerListingCountMax,
        numberOfSellersStatus: numberOfSellersStatus, 
        bsr: bsr,
        bsrStatus: bsrStatus,
      });

      // D - Check that all status fetched or not
      this.checkAllStatusDoneOrNot();

    } else { // Product not exist within array, so show messsage
      this.setState({
        showMessage: true,
        messageText: "Product info not available at present",
      });
    }

    // Call Third Api - Fetch product weight (#5)
    setTimeout(this.callThirdApi, 200);
  }
  
  // This function called if error while fetching competitive pricing for asin.
  getCompetitivePricingForASIN_Error = (error) => {
    console.log("getCompetitivePricingForASIN_Error() error: ", error);
    this.setState({
      showMessage: true,
      messageText: "Product info not available at present",
      //isProcessing: false,
    });

    // Call Third Api - Fetch product weight (#5)
    setTimeout(this.callThirdApi, 200);
  }
  //-----------------------------------------------------------------
  // END: 2-3-4 Fetch Selling Price, Sellers Count, and BSR
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: 5 Fetch product weight
  //-----------------------------------------------------------------  
  // Called when api call success
  getMatchingProductForId_Success = (result) => {
    console.log("getMatchingProductForId_Success() result:", result);

    const { toolSettings } = this.state;

    // 1 - Value from result
    // We passed single asin to api so we will receive Product as single data object.    
    //const { status, data, error } = result;
    const { Product } = result.data.Products;

    // 2 - Fetch weight info
    const { ItemAttributes } = Product.AttributeSets;
    let weightValue = 0;
    let weightUnit = '';
    const { PackageDimensions } = ItemAttributes;
    if (!PackageDimensions){ 
      console.log('ItemAttributes.PackageDimensions Not exist, So weight information not available.');
      this.setState({
        showMessage: true,
        messageText: 'Weight information not available',
      });
    } else {
      const { Weight } = PackageDimensions;
      console.log('Weight:', Weight);
      weightValue = Weight.Value; 
      weightUnit = Weight.Units;
    }
    console.log('weightValue:', weightValue, ' weightUnit:', weightUnit);
        
    // 3 - Decide status for weight
    let weightStatus = 'no';
    if ( weightValue >= toolSettings.weight.min && weightValue < toolSettings.weight.max ) { // if weight less than 5 lbs, set yes
      weightStatus = 'yes';
    }

    // 4 - Fetch product thumb image and title
    const { URL, Height, Width } = ItemAttributes.SmallImage;
    const { Title } = ItemAttributes;

    // 5 - Set value to state
    this.setState({
      weight: weightValue,
      weightUnit: weightUnit,
      weightStatus: weightStatus,
      title: Title,
      imageUrl: URL,
      imageWidth: Height.Value,
      imageHeight: Width.Value,
    });

    // 5 - Check that all status fetched or not
    this.checkAllStatusDoneOrNot();

    // 6 - Call Fourth Api - Fetch category name (#6)
    setTimeout(this.callFourthApi,200);
  }

  // Called if error while api call
  getMatchingProductForId_Error = (error) => {
    console.log("getMatchingProductForId_Error() error: ", error);
    this.setState({
      showMessage: true,
      messageText: 'Product weight fetch error.',
      //messageText: 'Product weight fetch error, please try again.',
      //isProcessing: false,
    });

    // Call Fourth Api - Fetch category name (#6)
    setTimeout(this.callFourthApi,200);
  }
  //-----------------------------------------------------------------
  // START: 5 Fetch product weight
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------      
  // START: 6 Fetch category name
  //-----------------------------------------------------------------      
  getProductCategoriesForASIN_Success = (result) => {
    console.log("GetProductCategoriesForASIN_Success() result:", result);

    // 1 - Read data from restult
    //const { status, data, error } = result;    
    const { data } = result;

    // 1A - If data not received then show error message and return
    if (!data) {
      this.setState({
        showMessage: true,
        messageText: 'Product category info not found at present.',
        isProcessing: false,
      });

      return; // IMPORTANT
    }
    
    // 2 - If multiple result then we will consider first result from array.
    let categoryTree; 
    if ( Array.isArray(data.Self) ) { // multiple result array
      categoryTree = data.Self[0];  // Grab first element from array
    } else { // Single data object
      categoryTree = data.Self;
    }
    //console.log('categoryTree:', JSON.stringify(categoryTree));

    // 3 - Find root_category_name and root_category_id category tree
    const rootCategoryInfo = this.findRootCategoryInfo(categoryTree);
    let rootCategoryName = '';
    let rootCategoryId = null;
    if (rootCategoryInfo) {
      rootCategoryName = rootCategoryInfo.categoryName;
      rootCategoryId = rootCategoryInfo.categoryId;
    }

    // 4 - Save data within state
    const isUnrestricted = this.isUnrestrictedCategory(rootCategoryName);
    this.setState({
      productCategory: rootCategoryName, 
      productCategoryId: rootCategoryId,
      productCategoryStatus: isUnrestricted,
    });

    // 5 - Check that all status fetched or not
    this.checkAllStatusDoneOrNot();
  }

  getProductCategoriesForASIN_Error = (error) => {
    console.log("getProductCategoriesForASIN_Error() error: ", error);
    this.setState({
      showMessage: true,
      messageText: 'Product Category fetch error, please try again.',
      isProcessing: false,
    });
  }

  // This function will trverse category tree and return rootCategoryName 
  // and rootCategoryId within data object as shown under.
  // e.g. Return value: { categoryName: '', categoryId: 0 }
  findRootCategoryInfo = (categoryTree) => {
  
    // 1 - Prepare null data object
    // e.g. { categoryName: '', categoryId: 0 }
    let categoryInfo;

    // 2 - Create a duplicate copy of category tree
    let categoryTreeTemp = { ...categoryTree };

    // 3 - Save dategory tree to array (from child to parent)
    // i.e. first element is deep level child, last element is top level category.
    const categoryArray = [];
    let hasParent = true;
    while(hasParent){
      // 3A - Push category info into array
      categoryArray.push({
        ProductCategoryId: categoryTreeTemp.ProductCategoryId,
        ProductCategoryName: categoryTreeTemp.ProductCategoryName
      });

      // 3B - If more parent exist then go to deep level.
      if (categoryTreeTemp.Parent) {
        hasParent = true;
        categoryTreeTemp = categoryTreeTemp.Parent;
      } else {
        hasParent = false;
      }
    }
    //console.log('categoryArray:', categoryArray);

    // Grab last and second last element from array, it is root category we need.
    // amzon mws api result in such a way that second last element have category name 
    // and last element have categoryId within it.
    const arrayLength = categoryArray.length;
    // atleast two element required within array  
    if (arrayLength >= 2) { 
      const categoryName = categoryArray[arrayLength - 1].ProductCategoryName;  // last element
      const categoryId = categoryArray[arrayLength - 2].ProductCategoryId;      // Second last element
      categoryInfo = { 
        categoryId: categoryId, 
        categoryName: categoryName, 
      }  
    }
    //console.log('categoryInfo:', categoryInfo);

    return categoryInfo;
  }  

  // This function will check that given category consider as unrestricted or not.
  // @ categoryName  String (name of category e.g. Electronics )
  // return 'yes' - if given category not restricted.
  // return 'no' - if given category is restricted.
  isUnrestrictedCategory = (categoryName) => {
    const categoryNameLower = categoryName.toLowerCase();
    let isUnrestricted = 'yes';

    Object.keys(this.restrictedCategories).forEach( (key, index) => {
      const categoryTitle = this.restrictedCategories[key].toLowerCase();
      
      // if ( categoryTitle.includes(categoryNameLower) ) {
      //   isUnrestricted = 'no';
      // }
      if (categoryTitle === categoryNameLower) {
        isUnrestricted = 'no';
      }
    });

    return isUnrestricted;
  }
  //-----------------------------------------------------------------      
  // END: 6 Fetch category name
  //-----------------------------------------------------------------      


  // This function will check that all parameter processed or not,
  // If all parameter processed then it will stop the progress bar. 
  checkAllStatusDoneOrNot = () => {
    // Stop processing if all status done.
    const allDone = this.isAllStatusDecided();
    if (allDone) {
      this.setState({
        isProcessing: false,
      });
    }
  }

  // True if all parameter information found.
  isAllStatusDecided = () => {
    const { bsrStatus, sellingPriceStatus, reviewCountStatus, 
      weightStatus, productCategoryStatus, numberOfSellersStatus } = this.state;

    if (!bsrStatus) { return false } 
    if (!sellingPriceStatus) { return false } 
    if (!reviewCountStatus) { return false }
    if (!weightStatus) { return false }
    if (!productCategoryStatus) { return false }
    if (!numberOfSellersStatus) { return false }

    return true;
  }

  // This function will return how many criteria have yes value exist.
  getYesCount = () => {
    const { bsrStatus, sellingPriceStatus, reviewCountStatus, weightStatus, 
      productCategoryStatus, numberOfSellersStatus } = this.state;

    let count = 0;
    if (bsrStatus && bsrStatus === 'yes') { count++ } 
    if (sellingPriceStatus && sellingPriceStatus === 'yes') { count++ } 
    if (reviewCountStatus && reviewCountStatus === 'yes') { count++ }
    if (weightStatus && weightStatus === 'yes') { count++ }
    if (productCategoryStatus && productCategoryStatus === 'yes') { count++ }    
    if (numberOfSellersStatus && numberOfSellersStatus === 'yes') { count++ }
    
    return count;
  }

  // Called when message close after time out
  handleMessageClose = () => {
    //console.log('handleMessageClose()'); 
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }

  // Render current settings
  renderToolSettings = () => {
    const { toolSettings } = this.state;
    
    // If tool settings data not exist then return null
    if (!toolSettings) { return null }

    // Return ui
    return(
      <Paper elevation={1} style={{padding: 20}} >
        <Typography variant="h6">Current Settings:</Typography>
        <Typography variant="body1" >Potential index calculated based on the below parameter:</Typography>
        <div style={{height: 10}} ></div>
        
        <Grid container spacing={1} >
          <Grid item align='left'>
            <Typography variant="body1">
              Best Seller Rank: {toolSettings.bsr.min} - {toolSettings.bsr.max} &nbsp;&nbsp;
            </Typography>
          </Grid>
          <Grid item align='left'>
            <Typography variant="body1">
              Review Count: &nbsp;{toolSettings.review_count.min} - {toolSettings.review_count.max} &nbsp;&nbsp;
            </Typography>
          </Grid>
          <Grid item align='left'>
            <Typography variant="body1">
              Seller Count: {toolSettings.seller_count.min} - {toolSettings.seller_count.max} &nbsp;&nbsp;
            </Typography>
          </Grid>
          <Grid item align='left' >
            <Typography variant="body1">
              Selling Price: ${toolSettings.selling_price.min} - ${toolSettings.selling_price.max} &nbsp;&nbsp;
            </Typography>
          </Grid>
          <Grid item align='left' >
            <Typography variant="body1">
              Weight: {toolSettings.weight.min} - {toolSettings.weight.max} {toolSettings.weight.unit}
            </Typography>
          </Grid>

        </Grid>
      </Paper>
    );
  }

  // Render debug info for admin user only
  renderDebugInfo = () => {
    const { toolSettings, bsr, sellingPrice, reviewCount, weight, productCategory, numberOfSellers } = this.state;
    const { bsrStatus, sellingPriceStatus, reviewCountStatus, weightStatus, productCategoryStatus, numberOfSellersStatus } = this.state;
    const yesCount = this.getYesCount();

    return(
      <Paper elevation={2} style={{padding: 20}} >
        <b>Debug:</b> (For Admin User Only) <br />
        <br />
        bsr ({toolSettings.bsr.min} - {toolSettings.bsr.max}): {bsr ? bsr : '--'}  &nbsp;&nbsp; {bsrStatus} <br />
        reviewCount ({toolSettings.review_count.min} - {toolSettings.review_count.max}): {reviewCount ? reviewCount : '--'} &nbsp;&nbsp; {reviewCountStatus} <br />
        numberOfSellers ({toolSettings.seller_count.min} - {toolSettings.seller_count.max}): {numberOfSellers ? numberOfSellers : '--'} &nbsp;&nbsp; {numberOfSellersStatus} <br />
        sellingPrice (${toolSettings.selling_price.min} - ${toolSettings.selling_price.max}): {sellingPrice ? sellingPrice : '--'}  &nbsp;&nbsp; {sellingPriceStatus} <br />
        weight ({toolSettings.weight.min} - {toolSettings.weight.max} lbs) : {weight ? weight : '--'} &nbsp;&nbsp; {weightStatus} <br />
        <br />
        productCategory: {productCategory ? productCategory : '--'} &nbsp;&nbsp; {productCategoryStatus} <br />
        <br />
        Yes Count: { yesCount } <br />
        Rank: { yesCount } out of 6  <br />
        Percentage: {parseInt((100 * yesCount)/6)} %<br />
      </Paper>
    );
  }


  render() {
    const { isProcessing, toolSettingsFetched, toolSettings, title, imageUrl, imageWidth, imageHeight } = this.state;
    const { asin, market } = this.state;

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    // Convert yes count to percentage 
    const yesCount = this.getYesCount();
    let percentage = parseInt((100 * yesCount)/6);

    // if all task not done, then do not update percentage.
    const allDone = this.isAllStatusDecided();
    if (!allDone) { percentage = 0 } 

    // Show slider when all task done.
    const displayValueLabel = allDone ? 'on' : 'off';

    return(
      <React.Fragment>
        <PotentialProductAsinForm 
          onSubmit={this.onSubmitForm} 
          isProcessing={ isProcessing || !toolSettingsFetched }
        />
        
        { isProcessing && <LinearProgress /> }
        
        <div style={{height: 20}} ></div>

        <Paper elevation={1} style={{padding: 20}} >
          { (imageUrl || title) &&
          <Grid container spacing={2} alignItems="center">
            <Grid item align='left' >
              { imageUrl ?  
                <img src={imageUrl} width={imageWidth} height={imageHeight} alt='product thumb' />
                :
                <div style={{width: 60, height: 60, border:'1px solid #cccccc'}} ></div>
              }
            </Grid>
            <Grid item xs align='left' >
              <Typography>
                <Link href={ marketPlaces[market].site_url + '/dp/' + asin } target="_blank" >
                  { title } 
                </Link>
              </Typography>
            </Grid>
          </Grid>
          }
          { (imageUrl || title) && <div style={{height: 30}} ></div> }
          <Grid container spacing={2} alignItems="center">
            <Grid item align='center' >
              <Typography component="h2" variant="h6" >
                Low<br />Potential
              </Typography>
            </Grid>
            <Grid item xs style={{marginLeft: 10, marginRight: 10}} >
              <Slider
                value={percentage}
                valueLabelDisplay={displayValueLabel}
                marks={sliderMarks}
                step={10}
                min={0}
                max={100}
              />
            </Grid>
            <Grid item align='center' >
              <Typography component="h2" variant="h6" >
                High<br />Potential
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        
        <div style={{height: 20}} ></div>

        { this.renderToolSettings() }

        <div style={{height: 40}} ></div>

        { // Show debug section to admin user only
          this.state.userType === 'admin' && toolSettings && this.renderDebugInfo()
        }

        <div style={{height: 30}} ></div>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={5000}
          onClose={this.handleMessageClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(PotentialProductAsin);


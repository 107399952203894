// Search 999 qty for given asin via puppeteer api created at server side.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


// Import helper functions, constant etc.
import { get999QtyForASIN } from '../../helpers/apiHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);

class Get999QtyForASIN extends React.Component {
  
  state = {
    // Marketplace selection
    market: 'CA',

    // ASIN text input
    asin: 'B07T2Z2KSM', // single asin

    status: '',
    data: null,
    error: '',

    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('Get999QtyForASIN - componentDidMount()');
  }


  onClickSubmit = () => {
    console.log('Get999QtyForASIN - onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asin } = this.state;
    if (!market || market === '') { return };
    if (!asin || asin === '') { return };

    // 2 - Make value empty
    this.setState({
      status: '',
      data: null,
      error: '',
      isProcessing: true,
    });

    // 4 - Call api to scrap url
    get999QtyForASIN(asin, market, this.get999QtyForASIN_Success, this.get999QtyForASIN_Error);
  }

  // Scrap Url success
  get999QtyForASIN_Success = (result) => {
    console.log("get999QtyForASIN_Success() result:", result);
    
    const { status, data, error } = result;
    
    this.setState({
      status: status,
      data: data,
      error: error,
      isProcessing: false,
    });
  }

  // Process url error
  get999QtyForASIN_Error = (error) => {
    console.log("get999QtyForASIN_Error() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }

  onClickClear = () => {
    this.setState({
      market: '',
      asin: '',
      
      status: '',
      data: null,
      error: '',
    });
  }


  renderProductInfo = (data) => {
    const keys = Object.keys(data);
    const elements = keys.map( (key, index) => {
      return(
        <div key={key} >
          <b>{key}</b> : {data[key]} <br />
        </div>);
    });

    return(
      <>
      { elements }
      </>
    );
  }

  renderForm = () => { 
    const { isProcessing } = this.state;
    const { classes } = this.props;
    return (
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    markePlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={5}>
              <FormControl className={classes.formControl} >
                <TextField 
                  //id="asin" 
                  label="ASIN (single)"
                  placeholder=""
                  value={this.state.asin} 
                  onChange={(e) => this.setState({ asin: e.target.value })} 
                  size="small"
                  disabled={isProcessing}
                />
              </FormControl>
          </Grid>
        </Grid>
      </form>
    )
  }

  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        {isProcessing &&
          <b>Processing... &nbsp;&nbsp;&nbsp; </b>
        }
        <Button 
          variant="contained" 
          size="medium" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={isProcessing}
        >Submit</Button>
        <Button 
          variant="contained" 
          size="medium" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div>
    );
  }

  renderResult = () => {
    const { status, data, error } = this.state;
    return(
      <div align='left' >
        
        { data && data.qty &&
          <>
            <b>Available Qty: </b>{ data.qty } <br />
          </>
        }

        { data && data.warningText &&
          <>
          <b>Warning: </b> { data.warningText } <br /><br />
          </>
        }

        { status !== '' &&
          <><b>Status:</b> {status} <br /><br /></>
        }

        { error &&
          <>
            <br /><br />
            <b>Error:</b>
            <div style={{width: '100%', height: 100, overflow: 'scroll', border: '0px solid #cdcdcd' }}>
              { error && error.toString() }
            </div>
          </>
        }
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>Puppeteer - Get999QtyForASIN </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            { this.renderFormButton() }
            { this.renderResult() }
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


Get999QtyForASIN.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Get999QtyForASIN);
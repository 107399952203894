// Get lowest offer listing for asins.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Import helper functions, constant etc.
import { getLowestOfferListingsForASIN } from '../../helpers/mwsHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);


class GetLowestOfferListingsForASIN extends React.Component {
  
  state = {
    // Form input
    market: 'CA',
    itemCondition: 'New', // New, Used, Collectible, Refurbished, Club
    asins: 'B015CH1PJU, B015CH1JIW', // e.g. 'B015CH1PJU,B015CH1JIW'

    status: '',
    error: '',

    headers: null,
    products: [],
    responseMetadata: null,
    statusCode: 0,

    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('GetLowestOfferListingsForASIN - componentDidMount()');
  }


  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      headers: null,
      products: [],
      responseMetadata: null,
      statusCode: 0,
    });
  }


  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asins, itemCondition } = this.state;
    if (!market || market === '') { return };
    if (!asins || asins === '') { return };

    // 2 - Make value empty
    this.setState({
      status: '',
      data: {},
      error: '',
      isProcessing: true,
    });

    // 3 - Call api
    getLowestOfferListingsForASIN(market, asins, itemCondition, this.getLowestOfferListingsForASIN_Success, this.getLowestOfferListingsForASIN_Error);
  }

  // Called when api call success
  getLowestOfferListingsForASIN_Success = (result) => {
    console.log("getLowestOfferListingsForASIN_Success() result:", result);
    
    const { status, data, error } = result;
    
    const stateUpdates = {};

    // If multiple product then data will be array of products
    if ( Array.isArray(data) ) { // Multiple product result
      const productsTemp = [];
      data.forEach( (singleData, index) => {
        if (singleData.Product) {
          productsTemp.push(singleData.Product);
        }
      });
      stateUpdates['products'] = productsTemp;
    } else { // Single Product result
      if (data.Headers) {
        stateUpdates['headers'] = data.Headers;
      }
      if (data.ResponseMetadata) {
        stateUpdates['responseMetadata'] = data.ResponseMetadata;
      }
      if (data.statusCode) {
        stateUpdates['statusCode'] = data.statusCode;
      }
      if (data.Product) {
        stateUpdates['products'] = [data.Product];
      }
    }

    // Udpate value to state
    this.setState({
      ...stateUpdates,
      status: status,
      error: error,
      isProcessing: false,
    });
  }

  // Called if error while api call
  getLowestOfferListingsForASIN_Error = (error) => {
    console.log("getLowestOfferListingsForASIN_Error() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }


  // Render single product element
  renderSingleProduct = (product, index) => {
    const { LowestOfferListing } = product.LowestOfferListings;

    // Check that LowestOfferListing is array or single data object
    // If single data object then convert to array. If product 
    // have only single offer then it will result single data instead
    // if the array, so we have to take care for that.
    let lowestOfferListingArray;
    if (Array.isArray(LowestOfferListing)) {
      lowestOfferListingArray = LowestOfferListing;
    } else { // Convert single record to array
      lowestOfferListingArray = [ LowestOfferListing ];
    }

    // Prepare html for lowest offer listing
    let LowestOfferListingEl = lowestOfferListingArray.map((item, index) => {
      const { LandedPrice, ListingPrice, Shipping } = item.Price;
      const priceEl = (
        <div>
          LandedPrice: { LandedPrice.CurrencyCode + ' ' + LandedPrice.Amount } &nbsp;
          ListingPrice: { ListingPrice.CurrencyCode + ' ' + ListingPrice.Amount } &nbsp;
          Shipping: { Shipping.CurrencyCode + ' ' + Shipping.Amount }
        </div>
      )
    
      const keys = Object.keys(item.Qualifiers);
      const eualifiersEl = keys.map((key, index) =>{
        return (
          <div key={'item-qualifier' + index} >{ 'Qualifiers.' + key}: { JSON.stringify(item.Qualifiers[key]) } </div>
        )
      }); 

      return(
        <div key={'offerlist-' + index} >
          OfferListing# { index+1 } 
          <br />
          MultipleOffersAtLowestPrice: { item.MultipleOffersAtLowestPrice }
          <br />
          NumberOfOfferListingsConsidered: { item.NumberOfOfferListingsConsidered }
          <br />
          { priceEl }
          { eualifiersEl }
          <br />
        </div>
      );
    });

  
    return (
      <div key={ 'product-' + index } style={{ margin: 10 }} >
        <b># {index + 1}</b> <br />
        
        <b>Product.Identifiers: </b> <br />
        { JSON.stringify(product.Identifiers) } <br /><br />

        <b>product.LowestOfferListings.LowestOfferListing:</b> <br />
          { LowestOfferListingEl }
        <hr />
      </div>
    );
  }

  // Render all product list
  renderProductList = (products) => {
    
    const allProductsEl = products.map( (product, index) => {
      return this.renderSingleProduct(product, index);
    });

    // Return all product element
    return(<>{ allProductsEl }</>);
  }


  renderHeaderInfo = (headers) => {
    const keys = Object.keys(headers);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {headers[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderResponseMetaData = (responseMetadata) => {
    const keys = Object.keys(responseMetadata);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {responseMetadata[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }


  renderForm = () => {
    const { isProcessing } = this.state;
    const { classes } = this.props;
    
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    markePlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Item Condition</InputLabel>
                <Select
                  id="itemCondition"
                  labelId="Item Condition"
                  value={this.state.itemCondition}
                  onChange={(e) => this.setState({ itemCondition: e.target.value })}
                  disabled={isProcessing}
                >
                  <MenuItem value="New" >New</MenuItem>
                  <MenuItem value="Used" >Used</MenuItem>
                  <MenuItem value="Collectible" >Collectible</MenuItem>
                  <MenuItem value="Refurbished" >Refurbished</MenuItem>
                  <MenuItem value="Club" >Club</MenuItem>
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={8}>
              <FormControl className={classes.formControl} >
                <TextField 
                  id="asins1" 
                  label="ASINS (max 20) Comma Separated" 
                  placeholder="" 
                  value={this.state.asins} 
                  onChange={(e) => this.setState({ asins: e.target.value })} 
                  size="small" 
                  disabled={isProcessing}
                />
              </FormControl>
          </Grid>
        </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        {isProcessing &&
          <b>Processing... &nbsp;&nbsp;&nbsp; </b>
        }
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={isProcessing}
        >Submit</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }

  renderResult = () => {
    const { status, error, headers, responseMetadata, products } = this.state;
    return (
      <div align='left'>
        { status !== '' && 
          <><b>Status:</b> {status}<hr /></>
        }
        { products.length > 0 && 
          <><h3>Product List: ({products.length})</h3>{ this.renderProductList(products) }</>
        }
        { responseMetadata && 
          <><h3>Response Header:</h3>{ this.renderHeaderInfo(headers) }<hr /></>
        }
        { responseMetadata && 
          <><h3>Response Metadata:</h3>{ this.renderResponseMetaData(responseMetadata) }<hr /></>
        }
        { error &&
          <>
            <b>Error:</b>
            <div style={{width: '100%', overflow: 'scroll', border: '0px solid #cdcdcd' }}>
              { error && error.toString() }
            </div>
          </>
        }
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>MWS - GetLowestOfferListingsForASIN</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            { this.renderFormButton() }
            { this.renderResult() }
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
  
}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


GetLowestOfferListingsForASIN.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(GetLowestOfferListingsForASIN);
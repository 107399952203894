// Tracker list component

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrow from '@material-ui/icons/PlayArrow';
import SalesIcon from '@material-ui/icons/DataUsage';

//import { getMediumDate } from '../helpers/utility';
import { marketPlaces } from '../../helpers/constants';

class TrackerList extends React.Component {
  
  state = {
  }

  componentDidMount = () => {
    //console.log('TrackerList - componentDidMount()');
  }

  // Inform parent component that restart tracker button tapped
  onClickRestart = (id, index) => {
    //console.log('onClickRestart() id:', id, ' index:', index);
    this.props.onClickRestart(id, index);
  }

  // Inform parent component that delete button tapped.
  onClickDelete = (id, index) => {
    //console.log('onClickDelete() id:', id, ' index:', index);
    this.props.onClickDelete(id, index);
  }

  // Inform parent component that sales data button clicked
  onClickSalesData = (id, index) => {
    //console.log('onClickSalesData() id:', id, ' index:', index);
    this.props.onClickSalesData(id, index);
  }

  render() {
    const { classes } = this.props;
    const { trackerList } = this.props;
    
    const currentDate = new Date();

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Market</TableCell>
              <TableCell align="left">ASIN</TableCell>
              <TableCell align="center">Sales Data</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { trackerList.map( (row, index) => (
              <TableRow key={row.id}>
                <TableCell align="left">
                { row.small_image_url ?
                  //<img src={row.small_image_url} width={row.small_image_width} height={row.small_image_height} alt={row.Title} />
                  <img src={row.small_image_url} width="40" alt={row.Title} />
                  :
                  <div style={{width: 60, height: 40, border: '1px solid #efefef'}} >&nbsp;</div>
                }
                </TableCell>
                <TableCell align="left">
                  { marketPlaces[row.market].country_name }
                </TableCell>
                <TableCell align="left">{row.asin}</TableCell>
                <TableCell align="center">
                  <IconButton 
                    aria-label="SalesData" 
                    className={classes.margin}
                    onClick={() => this.onClickSalesData(row.id, index) } 
                  >
                    <SalesIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  { currentDate > row.to_date ?
                    <IconButton 
                      aria-label="start" 
                      className={classes.margin}
                      onClick={ () => this.onClickRestart(row.id, index) } 
                      title="Tracker Stopped - Click to Start Again"
                    >
                      <PlayArrow fontSize="small" />
                    </IconButton>
                    :
                    <>Active</>
                  }
                </TableCell>
                <TableCell align="right">
                  <IconButton 
                    aria-label="delete" 
                    className={classes.margin}
                    onClick={ () => this.onClickDelete(row.id, index) } 
                    title="Delete Tracker"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

const styles = (theme) => ({

  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  table: {
    minWidth: 650,
  },

});


TrackerList.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(TrackerList);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LinearProgress from '@material-ui/core/LinearProgress';


// Import constant and helper functions
import { 
  registerWithEmailPassword, 
  sendVerificationEmail,
  getCurrentUserEmail,
  logoutCurrentUser,
} from '../../helpers/firebaseAuthHelper';

class EmailPasswordRegister extends React.Component {  

  state = {
    isProcessing: false,

    // Input text field
    name: '',
    email: '',
    password: '',
    
    showBanner: false,
    bannerTitle: '',
  };

  
  // Handle form submit event
  handleSubmit = () => {
    //event.preventDefault();

    const { name, email, password } = this.state;

    // 1 - If email/password not exist or empty then return.
    if ( !name || name === '' || !email || email === "" || !password || password === ""  ) {
      console.log("Name/Email/password empty, so return.");
      return;
    }
    
    // 2 - Set processing on 
    this.setState({
      isProcessing: true,
    });

    // 3 - Register with email/password via helper function.
    registerWithEmailPassword(name, email, password, this.onRegisterSuccess, this.onRegisterError);
  };


  // If registered successfully, this function called via callback.
  onRegisterSuccess = async () => {
    console.log('EmailPasswordRegister - onRegisterSuccess()');

    // 1 - Send email address verification email via firebase api.
    // We need current logged in user to send verification email.
    // At this moment user is just registered so current user exist, 
    // If current logged in user not exist then it will return false.
    const isSent = await sendVerificationEmail();

    // 2 - Show message based on the sent status
    let message = 'Verification email send error, Please login again to resend email';
    if (isSent) { // Sent successfully
      const email = getCurrentUserEmail();
      message = 'Registered Successfully. Verification link sent to ' + email + ', please verify email address to login.';
    } 
    this.showError(message);

    // 3 - Logout current user, so user can not log in without verifying email account.
    // We need current user to send verification email, so we keep user until this step.
    logoutCurrentUser();

    // 4 - Clear form content
    this.clearFormContent();

    // 5 - Set processing false.
    this.setState({
      isProcessing: false,
    });
  }


  // If error while register, this function called via callback
  onRegisterError = (error) => {
    console.log('EmailPasswordRegister - onRegisterError()');

    // 1 - Set processing false, so it will enable submit button.
    this.setState({
      isProcessing: false,
    });
    
    // 2 - Fetch error code and message.
    const errorCode = error.code;
    const errorMessage = error.message;

    // Debug
    console.log("errorCode:", errorCode);
    console.log("errorMessage:", errorMessage);
    
    // 3 - Show error message based on the error code.
    if (errorCode === 'auth/email-already-in-use') {
      this.showError('The email address is already in use by another account.');
    } else if (errorCode === 'auth/invalid-email') {
      this.showError('The email address is badly formatted.');
    } else {
      this.showError('Error while register, please try again.');
    }
  }

  
  // Clear form field content
  clearFormContent = () => {
    this.setState({
      name: '',
      email: '',
      password: '',
    })
  }


  // Show error banner with given title (message text)
  showError = (title) => {
    this.setState({
      showBanner: true,
      bannerTitle: title,
      isProcessing: false,
    });
  }

  // Hide message banner
  hideBanner = () => {
    this.setState({
      showBanner: false,
      bannerTitle: '',
      isProcessing: false,
    });
  }


  render() {
    const { name, email, password, isProcessing, showBanner, bannerTitle } = this.state;

    return (
        <div style={{padding: 20}} align='center' >
          <Typography variant="h6" component="h6" gutterBottom >
            Register with Email / Password
          </Typography>
          <form>
              <TextField
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                label="Name"
                type="text"
                //maxLength="200"
                style={{width: '100%'}}
              />
              <br /><br />
              <TextField
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
                label="Email"
                type="email"
                autoComplete="email"
                //maxLength="200"
                style={{width: '100%'}}
              />
              <br /><br />
              <TextField
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
                label="Password"
                type="password"
                //maxLength="100"
                autoComplete="current-password"
                style={{width: '100%'}}
              />
              <br /><br />
              <Button 
                variant="contained"
                size="medium"
                onClick={this.handleSubmit} 
                disabled={isProcessing}
                //style={{marginRight: 20 }} 
              >Register</Button>
          </form>
          
          { showBanner &&
            <h4 style={{ marginTop: 20 }} >{bannerTitle}</h4>
          }

          { isProcessing && 
            <LinearProgress style={{ marginTop: 10, }} />
          }
        </div>
    );
  }
}

export default EmailPasswordRegister;
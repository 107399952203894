import React from "react";
import { withStyles } from '@material-ui/core/styles';

import PageHeading from './common/PageHeading';


class AboutPage extends React.Component { 

  componentDidMount = () => {
    console.log('AboutPage - componentDidMount()');
  }

  componentWillUnmount = () => {
    console.log('AboutPage - componentWillUnmount()');
  }

  render() {
    return(
      <div>
        <PageHeading title="About" />
        <p>This page can browse without login.</p>
      </div>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(AboutPage);


// Form to take input for profit calculator
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


// Import helper functions, constant etc.
import { marketPlaces } from '../../helpers/constants';

const marketPlacesKeys = Object.keys(marketPlaces);


class ProfitCalculatorForm extends React.Component {
  
  state = {
    // Form input
    market: '',               // 'CA' or 'US' etc. 
    asin: '',                 // e.g B0179IU3XE (single asin)
    currencyCode: 'USD',         // 'CAD' or 'USD' etc.
    isAmazonFulfilled: true,  // true - fulfilled by amazon, false - not fulfilled
    sourceCost: '',         // Buy price / Source price / wholesale price (per unit)
    shippingCost: '',       // Shpping cost (per unit)
    advertisementCost: '',    // product advertisement cost (per unit)

    // Label to show within shipping fields.
    // If amazon fulfilled false then Shipping Cost (Send Item To Seller)
    // If amazon fulfilled true then Shipping Cost (Send Item To Amazon Warehouse)
    shippingFieldsLabel: 'Shipping Cost (Send Item To Amazon Warehouse)',

    // Message text
    showMessage: false,
    messageText: "",
  }

  componentDidMount = () => {
    //console.log('ProfitCalculatorForm - componentDidMount()');
  }

  // Whenever user will select market, we will also save currency code
  // that belongs to that market within state.
  onChangeMarket = (market) => {
    console.log('onChangeMarket() market:', market)

    // Get currency code for the selected market
    const currencyCode = marketPlaces[market].currency_code;
    //console.log('currencyCode:', currencyCode);

    // Set selected market and currency code within state.
    this.setState({
      market: market,
      currencyCode: currencyCode,
    });
  }
  

  onChangeAmazonFulfilled = () => {
    console.log('onChangeAmazonFulfilled()');

    const isFulfilled = !this.state.isAmazonFulfilled;
    const label = isFulfilled ? 'Shipping Cost (Send Item to Amazon Warehouse)' : 'Shipping Cost (Send Item to Seller)';

    this.setState({
      isAmazonFulfilled: isFulfilled,
      shippingFieldsLabel: label,
    })
  }

  // This function called when user click submit button, so we will validate form data 
  // and pass it to parent component so it can do further processing.
  onClickSubmit = () => {
    //console.log('ProfitCalculatorForm - onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asin, currencyCode, isAmazonFulfilled, sourceCost, shippingCost, advertisementCost } = this.state;
    if (!market || market === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please select Marketplace',
      });
      return;
    }
    if (!asin || asin === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please enter asin',
      });
      return;
    }
    if (!sourceCost || sourceCost === '') {
      this.setState({
        showMessage: true,
        messageText: 'Please enter Source/Wholesale Buy Cost (per unit)',
      });
      return;
    }
    if (isNaN(sourceCost)) {
      this.setState({
        showMessage: true,
        messageText: 'Please enter valid Source/Wholesale Buy Cost (per unit)',
      });
      return;
    }
    if (isNaN(shippingCost)) {
      this.setState({
        showMessage: true,
        messageText: 'Please enter valid Shipping Cost (per unit)',
      });
      return;
    }
    if (isNaN(advertisementCost)) {
      this.setState({
        showMessage: true,
        messageText: 'Please enter valid Advertisement Cost (per unit)',
      });
      return;
    }

    // 2 - Prepare data to pass parent component
    const formData = {
      market: market,
      asin: asin,
      currencyCode: currencyCode,
      isAmazonFulfilled: isAmazonFulfilled,
      sourceCost:  sourceCost === '' ? 0 : parseFloat(sourceCost),
      shippingCost: shippingCost === '' ? 0 : parseFloat(shippingCost),
      advertisementCost: advertisementCost === '' ? 0 : parseFloat(advertisementCost),
    }

    // 3 - Send form data to parent component, so parent can 
    // do further operation using this parameter.
    this.props.onSubmit(formData);
  }


  // Render form content 
  renderForm = () => { 
    const { isProcessing } = this.props;
    const { classes } = this.props;

    return (
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          
          <Grid item xs={12} >
            <Typography variant="h6" component="h1">
              Profit Calculator
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} >
              <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
              <Select
                id="market"
                labelId="market"
                value={this.state.market}
                onChange={ (e) => this.onChangeMarket(e.target.value) }
                disabled={isProcessing}
              >
                { // Dynamically generate options
                  marketPlacesKeys.map((key, index) => {
                    const market = marketPlaces[key];
                    return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url} ({market.currency_code})</MenuItem>)
                  })
                }
              </Select>
            </FormControl>  
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} >
              <TextField 
                id="asin" 
                label="ASIN (single)" 
                placeholder="e.g. B015CH1PJU" 
                value={this.state.asin} 
                onChange={(e) => this.setState({ asin: e.target.value })} 
                size="small" 
                disabled={isProcessing}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} >
              <FormGroup row >
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.isAmazonFulfilled}
                      //onChange={(e) => this.setState({ isAmazonFulfilled: !this.state.isAmazonFulfilled })} 
                      onChange={(e) => this.onChangeAmazonFulfilled() } 
                      name="isAmazonFulfilled"
                      color="primary"
                    />
                  }
                  label="Amazon Fulfilled"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl className={classes.formControl} >
              <TextField
                id="source-cost"
                label="Sourcing/Wholesale Buy Cost" 
                placeholder="0.00"
                helperText="(per unit)"
                //style={{ margin: 8 }}
                fullWidth
                margin="normal"
                size="small"
                InputLabelProps={{shrink: true}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.currencyCode}</InputAdornment>,
                }}
                value={this.state.sourceCost} 
                onChange={(e) => this.setState({ sourceCost: e.target.value })} 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} >
              <TextField
                id="shipping-cost"
                //label="Shipping Cost" 
                label={this.state.shippingFieldsLabel}
                placeholder="0.00"
                helperText="(per unit)"
                //style={{ margin: 8 }}
                fullWidth
                margin="normal"
                size="small"
                InputLabelProps={{shrink: true}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.currencyCode}</InputAdornment>,
                }}
                value={this.state.shippingCost} 
                onChange={(e) => this.setState({ shippingCost: e.target.value })} 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl className={classes.formControl} >
              <TextField
                id="advertisement-cost"
                label="Advertisement Cost" 
                placeholder="0.00"
                helperText="(per unit)"
                //style={{ margin: 8 }}
                fullWidth
                margin="normal"
                size="small"
                InputLabelProps={{shrink: true}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.currencyCode}</InputAdornment>,
                }}
                value={this.state.advertisementCost} 
                onChange={(e) => this.setState({ advertisementCost: e.target.value }) } 
                disabled={isProcessing}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2} style={{ textAlign: 'center', marginTop: 20 }} >
            <Button 
              variant="contained" 
              size="medium"
              onClick={this.onClickSubmit} 
              //style={{marginRight: 20 }} 
              disabled={isProcessing}
            >Submit</Button>
          </Grid>

        </Grid>
      </form>
    )
  }

  // Called when message close after time out
  handleClose = () => {
    //console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }  

  render() {
    //const { classes } = this.props;
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <>
        <Paper elevation={2} style={{padding: 20}} >
          { this.renderForm() }
        </Paper >
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


ProfitCalculatorForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(ProfitCalculatorForm);
// Product list for brand competition.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//import { getMediumDate } from '../helpers/utility';
//import { marketPlaces } from '../../helpers/constants';

class ProductListBrand extends React.Component {
  
  state = {
  }

  componentDidMount = () => {
    //console.log('ProductListBrand - componentDidMount()');
  }

  componentWillUnmount = () => {
    //console.log('ProductListBrand - componentWillUnmount()');
  }


  render() {
    const { classes } = this.props;
    const { products, asinReviewDict } = this.props;

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ASIN</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Product<br />Ratings</TableCell>
              <TableCell align="left">Brand</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { products.map( (row, index) => (
              <TableRow key={row.ASIN + '__' + index}>
                <TableCell align="left">
                  { row.SmallImageUrl &&
                  <img src={row.SmallImageUrl} width={row.SmallImageWidth} height={row.SmallImageHeight} alt={row.Title} />
                  }
                  {row.ASIN}
                </TableCell>
                <TableCell align="left">
                  <b>Title:</b> { row.Title } <br />
                  <b>Model:</b> { row.Model } | <b>ReleaseDate:</b> { row.ReleaseDate }
                </TableCell>
                <TableCell align="left">
                  { asinReviewDict[row.ASIN].review_count > 0 ? asinReviewDict[row.ASIN].review_count : '--' } <br />
                  { asinReviewDict[row.ASIN].review_star > 0 ? asinReviewDict[row.ASIN].review_star + '/5' : '--' }
                </TableCell>
                <TableCell align="left">
                  { row.Brand }
                </TableCell>
                <TableCell align="right">
                  { row.ListPrice ? 
                    row.ListPrice.CurrencyCode + ' ' + row.ListPrice.Amount
                    :
                    '--'
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

const styles = (theme) => ({

  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  table: {
    minWidth: 650,
  },

});


ProductListBrand.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(ProductListBrand);
// Amazon Advertising Api: Sponsored Products - Create new ad group
// POST: /v2/sp/adGroups
// 
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Ad%20groups/createAdGroups

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import helper functions, constant etc.
import { getProfiles } from '../../helpers/amazonAdHelper';
import { spGetCampaigns, spCreateAdGroups } from '../../helpers/amazonAdHelper';


class SponsoredProductsCreateAdGroup extends React.Component {
  
  state = {

    // Start - Form input data
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/createCampaigns
    // number: profile id under which new campaign will be created
    profileId: "",

    // number: An existing campaign to which the ad group is associated.
    // Note: Whenever user will select profile id from dropdown, we will fetch 
    // campaign list that belongs to selected profile id and fillup the campaign 
    // list drop down. So user can select campaign id from campaign dropdown.
    campaignId: "", 

    // string: A name for the ad group
    name: "",

    // Number (float) (minimum: 0.02)
    // A bid value for use when no bid is specified for keywords in the ad group.
    defaultBid: 0.02, 

    // string: The current resource state. Choose from ['enabled', 'paused', 'archived']
    state: "",        
    // End - Form input data


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],

    // We will fetch campaign list for the selected profileId and set data here.
    // This campaign list will be used to fillup the campaign drop down.
    // e.g. campaignList: [
    //  {
    //    "portfolioId": 0,
    //    "campaignId": 0,
    //    "name": "string",
    //    "campaignType": "sponsoredProducts",
    //    "targetingType": "manual",
    //    "state": "enabled",
    //    "dailyBudget": 0,
    //    "startDate": "string",
    //    "endDate": "string",
    //    "premiumBidAdjustment": true,
    //    "bidding": {
    //      "strategy": "legacyForSales",
    //      "adjustments": [
    //        {
    //          "predicate": "placementTop",
    //          "percentage": 0
    //        }
    //      ]
    //    }
    //  }
    // ]
    campaignList: [],

    // Once new ad group created we will store result here, 
    // this will be used to show result within ui.
    // e.g. adGroupCreatedResults : [
    //   {
    //     "adGroupId": 0,
    //     "code": "string",
    //     "details": "string"
    //   }
    // ],
    adGroupCreatedResults: null,

    // If error occur during any operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SponsoredProductsCreateAdGroup - componentDidMount()');

    // 1 - Fetch amazon profile list to fillup dropdown
    // Commented: because we passed profileList as props from parent component
    //this.fetchProfileList();
  }



  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass profile Id to server side api, so first 
  // we will fetch profile list and fillup drop down so user can choose it.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }    
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------

  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',
      isProcessing: false,
      
      adGroupCreatedResults: null,
      //profileId: '',
      campaignId: '',
      name: '',
      defaultBid: 0.02,
      state: '',
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Create new ad group
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // Reset error message
    this.setState({ error: '' });

    // 1 - Get info from the state
    const { profileId, campaignId, name, defaultBid, state } = this.state;
    
    // 2 - If mandatory info not selected then return
    if (!profileId || profileId === '') { 
      console.log('profileId not selected, so return');
      this.setState({ error: 'Please Select Profile'});
      return;
    }
    if (!campaignId || campaignId === '') { 
      console.log('campaignId not selected, so return');
      this.setState({ error: 'Please Select Campaign'});
      return;
    }
    if (!name || name === '') { 
      console.log('Ad Group name empty, so return');
      this.setState({ error: 'Enter Ad Group Name'});
      return;
    }
    if ( !defaultBid || defaultBid === '' || defaultBid < 0.02 ) { 
      console.log('defaultBid empty or 0, so return');
      this.setState({ error: 'Set Default Bid Amount (minimum 0.02)'});
      return;
    }
    if ( !state || state === '' ) { 
      console.log('Ad group state empty, so return');
      this.setState({ error: 'Select Ad Group State'});
      return;
    }

    // 3 - Prepare data to create ad group
    // We have to pass ad group create related data as an array although we have to  
    // create one ad group at present. Server side api expect array object.
    // e.g. adGroupDataArray = [
    //   {
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ]      
    const adGroupData1 = {
      name: name, 
      campaignId: campaignId, 
      defaultBid: parseFloat(defaultBid), 
      state: state, 
    }
    const adGroupDataArray = [ adGroupData1 ];
    console.log('adGroupDataArray:', adGroupDataArray);

    // 4 - Show processing
    this.setState({
      isProcessing: true,
    });
    
    // 5 - Call api to create new ad group
    spCreateAdGroups(profileId, adGroupDataArray, this.spCreateAdGroups_Success, this.spCreateAdGroups_Error);
  }

  // Called when ad group created successfully
  spCreateAdGroups_Success = (result) => {
    console.log('spCreateAdGroups_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        adGroupCreatedResults: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while creating ad group
  spCreateAdGroups_Error = (error) => {
    console.log('spCreateAdGroups_Error() error:', error);
    
    this.setState({
      error: error,
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Create new ad group
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Fetch campaign list for selected profile
  //-----------------------------------------------------------------
  // Whenever user will select profile from dropdown, this function called.
  // So we will set selected profile Id within state and fetch campaign list 
  // (via server side api) that belongs to selected profileId.
  handleProfileChange = (selectedProfileId) => {
    console.log('handleProfileChange() selectedProfileId:', selectedProfileId);

    // 1 - If profileId not selected then return
    if ( !selectedProfileId || selectedProfileId === '' ) {
      return;
    }

    // 2 - Set selected profile within state
    // Reset campaign id if selected before.
    this.setState({
      profileId: selectedProfileId,
      campaignId: '',  
    });

    // 3 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 4 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/listCampaigns
    const queryData = {
      startIndex: 0,
      count: 20,
      //stateFilter: 'enabled, paused, archived',
    }

    // 5 - Call api to Get campaign list for selected profile id
    // i.e. Fetch campaign list for selected profileId and fillup campaign drop down.
    spGetCampaigns(selectedProfileId, queryData, this.spGetCampaigns_Success, this.spGetCampaigns_Error);
  }

  // Called when campaign list fetched successfully
  spGetCampaigns_Success = (result) => {
    console.log('spGetCampaigns_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        campaignList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while fetch campaign list
  spGetCampaigns_Error = (error) => {
    console.log('spGetCampaigns_Error() error:', error);
    
    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch campaign list for selected profile
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => {
    const { isProcessing, profileId, campaignList, campaignId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="profileid-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="profileid-label"
              value={profileId}
              onChange={ (e) => this.handleProfileChange(e.target.value) }
              disabled={ isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { type, name } = accountInfo;
                  return (<MenuItem key={ 'profile_' + profileId } value={profileId}>{profileId} / {countryCode} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              Campaign list (dropdown below) will be fillup for selected profile.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="campaignid-label">Choose Campaign</InputLabel>
            <Select
              id="campaignId"
              labelId="campaignid-label"
              value={campaignId}
              onChange={(e) => this.setState({ campaignId: e.target.value })}
              disabled={isProcessing || campaignList.length === 0 }
            >
              { // Dynamically generate options
                campaignList.map((item, index) => {
                  const { campaignId, name, targetingType } = item;
                return (<MenuItem key={ 'campaign_' + campaignId } value={campaignId}>{campaignId} / {name} / { targetingType } </MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              Ad group will be associated with selected campaign.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="adgroup-name" 
              label="Ad Group Name" 
              placeholder="Enter Ad Group Name" 
              value={this.state.name} 
              onChange={(e) => this.setState({ name: e.target.value })} 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="defaultBid" 
              label="Default Bid (minimum 0.02)" 
              placeholder="0.02" 
              value={this.state.defaultBid} 
              onChange={(e) => this.setState({ defaultBid: e.target.value })} 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <InputLabel id="adgroup-state-label">Ad Group State</InputLabel>
            <Select
              id="state"
              labelId="adgroup-state-label"
              value={this.state.state}
              onChange={(e) => this.setState({ state: e.target.value })}
              disabled={isProcessing}
            >
              <MenuItem value="enabled" >enabled</MenuItem>
              <MenuItem value="paused" >paused</MenuItem>
              <MenuItem value="archived" >archived</MenuItem>
            </Select>
          </FormControl>  
        </Grid>
        
      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId, campaignId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' || campaignId === '' }
        >Create Ad Group</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }
  
  // Render result received once new ad group created.
  // It will returl result as an array of created ad group.
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { adGroupCreatedResults } = this.state;

    // adGroupCreatedResults not exist then do not show anything
    if ( !adGroupCreatedResults ) { return null; }

    // If ad group created result exist then render it
    // e.g. adGroupCreatedResults = [
    //   {
    //     "adGroupId": 0,
    //     "code": "string",
    //     "details": "string"
    //   }
    // ]        
    return (
      <div align='left'>
        <b>Ad Group Created Result:</b> <br />
        <hr />
        { 
          adGroupCreatedResults.map( (item, index) => {
            return (
              <div key={ 'adgroup_result_' + index } >
                <b>#{ index + 1}</b> <br />
                <b>adGroupId:</b> { item.adGroupId } <br />
                <b>code:</b> { item.code } <br />
                <b>details:</b> { item.details } <br />
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Create Ad Group</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SponsoredProductsCreateAdGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SponsoredProductsCreateAdGroup);


import React from "react";
import { withStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Import custom component
import PotentialProductSearchForm from './PotentialProductSearchForm';
import PotentialProductSearchResult from './PotentialProductSearchResult';

// Import helper functions
import { getToolSettings, getToolSettingsForUser } from '../../helpers/firebaseHelper';
import { 
  listMatchingProducts, getCompetitivePricingForASIN, getProductCategoriesForASIN, 
  //getMatchingProductForId 
} from '../../helpers/mwsHelper';
import { getProductReviewInfo } from '../../helpers/apiHelper';
import { restrictedCategoriesDefault } from './restricted-category';


class PotentialProductSearch extends React.Component { 

  // Class level variable
  toolSettingsDefault = null;   // Default Settings for potential product
  toolSettingsCustom = null;    // User level/custom settings for potential product

  // Grab the default restritcted category list.
  // If some category approved by user then we will remove it from this list.
  // e.g. restrictedCategories = { 1: 'Category1', 2: 'Category2', ... }
  restrictedCategories = { ...restrictedCategoriesDefault };


  // Data received from the api will be stored here. We will not directly show data 
  // received from the api to ui. So data received from api will be stored here, 
  // we will create simplified data from this and save to state, so that data 
  // will render within ui rows.
  PRODUCTS_RAW = [];

  // We will collect potential product related data for each asin and set info 
  // within this dictionary. Based on the value stored here, we will calculate 
  // potential index (percentage) for the product and set percentage value 
  // within this.state.products[] array.
  // e.g. POTENTIAL_DATA_DICT = {
  //   "B015CH1NAQ" : { 
  //     bsr: null,
  //     sellingPrice: null,
  //     reviewCount: null,
  //     weight: null,
  //     productCategory: null,
  //     numberOfSellers: null,
  //     
  //     bsrStatus: null,              // 'yes' or 'no'
  //     sellingPriceStatus: null,     // 'yes' or 'no'
  //     reviewCountStatus: null,      // 'yes' or 'no'
  //     weightStatus: null,           // 'yes' or 'no'
  //     productCategoryStatus: null,  // 'yes' or 'no'
  //     numberOfSellersStatus: null,  // 'yes' or 'no'
  //   },
  //   ...
  // }
  POTENTIAL_DATA_DICT = {};

  // Whenever we calculate potential index for each product from products[] array, 
  // we will set current processing index here. We will process product one by one
  // basis from the products[] array until all product finished.
  CURRENT_INDEX = -1;


  state = { 

    // Tool settings we have to consider while calculate potential product.
    // Note: we will fetch default and User level Settings for potential product tool.
    // Thereafter we will override default settings with user specific/custon settings 
    // and common data object and set it here.
    // e.g. toolSettings = { 
    //   bsr: { min: 500, max: 2000 },
    //   review_count: { min: 0, max: 1000 },
    //   seller_count: { min: 1, max: 10 },
    //   selling_price: { min: 20, max: 65 },
    //   weight: { min: 0, max: 5, unit: 'lbs' },
    // }
    toolSettings: null, 
    toolSettingsFetched: false, 

    // form input will be stored here
    market: 'CA',         // 'CA' or 'US' etc.
    query: 'Pen Drive',   // Search Query

    // We will fetch data from PRODUCTS_RAW and create product array that consist
    // proper field name that we will use to display info within ui.
    // e.g. 
    // products: [
    //   {
    //     ASIN: "B015CH1NAQ",
    //     Market: "CA", 
    //     MarketplaceId: "A2EUQ1WTGCTBG2",
    //     ListPrice: { Amount: "53.00", CurrencyCode: "CAD" },
    //     SmallImageUrl: "https://m.media-amazon.com/images/I/31AFHNNUfbL._SL75_.jpg",
    //     SmallImageWidth: "75",
    //     SmallImageHeight: "75",
    //     Title: "SanDisk Ultra Flair USB 3.0 64GB Flash Drive",
    //     PotentialRank: null,   // We will calculate % value (0-100) and set here
    //     Status: 'waiting'      // 'waiting', 'processing', 'done'
    //   }
    // ],
    products: [],

    // true - processing in progress, false - not processing anything
    // It will show progress bar when processing true.
    isProcessing: false,
    
    // Potential product index calculated for all products or not.
    // We will disable Submit button untill, potential product index calculated.
    isCalculatingPotentialIndex: false,

    // Message text
    showMessage: false, // true - show message, false - hide message
    messageText: '',    // message test supose to show
  }

  componentDidMount = () => {
    console.log('PotentialProductSearch - componentDidMount()');

    // Fetch default settings for potential product calculator
    // Note: wll not enable form submit button until tooSettings data ready.
    getToolSettings('potential_product', this.getToolSettings_Success, this.getToolSettings_Error);
  }

  componentWillUnmount = () => {
    console.log('PotentialProductSearch - componentWillUnmount()');
  }

  
  //-----------------------------------------------------------------
  // Start: Fetch potential product settings related functions
  //-----------------------------------------------------------------
  // Default settings fetched for potential product tool.
  getToolSettings_Success = (data) => {
    //console.log('getToolSettings_Success() data:', data);
    
    // 1 - Set value within class variable, no need to set within state, 
    // because we will not show this value within ui directly.
    this.toolSettingsDefault = data;

    // 2 - Fetch potential product tool settings for current user
    getToolSettingsForUser('potential_product', this.getToolSettingsForUser_Success, this.getToolSettingsForUser_Error);
  }

  // Error while fetching default settings
  getToolSettings_Error = (error) => {
    console.log('getToolSettings_Error() error:', error);
  }

  // Tool settings fetched success (for current user)
  getToolSettingsForUser_Success = (data) => {
    //console.log('getToolSettingsForUser_Success() data:', data);
    
    // 1 - Set value within class variable, no need to set within state, 
    // because we will not show this value within ui directly.
    if (data) {
      this.toolSettingsCustom = data;
    }

    // 2 - Combine value received from default and user level settings for potential product tool.
    this.combineDefaultAndCustomSettings();
  }

  // Tool settings fetch error (for current user)
  getToolSettingsForUser_Error = (error) => {
    console.log('getToolSettingsForUser_Error() error:', error);
  }  

  // This function will combine Default and User level settings for 
  // potential product tool and make common value for it.
  // i.e. It will take default settings and override with user level settings. 
  combineDefaultAndCustomSettings = () => {
    console.log('combineDefaultAndCustomSettings()');

    // 1 - Fetch custom and default settings from class
    const defaultSettings = this.toolSettingsDefault;
    const customSettings  = this.toolSettingsCustom;

    // 2 - Assign default settings to local variable 
    let bsrMin = defaultSettings.bsr.min;
    let bsrMax = defaultSettings.bsr.max;
    let reviewCountMin = defaultSettings.review_count.min;
    let reviewCountMax = defaultSettings.review_count.max;
    let sellerCountMin = defaultSettings.seller_count.min;
    let sellerCountMax = defaultSettings.seller_count.max;
    let sellingPriceMin = defaultSettings.selling_price.min;
    let sellingPriceMax = defaultSettings.selling_price.max;
    let weightMin = defaultSettings.weight.min;
    let weightMax = defaultSettings.weight.max;
    let weightUnit = defaultSettings.weight.unit;
    let approvedCategory = {};

    // 3 - Override default settings with custom settings if custom settings data exist.
    if (customSettings) {
      if (customSettings.bsr) {
        if (customSettings.bsr.min) { bsrMin = customSettings.bsr.min; }
        if (customSettings.bsr.max) { bsrMax = customSettings.bsr.max; }
      }
      if (customSettings.review_count) {
        if (customSettings.review_count.min) { reviewCountMin = customSettings.review_count.min; }
        if (customSettings.review_count.max) { reviewCountMax = customSettings.review_count.max; }
      }
      if (customSettings.seller_count) {
        if (customSettings.seller_count.min) { sellerCountMin = customSettings.seller_count.min; }
        if (customSettings.seller_count.max) { sellerCountMax = customSettings.seller_count.max; }
      }
      if (customSettings.selling_price) {
        if (customSettings.selling_price.min) { sellingPriceMin = customSettings.selling_price.min; }
        if (customSettings.selling_price.max) { sellingPriceMax = customSettings.selling_price.max; }
      }
      if (customSettings.weight) {
        if (customSettings.weight.min) { weightMin = customSettings.weight.min; }
        if (customSettings.weight.max) { weightMax = customSettings.weight.max; }
        if (customSettings.weight.unit) { weightUnit = customSettings.weight.unit; }
      }
      // e.g. customSettings.approved_category = { 1: 'Category1', 2: 'Category2', ... }
      if (customSettings.approved_category) {
        approvedCategory = customSettings.approved_category;
      }
    }

    // 4 - Prepare data for settings
    const toolSettingsCombined = {
      bsr: { min: bsrMin, max: bsrMax },
      review_count: { min: reviewCountMin, max: reviewCountMax },
      seller_count: { min: sellerCountMin, max: sellerCountMax },
      selling_price: { min: sellingPriceMin, max: sellingPriceMax },
      weight: { min: weightMin, max: weightMax, unit: weightUnit },
      approved_category: approvedCategory,
    }
    console.log('toolSettingsCombined:', toolSettingsCombined);

    // 5 - Set settings data within state
    this.setState({
      toolSettings: toolSettingsCombined,
      toolSettingsFetched: true,
    });

    // 6 - Remove approved category from default restricted category list
    // and save it within class level variable.
    const filteredCategory = {};
    Object.keys(this.restrictedCategories).forEach( (key, index) => {
      if( approvedCategory[key] === undefined ) {
        filteredCategory[key] = this.restrictedCategories[key];
      }
    });
    this.restrictedCategories = filteredCategory;
    //console.log('this.restrictedCategories:', this.restrictedCategories);
  }
  //-----------------------------------------------------------------
  // End: Fetch potential product settings related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Form submit related functions
  //-----------------------------------------------------------------
  // Called when submit button tapped via form component, this function will be called 
  // with market and search query value, We will fetch product list via mws api and 
  // set searched product list within state.
  onSubmitForm = (market, query) => {
    //console.log('onSubmitForm() market:', market, ' query:', query);

    // 1 - Start processing
    // Also save market and query value within state, so we can use it later on.
    // Also reset some state variable so it will show updated ui.
    this.setState({
      market: market, 
      query: query, 
      isProcessing: true,
      products: [],
      isCalculatingPotentialIndex: true,
    });

    // 2 - Call api to fetch matching product
    listMatchingProducts(query, market, this.listMatchingProductsSuccess, this.listMatchingProductsError);
  }

  // Called if api call success (listMatchingProducts)  
  listMatchingProductsSuccess = (result) => {
    console.log("listMatchingProductsSuccess() result:", result);

    const { data } = result;
    //const { status, error } = result;
    // console.log('status:', status);
    // console.log('data:', data);
    // console.log('error:', error);

    if ( data.StatusCode === 200 ) {
      // Save data received from api to class (this.) variable. We will not show this 
      // data directly to ui element, So no need to save it within state.
      this.PRODUCTS_RAW = data.Products.Product;

      // Prepare products data to show within ui.
      this.processProductsRawData();

    } else {
      this.setState({
        showMessage: true,
        messageText: 'Matching products not found, please try another search terms.',
        isProcessing: false,
        isCalculatingPotentialIndex: false,
      });
    }
  }

  // Called if error while api call (listMatchingProducts)
  listMatchingProductsError = (error) => {
    console.log("listMatchingProductsError() error: ", error);    
    
    this.setState({
      showMessage: true,
      messageText: 'Matching products fetch error, please try again.',
      isProcessing: false,
      isCalculatingPotentialIndex: false,
    });
  }

  // This function will process product raw data (received from api) 
  // and prepare products array to display info within ui. 
  processProductsRawData = () => {
    console.log('processProductsRawData()');

    const { market, toolSettings } = this.state;

    const potentialDataDict = {};  // Important: must be dictionary { key1: value1, key2: value2 }    
    const products = [];           // Important: must be array of data object.

    // Fetch each record from raw data and save it so simple format.
    const { PRODUCTS_RAW } = this;
    PRODUCTS_RAW.forEach( (product, index) => { 
      
      const { ASIN } = product.Identifiers.MarketplaceASIN;
      const { ItemAttributes } = product.AttributeSets;
      const { SmallImage, Title } = ItemAttributes;
      
      // Important: All keys start with Capital letter
      const tempProduct = {
        ASIN: ASIN,
        Market: market, 
        SmallImageUrl: SmallImage.URL,
        SmallImageWidth: SmallImage.Width.Value,
        SmallImageHeight: SmallImage.Height.Value,
        Title: Title,
        PotentialRank: null,   // We will calculate % value (0-100) and set here
        Status: 'waiting'      // 'waiting', 'processing', 'done'
      };
      products.push(tempProduct);

      // Within raw data weight information is there, so we will fetch weight 
      // and set it within POTENTIAL_DATA_DICT data object, so we dont need to 
      // call dedicated api to fetch weight for each asin.
      let weightValue = 0;
      let weightUnit = '';
      const { PackageDimensions } = product.AttributeSets.ItemAttributes;
      if (!PackageDimensions){ // PackageDimensions Not exist, So weight information not available
      } else {
        const { Weight } = PackageDimensions;
        weightValue = parseFloat(Weight.Value);
        weightUnit = Weight.Units;
      }
      // Decide status for weight
      let weightStatus = 'no';
      if ( weightValue >= toolSettings.weight.min && weightValue < toolSettings.weight.max ) { // if weight less than 5 lbs, set yes
        weightStatus = 'yes';
      }
      //console.log('weightValue:', weightValue, ' weightUnit:', weightUnit, ' weightStatus:', weightStatus);
  
      // Important: all keys start with small letter
      const tempData = {
        bsr: null,
        sellingPrice: null,
        reviewCount: null,
        weight: weightValue,
        weightUnit: weightUnit, 
        productCategory: null,
        numberOfSellers: null,

        bsrStatus: null,              // 'yes' or 'no'
        sellingPriceStatus: null,     // 'yes' or 'no'
        reviewCountStatus: null,      // 'yes' or 'no'
        weightStatus: weightStatus,   // 'yes' or 'no'
        productCategoryStatus: null,  // 'yes' or 'no'
        numberOfSellersStatus: null,  // 'yes' or 'no'
      }
      potentialDataDict[ASIN] = tempData;

    }); // End of PRODUCTS_RAW.forEach()
    console.log('products:', products);

    // Save data to class variable, reset current index so we can start 
    // calculating potential product from index 0
    this.POTENTIAL_DATA_DICT = potentialDataDict;
    this.CURRENT_INDEX = -1;
    console.log('this.POTENTIAL_DATA_DICT:', this.POTENTIAL_DATA_DICT);

    // Save data to state.
    this.setState({
      products: products,
      isProcessing: false,
      isCalculatingPotentialIndex: true,
    });

    // Calculate total number of seller based on the GetCompetitivePricingForAsin() mws api.
    this.calculateTotalNumberOfSeller();
  }
  //-----------------------------------------------------------------
  // End: Form submit related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // START: Find Total number of seller and other info
  //-----------------------------------------------------------------
  // This function will collect asin from product list and call getCompetitivePricingForAsin()
  // mws api and find out OfferListingCount maximum value from result. So OfferListingCount 
  // maximum value will be the total (or max.) number of seller selling (competiting for) 
  // the searched product.
  calculateTotalNumberOfSeller = () => {
    console.log('calculateTotalNumberOfSeller()');

    // 1 - Fetch maket value from state.
    const { market } = this.state;

    // 2 - Prepare asins string from products data. (comma separated asins)
    // e.g. 'asin1,asin2,asin3' etc.
    let asinList = '';  
    const count = this.state.products.length;
    this.state.products.forEach( (product, index) => {
      asinList += product.ASIN;
      if (index < count-1) {
        asinList += ',';
      }
    });
    console.log('asinList:', asinList);

    // 3 - Call mws api getCompetitivePricingForASIN() for multiple asin
    getCompetitivePricingForASIN(market, asinList, this.getCompetitivePricingForASIN_Success_All, this.getCompetitivePricingForASIN_Error_All);
  };

  // If api call success, this function will be called with multiple product within result.
  // Note: This api also return other info we need e.g. Selling Price, Sellers Count, 
  // and BSR etc. so we will parse all info from the result.
  getCompetitivePricingForASIN_Success_All = (result) => {
    //console.log("getCompetitivePricingForASIN_Success_All()");
    console.log("getCompetitivePricingForASIN_Success_All() result:", result);

    // 1 - Get necessary data from the result
    //const { data, status, error } = result;
    const { data } = result;
    const { toolSettings } = this.state;

    // 2 - We supplied multiple asin to api so result.data consist array of products, 
    // otherwise it have single data object. So we have handle both situation as under.
    let productsTemp = [];
    if ( Array.isArray(data) ) { // Multiple product received within result.data
      data.forEach( (singleData, index) => {
        if (singleData.Product) {
          productsTemp.push(singleData.Product);
        }
      });
    } else { // Single Product received within result.data
      if (data.Product) {
        productsTemp = [data.Product];
      }
    }
    //console.log("productsTemp:", productsTemp);

    // 3 - Find max value for OfferListingCount (maximum number of seller), 
    // it will give maximum number of seller among all asins we supplied to api.
    let offerListingCountMax = 0; 
    productsTemp.forEach( (product, index) => {
      // Fetch offer listing count array for current product.
      // e.g. OfferListingCount = [{"Value":"16","condition":"New"},{"Value":"16","condition":"Any"}]}
      const { OfferListingCount } = product.CompetitivePricing.NumberOfOfferListings;
      //const { ASIN } = product.Identifiers.MarketplaceASIN; // Fetch asin (Debug purpose)
      
      // Loop each record from OfferListingCount array.
      OfferListingCount.forEach((offer, index) => { 
        //console.log(ASIN, JSON.stringify(offer)); // Debug
        // If offer.Value is higher than offerListingCountMax then make it max.
        const offerValue = parseInt(offer.Value); // Convert to number, because Value in string format.
        if ( offerValue > offerListingCountMax ) { offerListingCountMax = offerValue; }
      });
    });
    console.log('offerListingCountMax:', offerListingCountMax);

    // 4 - Find Listing price, and Rank for each product
    productsTemp.forEach( (product, index) => { 
      const { ASIN } = product.Identifiers.MarketplaceASIN; // Fetch asin (Debug purpose)
      //console.log('ASIN ', index, ASIN);

      // A - Grab product listing/selling price from the data.
      let sellingPrice = 0;
      let sellingPriceCurrencyCode = '';
      const { CompetitivePrice } = product.CompetitivePricing.CompetitivePrices;
      if (!CompetitivePrice) { // CompetitivePrice not exist (Do nothing)
      } else { // CompetitivePrice exist
        // CompetitivePrice may be single data object or array. If array then it may have 
        // 'Used' or 'New' condition item, so find out price for a 'New' codition item.
        let ListingPrice;
        if (Array.isArray(CompetitivePrice)) { // Array (multiple price)
          CompetitivePrice.forEach( (item, index) => { // Loop array and find item ListingPrice for 'New' condition.
            if (!ListingPrice) { // listing price not found yet then grab its value.
              if (item.condition === 'New') { // If item condition 'New' then grab price info.
                ListingPrice = item.Price.ListingPrice;
              }
            }
          });
        } else { // Single data object (not array)
          ListingPrice = CompetitivePrice.Price.ListingPrice;
        }
        //console.log('ListingPrice:', ListingPrice);
        sellingPrice = ListingPrice ? ListingPrice.Amount : 0;
        sellingPriceCurrencyCode = ListingPrice ? ListingPrice.CurrencyCode : '';
        //console.log('sellingPrice:', sellingPrice, sellingPriceCurrencyCode);
      }

      // B - Find number of sellers count for this asin (Highest offer is number of seller count)
      // Note: We already find out maximum number of seller among all asin in step 3, So we 
      // We will use same value for all asin because we are showing potential index among asin 
      // received with search result, so we will not consider number of seller for particular 
      // but we will consider maximum number of seller among the all asin received in a result.

      // C - Find best seller rank from data
      // e.g. [ {ProductCategoryId: "ce_display_on_website", Rank: "24"}, 
      //       {ProductCategoryId: "3312811011", Rank: "2"} ]
      // SalesRank may be single data object or Array type so we have to check type 
      // of product.SalesRankings.SalesRank and parse the data accordingly.
      let bsr = 0;
      const { SalesRank } = product.SalesRankings;
      if (!SalesRank) { // SalesRank data not exist (Do nothing)
      } else { // SalesRank data exist
        let rankData;
        if ( Array.isArray(product.SalesRankings.SalesRank) ) { // Array
          rankData = product.SalesRankings.SalesRank[0];
        } else { // Single data object
          rankData = product.SalesRankings.SalesRank;
        }
        if (rankData.Rank) { bsr = parseInt(rankData.Rank) }  
      }
      //console.log('bsr:', bsr);

      // D - Decide status for selling price, number of seller, bsr and set to state.
      let sellingPriceStatus = 'no';
      if (parseFloat(sellingPrice) >= toolSettings.selling_price.min && parseFloat(sellingPrice) <= toolSettings.selling_price.max) {
        sellingPriceStatus = 'yes'; // Selling price between $20 - $65 so yes
      }
      let numberOfSellersStatus = 'no';
      if ( offerListingCountMax >= toolSettings.seller_count.min && offerListingCountMax <= toolSettings.seller_count.max) {
        numberOfSellersStatus = 'yes'; // e.g. # of sellers between 5 - 10 so yes
      }
      let bsrStatus = 'no';
      if ( bsr >= toolSettings.bsr.min && bsr <= toolSettings.bsr.max ) { // if BSR between 500 - 2,000 then yes
        bsrStatus = 'yes';
      }

      // E - Set value within class variable for current asin
      this.POTENTIAL_DATA_DICT[ASIN].sellingPrice = parseFloat(sellingPrice);
      this.POTENTIAL_DATA_DICT[ASIN].sellingPriceStatus = sellingPriceStatus;
      this.POTENTIAL_DATA_DICT[ASIN].sellingPriceCurrencyCode = sellingPriceCurrencyCode;
      this.POTENTIAL_DATA_DICT[ASIN].numberOfSellers = offerListingCountMax;
      this.POTENTIAL_DATA_DICT[ASIN].numberOfSellersStatus = numberOfSellersStatus;
      this.POTENTIAL_DATA_DICT[ASIN].bsr = bsr;
      this.POTENTIAL_DATA_DICT[ASIN].bsrStatus = bsrStatus;
      //console.log('this.POTENTIAL_DATA_DICT:', this.POTENTIAL_DATA_DICT);
    }); // End of 4 
    
    // Debug
    console.log('this.POTENTIAL_DATA_DICT:', this.POTENTIAL_DATA_DICT);

    // Calculate potential product rank one by one
    // Note: Untill now we alredy collected some parameter required to calculate 
    // potential index, so next step we will collect remaining parameter.
    setTimeout(this.calculatePotentialProduct, 500);
  } 
  
  // If error while callling api then this function called.
  getCompetitivePricingForASIN_Error_All = (error) => {
    console.log("getCompetitivePricingForASIN_Error_All() error: ", error);

    this.setState({
      showMessage: true,
      messageText: "Product info not available",
    });

    // Calculate potential product rank one by one
    setTimeout(this.calculatePotentialProduct, 500);
  }
  //-----------------------------------------------------------------
  // END: Find Total number of seller and other info
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Calculate potential product related functions
  //-----------------------------------------------------------------
  calculatePotentialProduct = () => {
    console.log('calculatePotentialProduct()');

    // 1 - Find total product count, We have to process each product one by one.
    const totalProductCount = this.state.products.length;

    // 2 - Increment current index
    this.CURRENT_INDEX = this.CURRENT_INDEX + 1;
    console.log('this.CURRENT_INDEX: ', this.CURRENT_INDEX);

    // 3 - Process current index if all product not processed yet.
    if (this.CURRENT_INDEX < totalProductCount) { 
    
      this.calculatePotentialProductNext();

    } else { // All product processed
      console.log('All Product Potential Index Done');
      
      // Update state so it will enable form submit button
      this.setState({
        isCalculatingPotentialIndex: false,
      });
    }
  }

  // This function will calculate potential product for current index
  calculatePotentialProductNext = () => {
    console.log("calculatePotentialProductNext() CURRENT_INDEX:", this.CURRENT_INDEX);

    // 1 - Set processing true for current index, 
    const productsTemp = [...this.state.products];
    productsTemp[this.CURRENT_INDEX].Status = 'processing';
    this.setState({
      products: productsTemp,
    }); 
    
    // 2 - We have to do multiple api call to calculate potential rank for a product.
    // So we will call first api, then second, then third etc.
    this.callFirstApi();
  }

  // First Api - Fetch review count (#1)
  callFirstApi = () => {
    //console.log('callFirstApi() - Fetch Review Count');

    // 1 - Fetch asin and market for current product
    const currentProduct = this.state.products[this.CURRENT_INDEX];
    const asin = currentProduct.ASIN;
    const market = currentProduct.Market;
    console.log('callFirstApi() asin:', asin, ' market:', market);

    // 2 - Call api via helper function
    getProductReviewInfo(asin, market, this.getProductReviewInfo_Success, this.getProductReviewInfo_Error);
  }

  // Second Api - Fetch Selling Price, Sellers Count, and BSR (#2-3-4)
  // No need this api because we calculated this value before.

  // Third Api - Call api to fetch product weight (#5)  
  // No need this api because weight calculated before.

  // Fourth Api - Fetch category name (#6)
  callFourthApi = () => {
    //console.log('callFourthApi() - Fetch Category Name');

    // 1 - Fetch asin and market for current product
    const currentProduct = this.state.products[this.CURRENT_INDEX];
    const asin = currentProduct.ASIN;
    const market = currentProduct.Market;
    console.log('callFourthApi() asin:', asin, ' market:', market);

    // 2 - Call api via helper function
    getProductCategoriesForASIN(market, asin, this.getProductCategoriesForASIN_Success, this.getProductCategoriesForASIN_Error);    
  }

  // Calculate potential rank for current product. 
  // i.e. It will calculate percentage value (0-100) based on parameter collected that is 
  // necessary to calculate potential product. Once all api call processed (parameter collected) 
  //for current product, we will calculate potential rank i.e. percentage.
  calculatePotentialRank = () => {
    console.log('calculatePotentialRank()');

    // 1 - Fetch asin for current index
    const { ASIN } = this.state.products[this.CURRENT_INDEX];
    
    // 2 - Fetch potential product related data from dict.
    const { bsrStatus, sellingPriceStatus, reviewCountStatus, weightStatus, 
      productCategoryStatus, numberOfSellersStatus } = this.POTENTIAL_DATA_DICT[ASIN];

    // 3 - Count how many parameter have yes value
    let yesCount = 0;
    if (bsrStatus && bsrStatus === 'yes') { yesCount++ } 
    if (sellingPriceStatus && sellingPriceStatus === 'yes') { yesCount++ } 
    if (reviewCountStatus && reviewCountStatus === 'yes') { yesCount++ }
    if (weightStatus && weightStatus === 'yes') { yesCount++ }
    if (productCategoryStatus && productCategoryStatus === 'yes') { yesCount++ }
    if (numberOfSellersStatus && numberOfSellersStatus === 'yes') { yesCount++ }
    
    // 4 - Calculate percentage based on yes value. (i.e. Convert yesCount to percentage)
    // We have total six parameter, so we have to calculate yes percentage accordingly.
    const percentage = parseInt((100 * yesCount)/6);

    // 5 - Set PotentialRank (percentage) for current product and set Status = 'done',
    // so it will show potential product slider with percentage value as slider pointer.
    const productsTemp = [...this.state.products];
    productsTemp[this.CURRENT_INDEX].Status = 'processing';
    productsTemp[this.CURRENT_INDEX].PotentialRank = percentage;
    productsTemp[this.CURRENT_INDEX].Status = 'done';
    this.setState({
      products: productsTemp,
    }); 

    // 6 - Process next product from the list
    setTimeout(this.calculatePotentialProduct, 400);
  }
  //-----------------------------------------------------------------
  // End: Calculate potential product related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // START: 1 - Fetch review count
  //-----------------------------------------------------------------  
  getProductReviewInfo_Success = (result, asin) => {
    console.log('getProductReviewInfo_Success() result:', result, ' asin', asin);

    const { data } = result;
    const { toolSettings } = this.state;

    // 1 - Fetch review count and its status
    let reviewCountStatus = 'no';
    if ( data.review_count > toolSettings.review_count.min && data.review_count < toolSettings.review_count.max ) { 
      reviewCountStatus = 'yes'; // reviews less than 1,000, so yes
    }

    // 2 - Set value within class variable for current index.
    const currentProduct = this.state.products[this.CURRENT_INDEX];
    const { ASIN } = currentProduct;
    this.POTENTIAL_DATA_DICT[ASIN].reviewCount = data.review_count;
    this.POTENTIAL_DATA_DICT[ASIN].reviewCountStatus = reviewCountStatus;
    
    // Debug
    console.log('this.POTENTIAL_DATA_DICT:', this.POTENTIAL_DATA_DICT);

    // 3 - Call Fourth Api - Fetch category name (#6)
    setTimeout(this.callFourthApi, 200);
  }

  getProductReviewInfo_Error = (error) => {
    console.log('getProductReviewInfo_Error() error:', error);
    
    // Show error message, but do not stop processing
    this.setState({
      showMessage: true,
      messageText: 'BSR/Review info fetch error.',
    });

    // Call Fourth Api - Fetch category name (#6)
    setTimeout(this.callFourthApi, 200);
  }
  //-----------------------------------------------------------------
  // END: 1 - Fetch review count
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: 2-3-4 Fetch Selling Price, Sellers Count, and BSR
  //-----------------------------------------------------------------  
  // No need to call api for this because we calculated this before
  //-----------------------------------------------------------------
  // END: 2-3-4 Fetch Selling Price, Sellers Count, and BSR
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: 5 Fetch product weight
  //-----------------------------------------------------------------  
  // No need to call api for this because we calculated this before
  //-----------------------------------------------------------------
  // END: 5 Fetch product weight
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------      
  // START: 6 Fetch category name
  //-----------------------------------------------------------------      
  getProductCategoriesForASIN_Success = (result) => {
    console.log("GetProductCategoriesForASIN_Success() result:", result);

    // 1 - Read data from restult
    //const { status, data, error } = result;    
    const { data } = result;

    // 1A - If data not received then show error message and return
    if (!data) {
      console.log('Product category info not found, so return.');

      this.setState({
        showMessage: true,
        messageText: 'Product category info not found.',
      });

      // Calculate potential rank 
      // i.e. It will calculate percentage value based on potential data collected.
      this.calculatePotentialRank(); 

      return; // IMPORTANT
    }
    
    // 2 - If multiple result then we will consider first result from array.
    let categoryTree; 
    if ( Array.isArray(data.Self) ) { // multiple result array
      categoryTree = data.Self[0];  // Grab first element from array
    } else { // Single data object
      categoryTree = data.Self;
    }
    //console.log('categoryTree:', JSON.stringify(categoryTree));

    // 3 - Find root_category_name and root_category_id category tree
    const rootCategoryInfo = this.findRootCategoryInfo(categoryTree);
    let rootCategoryName = '';
    let rootCategoryId = null;
    if (rootCategoryInfo) {
      rootCategoryName = rootCategoryInfo.categoryName;
      rootCategoryId = rootCategoryInfo.categoryId;
    }
    const isUnrestricted = this.isUnrestrictedCategory(rootCategoryName);

    // 4 - Set value within class variable for current index.
    const currentProduct = this.state.products[this.CURRENT_INDEX];
    const { ASIN } = currentProduct;
    this.POTENTIAL_DATA_DICT[ASIN].productCategory = rootCategoryName;
    this.POTENTIAL_DATA_DICT[ASIN].productCategoryId = rootCategoryId;
    this.POTENTIAL_DATA_DICT[ASIN].productCategoryStatus = isUnrestricted;

    // Debug
    console.log('this.POTENTIAL_DATA_DICT:', this.POTENTIAL_DATA_DICT);

    // 5 - Calculate potential product rank 
    // i.e. It will calculate percentage (0-100) based on potential data collected.
    this.calculatePotentialRank(); 
  }

  getProductCategoriesForASIN_Error = (error) => {
    console.log("getProductCategoriesForASIN_Error() error: ", error);
    
    // Show error message
    this.setState({
      showMessage: true,
      messageText: 'Product category info not found.',
    });

    // 5 - Calculate potential product rank 
    // i.e. It will calculate percentage (0-100) based on potential data collected.
    this.calculatePotentialRank(); 
  }

  // This function will trverse category tree and return rootCategoryName 
  // and rootCategoryId within data object as shown under.
  // e.g. Return value: { categoryName: '', categoryId: 0 }
  findRootCategoryInfo = (categoryTree) => {
  
    // 1 - Prepare null data object
    // e.g. { categoryName: '', categoryId: 0 }
    let categoryInfo;

    // 2 - Create a duplicate copy of category tree
    let categoryTreeTemp = { ...categoryTree };

    // 3 - Save dategory tree to array (from child to parent)
    // i.e. first element is deep level child, last element is top level category.
    const categoryArray = [];
    let hasParent = true;
    while(hasParent){
      // 3A - Push category info into array
      categoryArray.push({
        ProductCategoryId: categoryTreeTemp.ProductCategoryId,
        ProductCategoryName: categoryTreeTemp.ProductCategoryName
      });

      // 3B - If more parent exist then go to deep level.
      if (categoryTreeTemp.Parent) {
        hasParent = true;
        categoryTreeTemp = categoryTreeTemp.Parent;
      } else {
        hasParent = false;
      }
    }
    //console.log('categoryArray:', categoryArray);

    // Grab last and second last element from array, it is root category we need.
    // amzon mws api result in such a way that second last element have category name 
    // and last element have categoryId within it.
    const arrayLength = categoryArray.length;
    // atleast two element required within array  
    if (arrayLength >= 2) { 
      const categoryName = categoryArray[arrayLength - 1].ProductCategoryName;  // last element
      const categoryId = categoryArray[arrayLength - 2].ProductCategoryId;      // Second last element
      categoryInfo = { 
        categoryId: categoryId, 
        categoryName: categoryName, 
      }  
    }
    //console.log('categoryInfo:', categoryInfo);

    return categoryInfo;
  }  

  // This function will check that given category consider as unrestricted or not.
  // @ categoryName  String (name of category e.g. Electronics )
  // return 'yes' - if given category not restricted.
  // return 'no' - if given category is restricted.
  isUnrestrictedCategory = (categoryName) => {
    const categoryNameLower = categoryName.toLowerCase();
    let isUnrestricted = 'yes';

    Object.keys(this.restrictedCategories).forEach( (key, index) => {      
      const categoryTitle = this.restrictedCategories[key].toLowerCase();
      
      // if ( categoryTitle.includes(categoryNameLower) ) {
      //   isUnrestricted = 'no';
      // }
      if (categoryTitle === categoryNameLower) {
        isUnrestricted = 'no';
      }
    });
    
    return isUnrestricted;
  }
  //-----------------------------------------------------------------
  // END: 6 Fetch category name
  //-----------------------------------------------------------------


  // Called when message close after time out
  handleMessageClose = () => {
    //console.log('handleMessageClose()'); 
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }

  // Render current settings
  renderToolSettings = () => {

    const { toolSettings } = this.state;
    if (!toolSettings) { return null }

    return (
      <Paper elevation={1} style={{padding: 20}} >
        <Typography variant="h6">Current Settings:</Typography>
        <Typography variant="body1" >Potential index calculated based on the below parameter:</Typography>
        <div style={{height: 10}} ></div>
        <Grid container spacing={1} >
          <Grid item align='right' style={{width: 150}} >
            <Typography variant="body1">Best Seller Rank:</Typography>
          </Grid>
          <Grid item >
            <Typography variant="body1">
              {toolSettings.bsr.min} - {toolSettings.bsr.max}
            </Typography>
          </Grid>
          <Grid item align='right' style={{width: 150}} >
            <Typography variant="body1">Review Count: &nbsp;</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {toolSettings.review_count.min} - {toolSettings.review_count.max}
            </Typography>
          </Grid>
          <Grid item align='right' style={{width: 150}} >
            <Typography variant="body1">Seller Count: &nbsp;</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {toolSettings.seller_count.min} - {toolSettings.seller_count.max}
            </Typography>
          </Grid>
          <Grid item align='right' style={{width: 150}} >
            <Typography variant="body1">Selling Price: &nbsp;</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              ${toolSettings.selling_price.min} - ${toolSettings.selling_price.max}
            </Typography>
          </Grid>
          <Grid item align='right' style={{width: 150}} >
            <Typography variant="body1">Weight: &nbsp;</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {toolSettings.weight.min} - {toolSettings.weight.max} {toolSettings.weight.unit}
            </Typography>
          </Grid>

        </Grid>
      </Paper>
    );
  }

  render() {
    const { isProcessing, toolSettingsFetched, isCalculatingPotentialIndex, products } = this.state;
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return(
      <React.Fragment>
        <PotentialProductSearchForm 
          onSubmit={this.onSubmitForm} 
          isProcessing={ isProcessing || !toolSettingsFetched || isCalculatingPotentialIndex }
        />
        
        { isProcessing && <LinearProgress /> }

        <div style={{height: 20}} ></div>

        { products.length > 0 && 
          <PotentialProductSearchResult 
            products={products} 
            isProcessing={isProcessing} 
          />
        }

        <div style={{height: 30}} ></div>

        { this.renderToolSettings() }

        <div style={{height: 30}} ></div>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={5000}
          onClose={this.handleMessageClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </React.Fragment>
    );
  }
}


const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(PotentialProductSearch);


import React from "react";
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


// Login with email pass form
import EmailPassword from './login/EmailPassword';
import LoginWithFacebook from './login/LoginWithFacebook';
import LoginWithGoogle from './login/LoginWithGoogle';


class LoginPage extends React.Component { 

  componentDidMount = () => {
    console.log('LoginPage - componentDidMount()');
  }

  componentWillUnmount = () => {
    console.log('LoginPage - componentWillUnmount()');
  }

  render() {
    return(
      <div>
        {
        // <Typography component="h2" variant="h5">
        //   Login Page
        // </Typography>
        }
        
        <br />
        <Grid container spacing={1} >
          <Grid item xs={12} sm={3} md={3} lg={3} >
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} >
            <LoginWithGoogle />
            <br />
            <LoginWithFacebook />
            <br />
            <EmailPassword />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} >
          </Grid>
        </Grid>

      </div>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(LoginPage);


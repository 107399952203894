// This file consist puppeteer related helper functions, that will call api from cloud run.

import firebase from './firebaseApp';
import { config, marketPlaces } from './constants';
//import { getCompetitivePricingForASIN } from './mwsHelper';


// Get api base url from config constant
const API_BASE_URL = config.API_BASE_URL;

// Debug
console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
console.log('API_BASE_URL:', API_BASE_URL);

const db = firebase.firestore();


//-------------------------------------------------------------------
// Get product review info 
//-------------------------------------------------------------------
// It will return product review count, star count for given asin+market
// If info already exist within db (not older than 10 days), then it will retun
// info from the db, otherwise it will fetch rating using puppteer, save info to 
// db and return that info.
export const getProductReviewInfo = async (asin, market, successCallback, errorCallback) => {
  //console.log('getProductReviewInfo() asin:', asin, ' market:', market);

  // 1 - First Via client side sdk check that review existwithin db or not.
  // If exist and valid (i.e. not older than 10 days) then return it. 
  // So no need to call server sider api, Otherwise call the server side api.
  const doc = await db.collection('product_reviews').doc(asin).get();
  if (doc.exists) { // doc exist within db    
    const data = doc.data();
    const marketLower = market.toLowerCase(); // e.g. CA -> ca
    const marketData = data[marketLower];     // e.g. data.ca
    //console.log('marketData:', marketData);

    if (marketData) { // if data exist within db for the market
      const lastUpdateDate = marketData.review_last_update.toDate();
      const currentDate = new Date();
      const daysDiff = dateDiffInDays(lastUpdateDate, currentDate);      
      if ( daysDiff < 10 && marketData.review_count > 0 ) { 
        // Existing data not less than 10 days older and review count not zero, So send this data.
        console.log('Review data fetched via client side sdk.');
        const jsonBody = { status: 'success', data: marketData, error: null};
        successCallback(jsonBody, asin);
        return;  // IMPORTANT
      }
    }
  }

  // If control reach at this step means review latest data not exist within db, So we 
  // will call server side api to fetch latest review and return it. So below step will
  // do that task.

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    asin: asin,       // e.g. B002OWETK4
    market: market,   // e.g. 'US'
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/getProductReviewInfo';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getProductReviewInfo() - jsonBody:', jsonBody);
    // e.g. jsonBody = { status: 'success', data: marketData, error: null};
    successCallback(jsonBody, asin);
  })
  .catch( error => {
    console.log('getProductReviewInfo() error:', error);
    errorCallback(error);
  });
}

// Return difference between two dates in Days
// Note: It will calcualte difference from date2 - date1
// e.g. To find diff between current date and some older date, pass current date 
// as the second paremeter and older date as first parameter 
// @date1 javascript date object 
// @date2 javascript date object
const dateDiffInDays = (date1, date2) => {
  const dt1 = date1;
  const dt2 = date2;
  return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
}
//-------------------------------------------------------------------


/* WE ARE NOT USING THIS FUNCTION AT PRESENT, SO COMMENTED
//-------------------------------------------------------------------
// Get product listing (Selling) price
//-------------------------------------------------------------------
// This function will check within database if listing price exist or not.
// If exist within db then it will return result from db. Otherwise call 
// mws api, Parse result, Save list price within db, and Send data to caller.
export const getProductListingPrice = async (market, asin, successCallback, errorCallback) => {
  //console.log('getProductListingPrice() market:', market, ' asin:', asin);

  // 1 - First Via client side sdk check that asin exist db or not.
  // If exist and list price data (i.e. not older than 10 days) then return it. 
  // So no need to call server sider api, Otherwise call the server side api.
  const doc = await db.collection('product_reviews').doc(asin).get();
  if (doc.exists) { // doc exist within db    
    const data = doc.data();
    const marketLower = market.toLowerCase(); // e.g. CA -> ca
    const marketData = data[marketLower];     // e.g. data.ca
    //console.log('db marketData:', marketData);

    // Check that listing price exist within data or not.
    if (marketData && marketData.list_price) { // if list_price exist within db for the market
      const lastUpdateDate = marketData.list_price_last_update.toDate();
      const currentDate = new Date();
      const daysDiff = dateDiffInDays(lastUpdateDate, currentDate);
      if ( daysDiff < 10 ) { // Existing data not less than 10 days older, so return this data.
        console.log('List price fetched from db via client side sdk.');
        const data = {
          list_price: marketData.list_price, 
          list_price_currency_code: marketData.list_price_currency_code,
        }
        const jsonBody = { status: 'success', data: data, error: null};
        successCallback(jsonBody);
        return;  // IMPORTANT
      }
    }  
  }

  // If control reach at this step means listing price data not exist within db, So we 
  // will call server side api to fetch listing price, save listing price to db and return 
  // data to caller. So below step will do that task.
  console.log('Latest list price not exist within db, so fetching via mws api.');

  // 2 - Mws api call success handler function
  const getCompetitivePricingForASIN_Success = (result) => {
    console.log("getCompetitivePricingForASIN_Success() result:", result);

    // A - If multiple asin supplied to mws api then result.data consist array of products, 
    // otherwise it is data object (not array) with single product info.
    //const { data, status, error } = result;
    const { data } = result;
    
    // B - We called api with single asin so in our case it return one product as data object, 
    // so here we written logic to handle single product data.
    let product;
    if (data.Product) { product = data.Product }
    //console.log("product:", product);

    // C - Fetch competitive price.
    const { CompetitivePrice } = product.CompetitivePricing.CompetitivePrices;
    //console.log('CompetitivePrice:', CompetitivePrice);

    // D - If CompetitivePrice not available (may be product currently not available) then return message.
    if (!CompetitivePrice) {
      const error = { 
        code: 'list_price_not_exist', 
        message: "List price not exist because product may be currently unavailable.",
      }
      const jsonBody = { status: 'error', data: {}, error: error};
      successCallback(jsonBody, asin);
      return;  // IMPORTANT
    }

    // E - CompetitivePrice may be single data object or array, so we have to handle it.
    // If array then it may have price for 'Used' or 'New' condition item so we have to find 
    // out price for 'New' codition.
    let ListingPrice;
    if (Array.isArray(CompetitivePrice)) { // If array
      CompetitivePrice.forEach( (item, index) => { // Loop array and find ListingPrice with 'New' condition.
        if (!ListingPrice) { // listing price not found yet then find it
          if (item.condition === 'New') { // Grab price if item condition is new.
            ListingPrice = item.Price.ListingPrice;
          }
        }
      });
    } else { // Price as single data object (not array)
      ListingPrice = CompetitivePrice.Price.ListingPrice;
    }
    //console.log('ListingPrice:', ListingPrice);

    // F - Return list price data to caller.
    const listPriceData = {
      list_price: parseFloat(ListingPrice.Amount),
      list_price_currency_code: ListingPrice.CurrencyCode,
    }
    const jsonBody = { status: 'success', data: listPriceData, error: null }
    successCallback(jsonBody);
    console.log('List price fetched and returned via mws api'); 

    // G - Save list price latest data within db, so we dont need to call mws api 
    // when next time need same data (within 10 days period).
    saveListPriceWithinDB(market, asin, parseFloat(ListingPrice.Amount), ListingPrice.CurrencyCode);
  }

  // 3 - Mws api call error handler
  const getCompetitivePricingForASIN_Error = (error) => {
    console.log("getCompetitivePricingForASIN_Error() error: ", error);
    const e = { 
      code: 'list_price_fetch_error', 
      message: "error while fetch list price via mws api",
    }
    errorCallback(e);
  }

  // Call mws api with success and error handler
  getCompetitivePricingForASIN(market, asin, getCompetitivePricingForASIN_Success, getCompetitivePricingForASIN_Error);
}

// This function will save given list price data within db under given market + asin
const saveListPriceWithinDB = async (market, asin, listPrice, currencyCode) => {
  //console.log('saveListPriceWithinDB()');
  console.log('saveListPriceWithinDB() market:', market, ' asin:', asin, ' listPrice:', listPrice, ' currencyCode:', currencyCode);
  
  // 1 - Prepare data to save within db
  const listPriceInfo = {
    list_price: listPrice, 
    list_price_currency_code: currencyCode,
    list_price_last_update: new Date(),
  }
  const marketLower = market.toLowerCase(); // e.g. CA -> ca
  let updateData = {};
  updateData[marketLower] = listPriceInfo;
  console.log('List Price - updateData:', updateData); // Debug

  // 2 - Set data within db. If data already exist it will merge with existing data.
  // If doc not exist within collection, It will create doc and set data in it.
  await db.collection('product_reviews').doc(asin).set(updateData, {merge: true});
}
//-------------------------------------------------------------------
*/


//-------------------------------------------------------------------
// Call api to scrap given url
//-------------------------------------------------------------------
// @asin String    e.g. 'B002OWETK4'
// @market String  e.g. 'amazon.com'
export const scrapUrl = async (asin, market, successCallback, errorCallback) => {
  console.log('scrapUrl() asin:', asin, ' market:', market);

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare product scrap url 
  const marketPlaceUrl = marketPlaces[market].site_url;  // e.g. https://amazon.com
  const url = marketPlaceUrl + '/dp/' + asin;  // e.g. https://amazon.com/dp/B002OWETK4
  console.log('url:', url);

  // 3 - Prepare post data
  const postBody = {
    url: url,         // e.g. https://www.amazon.com/dp/B002OWETK4
    asin: asin,       // e.g. B002OWETK4
    market: market,   // e.g. amazon.com
  }

  // 4 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/scrapurl';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('scrapUrl() - jsonBody:', jsonBody);
    
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('scrapUrl() error:', error);
    
    errorCallback(error);
  });

}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// get999QtyForASIN()
//-------------------------------------------------------------------
// @market String  e.g. 'US'
// @asin String    e.g. 'B002OWETK4'
export const get999QtyForASIN = async (asin, market, successCallback, errorCallback) => {
  console.log('get999QtyForASIN() asin:', asin, ' market:', market);

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    asin: asin,       // e.g. B002OWETK4
    market: market,   // e.g. 'US'
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/get999QtyForASIN';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('scrapUrl() - jsonBody:', jsonBody);

    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('scrapUrl() error:', error);
    
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Save Seller Configuration info for current user
//-------------------------------------------------------------------
// This function will call server side api to save the seller configuraiton info 
// for current logged in user.
export const saveSellerConfig = async (sellerId, authToken, successCallback, errorCallback) => {
  console.log('saveSellerConfig()');
  //console.log('saveSellerConfig() sellerId:', sellerId, ' authToken:', authToken);

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  const uid = firebase.auth().currentUser.uid;
  //console.log('idToken:', idToken);
  //console.log('uid:', uid);

  // 2 - Prepare post data
  const postBody = {
    sellerId: sellerId,
    authToken: authToken,
    uid: uid,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/saveSellerConfig';
  //console.log('apiUrl:', apiUrl);
  
  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('saveSellerConfig() - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('saveSellerConfig() error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// fetchAmazonAccessToken()
//-------------------------------------------------------------------
// @regionId String (region id for which auth code granted) e.g. NA, EU, FE
// NA - North America, EU - Europe, FE - Far East
export const fetchAmazonAccessToken = async (regionId, code, successCallback, errorCallback) => {
  //console.log('fetchAmazonAccessToken()');
  console.log('fetchAmazonAccessToken() regionId', regionId, 'code:', code);

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    regionId: regionId, 
    code: code,
  }
  console.log('postBody:', postBody);

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }
  
  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/fetchAmazonAccessToken';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('fetchAmazonAccessToken() - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('fetchAmazonAccessToken() error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------
// Amazon Advertising Api: Sponsored Products - Create new campaign
// POST: /v2/sp/campaigns
// 
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import helper functions, constant etc.
import { getProfiles, spCreateCampaigns } from '../../helpers/amazonAdHelper';


class SponsoredProductsCreateCampaigns  extends React.Component {
  
  state = {

    // Start - Form input data
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/createCampaigns
    // number: profile id under which new campaign will be created
    profileId: "",
    
    // number: The identifier of an existing portfolio to which the campaign is associated.
    portfolioId: "",

    // string: A name for the campaign.
    name: "",

    // string: The advertising product managed by this campaign. ['sponsoredProducts']
    campaignType: 'sponsoredProducts', 

    // string: The type of targeting for the campaign. Choose from ['manual', 'auto']
    targetingType: "", 

    // string: The current resource state. Choose from ['enabled', 'paused', 'archived']
    state: "",
    
    // number(float): A daily budget for the campaign.
    dailyBudget: '',

    // string: A starting date for the campaign to go live. 
    // The format of the date is YYYYMMDD for passing to api.
    // Date input control expect format in YYYY-MM-DD
    startDate: "",  // e.g. YYYY-MM-DD

    // string: An ending date for the campaign to stop running. 
    // The format of the date is YYYYMMDD for passing to api
    // Date input control expect format in YYYY-MM-DD
    endDate: "2020-10-21",  // e.g. YYYY-MM-DD

    // boolean: If set to true, Amazon increases the default bid for ads 
    // that are eligible to appear in this placement. 
    premiumBidAdjustment: "false",
    
    // string: The bidding strategy. Choose from [ 'legacyForSales', 'autoForSales', 'manual' ] 
    biddingStrategy: "legacyForSales",

    // Array of bidding adjustment information
    biddingAdjustments: [
      {
        predicate: "placementTop",
        percentage: 0
      }
    ],
    // End - Form input data


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],
    
    // Once new campaign created we will store result here
    // campaignCreatedResults: [
    //   {
    //     "campaignId": 0,
    //     "code": "string",
    //     "description": "string"
    //   }
    // ],
    campaignCreatedResults: null,

    // If error occur during any operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SponsoredProductsCreateCampaigns - componentDidMount()');

    // 1 - Set default value for campaign start, end date
    const startDate = this.getFutureDate_YYYYMMDD(1); // current date + 1 day
    const endDate = this.getFutureDate_YYYYMMDD(7);   // current date + 7 day
    this.setState({
      startDate: startDate,
      endDate: endDate, 
    });

    // 1 - Fetch amazon profile list to fillup dropdown
    // Commented: because we passed profileList as props from parent component
    //this.fetchProfileList();
  }

  // Return current date in YYYY-MM-DD format e.g. 2020-10-14 
  getCurrentDate_YYYYMMDD = () => {
    const date = new Date();
    const yyyy = date.getFullYear();    // year e.g. 2018
    const mm = date.getMonth() + 1;     // Month number (0-11)
    var dd = date.getDate();            // Date e.g. 23 (1 to 31)
    if ( dd < 10 ) { dd = '0' + dd; };  // add zero at left 0
    const dateString = yyyy + '-' + mm + '-' + dd;
    return dateString;
  }

  // Return future date (current + days) in YYYY-MM-DD format 
  // e.g. 2020-10-21
  getFutureDate_YYYYMMDD = (days) => {
    var date = new Date();
    date = new Date(date.getTime() + days*24*60*60*1000);
    const yyyy = date.getFullYear();  // year e.g. 2018
    const mm = date.getMonth() + 1;   // Month number (0-11)
    var dd = date.getDate();          // Date e.g. 23 (1 to 31)
    if (dd < 10 ) { dd = '0' + dd };  // add zero at left 0
    const dateString = yyyy + '-' + mm + '-' + dd;
    return dateString;
  }


  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass the profile Id to get campain list api, so we 
  // first will fetch profile list and fillup drop down, so user 
  // can choose profile from there and click Get Camapaign List button
  // to fetch campain list for the selected profile.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }    
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------

  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',
      isProcessing: false,
      
      campaignCreatedResults: null,
      profileId: '',
      name: '',
      targetingType: '',
      dailyBudget: '',
      portfolioId: '',
      state: '',
      startDate: '',
      endDate: '',
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Create new campaign
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    console.log('onClickSubmit()');

    // Reset error message
    this.setState({ error: ''});

    // 1 - Get profileId from the state
    const { profileId, portfolioId, name, campaignType, targetingType, state, dailyBudget, 
      startDate, endDate, biddingStrategy, biddingAdjustments 
    } = this.state;
    var premiumBidAdjustment = this.state.premiumBidAdjustment;
    if (premiumBidAdjustment === 'true') {  // Convert to boolean
      premiumBidAdjustment = true;
    } else { 
      premiumBidAdjustment = false; 
    }

    // 2 - If mandatory info not selected then return
    if (!profileId || profileId === '') { 
      console.log('profileId not selected, so return');
      this.setState({ error: 'profileId not selected, so return'});
      return;
    }
    if (!name || name === '') { 
      console.log('Campaign name empty, so return');
      this.setState({ error: 'Enter Campaign name'});
      return;
    }
    if (!campaignType || campaignType === '') { // e.g. campaignType = 'sponsoredProducts'
      console.log('campaignType empty, so return');
      this.setState({ error: 'campaignType empty, so return'});
      return;
    }
    if (!targetingType || targetingType === '') { 
      console.log('targetingType empty, so return');
      this.setState({ error: 'Select Targeting Type'});
      return;
    }
    if (!startDate || startDate === '') { 
      console.log('startDate empty, so return');
      this.setState({ error: 'Choose Start Date'});
      return;
    }
    if (!endDate || endDate === '') { 
      console.log('endDate empty, so return');
      this.setState({ error: 'Choose End Date'});
      return;
    }
    if ( !dailyBudget || dailyBudget === 0 || dailyBudget === '' ) { 
      console.log('dailyBudget empty or 0, so return');
      this.setState({ error: 'Set dailyBudget, so return'});
      return;
    }
    if ( !state || state === '' ) { 
      console.log('campaign state empty, so return');
      this.setState({ error: 'Select Campaign Status'});
      return;
    }
    // if ( !portfolioId || portfolioId === '' ) { 
    //   console.log('portfolioId empty, so return');
    //   this.setState({ error: 'portfolioId empty, so return'});
    //   return;
    // }

    // 3 - Prepare data to create campaign
    // We have to pass campaign create data as array although we are creating one campaign.
    // Note: We can pass either premiumBidAdjustment = false or bidding ={} but not both.
    // e.g. campaignsData = [
    //   {
    //     "portfolioId": 0,
    //     "name": "string",
    //     "campaignType": "sponsoredProducts",
    //     "targetingType": "manual",
    //     "state": "enabled",
    //     "dailyBudget": 0,
    //     "startDate": "string",
    //     "endDate": "string",
    //     "premiumBidAdjustment": true,
    //     "bidding": {
    //       "strategy": "legacyForSales",
    //       "adjustments": [
    //         {
    //           "predicate": "placementTop",
    //           "percentage": 0
    //         }
    //       ]
    //     }
    //   }
    // ]    
    // Convert date in to YYYYMMDD format (server side api expect date as YYYYMMDD string)
    const startDateFinal = startDate.replace(/-/g, ""); // e.g. 20201021
    const endDateFinal = endDate.replace(/-/g, "");     // e.g. 20201028
    var campaignData1 = {
      name: name,
      campaignType: campaignType,
      targetingType: targetingType, 
      state: state, 
      dailyBudget: parseFloat(dailyBudget),
      startDate: startDateFinal,
      endDate: endDateFinal, 
    }
    // Note: If portfolioId not empty then set key:value for that (Do not pass empty value).
    if ( portfolioId !== '' ) {
      campaignData1['portfolioId'] = parseFloat(portfolioId);
    }
    // Note: We can pass either premiumBidAdjustment = false or bidding={} but not both.
    // So if premiumBidAdjustment is true then set bidding key:value, otherwise set 
    // premiumBidAdjustment key:value
    if ( premiumBidAdjustment === true ) { // Set bidding strategy value
      campaignData1['bidding'] = {
        strategy: biddingStrategy,
        adjustments: biddingAdjustments
      }
    } else { // set premiumBidAdjustment as false
      campaignData1['premiumBidAdjustment'] = premiumBidAdjustment; // false
    }
    
    // Create array (because server side api expect array although we have to create one campaign)
    const campaignArray = [ campaignData1 ]
    console.log('campaignArray:', campaignArray);

    // 4 - Show processing
    this.setState({
      isProcessing: true,
    });
    
    // 5 - Call api to create new campaign
    spCreateCampaigns(profileId, campaignArray, this.spCreateCampaigns_Success, this.spCreateCampaigns_Error);
  }

  // Called when campaign created successfully
  spCreateCampaigns_Success = (result) => {
    console.log('spCreateCampaigns_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        campaignCreatedResults: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while creating a campaign
  spCreateCampaigns_Error = (error) => {
    console.log('spCreateCampaigns_Error() error:', error);
    
    this.setState({
      error: error,
      isProcessing: false,
    });
  }
  
  //-----------------------------------------------------------------
  // End: Create new campaign
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="demo-simple-select-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="Profile"
              value={profileId}
              onChange={(e) => this.setState({ profileId: e.target.value })}
              disabled={isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const {   type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              Campaign will be created under selected profile
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={8} sm={8}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="name" 
              label="Campaign name" 
              placeholder="e.g. ProductName_Manual" 
              value={this.state.name} 
              onChange={(e) => this.setState({ name: e.target.value })} 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4}>
          <FormControl className={classes.formControl} >
            <InputLabel id="targeting-type-label">Targeting Type</InputLabel>
            <Select
              id="targetingType"
              labelId="targeting-type-label"
              value={this.state.targetingType}
              onChange={(e) => this.setState({ targetingType: e.target.value })}
              disabled={isProcessing}
            >
              <MenuItem value="manual" >Manual</MenuItem>
              <MenuItem value="auto" >Auto</MenuItem>
            </Select>
          </FormControl>  
        </Grid>

        <Grid item xs={6} sm={6}>
          <form className={classes.formControl} >
            <TextField
              id="startDate"
              label="Campaign Start Date"
              type="date"
              //defaultValue="2017-05-24"
              value={this.state.startDate}
              onChange={(e) => this.setState({ startDate: e.target.value })}
              className={classes.textField}
              InputLabelProps={{ shrink: true, }}
            />
          </form>
        </Grid>

        <Grid item xs={6} sm={6}>
          <form className={classes.formControl} >
            <TextField
              id="endDate"
              label="Campaign End Date"
              type="date"
              //defaultValue="2017-05-24"
              value={this.state.endDate}
              onChange={(e) => this.setState({ endDate: e.target.value })}
              className={classes.textField}
              InputLabelProps={{ shrink: true, }}
            />
          </form>
        </Grid>

        <Grid item xs={6} sm={6}>        
          <FormControl className={classes.formControl} >
            <TextField 
              id="dailyBudget" 
              label="Daily Budget ($)" 
              placeholder="0.0" 
              value={this.state.dailyBudget} 
              onChange={(e) => this.setState({ dailyBudget: e.target.value })} 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <InputLabel id="state-label">Campaign Status</InputLabel>
            <Select
              id="state"
              labelId="state-label"
              value={this.state.state}
              onChange={(e) => this.setState({ state: e.target.value })}
              disabled={isProcessing}
            >
              <MenuItem value="enabled" >enabled</MenuItem>
              <MenuItem value="paused" >paused</MenuItem>
              <MenuItem value="archived" >archived</MenuItem>
            </Select>
          </FormControl>  
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="premium-bid-djustment-label">Premium Bid Adjustment</InputLabel>
            <Select
              id="premiumBidAdjustment"
              labelId="premium-bid-djustment-label"
              value={this.state.premiumBidAdjustment}
              onChange={(e) => this.setState({ premiumBidAdjustment: e.target.value })}
              disabled={isProcessing}
            >
              <MenuItem value="true" >true</MenuItem>
              <MenuItem value="false" >false</MenuItem>
            </Select>
            <FormHelperText>
              If <b>true</b>, Amazon increases default bid for ads that are eligible to appear in this placement.
            </FormHelperText>
          </FormControl>
        </Grid>
        
        { this.state.premiumBidAdjustment === "true" &&
        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <InputLabel id="bidding-strategy-label">Bidding Strategy</InputLabel>
            <Select
              id="biddingStrategy"
              labelId="bidding-strategy-label"
              value={this.state.biddingStrategy}
              onChange={(e) => this.setState({ biddingStrategy: e.target.value })}
              disabled={isProcessing}
            >
              <MenuItem value="legacyForSales" >legacyForSales</MenuItem>
              <MenuItem value="autoForSales" >autoForSales</MenuItem>
              <MenuItem value="manual" >manual</MenuItem>              
            </Select>
            <FormHelperText>
              <b>legacyForSales:</b> Dynamic bids down only, 
              <b>autoForSales:</b> Dynamic bids - up and down, 
              <b>manual:</b> Fixed bid
            </FormHelperText>
          </FormControl>
        </Grid>
        }
        
        { this.state.premiumBidAdjustment === "true" &&
        <Grid item xs={6} sm={6}>
          <FormHelperText>
            <b>Bidding Adjustments:</b> <br />
            { '[{"predicate": "placementTop", "percentage": 0 }]' }
          </FormHelperText>
        </Grid>
        }

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="portfolioId" 
              label="Portfolio Id / Entity ID (number)" 
              placeholder="keep empty if dont know." 
              helperText="The identifier of an existing portfolio to which campaign is associated."
              value={this.state.portfolioId} 
              onChange={(e) => this.setState({ portfolioId: e.target.value })} 
              size="small" 
              disabled={isProcessing} 
              InputLabelProps={{ shrink: true, }} 
            />
          </FormControl>
        </Grid>

      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' }
        >Create Campaign</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }
  
  // Render result received once new campaign created.
  // It will result as an array of created campaigns.
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { campaignCreatedResults } = this.state;

    // campaignCreatedResults not exist then do not show anything
    if ( !campaignCreatedResults ) { return null; }

    // If campaign created result exist then render it
    // e.g. campaignCreatedResults = [
    //   {
    //     "campaignId": 0,
    //     "code": "string",
    //     "description": "string"
    //   }
    // ]
    return (
      <div align='left'>
        <b>Campaign Created Result:</b> <br />
        <hr />
        { 
          campaignCreatedResults.map( (item, index) => {
            return (
              <div key={ 'campaign_result_' + index } >
                <b>#{ index + 1}</b> <br />
                <b>campaignId:</b> { item.campaignId } <br />
                <b>code:</b> { item.code } <br />
                <b>description:</b> { item.description } <br />
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Create Campaign</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SponsoredProductsCreateCampaigns.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SponsoredProductsCreateCampaigns);


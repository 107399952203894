// Custom settings for potential product calculation

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slider from '@material-ui/core/Slider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';


// Import helper functions
import { getToolSettings, getToolSettingsForUser, 
          updateToolSettingsForUser, deleteToolSettingsForUser } from '../../helpers/firebaseHelper';

import { restrictedCategoriesDefault } from './restricted-category';

// Create default restricted category keys array 
// e.g. restrictedCategoryKeys = [1, 2, 3, 4, 5]
const restrictedCategoryKeys = Object.keys(restrictedCategoriesDefault);


class CustomSettings extends React.Component { 

  state = {
    // User specific settings
    customSettings: null,
    customSettingsFetched: false,

    // Default settings
    defaultSettings: null, 
    defaultSettingsFetched: false, 

    // Form input fields current value.
    bsrMin: null,
    bsrMax: null,
    reviewCountMin: null,
    reviewCountMax: null,
    sellerCountMin: null,
    sellerCountMax: null,
    sellingPriceMin: null,
    sellingPriceMax: null,
    weightMin: null,
    weightMax: null,
    weightUnit: null,
    approvedCategory: {},   // Approved category list e.g. { 1: 'category1', 2: 'category2' }
    formDataAssigned: false,

    // Message text
    showMessage: false,
    messageText: "",
  }

  componentDidMount = () => {
    console.log('CustomSettings - componentDidMount()');

    // Load user specific settings from db for potential_product
    getToolSettingsForUser('potential_product', this.getToolSettingsForUser_Success, this.getToolSettingsForUser_Error);
  }

  componentWillUnmount = () => {
    console.log('CustomSettings - componentWillUnmount()');
  }

  //-----------------------------------------------------------------
  // Start: Fetch settings for current user
  //-----------------------------------------------------------------
  // Tool settings fetched success (for current user)
  getToolSettingsForUser_Success = (data) => {
    //console.log('getToolSettingsForUser_Success() data:', data);
    
    // 1 - Set received data to state
    this.setState({
      customSettings: data,
      customSettingsFetched: true,
    });

    // 2 - Load default settings from db for potential_product
    getToolSettings('potential_product', this.getToolSettings_Success, this.getToolSettings_Error);
  }

  // Tool settings fetch error (for current user)
  getToolSettingsForUser_Error = (error) => {
    console.log('getToolSettingsForUser_Error() error:', error);

    // Show error message

    // Load default settings from db for potential_product
    getToolSettings('potential_product', this.getToolSettings_Success, this.getToolSettings_Error);
  }
  //-----------------------------------------------------------------
  // End: Fetch settings for current user
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Fetch default settings
  //-----------------------------------------------------------------
  // Tool default settings fetch success
  getToolSettings_Success = (data) => {
    //console.log('getToolSettings_Success() data:', data);
    
    this.setState({
      defaultSettings: data,
      defaultSettingsFetched: true,
    });

    // Assign tool settings data to form variable
    this.assignSettingsDataToForm();
  }
  
  // Tool default settings fetch error
  getToolSettings_Error = (error) => {
    console.log('getToolSettings_Error() error:', error);
    // Show error message
  }
  //-----------------------------------------------------------------
  // End: Fetch default settings
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Assign tool settings data to form variable  
  //-----------------------------------------------------------------  
  assignSettingsDataToForm = () => {
    //console.log('assignSettingsDataToForm()');

    // 1 - Fetch custom and default settings from state.
    const { defaultSettings, customSettings } = this.state;

    // 2 - Assign default settings to form variable 
    let bsrMin = defaultSettings.bsr.min;
    let bsrMax = defaultSettings.bsr.max;
    let reviewCountMin = defaultSettings.review_count.min;
    let reviewCountMax = defaultSettings.review_count.max;
    let sellerCountMin = defaultSettings.seller_count.min;
    let sellerCountMax = defaultSettings.seller_count.max;
    let sellingPriceMin = defaultSettings.selling_price.min;
    let sellingPriceMax = defaultSettings.selling_price.max;
    let weightMin = defaultSettings.weight.min;
    let weightMax = defaultSettings.weight.max;
    let weightUnit = defaultSettings.weight.unit;
    let approvedCategory = {};

    // 3 - Override default settings with custom settings if custom settings data exist.
    if (customSettings) {
      if (customSettings.bsr) {
        if (customSettings.bsr.min) { bsrMin = customSettings.bsr.min; }
        if (customSettings.bsr.max) { bsrMax = customSettings.bsr.max; }
      }
      if (customSettings.review_count) {
        if (customSettings.review_count.min) { reviewCountMin = customSettings.review_count.min; }
        if (customSettings.review_count.max) { reviewCountMax = customSettings.review_count.max; }
      }
      if (customSettings.seller_count) {
        if (customSettings.seller_count.min) { sellerCountMin = customSettings.seller_count.min; }
        if (customSettings.seller_count.max) { sellerCountMax = customSettings.seller_count.max; }
      }
      if (customSettings.selling_price) {
        if (customSettings.selling_price.min) { sellingPriceMin = customSettings.selling_price.min; }
        if (customSettings.selling_price.max) { sellingPriceMax = customSettings.selling_price.max; }
      }
      if (customSettings.weight) {
        if (customSettings.weight.min) { weightMin = customSettings.weight.min; }
        if (customSettings.weight.max) { weightMax = customSettings.weight.max; }
        if (customSettings.weight.unit) { weightUnit = customSettings.weight.unit; }
      }
      if (customSettings.approved_category) {
        approvedCategory = customSettings.approved_category;
      }
    }

    // 4 - Assign value to state, so it will show data within form
    this.setState({
      bsrMin: bsrMin,
      bsrMax: bsrMax,
      reviewCountMin: reviewCountMin,
      reviewCountMax: reviewCountMax,
      sellerCountMin: sellerCountMin,
      sellerCountMax: sellerCountMax,
      sellingPriceMin: sellingPriceMin,
      sellingPriceMax: sellingPriceMax,
      weightMin: weightMin,
      weightMax: weightMax,
      weightUnit: weightUnit,
      approvedCategory: approvedCategory, 
      formDataAssigned: true,
    });

  }
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Save changes related function
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    console.log('onClickSubmit()');

    // 1 - Read form data from state.
    const { 
      bsrMin, bsrMax, reviewCountMin, reviewCountMax, sellerCountMin, sellerCountMax,
      sellingPriceMin, sellingPriceMax, weightMin, weightMax, weightUnit, approvedCategory
    } = this.state;

    // 2 - Decide how many parameter changed 
    // true - default value changes, false - consist default value.
    const bsrChanged = this.isBsrChanged();
    const reviewCountChanged = this.isReviewCountChanged();
    const sellerCountChanged = this.isSellerCountChanged();
    const sellingPriceChanged = this.isSellingPriceChanged();
    const weightChanged = this.isWeightChanged();

    // 3 - Prepare data for update
    // Note: We have save parameter within db if its default value changed by user.
    // e.g. const data = {
    //   bsr: { min: bsrMin, max: bsrMax},
    //   review_count: { min: reviewCountMin, max: reviewCountMax},
    //   seller_count: { min: sellerCountMin, max: sellerCountMax},
    //   selling_price: { min: sellingPriceMin, max: sellingPriceMax},
    //   weight: { min: weightMin, max: weightMax, unit: weightUnit },
    //   approved_category: { 1: 'Category1', 2: 'Category2' }
    // }
    let data = {};
    if (bsrChanged)           { data['bsr'] = { min: bsrMin, max: bsrMax } }
    if (reviewCountChanged)   { data['review_count'] = { min: reviewCountMin, max: reviewCountMax } }
    if (sellerCountChanged)   { data['seller_count'] = { min: sellerCountMin, max: sellerCountMax } }
    if (sellingPriceChanged)  { data['selling_price'] = { min: sellingPriceMin, max: sellingPriceMax } }
    if (weightChanged)        { data['weight'] = { min: weightMin, max: weightMax, unit: weightUnit } }
    if (Object.keys(approvedCategory).length > 0 ) {
      data['approved_category'] = approvedCategory ; // If any category added as approved then save to db
    }
    if (Object.keys(data).length === 0) { data = null }; // If any settings default value not changed, then set null
    console.log('Changed data', data);

    // 3 - Set processing on
    this.setState({
      isProcessing: true,
    })

    // 4 - Call api to update settings for current user 
    updateToolSettingsForUser('potential_product', data, this.updateToolSettingsForUser_Success, this.updateToolSettingsForUser_Error);
  }
  
  updateToolSettingsForUser_Success = () => {
    console.log('updateToolSettingsForUser_Success()');
    
    this.setState({
      showMessage: true,
      messageText: 'Settings Saved Successfully',
      isProcessing: false,
    });
  }

  updateToolSettingsForUser_Error = (error) => {
    this.setState({
      isProcessing: false,
      showMessage: true,
      messageText: 'Error while save, Please try again.',
    });  
  }

  //-----------------------------------------------------------------
  // End: Save changes related function
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Set default value
  //-----------------------------------------------------------------
  onClickSetDefault = () => {
    console.log('onClickSetDefault()');

    // 1 - Set processing on
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to delete settings for current user.
    // We will delete custom settings saved under current user, so whenever user will 
    // use potential product tool, it will grab default settings.
    deleteToolSettingsForUser('potential_product', this.deleteToolSettingsForUser_Success, this.deleteToolSettingsForUser_Error);
  }

  // Settings deleted for user successfully.
  deleteToolSettingsForUser_Success = () => {
    console.log('deleteToolSettingsForUser_Success()');

    // Show message for reset success
    this.setState({
      isProcessing: false,
      showMessage: true,
      messageText: 'Default Value Set Successfully',
    });

    // Refresh data so user can see default settings.
    this.refreshData();
  }
  
  // Error while reset to default value.
  deleteToolSettingsForUser_Error = (error) => {
    console.log('deleteToolSettingsForUser_Error() error:', error);

    this.setState({
      isProcessing: false,
      showMessage: true,
      messageText: 'Error while reset, Please try again.',
    });
  }

  // Refresh data
  refreshData = () => {
    // Load user specific settings from db for potential_product
    getToolSettingsForUser('potential_product', this.getToolSettingsForUser_Success, this.getToolSettingsForUser_Error);
  }
  //-----------------------------------------------------------------
  // Start: Set default value for settings
  //-----------------------------------------------------------------
  

  //-----------------------------------------------------------------
  // Start: Form render related functios
  //-----------------------------------------------------------------
  // Called when bsr slider changed
  handleChangeBsr = (event, newValue) => {
    //console.log('handleChangeBsr() newValue:', newValue);
    this.setState({ 
      bsrMin: newValue[0],
      bsrMax: newValue[1],
    });
  };

  // Called when review count slider changed
  handleChangeReviewCount = (event, newValue) => {
    //console.log('handleChangeReviewCount() newValue:', newValue);
    this.setState({ 
      reviewCountMin: newValue[0],
      reviewCountMax: newValue[1],
    });
  };

  // Called when seller count slider changed
  handleChangeSellerCount = (event, newValue) => {
    //console.log('handleChangeSellerCount() newValue:', newValue);
    this.setState({ 
      sellerCountMin: newValue[0],
      sellerCountMax: newValue[1],
    });
  };

  // Called when selling price slider changed
  handleChangeSellingPrice = (event, newValue) => {
    //console.log('handleChangeSellingPrice() newValue:', newValue);
    this.setState({ 
      sellingPriceMin: newValue[0], 
      sellingPriceMax: newValue[1],
    });
  };

  // Called when weight slider changed
  handleChangeWeight = (event, newValue) => {
    //console.log('handleChangeWeight() newValue:', newValue);
    this.setState({ 
      weightMin: newValue[0], 
      weightMax: newValue[1],
    });
  };

  // If bsr default value changed by user then return true, otherwise false.
  isBsrChanged = () => {
    const { bsrMin, bsrMax, defaultSettings} = this.state;
    return bsrMin !== defaultSettings.bsr.min || bsrMax !== defaultSettings.bsr.max;
  }

  // If review count default value changed by user then return true, otherwise false.  
  isReviewCountChanged = () => {
    const { reviewCountMin, reviewCountMax, defaultSettings} = this.state;
    return reviewCountMin !== defaultSettings.review_count.min || reviewCountMax !== defaultSettings.review_count.max;
  }

  // If seller count default value changed by user then return true, otherwise false.  
  isSellerCountChanged = () => {
    const { sellerCountMin, sellerCountMax, defaultSettings} = this.state;
    return sellerCountMin !== defaultSettings.seller_count.min || sellerCountMax !== defaultSettings.seller_count.max;
  }

  // If Selling price value changed by user then return true, otherwise false.  
  isSellingPriceChanged = () => {
    const { sellingPriceMin, sellingPriceMax, defaultSettings} = this.state;
    return sellingPriceMin !== defaultSettings.selling_price.min || sellingPriceMax !== defaultSettings.selling_price.max;
  }
  
  // If weight value changed by user then return true, otherwise false.  
  isWeightChanged = () => {
    const { weightMin, weightMax, defaultSettings } = this.state;
    return weightMin !== defaultSettings.weight.min || weightMax !== defaultSettings.weight.max;
  }

  // This function called when user select / deselect category from list.
  // @key Number categoryId e.g. 1
  // @title String e.g. 'Category1'
  // @isSelected Bool i.e. clicked category selected at present or not.
  toggleCategory = (key, title, isSelected) => {
    //console.log('key:', key, 'title:', title, 'isSelected:', isSelected);
    
    const approvedCategory = { ...this.state.approvedCategory };

    if (isSelected) { // Remove clicked category from approved list
      
      let categoryUpdated = {};
      Object.keys(approvedCategory).forEach( (key1, index) => {
        if (key1 !== key) {
          categoryUpdated[key1] = approvedCategory[key1];
        }
      })

      // Set updated category to state
      this.setState({
        approvedCategory: { ...categoryUpdated },
      });
      //console.log('category After Remove:', categoryUpdated);

    } else { // Add clicked category to approved category list
      approvedCategory[key] = title;
      this.setState({
        approvedCategory: approvedCategory
      });
      //console.log('category After Add:', approvedCategory);
    }

  }


  // Render form within ui
  renderForm = () => {
    
    const { classes } = this.props;

    const { defaultSettings, isProcessing } = this.state;
    const { bsrMin, bsrMax, reviewCountMin, reviewCountMax, sellerCountMin, sellerCountMax,
      sellingPriceMin, sellingPriceMax, weightMin, weightMax, weightUnit, approvedCategory 
    } = this.state;
    
    const bsrValue = [bsrMin,bsrMax];
    const reviewCountValue = [reviewCountMin, reviewCountMax];
    const sellerCountValue = [sellerCountMin, sellerCountMax];
    const sellingPriceValue = [sellingPriceMin, sellingPriceMax];
    const weightValue = [weightMin, weightMax];

    // Decide that how many parameter changed
    const bsrChanged = this.isBsrChanged();
    const reviewCountChanged = this.isReviewCountChanged();
    const sellerCountChanged = this.isSellerCountChanged();
    const sellingPriceChanged = this.isSellingPriceChanged();
    const weightChanged = this.isWeightChanged();
    const anyValueChanged = (bsrChanged || reviewCountChanged || sellerCountChanged || sellingPriceChanged || weightChanged ) ? true : false;

    return (
      <form noValidate autoComplete="off">

        <Typography variant="h6">Set Custom Range For Each Parameter.</Typography>
        If custom value set then it will be used to calculate potential product for you.
        
        <div style={{height: 50}} ></div>

        <Grid container spacing={1} alignItems="center">
          <Grid item align='right' style={{width: 130}} >
            <Typography variant="body1">Best Seller Rank</Typography>
            <Typography variant="caption">
              Default: {defaultSettings.bsr.min} - {defaultSettings.bsr.max}
            </Typography>
          </Grid>
          <Grid item xs style={{marginLeft: 10}} >
            <Slider
              value={bsrValue}
              step={50}
              min={0}
              max={10000}
              onChange={this.handleChangeBsr}
              valueLabelDisplay="on"
            />
          </Grid>
          <Grid item align="center" >
            { bsrChanged ? <Badge color="primary" variant="dot" >&nbsp;</Badge> : <>&nbsp;</> }
          </Grid>
        </Grid>

        <div style={{height: 50}} ></div>

        <Grid container spacing={1} alignItems="center">
          <Grid item align='right' style={{width: 130}} >
            <Typography variant="body1">Review Count</Typography>
            <Typography variant="caption">
              Default: {defaultSettings.review_count.min} - {defaultSettings.review_count.max}
            </Typography>
          </Grid>
          <Grid item xs style={{marginLeft: 10}} >
            <Slider
              value={reviewCountValue}
              step={50}
              min={0}
              max={5000}
              onChange={this.handleChangeReviewCount}
              valueLabelDisplay="on"
            />
          </Grid>
          <Grid item align="center" >
            { reviewCountChanged ? <Badge color="primary" variant="dot" >&nbsp;</Badge> : <>&nbsp;</> }
          </Grid>
        </Grid>

        <div style={{height: 50}} ></div>

        <Grid container spacing={1} alignItems="center">
          <Grid item align='right' style={{width: 130}} >
            <Typography variant="body1">Seller Count</Typography>
            <Typography variant="caption">
              Default: {defaultSettings.seller_count.min} - {defaultSettings.seller_count.max}
            </Typography>
          </Grid>
          <Grid item xs style={{marginLeft: 10}} >
            <Slider
              value={sellerCountValue}
              step={1}
              min={0}
              max={50}
              onChange={this.handleChangeSellerCount}
              valueLabelDisplay="on"
            />
          </Grid>
          <Grid item align="center" >
            { sellerCountChanged ? <Badge color="primary" variant="dot" >&nbsp;</Badge> : <>&nbsp;</> }
          </Grid>
        </Grid>

        <div style={{height: 50}} ></div>

        <Grid container spacing={1} alignItems="center">
          <Grid item align='right' style={{width: 130}} >
            <Typography variant="body1">Selling Price</Typography>
            <Typography variant="caption">
              Default: {defaultSettings.selling_price.min} - {defaultSettings.selling_price.max}
            </Typography>
          </Grid>
          <Grid item xs style={{marginLeft: 10}} >
            <Slider
              value={sellingPriceValue}
              step={1}
              min={0}
              max={500}
              onChange={this.handleChangeSellingPrice}
              valueLabelDisplay="on"
            />
          </Grid>
          <Grid item align="center" >
            { sellingPriceChanged ? <Badge color="primary" variant="dot" >&nbsp;</Badge> : <>&nbsp;</> }
          </Grid>
        </Grid>

        <div style={{height: 50}} ></div>

        <Grid container spacing={1} alignItems="center">
          <Grid item align='right' style={{width: 130}} >
            <Typography variant="body1">Weight</Typography>
            <Typography variant="caption">
              Default: {defaultSettings.weight.min} - {defaultSettings.weight.max} {weightUnit}
            </Typography>
          </Grid>
          <Grid item xs style={{marginLeft: 10}} >
            <Slider
              value={weightValue}
              step={0.5}
              min={0}
              max={20}
              onChange={this.handleChangeWeight}
              valueLabelDisplay="on"
            />
          </Grid>
          <Grid item align="center" >
            { weightChanged ? <Badge color="primary" variant="dot" >&nbsp;</Badge> : <>&nbsp;</> }
          </Grid>
        </Grid>

        <div style={{height: 30}} ></div>

        <Grid container spacing={1} alignItems="center">
          <Grid item align='left' >
            <Typography variant="body1">Restricted Categories:</Typography>
            <Typography variant="caption">
              Please select category you have to consider as unrestricted.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="center">
          <Grid item align='left'>
            <div className={classes.categoryList}>
              { 
                restrictedCategoryKeys.map( (key, index) => {
                  const title = restrictedCategoriesDefault[key];
                  let isSelected = false;
                  let color = "default";
                  if (approvedCategory[key]) {
                    color = "primary";
                    isSelected = true;
                  }
                  return (
                    <Chip
                      key={'category_key_' + index}
                      label={title}
                      clickable
                      color={color}
                      onClick={ () => this.toggleCategory(key, title, isSelected)}
                    />
                  )
                })
              }
            </div>
          </Grid>
        </Grid>

        <div style={{height: 40}} ></div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} align="right" >
            { anyValueChanged &&
              <>
              <Badge color="primary" variant="dot" ></Badge> &nbsp; Indicates custom value.
              </>
            }
            <Button 
              color="default"
              variant="contained" 
              size="medium"
              onClick={this.onClickSetDefault} 
              style={{marginRight: 20, marginLeft: 30 }} 
              disabled={isProcessing || !anyValueChanged}
            >Set Default</Button>
            <Button 
              color="primary"
              variant="contained" 
              size="medium"
              onClick={this.onClickSubmit} 
              //style={{marginRight: 20 }} 
              disabled={isProcessing}
            >Save Settings</Button>
          </Grid>
        </Grid>

      </form>
    );
  }
  //-----------------------------------------------------------------
  // End: Form render related functios
  //-----------------------------------------------------------------


  // Called when message close after time out
  handleMessageClose = () => {
    //console.log('handleMessageClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }  


  render() {    
    const { formDataAssigned, isProcessing } = this.state;
    
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return(
      <>
        <Paper elevation={2} style={{padding: 20}} >
          { formDataAssigned && this.renderForm() }
        </Paper >
        { isProcessing && 
          <LinearProgress />
        }
        <div style={{height: 60}} ></div>
        
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleMessageClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </>
    );
  }
}

const styles = (theme) => ({

  categoryList: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },

});


//export default App;
export default withStyles(styles)(CustomSettings);


// Form to take input for asin and search query.
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';


// Import helper functions, constant etc.
import { getSellerConfigStatus } from '../../helpers/firebaseHelper';
import { saveSellerConfig } from '../../helpers/apiHelper';
//import { getMediumDate } from '../../helpers/utility';


class SellerConfigForm extends React.Component {
  
  state = {
    // Form input
    sellerId: '',       // e.g. AXXT0BXXXXX
    mwsAuthToken: '',  // e.g. amzn.mws.xxxxxxxxxxxx

    // true - it will show form within ui, false - no show form
    showForm: false,

    // We will fetch data from db and set seller config status here.
    sellerConfigDone: '', // '' - Not decided, true - Seller configured, false - Not Configured
    sellerConfigDate: '', // Last date when seller configuration done.

    // Message text
    showMessage: false,
    messageText: "",

    isProcessing: false,
  }

  componentDidMount = () => {
    //console.log('SellerConfigForm - componentDidMount()');

    // Get seller config status i.e. seller configured or not.
    getSellerConfigStatus(this.getSellerConfigStatus_Success, this.getSellerConfigStatus_Error);
  }

  // Fetched seller config status sucessfully
  getSellerConfigStatus_Success = (info) => {
    //console.log('getSellerConfigStatus_Success() info:', info);

    // If seller configured then hide form, otherwise show form.
    this.setState({
      sellerConfigDone: info.seller_config_done,
      sellerConfigDate: info.seller_config_date,
      showForm: !info.seller_config_done,
      sellerId: '',
      mwsAuthToken: '',
    });
  }

  // Error while fetch seller config status
  getSellerConfigStatus_Error = (error) => {
    console.log('getSellerConfigStatus_Error() error:', error);
  }


  onClickSubmit = () => {
    console.log('SellerConfigForm - onClickSubmit()');

    // 1 - Fetch data from state
    const { sellerId, mwsAuthToken } = this.state;
    if (!sellerId || sellerId === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please enter Amazon Seller Id',
      });
      return;
    }
    if (!mwsAuthToken || mwsAuthToken === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please enter MWS Auth Token',
      });
      return;
    }
    //console.log('sellerId:', sellerId, ' mwsAuthToken:', mwsAuthToken);
 
    // 2 - Set processing on 
    this.setState({
      isProcessing: true,
    }); 

    // 3 - Call Server side api to save seller configuration
    saveSellerConfig(sellerId, mwsAuthToken, this.saveSellerConfig_Success, this.saveSellerConfig_Error);
  }
  
  saveSellerConfig_Success = (result) => {
    console.log('saveSellerConfig_Success() result:', result);

    // If status = success then consider as operation completed
    if (result.status === 'success') {
      
      // Set processing off
      this.setState({
        isProcessing: false,
      });

      // Refresh seller config status
      this.refreshSellerConfigStatus();

    } else if (result.status === 'error') { // Some validation failed, it will return error status
      this.setState({
        showMessage: true,
        messageText: 'Error while save, please try again',
        isProcessing: false,
      });  
    }

  }
  
  // If some error whil calling the api, it will return via error callback
  saveSellerConfig_Error = (error) => {
    console.log('saveSellerConfig_Error() error:', error);
    
    // Show error message
    this.setState({
      showMessage: true,
      messageText: 'Error while save, please try again',
      isProcessing: false,
    });
  }

  // Fetch seller config status again from db, so it will show appropriate message within form
  refreshSellerConfigStatus = () => {
    getSellerConfigStatus(this.getSellerConfigStatus_Success, this.getSellerConfigStatus_Error);
  }

  // Called when message close after time out
  handleClose = () => {
    //console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }  

  // Render form content 
  renderForm = () => { 
    const { isProcessing, sellerConfigDone } = this.state;
    const { classes } = this.props;

    return (
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" component="h1">
              Seller Configuration
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl className={classes.formControl} >
              <TextField 
                id="sellerid" 
                label="Amazon Seller Id" 
                placeholder="e.g. AXXT0BXXXXX" 
                value={this.state.sellerId} 
                onChange={(e) => this.setState({ sellerId: e.target.value })} 
                size="small" 
                disabled={isProcessing} 
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} >
              <TextField 
                id="authtoken" 
                label="Mws Auth Token" 
                placeholder="amzn.mws.xxxxxxxxxxxx" 
                value={this.state.mwsAuthToken} 
                onChange={(e) => this.setState({ mwsAuthToken: e.target.value })} 
                size="small" 
                disabled={isProcessing}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} style={{ textAlign: 'left'}} >
            <Button 
              variant="contained" 
              size="medium"
              onClick={this.onClickSubmit} 
              style={{marginRight: 20 }} 
              disabled={isProcessing}
            >Save</Button>
            { sellerConfigDone === true && 
            <Button 
              variant="contained" 
              size="medium"
              onClick={() => this.setState({showForm: false}) } 
              style={{marginRight: 0 }} 
              disabled={isProcessing}
            >Cancel</Button>
            }
          </Grid>
        </Grid>
      </form>
    )
  }

  
  // If seller info already config then show message
  renderConfigDoneMessage = () => {
    const { sellerConfigDone } = this.state;
    if ( sellerConfigDone === '' || sellerConfigDone === false ) { return null };
    return(
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item>
            <CheckIcon style={{ color: green[500], fontSize: 30 }} />
          </Grid>
          <Grid item style={{ }} >
            <Typography variant="h6" gutterBottom>Seller Configured</Typography>
          </Grid>
          <Grid item style={{ }} >
            <Button 
              variant="contained" 
              size="small"
              onClick={() => this.setState({ showForm: true }) } 
              style={{marginRight: 20 }} 
            >Config</Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }


  render() {
    //const { classes } = this.props;
    const { isProcessing, sellerConfigDone, showForm } = this.state;  

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <React.Fragment>
        <Paper elevation={2} style={{padding: 20}} >
          { sellerConfigDone === '' && <h5>Loading....</h5> }
          
          { showForm === true && this.renderForm() }
          
          { (sellerConfigDone === true && showForm === false) &&
            this.renderConfigDoneMessage() 
          }
        </Paper >

        { isProcessing && <LinearProgress /> }

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


SellerConfigForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SellerConfigForm);

/**
 * @flow
 *
 * This file consist common utility functions.
 */

/**
 * Get a random integer between `min` and `max`.
 * @param {number} min - min number
 * @param {number} max - max number
 * @return {number} a random integer
 */
export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Get a random string based on the time stamp 
 * This function can not be used for uuid purpose.
 * @return {number} a random integer
 */
export const getRandomString = () => {
  const min = 1000;
  const max = 9999;
  const number = Math.floor(Math.random() * (max - min + 1) + min);

  const date = new Date();
  const time = date.getTime();
  return time + '_' + number;
}


/**
 * Return time in string format.
 * @dateToFormat date e.g. Wed May 23 2018 22:36:17 GMT+0530 (IST) (firestore date field)
 * return String '10:10 PM'
 *
 * Help: https://reactnativecode.com/get-current-time-in-12-hours-am-pm-format/
 */
export const getShortTime = (dateToFormat) => {

  // Creating variables to hold time.
  var date, TimeType, hour, minutes, seconds, fullTime;

  // Creating Date() function object.
  date = new Date(dateToFormat);

  // Getting current hour from Date object.
  hour = date.getHours();

  // Checking if the Hour is less than equals to 11 then Set the Time format as AM.
  if(hour <= 11){
    TimeType = 'AM';
  }else{
    // If the Hour is Not less than equals to 11 then Set the Time format as PM.
    TimeType = 'PM';
  }

  // IF current hour is grater than 12 then minus 12 from current hour to make it in 12 Hours Format.
  if( hour > 12 ){
    hour = hour - 12;
  }

  // If hour value is 0 then by default set its value to 12, because 24 means 0 in 24 hours time format.
  if( hour === 0 ){
      hour = 12;
  }

  // Getting the current minutes from date object.
  minutes = date.getMinutes();

  // Checking if the minutes value is less then 10 then add 0 before minutes.
  if(minutes < 10){
    minutes = '0' + minutes.toString();
  }

  //Getting current seconds from date object.
  seconds = date.getSeconds();

  // If seconds value is less than 10 then add 0 before seconds.
  if(seconds < 10){
    seconds = '0' + seconds.toString();
  }

  // Adding all the variables in fullTime variable.
  //fullTime = hour.toString() + ':' + minutes.toString() + ':' + seconds.toString() + ' ' + TimeType.toString();
  fullTime = hour.toString() + ':' + minutes.toString() + ' ' + TimeType.toString();

  return fullTime;
}


/**
 * Return time in string format.
 * @dateToFormat date e.g. 'Wed May 23 2018 22:36:17 GMT+0530 (IST)' (firestore date field value)
 * return String 'May 23 2020'
 *
 * Help: https://appendto.com/2016/02/get-current-date-object-javascript/
 */
//const dayNames =['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthName=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const getShortDate = (dateToFormat) => {

    // Creating Date() object.
    const date = new Date(dateToFormat);

    // Getting current hour from Date object.
    //const dayOfWeek = date.getDay();        // Day Of Week    (0-6 i.e. Sun-Sat)
    const dayNumber = date.getDate()        // Date e.g. 23 (1 to 31)
    const month = date.getMonth();          // Month number (0-11 i.e. Jan-Dec)
    const year = date.getFullYear();        // year e.g. 2018

    // const dateString = dayNames[dayOfWeek] + ' ' + monthName[month] + ' ' + dayNumber + ' '  + year;
    const dateString = monthName[month] + ' ' + dayNumber + ' ' + year;  // e.g.   May 23 2020

    return dateString;
}


/**
 * Return time in string format.
 * @dateToFormat date e.g. 'Wed May 23 2018 22:36:17 GMT+0530 (IST)' (firestore date field value)
 * return String 'May 23 2020 5:52:18 PM'
 *
 * Help: https://appendto.com/2016/02/get-current-date-object-javascript/
 */
export const getMediumDate = (dateToFormat) => {

    // Creating Date() object.
    const date = new Date(dateToFormat);

    // Getting current hour from Date object.
    const dayNumber = date.getDate()        // Date e.g. 23 (1 to 31)
    const month = date.getMonth();          // Month number (0-11 i.e. Jan-Dec)
    const year = date.getFullYear();        // year e.g. 2018

    const dateString = monthName[month] + ' ' + dayNumber + ', ' + year;  // e.g. May 23 2020
    const timePart = getTimeString(dateToFormat);                         // e.g. 5:52:18 PM

    return dateString + ' ' + timePart; // e.g. May 23 2020 5:52:18 PM
}


/**
 * Return time in string format.
 * @dateToFormat date e.g. Wed May 23 2018 22:36:17 GMT+0530 (IST) (firestore date field)
 * return String:  03:15:12 PM
 *
 * Help: https://reactnativecode.com/get-current-time-in-12-hours-am-pm-format/
 */
export const getTimeString = (dateToFormat) => {

  // Creating variables to hold time.
  var date, TimeType, hour, minutes, seconds, fullTime;

  // Creating Date() function object.
  date = new Date(dateToFormat);

  // Getting current hour from Date object.
  hour = date.getHours();

  // Checking if the Hour is less than equals to 11 then Set the Time format as AM.
  if(hour <= 11){
    TimeType = 'AM';
  }else{
    // If the Hour is Not less than equals to 11 then Set the Time format as PM.
    TimeType = 'PM';
  }

  // IF current hour is grater than 12 then minus 12 from current hour to make it in 12 Hours Format.
  if( hour > 12 ) { hour = hour - 12 }

  // If hour value is 0 then by default set its value to 12, because 24 means 0 in 24 hours time format.
  if( hour === 0 ) { hour = 12 }

  // Getting the current minutes from date object.
  minutes = date.getMinutes();

  // Checking if the minutes value is less then 10 then add 0 before minutes.
  if(minutes < 10) { minutes = '0' + minutes.toString() }

  //Getting current seconds from date object.
  seconds = date.getSeconds();

  // If seconds value is less than 10 then add 0 before seconds.
  if(seconds < 10){ seconds = '0' + seconds.toString() }

  // Adding all the variables in fullTime variable.
  //fullTime = hour.toString() + ':' + minutes.toString() + ':' + seconds.toString() + ' ' + TimeType.toString();
  fullTime = hour.toString() + ':' + minutes.toString() + ':' + seconds.toString() + ' ' + TimeType.toString();

  // Return date string e.g. May 23, 2018 03:15:12 PM
  return fullTime;
}


/**
 * Return time in string format.
 * @dateToFormat date e.g. Wed May 23 2018 22:36:17 GMT+0530 (IST) (firestore date field)
 * return String:  May 23, 2018 03:15:12 PM
 *
 * Help: https://reactnativecode.com/get-current-time-in-12-hours-am-pm-format/
 */
export const getDateTimeString = (dateToFormat) => {

  // Creating variables to hold time.
  var date, TimeType, hour, minutes, seconds, fullTime;

  // Creating Date() function object.
  date = new Date(dateToFormat);

  // Getting current hour from Date object.
  hour = date.getHours();

  // Checking if the Hour is less than equals to 11 then Set the Time format as AM.
  if(hour <= 11){
    TimeType = 'AM';
  }else{
    // If the Hour is Not less than equals to 11 then Set the Time format as PM.
    TimeType = 'PM';
  }

  // IF current hour is grater than 12 then minus 12 from current hour to make it in 12 Hours Format.
  if( hour > 12 ) { hour = hour - 12 }

  // If hour value is 0 then by default set its value to 12, because 24 means 0 in 24 hours time format.
  if( hour === 0 ) { hour = 12 }

  // Getting the current minutes from date object.
  minutes = date.getMinutes();

  // Checking if the minutes value is less then 10 then add 0 before minutes.
  if(minutes < 10) { minutes = '0' + minutes.toString() }

  //Getting current seconds from date object.
  seconds = date.getSeconds();

  // If seconds value is less than 10 then add 0 before seconds.
  if(seconds < 10){ seconds = '0' + seconds.toString() }

  // Adding all the variables in fullTime variable.
  //fullTime = hour.toString() + ':' + minutes.toString() + ':' + seconds.toString() + ' ' + TimeType.toString();
  fullTime = hour.toString() + ':' + minutes.toString() + ':' + seconds.toString() + ' ' + TimeType.toString();

  // Getting current day, month, week, year from Date object.
  //const dayOfWeek = date.getDay();        // Day Of Week    (0-6 i.e. Sun-Sat)
  const dayNumber = date.getDate()        // Date e.g. 23 (1 to 31)
  const month = date.getMonth();          // Month number (0-11 i.e. Jan-Dec)
  const year = date.getFullYear();        // year e.g. 2018

  // const dateString = dayNames[dayOfWeek] + ' ' + monthName[month] + ' ' + dayNumber + ' '  + year;
  const dateString = monthName[month] + ' ' + dayNumber + ', ' + year;  // e.g.   May 23, 2018

  // Return date string e.g. May 23, 2018 03:15:12 PM
  return dateString + '  ' + fullTime;
}


  // Return current date in YYYY-MM-DD format e.g. 2020-10-14 
  export const getCurrentDate_YYYYMMDD = () => {
    const date = new Date();
    const yyyy = date.getFullYear();    // year e.g. 2018
    
    let mm = date.getMonth() + 1;     // Month number (0-11)
    if ( mm < 10 ) { mm = '0' + mm } // add zero at left 0

    let dd = date.getDate();            // Date e.g. 23 (1 to 31)
    if ( dd < 10 ) { dd = '0' + dd; };  // add zero at left 0

    const dateString = yyyy + '-' + mm + '-' + dd;
    return dateString;
  }

  // Return future date (current + days) in YYYY-MM-DD format 
  // e.g. 2020-10-21
  export const getFutureDate_YYYYMMDD = (days) => {
    let date = new Date();
    date = new Date(date.getTime() + days*24*60*60*1000);
    
    const yyyy = date.getFullYear();  // year e.g. 2018
    
    let mm = date.getMonth() + 1;   // Month number (0-11)
    if ( mm < 10 ) { mm = '0' + mm } // add zero at left 0

    let dd = date.getDate();          // Date e.g. 23 (1 to 31)
    if (dd < 10 ) { dd = '0' + dd };  // add zero at left 0

    const dateString = yyyy + '-' + mm + '-' + dd;
    return dateString;
  }

  // Return past date (current - days) in YYYY-MM-DD format 
  // e.g. 2020-10-21
  export const getPastDate_YYYYMMDD = (days) => {
    let date = new Date();
    date = new Date(date.getTime() - days*24*60*60*1000);
    
    const yyyy = date.getFullYear();  // year e.g. 2018
    
    let mm = date.getMonth() + 1;   // Month number (0-11)
    if ( mm < 10 ) { mm = '0' + mm } // add zero at left 0

    let dd = date.getDate();          // Date e.g. 23 (1 to 31)
    if (dd < 10 ) { dd = '0' + dd };  // add zero at left 0
    
    const dateString = yyyy + '-' + mm + '-' + dd;
    return dateString;
  }

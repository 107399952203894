// Amazon Advertising Api: 
// Sponsored Products - Gets bid recommendations for keywords.
// Amazon API: POST /v2/sp/keywords/bidRecommendations
// Help: 
// https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Bid%20recommendations/createKeywordBidRecommendations

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import helper functions, constant etc.
import { getProfiles } from '../../helpers/amazonAdHelper';
import { spGetAdGroups, spGetKeywords, spGetBidRecommendationsForKeywords } from '../../helpers/amazonAdHelper';


class SPGetBidRecommendationsForKeywords extends React.Component {
  
  state = {

    // Start - Form input data
    // Number: The identifier of a profile associated with the advertiser account.
    profileId: "",

    // Number: The ad group identifier.
    // The identifier of the ad group that the keywords are associated with.
    // Note: Whenever user will select profile id from dropdown, we will fetch 
    // ad group list for selected profile id and fillup the ad group dropdown.
    // So user can select ad group from that dropdown for further step.
    adGroupId: "",

    // End - Form input data


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],

    // We will fetch ad group list for the selected profileId and set data here.
    // This ad group list will be used to fillup ad group drop down, so user can 
    // select the ad group from that and based on that we can show keyword list.
    // e.g. 
    // adGroupList: [
    //   {
    //     "adGroupId": 0,
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ],
    adGroupList: [],

    // Keywords list for selected profile.
    // Whenever user select profile we will fetch keyword list for selected profile 
    // and set result here. This keyword list consist all keywords that belongs to 
    // profile. But within ui we will show keyword for selected ad group only. Because 
    // whenever we will call bid recommendation for keyword api at that time 
    // we have to pass the ad group id and corresponding keyword list.
    // e.g. keywordList = [
    //   {
    //     "keywordId": 0,
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "exact",
    //     "bid": 0
    //   }
    // ]    
    keywordList: null,


    // Once we call bid recommendation api for keywords, we will set bid 
    // recommendation result here and display this info within ui.
    // e.g. bidRecommendationResult : {
    //   "adGroupId": "string",
    //   "recommendations": [
    //     {
    //       "code": "SUCCESS",
    //       "keyword": "string",
    //       "matchType": "exact",
    //       "suggestedBid": {
    //         "suggested": 0,
    //         "rangeStart": 0,
    //         "rangeEnd": 0
    //       }
    //     }
    //   ]
    // },
    bidRecommendationResult: null,


    // If error occur during any operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SPGetBidRecommendationsForKeywords - componentDidMount()');

    // 1 - Fetch amazon profile list to fillup dropdown
    // Commented: because we passed profileList as props from parent component
    //this.fetchProfileList();
  }


  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass profile Id to server side api, so first 
  // we will fetch profile list and fillup drop down so user can choose it.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    } 
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',
      isProcessing: false,
      
      //profileId: '',
      adGroupId: '',

      bidRecommendationResult: null,
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Get bid recommendation for keyword
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // Reset error message
    this.setState({ 
      error: '', 
      bidRecommendationResult: null,
    });

    // 1 - Get info from the state
    const { profileId, adGroupId, keywordList } = this.state;
    
    // 2 - If mandatory info not selected then return
    if ( !profileId || profileId === '' ) { 
      console.log('profileId not selected, so return');
      this.setState({ error: 'Please Select Profile'});
      return;
    }
    if ( !adGroupId || adGroupId === '' ) { 
      console.log('adGroupId not selected, so return');
      this.setState({ error: 'Please Select Ad Group'});
      return;
    }

    // 3 - Prepare keyword list for selected ad group id as per the api need.
    // e.g. keywordList = [
    //   {
    //     "keywordId": 0,
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "exact",
    //     "bid": 0
    //   }
    // ] 
    // 
    // Note: We need to pass keyword list to api as per below format.
    // e.g. keywordListForAdGroup = [
    //   {
    //     "keyword": "string",
    //     "matchType": "exact"
    //   }
    // ] 
    // Prepare keyword list for selected ad group id (we will pass it to api)
    const keywordListForAdGroup = [];
    keywordList.forEach( (item, index) => {
      if ( item.adGroupId === adGroupId ) { 
        const itemTemp = { 
          keyword: item.keywordText,
          matchType: item.matchType
        } 
        keywordListForAdGroup.push(itemTemp);
      }
    });
    console.log('keywordListForAdGroup:', keywordListForAdGroup);
    
    // 3A - If keyword list not exist for selected ad group then show message.
    if ( keywordListForAdGroup.length === 0 ) {
      console.log('Keywords not exist for selected ad group, so return');
      this.setState({ error: 'Keywords not exist for selected ad group'});
      return;
    }

    // 4 - Show processing
    this.setState({
      isProcessing: true,
    });
    
    // 5 - Call api
    spGetBidRecommendationsForKeywords(profileId, adGroupId, keywordListForAdGroup, this.spGetBidRecommendationsForKeywords_Success, this.spGetBidRecommendationsForKeywords_Error);
  }

  // Called when bid recommendation fetched successfully
  // Sample data received within jsonBody
  // e.g. result = { 
  //    status: 'success', 
  //    data: {
  //      "adGroupId": "string",
  //      "recommendations": [
  //        {
  //          "code": "SUCCESS",
  //          "keyword": "string",
  //          "matchType": "exact",
  //          "suggestedBid": {
  //          "suggested": 0,
  //          "rangeStart": 0,
  //          "rangeEnd": 0
  //          }
  //        }
  //      ]
  //    },
  //    error: null
  //  }
  spGetBidRecommendationsForKeywords_Success = (result) => {
    console.log('spGetBidRecommendationsForKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        bidRecommendationResult: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error it will run this callback
  spGetBidRecommendationsForKeywords_Error = (error) => {
    console.log('spGetBidRecommendationsForKeywords_Error() error:', error);
    
    this.setState({
      error: error,
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Get bid recommendation for keyword
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Handle profile selection change
  //-----------------------------------------------------------------
  // Whenever user will select profile from dropdown, this function called.
  // - So we will set selected profile Id within state and fetch ad groups 
  // (via server side api) that belongs to selected profileId.
  // - We will also fetch keyword list for selected profileId and set within state.
  handleProfileChange = (selectedProfileId) => {
    console.log('handleProfileChange() selectedProfileId:', selectedProfileId);

    // 1 - If profileId not selected then return
    if ( !selectedProfileId || selectedProfileId === '' ) {
      return;
    }

    // 2 - Set selected profile within state
    // Reset adGroupId and campaignId if selected before.
    this.setState({
      profileId: selectedProfileId, 
      adGroupId: '', 
      bidRecommendationResult: null, 
      error: '', 
    });

    // 3 - Show processing
    this.setState({ 
      isProcessing: true, 
    });

    // 4 - Fetch ad groups for selected profile
    this.fetchAdGroupsForProfile(selectedProfileId);

    // 5 - Fetch keyword list for selected profile
    this.fetchKeywordListForProfile(selectedProfileId);
  }
  //-----------------------------------------------------------------
  // End: Handle profile selection change
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Fetch ad group for the profile
  //-----------------------------------------------------------------
  fetchAdGroupsForProfile = (profileId) => {
    // 1 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Product%20ads/CreateProductAds
    const queryData = {
      startIndex: 0,
      count: 100,
      //stateFilter: 'enabled, paused, archived',
    }
  
    // 2 - Call api to Get ad groups (list) for selected profile Id.
    // i.e. We will fillup the received result within ad group dropdown.
    spGetAdGroups(profileId, queryData, this.spGetAdGroups_Success, this.spGetAdGroups_Error);
  }

  // Called when ad groups (list) fetched successfully
  // e.g. result: {
  //        status: 'success',
  //        data: [{
  //          "adGroupId": 0,
  //          "name": "string",
  //          "campaignId": 0,
  //          "defaultBid": 0,
  //          "state": "enabled"
  //        }],
  //        error: null,
  //      }
  spGetAdGroups_Success = (result) => {
    console.log('spGetAdGroups_Success() result:', result);

    if (result.status === 'success') {
      if ( result.data.length > 0 ) {
        this.setState({
          adGroupList: result.data,
          isProcessing: false,
          error: '',
        });
      } else {
        this.setState({
          adGroupList: result.data,
          isProcessing: false,
          error: 'Ad groups not exist for selected profile. Please create ad group under profile.',
        });
      }
    }

    if ( result.status === 'error' ) {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while fetch ad groups
  spGetAdGroups_Error = (error) => {
    console.log('spGetAdGroups_Error() error:', error);
    
    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch ad group for the profile
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // START: Fetch keyword list for the profile
  //-----------------------------------------------------------------
  fetchKeywordListForProfile = (profileId) => {
    console.log('fetchKeywordListForProfile() profileId:', profileId);
    
    // 1 - Show processing
    this.setState({
      isProcessing: true,
      keywordList: null,
    });

    // 2 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/listKeywords
    const queryData = {
      startIndex: 0,
      count: 100,
      //stateFilter: 'enabled, paused, archived',
    }

    // 3 - Call api to Get keywords (list) for selected profile Id.
    spGetKeywords(profileId, queryData, this.spGetKeywords_Success, this.spGetKeywords_Error);
  }

  // Called when keywords (list) fetched successfully 
  // e.g. result: {
  //        status: 'success',
  //        data: [{
  //          "keywordId": 0,
  //          "campaignId": 0,
  //          "adGroupId": 0,
  //          "state": "enabled",
  //          "keywordText": "string",
  //          "matchType": "exact",
  //          "bid": 0
  //        }],
  //        error: null,
  //      }    
  spGetKeywords_Success = (result) => {
    console.log('spGetKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        keywordList: result.data,
        //isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }

  //-----------------------------------------------------------------
  // END: Fetch keyword list for the profile
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // START: Assign selected group id within state.
  //-----------------------------------------------------------------
  // This function will be called whenever user will select group from 
  // the dropdown. So we will assign selected group id to state. 
  handleGroupChange = (selectedGroupId) => {
    console.log('handleGroupChange() selectedGroupId:', selectedGroupId);

    // 1 - If group id not selected then return
    if ( !selectedGroupId || selectedGroupId === '' ) {
      return;
    }

    // 2 - Set selected group id within state
    this.setState({
      adGroupId: selectedGroupId,
    });
  }
  //-----------------------------------------------------------------
  // START: Assign selected group id within state.
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => { 
    const { isProcessing, profileId, adGroupList, adGroupId, } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="profileid-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="profileid-label"
              value={profileId}
              onChange={ (e) => this.handleProfileChange(e.target.value) }
              disabled={ isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { type, name } = accountInfo;
                  return (<MenuItem key={ 'profile_' + profileId } value={profileId}>{profileId} / {countryCode} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              Ad groups (dropdown below) will be fillup for selected profile. 
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="adgrouid-label">Choose Ad Group</InputLabel>
            <Select
              id="adGrouId"
              labelId="adgrouid-label"
              value={adGroupId}
              onChange={ (e) => this.handleGroupChange(e.target.value) }
              disabled={ isProcessing || adGroupList.length === 0 }
            >
              { // Dynamically generate options
                adGroupList.map((item, index) => {
                  const { adGroupId, name, campaignId, defaultBid, state } = item;
                  return (<MenuItem key={ 'adgroup_' + adGroupId } value={adGroupId}>{adGroupId} / {name} / campaignId: {campaignId} / defaultBid: { defaultBid } / { state }</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              You will see list of keyword for selected ad group (if keyword created under that ad group). 
              Thereafter you can Get Bid Recommendations for those keywords.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          { 
            adGroupId !== '' && this.renderKeywordsForAdGroup() 
          }
        </Grid>

        <Grid item xs={12} sm={12}>
          { 
            //adGroupId !== '' && this.renderSelectedAdGroupInfo() 
          }
        </Grid>

      </Grid>
    );
  }

  // This function will render keywords for selected ad group
  renderKeywordsForAdGroup = () => {
    const { keywordList, profileId, adGroupId } = this.state;

    // If list variable not exist then do not show anything
    if ( !keywordList ) { return null; }

    // If list empty array then show message.
    if ( keywordList.length === 0 ) {
      return ( 
        <div align='left'>
          <b>Keyword list not exist for profileId: { profileId }</b>
        </div>
      );
    }

    // If keyword list exist then render keyword those belongs to selected ad group id.
    // e.g. 
    // keywordList = [
    //   {
    //     "keywordId": 0,
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "exact",
    //     "bid": 0
    //   }
    // ]
    // Prepare keyword list for selected ad group id.
    const keywordListForAdGroup = [];
    keywordList.forEach( (item, index) => {
      if ( item.adGroupId === adGroupId ) { 
        const itemTemp = { ...item } ;
        keywordListForAdGroup.push(itemTemp);
      }
    });

    return (
      <div align='left'>
        <b>Keywords for adGroupId: { adGroupId } </b> <br />
        { keywordListForAdGroup.length === 0 && 
          <p>Keywords not exist for selected ad group.</p>
        }
        { 
          keywordListForAdGroup.map( (item, index) => {
            return (
              <div key={ 'keyword_' + index } >
                #{ index + 1 }  { item.keywordText } ({ item.matchType }) { item.state } <br />
              </div>
            )
          })
        }
        <hr />
      </div>
    );

  }


  // This function will render more info about selected ad group id.
  // If ad group info not found from array, it will render nothing.
  renderSelectedAdGroupInfo = () => {
    //console.log('renderSelectedAdGroupInfo()');

    // 1 - Find group info that belongs to selected group id
    // e.g. adGroupList: [
    //   {
    //     "adGroupId": 0,
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ],
    let groupInfo;
    const { adGroupList, adGroupId } = this.state;
    adGroupList.forEach( (item, index) => {
      if ( item.adGroupId === adGroupId ) {
        groupInfo = item;
      }
    });

    // 2 - If group info not found then return
    if (!groupInfo) { 
      return null;
    } 

    // 3 - Render selected group information
    return (
      <>
        adGroupId: { groupInfo.adGroupId } <br /> 
        campaignId: { groupInfo.campaignId } <br /> 
        name: { groupInfo.name } <br /> 
        defaultBid: { groupInfo.defaultBid } <br /> 
        state: { groupInfo.state }  <br /> 
      </>
    );

  }

  // It will return true if any input not valid, so diasable submit button.
  // If all input valid then it will return false, so enable submit button.
  shouldDisableSubmitButton = () => {
    
    const { isProcessing, profileId, adGroupId, keywordList } = this.state; 
    
    // 1 - If any mandatory value empty then true (disable button)
    if ( isProcessing || profileId === '' || adGroupId === '' ) {
      return true; // disable button
    }

    // 2 - If keyword not exist for selected ad group then return true (disable button).
    let keywordCount = 0;
    keywordList.forEach( (item, index) => {
      if ( item.adGroupId === adGroupId ) { 
        keywordCount++;
      }
    });
    if ( keywordCount === 0 ) {
      return true; // disable button
    }

    // Return false (All input valid, so keep button enabled)
    return false; // enable button
  }


  // Render action button
  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{ marginRight: 20 }} 
          disabled={ this.shouldDisableSubmitButton() }
        >Get Bid Recommendation</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }
  
  // Render received result - recommended bid
  // e.g. bidRecommendationResult: {
  //   "adGroupId": "string",
  //   "recommendations": [
  //     {
  //       "code": "SUCCESS",
  //       "keyword": "string",
  //       "matchType": "exact",
  //       "suggestedBid": {
  //         "suggested": 0,
  //         "rangeStart": 0,
  //         "rangeEnd": 0
  //       }
  //     }
  //   ]
  // }
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { bidRecommendationResult } = this.state;
    
    // 1 - result not exist then do not show anything
    if ( !bidRecommendationResult ) { return null; }

    // 2 - If result exist then render result
    const { adGroupId, recommendations } = bidRecommendationResult;
    return (
      <div align='left'>
        <b>Bid Recommendation Result/Error:</b> <br />
        <b>For adGroupId:</b> { adGroupId } <br />
        <hr />
        { 
          recommendations.map( (item, index) => {
            return (
              <div key={ 'adgroup_result_' + index } >
                <b>#{ index + 1}</b> <br />
                <b>keyword:</b> { item.keyword } <br />
                <b>matchType:</b> { item.matchType } <br /> 
                <b>suggestedBid:</b> { JSON.stringify(item.suggestedBid) } <br /> 
                <b>code:</b> { item.code } <br />
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Get Bid Recommendations For Keywords</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SPGetBidRecommendationsForKeywords.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SPGetBidRecommendationsForKeywords);


// This file consist functions to call amazon advertising api via cloud run server

import firebase from './firebaseApp';
import { config, marketPlaces } from './constants';

// Get api base url from config constant
const API_BASE_URL = config.API_BASE_URL;

// Debug
//console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
//console.log('API_BASE_URL:', API_BASE_URL);



//-------------------------------------------------------------------
// POST /ad/getAmazonUserProfile
// Fetch current logged in amazon user profile info.
// e.g. primary email, name, customerId etc.
//-------------------------------------------------------------------
// Help: 
// https://developer.amazon.com/docs/login-with-amazon/obtain-customer-profile.html#call-profile-endpoint
export const getAmazonUserProfile = async (successCallback, errorCallback) => { 
  //console.log('amazonAdHelper - getAmazonUserProfile()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
  }  

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/getAmazonUserProfile';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getAmazonUserProfile - jsonBody:', jsonBody);

    // e.g. jsonBody: { 
    //   status: 'success', 
    //   data: { 
    //     user_id: 'amzn1.account.AG3OPETKMBVUEROxxxxxxxxxxxx',
    //     name: 'Firstname Lastname',
    //     email: 'email@gmail.com' 
    //   }
    //   error: null 
    // }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getAmazonUserProfile - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// getProfiles()
// Profiles - Get profiles list that belongs to logged in amazon user
//-------------------------------------------------------------------
// This function will call server side api to get profile list that belogs to current user.
// Help: https://advertising.amazon.com/API/docs/en-us/reference/2/profiles#/Profiles/listProfiles
export const getProfiles = async (successCallback, errorCallback) => { 
  //console.log('amazonAdHelper - getProfiles()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/getProfiles';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getProfiles - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getProfiles - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// dspGetAdvertisers()
// Amazon DSP - Returns a list of advertisers with information which 
// satisfy the filtering criteria.
//-------------------------------------------------------------------
// This function will call server side api to get list of advertisers.
// Help: 
// https://advertising.amazon.com/API/docs/en-us/dsp-advertiser/#/Advertiser/get_dsp_advertisers
// 
// @profileId Number e.g. 12123123123123
// @queryData: Data object
// e.g. queryData: {
//    startIndex: 0, 
//    count: 20, 
//    advertiserIdFilter: '6753579448901,8753890875385'
//  }
export const dspGetAdvertisers = async (profileId, queryData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - dspGetAdvertisers()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    queryData: queryData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/dsp/getAdvertisers';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    console.log('dspGetAdvertisers - Success');
    //console.log('dspGetAdvertisers - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. 
    // jsonBody: { status: 'success', data: [ ... ], error: null }
    // i.e. jsonBody.data: [
    //    {
    //      "totalResults": 1100,
    //      "response": [
    //        {
    //          "advertiserId": "4728736040201",
    //          "name": "DSP Public API Advertiser",
    //          "currency": "USD",
    //          "url": "www.example.com",
    //          "country": "US",
    //          "timezone": "string"
    //        }
    //      ]
    //    }
    //  ]
    successCallback(jsonBody);

  })
  .catch( error => {
    console.log('dspGetAdvertisers - error:', error);

    errorCallback(error);
  }); 

}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// spGetCampaigns()
// Sponsored Products - Get Capaigns
//-------------------------------------------------------------------
// This function will call server side api to get campaign list for sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns
// @profileId Number e.g. 12123123123123
// @queryData: Data object
// e.g. queryData: {
//   startIndex: 0,
//   count: 10,
//   stateFilter: 'enabled, paused, archived',
// }
export const spGetCampaigns = async (profileId, queryData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetCampaigns()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    queryData: queryData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getCampaigns';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spGetCampaigns - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetCampaigns - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spCreateCampaigns()
// Sponsored Products - Create one or more Capaigns
//-------------------------------------------------------------------
// This function will call server side api to create one or more campaign sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/createCampaigns
// @profileId: i.e. Amazon advertising api scope id
// @campaignsData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to create one or more campaign
// e.g. campaignsData = [
//   {
//     "portfolioId": 0,
//     "name": "string",
//     "campaignType": "sponsoredProducts",
//     "targetingType": "manual",
//     "state": "enabled",
//     "dailyBudget": 0,
//     "startDate": "string",
//     "endDate": "string",
//     "premiumBidAdjustment": true,
//     "bidding": {
//       "strategy": "legacyForSales",
//       "adjustments": [
//         {
//           "predicate": "placementTop",
//           "percentage": 0
//         }
//       ]
//     }
//   }
// ]
export const spCreateCampaigns = async (profileId, campaignsData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spCreateCampaigns()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if (!profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if (!campaignsData || campaignsData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: campaignsData' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    campaignsData: campaignsData, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/createCampaigns';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spCreateCampaigns - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spCreateCampaigns - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spUpdateCampaigns()
// Sponsored Products - Update one or more Capaigns
//-------------------------------------------------------------------
// This function will call server side api to Update one or more campaign sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/updateCampaigns
// @profileId: i.e. Amazon advertising api scope id
// @updateData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to update one or more campaign
// e.g. updateData = [
//   {
//     "campaignId": 0,
//     "portfolioId": 0,
//     "name": "string",
//     "state": "enabled",
//     "dailyBudget": 0,
//     "startDate": "string",
//     "endDate": "string",
//     "premiumBidAdjustment": true
//   }
// ]
// IMPORTANT: 
// @campaignId - existing campaign id to update values. (Required)
// - Pass other fields those value needs to update.
export const spUpdateCampaigns = async (profileId, updateData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spUpdateCampaigns()');
  
  // Data Validation - If parameter missing then return with error, do not call server side api.
  if (!profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if (!updateData || updateData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: updateData[]' };
    errorCallback(error);
    return;
  }
  
  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    updateData: updateData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/updateCampaigns';
  console.log('apiUrl:', apiUrl);
 
  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spUpdateCampaigns - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spUpdateCampaigns - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// spGetAdGroups()
// Sponsored Products - Get an array of (list) of ad groups
//-------------------------------------------------------------------
// This function will call server side api to get ad groups (list) for sponsored product
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Ad%20groups/getAdGroups
// @profileId: Number
// @queryData: Data object
export const spGetAdGroups = async (profileId, queryData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetAdGroups()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    queryData: queryData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getAdGroups';  
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spGetAdGroups - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetAdGroups - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spCreateAdGroups()
// Sponsored Products - Create one or more ad groups
//-------------------------------------------------------------------
// This function will call server side api to create one or more ad groups for sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Ad%20groups/createAdGroups
// @profileId: i.e. Amazon advertising api scope id
// @adGroupsData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to create one or ad groups
// e.g. adGroupsData = [
//   {
//     "name": "string",
//     "campaignId": 0,
//     "defaultBid": 0,
//     "state": "enabled"
//   }
// ]
export const spCreateAdGroups = async (profileId, adGroupsData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spCreateAdGroups()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if (!profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if (!adGroupsData || adGroupsData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: adGroupsData' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    adGroupsData: adGroupsData, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/createAdGroups';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spCreateAdGroups - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spCreateAdGroups - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spUpdateAdGroups()
// Sponsored Products - Update one or more AdGroups
//-------------------------------------------------------------------
// This function will call server side api to Update one or more AdGroups for sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Ad%20groups/updateAdGroups
// @profileId: i.e. Amazon advertising api scope id
// @updateData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to update one or more AdGroups
// e.g. updateData = [
//   {
//      "adGroupId": 0,
//      "name": "string",
//      "defaultBid": 0,
//      "state": "enabled"
//   }
// ]
// IMPORTANT: 
// @adGroupId - existing ad groups id to update values. (Required)
// - Pass other fields those value needs to update.
export const spUpdateAdGroups = async (profileId, updateData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spUpdateAdGroups()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if (!profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if (!updateData || updateData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: updateData[]' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    updateData: updateData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/updateAdGroups';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('updateAdGroups - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('updateAdGroups - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// spGetProductAds()
// Sponsored Products - Get a list of product ads
//-------------------------------------------------------------------
// This function will call server side api to get product ads (list) for sponsored product
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Product%20ads/listProductAds
// @profileId: Number
// @queryData: Data object
export const spGetProductAds = async (profileId, queryData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetProductAds()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    queryData: queryData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getProductAds';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spGetProductAds - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetProductAds - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spCreateProductAds()
// Sponsored Products - Create one or more product ads
//-------------------------------------------------------------------
// This function will call server side api to create one or more product ads for sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Product%20ads/CreateProductAds
// @profileId: i.e. Amazon advertising api scope id
// @productAdsData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to create one or more product ads
// e.g. productAdsData = [
//   {
//     "campaignId": 0,
//     "adGroupId": 0,
//     "sku": "string",
//     "asin": "string",
//     "state": "enabled"
//   }
// ]
//
// Note for productAdsData: 
// - The SKU associated with the product. Defined for seller accounts only.
// - The ASIN associated with the product. Defined for vendors only.
export const spCreateProductAds = async (profileId, productAdsData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spCreateProductAds()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if ( !profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if ( !productAdsData || productAdsData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: productAdsData[]' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA', 
    profileId: profileId, 
    productAdsData: productAdsData, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/createProductAds';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spCreateProductAds - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spCreateProductAds - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spUpdateProductAds()
// Sponsored Products - Update one or more product ads
//-------------------------------------------------------------------
// It will call server side api to Update one or more product ads for sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Product%20ads/updateProductAds
// @profileId: i.e. Amazon advertising api scope id
// @updateData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to update one or more ProductAds
// e.g. updateData = [
//   {
//      "adId": 0,
//      "state": "enabled"
//   }
// ]
// IMPORTANT: 
// @adId - existing product ad identifier. (Required)
// - Pass other fields those value needs to update.
export const spUpdateProductAds = async (profileId, updateData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spUpdateProductAds()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if (!profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if (!updateData || updateData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: updateData[]' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    updateData: updateData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/updateProductAds';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('updateProductAds - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('updateProductAds - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// spGetKeywords()
// Sponsored Products - Get a list of keywords
//-------------------------------------------------------------------
// This function will call server side api to get list of keywords (for sponsored product)
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/listKeywords
// @profileId: Number
// @queryData: Data object
export const spGetKeywords = async (profileId, queryData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetKeywords()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    queryData: queryData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getKeywords';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spGetKeywords - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. 
    // jsonBody: { status: 'success', data:[ ... ], error: null }
    // i.e. jsonBody.data: [
    //   {
    //     "keywordId": 0,
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "exact",
    //     "bid": 0
    //   }
    // ]
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetKeywords - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spCreateKeywords()
// Sponsored Products - Create one or more keywords
//-------------------------------------------------------------------
// This function will call server side api to create one or more keywords (for sponsored products).
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/createKeywords
// @profileId: i.e. Amazon advertising api scope id
// @keywordsData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// e.g. keywordsData = [
//   {
//     "campaignId": 0,
//     "adGroupId": 0,
//     "state": "enabled",
//     "keywordText": "string",
//     "matchType": "exact",
//     "bid": 0
//   }
// ]  
// i.e. Arrary of data to create one or more keywords
export const spCreateKeywords = async (profileId, keywordsData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spCreateKeywords()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if ( !profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if ( !keywordsData || keywordsData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: keywordsData[]' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA', 
    profileId: profileId, 
    keywordsData: keywordsData, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/createKeywords';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json() )
  .then( jsonBody => {
    //console.log('spCreateKeywords - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // Note: If some parameter missing or invalid value then also it return
    // jsonBody.data[] object. But within array 'code' field consist error code 
    // (e.g. INVALID_ARGUMENT ) and 'description' field consist brief information
    // about the error.
    // e.g. jsonBody: { status: 'success', data:[ ... ], error: null }
    // 
    // e.g. jsonBody.data: [{
    //    "keywordId": 0,
    //    "code": "string",
    //    "details": "string"
    //    "description": "string"
    //  }] 
    // 
    // e.g. jsonBody.data: [{
    //    "keywordId": '',
    //    "code": "INVALID_ARGUMENT",
    //    "details": "string"
    //    "description": "Bid is too low"
    //  }] 
    // 
    // e.g. [{
    //    "keywordId": 128343749582913,
    //    "code": "SUCCESS",
    //    "details": ""
    //    "description": ""
    // }]
    successCallback(jsonBody);

  })
  .catch( error => {
    console.log('spCreateKeywords - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spUpdateKeywords()
// Sponsored Products - Update one or more keywords
//-------------------------------------------------------------------
// This function will call server side api to Update one or more Keywords for sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/updateKeywords
// 
// @profileId: i.e. Amazon advertising api scope id
// @updateData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to update one or more Keywords
// e.g. updateData = [
//   {
//      "keywordId": 0,
//      "state": "enabled",
//      "bid": 0
//   }
// ]
// IMPORTANT: 
// @keywordId - The identifier of the existing keyword id to update values. (Required)
// - Pass other fields those value needs to update.
export const spUpdateKeywords = async (profileId, updateData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spUpdateKeywords()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if (!profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if (!updateData || updateData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: updateData[]' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    updateData: updateData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/updateKeywords';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('updateKeywords - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. 
    // jsonBody: { 
    //    status: 'success', 
    //    data:[ 
    //      {
    //         "keywordId": 0,
    //         "code": "string",
    //         "details": "string"
    //      }
    //    ], 
    //    error: null 
    //  }
    // 
    // i.e. keywordId - The identifer of the keyword.
    // i.e. code - The success or error code for the operation. e.g. SUCCESS
    // i.e. details - The human-readable description of the error.
    // 
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('updateKeywords - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// spGetNegativeKeywords()
// Sponsored Products - Get a list of negative keywords
//-------------------------------------------------------------------
// This function will call server side api to get list of negative keywords (for sponsored product)
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/listNegativeKeywords
// @profileId: Number
// @queryData: Data object
//  e.g. 
//  queryData = {
//    startIndex: 0,
//    count: 100, 
//    stateFilter: 'enabled, archived',
//  }
export const spGetNegativeKeywords = async (profileId, queryData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetNegativeKeywords()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    queryData: queryData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getNegativeKeywords';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spGetNegativeKeywords - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. 
    // jsonBody: { 
    //    status: 'success', 
    //    data:[ 
    //      {
    //        "keywordId": 0,
    //        "campaignId": 0,
    //        "adGroupId": 0,
    //        "state": "enabled",
    //        "keywordText": "string",
    //        "matchType": "negativeExact",
    //      }
    //    ], 
    //    error: null 
    //  }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetNegativeKeywords - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spCreateNegativeKeywords()
// Sponsored Products - Create one or more negative keywords
//-------------------------------------------------------------------
// This function will call server side api to create one or more negative keywords (for sponsored products).
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/createNegativeKeywords
// @profileId: i.e. Amazon advertising api scope id
// @keywordsData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// e.g. keywordsData = [
//   {
//     "campaignId": 0,
//     "adGroupId": 0,
//     "state": "enabled",
//     "keywordText": "string",
//     "matchType": "negativeExact",  // e.g. 'negativeExact' or 'negativePhrase'
//   }
// ]  
// i.e. Arrary of data to create one or more negative keywords
export const spCreateNegativeKeywords = async (profileId, keywordsData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spCreateNegativeKeywords()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if ( !profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if ( !keywordsData || keywordsData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: keywordsData[]' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA', 
    profileId: profileId, 
    keywordsData: keywordsData, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/createNegativeKeywords';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json() )
  .then( jsonBody => {
    console.log('createNegativeKeywords - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // Note: If some parameter missing or invalid value then also it return
    // jsonBody.data[] object. But within array 'code' field consist error code 
    // (e.g. INVALID_ARGUMENT ) and 'description' field consist brief information
    // about the error.
    // e.g. jsonBody: { 
    //        status: 'success', 
    //        data:[{
    //          "keywordId": 0,
    //          "code": "string",
    //          "details": "string",
    //          "description": "string"
    //        }], 
    //        error: null 
    //      }
    // 
    //  
    // e.g. jsonBody.data: [{
    //    "keywordId": '',
    //    "code": "INVALID_ARGUMENT",
    //    "details": "string",
    //    "description": ""
    //  }] 
    // 
    // e.g. [{
    //    "keywordId": 128343749582913,
    //    "code": "SUCCESS",
    //    "details": ""
    //    "description": ""
    // }]
    successCallback(jsonBody);

  })
  .catch( error => {
    console.log('createNegativeKeywords - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// spUpdateNegativeKeywords()
// Sponsored Products - Update one or more Negative keywords
//-------------------------------------------------------------------
// This function will call server side api to Update one or more Negative Keywords for sponsored products.
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/updateNegativeKeywords
// 
// @profileId: i.e. Amazon advertising api scope id
// @updateData: [ { key1: 'value1', key2: 'value2', ... }, ... ] 
// i.e Arrary of data to update one or more Negative Keywords
// e.g. updateData = [
//   {
//      "keywordId": 0,
//      "state": "enabled",
//   }
// ]
// IMPORTANT: 
// @keywordId - The identifier of the existing Negative keyword id to update values. (Required)
// - Pass other fields those value needs to update.
export const spUpdateNegativeKeywords = async (profileId, updateData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spUpdateNegativeKeywords()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if (!profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if (!updateData || updateData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: updateData[]' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    updateData: updateData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/updateNegativeKeywords';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('updateNegativeKeywords - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. 
    // jsonBody: { 
    //    status: 'success', 
    //    data:[ 
    //      {
    //         "keywordId": 0,
    //         "code": "string",
    //         "details": "string"
    //      }
    //    ], 
    //    error: null 
    //  }
    // 
    // i.e. keywordId - The identifer of the keyword.
    // i.e. code - The success or error code for the operation. e.g. SUCCESS
    // i.e. details - The human-readable description of the error.
    // 
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('updateNegativeKeywords - error:', error);
    errorCallback(error);
  });
  
}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// spGetSuggestedKeywordsForAsins()
// Sponsored Products - Get suggested keywords for asins.
//-------------------------------------------------------------------
// This function will call server side api to get Suggested keywords for given asin array. 
// Suggested keywords are returned in an array ordered by descending effectiveness.
// Help: 
// https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Suggested%20keywords/bulkGetAsinSuggestedKeywords
// 
// @profileId:  Number
// @asins   Array[String] e.g. [ 'asin1', 'asin2', ... ]
// @maxNumSuggestions   Integer (1-1000 ) default: 100
export const spGetSuggestedKeywordsForAsins = async (profileId, asins, maxNumSuggestions, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetSuggestedKeywordsForAsins()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    asins: asins, 
    maxNumSuggestions: maxNumSuggestions
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getSuggestedKeywordsForAsins';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    console.log('spGetSuggestedKeywordsForAsins - Success');
    //console.log('spGetSuggestedKeywordsForAsins - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. jsonBody = { 
    //   status: 'success', 
    //   data: [
    //     {
    //       "keywordText": "string",
    //       "matchType": "exact"
    //     }
    //   ],
    //   error: null
    // }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetSuggestedKeywordsForAsins - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// spGetKeywordsRecommendationForAsins()
// Sponsored Products - Get ranked keyword recommendations by user-provided metric (asin)
// Help: 
//https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi/prod#/Get%20ranked%20keywords%20recommendations
//-------------------------------------------------------------------
// @profileId:  Number
// @market: String  e.g. 'CA', 'US' etc.
// @advertiserId: String
// @asins: Array[String] e.g. [ 'asin1', 'asin2', ... ]
// @maxRecommendations: Integer 0-100 
// @sortDimension: String e.g. CLICKS, CONVERSIONS, DEFAULT
// @locale: String: e.g. zh_CN (for US, UK, CA) en_GB (for DE, FR, IT, ES) 
export const spGetKeywordsRecommendationForAsins = async (profileId, market, advertiserId, asins, maxRecommendations, sortDimension, locale, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetKeywordsRecommendationForAsins()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare data
  // e.g. asinsKeywordTargetRequest = {
  //    maxRecommendations: 100,             // Integer 0-100
  //    sortDimension: 'DEFAULT',            // String: CLICKS, CONVERSIONS, DEFAULT
  //    locale: 'zh_CN',                     // String: zh_CN (for US, UK, CA) en_GB (for DE, FR, IT, ES) 
  //    targetList: [{                       // User defined keyword targets list (max: 100)
  //      matchType: 'BROAD',                // String: BROAD, EXACT, PHRASE
  //      keyword: 'keyword text'            // The keyword value
  //      bid: 0.02                          // number (double)
  //    }],
  //    asins: ['asin1', 'asin2 ],              // (mandatory)  An array list of Asins (max 50)
  //    recommendationType: 'KEYWORD_FOR_ASINS' // (mandatory) 
  //}  
  const asinsKeywordTargetRequest = {
    maxRecommendations: maxRecommendations,
    sortDimension: sortDimension,
    locale: locale,
    targetList: [],
    asins: asins,
    recommendationType: 'KEYWORD_FOR_ASINS'
  }

  // 3 - Fetch market id for a market
  const marketplaceId = marketPlaces[market].market_place_id;
  console.log('marketplaceId:', marketplaceId);

  // 4 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here.   
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    marketplaceId: marketplaceId,
    advertiserId: advertiserId,
    asinsKeywordTargetRequest: asinsKeywordTargetRequest
  }

  // 5 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 6 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getKeywordsRecommendationForAsins';
  console.log('apiUrl:', apiUrl);

  // 7 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spGetKeywordsRecommendationForAsins - Success');
    //console.log('spGetKeywordsRecommendationForAsins - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. jsonBody = { 
    //    status: 'success', 
    //    data: [
    //      {
    //        "matchType": "BROAD",
    //        "keyword": "string",
    //        "bid": 0,
    //        "suggestedBid": {
    //          "suggested": 0,
    //          "rangeStart": 0,
    //          "rangeEnd": 0
    //        },
    //        "translation": "string",
    //        "rank": 0
    //      }
    //    ],
    //    error: null
    // }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetKeywordsRecommendationForAsins - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// spGetBidRecommendationsForKeywords()
// Sponsored Products - Get bid recommendation for keywords
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Bid%20recommendations/createKeywordBidRecommendations
//-------------------------------------------------------------------
// @profileId: Number i.e. Amazon advertising api scope id
// @adGroupId: number i.e. The identifier of the ad group that the keywords are associated with.
// @keywords: Array 
// e.g. keywords = [
//   {
//     "keyword": "string",
//     "matchType": "exact"
//   }
// ]
export const spGetBidRecommendationsForKeywords = async (profileId, adGroupId, keywords, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetBidRecommendationsForKeywords()');

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here.   
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    adGroupId: adGroupId, 
    keywords: keywords
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }
  
  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getBidRecommendationsForKeywords';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('spGetBidRecommendationsForKeywords - Success');
    //console.log('spGetBidRecommendationsForKeywords - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. jsonBody = { 
    //    status: 'success', 
    //    data: {
    //      "adGroupId": "string",
    //      "recommendations": [
    //        {
    //          "code": "SUCCESS",
    //          "keyword": "string",
    //          "matchType": "exact",
    //          "suggestedBid": {
    //          "suggested": 0,
    //          "rangeStart": 0,
    //          "rangeEnd": 0
    //          }
    //        }
    //      ]
    //    },
    //    error: null
    //  }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetBidRecommendationsForKeywords - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------



//-------------------------------------------------------------------
// createFlowSponsoredProduct()
// Create new flow for sponsored product via server side and point
//-------------------------------------------------------------------
// @profileId: String 
// @profileType: String e.g. 'vendor' or 'seller'
// @flowData: Data Object e.g. { key1:value1, key2:value2, }
export const createFlowSponsoredProduct = async (profileId, profileType, flowData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - createFlowSponsoredProduct()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if ( !profileId || profileId === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileId' };
    errorCallback(error);
    return;
  }
  if ( !profileType || profileType === '' ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: profileType' };
    errorCallback(error);
    return;
  }

  if ( !flowData || flowData.length === 0 ) {
    const error = { status: 'error', data: null, error: 'Missing/Empty parameter: flowData' };
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    profileType: profileType, 
    flowData: flowData,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/createFlowSponsoredProduct';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('createFlowSponsoredProduct - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('createFlowSponsoredProduct - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// spRequestReport()
// POST /ad/sp/requestReport
// Sponsored Products - Requests a Sponsored Products report.
//-------------------------------------------------------------------
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/requestReport
// 
// @profileId: String  i.e. Amazon advertising api scope id
// 
// @recordType: String  i.e. The type of entity for which the report should be generated.
//      e.g. campaigns | adGroups | keywords | productAds | asins | targets
// 
// @reportData: Json data object 
//    e.g. reportData = {
//            "campaignType": "sponsoredProducts",
//            "segment": "query",                       // 'query' or 'placement'
//            "reportDate": "string",                   // 'YYYYMMDD'
//            "metrics": "string"                       
//         }
export const spRequestReport = async (profileId, recordType, reportData, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spRequestReport()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if ( !profileId || profileId === '' ) {
    const error = 'Missing/Empty parameter: profileId';
    errorCallback(error);
    return;
  }
  if ( !recordType || recordType.length === 0 ) {
    const error = 'Missing/Empty parameter: recordType';
    errorCallback(error);
    return;
  }  
  if ( !reportData ) {
    const error = 'Missing/Empty parameter: reportData';
    errorCallback(error);
    return;
  }
  if ( recordType === 'asins' ) {
    if ( !reportData.campaignType ) {
      const error = 'Missing/Empty parameter: reportData.campaignType (Required for asins report)';
      errorCallback(error);
      return;
    }  
  }
  if ( !reportData.segment ) {
    const error = 'Missing/Empty parameter: reportData.segment';
    errorCallback(error);
    return;
  }
  if ( !reportData.reportDate ) {
    const error = 'Missing/Empty parameter: reportData.reportDate';
    errorCallback(error);
    return;
  }
  if ( !reportData.metrics ) {
    const error = 'Missing/Empty parameter: reportData.metrics';
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    recordType: recordType, 
    reportData: reportData, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/requestReport';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json() )
  .then( jsonBody => {
    //console.log('spRequestReport - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. jsonBody: { 
    //   status: 'success', 
    //   data: { 
    //      "reportId" : "amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa",
    //      "recordType" : "keyword",
    //      "status" : "IN_PROGRESS",
    //      "statusDetails" : "Generating report"
    //   },
    //   error: null 
    // }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spRequestReport - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// spGetReport()
// POST /ad/sp/getReport
// Sponsored Products - Gets a previously requested report specified by identifier (reportId)
//-------------------------------------------------------------------
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Reports/getReport
// 
// @profileId: String  i.e. Amazon advertising api scope id
// @reportId : String i.e. The report identifier
export const spGetReport = async (profileId, reportId, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spGetReport()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if ( !profileId || profileId === '' ) {
    const error = 'Missing/Empty parameter: profileId';
    errorCallback(error);
    return;
  }
  if ( !reportId || reportId === '' ) {
    const error = 'Missing/Empty parameter: reportId';
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    reportId: reportId, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/getReport';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json() )
  .then( jsonBody => {
    //console.log('spGetReport - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. jsonBody: { 
    //   status: 'success', 
    //   data: {
    //      "fileSize" : 2938, 
    //      "location" : "https://advertising-api-test.amazon.com/v1/reports/amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa/download", 
    //      "reportId" : "amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa", 
    //      "status" : "SUCCESS", 
    //      "statusDetails" : "Report successfully generated",
    //   },
    //   error: null 
    // }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spGetReport - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// spDownloadReport()
// POST /ad/sp/downloadReport
// Sponsored Products - Download report generated for sponsored product
//-------------------------------------------------------------------
// @profileId: String  i.e. Amazon advertising api scope id
// @downlodLocation: String i.e. report file download url
export const spDownloadReport = async (profileId, downlodLocation, successCallback, errorCallback) => { 
  console.log('amazonAdHelper - spDownloadReport()');

  // Data Validation - If parameter missing then return with error, do not call server side api.
  if ( !profileId || profileId === '' ) {
    const error = 'Missing/Empty parameter: profileId';
    errorCallback(error);
    return;
  }
  if ( !downlodLocation || downlodLocation === '' ) {
    const error = 'Missing/Empty parameter: downlodLocation';
    errorCallback(error);
    return;
  }

  // 1 - Fetch current logged in user id token
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);
  
  // 2 - Prepare post data
  // e.g. regionId: 'NA' - North America, 'EU' - Europe, 'FE' - Far East
  // Note: While user logged in to amazon it is authorised for North America region,
  // So we have to pass 'NA' as the regionId here. 
  const postBody = {
    regionId: 'NA',
    profileId: profileId,
    downlodLocation: downlodLocation, 
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/ad/sp/downloadReport';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json() )
  .then( jsonBody => {
    //console.log('spDownloadReport - jsonBody:', jsonBody);

    // Sample data received within jsonBody
    // e.g. jsonBody: { 
    //   status: 'success', 
    //   data: {
    //    message: 'Report Downloaded.' 
    //   },
    //   octetStreamData: 'String', 
    //   error: null 
    // }
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('spDownloadReport - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------

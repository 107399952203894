// Get lowest priced offers for asins.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Import helper functions, constant etc.
import { getProductCategoriesForASIN } from '../../helpers/mwsHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);


class GetProductCategoriesForASIN extends React.Component {
  
  state = {
    // Form input
    market: 'CA',
    asin: 'B015CH1PJU', // e.g. 'B015CH1PJU' (Single Asin)

    status: '',
    error: '',

    self: [],

    headers: null,
    responseMetadata: null,
    statusCode: 0,

    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('GetProductCategoriesForASIN - componentDidMount()');
  }


  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      headers: null,
      products: [],
      responseMetadata: null,
      statusCode: 0,
    });
  }


  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Fetch data from state
    const { market, asin } = this.state;
    if (!market || market === '') { return };
    if (!asin || asin === '') { return };

    // 2 - Make value empty
    this.setState({
      status: '',
      data: {},
      error: '',
      isProcessing: true,
    });

    // 3 - Call api
    getProductCategoriesForASIN(market, asin, this.getProductCategoriesForASIN_Success, this.getProductCategoriesForASIN_Error);
  }

  // Called when api call success
  getProductCategoriesForASIN_Success = (result) => {
    console.log("GetProductCategoriesForASIN_Success() result:", result);
    
    const { status, data, error } = result;
    
    // If received array then assign array directly, 
    // otherwise create single element array.
    const stateUpdates = {};
    if ( Array.isArray(data.Self) ) { // array
      stateUpdates['self'] = data.Self;
    } else {  // Single data object
      stateUpdates['self'] = [ data.Self ];
    }

    // Below information is common for single data object or array result.
    if (data.Headers) {
      stateUpdates['headers'] = data.Headers;
    }
    if (data.ResponseMetadata) {
      stateUpdates['responseMetadata'] = data.ResponseMetadata;
    }
    if (data.statusCode) {
      stateUpdates['statusCode'] = data.statusCode;
    }

    // Udpate value to state
    this.setState({
      ...stateUpdates,
      status: status,
      error: error,
      isProcessing: false,
    });
  }

  // Called if error while api call
  getProductCategoriesForASIN_Error = (error) => {
    console.log("getProductCategoriesForASIN_Error() error: ", error);
    
    this.setState({
      isProcessing: false,
    });
  }


  renderHeaderInfo = (headers) => {
    const keys = Object.keys(headers);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {headers[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  renderResponseMetaData = (responseMetadata) => {
    const keys = Object.keys(responseMetadata);
    const elements = keys.map((key, index) => {
      return(
        <div key={key}>
          <b>{key}</b>: {responseMetadata[key]} <br />
        </div>);
    });
    return(<>{elements}</>);
  }

  // Render category row recursively until parent found
  renderCategoryRow = (self) => {
    return (
      <div style={{"marginLeft": "25px", "marginTop": "10px"}}>
        <div>{ '/' + self.ProductCategoryName + ' (' + self.ProductCategoryId + ')' }</div>
        { self.Parent && this.renderCategoryRow(self.Parent) }
      </div>
    ) 
  } 

  // Render all category within given array
  // Each record consist parent category tree.
  renderSelfList = (self) => {
    const list = self.map( (singleSelf, index) => {
      return (
        <div key={ 'category-self' + index }>
          <b># { index + 1 }</b> <br />
          { this.renderCategoryRow(singleSelf) }
          <br />
        </div>
      );
    });
    return list;
  }

  renderResult = () => {
    const { self, status, error, headers, responseMetadata } = this.state;
    return (
      <div align='left'>
        { status !== '' && 
          <><b>Status:</b> {status}<hr /></>
        }
        { self.length > 0 && this.renderSelfList(self) }
        { responseMetadata && 
          <><h3>Response Header:</h3>{ this.renderHeaderInfo(headers) }<hr /></>
        }
        { responseMetadata && 
          <><h3>Response Metadata:</h3>{ this.renderResponseMetaData(responseMetadata) }<hr /></>
        }
        { error &&
          <>
            <b>Error:</b>
            <div style={{width: '100%', overflow: 'scroll', border: '0px solid #cdcdcd' }}>
              { error && error.toString() }
            </div>
          </>
        }
      </div>
    );
  }


  renderForm = () => {
    const { isProcessing } = this.state;
    const { classes } = this.props;
    
    return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
                <Select
                  id="market"
                  labelId="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({ market: e.target.value })}
                  disabled={isProcessing}
                >
                  { // Dynamically generate options
                    markePlacesKeys.map((key, index) => {
                      const market = marketPlaces[key];
                      return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>  
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <TextField 
                  id="asins3" 
                  label="ASIN (single)" 
                  placeholder="" 
                  value={this.state.asin} 
                  onChange={(e) => this.setState({ asin: e.target.value })} 
                  size="small" 
                  disabled={isProcessing} 
                />
              </FormControl>
          </Grid>
        </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        {isProcessing &&
          <b>Processing... &nbsp;&nbsp;&nbsp; </b>
        }
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={isProcessing}
        >Submit</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>MWS - GetProductCategoriesForASIN</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            { this.renderFormButton() }
            { this.renderResult() }
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
  
}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


GetProductCategoriesForASIN.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(GetProductCategoriesForASIN);
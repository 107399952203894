import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";


// Import custom ui component
//import PageHeading from './common/PageHeading';
import TopNavBar from './common/TopNavBar';

// Inventory tracker related components
import InventoryTracker from './tracker/InventoryTracker';

class TrackerPage extends React.Component { 

  componentDidMount = () => {
    console.log('TrackerPage - componentDidMount()');

    // Added for debug purpose 
    // let allProps = this.props;
    // console.log('allProps:', allProps);
    // let { location } = this.props;
    // console.log('location:', location);
  }

  componentWillUnmount = () => {
    console.log('TrackerPage - componentWillUnmount()');
  }

  render() {
    return(
      <div>
        <TopNavBar activeTab='tracker' />
        { /* <PageHeading title="Inventory Tracker" /> */ }
        <InventoryTracker />
        <br />
      </div>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withRouter(withStyles(styles)(TrackerPage));


import React from "react";
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";

import { getUserType } from '../../helpers/firebaseHelper';


class TopNavBar extends React.Component { 

  state = {
    userType: '',
  }

  componentDidMount = () => {
    //console.log('TopNavBar - componentDidMount()');
    getUserType(this.getUserTypeSuccess, this.getUserTypeError);
  }

  componentWillUnmount = () => {
    //console.log('TopNavBar - componentWillUnmount()');
  }

  getUserTypeSuccess = (userType) => { this.setState({ userType: userType }) }
  getUserTypeError = (error) => { /* Do nothing */ }


  render() {
    const { userType } = this.state;
    return(
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonGroup fullWidth color="default" aria-label="outlined default button group">
              <Button color={ this.props.activeTab === 'tracker' ? "primary" : 'default' } variant="outlined" size="medium" component={Link} to="/tracker">
                Inventory Tracker
              </Button>
              <Button color={ this.props.activeTab === 'competition' ? "primary" : 'default' } variant="outlined" size="medium" component={Link} to="/competition">
                Check Competition
              </Button>
              <Button color={ this.props.activeTab === 'profit-calculator' ? "primary" : 'default' } variant="outlined" size="medium" component={Link} to="/profit-calculator">
                Profit Calculator
              </Button>
              <Button color={ this.props.activeTab === 'potential-product' ? "primary" : 'default' } variant="outlined" size="medium" component={Link} to="/potential-product">
                Potential Product
              </Button>
              { userType === 'admin' &&
              <Button color={ this.props.activeTab === 'tracker-admin' ? "primary" : 'default'} variant="outlined" size="medium" component={Link} to="/tracker-admin">
                Tracking (Admin)
              </Button>
              }
              { userType === 'admin' &&
              <Button color={ this.props.activeTab === 'others' ? "primary" : 'default'} variant="outlined" size="medium" component={Link} to="/others">
                Others (Admin)
              </Button>
              }
              <Button color={ this.props.activeTab === 'seller-config' ? "primary" : 'default'} variant="outlined" size="medium" component={Link} to="/seller-config">
                Seller Config
              </Button>
              <Button color={ this.props.activeTab === 'ad-automation' ? "primary" : 'default'} variant="outlined" size="medium" component={Link} to="/ad-automation">
                Advertise Automation
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <div style={{height: 10}} ></div>
      </React.Fragment>
    )
  }

}


const styles = (theme) => ({

})

//export default App;
export default withStyles(styles)(TopNavBar);



import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

import LinearProgress from '@material-ui/core/LinearProgress';


// Import constant and helper functions
import { 
  loginWithEmailPassword, 
  isCurrentUserEmailVerified,
  getCurrentUserEmail,
  logoutCurrentUser,
  sendVerificationEmail,
} from '../../helpers/firebaseAuthHelper';


class EmailPasswordLogin extends React.Component {  

  state = {
    isProcessing: false,

    email: '',
    password: '',
    
    // Show normal message text
    showBanner: false,
    bannerTitle: '',

    // Show Email address not verified dialogue with 
    // Resend Email and Ok button
    // true - show dialogue, false - hide dialogue
    dialogVisible: false,
    isResending: false,
  };


  // Handle form submit event
  handleSubmit = () => {
    //event.preventDefault();

    // 1 - Fetch form input
    const { email, password } = this.state;

    // 2 - If email/password not exist or empty then return.
    if ( !email || email === "" || !password || password === ""  ) {
      console.log("Email/password empty, so return.");
      return;
    }
    
    // 3 - Set processing on 
    this.setState({
      isProcessing: true,
      showBanner: false,
    });

    // 4 - Do login via helper function
    loginWithEmailPassword(email, password, this.onLoginSuccess, this.onLoginError);
  };


  // If login successfully, this function called via callback.
  onLoginSuccess = () => {
    console.log('EmailPasswordLogin - onLoginSuccess()');
    
    // 1 - Set processing false
    this.setState({
      isProcessing: false,
    });

    // 2 - Allow test@test.com login although email not verified
    // NOTE: we will remove this logic later on
    const email = getCurrentUserEmail();
    if ( email === 'test@test.com' ) {
      return; // IMPORTANT
    } 

    // 3 - Check that current user's email verified or not.
    // Current user object is required to check this status, at this  
    // momement current logged in user exist, so we can call this function
    // to get email verified status.
    const emailVerified = isCurrentUserEmailVerified();

    // 4 - If email not verified then show email verification dialogue.
    if (!emailVerified) { // Email not verified
      this.showVerificationDialog();
    }

  }

  // If error while login, this function called via callback
  onLoginError = (error) => {
    console.log('EmailPasswordLogin - onLoginError()');

    // 1 - Set processing false, so it will enable submit button.
    this.setState({
      isProcessing: false,
    });
    
    // 2 - Fetch error code and message
    const errorCode = error.code;
    const errorMessage = error.message;

    // Debug
    console.log("errorCode:", errorCode);
    console.log("errorMessage:", errorMessage);
    
    // 3 - Show error message based on the error code.
    if (errorCode === 'auth/user-not-found') {
      this.showError('User not exist with given email.');
    } else if (errorCode === 'auth/wrong-password') {
      this.showError('The password is invalid.');
    } else if (errorCode === 'auth/invalid-email') {
      this.showError('The email address is badly formatted.');
    } else {
      //this.showError('Login error, please try again.');
      this.showError(errorMessage);
    }
  }


  // Show email verification dialogue
  showVerificationDialog = () => {
    this.setState({
      dialogVisible: true,
      isResending: false,
    });
  }


  // Hide email verification dialogue
  hideVerificationDialog = () => {
    this.setState({
      dialogVisible: false,
      isResending: false,
    });
  }


  // Ok button tapped from email verification dialogue
  onClickOk = () => {
    //console.log('EmailPasswordLogin - onClickOk()');

    // Important: Logout current user
    logoutCurrentUser();

    // Hide verification dialogue
    this.hideVerificationDialog();
  }


  // Resend Email button tapped from email verification dialogue
  onClickResendEmail = async () => {
    //console.log('EmailPasswordLogin - onClickResendEmail()');

    // 1 - Show resending activity indicator
    this.setState({
      isResending: true,
    })

    // 2 - Resend email address verification email via firebase api.
    // We need current logged in user to send verification email.
    // At this moment user status is logged in so current user exist, 
    // If current logged in user not exist then it will return false.
    const isSent = await sendVerificationEmail();
    
    // 3 - Show message based on the sent status
    let message = 'Verification link send error, Please try again.';
    if (isSent) { // Sent successfully
      const email = getCurrentUserEmail();
      message = 'Verification link re-sent to ' + email;
    } 
    this.showError(message);

    // 4 - Logout current user (Important)
    logoutCurrentUser();

    // 5 - Hide verificatin dialogue
    this.hideVerificationDialog();
  }


  // Show error message with given title (message text)
  showError = (title) => {
    this.setState({
      showBanner: true,
      bannerTitle: title,
      isProcessing: false,
    });
  }

  // Hide message banner
  hideBanner = () => {
    this.setState({
      showBanner: false,
      bannerTitle: '',
      isProcessing: false,
    });
  }


  render() {
    const { email, password, isProcessing } = this.state;
    const { showBanner, bannerTitle, isResending } = this.state;

    return (
        <div style={{padding: 20}} align='center' >

          <Typography variant="h6" component="h6" gutterBottom >
            Login with Email / Password
          </Typography>
          <form>
              <TextField
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
                label="Email"
                type="email"
                autoComplete="email"
                //maxLength="200"
                style={{width: '100%'}}
              />
              <br /><br />
              <TextField
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
                label="Password"
                type="password"
                //maxLength="100"
                autoComplete="current-password"
                style={{width: '100%'}}
              />
              <br /><br />
              <Button 
                variant="contained"
                size="medium"
                onClick={this.handleSubmit} 
                disabled={isProcessing}
                //style={{marginRight: 20 }} 
              > 
                &nbsp; Login &nbsp;
              </Button>
          </form>
          
          { showBanner &&
            <h4 style={{ marginTop: 20 }} >{bannerTitle}</h4>
          }

          { isProcessing && 
            <LinearProgress style={{ marginTop: 10, }} />
          }

          <Dialog
            open={this.state.dialogVisible}
            onClose={this.hideDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Email Not Verified</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please verify email address ({ this.state.email }) to login.
              </DialogContentText>
              { isResending && 
              <LinearProgress style={{ marginTop: 10, }} />
              }
            </DialogContent>
            <DialogActions>
              <Button 
                mode="contained"
                color="primary"
                size="small"
                onClick={this.onClickResendEmail} 
                disabled={isResending}
              >
                Re-send Email 
              </Button>
              <Button 
                onClick={this.onClickOk} 
                color="primary" 
                disabled={isResending}
                autoFocus
                style={{marginLeft: 20}}
                >
                OK
              </Button>
            </DialogActions>
          </Dialog>

        </div>
    );
  }
}

export default EmailPasswordLogin;
// Amazon Advertising Api: 
// Sponsored Products - Get keyword recommendations ranked by user-provided metric
// POST: ​/sp/targets/keywords/recommendation
// 
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi/prod#/


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';


// Import helper functions, constant etc.
import { spGetKeywordsRecommendationForAsins } from '../../helpers/amazonAdHelper';
//import { getProfiles } from '../../helpers/amazonAdHelper';
import { marketPlaces } from '../../helpers/constants';
const markePlacesKeys = Object.keys(marketPlaces);


class SPGetKeywordsRecommendationForAsins extends React.Component {
  
  state = {

    // Start: ------ Form input data -------
    profileId: '',    // Selected profile id
    
    // e.g. 'CA', 'US' etc.
    market: '',

    advertiserId: '',

    // Comma separated list of asins
    // e.g. asin1, asin2, asin3 etc.
    asins: '', 
    // End: ------ Form input data -------

    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],

    // We will fetch recommended keyword list and set here.
    // e.g. 
    // keywordList: [
    //   {
    //     "matchType": "BROAD",
    //     "keyword": "string",
    //     "bid": 0,
    //     "suggestedBid": {
    //       "suggested": 0,
    //       "rangeStart": 0,
    //       "rangeEnd": 0
    //     },
    //     "translation": "string",
    //     "rank": 0
    //   }
    // ],
    keywordList: null,
    

    // If any error occur during some operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SPGetSuggestedKeywordsForAsins - componentDidMount()');

    // Fetch amazon profile list
    // Commented: because we passed profileList as props from parent component 
    //this.fetchProfileList();
  }

  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass profile Id to server side api, so first 
  // we will fetch profile list and fillup drop down so user can choose it.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    //getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      profileId: '',
      market: '',
      advertiserId: '',
      asins: '',
      keywordList: null,

      isProcessing: false,
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Fetch data via api
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Get profileId from the state
    const { profileId, market, advertiserId, asins } = this.state;

    // 2 - If paremeter empty then return
    if ( !profileId || profileId === '' ) { return; }
    if ( !market || market === '' )       { return; }
    if ( !advertiserId || advertiserId === '' )  { return; }
    if ( !asins || asins === '' )         { return; }

    // 3 - Convert comma separated asins in to array object
    // Remove the empty element from the comma separated string
    let asinArray = [];
    const tempArray = asins.split(','); // convert comma separated string to array
    tempArray.forEach( (item, index) => {
      const tempItem = item.trim(); // Remove white space from the element.
      if ( tempItem !== "" ) { // If not empty then push element into array
        asinArray.push(tempItem);
      }
    });
    console.log('asinArray:', asinArray);

    // 3A - If asin array empty then return.
    if ( !asinArray || asinArray.length === 0 ) { 
      return;
    }

    // 4 - Show processing
    this.setState({
      isProcessing: true,
      keywordList: null,
      error: '',
    });

    // 5 - Call api to get keyword list
    // @profileId:  Number
    // @market: String  e.g. 'CA', 'US' etc.
    // @advertiserId: String
    // @asinArray: Array[String] e.g. [ 'asin1', 'asin2', ... ]
    // @maxRecommendations: Integer 0-100 
    // @sortDimension: String e.g. CLICKS, CONVERSIONS, DEFAULT
    // @locale: String: e.g. zh_CN (for US, UK, CA) en_GB (for DE, FR, IT, ES) 
    const maxRecommendations = 100;
    const sortDimension = 'DEFAULT';
    const locale = 'zh_CN';
    
    spGetKeywordsRecommendationForAsins( profileId, market, advertiserId, 
                                      asinArray, maxRecommendations, sortDimension, locale,  
                                      this.spGetKeywordsRecommendationForAsins_Success,  
                                      this.spGetKeywordsRecommendationForAsins_Error);  
    
  }

  // Called if recommended keyword list fetched successfully
  // e.g. result: { 
  //   status: 'success', 
  //   data: [
  //    {
  //       "matchType": "BROAD",
  //       "keyword": "string",
  //       "bid": 0,
  //       "suggestedBid": {
  //         "suggested": 0,
  //         "rangeStart": 0,
  //         "rangeEnd": 0
  //       },
  //       "translation": "string",
  //       "rank": 0
  //     }
  //   ],
  //   error: null
  // }
  spGetKeywordsRecommendationForAsins_Success = (result) => {
    console.log('spGetKeywordsRecommendationForAsins_Success() result:', result);

    if ( result.status === 'success' ) {
      this.setState({
        keywordList: result.data,
        isProcessing: false,
      });
    }

    if ( result.status === 'error' ) {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while fetch suggested keyword list
  spGetKeywordsRecommendationForAsins_Error = (error) => {
    console.log('spGetKeywordsRecommendationForAsins_Error() error:', error);
    
    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch data via api
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  // Called whenever user will select profile id from dropdown
  onChangeProfileId = (profileId) => {
    //console.log('onChangeProfileId() profileId:', profileId);

    this.setState({
      profileId: profileId,
      keywordList: null,
      error: '',
    });
  }
  
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-profile-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="choose-profile-label"
              value={profileId}
              onChange={ (e) => this.onChangeProfileId(e.target.value) }
              disabled={ isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { id, type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {id} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
            <Select
              id="market"
              labelId="market"
              value={this.state.market}
              onChange={(e) => this.setState({ market: e.target.value })}
              disabled={isProcessing}
            >
              { // Dynamically generate options
                markePlacesKeys.map((key, index) => {
                  const market = marketPlaces[key];
                  return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                })
              }
            </Select>
          </FormControl>  
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="advertiserId" 
              label="advertiserId (???)" 
              placeholder="advertiserId" 
              value={this.state.advertiserId} 
              onChange={ (e) => this.setState({ advertiserId: e.target.value }) } 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
            />
          </FormControl>
        </Grid>


        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="keywords-recommend-asins" 
              label="ASINS (comma separated) max. 50 asin" 
              placeholder="asin1,asin2,asin3" 
              value={this.state.asins} 
              onChange={ (e) => this.setState({ asins: e.target.value }) } 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
            />
          </FormControl>
        </Grid>

      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId, market, asins } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' || market === '' || asins.length < 4 } 
        >Get Recommended Keyword List</Button>
        <Button  
          variant="contained"  
          size="small"  
          onClick={this.onClickClear}
          disabled={isProcessing}  
        >Clear</Button>  
        <h3>Under Construction - Not Finalsed Yet</h3>
      </div> 
    );
  }
  
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { keywordList, profileId } = this.state;

    // If list variable not exist then do not show anything
    if ( !keywordList ) { return null; }

    // If list empty array then show message.
    if ( keywordList.length === 0 ) {
      return ( 
        <div align='left'>
          <h3>Recommended keyword list empty - profileId: { profileId }</h3>
        </div>
      );
    }

    // If list exist then render its content
    // e.g. 
    //   keywordList: [
    //    {
    //       "matchType": "BROAD",
    //       "keyword": "string",
    //       "bid": 0,
    //       "suggestedBid": {
    //         "suggested": 0,
    //         "rangeStart": 0,
    //         "rangeEnd": 0
    //       },
    //       "translation": "string",
    //       "rank": 0
    //     }
    //   ]
    return (
      <div align='left'>
        <b>Recommended Keyword List - ProfileId: { profileId } </b> <br />
        <hr />
        { 
          keywordList.map( (item, index) => {
            return (
              <div key={ 'recomment_keyword_' + index } >
                <b>#{ index + 1 }</b> <br />
                <b>matchType:</b> { item.matchType } <br />
                <b>keyword:</b> { item.keyword } <br />
                <b>bid:</b> { item.bid } <br />
                <b>suggestedBid:</b> { JSON.stringify(item.suggestedBid) } <br />
                <b>translation:</b> { item.translation } <br /> 
                <b>rank:</b> { item.rank } <br />
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Get Keywords Recommendation For Asins</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SPGetKeywordsRecommendationForAsins.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SPGetKeywordsRecommendationForAsins);


// Tracker list component

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import SalesIcon from '@material-ui/icons/DataUsage';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { getMediumDate } from '../../helpers/utility';
//import { marketPlaces } from '../../helpers/constants';


class TrackerListAdmin extends React.Component {
  
  state = {

    // Used to show tracking status for clicked record
    showTrackingStatusDialogue: false,
    trackingStatusReason: '',
    trackingStatusDate: '',
    trackingStatusAsin: '',
    trackingStatusMarket: ''
  }

  componentDidMount = () => {
    //console.log('TrackerListAdmin - componentDidMount()');
  }

  // Inform parent component that tracking raw data button clicked
  onClickRawData = (id, index) => {
    //console.log('onClickRawData() id:', id, ' index:', index);
    this.props.onClickRawData(id, index);
  }

  // Inform parent component that sales data button clicked
  onClickSalesData = (id, index) => {
    //console.log('onClickSalesData() id:', id, ' index:', index);
    this.props.onClickSalesData(id, index);
  }

  
  // Show dialogue with tracking status reason and date etc.
  onClickTrackingStatus = (statusReason, statusDate, market, asin) => {
    //console.log('onClickTrackingStatus()');
    
    this.setState({
      showTrackingStatusDialogue: true,
      trackingStatusReason: statusReason,
      trackingStatusDate: statusDate,
      trackingStatusAsin: asin,
      trackingStatusMarket: market,
    });
  }

  // Called when status dialogue closed.
  handleCloseStatusDialogue = () => {
    this.setState({
      showTrackingStatusDialogue: false,
      trackingStatusReason: '',
      trackingStatusDate: '',
      trackingStatusAsin: '',
      trackingStatusMarket: '',
    });
  }


  render() {
    const { classes } = this.props;
    const { trackerList } = this.props;
    
    const { showTrackingStatusDialogue, trackingStatusReason, trackingStatusDate, 
      trackingStatusAsin, trackingStatusMarket } = this.state;

    const currentDate = new Date();

    return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Market</TableCell>
              <TableCell align="left">ASIN</TableCell>
              <TableCell align="center">Reason</TableCell>
              <TableCell align="right">Tracking Data<br />(Raw)</TableCell>
              <TableCell align="right">Sales Data</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">RequestId<br/>UID<br/>Tracker End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              trackerList.map( (row, index) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell align="left">
                    { row.small_image_url ?
                      <img src={row.small_image_url} width="40" alt={row.Title} />
                      :
                      <div style={{width: 60, height: 40, border: '1px solid #efefef'}} >&nbsp;</div>
                    }
                    </TableCell>
                    <TableCell align="left">
                      { /* marketPlaces[row.market].country_name */ }
                      { row.market }
                    </TableCell>
                    <TableCell align="left">{row.asin}</TableCell>
                    <TableCell align="center">
                      { (row.tracking_status_reason && row.tracking_status_reason !== '') ?
                        <IconButton 
                          aria-label="RawData" 
                          className={classes.margin}
                          onClick={() => this.onClickTrackingStatus(row.tracking_status_reason, row.tracking_status_date, row.market, row.asin) } 
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                        :
                        <IconButton className={classes.margin} disabled={true} >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell align="right">
                      <IconButton 
                        aria-label="RawData" 
                        className={classes.margin}
                        onClick={() => this.onClickRawData(row.id, index) } 
                      >
                        <HistoryIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton 
                        aria-label="SalesData" 
                        className={classes.margin}
                        onClick={() => this.onClickSalesData(row.id, index) } 
                      >
                        <SalesIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      { currentDate > row.to_date ? <>(Expired)</> : <>Active</> }
                    </TableCell>
                    <TableCell align="right">
                      { row.id } <br />
                      { row.uid } <br />
                      { getMediumDate(row.to_date) }
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
          fullWidth={false}
          maxWidth="lg"
          open={showTrackingStatusDialogue}
          onClose={this.handleCloseStatusDialogue}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          aria-labelledby="tracking-status-dialogue"
        >
          <DialogTitle id="max-width-dialog-title" style={{ verticalAlign: 'text-top' }} >
            Tracking Status: &nbsp; { trackingStatusMarket } &nbsp; { trackingStatusAsin }
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <b>Reason:</b> { trackingStatusReason } <br /><br />
              <b>Reason Update Date:</b> { trackingStatusDate && getMediumDate(trackingStatusDate) }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStatusDialogue} color="primary">
              Close
            </Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
    );
  }
}

const styles = (theme) => ({

  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  table: {
    minWidth: 650,
  },

});


TrackerListAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(TrackerListAdmin);
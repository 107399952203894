//-------------------------------------------------------------------
// Export 
//-------------------------------------------------------------------
export const restrictedCategoriesDefault = {
  1: 'Alcohol',
  2: 'Animals & Animal-Related Products',
  3: 'Art - Fine Art',
  4: 'Art - Home Decor',
  5: 'Automotive and Powersports',
  6: 'Composite Wood Products',
  7: 'Cosmetics & Skin/Hair Care',
  8: 'Currency, Coins, Cash Equivalents, and Gift Cards',
  9: 'Dietary Supplements',
  10: 'Drugs & drug paraphernalia',
  11: 'Electronics',
  12: 'Explosives, Weapons, and Related Items',
  13: 'Export Controls',
  14: 'Food & Beverage',
  15: 'Gambling & Lottery',
  16: 'Hazardous and Dangerous Items',
  17: 'Human Parts & Burial Artifacts',
  18: 'Jewelry & Precious Gems',
  19: 'Laser products',
  20: 'Lighting',
  21: 'Lock Picking & Theft Devices',
  22: 'Medical devices and accessories',
  23: 'Offensive and Controversial Materials',
  24: 'Pesticides and Pesticide Devices',
  25: 'Plant and Seed Products',
  26: 'Postage meters and stamps',
  27: 'Recalled Products',
  28: 'Recycling electronics',
  29: 'Subscriptions and Periodicals',
  30: 'Surveillance Equipment',
  31: 'Tobacco & Tobacco-Related Products',
  32: 'Warranties, Service Plans, Contracts, and Guarantees',
  33: 'Other Restricted Products',
  34: 'Refrigerants: Ozone-Depleting Substances and Substitutes',
  35: 'Upholstered Furniture, Bedding, & Other Quilted Products',
  36: 'CPAP Cleaning and Disinfecting Devices'
} 

import React from "react";
import { withStyles } from '@material-ui/core/styles';

// Import custom component
import TopNavBar from './common/TopNavBar';
import Competition from './competition/Competition';


class CompetitionPage extends React.Component { 

  componentDidMount = () => {
    console.log('CompetitionPage - componentDidMount()');
  }

  componentWillUnmount = () => {
    console.log('CompetitionPage - componentWillUnmount()');
  }

  render() {
    return(
      <div>
        <TopNavBar activeTab='competition' />
        <Competition />
      </div>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(CompetitionPage);


// Edit flow component - Modal Screen

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
// import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';

//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


// Import helper funcion
import { 
  spGetCampaigns, spGetAdGroups, spGetKeywords, spGetNegativeKeywords, spGetProductAds 
} from '../../helpers/amazonAdHelper';

import { getMatchingProductForId } from '../../helpers/mwsHelper';

// Import firebase 
import firebase from '../../helpers/firebaseApp';
const db = firebase.firestore();


class SpAdAutomationEdit extends React.Component { 

  // Default state
  state = {

    // true - show debug info, false - hide debug info
    showDebug: true, 

    // Start: ----- Form input -----
    // Type of campaign 
    automaticCampaignChecked: false,
    broadAdGroupChecked: false,
    phraseAdGroupChecked: false,
    exactAdGroupChecked: false,

    // If automatic campaign exist within db then we will disable checkbox for that
    // so user can not uncheck it.
    disableAutomaticCheckbox: false, 
    disableBroadAdGroupCheckbox: false, 
    disablePhraseAdGroupCheckbox: false, 
    disableExactAdGroupCheckbox: false, 

    // Bid amount for each group type, If some ad group not created then we 
    // will show text input while user select checkbox to create that ad gorup.
    // So user can set bid amount that that ad group.
    // Note: It may happen that all below fields not used all time, so depending 
    // on the need to create new adgroup we will use some fields from below.
    bidAmountAutomatic: 0.5,    // Default 0.5
    bidAmountBroad: 0.6,        // Default 0.5
    bidAmountPhrase: 0.7,       // Default 0.5
    bidAmountExact: 0.8,        // Default 0.8

    // Budget for automatic campaign (input by user)
    // Note: If automatic campaign not created yet, and If need to create automatic 
    // campaign during save operation then we will use below field to take use input.
    campaignBudgetAutomatic: '', 

    // Budget for manual campaign (input by user)
    // Note: If manual campaign not created yet, and If need to create manual campaign
    // during save operation then we will use below field to take use input.
    campaignBudgetManual: '', 

    // Automatic Campaign - Negative keyword Input
    // Used if user is creating automatic campaign during edit time.
    // i.e. During flow creation, automaic campaign not created but now
    // he is interested to create it.
    negativeKeywordInputAutomatic: '',  // Text area - One keyword per line

    // Manual Broad - Keyword and Negative Keyword Input (Manual Campaign)
    // Used if user is creating Broad Ad Group during edit.
    // i.e. During flow creation, Broad Ad Group not created but now
    // he is interested to create it.
    keywordInputBroad: '',          // Multiline - One keyword per line
    negativeKeywordInputBroad: '',  // Multiline - One keyword per line

    // Manual Phrase - Keyword and Negative Keyword Input (Manual Campaign)
    // Used if user is creating Phrase Ad Group during edit.
    // i.e. During flow creation, Phrase Ad Group not created but now
    // he is interested to create it.
    keywordInputPhrase: '',          // Multiline - One keyword per line
    negativeKeywordInputPhrase: '',  // Multiline - One keyword per line

    // Manual Phrase - Keyword Input (Manual Campaign)
    // Used if user is creating Exact Ad Group during edit time.
    // i.e. During flow creation, Exact Ad Group not created but now
    // he is interested to create it.
    keywordInputExact: '',          // Multiline - One keyword per line

    // End: ----- Form input -----


    // True - Open current modal, false - hide it
    isOpen: true,

    // We will fetch flow related data from db and set here.
    // e.g. flowDataDb: { key1:value1, key2:{ }, key3:[], key4:value4, ... }
    flowDataDb: null,

    // We will fetch more info about campaign via amazon api and 
    // set it here for further use.
    campaignListApi:[],

    // We will fetch more info about ad group created for campaign and 
    // set it here for further use.
    adGroupListApi: [],

    // We will fetch keywords created for automatic and manual campaign 
    // and set it here for further use.
    keywordListApi: [],

    // We will fetch negative keywords created for automatic and manual 
    // campaign and set it here for further use.
    negativeKeywordListApi: [],

    // We will fetch product ads created under automatic and manual 
    // campaign and set it here.
    productAdListApi: [],

    // We will search products detial info via amazon api and set it here.
    // This information will be used to show product title thumb image etc.
    // e.g. productsInfoApi = [ { key: value, key: value }, ... ]
    // i.e. productsInfoApi: [ 
    //   asin1: {
    //      ASIN: 'asin1', 
    //      MarketplaceId: 'CA', 
    //      Title: 'product title',
    //      SmallImage: null,
    //  },
    //   asin2: {
    //      ASIN: 'asin1', 
    //      MarketplaceId: 'CA', 
    //      Title: 'product title',
    //      SmallImage: null,
    //  }
    // ],
    productsInfoDictApi: {},


    // Some processing in progress or not.
    isProcessing: false, 

    // Message text
    showMessage: false,
    messageText: "",
    errorMessage: "",
    
  }

  // Called when component mounted
  componentDidMount = () => {
    console.log('SpAdAutomationEdit - componentDidMount() flowDocId:', this.props.flowDocId)

    // Fetch flow data from db.
    this.fetchFlowDataDb();
  }

  componentWillUnmount = () => {
    console.log('SpAdAutomationEdit - componentWillUnmount()');
  }


  // Called when close button tapped
  // We will close the current model and inform parent about close.
  handleClose = () => {
    //console.log('SpAdAutomationEdit - handleClose()');

    // Close current modal
    this.setState({
      isOpen: false,
    });

    // Inform parent component that close button tapped from modal,
    // so it will unload current component from its render list.
    if (this.props.onEditFlowClose) {
      this.props.onEditFlowClose();
    }

  }; 


  //-----------------------------------------------------------------
  // Start: Data fetch related functions
  //-----------------------------------------------------------------
  // Fetch flow related data from db 
  fetchFlowDataDb = () => {
    //console.log('SpAdAutomationEdit - fetchFlowDataDb()');

    const { flowDocId } = this.props;

    // Fetch flow info from db
    db.collection('sponsored_product_ad').doc(flowDocId).get()
    .then( (doc) => {
      
      if (!doc.exists) {
        return;
      }

      // Grab required data
      const flowData = {};
      const data = doc.data();
      flowData['flow_name'] = data.flow_name;
      flowData['profile_id'] = data.profile_id;
      flowData['profile_type'] = data.profile_type;
      flowData['api_mode'] = data.api_mode; // 'DUMMY' or 'REAL'

      flowData['products'] = data.products ? data.products : [];

      flowData['auto_campaign_id'] = data.auto_campaign_id ? data.auto_campaign_id : null;
      flowData['auto_ad_group_id'] = data.auto_ad_group_id ? data.auto_ad_group_id : null;

      flowData['manual_campaign_id'] = data.manual_campaign_id ? data.manual_campaign_id : null;
      flowData['manual_broad_ad_group_id'] = data.manual_broad_ad_group_id ? data.manual_broad_ad_group_id : null;
      flowData['manual_phrase_ad_group_id'] = data.manual_phrase_ad_group_id ? data.manual_phrase_ad_group_id : null;
      flowData['manual_exact_ad_group_id'] = data.manual_exact_ad_group_id ? data.manual_exact_ad_group_id : null;
      
      flowData['auto_status'] = data.auto_status;
      flowData['manual_status'] = data.manual_status;
      flowData['status'] = data.status;

      // Debu
      console.log('flowData:', flowData);

      // Prepare data to update state
      const stateUpdateData = {};
      stateUpdateData['flowDataDb'] = flowData;
      stateUpdateData['automaticCampaignChecked'] = data.auto_ad_group_id ? true : false;
      stateUpdateData['broadAdGroupChecked'] = data.manual_broad_ad_group_id ? true : false;
      stateUpdateData['phraseAdGroupChecked'] = data.manual_phrase_ad_group_id ? true : false;
      stateUpdateData['exactAdGroupChecked'] = data.manual_exact_ad_group_id ? true : false;

      stateUpdateData['disableAutomaticCheckbox'] = data.auto_ad_group_id ? true : false;
      stateUpdateData['disableBroadAdGroupCheckbox'] = data.manual_broad_ad_group_id ? true : false;
      stateUpdateData['disablePhraseAdGroupCheckbox'] = data.manual_phrase_ad_group_id ? true : false;
      stateUpdateData['disableExactAdGroupCheckbox'] = data.manual_exact_ad_group_id ? true : false;

      // Update data within state
      this.setState(stateUpdateData);

      // Fetch necessary data via amazon ad api (if campaign created via REAL api mode)
      if (flowData.api_mode === 'REAL') {
        this.fetchDataViaAmazonApi();
      }

    })
    .catch( (error) => {
      console.log("Error fetching flow doc from db. error:", error);
    });
  }

  // Fetch necessary data via amazon ad api
  fetchDataViaAmazonApi = () => {
    console.log('fetchDataViaAmazonApi()');

    // Fetch campaign info via amazon api
    this.fetchCampaignListViaApi();

    // Fetch ad group info via amazon api
    this.fetchAdGroupListViaApi();

    // Fetch keyword list via amazon api
    this.fetchKeywordListViaApi();

    // Fetch negative keyword via amazon api
    this.fetchNegativeKeywordListViaApi();

    // Fetch product ad via amazon api
    this.fetchProductAdListViaApi();

    // Fetch product info via amazon api
    this.fetchProductsInfoViaApi();
  }

  
  // --------- START: Fetch Campaigns -------------
  // Fetch campaign info via amazon api
  fetchCampaignListViaApi = () => {
    //console.log('fetchCampaignListViaApi()');

    const { profile_id, auto_campaign_id, manual_campaign_id } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // It will give comma separated ids

    // 2 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/listCampaigns
    const queryData = {
      campaignIdFilter: campaignIds,
      //startIndex: 0,
      //count: 100,
      //stateFilter: 'enabled,paused,archived',
    }
    console.log('spGetCampaigns - queryData:', queryData);

    // 3 - Call api to Get sponsored products campaign list
    spGetCampaigns(profile_id, queryData, this.spGetCampaigns_Success, this.spGetCampaigns_Error);
  }

  // Called when campaign list fetched successfully
  spGetCampaigns_Success = (result) => {
    console.log('spGetCampaigns_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        campaignListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // show error message etc.
    }
  }

  // Called if any error while fetch campaign list
  spGetCampaigns_Error = (error) => {
    console.log('spGetCampaigns_Error() error:', error);
    
    // show error message etc.
  }
  // --------- END: Fetch Campaigns -------------
  
  // --------- START: Fetch Ad Groups -------------
  // Fetch ad group list via amazon api (i.e. list of ad group under given campaign id)
  fetchAdGroupListViaApi = () => {
    //console.log('fetchAdGroupListViaApi()');

    const { 
      profile_id, 
      auto_campaign_id, auto_ad_group_id, 
      manual_campaign_id, 
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id,
    } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 2 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Ad%20groups/getAdGroups
    const queryData = {
      campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
      //stateFilter: 'enabled,paused,archived',
      //startIndex: 0,
      //count: 100,
    }
    console.log('spGetAdGroups - queryData:', queryData);    

    // Call api to Get ad groups (list)
    spGetAdGroups(profile_id, queryData, this.spGetAdGroups_Success, this.spGetAdGroups_Error);
  }
  
  // Called when ad groups (list) fetched successfully
  spGetAdGroups_Success = (result) => {
    console.log('spGetAdGroups_Success() result:', result);

    if ( result.status === 'success' ) {
      this.setState({
        adGroupListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // Show error message etc
    }
  }

  // Called if any error while fetch ad groups (list)
  spGetAdGroups_Error = (error) => {
    console.log('spGetAdGroups_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Ad Groups ------------- 
   
  // --------- START: Fetch Keywords -------------
  // Fetch keyword list via amazon api (i.e. for given campaignId and adGroupId)  
  fetchKeywordListViaApi = () => {
    //console.log('fetchKeywordListViaApi()');

    const { profile_id, 
            auto_campaign_id, auto_ad_group_id,
            manual_campaign_id,
            manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
          } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/listKeywords
    const queryData = {
      //startIndex: 0,
      //count: 100,
      //stateFilter: 'enabled,paused,archived',
      campaignIdFilter: campaignIds,
      //adGroupIdFilter: adGroupIds,
    }
    console.log('spGetKeywords - queryData:', queryData);

    // 4 - Call api to Get keywords (list) for selected profile Id.
    spGetKeywords(profile_id, queryData, this.spGetKeywords_Success, this.spGetKeywords_Error);
  }

  // Called when keywords (list) fetched successfully
  spGetKeywords_Success = (result) => {
    console.log('spGetKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        keywordListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
    }
  }
  
  // Called if any error while fetch keywords (list)
  spGetKeywords_Error = (error) => {
    console.log('spGetKeywords_Error() error:', error);
    // Show error message etc.
  }  
  // --------- END: Fetch Keywords -------------

  // --------- START: Fetch Negative Keywords -------------
  // Fetch negative keyword list via amazon api (i.e. for given campaignId and adGroupId)    
  fetchNegativeKeywordListViaApi = () => {
    //console.log('fetchNegativeKeywordListViaApi()');

    const { profile_id, 
      auto_campaign_id, auto_ad_group_id,
      manual_campaign_id,
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
    } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/listNegativeKeywords
    const queryData = {
      // startIndex: 0,
      // count: 100,  
      //stateFilter: 'enabled,archived',
      campaignIdFilter: campaignIds,
      //adGroupIdFilter: adGroupIds,
    }
    console.log('spGetNegativeKeywords - queryData:', queryData);

    // 4 - Call api to Get negative keywords (list) for selected profile Id.
    spGetNegativeKeywords(profile_id, queryData, this.spGetNegativeKeywords_Success, this.spGetNegativeKeywords_Error);
  }

  // Called when negative keywords (list) fetched successfully
  spGetNegativeKeywords_Success = (result) => {
    console.log('spGetNegativeKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        negativeKeywordListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
    }
  }
  
  // Called if any error while fetch negative keywords (list)
  spGetNegativeKeywords_Error = (error) => {
    console.log('spGetNegativeKeywords_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Negative Keywords ---------------

  // --------- START: Fetch Product Ads -------------------
  // Fetch product ads via amazon api (for auto and manual campaign)
  fetchProductAdListViaApi = () => {
    //console.log('fetchProductAdListViaApi()');

    const { profile_id, 
            auto_campaign_id, auto_ad_group_id, 
            manual_campaign_id,
            manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id, 
          } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Product%20ads/listProductAds
    const queryData = {
      //startIndex: 0,
      //count: 100,
      //stateFilter: 'enabled,paused,archived',
      campaignIdFilter: campaignIds,
      //adGroupIdFilter: adGroupIds,
    }
    console.log('spGetProductAds - queryData:', queryData);

    // 4 - Call api to Get product ads (list) for selected profile Id.
    spGetProductAds(profile_id, queryData, this.spGetProductAds_Success, this.spGetProductAds_Error);
  }

  // Called when product ads (list) fetched successfully
  spGetProductAds_Success = (result) => {
    console.log('spGetProductAds_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        productAdListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
    }
  }
  
  // Called if any error while fetch product ads (list)
  spGetProductAds_Error = (error) => {
    console.log('spGetProductAds_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Product Ads ------------


  // --------- START: Fetch product info ------------
  fetchProductsInfoViaApi = () => {

    // 1 - Fetch data from state
    const { products } = this.state.flowDataDb;

    // 2 - If product list empty then return
    if (products.length === 0 ) { 
      return;
    }

    // 3 - Prepare comma separated list of asin 
    // e.g. asinArray = ['asin1','asin2','asin3']
    const asinArray = products.map( (item, index) => {
      return item.ASIN;
    });
    console.log('asinArray:', asinArray);

    // 4 - If asin array empty then return
    if (asinArray.length === 0 ) { return }

    // 5 - Prepare comma separated list of asin 
    // e.g. asinValues = 'asin1,asin2,asin3'
    const asinValues = asinArray.join(',');
    console.log('asinValues:', asinValues);

    // Fetch market value from first product
    // e.g. 'CA' etc.
    const market = products[0].market;
    if (!market) { return; }

    // 6 - Prepare data to pass the api
    const idType = 'ASIN';        // 'ASIN' or 'SellerSKU'
    const idValues = asinValues;  // e.g. 'asin1,asin2,asin3' etc.

    // 7 - Call api
    getMatchingProductForId(market, idType, idValues, this.fetchProductsInfoViaApi_Success, this.fetchProductsInfoViaApi_Error);
  }

  fetchProductsInfoViaApi_Success = (result) => {
    console.log("fetchProductsInfoViaApi_Success() result:", result);

    // 1 - If error then show error messsage and return
    const { status, error } = result;
    if ( status === 'error' ) { 
      // Show error message etc.
      return; // Important
    }

    // 2 - Read data from result
    const { data } = result;

    // 3 - If multiple product then data will be array of products
    var productsTemp = [];
    if ( Array.isArray(data) ) { // Array
      data.forEach( (singleData, index) => {
        if (singleData.Products) {
          if (singleData.Products.Product) {
            let product = { ...singleData.Products.Product };
            productsTemp.push(product);
          }
        }
      });
    } else { // Not array
      if (data.Products) { 
        let product = { ...data.Products.Product };
        productsTemp = [product];
      }
    }
    console.log('productsTemp:', productsTemp);

    // 4 - Prepare product data in generalise format
    var productDict = []; 
    productsTemp.forEach( (product, index) => {
      const { ASIN, MarketplaceId } = product.Identifiers.MarketplaceASIN;
      //const { SalesRank } = product.SalesRankings;
      const { ItemAttributes } = product.AttributeSets;
      const { Title, SmallImage } = ItemAttributes;
      
      // Prepare product data in generalise format
      const data = { 
        ASIN: ASIN, 
        MarketplaceId: MarketplaceId, 
        Title: Title,
        SmallImage: SmallImage,
      }

      // Insert data to dictionary
      productDict[ASIN] = data;
    });

    console.log('productDict:', productDict);

    // Set data within state
    this.setState({
      productsInfoDictApi: productDict,
    });
  }

  fetchProductsInfoViaApi_Error = (error) => {
    console.log("fetchProductsInfoViaApi_Error() error: ", error);
    // Show error message etc.
  }
  // --------- START: Fetch product info ------------

  //-----------------------------------------------------------------
  // End: Data fetch related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Add/Update/Delete related functions
  //-----------------------------------------------------------------

  // Called when automatic campaign checkbox toggle done.
  handleChangeAutomatic = (isChecked) => {
    //console.log('handleChangeAutomatic() isChecked:', isChecked);

    this.setState({
      automaticCampaignChecked: isChecked,
    });

    // If checked then Prepare negative keyword from Manual Broad and Phrase Ad 
    // group (if exist) and set it within state.negativeKeywordInputAutomatic
    // Note: Positive Keyword not need for auto ad group.
    if (isChecked) { 
      this.prepareNegativeKeyword_Auto();
    }
  }

  // Prepare negative keyword from Manual Broad and Phrase Ad group (if exist) 
  // and set it within state.negativeKeywordInputAutomatic
  // Note: Do not include same keyword multiple time.
  prepareNegativeKeyword_Auto = () => {
    console.log('prepareNegativeKeyword_Auto()');
    
    const { negativeKeywordListApi } = this.state;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id } = this.state.flowDataDb;

    // 1 - If keyword list empty (not fetched via api yet) then return.    
    if (negativeKeywordListApi.length === 0 ) { return; }

    // 2 - Create empty dictionary object, It will not allow duplicate 
    // keys, so if we add same keyword multiple time it will not 
    // create duplicate keyword within dictionary,
    const keywordDict = {};

    // 3 - If manual broad ad group negative keyword exist then grab it.
    if (manual_broad_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 4 - If manual phrase ad group negative keyword exist then grab it.
    if (manual_phrase_ad_group_id) {
      negativeKeywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // Debug
    // keywordDict['Auto negative1'] = 'Auto negative1';
    // keywordDict['Auto negative2'] = 'Auto negative2';
    console.log('Auto Negative keywordDict:', keywordDict);

    // 5 - Prepare keyword list string (one keyword in each line)
    let keywordString = '';
    Object.values(keywordDict).forEach((value, index) => {
      keywordString += value;
      keywordString += '\n';
    });

    // 6 - Save keyword within state
    this.setState({
      negativeKeywordInputAutomatic: keywordString
    });
  }



  // This function called when user enter bid amount for automatic campaign
  onChangeBidAmountAutomatic = (value) => {
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ bidAmountAutomatic: value });
  }

  // This function called when user enter automatic campaign budget
  onChangeCampaignBudgetAutomatic = (value) => {
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more tha 9999
    if ( value > 9999 ) { return; }

    // 3 - Set value within state
    this.setState({ campaignBudgetAutomatic: value });
  } 

  // Whenever user will input negative keyword for automatic campaign
  // this function called with its value. 
  onChange_NegativeKeywordInputAutomatic = (value) => {
    this.setState({ negativeKeywordInputAutomatic: value });
  }



  // Called when Broad Ad Group checkbox toggle done.
  handleChangeBroadAdGroup = (isChecked) => {
    console.log('handleChangeBroadAdGroup() isChecked:', isChecked);

    this.setState({
      broadAdGroupChecked: isChecked,
    });

    // If checked then Prepare Keyword and Negative keyword for broad ad group.
    if (isChecked) { 
      this.prepareKeyword_BroadAdGroup();
      this.prepareNegativeKeyword_BroadAdGroup();
    }
  }

  // Prepare Keyword for Manual Broad Ad Group.
  // Grab keyword from Manual Phrase Ad Group, Manual Exact Ad Group 
  // (if exist) and set it within state.
  // Note: Do not include same keyword multiple time.
  prepareKeyword_BroadAdGroup = () => {
    console.log('prepareKeyword_BroadAdGroup()');

    const { keywordListApi } = this.state;
    const { manual_phrase_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 1 - If keyword list empty (not fetched via api yet) then return.    
    if ( keywordListApi.length === 0 ) { return; }

    // 2 - Create empty dictionary object, It will not allow duplicate 
    // keys, so if we add same keyword multiple time it will not 
    // create duplicate keyword within dictionary,
    const keywordDict = {};

    // 3 - If Manual Phrase Ad Group keyword exist then grab it.
    if (manual_phrase_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 4 - If Manual Exact Ad Group keyword exist then grab it.
    if (manual_exact_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // Debug 
    // keywordDict['Broad keyword1'] = 'Broad keyword1';
    // keywordDict['Broad keyword2'] = 'Broad keyword2';
    console.log('Broad keywordDict:', keywordDict);

    // 5 - Prepare keyword list string (one keyword in each line)
    let keywordString = '';
    Object.values(keywordDict).forEach((value, index) => {
      keywordString += value;
      keywordString += '\n';
    });

    // 6 - Save keyword within state
    this.setState({
      keywordInputBroad: keywordString
    });

  }
  
  // Prepare Negative Keyword for Manual Broad Ad Group.
  // i.e Grab Negative Keyword from Manual Phrase Ad group AND Auto AdGroup 
  // (if keyword exist) and set it within state.
  // Note: Do not include same keyword multiple time.
  prepareNegativeKeyword_BroadAdGroup = () => {
    console.log('prepareNegativeKeyword_BroadAdGroup()');
    
    const { negativeKeywordListApi } = this.state;
    const { auto_ad_group_id, manual_phrase_ad_group_id } = this.state.flowDataDb;

    // 1 - If keyword list empty (not fetched via api yet) then return.    
    if (negativeKeywordListApi.length === 0 ) { return; }

    // 2 - Create empty dictionary object, It will not allow duplicate 
    // keys, so if we add same keyword multiple time it will not 
    // create duplicate keyword within dictionary,
    const keywordDict = {};

    // 3 - If auto ad group negative keyword exist then grab it.
    if (auto_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === auto_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 4 - If manual phrase ad group negative keyword exist then grab it.
    if (manual_phrase_ad_group_id) {
      negativeKeywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // Debug 
    // keywordDict['Broad Negative1'] = 'Broad Negative1';
    // keywordDict['Broad Negative2'] = 'Broad Negative2';
    console.log('Broad Negative keywordDict:', keywordDict);
    
    // 5 - Prepare keyword list string (one keyword in each line)
    let keywordString = '';
    Object.values(keywordDict).forEach((value, index) => {
      keywordString += value;
      keywordString += '\n';
    });

    // 6 - Save keyword within state
    this.setState({
      negativeKeywordInputBroad: keywordString
    });
  }

  

  // Called when Phrase Ad Group checkbox toggle done.  
  handleChangePhraseAdGroup = (isChecked) => {
    //console.log('handleChangePhraseAdGroup() isChecked:', isChecked);

    this.setState({
      phraseAdGroupChecked: isChecked,
    });

    // If checked then Prepare Keyword and Negative keyword for Phrase Ad Group
    if (isChecked) { 
      this.prepareKeyword_PhraseAdGroup();
      this.prepareNegativeKeyword_PhraseAdGroup();
    }
  }

  // Prepare Keyword for Manual Phrase Ad Group.
  // Grab keyword from Manual Broad Ad Group, Manual Exact Ad Group 
  // (if exist) and set it within state.
  // Note: Do not include same keyword multiple time.
  prepareKeyword_PhraseAdGroup = () => {
    console.log('prepareKeyword_PhraseAdGroup()');

    const { keywordListApi } = this.state;
    const { manual_broad_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 1 - If keyword list empty (not fetched via api yet) then return.    
    if ( keywordListApi.length === 0 ) { return; }

    // 2 - Create empty dictionary object, It will not allow duplicate 
    // keys, so if we add same keyword multiple time it will not 
    // create duplicate keyword within dictionary,
    const keywordDict = {};

    // 3 - If Manual Broad Ad Group keyword exist then grab it.
    if (manual_broad_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 4 - If Manual Exact Ad Group keyword exist then grab it.
    if (manual_exact_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // Debug 
    // keywordDict['Phrase keyword1'] = 'Phrase keyword1';
    // keywordDict['Phrase keyword2'] = 'Phrase keyword2';
    console.log('Phrase keywordDict:', keywordDict);

    // 5 - Prepare keyword list string (one keyword in each line)
    let keywordString = '';
    Object.values(keywordDict).forEach((value, index) => {
      keywordString += value;
      keywordString += '\n';
    });

    // 6 - Save keyword within state
    this.setState({
      keywordInputPhrase: keywordString 
    });
  }

  // Prepare Negative Keyword for Manual Phrase Ad Group.
  // i.e Grab Negative Keyword from Manual Broad Ad group AND Auto AdGroup 
  // (if keyword exist) and set it within state.
  // Note: Do not include same keyword multiple time.
  prepareNegativeKeyword_PhraseAdGroup = () => {
    console.log('prepareNegativeKeyword_PhraseAdGroup()');
    
    const { negativeKeywordListApi } = this.state;
    const { auto_ad_group_id, manual_broad_ad_group_id } = this.state.flowDataDb;

    // 1 - If keyword list empty (not fetched via api yet) then return.    
    if (negativeKeywordListApi.length === 0 ) { return; }

    // 2 - Create empty dictionary object, It will not allow duplicate 
    // keys, so if we add same keyword multiple time it will not 
    // create duplicate keyword within dictionary,
    const keywordDict = {};

    // 3 - If auto ad group negative keyword exist then grab it.
    if (auto_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === auto_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 4 - If manual broad ad group negative keyword exist then grab it.
    if (manual_broad_ad_group_id) {
      negativeKeywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // Debug 
    // keywordDict['Phrase Negative1'] = 'Phrase Negative1';
    // keywordDict['Phrase Negative2'] = 'Phrase Negative2';
    console.log('Phrase Negative keywordDict:', keywordDict);
    
    // 5 - Prepare keyword list string (one keyword in each line)
    let keywordString = '';
    Object.values(keywordDict).forEach((value, index) => {
      keywordString += value;
      keywordString += '\n';
    });

    // 6 - Save keyword within state
    this.setState({
      negativeKeywordInputPhrase: keywordString
    });
  }



  // Called when Exact Ad Group checkbox toggle done.    
  handleChangeExactAdGroup = (isChecked) => {
    //console.log('handleChangeExactAdGroup() isChecked:', isChecked);

    this.setState({
      exactAdGroupChecked: isChecked,
    });

    // If checked then Prepare Keyword for Exact Ad Group
    // Note: Negative Keyword not need for exact Ad Group
    if (isChecked) { 
      this.prepareKeyword_ExactAdGroup();
    }
  }

  // Prepare Keyword for Manual Exact Ad Group.
  // Grab keyword from Manual Broad Ad Group, Manual Phrase Ad Group 
  // (if exist) and set it within state.
  // Note: Do not include same keyword multiple time.
  prepareKeyword_ExactAdGroup = () => {
    console.log('prepareKeyword_ExactAdGroup()');

    const { keywordListApi } = this.state;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id } = this.state.flowDataDb;

    // 1 - If keyword list empty (not fetched via api yet) then return.    
    if ( keywordListApi.length === 0 ) { return; }

    // 2 - Create empty dictionary object, It will not allow duplicate 
    // keys, so if we add same keyword multiple time it will not 
    // create duplicate keyword within dictionary,
    const keywordDict = {};

    // 3 - If Manual Broad Ad Group keyword exist then grab it.
    if (manual_broad_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 4 - If Manual Phrase Ad Group keyword exist then grab it.
    if (manual_phrase_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // Debug 
    // keywordDict['Exact keyword1'] = 'Exact keyword1';
    // keywordDict['Exact keyword2'] = 'Exact keyword2';
    console.log('Exact keywordDict:', keywordDict);

    // 5 - Prepare keyword list string (one keyword in each line)
    let keywordString = '';
    Object.values(keywordDict).forEach((value, index) => {
      keywordString += value;
      keywordString += '\n';
    });

    // 6 - Save keyword within state
    this.setState({
      keywordInputExact: keywordString
    });
  }



  // This function called when user enter bid amount for broad ad group
  onChangeBidAmountBroad = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; } // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 2 - Set value within state
    this.setState({ bidAmountBroad: value, });
  }

  // Called whenever user will input keyword for manual broad ad group.
  onChange_KeywordInputBroad(value) {
    this.setState({ keywordInputBroad: value });
  }

  // Called when user will input negative keyword for manual broad ad group.
  onChange_NegativeKeywordInputBroad(value) {
    this.setState({ negativeKeywordInputBroad: value });
  }


  // This function called when user enter bid amount for phrase ad group
  onChangeBidAmountPhrase = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ bidAmountPhrase: value, });
  }

  // Called whenever user will input keyword for manual phrase ad group.
  onChange_KeywordInputPhrase(value) {
    this.setState({ keywordInputPhrase: value });
  }

  // Called when user will input negative keyword for manual phrase ad group.
  onChange_NegativeKeywordInputPhrase(value) {
    this.setState({ negativeKeywordInputPhrase: value });
  }


  // This function called when user enter bid amount for exact ad group
  onChangeBidAmountExact = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ bidAmountExact: value, });
  }

  // Called whenever user will input keyword for manual exact ad group.
  onChange_KeywordInputExact(value) {
    this.setState({ keywordInputExact: value });
  }



  // This function called when user enter manual campaign budget  
  onChangeCampaignBudgetManual = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 9999
    if ( value > 9999 ) { return; }

    // 2 - Set value within state
    this.setState({ campaignBudgetManual: value, });
  } 



  // Edit keyword created under Auto or Manual campaign
  onClick_EditKeyword = (keywordId) => {
    console.log('onClick_EditKeyword() keywordId:', keywordId);

  }


  // Delete negative keyword created under Auto or Manual campaign
  onClick_DeleteNegativeKeyword = (keywordId) => {
    console.log('onClick_DeleteNegativeKeyword() keywordId:', keywordId);

  }

  // Delete keyword created under Auto or Manual campaign
  onClick_DeleteKeyword = (keywordId) => {
    console.log('onClick_DeleteKeyword() keywordId:', keywordId);

  }


  // Called when delete product button tapped
  // We have to delete all product ad created for the asin.
  onClick_DeleteProductAdForASIN = (asin) => {
    console.log('onClick_DeleteProductAdForASIN() asin:', asin);

  }


  // Called when save button tapped
  onClickSave = () => {
    console.log('onClickSave()');

  }
  //-----------------------------------------------------------------
  // End: Add/Update/Delete related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Rendering related functions
  //-----------------------------------------------------------------
  renderContent = () => {
    const { classes, flowDocId } = this.props;
    const { profile_id, profile_type, api_mode } = this.state.flowDataDb;
    const { isProcessing } = this.state;

    return(
        <Grid container spacing={2}>
          
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" >
              Running Ad For: { profile_type } profile
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} >
            { this.renderContentAutoFlow() }
          </Grid>
          
          <Grid item xs={12} sm={9} >
            { this.renderContentManualFlow() }
          </Grid>

          <Grid item xs={12} sm={12}>
            <b>Products:</b>
            { this.renderProducts() }
          </Grid>

          <Grid item xs={12} sm={12} align='center' >
            <Button 
              variant="contained" 
              size="large" 
              onClick={ (e) => this.onClickSave(e) }
              disabled={ isProcessing } 
            >   Save  </Button>
            <br /> 
            (( Save Under Construction ))
          </Grid>

          { api_mode === 'DUMMY' && 
          <Grid item xs={12} sm={12}>
            <br /><br />
            <Typography variant="subtitle1" >
              Note: DUMMY api mode used to create this flow.
              So more info can not fetch via amazon api.
            </Typography>
          </Grid>
          }

          <Grid item xs={12} sm={12}>
            <br /><br />
          </Grid>

          { this.state.showDebug &&
          <Grid item xs={12} sm={4}>
            { this.debugCommonInfo() }
          </Grid>
          }
          { this.state.showDebug && 
          <Grid item xs={12} sm={4}> 
            { this.debugCampaignAuto() }
          </Grid>
          }
          { this.state.showDebug && 
          <Grid item xs={12} sm={4}> 
            { this.debugCampaignManual() }
          </Grid>
          } 
          <br />
          { this.state.showDebug && 
          <Grid item xs={12} sm={12}>
            Product Ads Debug:
            { this.debugProductAds() }
          </Grid>
          }
          <br /><br />
        </Grid>
    )
  }

  
  // Render all content related to automatic flow
  renderContentAutoFlow = () => {
    const { classes } = this.props;
    const { auto_ad_group_id } = this.state.flowDataDb;

    return(
      <React.Fragment>
        
        <Paper elevation={2} className={classes.paper}>
          <Typography variant="h6" style={{ textAlign: 'center' }} >Automatic Campaign</Typography>
        </Paper>

        <div style={{ height: 16 }}></div>

        <Paper elevation={2} className={classes.paper}>
          { this.renderAdGroupAuto() }
          <br /><br />
          
          { auto_ad_group_id && 
          <b>Negative Exact Keywords:</b>
          }
          { this.renderNegativeKeywordAuto() }

          <br />
        </Paper>

      </React.Fragment>
    );
  }

  // Render ad group info (for Auto flow)
  renderAdGroupAuto = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { auto_ad_group_id } = this.state.flowDataDb;
    const { showDebug, isProcessing, 
            automaticCampaignChecked, disableAutomaticCheckbox,
            bidAmountAutomatic, campaignBudgetAutomatic,
            negativeKeywordInputAutomatic, 
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group info if loaded via amazon api
    const autoAdGroupInfo = this.getAdGroupInfoFromId(auto_ad_group_id);

    // 3 - Render ad group info
    return (
      <FormControl component="fieldset" className={classes.formControl} >
        
        <FormGroup>
          <FormControlLabel
            control={ 
                    <Checkbox
                      color="primary" 
                      checked={automaticCampaignChecked} 
                      onChange={ (e) => { this.handleChangeAutomatic(e.target.checked) } }
                      name="automatic"
                      disabled={ disableAutomaticCheckbox || isProcessing }
                    />
                    }
            label="Automatic Campaign"
          />
        </FormGroup>
        
        { // If user will check automatic campaign checkbox, it means user  
          // is willing to create automatic campaign now, so we will show 
          // automatic campaign creation related input element.
        (!auto_ad_group_id && automaticCampaignChecked) &&
        <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }} >        
            <TextField 
              variant="outlined"
              id="bid-amount-automatic" 
              label="Bid Amount" 
              value={ bidAmountAutomatic } 
              onChange={ (e) => this.onChangeBidAmountAutomatic(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              helperText="Minimum $0.02"
              style={{ marginRight: 10 }}
            />
            <TextField 
              variant="outlined"
              id="campaign-budget-automatic" 
              label="Daily Budget"
              placeholder="enter budget"
              value={ campaignBudgetAutomatic } 
              onChange={ (e) => this.onChangeCampaignBudgetAutomatic(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              helperText="Minimum $5.0"
            />
          </div>
          <TextField
            variant="outlined"
            id="negative-keyword-input-automatic"
            label="Negative Keywords"
            multiline
            rows={4}
            value={negativeKeywordInputAutomatic}
            onChange={ (e) => this.onChange_NegativeKeywordInputAutomatic(e.target.value) }
            //defaultValue="Default Value"
            InputLabelProps={{ shrink: true, }}
            style={{ marginTop: 20, marginBottom: 20 }}
            helperText="Enter one keyword per line"
          />
        </React.Fragment> 
        }

        { showDebug && 
          <FormHelperText>
            Debug: <br />
            AdGroup Id (db): { auto_ad_group_id } <br />
            name: { autoAdGroupInfo && autoAdGroupInfo.name } <br />
            campaignId: { autoAdGroupInfo && autoAdGroupInfo.campaignId } <br />
            defaultBid: { autoAdGroupInfo && autoAdGroupInfo.defaultBid } &nbsp; 
            state: { autoAdGroupInfo && autoAdGroupInfo.state } <br />
          </FormHelperText>
        }
      </FormControl>
    );
  }


  // Render ui to show negative keywords (for Auto flow)
  renderNegativeKeywordAuto = () => {
    
    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing } = this.state;
    if (negativeKeywordListApi.length === 0 ) { return null; }
    
    // 2 - Prepare array that consist negative keyword belongs to auto_campaign_id
    const { auto_campaign_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if (item.campaignId === auto_campaign_id) { return true; } else { return false; }
    });
    //console.log('(Auto) Negative keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'negative-keyword-auto-' + index } >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4 }} >
            <IconButton 
              size="small" 
              aria-label="Delete Negative Keyword - Auto Campaign" 
              onClick={ (e) => this.onClick_DeleteNegativeKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Negative Exact keywords - Auto Campaign">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    ); 

  }


  
  // Render all content related to manual flow
  renderContentManualFlow = () => {
    const { classes } = this.props;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id  } = this.state.flowDataDb;

    return (
      <React.Fragment>
        
        <Paper elevation={2} className={classes.paper}>
          <Typography variant="h6" style={{ textAlign: 'center' }} >
            Manual Campaign
          </Typography>
        </Paper>
        
        <div style={{ height: 16 }}></div>

        { // Render daily budget input field for manual campaign
          this.renderManualCampaignDailyBudget()
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} >
            <Paper elevation={2} className={classes.paper}>
              { this.renderAdGroup_ManualBroad() }
              <br /><br />
              
              { manual_broad_ad_group_id && 
              <b>Broad Keywords:</b>
              }
              { this.renderKeyword_ManualBroad() }
              <br /><br />

              { manual_broad_ad_group_id && 
              <b>Negative Exact Keywords:</b>
              }
              { this.renderNegativeKeyword_ManualBroad() } 
              <br />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} >
            <Paper elevation={2} className={classes.paper}>
              { this.renderAdGroup_ManualPhrase() }
              <br /><br />

              { manual_phrase_ad_group_id && 
                <b>Phrase Keywords:</b>
              } 
              { this.renderKeyword_ManualPhrase() }
              <br /><br />
              
              { manual_phrase_ad_group_id && 
                <b>Negative Exact Keywords:</b> 
              }
              { this.renderNegativeKeyword_ManualPhrase() }
              <br />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} >
            <Paper elevation={2} className={classes.paper}>
              { this.renderAdGroup_ManualExact() }
              <br /><br />
              
              { manual_exact_ad_group_id && 
              <b>Exact Keywords:</b>
              }
              <br />
              { this.renderKeyword_ManualExact() }
              <br />
            </Paper>
          </Grid>
        </Grid>

      </React.Fragment>
    );
  }


  // It will render text input field for manual campaign daily budget.
  // If any ad group not created for manual campaign (broad, phrase, exact)
  // in that case user will choose to create any ad group for manual campaign 
  // then we will ask daily budget for manual campaign.
  renderManualCampaignDailyBudget = () => {
    const { classes } = this.props;

    const { isProcessing, 
            disableBroadAdGroupCheckbox, disablePhraseAdGroupCheckbox, disableExactAdGroupCheckbox,
            broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked, 
            campaignBudgetManual,
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 1 - If any one checkbox disabled (broad or phrase or exact) then do not render manual 
    // campaign daily budget input field. because campaign is already created so no need 
    // to ask budget again.
    if ( disableBroadAdGroupCheckbox || disablePhraseAdGroupCheckbox || disableExactAdGroupCheckbox ) {
      return null;
    }
    
    // 2 - If any ad group not checked (broad, phrase, exact) then do not show budget input field.
    if ( !broadAdGroupChecked && !phraseAdGroupChecked && !exactAdGroupChecked ) {
      return null;
    }

    // 3 - If atleast one ad group checked (broad, phrase, exact) then show campaign budget field.
    return(
      <Paper elevation={2} className={classes.paper} style={{ marginBottom: 20, padding: 10 }} >
        <FormControl className={classes.formControl} >
          <TextField 
            variant="outlined"
            id="campaign-budget-manual" 
            label="Manual Campaign Daily Budget (Minimum $5.0)" 
            placeholder="enter budget" 
            value={ campaignBudgetManual } 
            onChange={ (e) => this.onChangeCampaignBudgetManual(e.target.value) } 
            size="small" 
            disabled={ isDisabled } 
            InputLabelProps={{ shrink: true, }} 
            InputProps={{ 
              startAdornment: <InputAdornment position="start">$</InputAdornment>, 
            }}
            //helperText="Minimum $5.0"
            style={{ maxWidth: 400, }}
          />
        </FormControl>
      </Paper>
    );
  }

  // Render ad group info - Manual Broad
  renderAdGroup_ManualBroad = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { manual_broad_ad_group_id } = this.state.flowDataDb;
    const { showDebug, isProcessing, 
            broadAdGroupChecked, disableBroadAdGroupCheckbox,
            bidAmountBroad,
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group info if loaded via amazon api.
    const adGroupInfoBroad = this.getAdGroupInfoFromId(manual_broad_ad_group_id);

    // 3 - Render ad group info
    return(
      <FormControl component="fieldset" className={classes.formControl} >
        <FormGroup>

          <FormControlLabel
            control={
              <Checkbox 
                color="primary" 
                checked={broadAdGroupChecked} 
                onChange={ (e) => this.handleChangeBroadAdGroup(e.target.checked)} 
                name="broad-ad-group" 
                disabled={ disableBroadAdGroupCheckbox || isProcessing }
              />
            }
            label="Broad Ad Group"
          />

          { // If user will check broad adgroup checkbox, it means user  
            // is willing to create Broad Ad Group now, so we will show 
            // Broad Ad Group creation related input element.
          (!manual_broad_ad_group_id && broadAdGroupChecked) &&
          <React.Fragment>
            <TextField 
              variant="outlined"
              id="bid-amount-broad" 
              label="Bid Amount Broad" 
              value={ bidAmountBroad } 
              onChange={ (e) => this.onChangeBidAmountBroad(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              helperText="Minimum $0.02"
              style={{ maxWidth: 150 }}
            />
            <TextField
              variant="outlined"
              id="keyword-input-broad"
              label="Keywords - Broad"
              multiline
              rows={4}
              value={ this.state.keywordInputBroad }
              onChange={ (e) => this.onChange_KeywordInputBroad(e.target.value) }
              InputLabelProps={{ shrink: true, }}
              style={{ marginTop: 20, marginBottom: 0 }}
              helperText="Enter one keyword per line"
            /> 
            <TextField
              variant="outlined"
              id="negative-keyword-input-broad"
              label="Negative Keywords - Broad"
              multiline
              rows={4}
              value={ this.state.negativeKeywordInputBroad }
              onChange={ (e) => this.onChange_NegativeKeywordInputBroad(e.target.value) }
              InputLabelProps={{ shrink: true, }}
              style={{ marginTop: 20, marginBottom: 20 }}
              helperText="Enter one keyword per line"
            /> 
          </React.Fragment>
          }

          { showDebug && 
          <FormHelperText>
            Debug: <br />
            AdGroup Id (db): { manual_broad_ad_group_id } <br />
            name: { adGroupInfoBroad && adGroupInfoBroad.name } <br />
            campaignId: { adGroupInfoBroad && adGroupInfoBroad.campaignId } <br />
            defaultBid: { adGroupInfoBroad && adGroupInfoBroad.defaultBid } &nbsp; 
            state: { adGroupInfoBroad && adGroupInfoBroad.state } <br />
          </FormHelperText>
          }

        </FormGroup>
      </FormControl>
    );
  }

  // Render ad group info - manual Phrase
  renderAdGroup_ManualPhrase = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { manual_phrase_ad_group_id } = this.state.flowDataDb;
    const { showDebug, isProcessing, 
            phraseAdGroupChecked, disablePhraseAdGroupCheckbox,
            bidAmountPhrase,
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group name state if loaded via amazon api.
    const adGroupInfoPhrase = this.getAdGroupInfoFromId(manual_phrase_ad_group_id);

    // 3 - Render ad group info
    return(
      <FormControl component="fieldset" className={classes.formControl} >
        <FormGroup>
          
          <FormControlLabel
            control={
              <Checkbox 
                color="primary" 
                checked={phraseAdGroupChecked} 
                onChange={ (e) => this.handleChangePhraseAdGroup(e.target.checked) } 
                name="phrase-ad-group" 
                disabled={ disablePhraseAdGroupCheckbox || isProcessing }
              />
            }
            label="Phrase Ad Group"
          />

          { // If user will check Phrase adgroup checkbox, it means user  
            // is willing to create Phrase Ad Group now, so we will show 
            // Phrase Ad Group creation related input element.
          (!manual_phrase_ad_group_id && phraseAdGroupChecked) &&
          <React.Fragment>
            <TextField 
              variant="outlined"
              id="bid-amount-phrase" 
              label="Bid Amount Phrase" 
              value={ bidAmountPhrase } 
              onChange={ (e) => this.onChangeBidAmountPhrase(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              helperText="Minimum $0.02"
              style={{ maxWidth: 150 }}
            />
            <TextField
              variant="outlined"
              id="keyword-input-phrase"
              label="Keywords - Phrase"
              multiline
              rows={4}
              value={ this.state.keywordInputPhrase }
              onChange={ (e) => this.onChange_KeywordInputPhrase(e.target.value) }
              InputLabelProps={{ shrink: true, }}
              style={{ marginTop: 20, marginBottom: 0 }}
              helperText="Enter one keyword per line"
            /> 
            <TextField
              variant="outlined"
              id="negative-keyword-input-phrase"
              label="Negative Keywords - Phrase"
              multiline
              rows={4}
              value={ this.state.negativeKeywordInputPhrase }
              onChange={ (e) => this.onChange_NegativeKeywordInputPhrase(e.target.value) }
              InputLabelProps={{ shrink: true, }}
              style={{ marginTop: 20, marginBottom: 20 }}
              helperText="Enter one keyword per line"
            /> 
          </React.Fragment>
          }

          { showDebug && 
          <FormHelperText>
            Debug: <br />
            AdGroup Id (db): { manual_phrase_ad_group_id } <br />
            name: { adGroupInfoPhrase && adGroupInfoPhrase.name } <br />
            campaignId: { adGroupInfoPhrase && adGroupInfoPhrase.campaignId } <br />
            defaultBid: { adGroupInfoPhrase && adGroupInfoPhrase.defaultBid } &nbsp; 
            state: { adGroupInfoPhrase && adGroupInfoPhrase.state } <br />
          </FormHelperText>
          }

        </FormGroup>
      </FormControl>
    );
  }

  // Render ad group info - manual Exact
  renderAdGroup_ManualExact = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { manual_exact_ad_group_id } = this.state.flowDataDb;
    const { showDebug, isProcessing, 
            exactAdGroupChecked, disableExactAdGroupCheckbox,
            bidAmountExact,
          } = this.state;
  
    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group info if loaded via amazon api.
    const adGroupInfoExact = this.getAdGroupInfoFromId(manual_exact_ad_group_id);
    
    // 3 - Render ad group info
    return(
      <FormControl component="fieldset" className={classes.formControl} >
        <FormGroup>

          <FormControlLabel
            control={
              <Checkbox 
                color="primary" 
                checked={exactAdGroupChecked} 
                onChange={ (e) => this.handleChangeExactAdGroup(e.target.checked) } 
                name="exact-ad-group" 
                disabled={ disableExactAdGroupCheckbox || isProcessing }
              />
            }
            label="Exact Ad Group"
          />

          { // If user will check Phrase adgroup checkbox, it means user  
            // is willing to create Phrase Ad Group now, so we will show 
            // Phrase Ad Group creation related input element.
          (!manual_exact_ad_group_id && exactAdGroupChecked) &&
          <React.Fragment>
            <TextField 
              variant="outlined"
              id="bid-amount-exact" 
              label="Bid Amount Exact" 
              value={ bidAmountExact } 
              onChange={ (e) => this.onChangeBidAmountExact(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              helperText="Minimum $0.02"
              style={{ maxWidth: 150 }}
            />
            <TextField
              variant="outlined"
              id="keyword-input-exact"
              label="Keywords - Exact"
              multiline
              rows={4}
              value={ this.state.keywordInputExact }
              onChange={ (e) => this.onChange_KeywordInputExact(e.target.value) }
              InputLabelProps={{ shrink: true, }}
              style={{ marginTop: 20, marginBottom: 20 }}
              helperText="Enter one keyword per line"
            /> 
          </React.Fragment>
          }

          { showDebug && 
          <FormHelperText>
            Debug: <br />
            AdGroup Id (db): { manual_exact_ad_group_id } <br />
            name: { adGroupInfoExact && adGroupInfoExact.name } <br />
            campaignId: { adGroupInfoExact && adGroupInfoExact.campaignId } <br />
            defaultBid: { adGroupInfoExact && adGroupInfoExact.defaultBid } &nbsp; 
            state: { adGroupInfoExact && adGroupInfoExact.state } <br />
          </FormHelperText>
          }

        </FormGroup>
      </FormControl>
    ); 
  } 


  // Render keyword for Manual Broad Ad Group
  renderKeyword_ManualBroad = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_campaign_id and broad match type.
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.matchType === 'broad' ) { return true; } else { return false; }
    });
    //console.log('(Manual Broad) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'keyword-manual-broad-' + index } size="small" >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4, width: 30 }} >${ item.bid }</TableCell>          
          <TableCell align="right" style={{ padding: 4, width: 80 }} >
            <IconButton 
              size="small"
              aria-label="Edit Manual Broad Keyword" 
              style={{ marginRight: 10 }}
              onClick={ (e) => this.onClick_EditKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton 
              size="small"
              aria-label="Delete Manual Broad Keyword" 
              onClick={ (e) => this.onClick_DeleteKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Manual Broad Keywords">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
          //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  
  // Render keyword for Manual Phrase Ad Group
  renderKeyword_ManualPhrase = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_campaign_id and phrase match type.
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.matchType === 'phrase' ) { return true; } else { return false; }
    });
    //console.log('(Manual Phrase) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'keyword-manual-phrase-' + index } size="small" >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4, width: 30 }} >${ item.bid }</TableCell>          
          <TableCell align="right" style={{ padding: 4, width: 80 }} >
            <IconButton 
              size="small"
              aria-label="Edit Manual Phrase Keyword" 
              style={{ marginRight: 10 }}
              onClick={ (e) => this.onClick_EditKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton 
              size="small"
              aria-label="Delete Manual Phrase Keyword" 
              onClick={ (e) => this.onClick_DeleteKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Manaul Phrase Keywords">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
          //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Render keyword for Manual Exact Ad Group
  renderKeyword_ManualExact = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_campaign_id and exact match type.
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.matchType === 'exact' ) { return true; } else { return false; }
    });
    //console.log('(Manual Exact) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { 
      return (<React.Fragment>Not created</React.Fragment>);
    }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'keyword-manual-exact-' + index } size="small" >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4, width: 30 }} >${ item.bid }</TableCell>          
          <TableCell align="right" style={{ padding: 4, width: 80 }} >
            <IconButton 
              size="small"
              aria-label="Edit Manual Exact Keyword" 
              style={{ marginRight: 10 }}
              onClick={ (e) => this.onClick_EditKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton 
              size="small" 
              aria-label="Delete Manual Exact Keyword" 
              onClick={ (e) => this.onClick_DeleteKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <React.Fragment>
        <TableContainer>
          <Table aria-label="Manaul Exact Keywords">
            {
            // <TableHead>
            //   <TableRow>
            //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
            //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
            //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
            //   </TableRow>
            // </TableHead>
            }
            <TableBody>
              { tableRowEl }
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }


  // Render negative exact keyword for manual broad ad group
  renderNegativeKeyword_ManualBroad = () => {

    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing } = this.state;
    const { manual_broad_ad_group_id } = this.state.flowDataDb;
    if (negativeKeywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist negative keywords belongs to 
    // manual_campaign_id and manual_broad_ad_group_id
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.adGroupId === manual_broad_ad_group_id ) { 
        return true; 
      } else { 
        return false; 
      }
    });
    //console.log('(Negative Exact - Manual Broad) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'negative-keyword-manual-broad-' + index } >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4 }} >
            <IconButton 
              size="small" 
              aria-label="Delete Negative Keyword - Manual Broad Ad Group" 
              onClick={ (e) => this.onClick_DeleteNegativeKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer>
        <Table aria-label="Negative Exact keywords - Manual Broad Ad Group">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    ); 

  }

  // Render negative exact keyword for manual phrase ad group
  renderNegativeKeyword_ManualPhrase = () => {

    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing } = this.state;
    const { manual_phrase_ad_group_id } = this.state.flowDataDb;
    if (negativeKeywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist negative keywords belongs to 
    // manual_campaign_id and manual_phrase_ad_group_id
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.adGroupId === manual_phrase_ad_group_id ) { 
        return true; 
      } else { 
        return false; 
      }
    });
    //console.log('(Negative Exact - Manual Phrase) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'negative-keyword-manual-phrase-' + index } >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4 }} >
            <IconButton 
              size="small" 
              aria-label="Delete Negative Keyword - Manual Phrase Ad Group" 
              onClick={ (e) => this.onClick_DeleteNegativeKeyword(item.keywordId) }
              disabled={ isProcessing }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer>
        <Table aria-label="Negative Exact keywords - Manual Phrase Ad Group">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    ); 
  }


  // Render products used within flow
  renderProducts = () => {

    const { showDebug } = this.state;
    const { products } = this.state.flowDataDb;
    //console.log('products:', products);

    if ( !products || products.length === 0) { 
      return <b>Product not added</b>; 
    }

    // 4 - Prepare ui elements for product
    const { productsInfoDictApi, isProcessing } = this.state;
    const tableRowEl = products.map( (item, index) => {

      // If product image and title available then render it.
      let title = ' -- ';
      let imageUrl = '';
      if (item.ASIN in productsInfoDictApi) {
        const product = productsInfoDictApi[item.ASIN];
        title = product.Title;
        if (product.SmallImage && product.SmallImage.URL) {
          imageUrl = product.SmallImage.URL;
        }
      }

      // Show adIds in which this product used.
      let adIdsCreated = '';
      if (showDebug) {
        adIdsCreated = this.getAdIdsForAsin(item.ASIN);
      }

      return(
        <TableRow key={'product-' + index } >
          <TableCell align="left" style={{ }} >
            { imageUrl !== '' && 
              <img src={imageUrl} width="60" alt={title} />
            }
          </TableCell>
          <TableCell align="left" style={{ padding: 4 }} >
            { title }
            <br />
            { item.ASIN && 'ASIN: ' + item.ASIN }
            { item.SellerSKU && ' | SellerSKU: ' + item.SellerSKU }
            <br />
            { showDebug &&
              <b>Created Ads: </b>
            }
            { showDebug &&
              adIdsCreated
            }
          </TableCell>
          <TableCell align="left" style={{ padding: 4 }} >
            <IconButton 
              aria-label="Delete product ad" 
              onClick={ (e) => this.onClick_DeleteProductAdForASIN(item.ASIN) }
              disabled={ isProcessing }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>

          </TableCell>
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer component={Paper} >
        <Table aria-label="Products - Used for Product Ads">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ width: 100 }} ></TableCell>
          //     <TableCell align="left" style={{ }} >Product Info</TableCell>
          //     <TableCell align="left" style={{ width: 150 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );

  }


  // It will return product ad ids for given asin
  // It will return comma separated list of ad ids for given asin
  // e.g. return value = 'adId1,adId2,adId3'
  getAdIdsForAsin = (asin) => {
    const { productAdListApi } = this.state;
    if (productAdListApi.length === 0 ) { return '' }

    const adIdArray = []; // e.g. ['adId1', 'adId2', 'adId3']
    productAdListApi.forEach((item, index) => {
      if (item.asin === asin) { 
        adIdArray.push(item.adId);
      }
    });

    return adIdArray.join(', ');
  }

  // It will return product ad ids for given sku
  // It will return comma separated list of ad ids for given sku
  // e.g. return value = 'adId1,adId2,adId3'
  getAdIdsForsku = (sku) => {
    const { productAdListApi } = this.state;
    if (productAdListApi.length === 0 ) { return '' }

    const adIdArray = []; // e.g. ['adId1', 'adId2', 'adId3']
    productAdListApi.forEach((item, index) => {
      if (item.sku === sku) { 
        adIdArray.push(item.adId);
      }
    });

    return adIdArray.join(',');
  }


  // Render product ads for Debug purpose.
  debugProductAds = () => {

    // 1 - If product ad list empty then return
    const { productAdListApi } = this.state;
    if (productAdListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist product ad belongs to manual_campaign_id
    const { manual_campaign_id, auto_campaign_id } = this.state.flowDataDb;
    const productAdList = productAdListApi.filter( (item, index) => { 
      if (item.campaignId === auto_campaign_id || item.campaignId === manual_campaign_id) {
        return true;
      } else {
        return false;
      }
    });
    //console.log('(Auto + Manual) productAdList:', productAdList);

    // 3 - If product ad list empty then return
    if (productAdList.length === 0) { return null; }

    // 4 - Prepare ui element for product
    const tableRowEl = productAdList.map( (item, index) => {
      return(
        <TableRow key={'product-ad-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >
            { item.asin ? item.asin : '-' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.sku ? item.sku : '-' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.state }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.adGroupId }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.campaignId }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.adId }</TableCell>
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Products - Used for Product Ads">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >ASIN</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >SKU</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >State</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >AdGroupId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >CampaignId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >adId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );

  }



  // Return campaign info data for given campaignId
  getCampaignInfoFromId = (campaignId) => {
    if (!campaignId) { return null }

    let campaignInfo; 
    this.state.campaignListApi.forEach((item, index) => {
      if (item.campaignId === campaignId) {
        campaignInfo = item;
      }
    });

    return campaignInfo;
  }

  // It will return campaign name if exist within result
  getCampaignNameFromId = (campaignId) => {
    if (!campaignId) { return ''; }

    let campaignName = ''; 
    this.state.campaignListApi.forEach((item, index) => {
      if (item.campaignId === campaignId) {
        campaignName = item.name;
      }
    });
    return campaignName;
  }

  // It will return campaign state if exist within result
  // e.g. enabled, paused, archived
  getCampaignStateFromId = (campaignId) => {
    if (!campaignId) { return ''; }

    let campaignState = ''; 
    this.state.campaignListApi.forEach((item, index) => {
      if (item.campaignId === campaignId) {
        campaignState = item.state;
      }
    });
    return campaignState;
  }  

  // It will return ad group info if exist within result
  getAdGroupInfoFromId = (adGroupId) => {
    if (!adGroupId) { return null; }

    let adGroupInfo = '';
    this.state.adGroupListApi.forEach((item, index) => {
      if (item.adGroupId === adGroupId) {
        adGroupInfo = item;
      }
    });
    return adGroupInfo;
  }

  // It will return ad group name if exist within result
  getAdGroupNameFromId = (adGroupId) => {
    if (!adGroupId) { return ''; }
    
    let adGroupName = '';
    this.state.adGroupListApi.forEach((item, index) => {
      if (item.adGroupId === adGroupId) {
        adGroupName = item.name;
      }
    });
    return adGroupName;
  }

  // It will return ad group state if exist within result
  // e.g. enabled, paused, archived
  getAdGroupStateFromId = (adGroupId) => {
    if (!adGroupId) { return ''; }
    
    let adGroupState = '';
    this.state.adGroupListApi.forEach((item, index) => {
      if (item.adGroupId === adGroupId) {
        adGroupState = item.state;
      }
    });
    return adGroupState;
  }

  
  // Debug common info
  debugCommonInfo = () => {
    const { flowDocId } = this.props;
    const { 
      bidAmountAutomatic, bidAmountBroad, bidAmountPhrase, bidAmountExact, 
      campaignBudgetAutomatic, campaignBudgetManual, 
      keywordInputBroad, keywordInputPhrase, keywordInputExact, 
      negativeKeywordInputAutomatic,
      negativeKeywordInputBroad, negativeKeywordInputPhrase,
    } = this.state;
    const { profile_id, profile_type, api_mode } = this.state.flowDataDb;

    return (
      <Typography variant="caption" >
        <b>Debug:</b> <br />
        flowDocId (props): { flowDocId } <br /> 
        api_mode (db): { api_mode } <br />
        profile_id (db) : { profile_id } <br />
        profile_type (db): { profile_type } <br />
        <br />
        bidAmountAutomatic: { bidAmountAutomatic } <br />
        campaignBudgetAutomatic: { campaignBudgetAutomatic } <br />
        negativeKeywordInputAutomatic: { negativeKeywordInputAutomatic } <br />
        <br />
        bidAmountBroad: { bidAmountBroad } <br />
        bidAmountPhrase: { bidAmountPhrase } <br />
        bidAmountExact: { bidAmountExact } <br />
        campaignBudgetManual: { campaignBudgetManual } <br />
        <br />
        keywordInputBroad: { keywordInputBroad } <br />
        negativeKeywordInputBroad: { negativeKeywordInputBroad } <br />
        <br />
        keywordInputPhrase: { keywordInputPhrase } <br />
        negativeKeywordInputPhrase: { negativeKeywordInputPhrase } <br />
        <br />
        keywordInputExact: { keywordInputExact } <br />
        <br />
      </Typography>  
    );
  }

  // Debug campaign info (for Auto flow)
  debugCampaignAuto = () => {
    const { auto_campaign_id } = this.state.flowDataDb;

    // 1 - Fetch campaign name, state etc. if loaded via amazon api.
    const campaignInfo = this.getCampaignInfoFromId(auto_campaign_id);

    // 2 - Render auto campaign related info
    return (
      <React.Fragment>
        <b>Debug - Auto Campaign:</b> <br /> 
        Campaign Id (DB): { auto_campaign_id } <br />
        name: { campaignInfo && campaignInfo.name } <br />
        dailyBudget: { campaignInfo && campaignInfo.dailyBudget } <br />
        Campaign State: { campaignInfo && campaignInfo.state } <br />
        Start-End Date: { campaignInfo && campaignInfo.startDate } { campaignInfo && campaignInfo.endDate } <br />
        <br />
      </React.Fragment>
    );
  }

  debugCampaignManual = () => {
    const { manual_campaign_id } = this.state.flowDataDb;

    // 1 - Fetch campaign name, state etc. if loaded via amazon api.
    const campaignInfo = this.getCampaignInfoFromId(manual_campaign_id);

    // 2 - If show manual campaign related info
    return(
      <React.Fragment>
        <b>Debug - Manual Campaign:</b> <br />
        Campaign Id (DB): { manual_campaign_id } <br />
        name: { campaignInfo && campaignInfo.name } <br />
        dailyBudget: { campaignInfo && campaignInfo.dailyBudget } <br />
        Campaign State: { campaignInfo && campaignInfo.state } <br />
        Start-End Date: { campaignInfo && campaignInfo.startDate } { campaignInfo && campaignInfo.endDate } <br />
        <br />
      </React.Fragment>
    )
  }

  // Render debug button
  renderDebugButton = () => {
    return(
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.showDebug}
              onChange={ (event) => { this.setState({showDebug: event.target.checked }); } }
              name="show-debug"
              color="primary"
            />
          }
          label="Show Debug"
          labelPlacement="end"
        />
      </FormGroup>
    );
  }
  //-----------------------------------------------------------------
  // End: Rendering related functions
  //-----------------------------------------------------------------


  render() {
    const { classes } = this.props;
    const { isOpen, flowDataDb } = this.state;
  
    return (
      <Dialog disableEscapeKeyDown={true} fullScreen={true} open={isOpen} onClose={this.handleClose} >
        <AppBar className={classes.appBar} color='default' >
          <Toolbar>
            <Typography variant="h6" style={{ flex:1 }} >
              Edit Flow: { flowDataDb ? flowDataDb.flow_name : ' .... ' }  &nbsp;&nbsp; (DEPRECATED - Screen will be removed soon)
            </Typography>
            { 
              this.renderDebugButton() 
            }
            <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close" style={{ marginLeft: 20 }} >
              <CloseIcon />
            </IconButton>
            {
            // <Button autoFocus color="inherit" onClick={this.handleClose} >Close</Button>
            }
          </Toolbar>
        </AppBar>
        <Paper elevation={0} style={{margin: 0, padding: 20}} >
          { flowDataDb &&
            this.renderContent() 
          }
        </Paper>
      </Dialog>
    );
  }

}


// define styles
const styles = (theme) => ({
  
  appBar: {
    position: 'relative',
  },
  
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  table: {
    minWidth: 650,
  },

  paper: {
    padding: theme.spacing(1),
    //color: theme.palette.text.secondary,
    //textAlign: 'center',
  },

  formControl: {
    width: '100%',
  },

});


SpAdAutomationEdit.propTypes = {
  flowDocId: PropTypes.string.isRequired,
  onEditFlowClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

//export default App;
export default withStyles(styles)(SpAdAutomationEdit);


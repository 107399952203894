import React from "react";
import { withStyles } from '@material-ui/core/styles';

// Import custom ui component
import SellerConfigForm from './SellerConfigForm';


class SellerConfig extends React.Component { 

  componentDidMount = () => {
    //console.log('SellerConfig - componentDidMount()');
  }

  componentWillUnmount = () => {
    //console.log('SellerConfig - componentWillUnmount()');
  }

  render() {
    return(
      <React.Fragment>
        <SellerConfigForm />
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(SellerConfig);


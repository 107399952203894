/// This file consist functions to fetch data from firebase db.

import firebase from './firebaseApp';
const db = firebase.firestore();


//-------------------------------------------------------------------
// Get tacker list for current user
//-------------------------------------------------------------------
export const getTrackerList = (successCallback, errorCallback) => {
  //console.log('firebaseHelper - getTrackerList()');

  // 1 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  //console.log('uid:', uid);

  // 3 - Get tracker list for current user
  db.collection('tracking_request')
  .where('uid', '==', uid)
  .orderBy('from_date', 'desc')
  .get()
  .then( (querySnapshot) => {

    const list = [];
    querySnapshot.forEach( (doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      const data = doc.data();
      data['id'] = doc.id;
      data['from_date'] = data.from_date.toDate();
      data['to_date'] = data.to_date.toDate();

      list.push(data);
    });

    successCallback(list);
  })
  .catch( (error) => {
    console.log('Get tracker list error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Get tacker created by all user (this will be used by admin)
//-------------------------------------------------------------------
export const getTrackerListAllUser = (successCallback, errorCallback) => {
  //console.log('firebaseHelper - getTrackerListAllUser()');

  // Get tracker list created by all user
  db.collection('tracking_request')
  .orderBy('from_date', 'desc')
  .get()
  .then( (querySnapshot) => {

    const list = [];
    querySnapshot.forEach( (doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());

      const data = doc.data();
      data['id'] = doc.id;
      data['from_date'] = data.from_date.toDate();
      if (data.restart_date){ // If restart_date exist then set it.
        data['restart_date'] = data.restart_date.toDate();
      }
      data['to_date'] = data.to_date.toDate();
      if(data.tracking_status_date) { // If tracking_status_date exist then set it.
        if ( data.tracking_status_date === '') {
          // Do not include date field if empty
        } else {
          data['tracking_status_date'] = data.tracking_status_date.toDate();
        }
      }

      list.push(data);
    });

    successCallback(list);
  })
  .catch( (error) => {
    console.log('Get tracker list all user - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Get inforation for single tracker
//-------------------------------------------------------------------
// This function will fetch single tracker information from db and return it.
export const getSingleTracker = (trackerDocId, index, successCallback, errorCallback) => {
  console.log('firebaseHelper - getSingleTracker()');

  db.collection('tracking_request').doc(trackerDocId).get()
  .then( (doc) => {
    if (doc.exists) {
      const data = doc.data();
      data['id'] = doc.id;
      data['from_date'] = data.from_date.toDate();
      data['to_date'] = data.to_date.toDate();
      successCallback(data, index);
    }
  })
  .catch( (error) => {
    console.log('Get tracker list error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Create tracker request
//-------------------------------------------------------------------
export const createTrackerRequest = (market, asin, successCallback, errorCallback) => {
  //console.log('firebaseHelper - createTrackerRequest()');

  // 1 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  //console.log('uid:', uid);

  // 2 - Create data
  const fromDate = new Date();
  const toDate = addDays(new Date(), 14);
  const data = {
    asin: asin,
    market: market,
    uid: uid,
    from_date: fromDate,
    to_date: toDate,
  }

  // 3 - Add record within db
  db.collection('tracking_request').add(data)
  .then( (docRef) => {
    //console.log("Document written with ID: ", docRef.id);
    successCallback(market, asin, docRef.id);
  })
  .catch( (error) => {
    console.error("Error adding document: ", error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Restart tracker (i.e. Start tracker again)
//-------------------------------------------------------------------
// This function will extend tracker to_date for the give tracker.
export const restartTracker = async (trackerDocId, index, successCallback, errorCallback) => {
  //console.log('firebaseHelper - restartTracker() trackerDocId:', trackerDocId);

  // 1 - Extend tracker to_date as currentDate + 14 days
  const currentDate = new Date();
  const toDate = addDays(currentDate, 14);
  const updateData = {
    restart_date: currentDate,
    to_date: toDate
  }

  // 2 - Udpate to_date for a given tracker
  db.collection('tracking_request').doc(trackerDocId).update(updateData)
  .then( () => {
    successCallback(trackerDocId, index);
  })
  .catch( (error) => {
    console.error("Error update (restart tracker) doc: ", error);
    errorCallback(error, trackerDocId, index);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Delete given tracker
//-------------------------------------------------------------------
export const deleteTrackerRequest = (docId, index, successCallback, errorCallback) => {
  console.log('firebaseHelper - deleteTrackerRequest()');

  db.collection('tracking_request').doc(docId).delete()
  .then( () => {
    successCallback(docId, index);
  })
  .catch( (error) => {
    console.log('Tracker delete error: ', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Get tracking data (list) for given asin and market
// This is raw data for admin purpose only, we will not show it to user
//-------------------------------------------------------------------
// This function will return tracking list from tracking_data collection in db.
export const getTrackingData = (asin, market, successCallback, errorCallback) => {
  console.log('firebaseHelper - getTrackingData()');
  //console.log('firebaseHelper - getTrackingData() asin:', asin, ' market:', market);

  // 1 - Fetch tracking data for asin+market
  // e.g. tracking_data/{asin}/{ca}/docId
  const subCollection = market.toLowerCase(); // e.g. ca  
  db.collection('tracking_data').doc(asin).collection(subCollection)
  .orderBy('process_date', 'desc')
  .get()
  .then( (querySnapshot) => {
    
    // Create array from received snapshot
    const list = [];
    querySnapshot.forEach( (doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      const data = doc.data();
      data['id'] = doc.id;
      data['process_date'] = data.process_date.toDate();

      // Show -- if field not exist
      if (data.qty_sold === undefined) {
        data['qty_sold'] = '--';
      } 

      list.push(data);
    });

    // Return data via success callback
    successCallback(list);

  })
  .catch( (error) => {
    console.log('getTrackingData error:', error);
    
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Get sales data for given asin and market
// This is processed data that have sold quantity available for each day
//-------------------------------------------------------------------
// This function will show data from tracking_data_sales collection.
export const getSalesData = (asin, market, successCallback, errorCallback) => {
  console.log('firebaseHelper - getSalesData()');
  //console.log('firebaseHelper - getSalesData() asin:', asin, ' market:', market);

  // 1 - Fetch tracking data for asin+market
  // e.g. tracking_data/{asin}/{ca}/docId
  const subCollection = market.toLowerCase(); // e.g. ca  
  db.collection('tracking_data_sales').doc(asin).collection(subCollection)
  .orderBy('sales_date', 'desc')
  .get()
  .then( (querySnapshot) => {
    
    // Create array from received snapshot
    const list = [];
    querySnapshot.forEach( (doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      const data = doc.data();
      data['id'] = doc.id;
      data['sales_date'] = data.sales_date.toDate();
      list.push(data);
    });

    // Return data via success callback
    successCallback(list);

  })
  .catch( (error) => {
    console.log('getSalesData error:', error);
    
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Add days to given date object
//-------------------------------------------------------------------
const addDays = (givenDate, days) => {
  return new Date(givenDate.getTime() + days*24*60*60*1000);
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Call cloud function to scrap url
//-------------------------------------------------------------------
export const scrapUrl = (url, successCallback, errorCallback) => {
  console.log('firebaseHelper - scrapUrl() url:', url);

  // 1 - Prepare data to pass the cloud function
  const data = {
    url: url,
  }

  // 2 - Create cloud function reference
  const scrapUrl = firebase.functions().httpsCallable('scrapUrl');

  // Call cloud function
  scrapUrl(data)
  .then(   (result) => {

    successCallback(result);
    
  }).catch( (error) => {
    console.log('error:', error);

    // Getting the Error details.
    // var code = error.code;
    // var message = error.message;
    // var details = error.details;

    errorCallback(error);

  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Call cloud function to get matching product
//-------------------------------------------------------------------
export const getMatchingProduct = (asin_1, successCallback, errorCallback) => {
  console.log('firebaseHelper - getMatchingProduct() asin_1:', asin_1);

  // 1 - Prepare data to pass the cloud function
  const data = {
    Action: 'GetMatchingProduct',
    ASIN_1: asin_1,
  }

  // 2 - Create cloud function reference
  var processMWS = firebase.functions().httpsCallable('processMWS');

  // Call the cloud function
  processMWS(data)
  .then( (result) => {

    successCallback(result);
    
  }).catch( (error) => {
    console.log('error:', error);

    // Getting the Error details.
    // var code = error.code;
    // var message = error.message;
    // var details = error.details;

    errorCallback(error);

  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getUserType()
//-------------------------------------------------------------------
// Return 'admin' 'editor' for current user, otherwise return '' (empty string)
let userType;  // e.g. 'admin' 'editor' etc.
export const getUserType = (successCallback, errorCallback) => {
  //console.log('firebaseHelper - getUserType()');

  // 1 - If userType value already exist then return it, no need to fetch again.
  if (userType) {
    //console.log('Return saved userType');
    return successCallback(userType);
  } else {
    //console.log('Fetching userType');
  }
  
  // 2 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  //console.log('uid:', uid);

  // 3 - Check that doc exist at admin_users or not
  const docRef = db.collection("admin_users").doc(uid);
  return docRef.get().then( (doc) => {
      if (doc.exists) { // Doc exist within db
        //console.log("Document data:", doc.data());
        
        const data = doc.data();
        if (data.type) { // type field exist within data
          userType = data.type; // 'admin' | 'editor' etc.'
        } else { // type field not exist within data
          userType = '';
        }
        
        successCallback(userType);

      } else { // doc.data() will be undefined in this case
        //console.log("No such document!");
        userType = '';
        successCallback('');
      }
  })
  .catch( (error) => {
    //console.log("Error getting document:", error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Get settings for given tool
//-------------------------------------------------------------------
// e.g. @moduleDocId String e.g. 'potential_product'
let toolSettings = {};
export const getToolSettings = (moduleDocId, successCallback, errorCallback) => {
  //console.log('firebaseHelper - getToolSettings()');

  // 1 - If parameter not received or empty then return error.
  if (!moduleDocId || moduleDocId === '') {
    const error = { code: 'MISSING_PARAMETER', message: 'Missing parameter: moduleDocId' }
    errorCallback(error);
    return; // Important
  }

  // 2 - If cached settings exist then return it.
  const cachedData = toolSettings[moduleDocId];
  if (cachedData) {
    //console.log('Settings returned from cache');
    successCallback(cachedData);
    return; // Important
  }

  // 3 - Read document data
  db.collection('tool_settings').doc(moduleDocId).get()
  .then( (doc) => {
    if (doc.exists) {
      const data = doc.data();
      
      // Save settings to module variable.
      toolSettings[moduleDocId] = data;

      //console.log('Settings returned from db');
      successCallback(data);
      
    } else {
      successCallback(null);
    }
  })
  .catch( (error) => {
    console.log('Get tool settings error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Get given tool settings data for current user
// If current user have not custom settings for given tool then it 
// will return null. Otherwise return tool settings data object for 
// current user. 
//-------------------------------------------------------------------
// e.g. @moduleKey String e.g. 'potential_product'
export const getToolSettingsForUser = (moduleKey, successCallback, errorCallback) => {
  //console.log('firebaseHelper - getToolSettingsForUser()');

  // 1 - If parameter not received or empty then return error.
  if (!moduleKey || moduleKey === '') {
    const error = { code: 'MISSING_PARAMETER', message: 'Missing parameter: moduleKey' }
    errorCallback(error);
    return; // Important
  }

  // 2 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  //console.log('uid:', uid);

  // 3 - Read document data
  db.collection('users').doc(uid).get()
  .then( (doc) => {
    if (doc.exists) {
      const data = doc.data();
      if (data[moduleKey] !== undefined) {
        const settingsData = data[moduleKey];
        
        //console.log('Custom Settings data:', settingsData);
        successCallback(settingsData);
      } else {
        successCallback(null);
      }
    } else { // Return null data
      successCallback(null);
    }
  })
  .catch( (error) => {
    console.log('Get tool settings for user error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Update tool settings data for current user
//-------------------------------------------------------------------
// e.g. @moduleKey String e.g. 'potential_product'
export const updateToolSettingsForUser = (moduleKey, data, successCallback, errorCallback) => {
  //console.log('firebaseHelper - updateToolSettingsForUser()');

  // 1 - If parameter not received or empty then return error.
  if (!moduleKey || moduleKey === '') {
    const error = { code: 'MISSING_PARAMETER', message: 'Missing parameter: moduleKey' }
    errorCallback(error);
    return; // Important
  }

  // 2 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  //console.log('uid:', uid);

  // 3 - Prepeare data to update for given key
  // e.g  updateData = { 
  //        potential_product: data
  //      }
  let updateData = {};
  updateData[moduleKey] = data;

  // 4 - Update data within db
  db.collection('users').doc(uid).update(updateData)
  .then( (doc) => {
    successCallback();
  })
  .catch( (error) => {
    console.log('Update tool settings error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Delete tool settings for current user
//-------------------------------------------------------------------
// This function will set null for given moduleKey under current user.
// e.g. @moduleKey String e.g. 'potential_product'
export const deleteToolSettingsForUser = (moduleKey, successCallback, errorCallback) => {
  console.log('firebaseHelper - deleteToolSettingsForUser()');

  // 1 - If parameter not received or empty then return error.
  if (!moduleKey || moduleKey === '') {
    const error = { code: 'MISSING_PARAMETER', message: 'Missing parameter: moduleKey' }
    errorCallback(error);
    return; // Important
  }

  // 2 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  //console.log('uid:', uid);
  
  // 3 - Set null value for given key
  // e.g  updateData = { 
  //        potential_product: firebase.firestore.FieldValue.delete()
  //      }
  let updateData = {};
  updateData[moduleKey] = null;

  // 4 - Update data within db
  db.collection('users').doc(uid).update(updateData)
  .then( () => {
    successCallback();
  })
  .catch( (error) => {
    console.log('Delete tool settings error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Get seller configuration related status for current user
//-------------------------------------------------------------------
export const getSellerConfigStatus = (successCallback, errorCallback) => {
  console.log('firebaseHelper - getSellerConfigStatus()');

  // 1 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  //console.log('uid:', uid);

  // 2 - Read current user data from db
  db.collection('users').doc(uid).get()
  .then( (doc) => {
  
    // Set default value
    let configStatus = {
      seller_config_date: '',
      seller_config_done: false,
    };

    // If data exist then ser value from there
    if (doc.exists) {
      const data = doc.data();
      if (data.seller_config_date) {
        configStatus['seller_config_date'] = data.seller_config_date.toDate();
      } 
      if (data.seller_config_done) {
        configStatus['seller_config_done'] = data.seller_config_done;
      }
    } 
    
    // Send data back 
    successCallback(configStatus);

  })
  .catch( (error) => {
    console.log(' error:', error);
    
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Is current user logged in to amazon or not.
// true - logged in to amazon, false - not logged in to amazon
//-------------------------------------------------------------------
export const isLoggedInToAmazon = (successCallback, errorCallback) => {
  //console.log('firebaseHelper - isLoggedInToAmazon()'); 

  // 1 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  
  // 2 - Read current user data from db
  db.collection('users').doc(uid).get()
  .then( (doc) => {
  
    // Consider not logged in to amazon (default)
    let isLoggedIn = false;

    // If amazon login related data exist then consider as logged in
    if (doc.exists) {
      const data = doc.data();
      if (data.ad_access_token_NA) {
        if ( data.ad_access_token_NA.access_token && data.ad_access_token_NA.access_token !== '' 
             && data.ad_access_token_NA.refresh_token && data.ad_access_token_NA.refresh_token !== '' ) {
          isLoggedIn = true;
        }
      } 
    } 
    
    // Send data via success callback
    successCallback(isLoggedIn);
  })
  .catch( (error) => {
    console.log('firebaseHelper - isLoggedInToAmazon() error:', error);
    
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Delete amazon login related data from db for current user.
//-------------------------------------------------------------------
export const deleteAmazonLoginDataFromDb = (successCallback, errorCallback) => {
  console.log('firebaseHelper - deleteAmazonLoginDataFromDb()'); 
  
  // 1 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;

  // 2 - Prepare data to remove amazon login related info from db.
  let updateData = { ad_access_token_NA: null };

  // 3 - Update data within db
  db.collection('users').doc(uid).update(updateData)
  .then( () => {
    successCallback();
  })
  .catch( (error) => {
    console.log('firebaseHelper - deleteAmazonLoginDataFromDb() error:', error);

    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Return amazon access token for current user from db.
//-------------------------------------------------------------------
export const getAmazonAccessToken = async () => {
  //console.log('firebaseHelper - getAmazonAccessToken()'); 

  // 1 - Fetch current user uid
  const uid = firebase.auth().currentUser.uid;
  
  // 2 - Read current user data from db
  let accessToken = '';
  
  // 3 - Fetch amazon accees token from db for current user
  try {
    const doc = await db.collection('users').doc(uid).get();
    
    // If access token exist within data then return it.
    if (doc.exists) { 
      const data = doc.data(); 
      if (data.ad_access_token_NA) { 
        if ( data.ad_access_token_NA.access_token ) { 
          accessToken = data.ad_access_token_NA.access_token; 
        } 
      } 
    }

  } catch (error) {
    console.log('getAmazonAccessToken() Error', error);
  }

  return accessToken;
}
//-------------------------------------------------------------------

// Tracker delete confirmation dialogue 
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { marketPlaces } from '../../helpers/constants';


class TrackerRestartDialogue extends React.Component {

  state = {
  }

  componentDidMount = () => {
    //console.log('TrackerRestartDialogue - componentDidMount()');
  }

  componentWillUnmount = () => {
    //console.log('TrackerRestartDialogue - componentWillUnmount()');
  }


  // Inform parent to close dialogue
  onClickCancel = () => {
    this.props.onCancel();
  };

  // Inform parent to proceed for restart
  onClickConfirm = () => {
    this.props.onConfirm();
  }


  render() {
    return (
      <Dialog
        open={this.props.show}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={this.onClickCancel}
        //disableBackdropClick={true}
        //disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >Confirm Start Tracker?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>For Market:</b> { marketPlaces[this.props.market].country_name }  <b>ASIN:</b> { this.props.asin } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClickCancel} color="default" autoFocus>
            Cancel
          </Button>
          <Button onClick={this.onClickConfirm} color="primary" >
            Start
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}


const styles = (theme) => ({

});


TrackerRestartDialogue.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  asin: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
};

export default withStyles(styles)(TrackerRestartDialogue);
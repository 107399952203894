// Amazon Advertising Api: 
// Sponsored Products - Download report generated for sponsored product

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import helper functions, constant etc.
import { getProfiles, spDownloadReport } from '../../helpers/amazonAdHelper';
//import { getCurrentDate_YYYYMMDD, getFutureDate_YYYYMMDD, getPastDate_YYYYMMDD } from '../../helpers/utility';


class SPDownloadReport extends React.Component {
  
  state = {

    // Start - Form input data
    // number: profile id under which report will be requested
    profileId: "",
    
    // Report download location 
    // e.g. 
    //downlodLocation : "https://advertising-api-test.amazon.com/v1/reports/amzn1.clicksAPI.v1.m1.5FF58EE9.7fc09ca0-e82e-425f-9cbc-e96c2130a8fa/download",
    downlodLocation: '',

    // End - Form input data
    

    // Once report downloaded we will set repsonse here
    // e.g.
    // results: { 
    // },
    results: null, 


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],


    // If error occur during any operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SPGetReport - componentDidMount()');

    // 1 - Fetch amazon profile list to fillup dropdown
    // Commented: because we passed profileList as props from parent component
    //this.fetchProfileList();
  }

  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass the profile Id to get campain list api, so we 
  // first will fetch profile list and fillup drop down, so user 
  // can choose profile from there and click Get Camapaign List button
  // to fetch campain list for the selected profile.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    } 
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      
      status: '',
      error: '',
      isProcessing: false,
      
      profileId: '',
      downlodLocation: '', 
      results: '',

    });
  }

  
  //-----------------------------------------------------------------
  // Start: Download a report
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    console.log('onClickSubmit()');

    // Reset error message
    this.setState({ error: ''});

    // 1 - Read form data from state
    const { profileId, downlodLocation } = this.state;

    // 2 - If mandatory info not selected then return
    if (!profileId || profileId === '') { 
      console.log('profileId not selected, so return');
      this.setState({ error: 'Please select profileId.'});
      return;
    }
    if (!downlodLocation || downlodLocation === '') { 
      console.log('location empty, so return');
      this.setState({ error: 'Please enter report location'});
      return;
    }

    // debug
    console.log('profileId:', profileId);
    console.log('downlodLocation:', downlodLocation);
    
    // 4 - Show processing
    this.setState({
      isProcessing: true,
    });
    
    // 5 - Call api to get report
    spDownloadReport(profileId, downlodLocation, this.spDownloadReport_Success, this.spDownloadReport_Error);
  }

  // Called when api call success
  // e.g. result: { 
  //   status: 'success', 
  //   data: {
  //    message: 'Report Downloaded.' 
  //   },
  //   octetStreamData: 'String', 
  //   error: null 
  // }
  spDownloadReport_Success = (result) => {
    console.log('spDownloadReport_Success() result:', result);
  
    if (result.status === 'success') {

      // If octet stream data exist then download file via browser
      if (result.octetStreamData) {
        const blob = new Blob([result.octetStreamData]);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "Report.csv";
        link.click(); 
      }

      this.setState({
        results: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }

  // Called if any error while calling api
  spDownloadReport_Error = (error) => {
    console.log('spDownloadReport_Error() error:', error);
    
    this.setState({
      error: error.toString(),
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Download a report
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-profile-label">Choose Profile</InputLabel>
            <Select
              id="id-profile-id"
              labelId="choose-profile-label"
              value={profileId}
              onChange={(e) => this.setState({ profileId: e.target.value })}
              disabled={isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="downlod-location" 
              label="Report Location (i.e. report download url)" 
              placeholder="" 
              value={this.state.downlodLocation} 
              onChange={(e) => this.setState({ downlodLocation: e.target.value })} 
              size="small" 
              disabled={isProcessing}
              InputLabelProps={{ shrink: true, }}
              helperText="Please enter location received via Get Report api."
            />
          </FormControl>
        </Grid>

      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' }
        >Downlaod Report</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing}
        >Clear</Button>
      </div> 
    );
  }
  
  // Render result received
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { results } = this.state;
    if ( !results ) { return null; }

    // Render result
    // e.g. results = {
    // }
    const resultEl = Object.keys(results).map((key, index) => {
      return (
        <React.Fragment key={ 'download-result-' + index } >
          <b>{key}:</b> { results[key] } <br />
        </React.Fragment>
      );
    });

    return (
      <div align='left'>
        <b>Result:</b>
        <hr />
        { resultEl }
        <br />
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion elevation={4} >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Download Report</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h4 style={{ color: 'red' }} >{error}</h4> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SPDownloadReport.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SPDownloadReport);


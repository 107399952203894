// Amazon Advertising Api: 
// DSP Advertisers:  Returns a list of advertisers with information which satisfy the filtering criteria.
// GET: /dsp/advertisers
// 
// Help: https://advertising.amazon.com/API/docs/en-us/dsp-advertiser/#/Advertiser/get_dsp_advertisers

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';


// Import helper functions, constant etc.
import { dspGetAdvertisers } from '../../helpers/amazonAdHelper';
//import { getProfiles } from '../../helpers/amazonAdHelper';


class DspGetAdvertisers extends React.Component {
  
  state = {

    // Form input data
    profileId: '',    // Selected profile id

    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],

    // We will fetch advertoser list from amazon ad api and set it here.
    // e.g. 
    // advertiserList: [
    //   {
    //     "advertiserId": "4728736040201",
    //     "name": "DSP Public API Advertiser",
    //     "currency": "USD",
    //     "url": "www.example.com",
    //     "country": "US",
    //     "timezone": "string"
    //   }
    // ],
    advertiserList: null,
    
    // If any error occur during some operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('DspGetAdvertisers - componentDidMount()');

    // Fetch amazon profile list
    // Commented: because we passed profileList as props from parent component 
    //this.fetchProfileList();
  }

  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass profile Id to server side api, so first 
  // we will fetch profile list and fillup drop down so user can choose it.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    //getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',

      profileId: '',
      advertiserList: null,

      isProcessing: false,
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Fetch Advertisers (list)
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // 1 - Get profileId from the state
    const { profileId } = this.state;

    // 2 - If profileId not selected then return
    if ( !profileId || profileId === '' ) {
      return;
    }

    // 3 - Show processing
    this.setState({
      isProcessing: true,
      advertiserList: null,
    });

    // 4 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/dsp-advertiser/#/Advertiser/get_dsp_advertisers
    // @startIndex: (integer) Sets a cursor into the requested set of advertisers. 
    //              Use in conjunction with the count parameter to control pagination 
    //              of the returned array. 0-indexed record offset for the result set, 
    //              (defaults to 0.)
    // 
    // @count: (Integer) Sets the number of advertisers to be returned in a single call. (default 20)
    //
    // @advertiserIdFilter: (String) List of comma separated advertiser ids to filter the advertisers. 
    //     If no advertiser ids provided, all advertisers in this entity will be returned.
    const queryData = {
      startIndex: 0,
      count: 20,
      advertiserIdFilter: '6753579448901'
    }

    // 5 - Call api to Get Advertiser (list) for selected profile Id.
    dspGetAdvertisers(profileId, queryData, this.dspGetAdvertisers_Success, this.dspGetAdvertisers_Error);
  }

  // Called when advertisers (list) fetched successfully
  // e.g. result: { 
  //     status: 'success', 
  //     data: [
  //      {
  //        "totalResults": 1100,
  //        "response": [
  //          {
  //            "advertiserId": "4728736040201",
  //            "name": "DSP Public API Advertiser",
  //            "currency": "USD",
  //            "url": "www.example.com",
  //            "country": "US",
  //            "timezone": "string"
  //          }
  //        ]
  //      }
  //    ],
  //    error: null 
  // }
  dspGetAdvertisers_Success = (result) => {
    console.log('dspGetAdvertisers_Success() result:', result);

    if ( result.status === 'success' ) {
      this.setState({
        advertiserList: result.data.response,
        isProcessing: false,
      });
    }

    if ( result.status === 'error' ) {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while fetch advertisers (list)
  dspGetAdvertisers_Error = (error) => {
    console.log('dspGetAdvertisers_Error() error:', error);
    
    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch Advertisers (list)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  // Called whenever user will select profile id from dropdown
  onChangeProfileId = (profileId) => {
    //console.log('onChangeProfileId() profileId:', profileId);

    this.setState({
      profileId: profileId,
      advertiserList: null,
    });
  }
  
  renderForm = () => {
    const { isProcessing, profileId } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="choose-profile-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="choose-profile-label"
              value={profileId}
              onChange={ (e) => this.onChangeProfileId(e.target.value) }
              disabled={ isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { id, type, name } = accountInfo;
                  return (<MenuItem key={profileId} value={profileId}>{profileId} / {countryCode} / {id} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  renderFormButton = () => {
    const { isProcessing, profileId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' } 
        >Get Advertiser List</Button>
        <Button  
          variant="contained"  
          size="small"  
          onClick={this.onClickClear}  
          disabled={isProcessing}  
        >Clear</Button>  
      </div> 
    );
  }
  
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { advertiserList, profileId } = this.state;

    // If list variable not exist then do not show anything
    if ( !advertiserList ) { return null; }

    // If list empty array then show message.
    if ( advertiserList.length === 0 ) {
      return ( 
        <div align='left'>
          <h3>Advertiser list empty for profileId: { profileId }</h3>
        </div>
      );
    }

    // If list exist then render its content
    // e.g. 
    // advertiserList: [
    //   {
    //     "advertiserId": "4728736040201",
    //     "name": "DSP Public API Advertiser",
    //     "currency": "USD",
    //     "url": "www.example.com",
    //     "country": "US",
    //     "timezone": "string"
    //   }
    // ],
    return (
      <div align='left'>
        <b>Advertiser  List for: { profileId } </b> <br />
        <hr />
        { 
          advertiserList.map( (item, index) => {
            return (
              <div key={ 'advertiser_' + index } >
                <b>#{ index + 1 }</b> <br />
                <b>advertiserId:</b> { item.advertiserId } <br />
                <b>name:</b> { item.name } <br />
                <b>currency:</b> { item.currency } <br />
                <b>url:</b> { item.url } <br />
                <b>country:</b> { item.country } <br />
                <b>timezone:</b> { item.timezone } <br />
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>Amazon DSP - GetAdvertisers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


DspGetAdvertisers.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(DspGetAdvertisers);


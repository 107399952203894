
//----------------------------------------------------------------------------
// Firebase auth related functions are written in this module
//----------------------------------------------------------------------------
// isUserLoggedIn()
// getCurrentUser()
// getCurrentUserEmail()
// getCurrentUserPhotoUrl()
// getCurrentUserUid()
// loginWithEmailPassword()
// registerWithEmailPassword()
// sendVerificationEmail()
// isCurrentUserEmailVerified()
// logoutCurrentUser()
//----------------------------------------------------------------------------


//-------------------------------------------------------------------
// Import firebase app and other module need for this app.
//-------------------------------------------------------------------
import firebase from './firebaseApp';
export const db = firebase.firestore();


//----------------------------------------------------------------------------
// Is user logged in to (firebase)
//----------------------------------------------------------------------------
export const isUserLoggedIn = () => {
  //console.log('isUserLoggedIn()');

  if (firebase.auth().currentUser) {
    return true;
  } else {
    return false;
  }
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Get current logged in (firebase) user
//----------------------------------------------------------------------------
export const getCurrentUser = () => {
  //console.log('getCurrentUser()');

  if (firebase.auth().currentUser) {
    return firebase.auth().currentUser;
  } else {
    return null;
  }
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Get current logged in (firebase) user's email
//----------------------------------------------------------------------------
export const getCurrentUserEmail = () => {
  //console.log('getCurrentUserEmail()');

  // 1 - If current user not exist then return empty string
  if (!firebase.auth().currentUser) {
    return '';
  } 

  // 2 - Read email from current user
  const { currentUser } = firebase.auth();
  let email = currentUser.email;
  
  // 3 - If email null or empty then get it from providerData[]
  if( !email || email === '') {
    currentUser.providerData.forEach((provider) => {
      if ( provider.email && provider.email !== '' ){
        //console.log('provider.email:', provider.email);
        email = provider.email;
      }
    });
  }

  // 4 - Return email
  return email;
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Get current logged in (firebase) user's photoURL
//----------------------------------------------------------------------------
export const getCurrentUserPhotoUrl = () => {
  //console.log('getCurrentUserPhotoUrl()');

  if (firebase.auth().currentUser) {
    if (firebase.auth().currentUser.photoURL) {
      return firebase.auth().currentUser.photoURL;
    } 
  } 

  return '';
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Get current logged in (firebase) user's uid
//----------------------------------------------------------------------------
export const getCurrentUserUid = () => {
  //console.log('getCurrentUserUid()');

  if (firebase.auth().currentUser) {
    return firebase.auth().currentUser.uid;
  } else {
    return '';
  }
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Do firebase Login with email/password
//----------------------------------------------------------------------------
export const loginWithEmailPassword = (email, password, successCallback, errorCallback) => {
  //console.log('loginWithEmailPassword()');

  firebase.auth().signInWithEmailAndPassword(email, password)
  .then(() => { // Login success
    successCallback();
  })
  .catch( (error) => { // Login error
    errorCallback(error);
  });
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Register firebase user with email/password
//----------------------------------------------------------------------------
export const registerWithEmailPassword = (name, email, password, successCallback, errorCallback) => {
  //console.log('registerWithEmailPassword()');

  firebase.auth().createUserWithEmailAndPassword(email, password)
  .then( (response) => { // Register Successfully
    //console.log ('response:', response);
    
    // Grab user info from response
    // e.g. response.user = { 
    //   "emailVerified":false,
    //   "uid":"gJzD8nwUGuRkO4tU2BvBNPneep02",
    //   "providerId":"firebase",
    //   "providerData":[{"providerId":"password","uid":"test3@test.com","email":"test3@test.com"}],
    //   "displayName":null,
    //   "refreshToken":"AEuslgjldfjkdjfgd...",
    //   "email":"test3@test.com",
    //   "isAnonymous":false,
    //   "metadata":{"creationTime":1582034696174,"lastSignInTime":1582034696174},
    //   "photoURL":null,
    //   "phoneNumber":null
    // }
    const { user } = response;
    
    // Prepare info to create user within db
    const currentDate = new Date();
    const userInfo = {
      name: name,
      email: user.email,
      create_date: new Date(),
      last_login_date: currentDate, 
      last_login_method: 'password',    // i.e. login via email/password 
      photo_url: '',
      photo_source: '', // social - photoUrl grabbed from social provider,
                        // user - user uploaded photo url (stored in firebase storage)  
                        // '' - source not indetified yet.
      default_info_set: true,
      amazon_advertising_api_mode: 'live',  // 'test' or 'live'
    }

    // Create newly registered user info within db.
    db.collection("users").doc(user.uid)
    .set(userInfo, {merge: true})
    .then( () => { // User info created within db.
      if (successCallback) { successCallback() }
    })
    .catch( (error) => { // Error while save user info within db
      console.error("User Info Create Errror: ", error);
      if (errorCallback) { errorCallback(error) }
    });

  })
  .catch( (error) => { // Register error
    if (errorCallback) { errorCallback(error) }
  });

}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// createUserInfo()
// This function shared by both Google and Faceboook sign in.
//-----------------------------------------------------------------------------
// This function will create user basic info within database.
// How It Works: First it will check that user basic info exist within database
// or not, if not exist then create it for the given user, otherwise not.
export const createUserInfo = (user, providerId) => { 
  console.log("createUserInfo() user.uid:", user.uid, "providerId:", providerId);
  //console.log("createUserInfo() user:", JSON.stringify(user), " providerId:", providerId);
  
  // 1 - Fetch available displayName and photoUrl from auth normal profile or
  // from provider profile for current user auth()
  var displayName = getDisplayName(user, providerId);
  var photoURL = getPhotoUrl(user, providerId);
  //console.log("Found displayName:", displayName, " photoURL:", photoURL); // Debug

  // 2 - Update displayName & photoUrl within firebase Auth Object.
  // i.e. If firebase.auth().currentUser.displayName / photoURL not set then set it.
  updateDisplayNameInAuth(displayName);
  updatePhotoUrlInAuth(photoURL);

  // 3 - Create data for user
  const userInfo = {
    name: displayName,
    email: user.email,    
    last_login_date: new Date(),
    last_login_method: providerId,
    photo_url: photoURL,
    photo_source: 'social',   // social - photoUrl from social provider,
                            // user - user uploaded photo url (stored in firebase storage)
    default_info_set: true,
    amazon_advertising_api_mode: 'live',  // 'test' or 'live'
  }

  // 4 - Fetch basic info for user and check that default info set by us in
  // the past or not. If user basic info already set in the past then we will
  // not do anything, Otherwise we will set user basic info within db
  db.collection('users').doc(user.uid)
  .get()
  .then( (doc) => {
    
    // .1 - If user info exist and default info set in the past
    // then no need to do anything, just redirect to dashboard page.
    if (doc.exists) {
      const data = doc.data();
      if ( data.default_info_set && (data.default_info_set === true) ) {
        console.log('User info already set, No need to set again.'); // Debug

        // Update last login info
        const updateInfo = {
          last_login_date: new Date(),
          last_login_method: providerId,
        };
        
        db.collection('users').doc(user.uid).set( updateInfo, { merge: true})
        .then( () => { 
          console.log("Last login info updated.");
        })
        .catch( (error) => {
          console.log("Last login info update error: ", JSON.stringify(error));
        });

        return; // Important
      }
    }
    console.log("Creating User Info as:", JSON.stringify(userInfo) );

    // .2 - Create user info within database.
    // Merge with existing data if other key value exist within a doc.
    db.collection('users').doc(user.uid).set( userInfo, { merge: true} )
    .then( () => {
      console.log("User Default Info Created.");
    })
    .catch( (error) => {
      console.log("Error Creating User Default Info: ", JSON.stringify(error));
      logoutCurrentUser(); // Sign out because sign in process not completed fully
    });

  })
  .catch( (error) => {
    console.log("Error fetching user info document:", error);
    logoutCurrentUser(); // Sign out because sign in process not completed fully
  });

}

// This function will provide display name from firebase.auth().currentUser object
// If display name not exist within basic info then it will find it from
// providerData array.
const getDisplayName = (user, providerId) => {
  
  // If display name exist within user object then return it.
  if (user.displayName) {
    if(user.displayName !== "") { return user.displayName }
  }

  // Otherwise search display name from the given provider data and return it.
  var displayName = "";
  user.providerData.forEach( (provider) => {
    //console.log(" provider.providerId", provider.providerId, " provider.photoURL", provider.photoURL);
    if (provider.providerId === providerId) {
      displayName = provider.displayName;
    }
  });

  return displayName;
}

// This function will provide photoURL from firebase.auth().currentUser object
// If photoURL not exist within basic info then it will find from providerData array.
const getPhotoUrl = (user, providerId) => {
  // If photo URL exist within user object (basic info) then return it.
  if(user.photoURL) {
    if(user.photoURL !== "") { return user.photoURL }
  }

  // Otherwise search photo url from the given provider data and return it.
  var photoURL = "";
  user.providerData.forEach( (provider) => {
    //console.log(" provider.providerId", provider.providerId, " provider.photoURL", provider.photoURL);
    if (provider.providerId === providerId) {
      photoURL = provider.photoURL;
    }
  });

  return photoURL;
}

// Update displayName within firebase Auth Object basic info.
const updateDisplayNameInAuth = (displayName) => {
  // If displayName not set then set it
  if (!firebase.auth().currentUser.displayName) {
    firebase.auth().currentUser.updateProfile({ displayName: displayName });
    console.log("displayName Set in auth() ", displayName);
  }
}

// Update photoURL within firebase Auth Object basic info.
const updatePhotoUrlInAuth = (photoURL) => {
  // If photoURL not set then set it
  if (!firebase.auth().currentUser.photoURL) {
    firebase.auth().currentUser.updateProfile({ photoURL: photoURL });
    console.log("photoURL Set in auth() ", photoURL);
  }
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Send verification email for current user
//----------------------------------------------------------------------------
export const sendVerificationEmail = async () => {
  
  // 1 - Get current user
  const { currentUser } = firebase.auth();

  // 2 - If current user not exist then return false
  if (!currentUser) {
    return false;
  }

  // 3 - Send email verification
  try {
    await currentUser.sendEmailVerification();
    return true;
  } catch (error) {
    console.log('Verification email send error:' ,error);
    return false;
  }
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Current logged in (firebase) user's email address verified or not.
// Return: true - email verified, false - not verified
//----------------------------------------------------------------------------
export const isCurrentUserEmailVerified = () => {
  //console.log('isCurrentUserEmailVerified()');

  // 1 - Get current user
  const { currentUser } = firebase.auth();

  // 2 - If current user not exist then return false
  if (!currentUser) {
    return false;
  }
  
  // 3 - Return email varified or not (true/false)
  return currentUser.emailVerified;
}
//----------------------------------------------------------------------------


//----------------------------------------------------------------------------
// Logout current firebase user
//----------------------------------------------------------------------------
export const logoutCurrentUser = (successCallback, errorCallback) => {
  console.log('firebaseAuthHelper - logoutCurrentUser()');

  firebase.auth().signOut()
  .then(() => { // Sign-out successful.
    if (successCallback) { successCallback() }
  })
  .catch( (error) => { // An error happened.
    if (errorCallback) { errorCallback() }
  });
}
//----------------------------------------------------------------------------


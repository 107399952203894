// Profit Calculator component
// It consist multiple component i.e. form, result etc.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


// Import custom component
import ProfitCalculatorForm from './ProfitCalculatorForm';

// Import helper functions
import { getCompetitivePricingForASIN, getMyFeesEstimate } from '../../helpers/mwsHelper';
//import { getProductListingPrice } from '../../helpers/apiHelper';


class ProfitCalculator extends React.Component { 

  state = { 
    // Whenever user will fillup form and click submit button, form child component
    // will send form data object to this, so we will save it to this state variable.
    // e.g. formData = {
    //          market: 'CA',             // CA, US etc. 
    //          asin: 'B0179IU3XE',       // single asin
    //          currencyCode: 'CAD',      // CAD, USD etc.
    //          isAmazonFulfilled: true,  // true - fulfilled by amazon, false - not fulfilled
    //          sourceCost: 0,            // Buy price / Source price / wholesale price (per unit)
    //          shippingCost: 0,          // Shpping cost (per unit)
    //          advertisementCost: 0,     // product advertisement cost (per unit)
    //        }
    formData: {},
    
    // We will fetch list price, currency code via api and set here.
    // We are not taking list price input from user, so we will fetch list price 
    // using getCompetitivePricingForASIN() api and save it here.
    // So thereafter we can call GetMyFeesEstimate() mws api and calculate profit.
    listPrice: 0,
    listPriceCurrencyCode: '',  // e.g. 'CAD', 'USD' etc.

    // We will fetch fee estimate and save result here, so based on this 
    // we can calculate profit etc.
    FeesEstimateResult: null,  // (starts with capital letter)

    // true - processing in progress, false - not processing anything
    // It will show progress bar when processing true.
    isProcessing: false,

    // Message text
    showMessage: false, // true - show message, false - hide message
    messageText: '',    // message test supose to show
  }

  // Whenever user click submit button from form component, 
  // this function called with form data.
  // e.g. formData = {
  //          market: 'CA',             // CA, US etc. 
  //          asin: 'B0179IU3XE',       // e.g B015CH1PJU (single asin)
  //          currencyCode: 'CAD',      // CAD, USD, etc.  
  //          isAmazonFulfilled: true,  // true - fulfilled by amazon, false - not fulfilled
  //          sourceCost: 0,           // Buy price / Source price / wholesale price (per unit)
  //          shippingCost: 0,         // Shpping cost (per unit)
  //          advertisementCost: 0,    // product advertisement cost (per unit)
  //        }
  onSubmitForm = (formData) => {
    console.log('onSubmitForm() formData:', formData);
    
    // 1 - Start processing, also Save form data to state, 
    // so we can use it later on.
    this.setState({
      isProcessing: true,
      formData: formData,
      FeesEstimateResult: null,
    });
    
    // 2 - Call api to fetch product listing (competitive) price.
    getCompetitivePricingForASIN(formData.market, formData.asin, this.getCompetitivePricingForASIN_Success, this.getCompetitivePricingForASIN_Error);
  }


  //-----------------------------------------------------------------
  // START: Fetch product list price related functions
  //-----------------------------------------------------------------  
  // Called if Mws api call success
  getCompetitivePricingForASIN_Success = (result) => {
    console.log("getCompetitivePricingForASIN_Success() result:", result);

    // 1 - If multiple asin supplied to mws api then result.data consist array of products, 
    // otherwise it is data object (not array) with single product info.
    //const { data, status, error } = result;
    const { data } = result;
    
    // 2 - We called mws api with single asin so in our case it return one product as data object, 
    // so here we written logic to handle single product data.
    let product;
    if (data.Product) { product = data.Product }
    //console.log("product:", product);

    // 3 - Fetch competitive price.
    const { CompetitivePrice } = product.CompetitivePricing.CompetitivePrices;
    //console.log('CompetitivePrice:', CompetitivePrice);

    // 4 - If CompetitivePrice not available (may be product currently not available) then return message.
    if (!CompetitivePrice) {
      this.setState({
        showMessage: true,
        messageText: "Product list price not available, please try another asin.",
        isProcessing: false,
      });
      return;  // IMPORTANT
    }

    // 5 - CompetitivePrice may be single data object or array, so we have to handle it.
    // If array then it may have price for 'Used' or 'New' condition item so we have to find 
    // out price for 'New' codition.
    let ListingPrice;
    if (Array.isArray(CompetitivePrice)) { // If array
      CompetitivePrice.forEach( (item, index) => { // Loop array and find ListingPrice with 'New' condition.
        if (!ListingPrice) { // listing price not found yet then find it
          if (item.condition === 'New') { // Grab price if item condition is new.
            ListingPrice = item.Price.ListingPrice;
          }
        }
      });
    } else { // Price as single data object (not array)
      ListingPrice = CompetitivePrice.Price.ListingPrice;
    }
    //console.log('ListingPrice:', ListingPrice);

    // 6 - Set data within state
    this.setState({
      listPrice: parseFloat(ListingPrice.Amount),
      listPriceCurrencyCode: ListingPrice.CurrencyCode,
    });

    // 7 - Fetch estimated fee for current product
    this.fetchEstimatedFeeInfo();
  }
  
  // Called if any error while calling mws api call.
  getCompetitivePricingForASIN_Error = (error) => {
    console.log("getCompetitivePricingForASIN_Error() error: ", error);
    
    this.setState({
      showMessage: true,
      messageText: "Product list price not available, please try another asin.",
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // END: Fetch product list price related functions
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: Fetch estimated fee related functions
  //-----------------------------------------------------------------
  // Fetch estimated fee for the asin, so based on that result 
  // we will calculate profit for the product.
  fetchEstimatedFeeInfo = () => {
    
    // 1 - Fetch formData and product listPrice from state.
    // Before this step we saved listPrice within state.
    const { formData, listPrice } = this.state;

    // 2 - prepare data to pass the api
    const data = { 
      market: formData.market, 
      idType: 'ASIN',
      asin: formData.asin, 
      isAmazonFulfilled: formData.isAmazonFulfilled, 
      listPriceAmount: listPrice, 
      listPriceCurrency: formData.currencyCode, 
      //shippingAmount: formData.shippingCost, 
      shippingAmount: 0,   // We will always pass 0 to mws api, and deduct ourself to calculate profit.
      shippingCurrency: formData.currencyCode, 
      identifier: 'hello', // It can be any custom identifier
      pointsNumber: 0, 
    };
    console.log('api data:', data);

    // 3 - Call api to find fee estimate for the product
    getMyFeesEstimate(data, this.getMyFeesEstimate_Success, this.GetMyFeesEstimate_Error);
  }

  // Called if fee estimate received successfully
  getMyFeesEstimate_Success = (result) => {
    console.log("getMyFeesEstimate_Success() result:", result);

    //const { status, error } = result;
    const { data } = result;
    const { FeesEstimateResult } = data.FeesEstimateResultList;

    // Set value within state
    this.setState({
      FeesEstimateResult: FeesEstimateResult,
      isProcessing: false,
      //status: status,
      //error: error,
    });

  }

  // Called if error while api call
  GetMyFeesEstimate_Error = (error) => {
    console.log("GetMyFeesEstimate_Error() error: ", error);
    
    this.setState({
      showMessage: true,
      messageText: "Fee estimate not available at present, please try again.",
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // END: Fetch estimated fee related functions
  //-----------------------------------------------------------------


  // Called when message close after time out
  handleMessageClose = () => {
    //console.log('handleMessageClose()');
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }  


  // Render more (sub) details for a fee. Some main fee like FBA fees consist 
  // details information about that, so this functionw will render it.
  renderMoreDetails = (FeeDetail) => {

    // If fee deail not exist then return null
    if (!FeeDetail) {
      return null;
    }

    // We may receive fee details as single data object or array. So we have to 
    // convert into universal format i.e. array, so we can render it in loop.
    let feeDetailArray = [];
    if (Array.isArray(FeeDetail)) { // array
      feeDetailArray = FeeDetail;
    } else {  // not array, convert to array
      feeDetailArray = [FeeDetail];
    }

    // Loop array and render each row from that
    const element = feeDetailArray.map((detail, index) => {
      const feeAmountExist = parseFloat(detail.FeeAmount.Amount) === 0 ? false : true;
      const feePromotionExist = parseFloat(detail.FeePromotion.Amount) === 0 ? false : true;
      let content1 = '';
      let content2 = '';
      if (feeAmountExist || feePromotionExist) {
        if (feeAmountExist) {
          content1 = detail.FeeType + ': ' + detail.FeeAmount.CurrencyCode + ' ' + detail.FeeAmount.Amount;
        }
        if (feePromotionExist) { 
          content2 = 'Promotion: ' + detail.FeePromotion.CurrencyCode + ' ' + detail.FeePromotion.Amount 
        }
      }

      // If both content empty then return null
      if ( content1 === '' && content2 === '' ) { return null; }
      
      // Retuen content to render
      return (
        <div key={'fee-sub-detail-' + index } >
          {content1} &nbsp;&nbsp; {content2} <br/>
        </div>
      );

    });

    return element;
  }


  // Render fee details in table format
  renderFeeDetails = (FeeDetailList) => {
    console.log('FeeDetail:', FeeDetailList);
    const { classes } = this.props;
    const { formData } = this.state;
    //const { PriceToEstimateFees } = this.state.FeesEstimateResult.FeesEstimateIdentifier;

    return(
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>FeeType</TableCell>
            <TableCell align="right">Fee Amount</TableCell>
            <TableCell align="right">Final Fee</TableCell>
            <TableCell align="left">Fee Detail List</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          FeeDetailList.map( (row, index) => {
            const { IncludedFeeDetailList } = row;
            let FeeDetail;
            if (IncludedFeeDetailList && IncludedFeeDetailList.FeeDetail) {
              FeeDetail = IncludedFeeDetailList.FeeDetail;
            }

            let promotionFee = '';
            if (row.FeePromotion.Amount > 0) {
              promotionFee = 'Promotion:' + row.FeePromotion.Amount;
            }

            // Render null if fee amount zero (Do not render row)
            if ( parseFloat(row.FeeAmount.Amount) === 0 && parseFloat(row.FeePromotion.Amount) === 0 && parseFloat(row.FinalFee.Amount) === 0) {
              return null;  // Early return
            }

            return (
              <TableRow key={ 'fee-details-' + index}>
                <TableCell component="th" scope="row">
                  { row.FeeType }
                </TableCell>
                <TableCell align="right">
                  { row.FeeAmount.Amount } 
                  { promotionFee !== '' && '&nbsp;' }
                  { promotionFee }
                </TableCell>
                <TableCell align="right">
                  { row.FinalFee.Amount }
                </TableCell>
                <TableCell align="left">
                  { this.renderMoreDetails(FeeDetail) } 
                </TableCell>
              </TableRow>
            )
          })
        }
        
          <TableRow key={ 'fee-details-source-cost'}>
            <TableCell component="th" scope="row">Sourcing/Wholesale Buy Cost:</TableCell>
            <TableCell align="right">{parseFloat(formData.sourceCost).toFixed(2) }</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
          { formData.shippingCost > 0 && 
          <TableRow key={ 'fee-details-shipping-cost'}>
            <TableCell component="th" scope="row">Shipping Cost:</TableCell>
            <TableCell align="right">{parseFloat(formData.shippingCost).toFixed(2) }</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
          }
          { formData.advertisementCost > 0 && 
          <TableRow key={ 'fee-details-advertisement-cost'}>
            <TableCell component="th" scope="row">Advertisement Cost:</TableCell>
            <TableCell align="right">{ parseFloat(formData.advertisementCost).toFixed(2) }</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
    );
  }


  // Result FeesEstimateResult result within ui
  renderResult = () => {

    // If FeesEstimateResult not exist then return null (do not render anything)
    const { FeesEstimateResult } = this.state;
    if (!FeesEstimateResult) { return null }

    // Debug
    //console.log('FeesEstimateResult:', FeesEstimateResult);

    // Render data for fee estimate
    const { formData } = this.state;
    const { FeesEstimate, FeesEstimateIdentifier } = FeesEstimateResult;
    const { FeeDetailList, TotalFeesEstimate } = FeesEstimate;
    const { PriceToEstimateFees } = FeesEstimateIdentifier;

    let totalExpense = parseFloat(TotalFeesEstimate.Amount) + parseFloat(formData.sourceCost) + parseFloat(formData.shippingCost) + parseFloat(formData.advertisementCost);
    totalExpense = totalExpense.toFixed(2);
    //const totalExpenseString = `(${TotalFeesEstimate.Amount} + ${formData.sourceCost} + ${formData.shippingCost} + ${formData.advertisementCost})`;
    
    let profitLossAmount = parseFloat(PriceToEstimateFees.ListingPrice.Amount) - parseFloat(totalExpense);
    profitLossAmount = profitLossAmount.toFixed(2);
    //const profitOrLossString = `(${PriceToEstimateFees.ListingPrice.Amount} - ${totalExpense})`;

    let profitOrLossText = 'Profit';
    if ( profitLossAmount > 0 ) {
      profitOrLossText = 'Profit';
    } else if (profitLossAmount < 0) {
      profitOrLossText = 'Loss';
    }

    return(
      <div>
        <b>Fee Details:</b> <br />
        { this.renderFeeDetails(FeeDetailList.FeeDetail) }
        
        <h3>Listing Price (per unit): { PriceToEstimateFees.ListingPrice.CurrencyCode + ' ' + PriceToEstimateFees.ListingPrice.Amount }</h3>
        
        { profitLossAmount > 0 && 
          <h2 style={{color: 'green'}} >{profitOrLossText} (per unit): { formData.currencyCode + ' ' + profitLossAmount }</h2>
        }
        { profitLossAmount <= 0 && 
          <h2 style={{color: 'red'}} >{profitOrLossText} (per unit): { formData.currencyCode + ' ' + profitLossAmount }</h2>
        }
        
        {
        // <b>Total Fees Estimate:</b> { TotalFeesEstimate.CurrencyCode + ' ' +  TotalFeesEstimate.Amount } &nbsp;&nbsp;
        // (Time Of Fees Estimation: { TimeOfFeesEstimation }) <br />
        // <b>Total Expense (per unit): </b> { formData.currencyCode + ' ' + totalExpense } { totalExpenseString } <br />
        }
      </div>
    )
  }


  render() {
    //const { classes } = this.props;
    const { isProcessing } = this.state;
    
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return(
      <React.Fragment>
        <ProfitCalculatorForm 
          onSubmit={this.onSubmitForm} 
          isProcessing={ isProcessing }
        />
        
        { isProcessing && <LinearProgress /> }

        <div style={{height: 20}} ></div>
        
        { this.renderResult() }

        <br /><br />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleMessageClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </React.Fragment>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

  table: {
    minWidth: 650,
  },

});


ProfitCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(ProfitCalculator);
// Inventory tracker
// It consist multiple component, i.e. form, list etc.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

// Inventory tracker related components
import TrackerForm from './TrackerForm';
import TrackerList from './TrackerList';
import SalesDataModal from './SalesDataModal';
import TrackerDeleteDialogue from './TrackerDeleteDialogue';
import TrackerRestartDialogue from './TrackerRestartDialogue';

// Import helper functions, constant etc.
import { getTrackerList, deleteTrackerRequest, restartTracker, getSingleTracker } from '../../helpers/firebaseHelper';


class InventoryTracker extends React.Component {
  
  state = {

    // Tracker created by current user
    trackerList: [],

    isProcessing: false,
    
    // This data we will show to user
    // Whenever user click sales data icon from a single record (from the list). 
    // we will fill up this variable and show modal with sales data for asin+market.
    // This modal will show data from tracking_data_sales collection.
    showSalesDataModal: false,
    salesClickedIndex: -1,   // -1 means not clicked any record
    salesClickedId: '',      // requestId
    salesClickedAsin: '',    // e.g. ABD86SDFS45DG
    salesClickedMarket: '',  // e.g. US
    salesClickedImageUrl: '',  // e.g. small image url 

    // Whenever user click delete icon for a single record (from the list), 
    // we will show dialogue box to confirm delete. If user will confirm
    // then we will proceed to delete, otherwise not.
    showDeleteDialogue: false,
    deleteIndex: -1,
    deleteId: '',
    deleteAsin: '',
    deleteMarket: '',

    // Whenever user will click start tracker icon for a single record
    // We will show dialogue to confirm the tracker start. If user 
    // confirm then it will proceed to restart tracker, otherwise not.
    showRestartDialogue: false,
    restartIndex: -1,     // restart tracker indes
    restartId: '',        // restart tracker id
    restartAsin: '',
    restartMarket: '',

    // Message text
    showMessage: false,
    messageText: "",
  }

  componentDidMount = () => {
    console.log('InventoryTracker - componentDidMount()');
    this.fetchData();
  }

  // Fetch tracker list from db.
  fetchData = () => {
    getTrackerList(this.getTrackerList_Success, this.getTrackerList_Error);
  }

  // Fetch tracker list success
  getTrackerList_Success = (list) => {
    //console.log('getTrackerList_Success()');
    console.log('getTrackerList_Success() list:', list);

    this.setState({
      trackerList: list,
    })
  }

  // Error while fetch tracker list
  getTrackerList_Error = (error) => {
    console.log('getTrackerList_Error() ');

  }
  
  
  //-------------------------------------------------------------------
  // START: Tracker request delete related functions
  //-------------------------------------------------------------------
  // Called by child component when delete button tapped for any record
  // We will show delete confirmation dialogue, if user confirm delete 
  // then we will delete record, otherwise not.
  onClickDelete = (id, index) => {
    console.log('onClickDelete() index:', index);
    //console.log('onClickDelete() id:', id, ' index:', index);
    
    const { asin, market } = this.state.trackerList[index];

    // Show delete confirmation dialogue
    this.setState({
      deleteIndex: index,
      deleteId: id,
      deleteMarket: market,
      deleteAsin: asin,
      showDeleteDialogue: true,
    });
  }

  // If user click Cancel button from delete confirmation dialogue, this function called.
  // So no need to delete record due to cancel button clicked from dialogue.
  onCancelDelete = () => {
    //console.log('onCancelDelete()');

    // Close delete confirmation dialogue
    this.setState({
      deleteIndex: -1,
      deleteId: '',
      deleteMarket: '',
      deleteAsin: '',
      showDeleteDialogue: false,
    });
  }

  // If user click delete button (i.e. confirm delete) from delete dialogue, 
  // this function called. So we will proceed to delete the tracker request.
  onConfirmDelete = () => {
    const { deleteId, deleteIndex } = this.state;
    //console.log('onConfirmDelete() deleteId:', deleteId, ' deleteIndex:', deleteIndex);

    // Call server side api to delete tracker request
    deleteTrackerRequest(deleteId, deleteIndex, this.deleteTrackerRequest_Success, this.deleteTrackerRequest_Error);    
  }

  // Called if delete tracker request success
  deleteTrackerRequest_Success = (id, index) => {
    console.log('deleteTrackerRequest_Success() index:', index)

    // Remove deleted element from array
    const { trackerList } = this.state;
    trackerList.splice(index, 1);
    console.log("trackerList:", trackerList);

    this.setState({
      trackerList: trackerList,
      deleteIndex: -1,
      deleteId: '',
      deleteMarket: '',
      deleteAsin: '',
      showDeleteDialogue: false,
    });
  }
  
  // Called if any error while delete tracker request.
  deleteTrackerRequest_Error = (error) => {
    console.log('deleteTrackerRequest_Error() error: ', error.toString());

    // Close delete confirmation dialogue
    this.setState({
      deleteIndex: -1,
      deleteId: '',
      deleteMarket: '',
      deleteAsin: '',
      showDeleteDialogue: false,
    });
  }
  //-------------------------------------------------------------------
  // END: Tracker request delete related functions
  //-------------------------------------------------------------------


  //-------------------------------------------------------------------
  // START: Create new Tracker related functions
  //-------------------------------------------------------------------
  // Whenever new tracker cretaed, this function called via child component
  onCreatedNewTracker = (tackerId) => {
    console.log('onCreatedNewTracker() tackerId:', tackerId);
    this.fetchData();
  }
  //-------------------------------------------------------------------
  // END: Create new Tracker related functions
  //-------------------------------------------------------------------


  //-------------------------------------------------------------------
  // START: Restart tracker related functions (Start tracker again)
  //-------------------------------------------------------------------
  // When play button tapped for expired tacker, this function called 
  // by child component with clicked tracker id and index. We have to 
  // restart the given tracker. First we will show confirm dialogue.
  onClickRestart = (id, index) => {
    console.log('onClickRestart() id:', id, ' index:', index);

    const { asin, market } = this.state.trackerList[index];

    // Show restart confirm dialogue
    this.setState({
      restartIndex: index, 
      restartId: id,
      restartMarket: market,
      restartAsin: asin,
      showRestartDialogue: true,
    });
  }
  
  // If user click Cancel button from restart tracker confirm dialogue, this function called.
  // So no need to restart tracker due to cancel button clicked from dialogue.
  onCancelRestart = () => {
    console.log('onCancelRestart()');

    // Close restart confirmation dialogue
    this.setState({
      restartIndex: -1,
      restartId: '',
      restartMarket: '',
      restartAsin: '',
      showRestartDialogue: false,
    });
  }

  // If user click confirm button from restart tracker dialogue, 
  // this function called. So we will proceed to restart tracker.
  onConfirmRestart = () => {
    const { restartId, restartIndex } = this.state;
    console.log('onConfirmRestart() restartId:', restartId, ' restartIndex:', restartIndex);

    // Restart given tracker - call the api from helper function
    restartTracker(restartId, restartIndex, this.restartTracker_Success, this.restartTracker_Error);
  }

  // Successfulty updated to_date for tracker, i.e. tracker started again.
  restartTracker_Success = (id, index) => {
    console.log('restartTracker_Success() id:', id, ' index:', index);

    // Hide restart tracker dialogue
    this.setState({
      restartIndex: -1,
      restartId: '',
      restartMarket: '',
      restartAsin: '',
      showRestartDialogue: false,
    });

    // Fetch restarted tracker info from db and update within state data. 
    // So user can see restarted tacker.
    getSingleTracker(id, index, this.getSingleTracker_Success, this.getSingleTracker_Error);    
  }
  
  // Error while restart tracker
  restartTracker_Error = (error, trackerDocId, index) => {
    console.log('restartTracker_Error() error:', error);

    this.setState({ 
      restartIndex: -1,
      restartId: '',
      restartMarket: '',
      restartAsin: '',
      showRestartDialogue: false,

      showMessage: true,
      messageText: 'Error while start tracker, please try again.',
    });
  }

  getSingleTracker_Success = (data, index) => {
    console.log('getSingleTracker_Success() data:', data, ' index:', index);

    // Update restarted tracker data within state
    const { trackerList } = this.state;
    const trackerListTemp = [...trackerList];
    trackerListTemp[index] = data;
    //console.log("trackerListTemp:", trackerListTemp);

    this.setState({ 
      trackerList: trackerListTemp,
      showMessage: true,
      messageText: 'Tracker Started for ASIN:' + data.asin,
    });
  }

  getSingleTracker_Error = (error) => {
    console.log('getSingleTracker_Error() error:', error);
    
    this.setState({ 
      showMessage: true,
      messageText: 'Tracker Started, Please refresh page to show updated status.',
    });    
  }
  //-------------------------------------------------------------------
  // END: Restart tracker related functions (Start tracker again)
  //-------------------------------------------------------------------


  //-------------------------------------------------------------------
  // START: Show sales data related functions
  //-------------------------------------------------------------------
  // Called by child component when sales data button tapped from any record
  onClickSalesData = (id, index) => {
    //console.log('onClickSalesData() id:', id, ' index:', index);
    const { asin, market, small_image_url } = this.state.trackerList[index];
    this.setState({
      salesClickedIndex: index,
      salesClickedId: id,
      salesClickedAsin: asin,
      salesClickedMarket: market,
      salesClickedImageUrl: small_image_url,
      showSalesDataModal: true,
    });
  }

  // This function will be called via child component using callback pattern.
  onCloseSalesDataModal = () => {
    this.setState({
      salesClickedIndex: -1,
      salesClickedId: '',
      salesClickedAsin: '',
      salesClickedMarket: '',
      showSalesDataModal: false,
    });
  }
  //-------------------------------------------------------------------
  // END: Show sales data related functions
  //-------------------------------------------------------------------

  // Called when message close after time out
  handleMessageClose = () => {
    //console.log('handleMessageClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }  

  render() {
    //const { classes } = this.props;
    const { trackerList } = this.state;
    const { salesClickedId, salesClickedAsin, salesClickedMarket, showSalesDataModal, salesClickedImageUrl } = this.state;
    const { deleteId, deleteAsin, deleteMarket, showDeleteDialogue } = this.state;
    const { restartId, restartAsin, restartMarket, showRestartDialogue } = this.state;
    
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <div>
        <TrackerForm 
          trackerList={trackerList} 
          onCreatedNew={this.onCreatedNewTracker} 
        />
        <br />
        <TrackerList 
          trackerList={trackerList}
          onClickRestart={this.onClickRestart}
          onClickDelete={this.onClickDelete}
          onClickSalesData={this.onClickSalesData}
        />
        
        { showSalesDataModal && 
          <SalesDataModal 
            show={showSalesDataModal}
            onClose={this.onCloseSalesDataModal}
            id={salesClickedId}
            asin={salesClickedAsin}
            market={salesClickedMarket}
            imageUrl={salesClickedImageUrl}
          />
        }

        { showDeleteDialogue &&
          <TrackerDeleteDialogue 
            show={showDeleteDialogue}
            onCancel={this.onCancelDelete}
            onConfirm={this.onConfirmDelete}
            id={deleteId}
            asin={deleteAsin}
            market={deleteMarket}
          />
        }

        { showRestartDialogue && 
          <TrackerRestartDialogue 
            show={showRestartDialogue}
            onCancel={this.onCancelRestart}
            onConfirm={this.onConfirmRestart}
            id={restartId}
            asin={restartAsin}
            market={restartMarket}
          />
        }

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={5000}
          onClose={this.handleMessageClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </div>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


InventoryTracker.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(InventoryTracker);
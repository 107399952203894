import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";

// Import material ui component
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

// Import firebase
import firebase from './helpers/firebaseApp';

// Import all pages component
import AboutPage from './components/AboutPage';
import LoginPage from './components/LoginPage';
//import DashboardPage from './components/DashboardPage';
import TrackerPage from './components/TrackerPage';
import CompetitionPage from './components/CompetitionPage';
import ProfitCalculatorPage from './components/ProfitCalculatorPage';
import PotentialProductPage from './components/PotentialProductPage';
import TrackerAdminPage from './components/TrackerAdminPage';
import OthersPage from './components/OthersPage';
import SellerConfigPage from './components/SellerConfigPage';
import AdAutomationPage from './components/AdAutomationPage'
import AmazonAuthPage from './components/AmazonAuthPage';


import { getUserType } from './helpers/firebaseHelper';
import { getCurrentUser, getCurrentUserEmail, isCurrentUserEmailVerified } from './helpers/firebaseAuthHelper';


class App extends React.Component { 
  
  // Set default state
  state = {
    // '' - Not decided anything, true - User loggged in, false - User Not logged in
    isLoggedIn: '',

    userType: '',  // 'admin' or ''
  };

  // Event - Run after component mount
  componentDidMount = () => {
    //console.log("App - componentDidMount()");

    // Set listener for firbease login status change.
    // Note: Whenever user login status changed within firebase, it will call 
    // this.handleUserAuthChange() function and pass user object to that handler function
    this.removeUserAuthListener = firebase.auth().onAuthStateChanged(this.handleUserAuthChange);
  }


  // Event - Run before component unmount
  componentWillUnmount = () => {
    //console.log("App - componentWillUnmount()");

    // Remove firebase auth state change listener
    if (this.removeUserAuthListener) {
      this.removeUserAuthListener();
    }
  }

  // Whenever user auth state changed, this function called.
  handleUserAuthChange = (user) => {
    //console.log("App - handleUserAuthChange()");
    
    if(user) { // user logged in

      // Debug - Do not delete
      //console.log('user:', user);
      //console.log('user.providerData:', user.providerData);

      const email = getCurrentUserEmail();
      const emailVerified = isCurrentUserEmailVerified();

      // Allow test@test.com to login (either email verified or not)
      // NOTE: WE will remove this later on, so every user must verify email before using.
      if ( email === 'test@test.com') {
        this.setUserAsLoggedIn();

      } else if (emailVerified) { // Allow login if email verified
        this.setUserAsLoggedIn();
      
      } else { // Email not verified
        
        // When user will signIn via facebook only (no other provider), at that time 
        // emailVerified status will be false. So we will check that only one provider exist 
        // and it is other than 'password' (email/password) provider then we will allow login.
        const currentUser = getCurrentUser();
        if (currentUser.providerData.length === 1 ) {
          const providerId = currentUser.providerData[0].providerId;
          if (providerId !== 'password') { // Allow login if not password provider
            this.setUserAsLoggedIn();
          } else { // Do not login because password provider exist, and it is not verified.
            console.log('Email/password account not verified, so login not allowed.');
          }
        } else { 
          // More than one sign in provider exist, So allow login
          this.setUserAsLoggedIn();
        }

      }

    } else { // User not logged in, or logout
      this.setUserNotLoggedIn();
    }
  }

  setUserAsLoggedIn = () => {
    this.setState( { isLoggedIn: true });
    getUserType(this.getUserTypeSuccess, this.getUserTypeError);
  }

  getUserTypeSuccess = (userType) => { this.setState({ userType: userType }) }
  getUserTypeError = (error) => { /* Do nothing */ }  

  setUserNotLoggedIn = () => {
    this.setState( { isLoggedIn: false, userType: '' });
  }

  onClickLogout = () => {
    //console.log('App - onClickLogout()');
    firebase.auth().signOut();
  }


  render() {

    const { isLoggedIn, userType } = this.state;
    const { classes } = this.props;

    return (
      <BrowserRouter>
        
        <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              Plus Amaze
            </Typography>
            <nav>
              { /*
              <Button color="default" size="small" component={Link} to="/about">
                About
              </Button> */
              }

              { /*
              <Button color="default" size="small" component={Link} to="/dashboard">
                Dashboard
              </Button> */
              }

              { isLoggedIn === false &&
              <Button color="default" size="small" component={Link} to="/login" >
                Login
              </Button>
              }

              { isLoggedIn === true &&
                <Typography display="inline" variant="caption" >
                  Welcome, { firebase.auth().currentUser.email } 
                  { userType !== '' &&  ' (' + userType + ')' } &nbsp;
                </Typography>
              }

              { isLoggedIn === true &&
              <Button color="default" size="small" onClick={this.onClickLogout} >
                Logout
              </Button>
              }
            </nav>
          </Toolbar>
        </AppBar>
        
        <div style={{height: 12}} ></div>

        <Container maxWidth="lg" component="main">
          {/*
            A <Switch> looks through all its children <Route> elements and renders the first 
            one whose path matches the current URL. Use a <Switch> any time you have multiple 
            routes, but you want only one of them to render at a time
          */}
          <Switch>
            
            { /* When root (/) path browsed, Redirect to login page if 
              user not logged in, otherwise redirect to /dashboard */ }
            <Route exact path="/">
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/'} }} /> }
              { isLoggedIn === true && <Redirect to={{ pathname: "/tracker", state: {from: '/'} }} /> }
            </Route>
            
            { /* allow to see /about page without login */ }
            <Route path="/about">
              <AboutPage />
            </Route>

            { /* If not logged in then show login page, otherwise redirect to dashboard page */ }
            <Route path="/login">
              { isLoggedIn === false && <LoginPage /> }
              { isLoggedIn === true && <Redirect to={{ pathname: "/tracker", state: {from: '/login'} }} /> }
            </Route>

            { /* If logged in then show dashboard page, othewise redirect to login page */ }
            { /* Not used now, so commented
            <Route path="/dashboard">
              { isLoggedIn === true && <DashboardPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/dashboard'} }} /> }
            </Route> */
            }

            { /* If logged in then show tracker page, othewise redirect to login page */ }
            <Route path="/tracker">
              { isLoggedIn === true && <TrackerPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/tracker'} }} /> }
            </Route>

            { /* If logged in then show check competition page, othewise redirect to login page */ }
            <Route path="/competition">
              { isLoggedIn === true && <CompetitionPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/competition'} }} /> }
            </Route>

            { /* If logged in then show profit calculator page, othewise redirect to login page */ }
            <Route path="/profit-calculator">
              { isLoggedIn === true && <ProfitCalculatorPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/profit-calculator'} }} /> }
            </Route>

            { /* If logged in then show potential product page, othewise redirect to login page */ }
            <Route path="/potential-product">
              { isLoggedIn === true && <PotentialProductPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/potential-product'} }} /> }
            </Route>

            { /* If logged in then show tracker admin page, othewise redirect to login page */ }
            <Route path="/tracker-admin">
              { isLoggedIn === true && <TrackerAdminPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/tracker-admin'} }} /> }
            </Route>

            { /* If logged in then show other page, othewise redirect to login page */ }
            <Route path="/others">
              { isLoggedIn === true && <OthersPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/other'} }} /> }
            </Route>

            { /* If logged in then show seller config, otherwise redirect to login page */ }
            <Route path="/seller-config">
              { isLoggedIn === true && <SellerConfigPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/seller-config'} }} /> }
            </Route>

            { /* If logged in then show advertise automation page, otherwise redirect to login page */ }
            <Route path="/ad-automation">
              { isLoggedIn === true && <AdAutomationPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/ad-automation'} }} /> }
            </Route>

            { /* If logged in then show amazon-auth page, otherwise redirect to login page */ }
            { /* match url:  /amazon-auth/NA  or  /amazon-auth/EU  or  /amazon-auth/FE     */ }
            <Route path="/amazon-auth/:region_id">
              { isLoggedIn === true && <AmazonAuthPage /> }
              { isLoggedIn === false && <Redirect to={{ pathname: "/login", state: {from: '/amazon-auth'} }} /> }
            </Route>

            { /* If any route not match, Show this message  */ }
            <Route render={() => <h3>No Match</h3>} />
          
          </Switch>
        </Container>

      </BrowserRouter>
  );
  }
}


const styles = (theme) => ({

  // '@global': {
  //   ul: {
  //     margin: 0,
  //     padding: 0,
  //     listStyle: 'none',
  //   },
  // },

  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  toolbar: {
    flexWrap: 'wrap',
  },

  toolbarTitle: {
    flexGrow: 1,
  },

});


//export default App;
export default withStyles(styles)(App);

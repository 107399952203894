import React from "react";
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';


// Import custom ui component
//import AdAutomationForm from './AdAutomationForm';
import LoginWithAmazon from './LoginWithAmazon';

// Sponsored Product - Ad Automation Ui
import SpAdAutomationUi from './SpAdAutomationUi';      // Create Flow
import SpAdAutomationEdit from './SpAdAutomationEdit';  // Edit flow (Deprecated)
import SpAdAutomationEditKeyword from './SpAdAutomationEditKeyword';    // Edit keyword for a flow
import SpAdAutomationEditProduct from './SpAdAutomationEditProduct';    // Edit product (ads) for a flow
import SpAdAutomationEditCampaign from './SpAdAutomationEditCampaign';  // Edit campaign for a flow

// Sponsored Product - Created Flow List
import SpAdAutomationList from './SpAdAutomationList';


// Import constant 
import { isLoggedInToAmazon } from '../../helpers/firebaseHelper';



class AdAutomation extends React.Component { 


  state = {  
    // Amazon login status
    // At component load, we will check within db that current user is logged in 
    // to amazon or not and set this flag accordingly
    // '' - Not decided (default) , true - Logged in, false - Not logged in
    isAmzonLoggedIn: '',

    // Show or Hide create new flow form
    // true - it will show create new flow form component
    // false - it will hide create new flow form component
    showCreateFlowForm: false,

    
    // Show or Hide Edit flow modal form
    // true - show/load edit from form component
    // false - hide/unload edit form component
    showEditFlowForm: false,

    // Show or Hide Edit keyword modal form
    // true - show/load edit keyword modal form component
    // false - hide/unload edit keyword modal form component
    showEditKeywordForm: false, 

    // Show or Hide Edit product (i.e. product ad) modal form/screen
    // true - show/load edit product (ads) modal form component
    // false - hide/unload edit product (ads) modal form component
    showEditProductForm: false, 

    // Show or Hide Edit Campaign modal form
    // true - show/load edit Campaign modal form component
    // false - hide/unload edit Campaign modal form component
    showEditCampaignForm: false, 

    // Db docId for currently editing flow.
    // This will be used by multiple modal i.e. Edit Flow modal, Edit Keyword modal, Edit Campaign modal
    editFlowDocId: '', 


    // Should refresh flow list
    // Whenever new flow created by create new flow form componenet
    // we will set this value true. We are passing this props to 
    // flow list component (as shouldRefresh prop) so it will refresh 
    // flow list whenever it will set true.
    shouldRefreshFlowList: false,

    // true - Some processing on
    isProcessing: true,

    // If any error while fetching data we will set value here.
    // So it will show this message within snackbar component.
    messageText: '',
    showMessage: false,
  }


  componentDidMount = () => {
    //console.log('AdAutomation - componentDidMount()');

    // Check that current user logged in to amazon or not.
    this.checkAmazonLoginStatus();
  }

  componentWillUnmount = () => {
    //console.log('AdAutomation - componentWillUnmount()');
  }


  //-----------------------------------------------------------------
  // START: Check amazon login status for current user
  //-----------------------------------------------------------------  
  // This function will check that current user is logged in with amazon or not.
  checkAmazonLoginStatus = () => {
    isLoggedInToAmazon(this.isLoggedInToAmazon_Success, this.isLoggedInToAmazon_Error);
  }

  // It will give status either current user logged in to amazon or not.
  // true - logged in to amazon, false - not logged in to amazon.
  isLoggedInToAmazon_Success = (isLoggedIn) => {
    console.log('isLoggedInToAmazon_Success() isLoggedIn:', isLoggedIn);

    // Set login status
    this.setState({
      isAmzonLoggedIn: isLoggedIn,
      isProcessing: false,
    });
  }
  
  // If any error occur while checking the amazon login status, this function called.
  isLoggedInToAmazon_Error = (error) => {
    console.log('isLoggedInToAmazon_Error() error:', error);

    // Set login status false and show message
    this.setState({
      isAmzonLoggedIn: '',
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // END: Check amazon login status for current user
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: Edit Keyword modal show close
  //-----------------------------------------------------------------  
  // This function called by listing component when edit Keyword button tapped
  // to edit keywords for a flow. So we will show edit keywords modal component.
  // @flowDocId: String (db docid to edit keyword info for a flow)
  // @index: number (clicked record index within list component)
  handleOnClickEditKeyword = (flowDocId, index) => {
    //console.log('handleOnClickEditKeyword() flowDocId:', flowDocId, ' index:', index);

    // Show modal form to edit the flow
    this.setState({
      showEditKeywordForm: true,
      editFlowDocId: flowDocId,
    });

  }

  // This function called when close button called from edit keyword modal form etc.
  handleOnEditKeywordClose = () => {
    //console.log('handleOnEditKeywordClose()');

    // Unload modal form to edit keyword
    this.setState({
      showEditKeywordForm: false,
      editFlowDocId: '',
    });
  }
  //-----------------------------------------------------------------
  // END: Edit Keyword modal show close
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: Edit Product modal show close (i.e. Product Ads)
  //-----------------------------------------------------------------  
  // This function called by listing component when edit Product button tapped
  // to edit Products (i.e. product ads) for a flow. So we will show edit Product 
  // (ad) modal component.
  // @flowDocId: String (db docid to edit product (ads) for a flow)
  // @index: number (clicked record index within list component)
  handleOnClickEditProduct = (flowDocId, index) => {
    //console.log('handleOnClickEditProduct() flowDocId:', flowDocId, ' index:', index);

    // Show modal form to edit the flow
    this.setState({
      showEditProductForm: true,
      editFlowDocId: flowDocId,
    });

  }

  // This function called when close button called from edit product (ads) modal form etc.
  handleOnEditProductClose = () => {
    //console.log('handleOnEditProductClose()');

    // Unload modal form to edit keyword
    this.setState({
      showEditProductForm: false,
      editFlowDocId: '',
    });
  }
  //-----------------------------------------------------------------
  // END: Edit Product modal show close (i.e. Product Ads)
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: Edit Campaign modal show close
  //-----------------------------------------------------------------  
  // This function called by listing component when edit Campaign button tapped
  // to edit keywords for a flow. So we will show edit keywords modal component.
  // @flowDocId: String (db docid to edit Campaign info for a flow)
  // @index: number (clicked record index within list component)
  handleOnClickEditCampaign = (flowDocId, index) => {
    //console.log('handleOnClickEditCampaign() flowDocId:', flowDocId, ' index:', index);

    // Show modal form to edit the flow
    this.setState({
      showEditCampaignForm: true,
      editFlowDocId: flowDocId,
    });

  }

  // This function called when close button called from edit campaign modal form etc.
  handleOnEditCampaignClose = () => {
    //console.log('handleOnEditCampaignClose()');

    // Unload modal form to edit keyword
    this.setState({
      showEditCampaignForm: false,
      editFlowDocId: '',
    });
  }
  //-----------------------------------------------------------------
  // END: Edit Campaign modal show close
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // START: Edit flow modal show close
  //-----------------------------------------------------------------  
  // This function called by listing component when edit button tapped to edit 
  // some flow record. So we will show edit flow modal component.
  // @flowDocId: String (db docid to edit flow)
  // @index: number (clicked record index within list component)
  handleOnClickEditFlow = (flowDocId, index) => {
    //console.log('handleOnClickEditFlow() flowDocId:', flowDocId, ' index:', index);

    // Show modal form to edit the flow
    this.setState({
      showEditFlowForm: true,
      editFlowDocId: flowDocId,
    });

  }

  // This function called when close button called from edit flow modal form etc.
  handleOnEditFlowClose = () => {
    //console.log('handleOnEditFlowClose()');

    // Unload modal form to edit the flow
    this.setState({
      showEditFlowForm: false,
      editFlowDocId: '',
    });
  }
  //-----------------------------------------------------------------
  // END: Edit flow modal show close
  //-----------------------------------------------------------------  

  
  //-----------------------------------------------------------------
  // START: Create flow
  //-----------------------------------------------------------------  
  // When create flow button tapped we will show create flow form component.
  onClickCreateFlow = () => {
    console.log('onClickCreateFlow()');

    this.setState({
      showCreateFlowForm: true,
      shouldRefreshFlowList: false,
    });
  }

  // This function called by child component once new flow created by
  // Create New Flow form component. So we will refresh flow list, so 
  // newly created component will show within a list.
  handleOnFlowCreated = () => {
    console.log('handleOnFlowCreated()');

    // Close create new flow component, 
    // and show 'Flow Created Successfully' message.
    this.setState({
      showCreateFlowForm: false,
      shouldRefreshFlowList: true,
      messageText: 'Flow Created Successfully',
      showMessage: true,
    });
  }

  // This funtion called by child component once user click cancel/close button from 
  // Create New Flow component or via any other mechanism.
  handleOnFlowCancel = () => {
    console.log('handleOnFlowCancel()');

    // Close create new flow component
    this.setState({
      showCreateFlowForm: false,
      shouldRefreshFlowList: false,
    });
  }
  //-----------------------------------------------------------------
  // END: Create flow
  //-----------------------------------------------------------------  


  // Called when message close after time out (snackbar message)
  handleClose = () => {
    console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
      shouldRefreshFlowList: false,
    });
  }


  render() {

    const { isAmzonLoggedIn, showCreateFlowForm, shouldRefreshFlowList } = this.state;
    const { showEditFlowForm, showEditKeywordForm, showEditProductForm, showEditCampaignForm } = this.state;

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return(
      <React.Fragment>
        
        { // Show label (if logged in to amazon)
          isAmzonLoggedIn &&
          <Typography variant="h6" component="h1" style={{ marginTop: 10, marginBottom: 10 }} >
            Sponsored Product - Automation Flow 
          </Typography>
        }
        
        { // Show flow list (if logged in to amazon)
          isAmzonLoggedIn && 
          <SpAdAutomationList 
            shouldRefresh={shouldRefreshFlowList}
            onClickEditKeyword={this.handleOnClickEditKeyword}
            onClickEditProduct={this.handleOnClickEditProduct}
            onClickEditCampaign={this.handleOnClickEditCampaign}
            onClickEditFlow={this.handleOnClickEditFlow}
          /> 
        }
        
        { // Show create flow button if logged in to amazon.
        isAmzonLoggedIn && !showCreateFlowForm &&
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} align="center" >
            <Button 
              variant="contained"
              //color="default"
              size="large" 
              onClick={ (e) => this.onClickCreateFlow() } 
              style={{ marginTop: 10, }}
              //disabled={ false } 
            >Create Flow</Button>
          </Grid>
        </Grid>
        }
        
        <br />

        { // Load the create flow component if logged in to amazon and show form clicked.
          isAmzonLoggedIn && showCreateFlowForm && 
          <SpAdAutomationUi  
            onFlowCreated={ this.handleOnFlowCreated }
            onFlowCancel={ this.handleOnFlowCancel }
          /> 
        }
        <br /><br />


        { // Load edit flow component as full screen modal/overlay etc.  (DEPRECATED)
          showEditFlowForm &&
          <SpAdAutomationEdit
            flowDocId={ this.state.editFlowDocId }
            onEditFlowClose={ this.handleOnEditFlowClose }
          /> 
        }

        { // Load edit keyword full screen modal etc.
          showEditKeywordForm &&
          <SpAdAutomationEditKeyword
            flowDocId={ this.state.editFlowDocId }
            onEditKeywordClose={ this.handleOnEditKeywordClose }
          /> 
        }

        { // Load edit product (i.e. product ads) full screen modal etc.
          showEditProductForm &&
          <SpAdAutomationEditProduct  
            flowDocId={ this.state.editFlowDocId }
            onEditProductClose={ this.handleOnEditProductClose }
          /> 
        }

        { // Load edit campaign full screen modal etc.
          showEditCampaignForm &&
          <SpAdAutomationEditCampaign
            flowDocId={ this.state.editFlowDocId }
            onEditCampaignClose={ this.handleOnEditCampaignClose }
          /> 
        }


        <LoginWithAmazon />
        <br /><br />

        {
          //<AdAutomationForm />
        }

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </React.Fragment>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(AdAutomation);


// This component will list the given product data.
// Component used for Potential Product via Search

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';

import { marketPlaces } from '../../helpers/constants';


class PotentialProductSearchResult extends React.Component {

  SLIDER_MARKS = [ 
    { value: 0, label: '0' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];


  state = {
  }

  componentDidMount = () => {
    console.log('PotentialProductSearchResult - componentDidMount()');
  }

  // It will render slider based on the passed parameter
  // @potentialRank Number 0 to 100   
  // @status: String e.g. 'waiting', 'processing', 'done'
  renderSlider = (potentialRank, status) => {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item align='center'>Low</Grid>
        <Grid item xs align='center' style={{marginLeft: 4, marginRight: 4}} >
          { status === 'waiting' && <>waiting...</> }
          { status === 'processing' && <LinearProgress /> }
          { status === 'done' &&
            <Slider
              value={potentialRank}
              valueLabelDisplay="on"
              marks={this.SLIDER_MARKS}
              step={10}
              min={0}
              max={100}
            />
          }
        </Grid>
        <Grid item align='center'>High</Grid>
      </Grid>
    );
  }


  render() {
    const { classes } = this.props;
    const { products } = this.props;

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="center" width="50%">Potential Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              products.map( (row, index) => {
                return (
                  <TableRow key={row.ASIN + '__' + index}>
                    <TableCell align="left">
                      { row.SmallImageUrl &&
                      <img src={row.SmallImageUrl} width={row.SmallImageWidth} height={row.SmallImageHeight} alt={row.Title} />
                      }
                    </TableCell>
                    <TableCell align="left">
                      <Link href={ marketPlaces[row.Market].site_url + '/dp/' + row.ASIN } target="_blank" >
                      { row.Title } 
                      </Link>
                      &nbsp; { row.ASIN } 
                    </TableCell>
                    <TableCell align="center">
                      { this.renderSlider(row.PotentialRank, row.Status) }
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

const styles = (theme) => ({

  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  table: {
    minWidth: 650,
  },

});


PotentialProductSearchResult.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(PotentialProductSearchResult);
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";

// Import custom ui component
import TopNavBar from './common/TopNavBar';
import AdAutomation from './adautomation/AdAutomation';


class AdAutomationPage extends React.Component { 

  componentDidMount = () => {
    //console.log('AdAutomationPage - componentDidMount()');
  }

  componentWillUnmount = () => {
    //console.log('AdAutomationPage - componentWillUnmount()');
  }

  render() {
    return(
      <div>
        <TopNavBar activeTab='ad-automation' />
          <AdAutomation />
        <br />
      </div>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withRouter(withStyles(styles)(AdAutomationPage));


// Form to take input for potential product via search
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


// Import helper functions, constant etc.
import { marketPlaces } from '../../helpers/constants';

const marketPlacesKeys = Object.keys(marketPlaces);


class PotentialProductSearchForm extends React.Component {
  
  state = {
    // Form input
    market: 'CA',         // 'CA' or 'US' etc. 
    query: 'Pen Drive',   // Search Query

    // Message text
    showMessage: false,
    messageText: "",
  }

  componentDidMount = () => {
    console.log('PotentialProductSearchForm - componentDidMount()');
  }

  // Whenever user will select market, we will also save currency code
  // that belongs to that market within state.
  onChangeMarket = (market) => {
    //console.log('onChangeMarket() market:', market)
    this.setState({
      market: market,
    });
  }
  
  // This function called when user click submit button, so we will validate form data 
  // and pass it to parent component so it can do further processing.
  onClickSubmit = () => {
    //console.log('PotentialProductSearchForm - onClickSubmit()');

    // 1 - Fetch data from state
    const { market, query } = this.state;
    if (!market || market === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please select Marketplace',
      });
      return;
    }
    if (!query || query === '') { 
      this.setState({
        showMessage: true,
        messageText: 'Please enter Search Query',
      });
      return;
    }

    // 2 - Send form data to parent component, so parent can 
    // do further operation using this parameter.
    this.props.onSubmit(market, query);
  }


  // Render form content 
  renderForm = () => { 
    const { isProcessing } = this.props;
    const { classes } = this.props;

    return (
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" component="h1">
              Potential Product
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} >
              <InputLabel id="demo-simple-select-label">Choose Marketplace</InputLabel>
              <Select
                id="market"
                labelId="market"
                value={this.state.market}
                onChange={ (e) => this.onChangeMarket(e.target.value) }
                disabled={isProcessing}
              >
                { // Dynamically generate options
                  marketPlacesKeys.map((key, index) => {
                    const market = marketPlaces[key];
                    return (<MenuItem key={key} value={key}>{market.country_name} - {market.site_url}</MenuItem>)
                  })
                }
              </Select>
            </FormControl>  
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl className={classes.formControl} >
              <TextField 
                id="query" 
                label="Search Query" 
                placeholder="" 
                value={this.state.query} 
                onChange={(e) => this.setState({ query: e.target.value })} 
                size="small" 
                disabled={isProcessing}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2} >
            <Button 
              variant="contained" 
              size="medium"
              onClick={this.onClickSubmit} 
              //style={{marginRight: 20 }} 
              disabled={isProcessing}
            >Submit</Button>
          </Grid>

        </Grid>
      </form>
    )
  }

  // Called when message close after time out
  handleClose = () => {
    //console.log('handleClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    })
  }  

  render() {
    //const { classes } = this.props;
    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <>
        <Paper elevation={2} style={{padding: 20}} >
          { this.renderForm() }
        </Paper >
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


PotentialProductSearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(PotentialProductSearchForm);
import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import * as firebase from 'firebase/app';
//import firebase from '../../helpers/firebaseApp';

// Import constant and helper functions
import { 
  createUserInfo,
} from '../../helpers/firebaseAuthHelper';


class LoginWithFacebook extends React.Component {  

  // Set default state 
  state = {
    isProcessing: false,

    // When account already exist with same email at that time we will ask user to 
    // link facebook with existing firebase user, so below variable used for that.
    pendingCred: null,   // Facebook credential stored here if need to link facebook with google or email-pass
    pendingEmail: '',    // Email address associated with pending credential stored here
    showLinkForm: false, // true - Show form to link facebook with google or email pass account.
    isLinking: false,    // true - Linking in progress, false - linking not in progress

    // If account already exist with same email within firebase then we will show this 
    // message and form to link facebook with existing account.
    linkMessage: '',  // e.g. Account already exist with same email..

    // e.g. If existing account is email/pass then we will show form with password input, 'Link' and 'Cancel' button.
    // e.g. If existing account is google then we will show 'Link Facebook With Google' and 'Cancel' button
    // password - link facebook with email password account, google - link facebook with google
    linkWithType: '', // 'password' or 'google.com' Depending on type we will show form.

    // When we need to link facebook with email/password account, we will take password input here.
    password: '',

    // Message banner show/hide flag.
    showBanner: false,
    bannerTitle: '',
  };


  componentDidMount = () => {
    console.log("LoginWithFacebook - componentDidMount()");
  }

  componentWillUnmount = () => {
    console.log("LoginWithFacebook - componentWillUnmount()");
  }
  

  //-----------------------------------------------------------------
  // Handle button click
  //-----------------------------------------------------------------  
  onClickLogin = () => {
    //console.log("onClickLogin()");

    // 1 - Set processing on
    this.setState({
      isProcessing: true,
      pendingCred: null, 
      pendingEmail: '',
    });

    // 2 - Sign in with facebook
    this.doSignInWithPopUp();
  }
  //-----------------------------------------------------------------  


  //-----------------------------------------------------------------
  // Do sign in with popup
  // Help: https://firebase.google.com/docs/auth/web/facebook-login
  //-----------------------------------------------------------------  
  doSignInWithPopUp = () => {
    console.log("LoginWithFacebook - doSignInWithPopUp()");

    // Create auth provider
    var provider = new firebase.auth.FacebookAuthProvider();

    // Call sign in api
    firebase.auth().signInWithPopup(provider)
    .then( (result) => { // Sign in success
      console.log("User Signed In - result: ", result);

      // Save logged in user info within db
      createUserInfo(result.user, "facebook.com");
      
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      //var accessToken = result.credential.accessToken;
      //console.log("facebook accessToken: ", accessToken);

      // The signed-in user info.
      //var user = result.user;
      //console.log("user:", JSON.stringify(user));
    })
    .catch( (error) => { // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      //console.log("errorCode:", errorCode);
      //console.log("errorMessage:", errorMessage);

      // Display message based on the error code
      if ( errorCode === 'auth/user-cancelled' ) { 
        const message = "Please grant permission from facebook to login.";
        this.showError(message);
      } else if ( errorCode === "auth/account-exists-with-different-credential" ) {
        this.handleAccountExistsWithDiffCredError(error);
      } else {
        this.showError(errorMessage);
      }

    });
  }


  // If account already exist within firebase with same email address but
  // different credential (e.g. password, google.com) then this error occurs
  // i.e. 'auth/account-exists-with-different-credential', so we have to
  // inform user about this situation and give provision to link facebook 
  // with existing (google.com or password) account.
  handleAccountExistsWithDiffCredError = (error) => {
    console.log("handleAccountExistsWithDiffCredError() error:", error);

    // 1 - Get registered providers/signIn method for given email.
    // Note: Sign in provider or Sign in methods both are interchangable terms used
    // by firebase. Previously they use sign in provider, now they changed function
    // name as "fetchSignInMethodsForEmail()"
    firebase.auth().fetchSignInMethodsForEmail(error.email)
    .then( (methods) => {
      // 2A - If the user has several sign in methods, then first method in the
      // list will be the "recommended" methods to use. So verify that first
      // method is 'password' or not (i.e. email/password provider). If it is then
      // ask user to enter password for that account, so we can link faceboook with that.
      console.log("methods[0]: " + methods[0]); // Debug
      if ( methods[0] === 'password' ) {
        const linkMessage = "Account already exist for " + error.email + " having email/password credential. Please enter password to verify and link facebook with it.";
        const linkWithType = methods[0]; // e.g. 'password';
        //console.log('linkWithType:', linkWithType);
        //console.log('linkMessage:', linkMessage);

        // Show form to link facebook with email/password account.
        this.setState({ 
          pendingCred: error.credential,
          pendingEmail: error.email,
          linkMessage: linkMessage, 
          linkWithType: linkWithType,
          showLinkForm: true,
        });

      } else {
        // 2B - All other cases are external providers.
        // i.e. If first provider type not email/password within provider list, then
        // we will ask user to sign in via other provider and link facebook with that account.
        // i.e. link facebook with existiing provider other than email/password.
        // In our case it will be google.com account, because we have only three type of 
        // login method exist, email/pass, google.com, facebook.com
        const linkMessage = "Account already exist for " + error.email + " with " + methods[0] + " credential. " + 
                            "Please click 'Link Facebook With Google' button to continue. " + 
                            "We will ask " + methods[0] + " sign in to link facebook with it.";

        const linkWithType = methods[0]; // e.g. google.com
        //console.log('linkWithType:', linkWithType);
        //console.log('linkMessage:', linkMessage);
        
        // Show button to link facebook with google account.
        this.setState({ 
          pendingCred: error.credential,
          pendingEmail: error.email,
          linkMessage: linkMessage, 
          linkWithType: linkWithType,
          showLinkForm: true,
        });
      }
    });
  }


  // Link facebook with email/password account
  // We shows form with password text input, 'Link' and 'Cancel' button.
  // When user tap 'Link' button it will call this function. 
  // How It Works: We will first sign in with email password, once logged in successfully,
  // we will link pending facebook credential with current user.
  doLinkWithPassword = () => {
    console.log('doLinkWithPassword()');

    // 1 - Read password from input
    const { password } = this.state;
    if ( !password || password === '') {
      console.log('Password empty, So return');
      return;
    }

    // 2 - Set linking in progress.
    this.setState({
      isLinking: true,
    });

    // 3 - Fetch pending (Facebook) credential from state. We will use
    // it to link with existing credential (email/password) in firebase.
    const email = this.state.pendingEmail;
    const pendingCred = this.state.pendingCred;
    //console.log("pendingCred: ", JSON.stringify(pendingCred) ); // Debug
    //console.log("pendingEmail: ", email ); // Debug

    // 4 - Sign in with email password, upon successful sign in, we will link that user with facebook.
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then( (user) => {
      console.log("email/pass login success before linking with facebook"); // Debug
      //console.log("email/pass login success before linking with facebook - user:", user); // Debug

      // It will return promise, that will be handle by next then() block.
      return firebase.auth().currentUser.linkWithCredential(pendingCred);
    })
    .then( (userCredential) => { // Facebook linked successfully.
      //console.log("Facebook linked successfully - userCredential:", JSON.stringify(userCredential)); // Debug - IMPORTANT

      // Facebook account successfully linked to existing Firebase user. (Debug)
      console.log("Facebook linked with existing firebase user (via email/pasword verification).");

      // Create current user basic info if not created yet in database.
      // Note: at this step. current user object exist so we can pass it to create user info.
      createUserInfo(userCredential.user, "facebook.com");
    })
    .catch( (error) => {
      // error occured during any .then() block above, it will be cached here.
      console.log('Email/password and facebook link error: ', error); // Debug
      
      // Enable linking button, so user can try again.
      this.setState({ isLinking: false, });
      this.showError(error.message);
    });
  }

  // Cancel button tapped from link facebook with email/password account form.
  cancelLinkWithPassword = () => {
    console.log('cancelLinkWithPassword()');

    this.setState({
      pendingCred: null,
      pendingEmail: '',
      linkMessage: '', 
      linkWithType: '',
      showLinkForm: false,
      isLinking: false,
    });
  }


  // Link facebook with existing google.com account
  // First we have to show google auth popup so user will grant the google 
  // permission successfully. Thereafter we will link existing google user with 
  // facebook pending credential that we alreay received during facebook login step.
  // Help: https://firebase.google.com/docs/auth/web/facebook-login
  onClickLinkWithGoogle = () => {
    //console.log("onClickLinkWithGoogle() called");

    // 1 - Set linking in progress.
    this.setState({
      isLinking: true, 
    });

    // 2 - Create google auth provider and show Google sign in popup
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
    .then( (result) => {
      // 2.1 - Fetch pending facebook credential that we already stored 
      // when user signed in to facebook at first step.
      const { pendingCred } = this.state;

      // 2.2 - Link existing Google user with facebook credential.
      result.user.linkWithCredential(pendingCred)
      .then( (userCredential) => {
        console.log("Facebook linked with existing google.com account");
        //console.log("Facebook linked with existing google user - userCredential:", JSON.stringify(userCredential));
        
        // Save logged in user info within db (if user info not created)
        createUserInfo(userCredential.user, "facebook.com");
      })
      .catch( (error) => { // Error while link
        console.log("Error linking facebook with google. error: ", error);

        // Enable linking button so user can try again.
        this.setState( { isLinking: false } );
        this.showError(error.message);
      });

    })
    .catch( (error) => { // Error while link
      //console.log("Google auth error: ", error);
      this.showError(error.message);

      // Enable linking button so user can try again.
      this.setState( { isLinking: false } );
    });

  }

  // Cancel button tapped during link facebook with google.
  onCancelLinkWithGmail = () => {
    //console.log("onCancelLinkWithGmail() called");

    this.setState({
      pendingCred: null,
      pendingEmail: '',
      linkMessage: '', 
      linkWithType: '',
      showLinkForm: false,
      isLinking: false,
    });
  }
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Message banner
  //-----------------------------------------------------------------
  // Show error banner with given title (message text)
  showError = (title) => {
    this.setState({
      showBanner: true,
      bannerTitle: title,
      isProcessing: false,
    });
  }

  // Hide message banner
  hideBanner = () => {
    this.setState({
      showBanner: false,
      bannerTitle: '',
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------


  render() {
    const { showLinkForm, isLinking, linkMessage, linkWithType } = this.state;

    const { showBanner, bannerTitle } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    // Disable Sign in with facebook button if linking form shows.
    const isDisabledFacebookButton = showLinkForm ? true : false;

    // If linking in progress then disable 'Link Facebook with Google' and 'Cancel' button etc.
    const isDisableLinkButton = isLinking ? true : false;

    return (
      <Paper elevation={2} style={{padding: 20}} align='center' >
        <Button 
          variant="contained" 
          color="primary"
          size="large"
          onClick={this.onClickLogin}
          disabled={isDisabledFacebookButton}
          //style={{ marginBottom: 20,}}
        >
          Login with Facebook
        </Button>

        { // Link facebook with existing account message.
          // Used to link facebook with email/pass, or link facebook with google.com account.
          linkMessage !== '' &&
          <Typography style={{ marginTop: 20, marginBottom: 20 }} >{ linkMessage }</Typography>
        }

        { // Form to link facebook with existing email password account.
          // Show password text field, 'Link' and 'Cancel' button.
          linkWithType === 'password' &&
          <form>
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12} md={6} lg={6} >
                <TextField
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  label="Password"
                  type="password"
                  //maxLength="100"
                  autoComplete="password"
                  style={{ marginRight: 20, width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} >
                <Button 
                  variant="contained"
                  size="small"
                  onClick={this.doLinkWithPassword} 
                  disabled={isDisableLinkButton}
                  style={{ marginRight: 20 }} 
                >Link</Button>
                <Button 
                  variant="contained"
                  size="small"
                  onClick={this.cancelLinkWithPassword} 
                  disabled={isDisableLinkButton}
                  //style={{marginRight: 20 }} 
                >Cancel</Button>
              </Grid>
            </Grid>
          </form>
        }

        { // Form to link facebook with existing google.com account
          // It will show 'Link Facebook with Google' and 'Cancel' button
          linkWithType === 'google.com' &&
          <React.Fragment>
            <Button 
              variant="contained"
              color="primary"
              size="small"
              onClick={this.onClickLinkWithGoogle} 
              disabled={isDisableLinkButton} 
              style={{ marginRight: 20, marginBottom: 20, }}
            >
              Link Facebook with Google
            </Button>
            <Button 
              variant="contained"
              color="default"
              size="small"
              onClick={this.onCancelLinkWithGmail} 
              disabled={isDisableLinkButton} 
              style={{ marginBottom: 20, }}
            >
              Cancel
            </Button>
          </React.Fragment>
        }
        
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showBanner}
          autoHideDuration={8000}
          onClose={this.hideBanner}
          message={bannerTitle}
          severity="success"
          key={vertical + horizontal}
        />
      </Paper>
    );
  }

}

export default LoginWithFacebook;
// Amazon Advertising Api: 
// Sponsored Products - Create negative keyword
// POST: /v2/sp/negativeKeywords
// 
// Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/createNegativeKeywords


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import helper functions, constant etc.
//import { getProfiles } from '../../helpers/amazonAdHelper';
import { spGetAdGroups, spCreateNegativeKeywords } from '../../helpers/amazonAdHelper';


class SponsoredProductsCreateNegativeKeyword extends React.Component {
  
  state = {

    // Start - Form input data
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/createKeywords
    // number: profile id under which new keyword will be created.
    profileId: "",

    // Number: The ad group identifier.
    // The identifier of the ad group to which the negative keyword is associated.
    // Note: Whenever user will select profile id from dropdown, we will fetch 
    // ad group list that belongs to selected profile id and fillup the ad group
    // list drop down. So user can select ad group from that dropdown.
    adGroupId: "",

    // number: The campaign identifier.
    // The identifier of the campaign to which the negative keyword is associated.
    // Note: Whenever user will select ad group from the dropdown, we will set the 
    // campaignId that belongs to it. Each ad group is associated with campaignId
    // so we will set value here based on the selected adGroupId.
    campaignId: "", 

    // string: The negative keyword text (single keyword)
    keywordText: "", 

    // string: The type of match. [ 'negativeExact', 'negativePhrase' ]
    matchType: "", 

    // string: The current resource state. 
    // Choose from ['enabled', 'paused', 'archived']
    state: "", 
    // End - Form input data


    // We will fetch profile list from amazon and set it here.
    // This will be used to fillup the profile list dropdown.
    // e.g profileList = [
    //   {
    //     "profileId": 4141988869858613,
    //     "countryCode": "US",
    //     "currencyCode": "USD",
    //     "dailyBudget": 0.0,
    //     "timezone": "America/Los_Angeles",
    //     "accountInfo": {
    //       "marketplaceStringId": "ATVPDKIKX0DER",
    //       "id": "A33SQZ1KV70HZP",
    //       "type": "seller"
    //     }
    //   }
    // ]
    profileList: [],

    // We will fetch ad group list for the selected profileId and set data here.
    // This ad group list will be used to fillup ad group drop down, so user can 
    // select the ad group from that.
    // e.g. 
    // adGroupList: [
    //   {
    //     "adGroupId": 0,
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ],
    adGroupList: [],

    // Once new negative keyword created we will store result here.
    // This will be used to show results within ui.
    // e.g.
    // keywordCreatedResult: [
    //   {
    //     "keywordId": 0,
    //     "code": "string",
    //     "details": "string",
    //     "description": "string"
    //   }
    // ],
    keywordCreatedResult: null,

    // If error occur during any operation we will fillup error info here.
    status: '',
    error: '',

    // Some processing in progress or not.
    isProcessing: false,
  }

  componentDidMount = () => {
    console.log('SponsoredProductsCreateNegativeKeyword - componentDidMount()');

    // 1 - Fetch amazon profile list to fillup dropdown
    // Commented: because we passed profileList as props from parent component
    //this.fetchProfileList();
  }



  //-----------------------------------------------------------------
  // Start: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------
  // Fetch profile list for current logged in amazon user
  // We have to pass profile Id to server side api, so first 
  // we will fetch profile list and fillup drop down so user can choose it.
  fetchProfileList = () => {
    //console.log('fetchProfileList()');

    // 1 - Show processing
    this.setState({
      isProcessing: true,
    });

    // 2 - Call api to get profile list for current amazon user
    //getProfiles(this.getProfiles_Success, this.getProfiles_Error);
  }

  // Profile list fetched successfully
  getProfiles_Success = (result) => {
    //console.log('getProfiles_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        profileList: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    } 
  }
  
  // Error while fetching profile list
  getProfiles_Error = (error) => {
    console.log('getProfiles_Error() error:', error);

    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Fetch profile list for current logged in amazon user
  //-----------------------------------------------------------------


  // Clear content
  onClickClear = () => {
    this.setState({
      status: '',
      error: '',
      isProcessing: false,
      
      //profileId: '',
      adGroupId: '',
      campaignId: '',
      keywordText: '',
      matchType: '',
      state: '',

      keywordCreatedResult: null,
    });
  }

  
  //-----------------------------------------------------------------
  // Start: Create new negative keyword
  //-----------------------------------------------------------------
  onClickSubmit = () => {
    //console.log('onClickSubmit()');

    // Reset error message
    this.setState({ 
      error: '', 
      keywordCreatedResult: null,
    });

    // 1 - Get info from the state
    const { profileId, adGroupId, campaignId, 
            keywordText, matchType, state } = this.state;
    
    // 2 - If mandatory info not selected then return
    if ( !profileId || profileId === '' ) { 
      console.log('profileId not selected, so return');
      this.setState({ error: 'Please Select Profile'});
      return;
    }
    if ( !adGroupId || adGroupId === '' ) { 
      console.log('adGroupId not selected, so return'); 
      this.setState({ error: 'Please Select Ad Group'}); 
      return;
    }
    if ( !campaignId || campaignId === '' ) { 
      console.log('campaignId found empty, so return'); 
      this.setState({ error: 'Please Enter CampaignId'}); 
      return; 
    }
    if ( !keywordText || keywordText === '' ) { 
      console.log('Negative keywordText empty, so return'); 
      this.setState({ error: 'Please enter negative keyword (single)'}); 
      return; 
    }
    if ( !matchType || matchType === '' ) { 
      console.log('matchType found empty, so return'); 
      this.setState({ error: 'Please Select Match Type'}); 
      return; 
    }
    if ( !state || state === '' ) { 
      console.log('Keyword state empty, so return'); 
      this.setState({ error: 'Please Select Keyword State'}); 
      return; 
    }

    // 3 - Prepare data to create negative keyword
    // We have to pass data as an array although we are creating one keyword 
    // at present, because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "exact",
    //   }
    // ] 
    var keywordData1 = {
      campaignId: campaignId,
      adGroupId: adGroupId,
      state: state,
      keywordText: keywordText,
      matchType: matchType
    }
    const keywordDataArray = [ keywordData1 ];
    console.log('keywordDataArray:', keywordDataArray);

    // 4 - Show processing
    this.setState({
      isProcessing: true,
    });
    
    // 5 - Call api to create negative keywords
    spCreateNegativeKeywords(profileId, keywordDataArray, this.spCreateNegativeKeywords_Success, this.spCreateNegativeKeywords_Error);
  }

  // Called when negative keyword created successfully
  // e.g. jsonBody: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //          "description": ""
  //        }], 
  //        error: null 
  //      }
  // 
  spCreateNegativeKeywords_Success = (result) => {
    console.log('spCreateNegativeKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        keywordCreatedResult: result.data,
        isProcessing: false,
      });
    }

    if (result.status === 'error') {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while creating negative keyword
  spCreateNegativeKeywords_Error = (error) => {
    console.log('spCreateNegativeKeywords_Error() error:', error);
    
    this.setState({
      error: error,
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Create new negative keyword
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Fetch ad groups (list) for selected profile
  //-----------------------------------------------------------------
  // Whenever user will select profile from dropdown, this function called.
  // So we will set selected profile Id within state and fetch ad groups 
  // (via server side api) that belongs to selected profileId.
  handleProfileChange = (selectedProfileId) => {
    console.log('handleProfileChange() selectedProfileId:', selectedProfileId);

    // 1 - If profileId not selected then return
    if ( !selectedProfileId || selectedProfileId === '' ) {
      return;
    }

    // 2 - Set selected profile within state
    // Reset adGroupId and campaignId if selected before.
    this.setState({
      profileId: selectedProfileId,  
      
      adGroupId: '', 
      campaignId: '',
      keywordText: '',
      matchType: '',
      state: '',
      
      keywordCreatedResult: null,
      error: '',  
    });

    // 4 - Show processing
    this.setState({ 
      isProcessing: true, 
    });

    // 5 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    const queryData = {
      startIndex: 0,
      count: 20,
      //stateFilter: 'enabled, paused, archived',
    }
  
    // 6 - Call api to Get ad groups (list) for selected profile Id.
    // i.e. We wil fetch ad group list for selected profileId and fillup ad groups drop down.
    spGetAdGroups(selectedProfileId, queryData, this.spGetAdGroups_Success, this.spGetAdGroups_Error);
  }

  // Called when ad groups (list) fetched successfully
  // e.g. adGroupList: [
  //   {
  //     "adGroupId": 0,
  //     "name": "string",
  //     "campaignId": 0,
  //     "defaultBid": 0,
  //     "state": "enabled"
  //   }
  // ],
  spGetAdGroups_Success = (result) => {
    console.log('spGetAdGroups_Success() result:', result);

    if ( result.status === 'success' ) {
      if (result.data.length > 0 ) {
        this.setState({
          adGroupList: result.data,
          isProcessing: false,
          error: '',
        });
      } else {
        this.setState({
          adGroupList: result.data,
          isProcessing: false,
          error: 'Ad groups not exist for selected profile. Please create ad group under profile, thereafter you can create negative keyword.',
        });
      }
    }

    if ( result.status === 'error' ) {
      this.setState({
        error: result.error,
        isProcessing: false,
      });
    }
  }
  
  // Called if any error while fetch ad groups
  spGetAdGroups_Error = (error) => {
    console.log('spGetAdGroups_Error() error:', error);
    
    this.setState({
      isProcessing: false,
    });
  }
  //-----------------------------------------------------------------
  // END: Fetch ad groups (list) for selected profile
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // START: Assign selected group id within state.
  //-----------------------------------------------------------------
  // This function will be called whenever user will select group from 
  // the dropdown. So we will assign selected group id to state and we 
  // will also find campaign Id that belongs to selected group id and 
  // set campaignId within state too.
  handleGroupChange = (selectedGroupId) => {
    //console.log('handleGroupChange() selectedGroupId:', selectedGroupId);

    // 1 - If group id not selected then return
    if ( !selectedGroupId || selectedGroupId === '' ) {
      return;
    }

    // 2 - Find campaign id that belongs to selected group id
    // e.g. adGroupList: [
    //   {
    //     "adGroupId": 0,
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ],
    var campaignId = "";
    const { adGroupList } = this.state;
    adGroupList.forEach( (item, index) => {
      if ( item.adGroupId === selectedGroupId ) {
        campaignId = item.campaignId;
      }
    });

    // 3 - Set selected groupid and campaign id within state
    this.setState({
      adGroupId: selectedGroupId,
      campaignId: campaignId,
    });
  
  }
  //-----------------------------------------------------------------
  // START: Assign selected group id within state.
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render content
  //-----------------------------------------------------------------
  renderForm = () => {
    const { isProcessing, profileId, adGroupList, adGroupId, 
              keywordText, matchType, bid, state } = this.state;
    const { classes, profileList } = this.props;

    return (
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="profileid-label">Choose Profile</InputLabel>
            <Select
              id="profileId"
              labelId="profileid-label"
              value={profileId}
              onChange={ (e) => this.handleProfileChange(e.target.value) }
              disabled={ isProcessing || profileList.length === 0 }
            >
              { // Dynamically generate options
                profileList.map((profile, index) => {
                  const { profileId, countryCode, accountInfo } = profile;
                  const { type, name } = accountInfo;
                  return (<MenuItem key={ 'profile_' + profileId } value={profileId}>{profileId} / {countryCode} / {type} / {name}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              Ad groups (dropdown below) will be fillup for selected profile.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl className={classes.formControl} >
            <InputLabel id="adgrouid-label">Choose Ad Group</InputLabel>
            <Select
              id="adGrouId"
              labelId="adgrouid-label"
              value={adGroupId}
              onChange={ (e) => this.handleGroupChange(e.target.value) }
              disabled={ isProcessing || adGroupList.length === 0 }
            >
              { // Dynamically generate options
                adGroupList.map((item, index) => {
                  const { adGroupId, name, campaignId, defaultBid, state } = item;
                  return (<MenuItem key={ 'adgroup_' + adGroupId } value={adGroupId}>{adGroupId} / {name} / campaignId: {campaignId} / defaultBid: { defaultBid } / { state }</MenuItem>)
                })
              }
            </Select>
            <FormHelperText>
              Negative keyword will be associated with selected adGroup and adGroup's campaignId. 
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          { adGroupId !== '' && this.renderSelectedAdGroupInfo() }
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <TextField 
              id="negativekeywordtext" 
              label="Enter Negative Keyword (single) " 
              placeholder="Enter Negative Keyword (single)" 
              value={keywordText} 
              onChange={ (e) => this.setState({ keywordText: e.target.value }) } 
              size="small" 
              disabled={ isProcessing } 
              InputLabelProps={{ shrink: true, }} 
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <InputLabel id="matchtype-label">Match Type</InputLabel>
            <Select
              id="matchtype" 
              labelId="matchtype-label" 
              value={matchType} 
              onChange={(e) => this.setState({ matchType: e.target.value })} 
              disabled={isProcessing} 
            >
              <MenuItem value="negativeExact" >negativeExact</MenuItem>
              <MenuItem value="negativePhrase" >negativePhrase</MenuItem>
            </Select>
          </FormControl>  
        </Grid>

        <Grid item xs={6} sm={6}>
          <FormControl className={classes.formControl} >
            <InputLabel id="negative-keyword-state-label">Keyword State</InputLabel>
            <Select 
              id="state" 
              labelId="negative-keyword-state-label" 
              value={state} 
              onChange={(e) => this.setState({ state: e.target.value })} 
              disabled={isProcessing} 
            >
              <MenuItem value="enabled" >enabled</MenuItem>
              <MenuItem value="paused" >paused</MenuItem>
              <MenuItem value="archived" >archived</MenuItem>
            </Select>
          </FormControl>  
        </Grid>
        
      </Grid>
    );
  }

  // This function will render more info about selected ad group id.
  // If ad group info not found from array, it will render nothing.
  renderSelectedAdGroupInfo = () => {
    //console.log('renderSelectedAdGroupInfo()');

    // 1 - Find group info that belongs to selected group id
    // e.g. adGroupList: [
    //   {
    //     "adGroupId": 0,
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ],
    let groupInfo;
    const { adGroupList, adGroupId } = this.state;
    adGroupList.forEach( (item, index) => {
      if ( item.adGroupId === adGroupId ) {
        groupInfo = item;
      }
    });

    // 2 - If group info not found then return
    if (!groupInfo) { 
      return null;
    } 

    // 3 - Render selected group information
    return (
      <>
        adGroupId: { groupInfo.adGroupId } <br /> 
        campaignId: { groupInfo.campaignId } <br /> 
        name: { groupInfo.name } <br /> 
        defaultBid: { groupInfo.defaultBid } <br /> 
        state: { groupInfo.state }  <br /> 
      </>
    );

  }


  // Render 'Create Negative Keyword' and 'Clear' button.
  renderFormButton = () => {
    const { isProcessing, profileId, adGroupId } = this.state;
    return (
      <div align='right' style={{margin: 10}} >
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickSubmit} 
          style={{ marginRight: 20 }} 
          disabled={ isProcessing || profileId === '' || adGroupId === '' } 
        >Create Negative Keyword</Button>
        <Button 
          variant="contained" 
          size="small" 
          onClick={this.onClickClear} 
          disabled={isProcessing} 
        >Clear</Button> 
      </div> 
    );
  }
  
  // Render received result for newly created negative keyword.
  // It will return result as an array of created negative keywords.
  // Note: In this user interface are are creating one keyword at 
  // a time, but server side api is capable to create multiple keyword  
  // (by calling api one time).
  renderResult = () => {
    //const { isProcessing } = this.state;
    const { keywordCreatedResult } = this.state;
    
    // 1 - Negative keyword Created Result not exist then do not show anything
    if ( !keywordCreatedResult ) { return null; }

    // 2 - If Negative keyword created result exist then render it
    // e.g. keywordCreatedResult = [
    //   {
    //     "keywordId": 0,
    //     "code": "string",
    //     "details": "string"
    //     "description": "string"
    //   }
    // ]
    return (
      <div align='left'>
        <b>Negative Keyword Created - Result/Error:</b> <br />
        <hr />
        { 
          keywordCreatedResult.map( (item, index) => {
            return (
              <div key={ 'negative_keyword_' + index } >
                <b>#{ index + 1}</b> <br />
                <b>keywordId:</b> { item.keywordId } <br />
                <b>code:</b> { item.code } <br />
                <b>details:</b> { item.details } <br />
                <b>description:</b> { item.description } <br />
                <hr />
              </div>
            )
          })
        }
      </div>
    );
  }
  //-----------------------------------------------------------------
  // End: Render content
  //-----------------------------------------------------------------


  render() {
    const { error, isProcessing } = this.state;
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>SponsoredProducts - Create Negative Keyword</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box my={0} className={classes.root} >
            { this.renderForm() }
            
            { this.renderFormButton() }
            
            { isProcessing && <LinearProgress /> }
            
            { error !== '' && <h3>{error}</h3> }
            
            { this.renderResult() }

          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  formControl: {
    width: '100%',
  },

});


SponsoredProductsCreateNegativeKeyword.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SponsoredProductsCreateNegativeKeyword);


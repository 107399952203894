// Edit flow campaign - Modal Screen
// i.e. Manage campaign, ad group etc. created under flow

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';

//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
// import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
//import PlayIcon from '@material-ui/icons/PlayArrow';
//import PauseIcon from '@material-ui/icons/Pause';
//import DeleteIcon from '@material-ui/icons/Delete';

import CircularProgress from '@material-ui/core/CircularProgress';
//import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';

// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';

// Import customised component
import { IOSSwitch } from '../common/IOSSwitch';


// Import helper funcion
import { 
  spGetCampaigns, spGetAdGroups, spGetKeywords, spGetNegativeKeywords, spGetProductAds,
  spCreateCampaigns, spCreateAdGroups, 
  spCreateKeywords, spCreateNegativeKeywords, spCreateProductAds,
  spUpdateCampaigns, spUpdateAdGroups,
} from '../../helpers/amazonAdHelper';

//import { getMatchingProductForId } from '../../helpers/mwsHelper';

import { getCurrentDate_YYYYMMDD, getFutureDate_YYYYMMDD, getPastDate_YYYYMMDD } from '../../helpers/utility';

// Import firebase 
import firebase from '../../helpers/firebaseApp';
const db = firebase.firestore();


class SpAdAutomationEditCampaign extends React.Component { 

  // When set DUMMY it will not call amazon ad api but call the logic to return dummy result
  // so we can test whole flow during development. During dummy it will not any create any campaign 
  // etc. within amazon account. It will just save dummy data within db and run necessary flow.
  // 
  // IMPORTANT: When set REAL it will call amazon ad api, and save necessary data within db also.
  API_MODE_AUTO_FLOW = 'REAL';    // 'DUMMY' or 'REAL'  (Auto Campaign create flow)
  API_MODE_MANUAL_FLOW = 'REAL'; // 'DUMMY' or 'REAL'  (Manual Campaign create flow)

  // Retry Count Variable for Auto Flow
  RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_AUTO = 0; // Retry count for Negative Keyword create (Auto flow)
  RETRY_COUNT_CREATE_PRODUCT_AD_AUTO = 0;       // Retry count for Product Ad Create  (Auto flow)

  // Retry count variable for manual flow.
  RETRY_COUNT_CREATE_BROAD_AD_GROUP_MANUAL = 0;   // Retry count for Broad Ad Group create (Manual flow)
  RETRY_COUNT_CREATE_PHRASE_AD_GROUP_MANUAL = 0;  // Retry count for Phrase Ad Group create (Manual flow)
  RETRY_COUNT_CREATE_EXACT_AD_GROUP_MANUAL = 0;   // Retry count for Phrase Ad Group create (Manual flow)
  RETRY_COUNT_CREATE_KEYWORD_MANUAL = 0;          // Retry count for Keyword creation (Manual flow)
  RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL = 0; // Retry count for Negative keyword creation (Manual flow)
  RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL = 0;       // Retry count for Product ad creation. (Manual flow)
  
  // Default state
  state = {

    // true - show debug info, false - hide debug info
    showDebug: false, 

    // Start: ----- Form input -----
    // Type of campaign 
    autoAdGroupChecked: false, 
    broadAdGroupChecked: false,
    phraseAdGroupChecked: false,
    exactAdGroupChecked: false,

    // If automatic campaign exist within db then we will disable checkbox for that
    // so user can not uncheck it.
    disableAutomaticCheckbox: false, 
    disableBroadAdGroupCheckbox: false, 
    disablePhraseAdGroupCheckbox: false, 
    disableExactAdGroupCheckbox: false, 

    // Bid amount for each group type, If some ad group not created then we 
    // will show text input while user select checkbox to create that ad gorup.
    // So user can set bid amount that that ad group.
    // Note: It may happen that all below fields not used all time, so depending 
    // on the need to create new adgroup we will use some fields from below.
    bidAmountAutomatic: 0.5,    // Default 0.5
    bidAmountBroad: 0.5,        // Default 0.5
    bidAmountPhrase: 0.5,       // Default 0.5
    bidAmountExact: 0.8,        // Default 0.8


    // string: Campaign Start Date
    // A starting date for the campaign to go live. 
    // The format of the date is YYYYMMDD for passing to api.
    // Date input control expect format in YYYY-MM-DD
    // Note: If Auto or Manual campaign not created then we will use this text input.
    // This will be current date by default.
    startDate: "",  // e.g. YYYY-MM-DD

    // string: Campaign End Date
    // An ending date for the campaign to stop running. 
    // The format of the date is YYYYMMDD for passing to api
    // Date input control expect format in YYYY-MM-DD
    // Note: If Auto or Manual campaign not created then we will use this text input.
    // This will be end date of Auto Or Manual Campaign (whatever is created)
    endDate: "",  // e.g. YYYY-MM-DD

    // Budget for automatic campaign (input by user)
    // Note: If automatic campaign not created yet, and If need to create automatic 
    // campaign during save operation then we will use below field to take use input.
    campaignBudgetAutomatic: 5, 

    // Budget for manual campaign (input by user)
    // Note: If manual campaign not created yet, and If need to create manual campaign
    // during save operation then we will use below field to take use input.
    campaignBudgetManual: 5, 

    // End: ----- Form input -----


    // Start: --- Used for Edit (Auto and Manual campaign) -----
    editModeAutoCampaign: false,    // false - edit mode off, true - edit mode on
    editModeAutoAdGroup: false,     // false - edit mode off, true - edit mode on

    editModeManualCampaign: false,  // false - edit mode off, true - edit mode on
    editModeBroadAdGroup: false,    // false - edit mode off, true - edit mode on
    editModePhraseAdGroup: false,   // false - edit mode off, true - edit mode on
    editModeExactAdGroup: false,    // false - edit mode off, true - edit mode on

    isUpdatingAutoCampaign: false,   // Auto campaign update in progress
    isUpdatingAutoAdGroup: false,    // Auto AdGroup update in progress

    isUpdatingManualCampaign: false, // Manual campaign update in progress
    isUpdatingBroadAdGroup: false,   // Broad AdGroup update in progress
    isUpdatingPhraseAdGroup: false,  // Phrase AdGroup update in progress
    isUpdatingExactAdGroup: false,   // Exact AdGroup update in progress

    editDailyBudgetAuto: "", // Daily budget edit field value (auto campaign)
    editStartDateAuto: "",   // Start date edit field value (auto campaign) Format: YYYY-MM-DD (Note: we have to pass YYYYMMDD format amazon to api)
    editEndDateAuto: "",     // End date edit field value. (auto campaign) Format: YYYY-MM-DD (Note: we have to pass YYYYMMDD format amazon to api)
    editStateAuto: "",       // state field e.g. 'enabled', 'paused', 'archived' (auto campaign)

    editDailyBudgetManual: "" , // Daily budget edit field value (manual campaign)
    editStartDateManual: "",    // Start date edit field value (manual campaign) Format: YYYY-MM-DD (Note: we have to pass YYYYMMDD format amazon to api)
    editEndDateManual: "",      // End date edit field value. (manual campaign) Format: YYYY-MM-DD (Note: we have to pass YYYYMMDD format amazon to api)
    editStateManual: "",        // State for auto campaign e.g. 'enabled', 'paused', 'archived'

    editDefaultBidAutoAdGroup: 0,   // Default bid amount (Auto AdGroup)
    editStateAutoAdGroup: "",       // state field e.g. 'enabled', 'paused', 'archived' (Auto AdGroup)

    editDefaultBidBroadAdGroup: 0,  // Default bid amount (Broad AdGroup)
    editStateBroadAdGroup: "",      // state field e.g. 'enabled', 'paused', 'archived' (Broad AdGroup)
    
    editDefaultBidPhraseAdGroup: 0, // Default bid amount (Phrase AdGroup)
    editStatePhraseAdGroup: "",     // state field e.g. 'enabled', 'paused', 'archived' (Phrase AdGroup)
    
    editDefaultBidExactAdGroup: 0,  // Default bid amount (Exact AdGroup)
    editStateExactAdGroup: "",      // state field e.g. 'enabled', 'paused', 'archived' (Exact AdGroup)
    // End: --- Used for Edit (Auto and Manual campaign) -----


    // Start: ---- Below variable used for display purpose within ui ---- 
    // List of Negative keywords will be added under Auto adgroup that suppose to create.
    hintNegativeKeywordAuto: [],    // e.g. ['keyword1', 'keyword2', ... ]

    // List of Keyword + Negative Keyword added under Broad Ad Group that suppose to create.
    hintKeywordBroad: [],           // e.g. ['keyword1', 'keyword2', ... ]
    hintNegativeKeywordBroad: [],   // e.g. ['keyword1', 'keyword2', ... ]

    // List of Keyword + Negative Keyword added under Phrase Ad Group that suppose to create.
    hintKeywordPhrase: [],          // e.g. ['keyword1', 'keyword2', ... ]
    hintNegativeKeywordPhrase: [],  // e.g. ['keyword1', 'keyword2', ... ]

    // List of Keyword added under Exact Ad Group that suppose to create.
    hintKeywordExact: [],           // e.g. ['keyword1', 'keyword2', ... ]

    // End: ---- Below variable used for display purpose within ui ---- 


    // True - Open current modal, false - hide it
    isOpen: true,

    // We will fetch flow related data from db and set here.
    // e.g. flowDataDb: { key1:value1, key2:{ }, key3:[], key4:value4, ... }
    flowDataDb: null,

    // We will fetch more info about campaign via amazon api and 
    // set it here for further use.
    campaignListApi:[],

    // We will fetch more info about ad group created for campaign and 
    // set it here for further use.
    adGroupListApi: [],

    // We will fetch keywords created for automatic and manual campaign 
    // and set it here for further use.
    keywordListApi: [],
    keywordListFetched: false,  // Will be set true once keyword list fetched

    // We will fetch negative keywords created for automatic and manual 
    // campaign and set it here for further use.
    negativeKeywordListApi: [],
    negativeKeywordListFetched: false,  // Will be set true once negative keyword list fetched

    // We will fetch product ads created under automatic and manual 
    // campaign and set it here.
    productAdListApi: [],
    productAdListFetched: false,   // Will be set true once product ad list fetched


    // Some processing in progress or not.
    isProcessing: false, 

    
    // START: --------- Used for Auto Campaign OR Auto AdGroup creation ----    
    // true - Auto Campaign onwards entity creation in progress, false - Not creating
    // i.e. Creating: Auto Campaign -> AutoAdGroup -> Negative Keywords -> Product Ads
    isCreatingAutoCampaign: false,

    // true - Auto AdGroup onwards entity creation in progress, false - Not creating
    // i.e. Creating: AutoAdGroup -> Negative Keywords -> Product Ads 
    // Note: Auto Campaign already created, so no need to create it.
    isCreatingAutoAdGroup: false,

    // Once auto campaign created, we will set campaidId here. 
    // Note: If campaign was created before and user is creating AutoAdGroup now 
    // in that case also we will set existing auto campaign id here, so other steps will 
    // can be used with same logic.
    createdAutoCampaignId: '', // Once auto campaign created, we will set auto campaidId here. 
    createdAutoAdGroupId: '', // Once auto AdGroup created, we will set auto adGroupId here.
    // END: --------- Used for Auto Campaign OR Auto AdGroup creation ----    

    
    // START: --------- Used during Manual Campaign OR Broad/Phrase/Exact AdGroup creation ----
    // true - Manual Campaign onwards entity creation in progress, false - Not creating
    // i.e. Creating: Manual Campaign -> Broad+Phrase+Exact AdGroup --> Keywords -> Negative Keywords -> Product Ads
    isCreatingManualCampaign: false, 
    
    // true - Broad AdGroup onwards entity creation in progress, false - Not creating
    // i.e. Creating: Broad AdGroup --> Keywords -> Negative Keywords -> Product Ads
    // Note: Manual Campaign already created, so no need to create it.
    isCreatingBroadAdGroup: false, 

    // true - Phrase AdGroup onwards entity creation in progress, false - Not creating    
    // i.e. Creating: Phrase AdGroup --> Keywords -> Negative Keywords -> Product Ads
    // Note: Manual Campaign already created, so no need to create it.
    isCreatingPhraseAdGroup: false, 

    // true - Exact AdGroup onwards entity creation in progress, false - Not creating    
    // i.e. Creating: Exact AdGroup --> Keywords -> Product Ads
    // Note: Manual Campaign already created, so no need to create it.
    isCreatingExactAdGroup: false, 

    // Once Manual campaign created, we will set campaidId here. 
    // Note: If manual campaign was created before and user is creating Broad/Phrase/Exact 
    // AdGroup now in that case also we will set existing manual campaign id here, so other 
    // steps can be used with same logic.
    createdManualCampaignId: '', // Once Manual campaign created, we will set id here.
    createdBroadAdGroupId: '',  // Once Broad AdGroup created, we will set id here.
    createdPhraseAdGroupId: '', // Once Phrase AdGroup created, we will set id here.
    createdExactAdGroupId: '',  // Once Exact AdGroup created, we will set id here.

    manualBroadAdGroupCreated: false,  // Is Broad adgroup created during manual flow creation.
    manualPhraseAdGroupCreated: false, // Is Phrase adgroup created during manual flow creation.
    manualExactAdGroupCreated: false,  // Is Exact adgroup created during manual flow creation.

    manualKeywordCreated: false,          // Once (positive) keyword created for manual flow, we will set true
    manualNegativeKeywordCreated: false,  // Once Negative keyword created for manual flow, we will set true
    manualProductAdCreated: false,        // Once product ad created for manual flow, we will set it true
    // END: --------- Used during Manual Campaign OR Broad/Phrase/Exact AdGroup creation ----


    // Message text
    showMessage: false,
    messageText: "",
    //errorMessage: "",

  }


  // Called when component mounted
  componentDidMount = () => {
    console.log('SpAdAutomationEditCampaign - componentDidMount() flowDocId:', this.props.flowDocId)

    // 1 - Set default value for campaign start, end date
    const startDate = getCurrentDate_YYYYMMDD(0);
    this.setState({
      startDate: startDate,
    });

    // Fetch flow data from db.
    this.fetchFlowDataDb();
  }

  componentWillUnmount = () => {
    console.log('SpAdAutomationEditCampaign - componentWillUnmount()');
  }

  // Called when close button tapped
  // We will close the current model and inform parent about close.
  handleClose = () => {
    //console.log('SpAdAutomationEditCampaign - handleClose()');

    // Close current modal
    this.setState({
      isOpen: false,
    });

    // Inform parent component that close button tapped from modal,
    // so it will unload current component from its render list.
    if (this.props.onEditCampaignClose) {
      this.props.onEditCampaignClose();
    }
  }; 


  //-----------------------------------------------------------------
  // Start: Data fetch related functions
  //-----------------------------------------------------------------
  // Fetch flow related data from db 
  fetchFlowDataDb = () => {
    //console.log('SpAdAutomationEditCampaign - fetchFlowDataDb()');

    const { flowDocId } = this.props;

    // Fetch flow info from db
    db.collection('sponsored_product_ad').doc(flowDocId).get()
    .then( (doc) => {
      
      if (!doc.exists) {
        return;
      }

      // Grab required data
      const flowData = {};
      const data = doc.data();
      flowData['flow_name'] = data.flow_name;
      flowData['profile_id'] = data.profile_id;
      flowData['profile_type'] = data.profile_type;
      flowData['api_mode'] = data.api_mode; // 'DUMMY' or 'REAL'

      flowData['products'] = data.products ? data.products : [];

      flowData['market'] = data.market ? data.market : '';
      flowData['market_place_id'] = data.market_place_id ? data.market_place_id : '';

      flowData['auto_campaign_id'] = data.auto_campaign_id ? data.auto_campaign_id : null;
      flowData['auto_ad_group_id'] = data.auto_ad_group_id ? data.auto_ad_group_id : null;

      flowData['manual_campaign_id'] = data.manual_campaign_id ? data.manual_campaign_id : null;
      flowData['manual_broad_ad_group_id'] = data.manual_broad_ad_group_id ? data.manual_broad_ad_group_id : null;
      flowData['manual_phrase_ad_group_id'] = data.manual_phrase_ad_group_id ? data.manual_phrase_ad_group_id : null;
      flowData['manual_exact_ad_group_id'] = data.manual_exact_ad_group_id ? data.manual_exact_ad_group_id : null;
      
      flowData['auto_status'] = data.auto_status;
      flowData['manual_status'] = data.manual_status;
      flowData['status'] = data.status;

      // Debug
      console.log('flowData:', flowData);

      // Prepare data to update state
      const stateUpdateData = {};
      stateUpdateData['flowDataDb'] = flowData;
      stateUpdateData['autoAdGroupChecked'] = data.auto_ad_group_id ? true : false;
      stateUpdateData['broadAdGroupChecked'] = data.manual_broad_ad_group_id ? true : false;
      stateUpdateData['phraseAdGroupChecked'] = data.manual_phrase_ad_group_id ? true : false;
      stateUpdateData['exactAdGroupChecked'] = data.manual_exact_ad_group_id ? true : false;

      stateUpdateData['disableAutomaticCheckbox'] = data.auto_ad_group_id ? true : false;
      stateUpdateData['disableBroadAdGroupCheckbox'] = data.manual_broad_ad_group_id ? true : false;
      stateUpdateData['disablePhraseAdGroupCheckbox'] = data.manual_phrase_ad_group_id ? true : false;
      stateUpdateData['disableExactAdGroupCheckbox'] = data.manual_exact_ad_group_id ? true : false;

      // Set Auto Campaign Create realated variable within state
      stateUpdateData['isProcessing'] = false;
      stateUpdateData['isCreatingAutoCampaign'] = false;
      stateUpdateData['isCreatingAutoAdGroup'] = false;
      stateUpdateData['createdAutoCampaignId'] = '';
      stateUpdateData['createdAutoAdGroupId'] = '';

      // Update Manual Campaign Create realated variable within state
      stateUpdateData['isCreatingManualCampaign'] = false;
      stateUpdateData['isCreatingBroadAdGroup'] = false;
      stateUpdateData['isCreatingPhraseAdGroup'] = false;
      stateUpdateData['isCreatingExactAdGroup'] = false;
      
      stateUpdateData['createdManualCampaignId'] = '';
      stateUpdateData['createdBroadAdGroupId'] = '';
      stateUpdateData['createdPhraseAdGroupId'] = '';
      stateUpdateData['createdExactAdGroupId'] = '';
      
      stateUpdateData['manualBroadAdGroupCreated'] = false;
      stateUpdateData['manualPhraseAdGroupCreated'] = false;
      stateUpdateData['manualExactAdGroupCreated'] = false;

      stateUpdateData['manualKeywordCreated'] = false;
      stateUpdateData['manualNegativeKeywordCreated'] = false;
      stateUpdateData['manualProductAdCreated'] = false;
  
      // Update All data within state
      this.setState(stateUpdateData);

      // Fetch necessary data via amazon ad api (if campaign created via REAL api mode)
      if (flowData.api_mode === 'REAL') {
        this.fetchDataViaAmazonApi();
      }

    })
    .catch( (error) => {
      console.log("Error fetching flow doc from db. error:", error);
    });
  }

  // Fetch necessary data via amazon ad api
  fetchDataViaAmazonApi = () => {
    console.log('fetchDataViaAmazonApi()');

    // Fetch campaign info via amazon api
    this.fetchCampaignListViaApi();

    // Fetch ad group info via amazon api
    this.fetchAdGroupListViaApi();

    // Fetch keyword list via amazon api
    this.fetchKeywordListViaApi();

    // Fetch negative keyword via amazon api
    this.fetchNegativeKeywordListViaApi();

    // Fetch product ads via amazon api
    this.fetchProductAdListViaApi();
  }


  // --------- START: Fetch Campaigns -------------
  // Fetch campaign info via amazon api
  fetchCampaignListViaApi = () => {
    //console.log('fetchCampaignListViaApi()');

    const { profile_id, auto_campaign_id, manual_campaign_id } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // Comma separated campaign ids e.g. 'campaignId1,campaignId2'

    // 2 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Campaigns/listCampaigns
    const queryData = {
      campaignIdFilter: campaignIds,
      //startIndex: 0,
      //count: 100,
      //stateFilter: 'enabled,paused,archived',
    }
    console.log('spGetCampaigns - queryData:', queryData);

    // 3 - Call api to Get sponsored products campaign list
    spGetCampaigns(profile_id, queryData, this.spGetCampaigns_Success, this.spGetCampaigns_Error);
  }

  // Called when campaign list fetched successfully
  spGetCampaigns_Success = (result) => {
    console.log('spGetCampaigns_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        campaignListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // show error message etc.
    }
  }

  // Called if any error while fetch campaign list
  spGetCampaigns_Error = (error) => {
    console.log('spGetCampaigns_Error() error:', error);
    
    // show error message etc.
  }
  // --------- END: Fetch Campaigns -------------
  

  // --------- START: Fetch Ad Groups -------------
  // Fetch ad group list via amazon api (i.e. list of ad group under given campaign id)
  fetchAdGroupListViaApi = () => {
    //console.log('fetchAdGroupListViaApi()');

    const { 
      profile_id, 
      auto_campaign_id, auto_ad_group_id, 
      manual_campaign_id, 
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id,
    } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 2 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Ad%20groups/getAdGroups
    const queryData = {
      campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
      //stateFilter: 'enabled,paused,archived',
      //startIndex: 0,
      //count: 100,
    }
    console.log('spGetAdGroups - queryData:', queryData);    

    // Call api to Get ad groups (list)
    spGetAdGroups(profile_id, queryData, this.spGetAdGroups_Success, this.spGetAdGroups_Error);
  }
  
  // Called when ad groups (list) fetched successfully
  spGetAdGroups_Success = (result) => {
    console.log('spGetAdGroups_Success() result:', result);

    if ( result.status === 'success' ) {
      this.setState({
        adGroupListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // Show error message etc
    }
  }

  // Called if any error while fetch ad groups (list)
  spGetAdGroups_Error = (error) => {
    console.log('spGetAdGroups_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Ad Groups ------------- 

   
  // --------- START: Fetch Keywords -------------
  // Fetch keyword list via amazon api (i.e. for given campaignId and adGroupId)  
  fetchKeywordListViaApi = () => {
    //console.log('fetchKeywordListViaApi()');

    const { profile_id, 
            auto_campaign_id, auto_ad_group_id,
            manual_campaign_id,
            manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
          } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/listKeywords
    const queryData = {
      //startIndex: 0,
      //count: 100,
      stateFilter: 'enabled,paused', // 'enabled,paused,archived'
      //campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
    }
    console.log('spGetKeywords - queryData:', queryData);

    // 4 - Call api to Get keywords (list) for selected profile Id.
    spGetKeywords(profile_id, queryData, this.spGetKeywords_Success, this.spGetKeywords_Error);
  }

  // Called when keywords (list) fetched successfully
  spGetKeywords_Success = (result) => {
    console.log('spGetKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        keywordListApi: result.data,
        keywordListFetched: true,
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
    }
  }
  
  // Called if any error while fetch keywords (list)
  spGetKeywords_Error = (error) => {
    console.log('spGetKeywords_Error() error:', error);
    // Show error message etc.
  }  
  // --------- END: Fetch Keywords -------------


  // --------- START: Fetch Negative Keywords -------------
  // Fetch negative keyword list via amazon api (i.e. for given campaignId and adGroupId)    
  fetchNegativeKeywordListViaApi = () => {
    //console.log('fetchNegativeKeywordListViaApi()');

    const { profile_id, 
      auto_campaign_id, auto_ad_group_id,
      manual_campaign_id,
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
    } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/listNegativeKeywords
    const queryData = {
      // startIndex: 0,
      // count: 100,  
      stateFilter: 'enabled,paused',  // 'enabled,paused,archived'
      //campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
    }
    console.log('spGetNegativeKeywords - queryData:', queryData);

    // 4 - Call api to Get negative keywords (list) for selected profile Id.
    spGetNegativeKeywords(profile_id, queryData, this.spGetNegativeKeywords_Success, this.spGetNegativeKeywords_Error);
  }

  // Called when negative keywords (list) fetched successfully
  spGetNegativeKeywords_Success = (result) => {
    console.log('spGetNegativeKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        negativeKeywordListApi: result.data,
        negativeKeywordListFetched: true,
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
    }
  }
  
  // Called if any error while fetch negative keywords (list)
  spGetNegativeKeywords_Error = (error) => {
    console.log('spGetNegativeKeywords_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Negative Keywords ---------------


  // --------- START: Fetch Product Ads -------------------
  // Fetch product ads via amazon api (for auto and manual campaign)
  fetchProductAdListViaApi = () => {
    //console.log('fetchProductAdListViaApi()');

    const { profile_id, 
            auto_campaign_id, auto_ad_group_id, 
            manual_campaign_id,
            manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id, 
          } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Product%20ads/listProductAds
    const queryData = {
      //startIndex: 0,
      //count: 100,
      stateFilter: 'enabled,paused', // 'enabled,paused,archived'
      //campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
    }
    console.log('spGetProductAds - queryData:', queryData);

    // 4 - Call api to Get product ads (list) for selected profile Id.
    spGetProductAds(profile_id, queryData, this.spGetProductAds_Success, this.spGetProductAds_Error);
  }

  // Called when product ads (list) fetched successfully
  spGetProductAds_Success = (result) => {
    console.log('spGetProductAds_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        productAdListApi: result.data,
        productAdListFetched: true,
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
    }
  }
  
  // Called if any error while fetch product ads (list)
  spGetProductAds_Error = (error) => {
    console.log('spGetProductAds_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Product Ads ------------


  //-----------------------------------------------------------------
  // End: Data fetch related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Add/Update/Delete related functions
  //-----------------------------------------------------------------

  // Called when automatic campaign (i.e. Auto Ad Group) checkbox toggle done.
  handleChangeAutomatic = (isChecked) => {
    //console.log('handleChangeAutomatic() isChecked:', isChecked);

    this.setState({
      autoAdGroupChecked: isChecked,
    });

    // If checked then Prepare keyword hint etc.
    if (isChecked) { 
      this.prepareNegativeKeywordHint_Auto();
      this.setDefaultEndDateFrom_ManualCampaign();
    }
  }

  // Prepar hint how many Negative keywords will be added under new Auto ad group.
  // i.e. Fetch Broad Negative + Phrase Negative + Exact Positive keyword and combine it. (Remove duplicate)
  prepareNegativeKeywordHint_Auto = () => {
    console.log('prepareNegativeKeywordHint_Auto()');
  
    const { keywordListApi, negativeKeywordListApi } = this.state;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 1 - Create empty dictionary object. It will not allow duplicate keys, so if we add 
    // same keyword multiple time it will consider as single entry within dictionary.
    const keywordDict = {};

    // 2 - Grab Negative keyword from Manual Broad
    if (manual_broad_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => { 
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 3 - Grab Negative keyword from Manual Phrase
    if (manual_phrase_ad_group_id) {
      negativeKeywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // 4 - Grab Keyword from Manual Exact
    if (manual_exact_ad_group_id) {
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // Debug
    // keywordDict['Auto negative1'] = 'Auto negative1';
    // keywordDict['Auto negative2'] = 'Auto negative2';
    //console.log('Auto Negative keywordDict:', keywordDict);

    // 5 - Prepare array from dictionary.
    // e.g. ['keyword1', 'keyword2']
    const keywordArray = Object.values(keywordDict).map((value, index) => { return value; });
    console.log('hintNegativeKeywordAuto:', keywordArray);

    // 6 - Save hint keyword within state
    this.setState({
      hintNegativeKeywordAuto: keywordArray,
    });
  }


  // Whenever user will check Auto Ad Group Checkbox we will call this function so it will 
  // set auto campaign default end date value from the manual campaign which exist at present. 
  // Note: Atleast One campaign always exist i.e. either Auto or Manual so in this case there 
  // will be manual campaign always exist so we can fetch end date from that and use it while 
  // create Auto Campaign.
  setDefaultEndDateFrom_ManualCampaign = () => {

    // 1 - If campaign list not loaded via api then return, 
    // we can not fetch any campaign end date without campaign list.
    const { campaignListApi } = this.state;
    if (campaignListApi.length === 0) { return; }

    // 2 - If Auto ad group exist then checkbox for auto campaign was disabled, 
    // it means no need to create auto campaign so return. i.e. no need to set end date.
    const { disableAutomaticCheckbox } = this.state;
    if ( disableAutomaticCheckbox ) { return null; }

    // 3 - Get campaign info from manual_campaign_id
    const { manual_campaign_id } = this.state.flowDataDb;
    const campaignInfo = this.getCampaignInfoFromId(manual_campaign_id);
    
    // 4 - If campaign info not found then return
    if (!campaignInfo) { return; }

    // 5 - Fetch campaign end date from data received via api 
    // It will be in YYYYMMDD string format
    const { endDate } = campaignInfo;  
    //console.log('endDate:', endDate);

    // 5A - If end date expired (passed current date) (endDate < currentDate) 
    // then do not assign endDate to state variable. i.e. Keep the end date empty 
    // so user will select endDate himself.
    const endDateYYYY = endDate.substr(0,4);  // e.g. YYYY
    const endDateMM = endDate.substr(4,2);    // e.g. MM 
    const endDateDD = endDate.substr(6,2);    // e.g. DD
    const endDateJS = new Date(endDateYYYY, endDateMM - 1, endDateDD); // Create JS Date object
    const currentDateJs = new Date();  // JS Date object
    //console.log('(From Manual Campaign) endDateJS:', endDateJS); 
    //console.log('(From Manual Campaign) currentDateJs:', currentDateJs); 

    if ( endDateJS < currentDateJs) { // End date passed current date
      this.setState({ endDate: '' });
      return; // Important
    }


    // 6 - Convert date from YYYYMMDD to YYYY-MM-DD 
    // i.e. convert date from 20201119 to 2020-11-19 because this format need for date ui component.
    const endDateFormated = endDate.substr(0,4) + '-' + endDate.substr(4,2) + '-' + endDate.substr(6,2); 
    console.log('(for Auto Campaign) endDateFormated:', endDateFormated);

    // 7 - Set end date within state.
    this.setState({
      endDate: endDateFormated,
    });
  }



  // This function called when user enter bid amount for automatic campaign
  onChangeBidAmountAutomatic = (value) => {
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ bidAmountAutomatic: value });
  }

  // This function called when user enter automatic campaign budget
  onChangeCampaignBudgetAutomatic = (value) => {
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 999
    if ( value > 999 ) { return; }

    // 3 - Set value within state
    this.setState({ campaignBudgetAutomatic: value });
  } 



  // Called when Broad Ad Group checkbox toggle done.
  handleChangeBroadAdGroup = (isChecked) => {
    console.log('handleChangeBroadAdGroup() isChecked:', isChecked);

    this.setState({
      broadAdGroupChecked: isChecked,
    });

    // If checked then Prepare keyword hint.
    if (isChecked) { 
      this.prepareKeywordHint_Broad();
      this.prepareNegativeKeywordHint_Broad();
      this.setDefaultEndDateFrom_AutoCampaign();
    }
  }

  // Prepar hint how many Postive keywords will be added under new Broad ad group.
  // i.e. Grab Phrase Positive + Exact Positive keyword and combine it. (Remove duplicate)
  prepareKeywordHint_Broad = () => {
    console.log('prepareKeywordHint_Broad()');

    const { keywordListApi } = this.state;
    const { manual_phrase_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 1 - Create empty dictionary object. It will not allow duplicate keys, so if we add 
    // same keyword multiple time it will consider as single entry within dictionary.
    const keywordDict = {};

    // 2 - Grab keyword from Manual Phrase Ad Group.
    if (manual_phrase_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 3 - Grab keyword from Manual Exact Ad Group.
    if (manual_exact_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // Debug 
    // keywordDict['Broad keyword1'] = 'Broad keyword1';
    // keywordDict['Broad keyword2'] = 'Broad keyword2';
    console.log('Broad keywordDict:', keywordDict);

    // 4 - Prepare keyword array from dictionary 
    // e.g. ['keyword1', 'keyword2']
    const keywordArray = Object.values(keywordDict).map((value, index) => { return value; });
    
    // 5 - Save hint keyword within state
    this.setState({
      hintKeywordBroad: keywordArray,
    });
  }
  
  
  // Prepar hint how many Negative keywords will be added under new Broad ad group.
  // i.e. Grab Auto Negative + Phrase Negative + Exact Positive keyword and combine it. (Remove duplicate)
  prepareNegativeKeywordHint_Broad = () => {
    console.log('prepareNegativeKeywordHint_Broad()');
  
    const { keywordListApi, negativeKeywordListApi } = this.state;
    const { auto_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 1 - Create empty dictionary object. It will not allow duplicate keys, so if we add 
    // same keyword multiple time it will consider as single entry within dictionary.
    const keywordDict = {};

    // 2 - Grab Negative keyword from Auto Ad Group.
    if (auto_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => { 
        if ( item.adGroupId === auto_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 3 - Grab Negative keyword from Manual Phrase ad group.
    if (manual_phrase_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => { 
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // 4 - Grab Keyword from Manual Exact ad group.
    if (manual_exact_ad_group_id) {
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // Debug 
    // keywordDict['Broad Negative1'] = 'Broad Negative1';
    // keywordDict['Broad Negative2'] = 'Broad Negative2';
    console.log('Broad Negative keywordDict:', keywordDict);

    // 5 - Prepare keyword array from dictionary 
    // e.g. ['keyword1', 'keyword2']
    const keywordArray = Object.values(keywordDict).map((value, index) => { return value; });

    // 6 - Save hint keyword within state
    this.setState({
      hintNegativeKeywordBroad: keywordArray,
    });
  }



  // Called when Phrase Ad Group checkbox toggle done.  
  handleChangePhraseAdGroup = (isChecked) => {
    //console.log('handleChangePhraseAdGroup() isChecked:', isChecked);

    this.setState({
      phraseAdGroupChecked: isChecked,
    });

    // If checked then Prepare keyword hint.
    if (isChecked) { 
      this.prepareKeywordHint_Phrase();
      this.prepareNegativeKeywordHint_Phrase();
      this.setDefaultEndDateFrom_AutoCampaign();
    }
  }

  // Prepar hint how many keywords will be added under new Phrase ad group.
  // i.e. Grab Broad Positive + Exact Positive keyword and combine it. (Remove duplicate)
  prepareKeywordHint_Phrase = () => {
    console.log('prepareKeywordHint_Phrase()');

    const { keywordListApi } = this.state;
    const { manual_broad_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 1 - Create empty dictionary object. It will not allow duplicate keys, so if we add 
    // same keyword multiple time it will consider as single entry within dictionary.
    const keywordDict = {};

    // 2 - Grab keyword from Manual Broad Ad Group.
    if (manual_broad_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 3 - Grab keyword from Manual Exact Ad Group.
    if (manual_exact_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // Debug 
    // keywordDict['Phrase keyword1'] = 'Phrase keyword1';
    // keywordDict['Phrase keyword2'] = 'Phrase keyword2';
    console.log('Phrase keywordDict:', keywordDict);

    // 4 - Prepare keyword array from dictionary 
    // e.g. ['keyword1', 'keyword2']
    const keywordArray = Object.values(keywordDict).map((value, index) => { return value; });
    
    // 5 - Save hint keyword within state
    this.setState({
      hintKeywordPhrase: keywordArray,
    });
  }
  
  // Prepar hint how many Negative keywords will be added under new Phrase ad group.  
  // i.e. Grab Auto Negative + Broad Negative + Exact Positive keyword and combine it. (Remove duplicate)
  prepareNegativeKeywordHint_Phrase = () => {
    console.log('prepareNegativeKeywordHint_Phrase()');
  
    const { keywordListApi, negativeKeywordListApi } = this.state;
    const { auto_ad_group_id, manual_broad_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 1 - Create empty dictionary object. It will not allow duplicate keys, so if we add 
    // same keyword multiple time it will consider as single entry within dictionary.
    const keywordDict = {};

    // 2 - Grab Negative keyword from Auto Ad Group.
    if (auto_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => { 
        if ( item.adGroupId === auto_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 3 - Grab Negative keyword from Manual Broad ad group.
    if (manual_broad_ad_group_id) { 
      negativeKeywordListApi.forEach( (item, index) => { 
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // 4 - Grab Keyword from Manual Exact ad group.
    if (manual_exact_ad_group_id) {
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // Debug
    // keywordDict['Phrase negative1'] = 'Phrase negative1';
    // keywordDict['Phrase negative2'] = 'Phrase negative2';
    console.log('Phrase Negative keywordDict:', keywordDict);

    // 5 - Prepare keyword array from dictionary 
    // e.g. ['keyword1', 'keyword2']
    const keywordArray = Object.values(keywordDict).map((value, index) => { return value; });

    // 6 - Save hint keyword within state
    this.setState({
      hintNegativeKeywordPhrase: keywordArray,
    });
  }



  // Called when Exact Ad Group checkbox toggle done.    
  handleChangeExactAdGroup = (isChecked) => {
    //console.log('handleChangeExactAdGroup() isChecked:', isChecked);

    this.setState({
      exactAdGroupChecked: isChecked,
    });

    // If checked then Prepare keyword hint.
    if (isChecked) { 
      this.prepareKeywordHint_Exact();
      this.setDefaultEndDateFrom_AutoCampaign();
    }
  }

  // Prepar hint how many Keywords will be added under new Exact ad group.
  // e.g. Grab Broad postive + Phrase positive keyword and combine it. (Remove duplicate)
  prepareKeywordHint_Exact = () => {
    console.log('prepareKeywordHint_Exact()');

    const { keywordListApi } = this.state;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id } = this.state.flowDataDb;

    // 1 - Create empty dictionary object. It will not allow duplicate keys, so if we add 
    // same keyword multiple time it will consider as single entry within dictionary.
    const keywordDict = {};

    // 2 - Grab keyword from Manual Broad Ad Group.
    if (manual_broad_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_broad_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }

    // 3 - Grab keyword from Manual Broad Ad Group.
    if (manual_phrase_ad_group_id) { 
      keywordListApi.forEach( (item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) {
          keywordDict[item.keywordText] = item.keywordText;
        }
      });
    }
    
    // Debug 
    // keywordDict['Exact keyword1'] = 'Exact keyword1';
    // keywordDict['Exact keyword2'] = 'Exact keyword2';
    console.log('Exact keywordDict:', keywordDict);

    // 4 - Prepare keyword array from dictionary 
    // e.g. ['keyword1', 'keyword2']
    const keywordArray = Object.values(keywordDict).map((value, index) => { return value; });
    
    // 5 - Save hint keyword within state
    this.setState({
      hintKeywordExact: keywordArray,
    });
  }
  

  
  // Whenever user will check any checkbox from Broad Ad Group OR Phrase Ad Group OR Exact Ad Group
  // we will call this function so it will set manual campaign default end date value from the auto 
  // campaign exist at present. 
  // Note: Atleast One campaign always exist i.e. either Auto or Manual so in this case there 
  // will be auto campaign always exist so we can fetch end date from that and use it while 
  // create the Manual Campaign.
  setDefaultEndDateFrom_AutoCampaign = () => {

    // 1 - If campaign list not loaded then return, we can not fetch end data without campaign list.
    const { campaignListApi } = this.state;
    if (campaignListApi.length === 0) { return; }

    // 2 - If any one checkbox disabled (broad or phrase or exact) then no need to set 
    // default end date, because manual campaign was already created in the past, so no 
    // need to create manual campaign again.
    const { disableBroadAdGroupCheckbox, disablePhraseAdGroupCheckbox, disableExactAdGroupCheckbox } = this.state;
    if ( disableBroadAdGroupCheckbox || disablePhraseAdGroupCheckbox || disableExactAdGroupCheckbox ) { 
      console.log('Manual campaign already exist, So no need to set default endDate');
      return null;
    }

    // 3 - Get campaign info from auto_campaign_id
    const { auto_campaign_id } = this.state.flowDataDb;
    const campaignInfo = this.getCampaignInfoFromId(auto_campaign_id);
    
    // 4 - If campaign info not found then return
    if (!campaignInfo) { return; }

    // 5 - Fetch campaign end date from data received via api 
    // It will be in YYYYMMDD string format
    const { endDate } = campaignInfo;  
    //console.log('endDate:', endDate);

    // 5A - If end date expired (passed current date) (endDate < currentDate) then do not 
    // assign endDate to state variable. i.e. Keep the end date empty so user 
    // will select endDate himself.
    const endDateYYYY = endDate.substr(0,4);  // e.g. YYYY
    const endDateMM = endDate.substr(4,2);    // e.g. MM 
    const endDateDD = endDate.substr(6,2);    // e.g. DD
    const endDateJS = new Date(endDateYYYY, endDateMM - 1, endDateDD); // Create JS Date object
    const currentDateJs = new Date();  // JS Date object    
    // console.log('(From Auto Campaign) endDateJS:', endDateJS); 
    // console.log('(From Auto Campaign) currentDateJs:', currentDateJs); 
    if ( endDateJS < currentDateJs) { // End date passed current date, no need to set default end date.
      // this.setState({ endDate: '' });
      return; // Important
    }

    // 6 - Convert date from YYYYMMDD to YYYY-MM-DD 
    // i.e. convert date from 20201119 to 2020-11-19 because this format need for date ui component.
    const endDateFormated = endDate.substr(0,4) + '-' + endDate.substr(4,2) + '-' + endDate.substr(6,2); 
    //console.log('endDateFormated:', endDateFormated);

    // 7 - Set end date within state.
    this.setState({
      endDate: endDateFormated,
    });
  }



  // This function called when user enter bid amount for broad ad group
  onChangeBidAmountBroad = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; } // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 2 - Set value within state
    this.setState({ bidAmountBroad: value, });
  }


  // This function called when user enter bid amount for phrase ad group
  onChangeBidAmountPhrase = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ bidAmountPhrase: value, });
  }


  // This function called when user enter bid amount for exact ad group
  onChangeBidAmountExact = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ bidAmountExact: value, });
  }


  // This function called when user enter manual campaign budget  
  onChangeCampaignBudgetManual = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 9999
    if ( value > 9999 ) { return; }

    // 3 - Set value within state
    this.setState({ campaignBudgetManual: value, });
  } 
  //-----------------------------------------------------------------
  // End: Add/Update/Delete related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Create Auto Campaign + Auto Ad Group etc. (Api Call)
  //-----------------------------------------------------------------

  // Called when user tap Save/Create button from Auto AdGroup create button.
  // Note: In this case auto campaign is already exist, but auto adgroup not exist 
  // so user is creating Auto AdGroup. So we will skip auto campaign create step
  // and proceed to create Auto AdGroup onwards entity.
  onClick_CreateAutoAdGroup = () => {
    console.log('onClick_CreateAutoAdGroup()');

    // 1 - Fetch necessary data from state (to create auto campaign, auto adgroup, negative keywords)
    const { flowDocId } = this.props;
    const { bidAmountAutomatic } = this.state;
    const { auto_campaign_id } = this.state.flowDataDb;

    // 2 - If data not valid then show message and return.
    if ( !auto_campaign_id || auto_campaign_id === '') { 
      const msg = 'Auto campaign not exist, So can not create auto Ad Group.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !bidAmountAutomatic || bidAmountAutomatic < 0.02) { 
      const msg = 'Default Bid Amount Should be Minimum $0.02';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }

    // 3 - Reset variable before proceed to create auto campaign related things
    this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_AUTO = 0;
    this.RETRY_COUNT_CREATE_PRODUCT_AD_AUTO = 0;

    // 4 - Set processing on, also set creating auto adGroup true, 
    // so it will show loader near the auto adGroup box.
    // 
    // Note: We used auto agGroup create logic from auto campaign create flow, 
    // so we need to set isCreatingAutoCampaign= true because technically we skip 
    // auto campaign create step (because campaign already created) and now running 
    // flow from auto adgroup creation step onwards. 
    // 
    // Important: Here we are not just creating auto adGroup, but we will also 
    // create negative keywords, and product Ads for that autoAdGroup.
    //
    // Important: set createdAutoCampaignId = auto_campaign_id because to 
    // create auto adGroup it will use value from state.createdAutoCampaignId.
    this.setState({
      isProcessing: true,
      isCreatingAutoAdGroup: true,
      isCreatingAutoCampaign: true,
      createdAutoCampaignId: auto_campaign_id,
    }, () => {
      // IMPORTANT: We write this within setState() callaback parameter so we 
      // can make sure that once state variable set, then it will call 
      // this.createAdGroup_Auto() function, because it is using 
      // createdAutoCampaignId variable while creating ad group.
      // 
      // 5 - Proceed to create Auto AdGroup onwards entity using auto_campaign_id
      // i.e. Create AutoAdGroup --> Negative Keywords --> Product Ads
      // Just trigger step to create auto adGroup, so it will triger other step onwards
      // STEP-2: Create automatic ad group
      this.createAdGroup_Auto();
    });
  }


  // Called when user click cancel button from Auto Ad Group create step.
  // In this case auto campaign already exist, but Auto AdGroup not exist 
  // so user is creating AdGroup and clicked cancel button from that ui.
  onClick_CancelAutoAdGroup = () => {
    console.log('onClick_CancelAutoAdGroup()');

    // Uncheck auto ad group checkbox
    this.setState({
      autoAdGroupChecked: false,
      isCreatingAutoAdGroup: false,
      isCreatingAutoCampaign: false,
    });
  }



  // NOTE: Below function used when Auto Campaign not created yet and 
  // user need to create Auto Ad Group under Automatic Campaign. So we have to 
  // create Auto Campaign + Auto AdGroup + Negative Keywords + Products Ads for auto 
  // campaign, so below onClick_CreateAutoCampaign() function will take care for that.

  // Save Button tapped (Auto Campaign Create)
  // We have to create auto campaign + auto ad group + negative keyword + Product Ads
  onClick_CreateAutoCampaign = async () => {
    console.log('onClick_CreateAutoCampaign()');
    
    // 1 - Fetch necessary data from state (to create auto campaign, auto adgroup, negative keywords)
    const { flowDocId } = this.props;
    const { campaignBudgetAutomatic, bidAmountAutomatic, startDate, endDate } = this.state;
    const { auto_campaign_id } = this.state.flowDataDb;

    // 2 - If data not valid then show message and return.
    if ( auto_campaign_id !== null ) { // Auto campaign already exist
      const msg = 'Auto Campaign Already Exist, Can not create again.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !campaignBudgetAutomatic || campaignBudgetAutomatic < 5) { 
      const msg = 'Campaign Daily Budget Should be Minimum $5';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !bidAmountAutomatic || bidAmountAutomatic < 0.02) { 
      const msg = 'Default Bid Amount Should be Minimum $0.02';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !startDate || startDate === '') { 
      const msg = 'Please Select Campaign Start Date';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !endDate || endDate === '') { 
      const msg = 'Please Select Campaign End Date';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !flowDocId || flowDocId === '' ) { 
      const msg = 'flowDocId empty, So can not create automatic campaign';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }

    // Debug 
    // console.log('flowDocId:', flowDocId); 
    // console.log('auto_campaign_id:', auto_campaign_id); 
    // console.log('campaignBudgetAutomatic:', campaignBudgetAutomatic); 
    // console.log('bidAmountAutomatic:', bidAmountAutomatic); 
    // console.log('startDate:', startDate); 
    // console.log('endDate:', endDate); 

    // 3 - Set processing on, also set creating auto campaign true, 
    // so it will show loader near the auto campaign box.
    this.setState({
      isProcessing: true,
      isCreatingAutoCampaign: true,
      createdAutoCampaignId: '',  // We will create auto campaign and set here during flow.
      createdAutoAdGroupId: '',  // We will create auto ad group and set here during flow.
    });

    // 4 - Create Automatic flow
    this.createAutomaticFlow();
  }

  // Cancel Button tapped (Auto Campaign Create)
  onClick_CancelAutoCampaign = () => {
    console.log('onClick_CancelAutoCampaign()');

    this.setState({
      autoAdGroupChecked: false,
      isCreatingAutoCampaign: false,
    });
  }

  // For automatic flow we need to create below things
  // STEP-1: Create automatic campaign within amazon, and save auto_campaign_id within db.
  // STEP-2: Create ad group within amazon, and save auto_ad_group_id within db.
  // STEP-3: Create negative exact keyword within amazon. (No need to save any info within db)
  // STEP-4: Create product ad (using campaignId, groupId) within amazon. (No need to save any info within db)
  createAutomaticFlow = () => { 
    console.log('createAutomaticFlow()');

    // 1 - Grab flowDocId from props, If flowDocId not exist then return.
    const { flowDocId } = this.props;
    if ( !flowDocId || flowDocId === '' ) { 
      const msg = 'flowDocId empty, So can proceed to create automatic campaign';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }

    // 2 - Reset variable before proceed to create auto campaign flow.
    this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_AUTO = 0;
    this.RETRY_COUNT_CREATE_PRODUCT_AD_AUTO = 0;

    // 3 - 
    // STEP-1: Create automatic campaign
    this.createCampaign_Auto();
  }


  // STEP-1: Create automatic campaign within amazon, and save auto_campaign_id within db.
  createCampaign_Auto = () => {
    console.log('createCampaign_Auto()');

    // 1 - Grab data from state
    const { profile_id, flow_name } = this.state.flowDataDb;
    const { campaignBudgetAutomatic, startDate, endDate } = this.state;
    
    // Debug
    console.log('(Auto) profile_id:', profile_id);

    // 2 - Convert data to appropriate format
    const campaignName = flow_name + ' Auto Campaign';
    const startDateFinal = startDate.replace(/-/g, "");     // e.g. 20201021
    const endDateFinal = endDate.replace(/-/g, "");         // e.g. 20201028
    const dailyBudget = parseFloat(campaignBudgetAutomatic);

    // 3 - Prepare data to create campaign
    // Note: We can pass either premiumBidAdjustment = false or bidding={} but not both.
    // So if premiumBidAdjustment is true then set bidding key:value, otherwise set 
    // premiumBidAdjustment key:value
    const campaignData1 = {
      name: campaignName,
      campaignType: 'sponsoredProducts', 
      targetingType: 'auto', 
      state: 'enabled', 
      dailyBudget: dailyBudget, 
      startDate: startDateFinal, 
      endDate: endDateFinal, 
      premiumBidAdjustment: false, 
    }
    const campaignDataArray = [ campaignData1 ];
    console.log('(Auto) campaignDataArray:', campaignDataArray);

    // 4 - Run success callback with dummy result
    // Note: Comment below dummy callback once whole flow ready.
    // Start: dummy callback
    if ( this.API_MODE_AUTO_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Auto Campaign (Auto Flow)');
      
      // 4A - Imitate Success callback
      const dummyResult = { 
        status: 'success', 
        data: [ {campaignId: 101, code: 'SUCCESS', description: ''} ],
        error: null,
      }
      this.spCreateCampaigns_Auto_Success(dummyResult);
      return; // Important

      // // 4B - OR Imitate error callback
      // const error = { code: 'error', message: 'Auto Campaign create error' }
      // this.spCreateCampaigns_Auto_Error(error);
      // return; // Important

    }
    // End: dummy callback

    // 4 - Call amaozon api to create automatic campaign
    spCreateCampaigns(profile_id, campaignDataArray, this.spCreateCampaigns_Auto_Success, this.spCreateCampaigns_Auto_Error);
  }

  // Called if automatic campaign created successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //       "campaignId": 0,
  //       "code": "SUCCESS",
  //       "description": "string"
  //     }  
  //   ],
  //   error: null
  // }
  spCreateCampaigns_Auto_Success = async (result) => {
    console.log('spCreateCampaigns_Auto_Success() result:', result);

    // If status success then save created campaign id within state and db
    // status = 'success' returned by server side endpoint.
    // if any error while processing request on server side it will return status = 'error'    
    if ( result.status === 'success' ) {
      const { code, campaignId, description, details } = result.data[0];
      const { flowDocId } = this.props;

      if (code === 'SUCCESS') { // Returned by amazon if campaign created
        // Save created campaign id within db
        await db.collection('sponsored_product_ad').doc(flowDocId).update({ auto_campaign_id: campaignId });
        
        // Save created auto campaign id within state
        this.setState({ 
          createdAutoCampaignId: campaignId,
        }, () => {
          // Important: Must write within setState() callback, so createdAutoCampaignId 
          // set within state and then it call this.createAdGroup_Auto() function.
          // STEP-2: Create automatic ad group
          this.createAdGroup_Auto();
        });

      } else {
        //const msg = 'Error while creating automatic campaign. ' + description + details;
        const msg = 'Error while creating automatic campaign, Please try again.';
        this.setState({
          messageText: msg,
          showMessage: true,
          isProcessing: false,
          isCreatingAutoCampaign: false,
        }); 

        // Call this function so it wil refresh data and ui.
        this.considerAutoCampaignCreated();
      }
    }
    
    // If any error while calling amazon api, our server side code will 
    // return status = 'error'
    if ( result.status === 'error' ) {
      
      const msg = 'Error creating automatic campaign, Please try again.';
      this.setState({
        //messageText: result.error,
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingAutoCampaign: false,
      });

      // Call this function so it wil refresh data and ui.
      this.considerAutoCampaignCreated();
    }

  }

  // Called if any error while creating automatic campaign
  spCreateCampaigns_Auto_Error = (error) => {
    console.log('spCreateCampaigns_Auto_Error() error:', error);

    const msg = 'Error creating automatic campaign, Please try again.';
    this.setState({
      //messageText: error,
      messageText: msg,
      showMessage: true,
      isProcessing: false,
      isCreatingAutoCampaign: false,
    });

    // Call this function so it wil refresh data and ui.
    this.considerAutoCampaignCreated();
  }


  // STEP-2: Create ad group within amazon, and save auto_ad_group_id within db and state.
  createAdGroup_Auto = () => {
    console.log('createAdGroup_Auto()');

    // 1 - Grab data from state
    const { profile_id, flow_name } = this.state.flowDataDb;
    const { createdAutoCampaignId, bidAmountAutomatic } = this.state;
    const adGroupName = flow_name + ' Auto Ad Group';
    
    // Debug
    console.log('(Auto) profile_id:', profile_id);
    console.log('(Auto) createdAutoCampaignId:', createdAutoCampaignId);

    // 1A - IMPORTANT
    // If auto campaign id empty/not exist then return, we can not create auto ad group 
    // without auto campaign id.
    if ( createdAutoCampaignId === '' ) {
      const msg = 'Auto campaign id not found, So can not create auto ad group. Please try again.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingAutoCampaign: false,
        isCreatingAutoAdGroup: false,
      });

      // Call this function so it wil refresh data and ui.
      this.considerAutoCampaignCreated();

      return; // IMPORTANT
    }

    // 2 - Prepare data to create ad group
    // We have to pass ad group create related data as an array although we have to  
    // create one ad group at present. Server side api expect array object.
    // e.g. adGroupDataArray = [
    //   {
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ]      
    const adGroupData1 = {
      name: adGroupName, 
      campaignId: createdAutoCampaignId, 
      defaultBid: parseFloat(bidAmountAutomatic), 
      state: 'enabled', 
    }
    const adGroupDataArray = [ adGroupData1 ];
    console.log('(Auto) adGroupDataArray:', adGroupDataArray);

    // 3 - Run success callback with dummy result
    // Note: Comment below dummy callback once whole flow ready.
    // Start: dummy callback
    if ( this.API_MODE_AUTO_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Auto Ad Group (Auto Flow)');

      // 3A - OR Imitate succcess callback
      const dummyResult = { 
        status: 'success', 
        data: [ {adGroupId: 201, code: 'SUCCESS', details: ''} ],
        error: null,
      }
      this.spCreateAdGroups_Auto_Success(dummyResult);
      return; // Important

      // 3B - OR Imitate error callback
      // const error = { code: 'error', message: 'Auto AdGroup create error' }
      // this.spCreateAdGroups_Auto_Error(error);
      // return; // Important

    }
    // End: dummy callback

    // 3 - Call amaozon api to create ad group
    spCreateAdGroups(profile_id, adGroupDataArray, this.spCreateAdGroups_Auto_Success, this.spCreateAdGroups_Auto_Error);
  }

  // Called when ad group created successfully (for automatic campaign)
  spCreateAdGroups_Auto_Success = async (result) => {
    console.log('spCreateAdGroups_Auto_Success() result:', result);

    // If status success then save created adGroupId within state and db.
    // status = 'success' returned by server side endpoint.
    // if any error while processing request on server side it will return status = 'error'
    if ( result.status === 'success' ) {
      const { code, adGroupId, details, description } = result.data[0];
      const { flowDocId } = this.props;

      // Debug
      console.log('createdAutoAdGroupId:', adGroupId);

      if (code === 'SUCCESS') { // Returned by amazon if ad group created
        // Save ad group id within db
        await db.collection('sponsored_product_ad').doc(flowDocId).update({ auto_ad_group_id: adGroupId });
        
        // Save ad group id within state
        this.setState({ 
          createdAutoAdGroupId: adGroupId,
        }, () => {
          // Important: Must write in setState() callback so it will confirm that 
          // createdAutoAdGroupId value set within state and then proceed further.
          // STEP-3: Create Negative Keyword (for automatic flow) 
          this.createNegativeKeyword_Auto();
        });

      } else {
        //const msg = 'Error while creating ad group (automatic flow). ' + details ;
        const msg = 'Error while creating auto ad group, Please try again later. ';
        this.setState({
          messageText: msg,
          showMessage: true,
          isProcessing: false,
          isCreatingAutoCampaign: false,
        }); 

        // Call this function so it will refresh data and ui.
        this.considerAutoCampaignCreated();
      }
    }
    
    if (result.status === 'error') {
      const msg = 'Error while creating auto ad group, Please try again. ';
      this.setState({
        //messageText: result.error,
        messageText: msg, 
        showMessage: true,
        isProcessing: false,
        isCreatingAutoCampaign: false, 
      });

      // Call this function so it will refresh data and ui.
      this.considerAutoCampaignCreated();
    }
  }
  
  // Called if any error while creating ad group (for automatic flow)
  spCreateAdGroups_Auto_Error = (error) => {
    console.log('spCreateAdGroups_Auto_Error() error:', error);

    const msg = 'Error creating auto ad group, Please try again later.';
    this.setState({
      //messageText: error,
      messageText: msg,
      showMessage: true,
      isProcessing: false,
      isCreatingAutoCampaign: false,
    });

    // Call this function so it will refresh data and ui.
    this.considerAutoCampaignCreated();
  }


  // STEP-3: Create negative exact keyword within amazon. (No need to save any info within db)
  // Proceed to create negative keyword for automatic campaign
  createNegativeKeyword_Auto = () => {
    console.log('createNegativeKeyword_Auto()');

    // 1 - Read data from state
    const { profile_id } = this.state.flowDataDb;
    const { createdAutoCampaignId, createdAutoAdGroupId, hintNegativeKeywordAuto } = this.state;
    const selectedKeywords = hintNegativeKeywordAuto.filter((item, index) => { return true; }); // Copy array to new variable

    // Debug 
    console.log('(Auto) createdAutoCampaignId:', createdAutoCampaignId);
    console.log('(Auto) createdAutoAdGroupId:', createdAutoAdGroupId);

    // 1A - IMPORTANT: If createdAutoCampaignId empty then return
    if ( createdAutoCampaignId === '' ) {
      const msg = 'Auto campaign Id not found, So cant create negative keyword.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingAutoCampaign: false,
        isCreatingAutoAdGroup: false,
      });

      // Call this function so it wil refresh data and ui.
      this.considerAutoCampaignCreated();

      return; // IMPORTANT
    }

    // 1B - IMPORTANT: If createdAutoAdGroupId empty then return.
    if ( createdAutoAdGroupId === '' ) {
      const msg = 'Auto adGroup Id not found, So cant create negative keyword.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingAutoCampaign: false,
        isCreatingAutoAdGroup: false,
      });

      // Call this function so it wil refresh data and ui.
      this.considerAutoCampaignCreated();

      return; // IMPORTANT
    }


    // Debug
    console.log('(Auto) profile_id:', profile_id);
    console.log('(Auto) selectedKeywords:', selectedKeywords); // ['keyword1', 'keyword2']

    // 1A - IMPORTANT CHECK: 
    // If keyword list not exist then we will not call api to create negative keywords, 
    // but proceed to create product ads.
    if ( selectedKeywords.length === 0 ) {
      console.log('(Auto) Keyword List Not Exist, So Not Creating Negative Keywords.');

      // STEP 4: Create Product Ad (for automatic flow)
      this.createProdutAd_Auto();

      return; // Important
    }

    // 2 - Prepare data to create negative keyword
    // We have to pass data as an array although we have to create one 
    // keyword, because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ] 
    const keywordDataArray = [];
    selectedKeywords.forEach((item, index) => {
      const keywordData = {
        campaignId: createdAutoCampaignId,
        adGroupId: createdAutoAdGroupId,
        state: 'enabled',
        keywordText: item,
        matchType: 'negativeExact'
      }  
      keywordDataArray.push(keywordData);
    });
    console.log('(Auto) Negative keywordDataArray:', keywordDataArray);

    // 3 - Run success/error callback with dummy result/error
    // Start: dummy callback
    if ( this.API_MODE_AUTO_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Negative Keywords (Auto Flow)'); 
      
      // 3A - Imitate success callback
      const dummyResult = { 
        status: 'success', 
        data: [ { keywordId: 401, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      this.spCreateNegativeKeywords_Auto_Success(dummyResult);
      return; // Important

      // 3B - OR Imitate error callback
      // const error = { code: 'error', message: 'Negative keyword create error' }
      // this.spCreateNegativeKeywords_Auto_Error(error);
      // return; // Important

    }
    // End: dummy callback
  
    // 4 - Call api to create negative keywords
    spCreateNegativeKeywords(profile_id, keywordDataArray, this.spCreateNegativeKeywords_Auto_Success, this.spCreateNegativeKeywords_Auto_Error);
  }
 

  // Called when negative keyword created successfully (for automatic campaign)
  // e.g. jsonBody: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //          "description": ""
  //        }], 
  //        error: null 
  //      }
  spCreateNegativeKeywords_Auto_Success = (result) => {
    console.log('spCreateNegativeKeywords_Auto_Success() result:', result);
 
    if (result.status === 'success') {
      // We will not store keyword created result within db or state.
      // So within state we just update necessary state variable if need.
      // this.setState({
      //   isProcessing: false,
      // });

      // STEP 4: Create Product Ad (for automatic flow)
      // Note: We got success or error while creating negative keyword then also we can 
      // proceed to create product ad, so proceed to create product ad now.
      this.createProdutAd_Auto();
    }

    if (result.status === 'error') { 
      // try again to create negative keyword
      this.tryAgain_CreateNegativeKeyword_Auto();
    }

  }
  
  // Called if any error while creating negative keyword (for automatic flow)
  spCreateNegativeKeywords_Auto_Error = (error) => {
    console.log('spCreateNegativeKeywords_Auto_Error() error:', error);

    // try again to create negative keyword
    this.tryAgain_CreateNegativeKeyword_Auto();
  }

  
  // Try again (Retry) to create negative keyword for auto campaign
  tryAgain_CreateNegativeKeyword_Auto = () => {
    console.log('tryAgain_CreateNegativeKeyword_Auto()');
    console.log('RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_AUTO', this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_AUTO);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_AUTO < 3 ) {
      this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_AUTO++;  // Increment retry counter

      // Retry after 2 seconds
      setTimeout( () => {
        this.createNegativeKeyword_Auto();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createNegativeKeyword_Auto(), Continue with other task.');

      // Show error message to user
      this.setState({
        messageText: 'Can not create negative keywords this time, Please try again later.',
        showMessage: true,
      });

      // STEP 4: Create Product Ad (for automatic flow)
      // Note: We got error while creating negative keyword then also we can 
      // proceed to create product ad, so proceed now to create product ad now.
      this.createProdutAd_Auto();
    }

  }


  
  // STEP-4: Create product ad (using campaignId, groupId) within amazon. 
  // No need to save any info within db.
  createProdutAd_Auto = () => {
    console.log('createProdutAd_Auto()');

    // 1 - Read data from state
    const { profile_type, profile_id } = this.state.flowDataDb;
    const { createdAutoCampaignId, createdAutoAdGroupId } = this.state;

    // Debug 
    console.log('(Auto) createdAutoCampaignId:', createdAutoCampaignId);
    console.log('(Auto) createdAutoAdGroupId:', createdAutoAdGroupId);

    // 1A - IMPORTANT: If createdAutoCampaignId empty then return
    if ( createdAutoCampaignId === '' ) {
      const msg = 'Auto campaign Id not found, So cant create product ad.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingAutoCampaign: false,
        isCreatingAutoAdGroup: false,
      });

      // Call this function so it wil refresh data and ui.
      this.considerAutoCampaignCreated();

      return; // IMPORTANT
    }

    // 1B - IMPORTANT: If createdAutoAdGroupId empty then return.
    if ( createdAutoAdGroupId === '' ) {
      const msg = 'Auto adGroup Id not found, So cant create product ad.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingAutoCampaign: false,
        isCreatingAutoAdGroup: false,
      });

      // Call this function so it wil refresh data and ui.
      this.considerAutoCampaignCreated();

      return; // IMPORTANT
    }


    // 2 - Prepare data to create product Ads
    // We have to pass product ad creation related data as an array although
    // we need to create one product ad. Server side api expect array. 
    // Note: 
    //  - If seller profile then add 'sku' key and value (sku must for seller profile) 
    //  - If vendor profile then add 'asin' key value (asin must for vendor profile) 
    //  - Do not inclde both 'sku' and 'asin' otherwise api will give error.
    // 
    // e.g. productAdDataArray = [
    //   {
    //      "campaignId": 0,
    //      "adGroupId": 0,
    //      "sku": "string",      // Add this key:value for seller profile
    //      "asin": "string",     // Add this key:value for vendor profile
    //      "state": "enabled"
    //   }
    // ]
    const productAdDataArray = [];
    
    // 2A - If vendor profile then use asin list to create product ad data
    if ( profile_type === 'vendor') {
      const asinDict = this.getAsinDictFromProductAds(); // e.g { 'asin1': {state: 'enabled'}, 'asin2': {state: 'paused'} }
      const asinArray = Object.keys(asinDict); // Covert keys to array e.g. ['asin1', 'asin2']
      console.log('asinArray: ', asinArray); 

      asinArray.forEach( (item, index) => {
        const singleData = {
          campaignId: createdAutoCampaignId,
          adGroupId: createdAutoAdGroupId,
          state: asinDict[item].state,    // 'enabled' or 'paused'
          asin: item,                     // e.g. 'asin1'
        }
        productAdDataArray.push(singleData);
      });
    }

    // 2B - If seller profile then use seller sku to create product ad data
    if ( profile_type === 'seller') {
      const skuDict = this.getSkuDictFromProductAds();  // e.g { 'sku1': {state: 'enabled'}, 'sku2': {state: 'paused'} }
      const skuArray = Object.keys(skuDict);            // Convert keys to array e.g. ['sku1', 'sku2']
      console.log('skuArray: ', skuArray);

      skuArray.forEach( (item, index) => {
        const singleData = {
          campaignId: createdAutoCampaignId,
          adGroupId: createdAutoAdGroupId,
          state: skuDict[item].state,     // 'enabled' or 'paused'
          sku: item,                      // e.g. 'sku1'
        }
        productAdDataArray.push(singleData);
      });
    }

    // Debug
    console.log('(Auto) profile_id:', profile_id);
    console.log('(Auto) productAdDataArray - ' + profile_type + ' :', productAdDataArray);

    
    // 2C - IMPORTANT CHECK: 
    // If productAdDataArray empty then no need to call the api, 
    // just consider as the auto campaign created.
    if (productAdDataArray.length === 0) {
      this.considerAutoCampaignCreated();
      return; // Important
    }


    // 3 - Run success callback with dummy result
    // Note: Comment below dummy callback once whole flow ready.
    // Start: dummy callback
    if ( this.API_MODE_AUTO_FLOW === 'DUMMY' ) {
      console.log('DUMMY API Call Done - Create Product Ads (Auto Flow)');
      
      // 3A - Imitate success callback
      const dummyResult = { 
        status: 'success', 
        data: [ { adId: 501, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      this.spCreateProductAds_Auto_Success(dummyResult);
      return; // Important

      // // 3B - OR Imitate error callback
      // const error = { code: 'error', message: 'Product Ad create error' }
      // this.spCreateProductAds_Auto_Error(error);
      // return; // Important

    }
    // End: dummy callback

    // 3 - Call api to create product ad (automatic flow)
    spCreateProductAds(profile_id, productAdDataArray, this.spCreateProductAds_Auto_Success, this.spCreateProductAds_Auto_Error);
  }


  // Called if product ad created successfully (for automatic flow)
  // e.g. result = { 
  //   status: 'success', 
  //   data: [
  //     { 
  //        "adId": 0,
  //        "code": "string",
  //        "details": "string"
  //     } 
  //   ],
  //   error: null,
  // }
  spCreateProductAds_Auto_Success = (result) => {
    console.log('spCreateProductAds_Auto_Success() result:', result);

    if (result.status === 'success') {
      // We will not store product ad created result within db or state.
      // So within state we just update necessary variable if need.
      // this.setState({
      //   isProcessing: false,
      //   isCreatingAutoCampaign: false,
      // });

      // Consider auto campaign created fully
      this.considerAutoCampaignCreated();
    } 

    if (result.status === 'error') { 
      // Try again to create product ad (auto campaign)
      this.tryAgain_CreateProdutAd_Auto();
    }

  }
  
  // Called if any error while creating product ad (for automatic campaign)
  spCreateProductAds_Auto_Error = (error) => {
    console.log('spCreateProductAds_Auto_Error() error:', error);

    // Try again to create product ad (auto campaign)
    this.tryAgain_CreateProdutAd_Auto();
  }


  // Try again (Retry) to create negative keyword for auto campaign
  tryAgain_CreateProdutAd_Auto = () => {
    console.log('tryAgain_CreateProdutAd_Auto()');
    console.log('RETRY_COUNT_CREATE_PRODUCT_AD_AUTO', this.RETRY_COUNT_CREATE_PRODUCT_AD_AUTO);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_PRODUCT_AD_AUTO < 3 ) {
      this.RETRY_COUNT_CREATE_PRODUCT_AD_AUTO++;  // Increment retry count

      // Retry after 2 seconds
      setTimeout( () => {
        this.createProdutAd_Auto();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createProdutAd_Auto(), Continue with other task.');

      // Show error message to user
      this.setState({
        messageText: 'Can not create product ad this time, Please try again later.',
        showMessage: true,
      });
      
      // Consider auto campaign created
      this.considerAutoCampaignCreated();
    }
    
  }


  // Consider as the auto campaign created,
  // So we will refresh necessary data, so it will update ui element 
  // according to available data.
  considerAutoCampaignCreated = () => {
    console.log('considerAutoCampaignCreated()');

    // We will set isProcessing = false, and isCreatingAutoCampaign = false within 
    // fetchFlowDataDb() function so until data refresh happen it will show processing
    // state etc.

    // Fetch flow data from db after x seconds
    setTimeout(() => {
      this.fetchFlowDataDb();
    }, 1000);
  }

  //-----------------------------------------------------------------
  // End: Create Auto Campaign + Auto Ad Group etc. (Api Call)
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------  
  // Start: Common function used to create Auto Campaign + Manual Campaign
  //-----------------------------------------------------------------
   
  /*  BELOW FUNCTIONS NOT USED - WILL BE REMOVED LATER ON
  // This function wil return sku array from product ads.
   // It will also eliminate duplicate sku and create unique array of sku.
   getSellerSkuArrayFromProductAds = () => {

    // 1 - Grab product ad list (fetched via amazon api)
    const { productAdListApi } = this.state;

    // 2 - Create empty dictionary
    const skuDict = {}; 

    // 3 - Save sku to dictinary (It will eliminate duplicate sku)
    // e.g { 'sku1': 'sku1', 'sku2': 'sku2' }
    productAdListApi.forEach( (item, index) => {
      if (item.sku && item.sku !== '') {
        skuDict[item.sku] = item.sku;
      }
    });
    //console.log('skuDict:', skuDict);

    // 4 - Convert dict keys to array e.g. ['sku1', 'sku1']
    const skuArray = Object.keys(skuDict); 

    // 5 - Return sku array e.g. ['sku1', 'sku2']
    return skuArray;
  }
  */

  /*  BELOW FUNCTIONS NOT USED - WILL BE REMOVED LATER ON
  // This function wil return asin array from product ads.
  // It will also eliminate duplicate asin and create unique array of asin.  
  getAsinArrayFromProductAds = () => {

    // 1 - Grab product ad list (fetched via amazon api)
    const { productAdListApi } = this.state;

    // 2 - Create empty dictionary
    const asinDict = {}; 
    
    // 3 - Save asin to dictinary (It will eliminate duplicate asin)
    // e.g { 'asin1': 'asin1', 'asin2': 'asin2' }
    productAdListApi.forEach( (item, index) => {
      if (item.asin && item.asin !== '') {
        asinDict[item.asin] = item.asin;
      }
    });
    //console.log('asinDict:', asinDict);

    // 4 - Convert dict keys to array e.g. ['asin1', 'asin2']
    const asinArray = Object.keys(asinDict); 

    // 5 - Return asin array e.g. ['asin1', 'asin2'] 
    return asinArray; 
  }
  */

 
  // This function wil return asin dict from product ads.
  // Note: 
  // - eliminate duplicate asin and create unique asin list.
  // - If asin have all product ad in 'paused' state, then we will consider 'paused' state.
  // - If asin have atleast one ad in 'enabled' state, then consider 'enabled' state.
  // Note: 
  // - We have product ad list with 'enabled' or 'paused' state because when we fetch 
  // product ads via amazon api at that time we put filter to fetch 'enabled' and 'paused' ads.
  getAsinDictFromProductAds = () => {

    // 1 - Grab product ad list (fetched via amazon api)
    const { productAdListApi } = this.state;

    // 2 - Create empty dictionary
    const asinDict = {}; 
    
    // 3 - Save asin to dictinary (It will eliminate duplicate asin)
    // e.g asinDict = { 
    //  'asin1': { state: 'enabled' },
    //  'asin2': { state: 'paused' } 
    // }
    productAdListApi.forEach( (item, index) => {

      // start - Asin exist within data.
      if (item.asin && item.asin !== '') {

        // Key not exist within dict, so add it
        if ( asinDict[item.asin] === undefined ) {
          asinDict[item.asin] = { state: item.state };

        } else { // Key exist within dict
          // If new state is enabled then update state within dict, otherwise no need to update. 
          // If atleast one ad in enabled state then we have to consider it, Otherwise keep existing.
          const newState = item.state;
          if ( newState === 'enabled' ) { 
            asinDict[item.asin] = { state: item.state };
          }
        }

      } // end - Asin exist within data.

    });
    //console.log('asinDict:', asinDict);

    // 5 - Return asin dict 
    // e.g { 
    //  'asin1': { state: 'enabled' },
    //  'asin2': { state: 'paused' } 
    // }
    return asinDict; 
  }


  // This function wil return sku dict from product ads.
  // Note: 
  // - eliminate duplicate sku and create unique sku list.
  // - If sku have all product ad in 'paused' state, then we will consider 'paused' state.
  // - If sku have atleast one ad in 'enabled' state, then consider 'enabled' state.
  // Note: 
  // - We have product ad list with 'enabled' or 'paused' state because when we fetch 
  // product ads via amazon api at that time we put filter to fetch 'enabled' and 'paused' ads.
  getSkuDictFromProductAds = () => {

    // 1 - Grab product ad list (fetched via amazon api)
    const { productAdListApi } = this.state;

    // 2 - Create empty dictionary
    const skuDict = {}; 
    
    // 3 - Save sku to dictinary (It will eliminate duplicate sku)
    // e.g skuDict = { 
    //  'sku1': { state: 'enabled' },
    //  'sku2': { state: 'paused' } 
    // }
    productAdListApi.forEach( (item, index) => {

      // start - sku exist within data.
      if (item.sku && item.sku !== '') {
        
        // Key not exist within dict, so add it
        if ( skuDict[item.sku] === undefined ) { 
          skuDict[item.sku] = { state: item.state };

        } else { // Key exist within dict
          // If new state is enabled then update state within dict, otherwise no need to update. 
          // If atleast one ad in enabled state then we have to consider it, Otherwise keep existing.
          const newState = item.state;
          if ( newState === 'enabled' ) { 
            skuDict[item.sku] = { state: item.state };
          }
        }

      } // end - sku exist within data.

    });
    //console.log('skuDict:', skuDict);

    // 5 - Return asin dict 
    // e.g { 
    //  'sku1': { state: 'enabled' },
    //  'sku2': { state: 'paused' } 
    // }
    return skuDict; 
  }

  //-----------------------------------------------------------------
  // End: Common function used to create Auto Campaign + Manual Campaign
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Create Broad Ad Group and related entity (Api Call)
  //-----------------------------------------------------------------
  // Note: Below function used when Manual Campaign already exist and 
  // user need to create Broad Ad Group now (e.g. it was not created during 
  // flow create time) So we will use below function in this situation 
  // to create the single ad group and related keyword etc.

  // Create Broad Ad Group and related entity etc.
  // i.e. Create BroadAdGroup -> Keywords -> NegativeKeywords -> ProductAds
  // Note: Manual Campaign already created, so no need to create it.
  onClick_CreateBroadAdGroup = () => {
    console.log('onClick_CreateBroadAdGroup()');
  
    // 1 - Fetch necessary data from state etc.
    const { flowDocId } = this.props;
    const { manual_campaign_id, manual_broad_ad_group_id } = this.state.flowDataDb;
    const { broadAdGroupChecked, bidAmountBroad } = this.state;

    console.log('flowDocId:', flowDocId);
    console.log('manual_campaign_id:', manual_campaign_id);
    console.log('manual_broad_ad_group_id:', manual_broad_ad_group_id);
    console.log('broadAdGroupChecked:', broadAdGroupChecked);
    console.log('bidAmountBroad:', bidAmountBroad);


    // 2 - If data not valid then show message and return.
    if ( !manual_campaign_id || manual_campaign_id === '' ) { 
      const msg = 'Manual campaign not exist, So can not proceed.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( manual_broad_ad_group_id !== null ) {
      const msg = 'Broad Ad Group Already Exist, So can not proceed.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !bidAmountBroad || bidAmountBroad < 0.02 ) { 
      const msg = 'Broad Ad Group - Default Bid Amount Should be Minimum $0.02';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }

    // 3 - Reset variable before proceed to create broad adgroup related things
    this.RETRY_COUNT_CREATE_BROAD_AD_GROUP_MANUAL = 0;
    this.RETRY_COUNT_CREATE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL = 0;

    // 4 - Set processing on, also set creating Broad adGroup true, 
    // so it will show loader near the Broad adGroup box.
    // 
    // Note: We are using Broad adGroup create logic from manual campaign create flow.
    // So we need to set isCreatingManualCampaign=true because technically we skip 
    // manual campaign create step (because manual campaign already exist) and now 
    // we are running flow from Broad AdGroup creation step onwards. 
    // 
    // Important: Here we are not just creating Broad AdGroup, but we will also 
    // create Keyword, Negative Keywords, Product Ads under Broad AdGroup.
    //
    // Important: set createdManualCampaignId = manual_campaign_id because to 
    // create BroadAdGroup it will use value from state.createdManualCampaignId.
    this.setState({ 
      isProcessing: true,
      isCreatingBroadAdGroup: true,
      isCreatingManualCampaign: true,
      createdManualCampaignId: manual_campaign_id,
      manualBroadAdGroupCreated: false,     // Important - Consider as Not created
      manualPhraseAdGroupCreated: true,     // Consider as Created to run the flow
      manualExactAdGroupCreated: true,      // Consider as Created to run the flow
    }, () => {
      // IMPORTANT: We write this within setState() callaback parameter so we 
      // can make sure that once state variable set, then it will call 
      // this.createAdGroup_Broad() function, because it is using 
      // createdManualCampaignId variable while creating ad group.
      // 
      // 5 - Proceed to create Broad AdGroup onwards entity using manual_campaign_id
      // i.e. Create BroadAdGroup --> Keyword --> Negative Keywords --> Product Ads
      // Just trigger step to create Broad adGroup, so it will triger other step onwards
      // STEP-2: Create Broad Ad Group
      this.createAdGroup_Broad();
    }); 

  }

  // Cancel the Broad ad group creation step  
  onClick_CancelBroadAdGroup = () => {
    console.log('onClick_CancelBroadAdGroup()');

    // Update state
    this.setState({
      broadAdGroupChecked: false,
      isCreatingBroadAdGroup: false,
      isCreatingManualCampaign: false, 
    });
  }
  //-----------------------------------------------------------------
  // End: Create Broad Ad Group and related entity (Api Call)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Create Phrase Ad Group and related entity (Api Call)
  //-----------------------------------------------------------------
  // Note: Below function used when Manual Campaign already exist and 
  // user need to create Phrase Ad Group now (e.g. it was not created during 
  // flow create time) So we will use below function in this situation 
  // to create the single ad group and related keyword etc.

  // Create Phrase Ad Group and related entity etc.
  // i.e. Create PhraseAdGroup -> Keywords -> NegativeKeywords -> ProductAds
  // Note: Manual Campaign already created, so no need to create it.
  onClick_CreatePhraseAdGroup = () => {
    console.log('onClick_CreatePhraseAdGroup()');
    
    // 1 - Fetch necessary data from state etc.
    const { flowDocId } = this.props;
    const { manual_campaign_id, manual_phrase_ad_group_id } = this.state.flowDataDb;
    const { phraseAdGroupChecked, bidAmountPhrase } = this.state;
    
    console.log('flowDocId:', flowDocId);
    console.log('manual_campaign_id:', manual_campaign_id);
    console.log('manual_phrase_ad_group_id:', manual_phrase_ad_group_id);
    console.log('phraseAdGroupChecked:', phraseAdGroupChecked);
    console.log('bidAmountPhrase:', bidAmountPhrase);

    // 2 - If data not valid then show message and return.
    if ( !manual_campaign_id || manual_campaign_id === '' ) { 
      const msg = 'Manual campaign not exist, So can not proceed.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( manual_phrase_ad_group_id !== null ) {
      const msg = 'Phrase Ad Group Already Exist, So can not proceed.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !bidAmountPhrase || bidAmountPhrase < 0.02 ) { 
      const msg = 'Phrase Ad Group - Default Bid Amount Should be Minimum $0.02';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
 
    // 3 - Reset variable before proceed to create phrase adgroup related things
    this.RETRY_COUNT_CREATE_PHRASE_AD_GROUP_MANUAL = 0;
    this.RETRY_COUNT_CREATE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL = 0;

    // 4 - Set processing on, also set creating Phrase adGroup true, 
    // so it will show loader near the Phrase adGroup box.
    // 
    // Note: We are using Phrase adGroup create logic from manual campaign create flow.
    // So we need to set isCreatingManualCampaign=true because technically we skip 
    // manual campaign create step (because manual campaign already exist) and now 
    // we are running flow from Phrase AdGroup creation step onwards. 
    // 
    // Important: Here we are not just creating Phrase AdGroup, but we will also 
    // create Keyword, Negative Keywords, Product Ads under Phrase AdGroup.
    //
    // Important: set createdManualCampaignId = manual_campaign_id because to 
    // create PhraseAdGroup it will use value from state.createdManualCampaignId.
    this.setState({ 
      isProcessing: true,
      isCreatingPhraseAdGroup: true,
      isCreatingManualCampaign: true,
      createdManualCampaignId: manual_campaign_id,
      manualBroadAdGroupCreated: true,      // Consider as Created to run the flow
      manualPhraseAdGroupCreated: false,    // Important - Consider as Not created
      manualExactAdGroupCreated: true,      // Consider as Created to run the flow
    }, () => {
      // IMPORTANT: We write this within setState() callaback parameter so we 
      // can make sure that once state variable set, then it will call 
      // this.createAdGroup_Phrase() function, because it is using 
      // createdManualCampaignId variable while creating ad group.
      // 
      // 5 - Proceed to create Phrase AdGroup onwards entity using manual_campaign_id
      // i.e. Create PhraseAdGroup --> Keyword --> Negative Keywords --> Product Ads
      // Just trigger step to create Phrase adGroup, so it will triger other step onwards
      // STEP-2: Create Phrase Ad Group
      this.createAdGroup_Phrase();
    }); 

  }

  // Cancel the Phrase ad group creation step
  onClick_CancelPhraseAdGroup = () => {
    console.log('onClick_CancelPhraseAdGroup()');

    // Update state
    this.setState({
      phraseAdGroupChecked: false,
      isCreatingPhraseAdGroup: false,
      isCreatingManualCampaign: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Create Phrase Ad Group and related entity (Api Call)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Create Exact Ad Group and related entity (Api Call)
  //-----------------------------------------------------------------
  // Note: Below function used when Manual Campaign already exist and 
  // user need to create Exact Ad Group now (e.g. it was not created during 
  // flow create time) So we will use below function in this situation 
  // to create the single ad group and related keyword etc.

  // Create Exact ad group and related entity etc.
  // i.e. Create ExactAdGroup -> Keywords -> ProductAds
  // Note: Manual Campaign already created, so no need to create it.
  onClick_CreateExactAdGroup = () => {
    console.log('onClick_CreateExactAdGroup()');

    // 1 - Fetch necessary data from state etc.
    const { flowDocId } = this.props;
    const { manual_campaign_id, manual_exact_ad_group_id } = this.state.flowDataDb;
    const { exactAdGroupChecked, bidAmountExact } = this.state;

    console.log('flowDocId:', flowDocId);
    console.log('manual_campaign_id:', manual_campaign_id);
    console.log('manual_exact_ad_group_id:', manual_exact_ad_group_id);
    console.log('exactAdGroupChecked:', exactAdGroupChecked);
    console.log('bidAmountExact:', bidAmountExact);

    // 2 - If data not valid then show message and return.
    if ( !manual_campaign_id || manual_campaign_id === '' ) { 
      const msg = 'Manual campaign not exist, So can not proceed.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( manual_exact_ad_group_id !== null ) {
      const msg = 'Exact Ad Group Already Exist, So can not proceed.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !bidAmountExact || bidAmountExact < 0.02 ) { 
      const msg = 'Exact Ad Group - Default Bid Amount Should be Minimum $0.02';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
 
    // 3 - Reset variable before proceed to create exact adgroup related things
    this.RETRY_COUNT_CREATE_EXACT_AD_GROUP_MANUAL = 0;
    this.RETRY_COUNT_CREATE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL = 0;

    // 4 - Set processing on, also set creating Exact adGroup true, 
    // so it will show loader near the Exact adGroup box.
    // 
    // Note: We are using Exact adGroup create logic from manual campaign create flow.
    // So we need to set isCreatingManualCampaign=true because technically we skip 
    // manual campaign create step (because manual campaign already exist) and now 
    // we are running flow from Exact AdGroup creation step onwards. 
    // 
    // Important: Here we are not just creating Exact AdGroup, but we will also 
    // create Keyword, Product Ads under Exact AdGroup.
    //
    // Important: set createdManualCampaignId = manual_campaign_id because to 
    // create ExactAdGroup it will use value from state.createdManualCampaignId.
    this.setState({ 
      isProcessing: true,
      isCreatingExactAdGroup: true,
      isCreatingManualCampaign: true,
      createdManualCampaignId: manual_campaign_id,
      manualBroadAdGroupCreated: true,    // Consider as Created to run the flow
      manualPhraseAdGroupCreated: true,   // Consider as Created to run the flow
      manualExactAdGroupCreated: false,   // Important - Consider as Not created
    }, () => {
      // IMPORTANT: We write this within setState() callaback parameter so we 
      // can make sure that once state variable set, then it will call 
      // this.createAdGroup_Exact() function, because it is using 
      // createdManualCampaignId variable while creating ad group.
      // 
      // 5 - Proceed to create Exact AdGroup onwards entity using manual_campaign_id
      // i.e. Create ExactAdGroup --> Keyword --> Product Ads
      // Just trigger step to create Exact adGroup, so it will triger other step onwards
      // STEP-2: Create Exact Ad Group
      this.createAdGroup_Exact();
    }); 

  }

  // Cancel the Exact ad group creation step
  onClick_CancelExactAdGroup = () => {
    console.log('onClick_CancelExactAdGroup()');

    // Update state
    this.setState({
      exactAdGroupChecked: false,
      isCreatingExactAdGroup: false,
      isCreatingManualCampaign: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Create Exact Ad Group and related entity (Api Call)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Create Manual Campaign + Broad/Phrase/Exact Ad Group etc. (Api Call)
  //-----------------------------------------------------------------
  // NOTE: Below function used when Manual Campaign not created yet and 
  // user need to create either Broad/Phrase/Exact (or all) ad group under
  // the manual campaign.

  // Save Button tapped (Manual Campaign Create)
  // We have to create below entity:
  // i.e. Manual campaign, Broad/Phrase/Exact AdGroup, Keywords, Negative keywords, Product Ad for checked ad group etc.
  // 
  // For Manual flow we need to create below things in general
  // 1 - Create manual campaign.
  // 
  // 2 - Create Broad Ad Group (If Broad ad group not exist and Checked)
  // 3 - Create Phrase Ad Group (If Phrase ad group not exist and Checked)
  // 4 - Create Exact Ad Group (If Exact ad group not exist and Checked)
  // 
  // 5 - Create Keyword for Broad Ad Group (If Broad ad group not exist and Checked)
  // 6 - Create Keyword for Phrase Ad Group (If Phrase ad group not exist and Checked)
  // 7 - Create Keyword for Exact Ad Group (If Exact ad group not exist and Checked)
  // 
  // 8 - Create NegativeExact keyword for Broad Ad Group (If Broad ad group not exist and Checked)
  // 9 - Create NegativeExact keyword for Phrase Ad Group (If Phrase ad group not exist and Checked)
  //
  // 10 - Create Product Ad for Broad Ad Group. (If Broad ad group not exist and Checked)
  // 11 - Create Product Ad for Phrase Ad Group. (If Phrase ad group not exist and Checked)
  // 12 - Create Product Ad for Exact Ad Group. (If Exact ad group not exist and Checked)  
  onClick_CreateManualCampaign = () => {
    console.log('onClick_CreateManualCampaign()');
    
    // 1 - Fetch necessary data from state to create manual campaign + Broad/Phrase/Exact AdGroup etc.
    const { flowDocId } = this.props;
    const { campaignBudgetManual, 
            broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked, 
            bidAmountBroad, bidAmountPhrase, bidAmountExact, 
            startDate, endDate 
          } = this.state
    const { manual_campaign_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
          } = this.state.flowDataDb;


    // 2 - If data not valid then show message and return.
    if ( !flowDocId || flowDocId === '' ) { 
      const msg = 'flowDocId empty, So can not create manual campaign';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( manual_campaign_id !== null ) { // Manual campaign already exist
      const msg = 'Manual Campaign Already Exist, Can not create again.';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !campaignBudgetManual || campaignBudgetManual < 5) { 
      const msg = 'Campaign Daily Budget Should be Minimum $5';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !startDate || startDate === '') { 
      const msg = 'Please Select Campaign Start Date';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( !endDate || endDate === '') { 
      const msg = 'Please Select Campaign End Date';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }
    if ( manual_broad_ad_group_id === null && broadAdGroupChecked ) {
      if ( !bidAmountBroad || bidAmountBroad < 0.02 ) { 
        const msg = 'Broad AdGroup - Default Bid Should be Minimum $0.02';
        this.setState( { messageText: msg, showMessage: true });
        return;
      }  
    }
    if ( manual_phrase_ad_group_id === null && phraseAdGroupChecked ) {
      if ( !bidAmountPhrase || bidAmountPhrase < 0.02 ) { 
        const msg = 'Phrase AdGroup - Default Bid Should be Minimum $0.02';
        this.setState( { messageText: msg, showMessage: true });
        return;
      }
    }
    if ( manual_exact_ad_group_id === null && exactAdGroupChecked ) {
      if ( !bidAmountExact || bidAmountExact < 0.02 ) { 
        const msg = 'Exact AdGroup - Default Bid Should be Minimum $0.02';
        this.setState( { messageText: msg, showMessage: true });
        return;
      }
    }


    // // Start: Temporary show under construction message
    // this.setState({
    //   messageText: 'Manual Campaign - Under Construction', 
    //   showMessage: true, 
    // });
    // return;
    // // End: Temporary show under construction message


    // 3 - Set processing on, also set creating manual campaign true, 
    // so it will show loader near the manual campaign box.
    this.setState({
      isProcessing: true,
      isCreatingManualCampaign: true, 

      createdManualCampaignId: '', // We will create Manual Campaign and set id here during flow. 
      createdBroadAdGroupId: '',   // We will create Broad AdGroup and set id here durig flow.
      createdPhraseAdGroupId: '',  // We will create Phrase AdGroup and set id here during flow.
      createdExactAdGroupId: '',   // We will create Exact AdGroup and set id here during flow.

      manualBroadAdGroupCreated: false,  // Is Broad adgroup created during manual flow creation.
      manualPhraseAdGroupCreated: false, // Is Phrase adgroup created during manual flow creation.
      manualExactAdGroupCreated: false,  // Is Exact adgroup created during manual flow creation.
  
      manualKeywordCreated: false,          // Once (positive) keyword created for manual flow, we will set true
      manualNegativeKeywordCreated: false,  // Once Negative keyword created for manual flow, we will set true
      manualProductAdCreated: false,        // Once product ad created for manual flow, we will set it true
    }, () => {
      // 4 - Create Manual flow
      this.createManualFlow();
    });

  }

  // Cancel Button tapped (Manual Campaign Create)
  // No need to create any entity, just cancel the current operation.
  onClick_CancelManualCampaign = () => {
    console.log('onClick_CancelManualCampaign()');

    // Uncheck all checkbox (Broad, Phrase, Exact Ad Group)
    this.setState({
      broadAdGroupChecked: false, 
      phraseAdGroupChecked: false, 
      exactAdGroupChecked: false, 
    });
  } 

  
  // For Manual flow we need to create below things in general
  // STEP-1: Create manual campaign within amazon, and save manual_campaign_id within db.
  // 
  // STEP-2: 
  //  STEP-2.1: Create Broad Ad Group in amazon, and save manual_broad_ad_group_id within db.
  //  STEP-2.2: Create Phrase Ad Group in amazon, and save manual_phrase_ad_group_id within db.
  //  STEP-2.3: Create Exact Ad Group in amazon, and save manual_exact_ad_group_id within db.
  //   
  // STEP-3: 
  //  STEP-3.1A: Create Keywords for Broad match (using manual_broad_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //  STEP-3.1B: Create Negative Keywords for Negative Exact match (using manual_broad_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  // 
  //  STEP-3.2A: Create Keywords for Phrase match (using manual_phrase_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //  STEP-3.2B: Create Negative Keywords for Negative Exact match (using manual_phrase_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //
  //  STEP-3.3: Create Keywords for Exact match (using manual_exact_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  //
  // STEP-4: 
  //  STEP-4.1: Create Product Ad for Broad Ad Group. (using manual_broad_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  //  STEP-4.2: Create Product Ad for Phrase Ad Group. (using manual_phrase_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  //  STEP-4.3: Create Product Ad for Exact Ad Group. (using manual_exact_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  createManualFlow = () => {
    console.log('createManualFlow()');

    // 1 - Grab flowDocId from props, If flowDocId not exist then return.
    const { flowDocId } = this.props;
    if ( !flowDocId || flowDocId === '' ) { 
      const msg = 'flowDocId empty, So can not proceed to create manual campaign';
      this.setState( { messageText: msg, showMessage: true });
      return;
    }

    // 2 - Reset variable before proceed to create manual campaign flow.
    this.RETRY_COUNT_CREATE_BROAD_AD_GROUP_MANUAL = 0;
    this.RETRY_COUNT_CREATE_PHRASE_AD_GROUP_MANUAL = 0;
    this.RETRY_COUNT_CREATE_EXACT_AD_GROUP_MANUAL = 0;
    this.RETRY_COUNT_CREATE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL = 0;
    this.RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL = 0;

    // 3 - 
    // STEP-1: Create manual campaign
    this.createCampaign_Manual();
  }


  // STEP-1: Create manual campaign within amazon, and save manual_campaign_id within db.  
  createCampaign_Manual = () => {
    console.log('createCampaign_Manual()');

    // 1 - Grab data from state
    const { profile_id, flow_name } = this.state.flowDataDb;
    const { campaignBudgetManual, startDate, endDate } = this.state;

    // 2 - Convert data to appropriate format
    const campaignName = flow_name + ' Manual Campaign';
    const startDateFinal = startDate.replace(/-/g, "");     // e.g. 20201021
    const endDateFinal = endDate.replace(/-/g, "");         // e.g. 20201028
    const dailyBudget = parseFloat(campaignBudgetManual);

    // 3 - Prepare data to create campaign
    // Note: We can pass either premiumBidAdjustment = false or bidding={} but not both.
    // So if premiumBidAdjustment is true then set bidding key:value, otherwise set 
    // premiumBidAdjustment key:value
    const campaignData1 = {
      name: campaignName,
      campaignType: 'sponsoredProducts', 
      targetingType: 'manual', 
      state: 'enabled', 
      dailyBudget: dailyBudget, 
      startDate: startDateFinal, 
      endDate: endDateFinal, 
      premiumBidAdjustment: false, 
    }
    const campaignDataArray = [ campaignData1 ];
    console.log('(Manual) campaignDataArray:', campaignDataArray);

    // 4 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_MANUAL_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Manual Campaign');

      // 4A - Run success callback
      const dummyResult = { 
        status: 'success', 
        data: [ {campaignId: 1001, code: 'SUCCESS', description: ''} ],
        error: null,
      }
      this.spCreateCampaigns_Manual_Success(dummyResult);
      return; // Important

      // // 4B - OR Imitate error callback
      // const error = { code: 'error', message: 'Manual campaign create error' }
      // this.spCreateCampaigns_Manual_Error(error);
      // return; // Important
    }
    // End: dummy callback
     
    // 4 - Call amaozon api to create manual campaign
    spCreateCampaigns(profile_id, campaignDataArray, this.spCreateCampaigns_Manual_Success, this.spCreateCampaigns_Manual_Error);
  }

  // Called if manual campaign created successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //       "campaignId": 0,
  //       "code": "SUCCESS",
  //       "description": "string"
  //     }  
  //   ],
  //   error: null
  // }
  spCreateCampaigns_Manual_Success = async (result) => {
    console.log('spCreateCampaigns_Manual_Success() result:', result);

    // If status success then save created campaign id within state and db
    // status = 'success' returned by server side endpoint.
    // if any error while processing request on server side it will return status = 'error'    
    if ( result.status === 'success' ) {
      const { code, campaignId, description, details } = result.data[0];
      const { flowDocId } = this.props;

      if (code === 'SUCCESS') { // Returned by amazon if manual campaign created
        // Save campaign id within db
        await db.collection('sponsored_product_ad').doc(flowDocId).update({manual_campaign_id: campaignId});
        
        // Save created manual campaign id within state
        this.setState({ 
          createdManualCampaignId: campaignId,
        }, () => {
          // Important: Must write within setState() callback, so createdManualCampaignId 
          // set within state and then it call this.createAdGroup_Manual() function.
          // STEP-2: Create ad group related to manual campagin
          this.createAdGroup_Manual();
        });

      } else {
        this.showManualCampaignCreateError();
      }
    }
    
    // If any error while calling amazon api, our server side code will 
    // return status = 'error'
    if ( result.status === 'error' ) {
      this.showManualCampaignCreateError();
    }
  }

  // Called if any error while creating manual campaign
  spCreateCampaigns_Manual_Error = (error) => {
    console.log('spCreateCampaigns_Manual_Error() error:', error);
    this.showManualCampaignCreateError();
  } 

  showManualCampaignCreateError = () => {
    const msg = 'Can not create manual campaign this time, Please try again later.';
    this.setState({
      messageText: msg,
      showMessage: true,
      isProcessing: false,
      isCreatingManualCampaign: false,
    }, () => {
      this.considerManualCampaignCreated();
    });
  }




  // STEP-2: 
  //  STEP-2.1: Create Broad Ad Group in amazon, and save manual_broad_ad_group_id within db.
  //  STEP-2.2: Create Phrase Ad Group in amazon, and save manual_phrase_ad_group_id within db.
  //  STEP-2.3: Create Exact Ad Group in amazon, and save manual_exact_ad_group_id within db.
  createAdGroup_Manual = () => {
    console.log('createAdGroup_Manual()');

    // 1 - Read data from the state
    const { createdManualCampaignId } = this.state;
    const { broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked } = this.state;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // Debug
    console.log('createdManualCampaignId:', createdManualCampaignId);

    // 1A - IMPORTANT
    // If manual campaign id empty/not exist then return, we can not create any ad group without it.
    if ( !createdManualCampaignId || createdManualCampaignId === '' ) {
      const msg = 'createdManualCampaignId empty, So can not create any adGroup. Please try again.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingManualCampaign: false,
      });

      // Call this function so it wil refresh data and ui.
      this.considerManualCampaignCreated();

      return; // IMPORTANT
    }


    // 2 - If Broad ad group not exist and checked then create Ad group for it.
    if ( manual_broad_ad_group_id === null && broadAdGroupChecked) { 
      this.createAdGroup_Broad();
    } else { // Consider as Broad ad group created during this manual flow creation
      this.setState({ 
        manualBroadAdGroupCreated: true 
      });
    }

    // 3 - If Phrase ad group not exist and checked then create Ad group for it.
    if ( manual_phrase_ad_group_id === null && phraseAdGroupChecked) { 
      this.createAdGroup_Phrase();
    } else { // Consider as Phrase ad group created during this manual flow creation
      this.setState({
        manualPhraseAdGroupCreated: true
      });
    }

    // 4 - If Exact ad group not exist and checked then create Ad group for it.
    if ( manual_exact_ad_group_id === null && exactAdGroupChecked) { 
      this.createAdGroup_Exact();
    } else { // Consider as Exact ad group created during this manual flow creation.
      this.setState({
        manualExactAdGroupCreated: true
      });
    }

  }


  // STEP-2.1: Create Broad Ad Group in amazon, and save manual_broad_ad_group_id within db.
  createAdGroup_Broad = () => {
    console.log('createAdGroup_Broad()');

    // 1 - Grab data from state
    const { profile_id, flow_name, manual_broad_ad_group_id } = this.state.flowDataDb; 
    const { createdManualCampaignId, bidAmountBroad } = this.state;
    const adGroupName = flow_name + ' Manual Broad Ad Group';

    // Debug
    console.log('(Manual Broad) profile_id:', profile_id);
    console.log('(Manual Broad) createdManualCampaignId:', createdManualCampaignId);

    // 1A - IMPORTANT
    // If manual campaign id empty/not exist then return, we can not create ad group without it.
    if ( !createdManualCampaignId || createdManualCampaignId === '' ) {
      const msg = 'Manual campaign id empty, So can not create Broad ad group.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingManualCampaign: false,
      });

      return; // IMPORTANT
    }

    // 2 - Prepare data to create Broad Ad Group (manual flow)
    // We have to pass ad group create related data as an array although we have to  
    // create one ad group at present. Server side api expect array object.
    // e.g. adGroupDataArray = [
    //   {
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ]      
    const adGroupData1 = {
      name: adGroupName, 
      campaignId: createdManualCampaignId, 
      defaultBid: parseFloat(bidAmountBroad), 
      state: 'enabled', 
    }
    const adGroupDataArray = [ adGroupData1 ];
    console.log('(Manual Broad) adGroupDataArray:', adGroupDataArray);

    // 3 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_MANUAL_FLOW === 'DUMMY' ) {
      console.log('DUMMY API Call Done - Create Broad AdGroup');

      // 3A - Imitate Success callback
      const dummyResult = { 
        status: 'success', 
        data: [ { adGroupId: 2001, code: 'SUCCESS', details: '' } ],
        error: null,
      }
      this.spCreateAdGroups_Broad_Success(dummyResult);
      return; // Important

      // // 3B - Imitate Error callback
      // const error = { code: 'error', message: 'Broad AdGroup Create Error' };
      // this.spCreateAdGroups_Broad_Error(error);
      // return; // Important
    }
    // End: dummy callback

    // 3 - Call amaozon api to create Broad Ad group (for manual flow)
    spCreateAdGroups(profile_id, adGroupDataArray, this.spCreateAdGroups_Broad_Success, this.spCreateAdGroups_Broad_Error);
  }

  // Called when Broad Ad Group created (for manual campaign)
  spCreateAdGroups_Broad_Success = async (result) => {
    console.log('spCreateAdGroups_Broad_Success() result:', result);

    // If status success then save created adGroupId within state and db.
    // status = 'success' returned by server side endpoint.
    // if any error while processing request on server side it will return status = 'error'
    if ( result.status === 'success' ) {
      const { code, adGroupId, details, description } = result.data[0];
      const { flowDocId } = this.props;

      // Debug
      console.log('createdBroadAdGroupId:', adGroupId);

      if (code === 'SUCCESS') { // Returned by amazon if ad group created
        // Save created ad group id within db as manual_broad_ad_group_id
        await db.collection('sponsored_product_ad').doc(flowDocId).update({manual_broad_ad_group_id: adGroupId});
        
        // Save adGroupId within state for manualBroadAdGroupId (manual flow)
        this.setState({ 
          createdBroadAdGroupId: adGroupId,
          manualBroadAdGroupCreated: true,
        }, () => {
          // Important: Must write in setState() callback so we can make sure that
          // createdBroadAdGroupId, and manualBroadAdGroupCreated variable set within state 
          // and then it will calll below function.
          // 
          // Check that all type of ad group created (Broad, Phrase, Exact) for manual flow or not. 
          // If all type of ad group created for manual flow then it will proceed to create keyword, 
          // negative keyword, and product ad related to manual flow.
          this.checkAllTypeAdGroupCreated_For_Manual();
        });
      } else {
        this.tryAgain_CreateBroadAdGroup();  // Try again to create broad ad group
      }
    }

    if (result.status === 'error') {
      this.tryAgain_CreateBroadAdGroup();   // Try again to create broad ad group
    }
  } 

  // Called if any error while creating Broad Ad Group (for manual campaign)
  spCreateAdGroups_Broad_Error = (error) => {
    console.log('spCreateAdGroups_Broad_Error() error:', error);
    this.tryAgain_CreateBroadAdGroup();   // Try again to create broad ad group
  }

  // Try again (Retry) to create broad ad group (manual campaign)
  tryAgain_CreateBroadAdGroup = () => {
    console.log('tryAgain_CreateBroadAdGroup()');
    console.log('RETRY_COUNT_CREATE_BROAD_AD_GROUP_MANUAL', this.RETRY_COUNT_CREATE_BROAD_AD_GROUP_MANUAL);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_BROAD_AD_GROUP_MANUAL < 3 ) {
      this.RETRY_COUNT_CREATE_BROAD_AD_GROUP_MANUAL++;  // Increment retry counter

      // Retry after 2 seconds
      setTimeout( () => {
        this.createAdGroup_Broad();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createAdGroup_Broad(), Continue with other task.');
      this.showBroadAdGroupCreateError_And_Proceed();
    }
  }

  // This function will show error while create adgroup and proceed next step
  showBroadAdGroupCreateError_And_Proceed = () => {
    console.log('showBroadAdGroupCreateError_And_Proceed()');

    const msg = 'Can not create Broad Ad Group this time, please try again later';
    this.setState({
      messageText: msg, 
      showMessage: true, 
      createdBroadAdGroupId: '',
      manualBroadAdGroupCreated: true,
    }, () => {
      this.checkAllTypeAdGroupCreated_For_Manual();
    });
  }



  // STEP-2.2: Create Phrase Ad Group in amazon, and save manual_phrase_ad_group_id within db.
  createAdGroup_Phrase = () => {
    console.log('createAdGroup_Phrase()');
  
    // 1 - Grab data from state
    const { profile_id, flow_name } = this.state.flowDataDb; 
    const { createdManualCampaignId, bidAmountPhrase } = this.state;
    const adGroupName = flow_name + ' Manual Phrase Ad Group';

    // Debug
    console.log('(Manual Phrase) profile_id:', profile_id);
    console.log('(Manual Phrase) createdManualCampaignId:', createdManualCampaignId);

    // 1A - IMPORTANT
    // If manual campaign id empty/not exist then return, we can not create ad group without it.
    if ( !createdManualCampaignId || createdManualCampaignId === '' ) {
      const msg = 'Manual campaign id empty, So can not create Phrase ad group.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingManualCampaign: false,
      });

      return; // IMPORTANT
    }

    // 2 - Prepare data to create Phrase Ad Group (manual flow)
    // We have to pass ad group create related data as an array although we have to  
    // create one ad group at present. Server side api expect array object.
    // e.g. adGroupDataArray = [
    //   {
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ]      
    const adGroupData1 = {
      name: adGroupName, 
      campaignId: createdManualCampaignId, 
      defaultBid: parseFloat(bidAmountPhrase), 
      state: 'enabled', 
    }
    const adGroupDataArray = [ adGroupData1 ];
    console.log('(Manual Phrase) adGroupDataArray:', adGroupDataArray);

    // 3 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_MANUAL_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Phrase AdGroup');

      // 3A - Imitate success callback
      const dummyResult = { 
        status: 'success', 
        data: [ { adGroupId: 2002, code: 'SUCCESS', details: '' } ],
        error: null,
      }
      this.spCreateAdGroups_Phrase_Success(dummyResult);
      return; // Important

      // // 3B - Imitate Error callback
      // const error = { code: 'error', message: 'Phrase AdGroup Create Error' };
      // this.spCreateAdGroups_Phrase_Error(error);
      // return; // Important
    }
    // End: dummy callback
    
    // 3 - Call amaozon api to create Phrase Ad group (for manual flow)
    spCreateAdGroups(profile_id, adGroupDataArray, this.spCreateAdGroups_Phrase_Success, this.spCreateAdGroups_Phrase_Error);
  }

  // Called when Phrase Ad Group created (for manual campaign)
  spCreateAdGroups_Phrase_Success = async (result) => { 
    console.log('spCreateAdGroups_Phrase_Success() result:', result);

    // If status success then save created adGroupId within state and db.
    // status = 'success' returned by server side endpoint.
    // if any error while processing request on server side it will return status = 'error'
    if ( result.status === 'success' ) {
      const { code, adGroupId, details, description } = result.data[0];
      const { flowDocId } = this.props;

      // Debug
      console.log('createdPhraseAdGroupId:', adGroupId);

      if (code === 'SUCCESS') { // Returned by amazon if ad group created
        // Save created ad group id within db as manual_phrase_ad_group_id
        await db.collection('sponsored_product_ad').doc(flowDocId).update({manual_phrase_ad_group_id: adGroupId});
        
        // Save adGroupId within state for manualPhraseAdGroupId (manual flow)
        this.setState({ 
          createdPhraseAdGroupId: adGroupId, 
          manualPhraseAdGroupCreated: true, 
        }, () => {
          // Important: Must write in setState() callback so we can make sure that
          // createdPhraseAdGroupId, and manualPhraseAdGroupCreated variable set within 
          // state and then it will call below function.
          // 
          // Check that all type of ad group created (Broad, Phrase, Exact) for manual flow or not. 
          // If all type of ad group created for manual flow then it will proceed to create keyword, 
          // negative keyword, and product ad related to manual flow.
          this.checkAllTypeAdGroupCreated_For_Manual(); 
        });
      } else {
        this.tryAgain_CreatePhraseAdGroup(); // Try again to create phrase ad group
      }
    }

    if (result.status === 'error') {
      this.tryAgain_CreatePhraseAdGroup(); // Try again to create broad ad group
    }
  } 

  // Called if any error while creating Phrase Ad Group (for manual campaign)
  spCreateAdGroups_Phrase_Error = (error) => {
    console.log('spCreateAdGroups_Phrase_Error() error:', error);
    this.tryAgain_CreatePhraseAdGroup(); // Try again to create phrase ad group
  } 

  // Try again (Retry) to create Phrase ad group (manual flow)
  tryAgain_CreatePhraseAdGroup = () => {
    console.log('tryAgain_CreatePhraseAdGroup()');
    console.log('RETRY_COUNT_CREATE_PHRASE_AD_GROUP_MANUAL', this.RETRY_COUNT_CREATE_PHRASE_AD_GROUP_MANUAL);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_PHRASE_AD_GROUP_MANUAL < 3 ) {
      this.RETRY_COUNT_CREATE_PHRASE_AD_GROUP_MANUAL++;  // Increment retry counter

      // Retry after 2 seconds
      setTimeout( () => {
        this.createAdGroup_Phrase();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createAdGroup_Phrase(), Continue with other task.');
      this.showPhraseAdGroupCreateError_And_Proceed();
    }
  }

  // This function will show error while create adgroup and proceed next step
  showPhraseAdGroupCreateError_And_Proceed = () => {
    console.log('showPhraseAdGroupCreateError_And_Proceed()');

    const msg = 'Can not create Phrase Ad Group this time, please try again later';
    this.setState({
      messageText: msg, 
      showMessage: true, 
      createdPhraseAdGroupId: '',
      manualPhraseAdGroupCreated: true,
    }, () => {
      this.checkAllTypeAdGroupCreated_For_Manual();
    });
  }



  // STEP-2.3: Create exact Ad Group in amazon, and save manual_exact_ad_group_id within db.
  createAdGroup_Exact = () => {
    console.log('createAdGroup_Exact()');
  
    // 1 - Grab data from state
    const { profile_id, flow_name } = this.state.flowDataDb;
    const { createdManualCampaignId, bidAmountExact } = this.state;
    const adGroupName = flow_name + ' Manual Exact Ad Group';

    // 1A - IMPORTANT
    // If manual campaign id empty/not exist then return, we can not create ad group without it.
    if ( !createdManualCampaignId || createdManualCampaignId === '' ) {
      const msg = 'Manual campaign id empty, So can not create Phrase ad group.';
      this.setState({
        messageText: msg,
        showMessage: true,
        isProcessing: false,
        isCreatingManualCampaign: false,
      });

      return; // IMPORTANT
    }

    // Debug
    console.log('(Manual Exact) profile_id:', profile_id);
    console.log('(Manual Exact) createdManualCampaignId:', createdManualCampaignId);

    // 2 - Prepare data to create Exact Ad Group (manual flow)
    // We have to pass ad group create related data as an array although we have to  
    // create one ad group at present. Server side api expect array object.
    // e.g. adGroupDataArray = [
    //   {
    //     "name": "string",
    //     "campaignId": 0,
    //     "defaultBid": 0,
    //     "state": "enabled"
    //   }
    // ]      
    const adGroupData1 = {
      name: adGroupName, 
      campaignId: createdManualCampaignId, 
      defaultBid: parseFloat(bidAmountExact), 
      state: 'enabled', 
    }
    const adGroupDataArray = [ adGroupData1 ];
    console.log('(Manual Exact) adGroupDataArray:', adGroupDataArray);

    // 3 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_MANUAL_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Exact AdGroup');

      // 3A - Imitate success callback
      const dummyResult = { 
        status: 'success', 
        data: [ { adGroupId: 2003, code: 'SUCCESS', details: '' } ],
        error: null,
      }
      this.spCreateAdGroups_Exact_Success(dummyResult);
      return; // Important

      // // 3B - Imitate Error callback
      // const error = { code: 'error', message: 'Exact AdGroup Create Error' };
      // this.spCreateAdGroups_Exact_Error(error);
      // return; // Important
    } 
    // End: dummy callback

    // 4 - Call amaozon api to create Exact Ad group (for manual flow)
    spCreateAdGroups(profile_id, adGroupDataArray, this.spCreateAdGroups_Exact_Success, this.spCreateAdGroups_Exact_Error);
  }


  // Called when Exact Ad Group created (for manual campaign)
  spCreateAdGroups_Exact_Success = async (result) => {
    console.log('spCreateAdGroups_Exact_Success() result:', result);

    // If status success then save created adGroupId within state and db.
    // status = 'success' returned by server side endpoint.
    // if any error while processing request on server side it will return status = 'error'
    if ( result.status === 'success' ) {
      const { code, adGroupId, details, description } = result.data[0];
      const { flowDocId } = this.props;

      // Debug
      console.log('createdExactAdGroupId:', adGroupId);

      if (code === 'SUCCESS') { // Returned by amazon if ad group created
        // Save created ad group id within db as manual_exact_ad_group_id
        await db.collection('sponsored_product_ad').doc(flowDocId).update({manual_exact_ad_group_id: adGroupId});
        
        // Save adGroupId within state for manualExactAdGroupId (manual flow)
        this.setState({
          createdExactAdGroupId: adGroupId, 
          manualExactAdGroupCreated: true, 
        }, () => { 
          // Important: Must write in setState() callback so we can make sure that
          // createdPhraseAdGroupId, and manualPhraseAdGroupCreated variable set within 
          // state and then it will call below function.
          //
          // Check that all type of ad group created (Broad, Phrase, Exact) for manual flow or not. 
          // If all type of ad group created for manual flow then it will proceed to create keyword, 
          // negative keyword, and product ad related to manual flow.
          this.checkAllTypeAdGroupCreated_For_Manual(); 
        });
      } else {
        this.tryAgain_CreateExactAdGroup(); // Try again to create Exact ad group
      }
    }

    if (result.status === 'error') {
      this.tryAgain_CreateExactAdGroup(); // Try again to create Exact ad group
    }
  } 

  // Called if any error while creating Exact Ad Group (for manual campaign)
  spCreateAdGroups_Exact_Error = (error) => {
    console.log('spCreateAdGroups_Exact_Error() error:', error);
    this.tryAgain_CreateExactAdGroup(); // Try again to create Exact ad group
  } 

  // Try again (Retry) to create Exact ad group (manual flow)
  tryAgain_CreateExactAdGroup = () => {
    console.log('tryAgain_CreateExactAdGroup()');
    console.log('RETRY_COUNT_CREATE_EXACT_AD_GROUP_MANUAL', this.RETRY_COUNT_CREATE_EXACT_AD_GROUP_MANUAL);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_EXACT_AD_GROUP_MANUAL < 3 ) {
      this.RETRY_COUNT_CREATE_EXACT_AD_GROUP_MANUAL++;  // Increment retry counter

      // Retry after 2 seconds
      setTimeout( () => {
        this.createAdGroup_Exact();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createAdGroup_Exact(), Continue with other task.');
      this.showExactAdGroupCreateError_And_Proceed();
    }
  }

  // This function will show error while create adgroup and proceed next step
  showExactAdGroupCreateError_And_Proceed = () => {
    console.log('showExactAdGroupCreateError_And_Proceed()');

    const msg = 'Can not create Exact Ad Group this time, please try again later';
    this.setState({
      messageText: msg, 
      showMessage: true, 
      createdExactAdGroupId: '',
      manualExactAdGroupCreated: true,
    }, () => {
      this.checkAllTypeAdGroupCreated_For_Manual();
    });
  }



  // Check that all type of ad group created (Broad, Phrase, Exact) for manual flow or not. 
  // If all type of ad group created for manual flow then it will proceed to create keyword, 
  // negative keyword, and product ad related to manual flow.
  checkAllTypeAdGroupCreated_For_Manual = () => { 
    console.log('checkAllTypeAdGroupCreated_For_Manual()');

    const { manualBroadAdGroupCreated, manualPhraseAdGroupCreated, manualExactAdGroupCreated } = this.state;
    console.log('manualBroadAdGroupCreated:', manualBroadAdGroupCreated);
    console.log('manualPhraseAdGroupCreated:', manualPhraseAdGroupCreated);
    console.log('manualExactAdGroupCreated:', manualExactAdGroupCreated);

    // If all type of ad group created for manual flow then create Keyword, Negative keyword, and Product Ads.
    // Note: It may be possible that some error occur while creating some adgroup 
    // among BrodAdGroup, PhraseAdGroup, ExactAdGroup so within that error handler we set 
    // that adgroup created as true, so we can proceed next step from below condition.
    if ( manualBroadAdGroupCreated && 
          manualPhraseAdGroupCreated && manualExactAdGroupCreated ) { 
  
      // Create keyword for Broad, Phrase, and Exact Ad Group (manual flow)
      this.createKeyword_Broad_Phrase_Exact();

      // Create Negative keyword for Broad, Phrase Ad Group (manual flow)
      this.createNegativeKeyword_Broad_Phrase();

      // Create Product Ad for Broad, Phrase, and Exact Ad Group (Manual Flow)
      this.createProductAd_Broad_Phrase_Exact();
    }
  }



  // Create keyword for Broad, Phrase, and Exact Ad Group (manual campaign)
  // STEP-3.1A: Create Keywords for Broad match (using manual_broad_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  // STEP-3.2A: Create Keywords for Phrase match (using manual_phrase_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]  
  // STEP-3.3: Create Keywords for Exact match (using manual_exact_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]  
  createKeyword_Broad_Phrase_Exact = () => { 
    console.log('createKeyword_Broad_Phrase_Exact()');

    // 1 - Read data from state
    const { profile_id, 
        manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id
    } = this.state.flowDataDb;

    const { createdManualCampaignId, 
      createdBroadAdGroupId, createdPhraseAdGroupId, createdExactAdGroupId, 
      bidAmountBroad, bidAmountPhrase, bidAmountExact, 
      broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked, 
      hintKeywordBroad, hintKeywordPhrase, hintKeywordExact, 
    } = this.state;

    // Debug 
    // console.log('createdManualCampaignId:', createdManualCampaignId);
    // console.log('createdBroadAdGroupId:', createdBroadAdGroupId);
    // console.log('createdPhraseAdGroupId:', createdPhraseAdGroupId);
    // console.log('createdExactAdGroupId:', createdExactAdGroupId);

    // 1A - IMPORTANT
    // If manual campaign id empty/not exist then return, we can not create any ad group without it.
    if ( !createdManualCampaignId || createdManualCampaignId === '' ) {
      const msg = 'createdManualCampaignId empty, So can not create keyword.';
      this.setState({
        messageText: msg,
        showMessage: true,
        //isProcessing: false,
        //isCreatingManualCampaign: false,
      });

      return; // IMPORTANT
    }


    // 2 - Prepare data to create keyword
    // We have to pass data as an array either create one or more 
    // keyword, because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "exact",
    //     "bid": 0
    //   }
    // ] 
    const keywordDataArray = []; 
        
    // 2A - If Broad Ad Group checked then create keyword for that
    // STEP-3.1A: Create Keywords for Broad match (using createdManualCampaignId and createdBroadAdGroupId) 
    if ( manual_broad_ad_group_id === null && broadAdGroupChecked && createdBroadAdGroupId !== '' ) { 
      console.log('Adding keyword for Broad AdGroup');

      // Prepare array for broad keyword
      const selectedKeywordsBroad = hintKeywordBroad.filter((item, index) => { return true; }); // Copy array to new variable
      console.log('selectedKeywordsBroad:', selectedKeywordsBroad); // e.g. ['keyword1', 'keyword2']
      
      selectedKeywordsBroad.forEach((item, index) => {
        const keywordData = {
          campaignId: createdManualCampaignId,
          adGroupId: createdBroadAdGroupId,
          state: 'enabled',
          keywordText: item,
          matchType: 'broad',
          bid: parseFloat(bidAmountBroad)
        } 
        keywordDataArray.push(keywordData);
      }); 
    } else {
      console.log('Not Adding keyword for Broad AdGroup');
    }

    // 2B - If Phrase Ad Group checked then create keyword for that
    // STEP-3.2A: Create Keywords for Phrase match (using createdManualCampaignId and createdPhraseAdGroupId) 
    if ( manual_phrase_ad_group_id === null && phraseAdGroupChecked && createdPhraseAdGroupId !== '' ) { 
      console.log('Adding keyword for Phrase AdGroup');

      // Prepare array for phrase keyword
      const selectedKeywordsPhrase = hintKeywordPhrase.filter((item, index) => { return true; }); // Copy array to new variable      
      console.log('selectedKeywordsPhrase:', selectedKeywordsPhrase); // e.g. ['keyword1', 'keyword2']
      
      selectedKeywordsPhrase.forEach((item, index) => {
        const keywordData = {
          campaignId: createdManualCampaignId,
          adGroupId: createdPhraseAdGroupId,
          state: 'enabled',
          keywordText: item,
          matchType: 'phrase',
          bid: parseFloat(bidAmountPhrase)
        } 
        keywordDataArray.push(keywordData);
      }); 
    } else {
      console.log('Not Adding keyword for Phrase AdGroup');
    }

    // 2C - If Exact Ad Group checked then create keyword for that
    // STEP-3.3: Create Keywords for exact match (using createdManualCampaignId and createdExactAdGroupId) 
    if ( manual_exact_ad_group_id === null && exactAdGroupChecked && createdExactAdGroupId !== '' ) { 
      console.log('Adding keyword for exact AdGroup');

      // Prepare array for exact keyword
      const selectedKeywordsExact = hintKeywordExact.filter((item, index) => { return true; }); // Copy array to new variable
      console.log('selectedKeywordsExact:', selectedKeywordsExact); // e.g. ['keyword1', 'keyword2']
      
      selectedKeywordsExact.forEach((item, index) => { 
        const keywordData = {
          campaignId: createdManualCampaignId,
          adGroupId: createdExactAdGroupId,
          state: 'enabled',
          keywordText: item,
          matchType: 'exact',
          bid: parseFloat(bidAmountExact)
        } 
        keywordDataArray.push(keywordData); 
      }); 
    } else {
      console.log('Not Adding keyword for Exact AdGroup');
    }

    // Debug
    console.log('(Manual - Broad/Phrase/Exact) keywordDataArray:', keywordDataArray);

    // Important: If keyword list empty then no need to all api, so consider as 
    // the keyword created and proceed with next task.
    if (keywordDataArray.length === 0) {
      console.log('(Manual) Keyword list empty, so no need to call api.');

      this.setState({
        manualKeywordCreated: true, 
      }, () => {
        // Check all task done for manual flow or not
        this.checkAllTaskDone_Manual_Flow();
      });

      return; // Important
    }


    // 3 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_MANUAL_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Keyword (manual flow)');

      // 3A - Imitate manual flow
      const dummyResult = { 
        status: 'success', 
        data: [ { keywordId: 3001, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      this.spCreateKeywords_Manual_Success(dummyResult);
      return; // Important

      // // 3B - OR Imitate error callback
      // const error = { code: 'error', message: 'Keyword create error' }
      // this.spCreateKeywords_Manual_Error(error);
      // return; // Important
    }
    // End: dummy callback

    // 4 - Call api to create keywords for manual flow
    spCreateKeywords(profile_id, keywordDataArray, this.spCreateKeywords_Manual_Success, this.spCreateKeywords_Manual_Error);
  }

  // Called when keyword created successfully (for manual campaign)
  // e.g. jsonBody: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //          "description": ""
  //        }], 
  //        error: null 
  //      }
  spCreateKeywords_Manual_Success = (result) => {
    console.log('spCreateKeywords_Manual_Success() result:', result);

    if (result.status === 'success') {
      // We will not store keyword created result within db or state.
      // So within state we just update necessary variable.

      this.setState({
        manualKeywordCreated: true, 
      }, () => {
        // Check all task done for manual flow or not
        this.checkAllTaskDone_Manual_Flow();
      });
    }

    if (result.status === 'error') {
      this.tryAgain_CreateKeyword_Manual();
    }
  }

  // Called if any error while creating keywords (for manual campaign)
  spCreateKeywords_Manual_Error = (error) => {
    console.log('spCreateKeywords_Manual_Error() error:', error);
    this.tryAgain_CreateKeyword_Manual();
  }

  // Try again (Retry) to create keyword for manual flow (Broad/Phrase/Exact)
  tryAgain_CreateKeyword_Manual = () => {
    console.log('tryAgain_CreateKeyword_Manual()');
    console.log('RETRY_COUNT_CREATE_KEYWORD_MANUAL: ', this.RETRY_COUNT_CREATE_KEYWORD_MANUAL);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_KEYWORD_MANUAL < 3 ) {
      this.RETRY_COUNT_CREATE_KEYWORD_MANUAL++;  // Increment retry count

      // Retry after 2 seconds
      setTimeout( () => {
        this.createKeyword_Broad_Phrase_Exact();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createKeyword_Broad_Phrase_Exact(), Continue with other task.');

      // Show error message to user
      const msg = 'Can not create keyword this time, please try again later on.';
      this.setState({
        messageText: msg,
        showMessage: true,
        manualKeywordCreated: true, 
      }, () => {
        this.checkAllTaskDone_Manual_Flow();
      });
    }
  }



  // Create Negative keyword for Broad, and Phrase Ad Group (manual campaign)
  // STEP-3.1B: Create Negative Keywords for Negative Exact match (using manual_broad_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  // STEP-3.2B: Create Negative Keywords for Negative Exact match (using manual_phrase_ad_group_id, manual_campaign_id) [No need to save created keyword id within db]
  createNegativeKeyword_Broad_Phrase = () => {
    console.log('createNegativeKeyword_Broad_Phrase()');

    // 1 - Read data from state
    const { profile_id, manual_broad_ad_group_id, manual_phrase_ad_group_id } = this.state.flowDataDb;
    const { createdManualCampaignId, 
      createdBroadAdGroupId, createdPhraseAdGroupId, 
      broadAdGroupChecked, phraseAdGroupChecked, 
      hintNegativeKeywordBroad, hintNegativeKeywordPhrase, 
    } = this.state;

    // Debug 
    // console.log('createdManualCampaignId:', createdManualCampaignId);
    // console.log('createdBroadAdGroupId:', createdBroadAdGroupId);
    // console.log('createdPhraseAdGroupId:', createdPhraseAdGroupId);

    // 1A - IMPORTANT
    // If manual campaign id empty/not exist then return, we can not create any ad group without it.
    if ( !createdManualCampaignId || createdManualCampaignId === '' ) {
      const msg = 'createdManualCampaignId empty, So can not create negative keyword.';
      this.setState({
        messageText: msg,
        showMessage: true,
        //isProcessing: false,
        //isCreatingManualCampaign: false,
      });

      return; // IMPORTANT
    }
    
    // 2 - Prepare data to create negative keyword
    // We have to pass data as an array because server side api expect data as an array.
    // So we can create multiple 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ] 
    const keywordDataArray = []; 

    // 2A - If Broad Ad Group checked then create Negative keyword for that. (using negativeExact match).
    // STEP-3.1B: Create Negative Exact match keyword (using createdBroadAdGroupId, createdManualCampaignId)
    if ( manual_broad_ad_group_id === null && broadAdGroupChecked && createdBroadAdGroupId !== '' ) { 
      console.log('Adding negativeExact keyword for Broad AdGroup');

      // Prepare array for negative exact keyword - Broad AdGroup
      const selectedNegativeKeywordsBroad = hintNegativeKeywordBroad.filter((item, index) => { return true; }); // Copy array to new variable
      console.log('selectedNegativeKeywordsBroad:', selectedNegativeKeywordsBroad); // e.g. ['keyword1', 'keyword2']

      selectedNegativeKeywordsBroad.forEach((item, index) => {
        const keywordData = {
          campaignId: createdManualCampaignId,
          adGroupId: createdBroadAdGroupId,
          state: 'enabled',
          keywordText: item,
          matchType: 'negativeExact',
        } 
        keywordDataArray.push(keywordData);
      });
    } else {
      console.log('Not Adding negativeExact keyword for Broad AdGroup');
    }

    // 2B - If Phrase Ad Group checked then create Negative keyword for that (using negativeExact match).
    // STEP-3.2B: Create Negative Exact match keyword (using createdPhraseAdGroupId, createdManualCampaignId)
    if ( manual_phrase_ad_group_id === null && phraseAdGroupChecked && createdPhraseAdGroupId !== '' ) { 
      console.log('Adding negativeExact keyword for Phrase AdGroup');
      
      // Prepare array for negative exact keyword - Phrase AdGroup
      const selectedNegativeKeywordsPhrase = hintNegativeKeywordPhrase.filter((item, index) => { return true; }); // Copy array to new variable
      console.log('selectedNegativeKeywordsPhrase:', selectedNegativeKeywordsPhrase); // e.g. ['keyword1', 'keyword2']

      selectedNegativeKeywordsPhrase.forEach((item, index) => {
        const keywordData = {
          campaignId: createdManualCampaignId,
          adGroupId: createdPhraseAdGroupId,
          state: 'enabled',
          keywordText: item,
          matchType: 'negativeExact',
        } 
        keywordDataArray.push(keywordData);
      }); 
    } else {
      console.log('Not Adding negativeExact keyword for Phrase AdGroup');
    }
   
    // 2C - We will not create negative keyword for Exact Ad group although
    // that ad group is selected (checked) by the user. So we will not add 
    // negative keyword for exact ad group within keywordDataArray.
    // According to our autmation flow criteria, we dont need negative keyword for exact ad group.

    // Debug
    console.log('(Manual - Broad/Phrase) Negative keywordDataArray:', keywordDataArray);


    // Important: If negative keyword list empty then no need to all api, so consider as 
    // the keyword created and proceed with next task.
    if (keywordDataArray.length === 0) {
      console.log('(Manual) Negative Keyword list empty, so no need to call api.');

      this.setState({
        manualNegativeKeywordCreated: true, 
      }, () => {
        // Check all task done for manual flow or not
        this.checkAllTaskDone_Manual_Flow();
      });

      return; // Important
    }


    // 3 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_MANUAL_FLOW === 'DUMMY' ) { 
      console.log('DUMMY API Call Done - Create Negative Keyword (manual flow)');

      // 3A - Imitate success call back
      const dummyResult = { 
        status: 'success', 
        data: [ { keywordId: 4001, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      this.spCreateNegativeKeywords_Manual_Success(dummyResult);
      return; // Important

      // // 3B - OR Imitate error callback
      // const error = { code: 'error', message: 'Negative keyword create error (manual flow)' }
      // this.spCreateNegativeKeywords_Manual_Error(error);
      // return; // Important
    }
    // End: dummy callback

    // 3 - Call api to create negative keywords
    spCreateNegativeKeywords(profile_id, keywordDataArray, this.spCreateNegativeKeywords_Manual_Success, this.spCreateNegativeKeywords_Manual_Error);
  }

  // Called when Negative keyword created successfully (for manual campaign)
  // e.g. jsonBody: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //          "description": ""
  //        }], 
  //        error: null 
  //      }
  spCreateNegativeKeywords_Manual_Success = (result) => {
    console.log('spCreateNegativeKeywords_Manual_Success() result:', result);
  
    if (result.status === 'success') {
      // We will not store negative keyword created result within db or state.
      // So within state we just update necessary variable.
      
      this.setState({
        manualNegativeKeywordCreated: true, 
      }, () => {
        // Check all task done for manual flow or not
        this.checkAllTaskDone_Manual_Flow();
      });
    }

    if (result.status === 'error') {
      this.tryAgain_CreateNegativeKeyword_Manual();
    }
  }

  // Called if any error while creating negative keywords (for manual campaign)
  spCreateNegativeKeywords_Manual_Error = (error) => {
    console.log('spCreateNegativeKeywords_Manual_Error() error:', error);
    this.tryAgain_CreateNegativeKeyword_Manual();
  }

  // Try again (Retry) to create negative keyword for manual campaign
  tryAgain_CreateNegativeKeyword_Manual = () => {
    console.log('tryAgain_CreateNegativeKeyword_Manual()');
    console.log('RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL', this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL < 3 ) {
      this.RETRY_COUNT_CREATE_NEGATIVE_KEYWORD_MANUAL++;  // Increment retry count

      // Retry after 2 seconds
      setTimeout( () => {
        this.createNegativeKeyword_Broad_Phrase();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createNegativeKeyword_Broad_Phrase(), Continue with other task.');

      // Show error message to user
      const msg = 'Can not create Negative keyword this time, please try again.';
      this.setState({
        messageText: msg,
        showMessage: true,
        manualNegativeKeywordCreated: true, 
      }, () => {
        this.checkAllTaskDone_Manual_Flow();
      });
    }
  }



  // Create Product Ad for Broad, Phrase, and Exact Ad Group (Manual Flow)  
  //  STEP-4.1: Create Product Ad for Broad Ad Group. (using manual_broad_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  //  STEP-4.2: Create Product Ad for Phrase Ad Group. (using manual_phrase_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  //  STEP-4.3: Create Product Ad for Exact Ad Group. (using manual_exact_ad_group_id, manual_campaign_id, SKU/ASIN Array) [No need to save any info within db]
  createProductAd_Broad_Phrase_Exact = () => {
    console.log('createProductAd_Broad_Phrase_Exact()');

    // 1 - Read data from state
    const { profile_type, profile_id, 
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id,
    } = this.state.flowDataDb;

    const { createdManualCampaignId,  
      broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked, 
      createdBroadAdGroupId, createdPhraseAdGroupId, createdExactAdGroupId, 
    } = this.state;
    
    // Debug 
    // console.log('(Manual) profile_type: ', profile_type);
    console.log('(Manual) createdManualCampaignId:', createdManualCampaignId);
    console.log('(Manual) createdBroadAdGroupId:', createdBroadAdGroupId);
    console.log('(Manual) createdPhraseAdGroupId:', createdPhraseAdGroupId);
    console.log('(Manual) createdExactAdGroupId:', createdExactAdGroupId);

    // 1A - IMPORTANT: If createdManualCampaignId empty then return
    if ( createdManualCampaignId === '' ) {
      const msg = 'createdManualCampaignId not found, So cant create product ad.';
      this.setState({
        messageText: msg,
        showMessage: true,
        // isProcessing: false,
        // isCreatingManualCampaign: false,
      });

      return; // IMPORTANT
    }

    // 2 - Prepare data to create product Ads (Manual Flow)
    // We have to pass product ad creation related data as an array either 
    // create one or more product ad because server side api expect array data.
    // Note: 
    //  - If seller profile then add 'sku' key and value (sku must for seller profile) 
    //  - If vendor profile then add 'asin' key value (asin must for vendor profile) 
    //  - Do not inclde both 'sku' and 'asin' otherwise api will give error.
    // 
    // e.g. productAdDataArray = [
    //   {
    //      "campaignId": 0,
    //      "adGroupId": 0,
    //      "sku": "string",      // Add this key:value for seller profile (Do not add asin)
    //      "asin": "string",     // Add this key:value for vendor profile (Do not add sku)
    //      "state": "enabled"
    //   }
    // ]
    const productAdDataArray = [];

    // 2A - If vendor profile then use asin list to create product ad data
    // Note: User may selected Broad/Phrase/Exact match group, so depending on 
    // the user selection (Broad/Phrase/Exact) create necessary data.
    if ( profile_type === 'vendor' ) {
      const asinDict = this.getAsinDictFromProductAds(); // e.g { 'asin1': {state: 'enabled'}, 'asin2': {state: 'paused'} }
      const asinArray = Object.keys(asinDict); // Covert keys to array e.g. ['asin1', 'asin2']
      console.log('(vendor) asinArray: ', asinArray); // e.g. ['asin1', 'asin2']

      // If 'Broad Ad Group' checked then create product ad data for it.
      //  STEP-4.1: Create Product Ad for Broad Ad Group. (using createdBroadAdGroupId, createdManualCampaignId, ASIN) 
      if ( manual_broad_ad_group_id === null && broadAdGroupChecked && createdBroadAdGroupId !== '' ) { 
        console.log('(vendor) Adding product ad for Broad AdGroup');
        asinArray.forEach( (item, index) => {
          const singleData = {
            campaignId: createdManualCampaignId,
            adGroupId: createdBroadAdGroupId,
            state: asinDict[item].state,    // 'enabled' or 'paused'
            asin: item,                     // e.g. 'asin1'
          }
          productAdDataArray.push(singleData);
        });
      } else {
        console.log('(vendor) Not Adding Product Ad for Broad Ad Group');
      }

      // If 'Phrase Ad Group' checked then create product ad data for it.
      //  STEP-4.2: Create Product Ad for Phrase Ad Group. (using createdPhraseAdGroupId, createdManualCampaignId, ASIN) 
      if ( manual_phrase_ad_group_id === null && phraseAdGroupChecked && createdPhraseAdGroupId !== '' ) { 
        console.log('(vendor) Adding product ad for Phrase AdGroup');
        asinArray.forEach( (item, index) => {
          const singleData = {
            campaignId: createdManualCampaignId,
            adGroupId: createdPhraseAdGroupId,
            state: asinDict[item].state,    // 'enabled' or 'paused'
            asin: item,                     // e.g. 'asin1'
          }
          productAdDataArray.push(singleData);
        });
      } else {
        console.log('(vendor) Not Adding Product Ad for Phrase Ad Group');
      }

      // If 'Exact Ad Group' checked then create product ad data for it.
      // STEP-4.3: Create Product Ad for Exact Ad Group. (using createdExactAdGroupId, createdManualCampaignId, ASIN) 
      if ( manual_exact_ad_group_id === null && exactAdGroupChecked && createdExactAdGroupId !== '' ) { 
        console.log('(vendor) Adding product ad for Exact AdGroup');
        asinArray.forEach( (item, index) => {
          const singleData = {
            campaignId: createdManualCampaignId,
            adGroupId: createdExactAdGroupId,
            state: asinDict[item].state,    // 'enabled' or 'paused'
            asin: item,                     // e.g. 'asin1'
          }
          productAdDataArray.push(singleData);
        });
      } else {
        console.log('(vendor) Not Adding Product Ad for Exact Ad Group');
      }
    
    } // End bracket - vendor (End 2A)


    // 2B - If seller profile then use seller sku to create product ad data
    if ( profile_type === 'seller' ) {
      const skuDict = this.getSkuDictFromProductAds();  // e.g { 'sku1': {state: 'enabled'}, 'sku2': {state: 'paused'} }
      const skuArray = Object.keys(skuDict);  // Convert keys to array e.g. ['sku1', 'sku2']
      console.log('skuArray: ', skuArray);    // e.g. ['sku1', 'sku2']
    
      // If 'Broad Ad Group' checked then create product ad data for it.
      //  STEP-4.1: Create Product Ad for Broad Ad Group. (using createdBroadAdGroupId, createdManualCampaignId, SKU) 
      if ( manual_broad_ad_group_id === null && broadAdGroupChecked && createdBroadAdGroupId !== '' ) {         
        console.log('(seller) Adding product ad for Broad AdGroup');
        skuArray.forEach( (item, index) => {
          const singleData = {
            campaignId: createdManualCampaignId,
            adGroupId: createdBroadAdGroupId,
            state: skuDict[item].state,   // 'enabled' or 'paused'
            sku: item,                    // e.g. 'sku1'
          }
          productAdDataArray.push(singleData);
        });
      } else {
        console.log('(seller) Not Adding Product Ad for Broad Ad Group');
      }

      // If 'Phrase Ad Group' checked then create product ad data for it.
      //  STEP-4.2: Create Product Ad for Phrase Ad Group. (using createdPhraseAdGroupId, createdManualCampaignId, SKU) 
      if ( manual_phrase_ad_group_id === null && phraseAdGroupChecked && createdPhraseAdGroupId !== '' ) { 
        console.log('(seller) Adding product ad for Phrase AdGroup');
        skuArray.forEach( (item, index) => {
          const singleData = {
            campaignId: createdManualCampaignId,
            adGroupId: createdPhraseAdGroupId,
            state: skuDict[item].state,    // 'enabled' or 'paused'
            sku: item,                     // e.g. 'sku1'
          }
          productAdDataArray.push(singleData);
        });
      } else {
        console.log('(seller) Not Adding Product Ad for Phrase Ad Group');
      }

      // If 'Exact Ad Group' checked then create product ad data for it.
      // STEP-4.3: Create Product Ad for Exact Ad Group. (using createdExactAdGroupId, createdManualCampaignId, SKU) 
      if ( manual_exact_ad_group_id === null && exactAdGroupChecked && createdExactAdGroupId !== '' ) { 
        console.log('(seller) Adding product ad for Exact AdGroup');
        skuArray.forEach( (item, index) => {
          const singleData = {
            campaignId: createdManualCampaignId,
            adGroupId: createdExactAdGroupId,
            state: skuDict[item].state,    // 'enabled' or 'paused'
            sku: item,                     // e.g. 'sku1'
          }
          productAdDataArray.push(singleData);
        });
      } else {
        console.log('(seller) Not Adding Product Ad for Exact Ad Group');
      }

    }  // End bracket - seller (End 2B)

    // Debug
    console.log('(Manual) profile_id:', profile_id);
    console.log('(Manual) productAdDataArray - ' + profile_type + ' :', productAdDataArray);

    // 3 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_MANUAL_FLOW === 'DUMMY' ) {
      console.log('DUMMY API Call Done - Create Product Ads (Manual Flow)');

      // 3A - Imitate Success callback
      const dummyResult = { 
        status: 'success', 
        data: [ { adId: 5001, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      this.spCreateProductAds_Manual_Success(dummyResult);
      return; // Important

      // // 3B - OR Imitate error callback
      // const error = { code: 'error', message: 'Product Ad create error (manual flow)' }
      // this.spCreateProductAds_Manual_Error(error);
      // return; // Important
    }
    // End: dummy callback

    // 3 - Call api to create product ad (for manual flow)
    spCreateProductAds(profile_id, productAdDataArray, this.spCreateProductAds_Manual_Success, this.spCreateProductAds_Manual_Error);
  }

  // Called if product ad created successfully (for manual flow)
  // e.g. result = { 
  //   status: 'success', 
  //   data: [
  //     { 
  //        "adId": 0,
  //        "code": "string",
  //        "details": "string"
  //     } 
  //   ],
  //   error: null,
  // }
  spCreateProductAds_Manual_Success = (result) => {
    console.log('spCreateProductAds_Manual_Success() result:', result);

    if (result.status === 'success') {
      // We will not store product ad created result within db or state.
      // So within state we just update necessary variable. 
      this.setState({
        manualProductAdCreated: true,
      }, () => {
        // Check all task done for manual flow or not
        this.checkAllTaskDone_Manual_Flow();
      });
    }

    if (result.status === 'error') {
      // Try again to create product ad for Broad, Phrase, exact ad group
      this.tryAgain_CreateProductAd_Manual();
    }
  }

  // Called if any error while creating product ad (for manual flow)
  spCreateProductAds_Manual_Error = (error) => {
    console.log('spCreateProductAds_Manual_Error() error:', error);

    // Try again to create product ad for Broad, Phrase, exact ad group
    this.tryAgain_CreateProductAd_Manual();
  }

  // Try again (Retry) to create product ad for manual campaign
  tryAgain_CreateProductAd_Manual = () => {
    console.log('tryAgain_CreateProductAd_Manual()');
    console.log('RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL', this.RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL);

    // We will retry max. 3 times (retry count start with 0)
    if ( this.RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL < 3 ) {
      this.RETRY_COUNT_CREATE_PRODUCT_AD_MANUAL++;  // Increment retry count

      // Retry after 2 seconds
      setTimeout( () => {
        this.createProductAd_Broad_Phrase_Exact();
      }, 2000);

    } else { // Maximum retry done. i.e. show error message and continue with other task
      console.log('All Retry Done for createProductAd_Broad_Phrase_Exact(), Continue with other task.');

      // Show error message to user
      this.setState({
        messageText: 'Can not create Product Ad this time, Please try again later.',
        showMessage: true,
        manualProductAdCreated: true,
      }, () => {
        this.checkAllTaskDone_Manual_Flow();
      });
    }
  }



  // It will check that all task done realted to manual flow.
  checkAllTaskDone_Manual_Flow = () => {
    console.log('checkAllTaskDone_Manual_Flow()');

    const { 
      manualBroadAdGroupCreated, manualPhraseAdGroupCreated, manualExactAdGroupCreated,
      manualKeywordCreated, manualNegativeKeywordCreated, manualProductAdCreated,
    } = this.state;

    // If all task done related to manual flow then consider manual campaign completed.
    if ( manualBroadAdGroupCreated === true && 
          manualPhraseAdGroupCreated === true && 
          manualExactAdGroupCreated === true &&
          manualKeywordCreated === true && 
          manualNegativeKeywordCreated === true && 
          manualProductAdCreated === true
      ) {
      console.log('All task DONE - manual flow');
      this.considerManualCampaignCreated();
    } else {
      console.log('All task NOT Done yet - manual flow');
    }
  }


  // Consider as the manual campaign created,
  // So we will refresh necessary data, so it will update ui element according to available data.
  considerManualCampaignCreated = () => {
    console.log('considerManualCampaignCreated()');

    // We will set isProcessing = false, and isCreatingManualCampaign = false within 
    // fetchFlowDataDb() function so until data refresh happen it will show processing
    // state etc.

    // If timeout handler exist/set then clear it.
    if (this.timeOutRefreshData_Manual) {
      console.log('clearTimeout handler - this.timeOutRefreshData_Manual');
      clearTimeout(this.timeOutRefreshData_Manual);
    }

    // Set timeout to refresh data after X seconds.
    // i.e. Fetch flow data from db after x seconds
    this.timeOutRefreshData_Manual = setTimeout(() => {
      console.log('Refreshing flow data from db......');
      this.fetchFlowDataDb();
    }, 1000);
    console.log('setTimeout handler - this.timeOutRefreshData_Manual');
  }

  //-----------------------------------------------------------------
  // End: Create Manual Campaign + Broad/Phrase/Exact Ad Group etc. (Api Call)
  //-----------------------------------------------------------------



  //-----------------------------------------------------------------
  // Start: Update Auto Campaign Info
  //----------------------------------------------------------------- 
  // Auto Campaign - Clicked edit icon
  onClickEdit_AutoCampaign = (campaign) => {
    console.log('onClickEdit_AutoCampaign() campaignId:', campaign.campaignId);
    
    // Convert YYYYMMDD to YYYY-MM-DD format because input control need YYYY-MM-DD format.
    const startDateFormated = this.convert_YYYYMMDD_YYYY_MM_DD(campaign.startDate);
    const endDateFormated = this.convert_YYYYMMDD_YYYY_MM_DD(campaign.endDate);

    // Set edit mode - Auto campaign
    // i.e. Assign data received via api to edit fields
    this.setState({
      editModeAutoCampaign: true,
      editDailyBudgetAuto: campaign.dailyBudget,
      editStartDateAuto: startDateFormated,
      editEndDateAuto: endDateFormated,
      editStateAuto: campaign.state,
    });
  }
  
  // Auto Campaign - Clicked Save icon (i.e. Update Campaign)
  onClickEdit_SaveAutoCampaign = (campaign) => {
    console.log('onClickEdit_SaveAutoCampaign() campaignId:', campaign.campaignId);

    // 1 - Fetch flow data saved in db
    const { profile_id, auto_campaign_id } = this.state.flowDataDb;

    // 2 - Fetch value from input form field (Update Campaign)
    const { editDailyBudgetAuto, editStartDateAuto, editEndDateAuto, editStateAuto } = this.state;

    // 3 - If input not valid then return
    if ( campaign.campaignId !== auto_campaign_id ) { // Campain id not match with db then show message
      this.setState({ messageText: 'Campaign id mismatch, please refresh the page.', showMessage: true }); 
      return;
    }
    if ( editDailyBudgetAuto < 1 ) {
      this.setState({ messageText: 'Daily budget should be minimum $1', showMessage: true }); 
      return;
    }
    if( editStartDateAuto === '' ) {
      this.setState({ messageText: 'Please enter valid start date', showMessage: true });
      return;
    } else {
      // If start date changed within ui then validate that Start date can not be past date.
      const startDateApi = campaign.startDate;                  // e.g. 20201218 (String)
      const startDateUi = editStartDateAuto.replace(/-/g, "");  // e.g. 20201220 (String)
      if ( startDateApi !== startDateUi ) { // Date Changed within ui
        const isExpired = this.isGiven_YYYYMMDD_Expired(startDateUi);
        if (isExpired) { // Start date in the past means it is expired.
          this.setState({ messageText: 'Start date can not be past date.', showMessage: true });
          return;
        }
      }
    }
    if( editEndDateAuto === '' ) {
      this.setState({ messageText: 'Please enter valid end date', showMessage: true });
      return;
    } else {
      // If end date changed within ui then validate that End date can not be past date.
      const endDateApi = campaign.endDate;                  // e.g. 20201218 (String)
      const endDateUi = editEndDateAuto.replace(/-/g, "");  // e.g. 20201220 (String)
      if ( endDateApi !== endDateUi ) { // Date Changed within ui
        const isExpired = this.isGiven_YYYYMMDD_Expired(endDateUi);
        if (isExpired) { // End date in the past means it is expired.
          this.setState({ messageText: 'End date can not be past date.', showMessage: true });
          return;
        }
      }
    }
    const validStates = ['enabled', 'paused'];
    if ( !validStates.includes(editStateAuto) ) { 
      this.setState({ messageText: 'Campaign state should be enabled or paused', showMessage: true }); 
      return;
    }

    // 4 - Prepare data to pass the api
    // i.e. Convert data to appropriate format
    const startDateFinal = editStartDateAuto.replace(/-/g, "");     // e.g. 20201021
    const endDateFinal = editEndDateAuto.replace(/-/g, "");         // e.g. 20201028
    const dailyBudget = parseFloat(editDailyBudgetAuto);

    // 5 - Prepare data to update campaign
    // e.g. updateDataArray = [
    //   {
    //     "campaignId": 0,
    //     "portfolioId": 0,
    //     "name": "string",
    //     "state": "enabled",
    //     "dailyBudget": 0,
    //     "startDate": "string",
    //     "endDate": "string",
    //     "premiumBidAdjustment": true
    //   }
    // ]
    const updateData1 = { 
      campaignId: campaign.campaignId,
    }
    let anyValueChanged = false;
    if( dailyBudget !== campaign.dailyBudget ) { // Daily budget changed within ui, so update this field.
      updateData1['dailyBudget'] = dailyBudget;
      anyValueChanged = true;
    }
    if( startDateFinal !== campaign.startDate ) { // Start date changed within ui, so update this field.
      updateData1['startDate'] = startDateFinal;
      anyValueChanged = true;
    }
    if( endDateFinal !== campaign.endDate ) { // End date changed within ui, so update this field.
      updateData1['endDate'] = endDateFinal;
      anyValueChanged = true;
    }
    if( editStateAuto !== campaign.state ) { // State changed within ui, so update this field
      updateData1['state'] = editStateAuto;
      anyValueChanged = true;
    }
    const updateDataArray = [ updateData1 ];
    console.log('(Auto Campaign) updateDataArray:', updateDataArray);

    // 6 - At least one value changed within update form (ui) then call api, otherwise not.
    if ( !anyValueChanged ) {
      console.log('(Auto Campaign) Any input value not changed within ui, So Api call not need.');
      this.setState({
        editModeAutoCampaign: false,
      });
      return; // Important
    }

    // 7 - Set processing on
    this.setState({
      isProcessing: true, 
      isUpdatingAutoCampaign: true,
    });

    // 8 - Call api to update campaign
    spUpdateCampaigns(profile_id, updateDataArray, this.spUpdateCampaigns_Auto_Success, this.spUpdateCampaigns_Auto_Error);
  }

  // Called if auto campaign updated successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //       "campaignId": 0,
  //       "code": "SUCCESS",
  //       "description": "string"
  //     }  
  //   ],
  //   error: null
  // }
  // i.e. campaignId - The identifier of the campaign.
  // i.e. code - An enumerated success or error code for machine use.
  // i.e. description - A human-readable description of the code.
  spUpdateCampaigns_Auto_Success = async (result) => {
    console.log('spUpdateCampaigns_Auto_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      const { code, campaignId, description } = result.data[0];
      if ( code === 'SUCCESS' ) { // Returned by amazon if updated successfully
        this.fetchCampaignListViaApi();
        this.closeEditModeAfterDelay_AutoCampaign();
      } else if ( code === 'INVALID_ARGUMENT') {
        this.showError_AutoCampaignUpdate(description);
      } else {
        this.showError_AutoCampaignUpdate();
      }
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_AutoCampaignUpdate();
    }
  }

  // Called if error occur while update
  spUpdateCampaigns_Auto_Error = (error) => {
    console.log('spUpdateCampaigns_Auto_Error() error:', error);
    this.showError_AutoCampaignUpdate();
  }

  // Show error message 
  showError_AutoCampaignUpdate = (message) => {
    let msg = 'Can not update campaign this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    
    this.setState({
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingAutoCampaign: false, 
      editModeAutoCampaign: false,
    }); 
  }

  // Close the auto campaign edit mode after x seconds
  closeEditModeAfterDelay_AutoCampaign = () => {
    setTimeout( () => {
      this.setState({
        isProcessing: false, 
        isUpdatingAutoCampaign: false, 
        editModeAutoCampaign: false, 
      });
    }, 3000);
  }


  // Auto Campaign - Clicked Cancel icon (i.e. Update Campaign cancel)
  onClickEdit_CancelAutoCampaign = (campaign) => {
    console.log('onClickEdit_CancelAutoCampaign() campaignId:', campaign.campaignId);

    // Remove edit mode - Auto Campaign
    this.setState({
      editModeAutoCampaign: false,
    });
  }

  // Auto Campaign - User entering Daily Budget within text input
  onChange_DailyBudget_AutoCampaign = (value) => {

    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 9999
    if ( value > 9999 ) { return; }

    // 3 - Set value within state
    this.setState({ editDailyBudgetAuto: value, });
  }
  //-----------------------------------------------------------------
  // End: Update Auto Campaign Info
  //----------------------------------------------------------------- 


  //-----------------------------------------------------------------
  // Start: Update Auto Ad Group Info
  //----------------------------------------------------------------- 
  // Auto Ad Group - Clicked edit icon 
  onClickEdit_AutoAdGroup = (adGroup) => {
    console.log('onClickEdit_AutoAdGroup() adGroupId:', adGroup.adGroupId);
    
    // Set edit mode - Auto Ad Group
    this.setState({
      editModeAutoAdGroup: true,
      editDefaultBidAutoAdGroup: adGroup.defaultBid,
      editStateAutoAdGroup: adGroup.state,
    });
  }

  // Auto Ad Group - Clicked Save icon (i.e. Update AdGroup)
  onClickEdit_SaveAutoAdGroup = (adGroup) => {
    console.log('onClickEdit_SaveAutoAdGroup() adGroupId:', adGroup.adGroupId);
    //console.log('onClickEdit_SaveAutoAdGroup() adGroup:', adGroup);

    // 1 - Fetch flow data saved in db
    const { profile_id, auto_ad_group_id } = this.state.flowDataDb;

    // 2 - Fetch value from input field (Update form)
    const { editDefaultBidAutoAdGroup, editStateAutoAdGroup } = this.state;

    // Debug
    // console.log('auto_ad_group_id:', auto_ad_group_id);
    // console.log('editDefaultBidAutoAdGroup:', editDefaultBidAutoAdGroup);
    // console.log('editStateAutoAdGroup:', editStateAutoAdGroup);

    // 3 - If input not valid then return
    if ( adGroup.adGroupId !== auto_ad_group_id ) { // Ad Group id not match with db then show message
      this.setState({ messageText: 'AdGroup id mismatch, please refresh the page.', showMessage: true }); 
      return;
    }
    if ( editDefaultBidAutoAdGroup < 0.02 ) {
      this.setState({ messageText: 'Default bid should be minimum $0.02', showMessage: true });
      return;
    }
    const validStates = ['enabled', 'paused'];
    if ( !validStates.includes(editStateAutoAdGroup) ) { 
      this.setState({ messageText: 'AdGroup state should be enabled or paused', showMessage: true }); 
      return;
    }

    // 4 - Convert input data to api supported format.
    const defaultBid = parseFloat(editDefaultBidAutoAdGroup);
    
    // 5 - Prepare data to update ad group
    // i.e Arrary of data to update one or more AdGroups
    // e.g. updateData = [
    //   {
    //      "adGroupId": 0,
    //      "name": "string",
    //      "defaultBid": 0,
    //      "state": "enabled"
    //   }
    // ]
    // @adGroupId - existing ad groups id to update values. (Required)
    // - Pass other fields those value needs to update.
    const updateData1 = { adGroupId: adGroup.adGroupId }
    let anyValueChanged = false;
    if( defaultBid !== adGroup.defaultBid ) { // Changed default bid value within ui, so update this field.
      updateData1['defaultBid'] = defaultBid;
      anyValueChanged = true;
    }
    if( editStateAutoAdGroup !== adGroup.state ) { // Changed state value within ui, so update this field
      updateData1['state'] = editStateAutoAdGroup;
      anyValueChanged = true;
    }
    const updateDataArray = [ updateData1 ];
    console.log('(Auto AdGroup) updateDataArray:', updateDataArray);

    // 6 - At least one value changed within update form (ui) then call api, otherwise not.
    if ( !anyValueChanged ) {
      console.log('(Auto AdGroup) Any input value not changed within ui, So Api call not need.');
      this.setState({
        editModeAutoAdGroup: false,
      });
      return; // Important
    }

    // 7 - Set processing on
    this.setState({
      isProcessing: true, 
      isUpdatingAutoAdGroup: true,
    });
    
    // 8 - Call api to update adGroup
    spUpdateAdGroups(profile_id, updateDataArray, this.spUpdateAdGroups_Auto_Success, this.spUpdateAdGroups_Auto_Error);
  }

  // Called if ad group udpated successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //        "adGroupId": 0,
  //        "code": "string",
  //        "details": "string"
  //     }  
  //   ],
  //   error: null
  // }
  // i.e. adGroupId - The identifier of the ad group.
  // i.e. code - An enumerated success or error code for machine use. SUCCESS etc.
  // i.e. details - A human-readable description of the code.
  spUpdateAdGroups_Auto_Success = (result) => {
    console.log('spUpdateAdGroups_Auto_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      // If result.data array empty, then show error message and return.
      if ( result.data.length === 0 ) { 
        this.showError_AutoAdGroupUpdate();
        return; // Important
      }
      
      // We passed one update data to api, so result.data array consist one element.
      const { code, adGroupId, details } = result.data[0];
      if ( code === 'SUCCESS' ) { // Returned by amazon if updated successfully
        this.fetchAdGroupListViaApi();
        this.closeEditModeAfterDelay_AutoAdGroup();
      } else if ( code === 'INVALID_ARGUMENT') {
        this.showError_AutoAdGroupUpdate(details);
      } else {
        this.showError_AutoAdGroupUpdate();
      }
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_AutoAdGroupUpdate();
    }
  }

  // Called if error occured while update
  spUpdateAdGroups_Auto_Error = (error) => {
    console.log('spUpdateAdGroups_Auto_Error() error:', error);
    this.showError_AutoAdGroupUpdate();
  }

  // Show error message 
  showError_AutoAdGroupUpdate = (message) => {
    let msg = 'Can not update ad group this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    
    this.setState({
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingAutoAdGroup: false, 
      editModeAutoAdGroup: false,
    });
  }
 
  // Close the auto ad group edit mode after x seconds
  closeEditModeAfterDelay_AutoAdGroup = () => {
    setTimeout( () => {
      this.setState({
        isProcessing: false, 
        isUpdatingAutoAdGroup: false, 
        editModeAutoAdGroup: false, 
      });
    }, 3000);
  } 


  // Auto Ad Group - Clicked Cancel icon (i.e. Update AdGroup Cancel)
  onClickEdit_CancelAutoAdGroup = (adGroup) => {
    console.log('onClickEdit_CancelAutoAdGroup() adGroupId:', adGroup.adGroupId);

    // No need to update any data via api

    // Remove edit mode - Auto Ad Group
    this.setState({
      editModeAutoAdGroup: false,
    });
  }

  // Auto AdGroup - User entering Default Bid within text input 
  onChange_DefaultBid_AutoAdGroup = (value) => {
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    //this.setState({ editDefaultBidAuto: value, });
    this.setState({ editDefaultBidAutoAdGroup: value, });
  }
  //-----------------------------------------------------------------
  // End: Update Auto Ad Group Info
  //----------------------------------------------------------------- 


  //-----------------------------------------------------------------
  // Start: Update Manual Campaign Info
  //----------------------------------------------------------------- 
  // Manual Campaign - Clicked edit icon
  onClickEdit_ManualCampaign = (campaign) => {
    console.log('onClickEdit_ManualCampaign() campaignId:', campaign.campaignId);
    
    // Convert YYYYMMDD to YYYY-MM-DD format because input control need YYYY-MM-DD format.
    const startDateFormated = this.convert_YYYYMMDD_YYYY_MM_DD(campaign.startDate);
    const endDateFormated = this.convert_YYYYMMDD_YYYY_MM_DD(campaign.endDate);

    // Set edit mode - manual campaign
    // i.e. Assign data received via api to edit fields
    this.setState({
      editModeManualCampaign: true,
      editDailyBudgetManual: campaign.dailyBudget,
      editStartDateManual: startDateFormated,
      editEndDateManual: endDateFormated,
      editStateManual: campaign.state,
    });
  }

  // Manual Campaign - Clicked Save icon (i.e. Update Campaign)
  onClickEdit_SaveManualCampaign = (campaign) => {
    console.log('onClickEdit_SaveManualCampaign() campaignId:', campaign.campaignId);
    //console.log('onClickEdit_SaveManualCampaign() campaign:', campaign);
   
    // 1 - Fetch flow data saved in db
    const { profile_id, manual_campaign_id } = this.state.flowDataDb;

    // 2 - Fetch value from input form field (Update Campaign)
    const { editDailyBudgetManual, editStartDateManual, editEndDateManual, editStateManual } = this.state;

    // 3 - If input not valid then return
    if ( campaign.campaignId !== manual_campaign_id ) { // Campain id not match with db then show message
      this.setState({ messageText: 'Campaign id mismatch, please refresh the page.', showMessage: true }); 
      return;
    }
    if ( editDailyBudgetManual < 1 ) {
      this.setState({ messageText: 'Daily budget should be minimum $1', showMessage: true }); 
      return;
    }
    if( editStartDateManual === '' ) {
      this.setState({ messageText: 'Please enter valid start date', showMessage: true });
      return;
    } else {
      // If start date changed within ui then validate that Start date can not be past date.
      const startDateApi = campaign.startDate;                    // e.g. 20201218 (String)
      const startDateUi = editStartDateManual.replace(/-/g, "");  // e.g. 20201220 (String)
      if ( startDateApi !== startDateUi ) { // Date Changed within ui
        const isExpired = this.isGiven_YYYYMMDD_Expired(startDateUi);
        if (isExpired) { // Start date in the past means it is expired.
          this.setState({ messageText: 'Start date can not be past date.', showMessage: true });
          return;
        }
      }
    }
    if( editEndDateManual === '' ) {
      this.setState({ messageText: 'Please enter valid end date', showMessage: true });
      return;
    } else {
      // If end date changed within ui then validate that End date can not be past date.
      const endDateApi = campaign.endDate;                    // e.g. 20201218 (String)
      const endDateUi = editEndDateManual.replace(/-/g, "");  // e.g. 20201220 (String)
      if ( endDateApi !== endDateUi ) { // Date Changed within ui
        const isExpired = this.isGiven_YYYYMMDD_Expired(endDateUi);
        if (isExpired) { // End date in the past means it is expired.
          this.setState({ messageText: 'End date can not be past date.', showMessage: true });
          return;
        }
      }
    }
    const validStates = ['enabled', 'paused'];
    if ( !validStates.includes(editStateManual) ) { 
      this.setState({ messageText: 'Campaign state should be enabled or paused', showMessage: true }); 
      return;
    }

    // 4 - Prepare data to pass the api
    // i.e. Convert data to appropriate format
    const startDateFinal = editStartDateManual.replace(/-/g, "");     // e.g. 20201021
    const endDateFinal = editEndDateManual.replace(/-/g, "");         // e.g. 20201028
    const dailyBudget = parseFloat(editDailyBudgetManual);

    // 5 - Prepare data to update campaign
    // e.g. updateDataArray = [
    //   {
    //     "campaignId": 0,
    //     "portfolioId": 0,
    //     "name": "string",
    //     "state": "enabled",
    //     "dailyBudget": 0,
    //     "startDate": "string",
    //     "endDate": "string",
    //     "premiumBidAdjustment": true
    //   }
    // ]
    const updateData1 = { 
      campaignId: campaign.campaignId,
    }
    let anyValueChanged = false;
    if( dailyBudget !== campaign.dailyBudget ) { // Daily budget changed within ui, so update this field.
      updateData1['dailyBudget'] = dailyBudget;
      anyValueChanged = true;
    }
    if( startDateFinal !== campaign.startDate ) { // Start date changed within ui, so update this field.
      updateData1['startDate'] = startDateFinal;
      anyValueChanged = true;
    }
    if( endDateFinal !== campaign.endDate ) { // End date changed within ui, so update this field.
      updateData1['endDate'] = endDateFinal;
      anyValueChanged = true;
    }
    if( editStateManual !== campaign.state ) { // State changed within ui, so update this field
      updateData1['state'] = editStateManual;
      anyValueChanged = true;
    }
    const updateDataArray = [ updateData1 ];
    console.log('(Manual Campaign) updateDataArray:', updateDataArray);

    // 6 - At least one value changed within update form (ui) then call api, otherwise not.
    if ( !anyValueChanged ) {
      console.log('(Manual Campaign) Any input value not changed within ui, So Api call not need.');
      this.setState({
        editModeManualCampaign: false,
      });
      return; // Important
    }

    // 7 - Set processing on
    this.setState({
      isProcessing: true, 
      isUpdatingManualCampaign: true,
    });

    // 8 - Call api to update campaign
    spUpdateCampaigns(profile_id, updateDataArray, this.spUpdateCampaigns_Manual_Success, this.spUpdateCampaigns_Manual_Error);
  }
  
  // Called if manual campaign updated successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //       "campaignId": 0,
  //       "code": "SUCCESS",
  //       "description": "string"
  //     }  
  //   ],
  //   error: null
  // }
  // i.e. campaignId - The identifier of the campaign.
  // i.e. code - An enumerated success or error code for machine use.
  // i.e. description - A human-readable description of the code.
  spUpdateCampaigns_Manual_Success = async (result) => {
    console.log('spUpdateCampaigns_Manual_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      const { code, campaignId, description } = result.data[0];
      if ( code === 'SUCCESS' ) { // Returned by amazon if updated successfully
        this.fetchCampaignListViaApi();
        this.closeEditModeAfterDelay_ManualCampaign();
      } else if ( code === 'INVALID_ARGUMENT') {
        this.showError_ManualCampaignUpdate(description);
      } else {
        this.showError_ManualCampaignUpdate();
      }
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_ManualCampaignUpdate();
    }
  }

  // Called if error occur while update
  spUpdateCampaigns_Manual_Error = (error) => {
    console.log('spUpdateCampaigns_Manual_Error() error:', error);
    this.showError_ManualCampaignUpdate();
  }

  // Show error message 
  showError_ManualCampaignUpdate = (message) => {
    let msg = 'Can not update campaign this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    
    this.setState({
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingManualCampaign: false, 
      editModeManualCampaign: false,
    });
  }

  // Close the manual campaign edit mode after x seconds
  closeEditModeAfterDelay_ManualCampaign = () => {
    setTimeout( () => {
      this.setState({
        isProcessing: false, 
        isUpdatingManualCampaign: false, 
        editModeManualCampaign: false, 
      });
    }, 3000);
  }

  // Manual Campaign - Clicked Cancel icon (i.e. Update Campaign cancel)
  onClickEdit_CancelManualCampaign = (campaign) => {
    console.log('onClickEdit_CancelManualCampaign() campaignId:', campaign.campaignId);

    // Remove edit mode - Manual Campaign
    this.setState({
      editModeManualCampaign: false,
    });
  }

  // Manual Campaign - User entering Daily Budget within text input
  onChange_DailyBudget_ManualCampaign = (value) => {

    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 9999
    if ( value > 9999 ) { return; }

    // 3 - Set value within state
    this.setState({ editDailyBudgetManual: value, });
  }
  //-----------------------------------------------------------------
  // End: Update Manual Campaign Info
  //----------------------------------------------------------------- 


  //-----------------------------------------------------------------
  // Start: Update Broad Ad Group Info
  //----------------------------------------------------------------- 
  // Broad Group - Clicked edit icon (Manual campaign)
  onClickEdit_BroadAdGroup = (adGroup) => {
    console.log('onClickEdit_BroadAdGroup() adGroupId:', adGroup.adGroupId);
    
    // Set edit mode for broad ad group
    this.setState({
      editModeBroadAdGroup: true,
      editDefaultBidBroadAdGroup: adGroup.defaultBid,
      editStateBroadAdGroup: adGroup.state,
    });
  }

  // Broad Ad Group - Clicked Save icon (i.e. Update AdGroup)
  onClickEdit_SaveBroadAdGroup = (adGroup) => {
    console.log('onClickEdit_SaveBroadAdGroup() adGroupId:', adGroup.adGroupId);
    //console.log('onClickEdit_SaveBroadAdGroup() adGroup:', adGroup);
    
    // 1 - Fetch flow data saved in db
    const { profile_id, manual_broad_ad_group_id } = this.state.flowDataDb;

    // 2 - Fetch value from input field (Update form)
    const { editDefaultBidBroadAdGroup, editStateBroadAdGroup } = this.state;

    // Debug
    // console.log('manual_broad_ad_group_id:', manual_broad_ad_group_id);
    // console.log('editDefaultBidBroadAdGroup:', editDefaultBidBroadAdGroup);
    // console.log('editStateBroadAdGroup:', editStateBroadAdGroup);
  
    // 3 - If input not valid then return
    if ( adGroup.adGroupId !== manual_broad_ad_group_id ) { // Ad Group id not match with db then show message
      this.setState({ messageText: 'AdGroup id mismatch, please refresh the page.', showMessage: true }); 
      return;
    }
    if ( editDefaultBidBroadAdGroup < 0.02 ) {
      this.setState({ messageText: 'Default bid should be minimum $0.02', showMessage: true });
      return;
    }
    const validStates = ['enabled', 'paused'];
    if ( !validStates.includes(editStateBroadAdGroup) ) { 
      this.setState({ messageText: 'AdGroup state should be enabled or paused', showMessage: true }); 
      return;
    }

    // 4 - Convert input data to api supported format.
    const defaultBid = parseFloat(editDefaultBidBroadAdGroup);
 
    // 5 - Prepare data to update ad group
    // i.e Arrary of data to update one or more AdGroups
    // e.g. updateData = [
    //   {
    //      "adGroupId": 0,
    //      "name": "string",
    //      "defaultBid": 0,
    //      "state": "enabled"
    //   }
    // ]
    // @adGroupId - existing ad groups id to update values. (Required)
    // - Pass other fields those value needs to update.
    const updateData1 = { adGroupId: adGroup.adGroupId }
    let anyValueChanged = false;
    if( defaultBid !== adGroup.defaultBid ) { // Changed default bid value within ui, so update this field.
      updateData1['defaultBid'] = defaultBid;
      anyValueChanged = true;
    }
    if( editStateBroadAdGroup !== adGroup.state ) { // Changed state value within ui, so update this field
      updateData1['state'] = editStateBroadAdGroup;
      anyValueChanged = true;
    }
    const updateDataArray = [ updateData1 ];
    console.log('(Broad AdGroup) updateDataArray:', updateDataArray);
    
    // 6 - At least one value changed within update form (ui) then call api, otherwise not.
    if ( !anyValueChanged ) {
      console.log('(Broad AdGroup) Any input value not changed within ui, So Api call not need.');
      this.setState({
        editModeBroadAdGroup: false,
      });
      return; // Important
    }
    
    // 7 - Set processing on
    this.setState({
      isProcessing: true, 
      isUpdatingBroadAdGroup: true,
    });
    
    // 8 - Call api to update adGroup
    spUpdateAdGroups(profile_id, updateDataArray, this.spUpdateAdGroups_Broad_Success, this.spUpdateAdGroups_Broad_Error);
  }

  // Called if ad group udpated successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //        "adGroupId": 0,
  //        "code": "string",
  //        "details": "string"
  //     }  
  //   ],
  //   error: null
  // }
  // i.e. adGroupId - The identifier of the ad group.
  // i.e. code - An enumerated success or error code for machine use. SUCCESS etc.
  // i.e. details - A human-readable description of the code.
  spUpdateAdGroups_Broad_Success = (result) => {
    console.log('spUpdateAdGroups_Broad_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      // If result.data array empty, then show error message and return.
      if ( result.data.length === 0 ) { 
        this.showError_BroadAdGroupUpdate();
        return; // Important
      }
      
      // We passed one update data to api, so result.data array consist one element.
      const { code, adGroupId, details } = result.data[0];
      if ( code === 'SUCCESS' ) { // Returned by amazon if updated successfully
        this.fetchAdGroupListViaApi();
        this.closeEditModeAfterDelay_BroadAdGroup();
      } else if ( code === 'INVALID_ARGUMENT' ) {
        this.showError_BroadAdGroupUpdate(details);
      } else {
        this.showError_BroadAdGroupUpdate();
      }
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_BroadAdGroupUpdate();
    } 
  } 

  // Called if error occured while update
  spUpdateAdGroups_Broad_Error = (error) => {
    console.log('spUpdateAdGroups_Broad_Error() error:', error);
    this.showError_BroadAdGroupUpdate();
  }  

  // Show error message 
  showError_BroadAdGroupUpdate = (message) => {
    let msg = 'Can not update ad group this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    
    this.setState({ 
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingBroadAdGroup: false, 
      editModeBroadAdGroup: false, 
    });
  }
  
  // Close the auto ad group edit mode after x seconds
  closeEditModeAfterDelay_BroadAdGroup = () => {
    setTimeout( () => {
      this.setState({
        isProcessing: false, 
        isUpdatingBroadAdGroup: false, 
        editModeBroadAdGroup: false, 
      });
    }, 3000);
  } 


  // Broad Ad Group - Clicked Cancel icon (i.e. Update AdGroup cancel)
  onClickEdit_CancelBroadAdGroup = (adGroup) => {
    console.log('onClickEdit_CancelBroadAdGroup() adGroupId:', adGroup.adGroupId);

    // No need to update any data via api

    // Remove edit mode - Broad Ad Group
    this.setState({
      editModeBroadAdGroup: false,
    });
  }

  // Broad AdGroup - User entering Default Bid within text input 
  onChange_DefaultBid_BroadAdGroup = (value) => {
  
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ editDefaultBidBroadAdGroup: value, });
  }
  //-----------------------------------------------------------------
  // End: Update Broad Ad Group Info
  //----------------------------------------------------------------- 


  //-----------------------------------------------------------------
  // Start: Update Phrase Ad Group Info
  //----------------------------------------------------------------- 
  // Phrase Group - Clicked edit icon (Manual campaign)
  onClickEdit_PhraseAdGroup = (adGroup) => {
    console.log('onClickEdit_PhraseAdGroup() adGroupId:', adGroup.adGroupId);
    
    // Set edit mode for phrase ad group
    this.setState({
      editModePhraseAdGroup: true,
      editDefaultBidPhraseAdGroup: adGroup.defaultBid,
      editStatePhraseAdGroup: adGroup.state,
    });
  }

  // Phrase Ad Group - Clicked Save icon (i.e. Update AdGroup)
  onClickEdit_SavePhraseAdGroup = (adGroup) => {
    console.log('onClickEdit_SavePhraseAdGroup() adGroupId:', adGroup.adGroupId);
    //console.log('onClickEdit_SavePhraseAdGroup() adGroup:', adGroup);

    // 1 - Fetch flow data saved in db
    const { profile_id, manual_phrase_ad_group_id } = this.state.flowDataDb;

    // 2 - Fetch value from input field (Update form)
    const { editDefaultBidPhraseAdGroup, editStatePhraseAdGroup } = this.state;

    // Debug
    // console.log('manual_phrase_ad_group_id:', manual_phrase_ad_group_id);
    // console.log('editDefaultBidPhraseAdGroup:', editDefaultBidPhraseAdGroup);
    // console.log('editStatePhraseAdGroup:', editStatePhraseAdGroup);
    
    // 3 - If input not valid then return
    if ( adGroup.adGroupId !== manual_phrase_ad_group_id ) { // Ad Group id not match with db then show message
      this.setState({ messageText: 'AdGroup id mismatch, please refresh the page.', showMessage: true }); 
      return;
    }
    if ( editDefaultBidPhraseAdGroup < 0.02 ) {
      this.setState({ messageText: 'Default bid should be minimum $0.02', showMessage: true });
      return;
    }
    const validStates = ['enabled', 'paused'];
    if ( !validStates.includes(editStatePhraseAdGroup) ) { 
      this.setState({ messageText: 'AdGroup state should be enabled or paused', showMessage: true }); 
      return;
    }
    
    // 4 - Convert input data to api supported format.
    const defaultBid = parseFloat(editDefaultBidPhraseAdGroup);
    
    // 5 - Prepare data to update ad group
    // i.e Arrary of data to update one or more AdGroups
    // e.g. updateData = [
    //   {
    //      "adGroupId": 0,
    //      "name": "string",
    //      "defaultBid": 0,
    //      "state": "enabled"
    //   }
    // ]
    // @adGroupId - existing ad groups id to update values. (Required)
    // - Pass other fields those value needs to update.
    const updateData1 = { adGroupId: adGroup.adGroupId }
    let anyValueChanged = false;
    if( defaultBid !== adGroup.defaultBid ) { // Changed default bid value within ui, so update this field.
      updateData1['defaultBid'] = defaultBid;
      anyValueChanged = true;
    }
    if( editStatePhraseAdGroup !== adGroup.state ) { // Changed state value within ui, so update this field
      updateData1['state'] = editStatePhraseAdGroup;
      anyValueChanged = true;
    }
    const updateDataArray = [ updateData1 ];
    console.log('(Phrase AdGroup) updateDataArray:', updateDataArray);
    
    // 6 - At least one value changed within update form (ui) then call api, otherwise not.
    if ( !anyValueChanged ) {
      console.log('(Phrase AdGroup) Any input value not changed within ui, So Api call not need.');
      this.setState({
        editModePhraseAdGroup: false,
      });
      return; // Important
    }
    
    // 7 - Set processing on
    this.setState({
      isProcessing: true, 
      isUpdatingPhraseAdGroup: true,
    });
    
    // 8 - Call api to update adGroup
    spUpdateAdGroups(profile_id, updateDataArray, this.spUpdateAdGroups_Phrase_Success, this.spUpdateAdGroups_Phrase_Error);
  }
  
  // Called if ad group udpated successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //        "adGroupId": 0,
  //        "code": "string",
  //        "details": "string"
  //     }  
  //   ],
  //   error: null
  // }
  // i.e. adGroupId - The identifier of the ad group.
  // i.e. code - An enumerated success or error code for machine use. SUCCESS etc.
  // i.e. details - A human-readable description of the code.
  spUpdateAdGroups_Phrase_Success = (result) => {
    console.log('spUpdateAdGroups_Phrase_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      // If result.data array empty, then show error message and return.
      if ( result.data.length === 0 ) { 
        this.showError_PhraseAdGroupUpdate();
        return; // Important
      }
      
      // We passed one update data to api, so result.data array consist one element.
      const { code, adGroupId, details } = result.data[0];
      if ( code === 'SUCCESS' ) { // Returned by amazon if updated successfully
        this.fetchAdGroupListViaApi();
        this.closeEditModeAfterDelay_PhraseAdGroup();
      } else if ( code === 'INVALID_ARGUMENT' ) {
        this.showError_PhraseAdGroupUpdate(details);
      } else {
        this.showError_PhraseAdGroupUpdate();
      }
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_PhraseAdGroupUpdate();
    } 
  } 

  // Called if error occured while update
  spUpdateAdGroups_Phrase_Error = (error) => {
    console.log('spUpdateAdGroups_Phrase_Error() error:', error);
    this.showError_PhraseAdGroupUpdate();
  }  
  
  // Show error message 
  showError_PhraseAdGroupUpdate = (message) => {
    let msg = 'Can not update ad group this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    
    this.setState({ 
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingPhraseAdGroup: false, 
      editModePhraseAdGroup: false, 
    }); 
  }
  
  // Close the auto ad group edit mode after x seconds
  closeEditModeAfterDelay_PhraseAdGroup = () => {
    setTimeout( () => {
      this.setState({
        isProcessing: false, 
        isUpdatingPhraseAdGroup: false, 
        editModePhraseAdGroup: false, 
      });
    }, 3000);
  } 


  // Phrase Ad Group - Clicked Cancel icon (i.e. Update AdGroup cancel)
  onClickEdit_CancelPhraseAdGroup = (adGroup) => {
    console.log('onClickEdit_CancelPhraseAdGroup() adGroupId:', adGroup.adGroupId);

    // No need to update any data via api

    // Remove edit mode - Phrase Ad Group
    this.setState({
      editModePhraseAdGroup: false,
    });
  }

  // Phrase AdGroup - User entering Default Bid within text input 
  onChange_DefaultBid_PhraseAdGroup = (value) => {
  
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ editDefaultBidPhraseAdGroup: value, });
  }
  //-----------------------------------------------------------------
  // End: Update Phrase Ad Group Info
  //----------------------------------------------------------------- 


  //-----------------------------------------------------------------
  // Start: Update Exact Ad Group Info
  //----------------------------------------------------------------- 
  // Exact Group - Clicked edit icon (Manual campaign)
  onClickEdit_ExactAdGroup = (adGroup) => {
    console.log('onClickEdit_ExactAdGroup() adGroupId:', adGroup.adGroupId);
    
    // Set edit mode for exact ad group
    this.setState({
      editModeExactAdGroup: true,
      editDefaultBidExactAdGroup: adGroup.defaultBid,
      editStateExactAdGroup: adGroup.state,
    });
  }

  // Exact Ad Group - Clicked Save icon (i.e. Update AdGroup)
  onClickEdit_SaveExactAdGroup = (adGroup) => {
    console.log('onClickEdit_SaveExactAdGroup() adGroupId:', adGroup.adGroupId);
    //console.log('onClickEdit_SaveExactAdGroup() adGroup:', adGroup);

    // 1 - Fetch flow data saved in db
    const { profile_id, manual_exact_ad_group_id } = this.state.flowDataDb;

    // 2 - Fetch value from input field (Update form)
    const { editDefaultBidExactAdGroup, editStateExactAdGroup } = this.state;

    // Debug
    // console.log('manual_exact_ad_group_id:', manual_exact_ad_group_id);
    // console.log('editDefaultBidExactAdGroup:', editDefaultBidExactAdGroup);
    // console.log('editStateExactAdGroup:', editStateExactAdGroup);
    
    // 3 - If input not valid then return
    if ( adGroup.adGroupId !== manual_exact_ad_group_id ) { // Ad Group id not match with db then show message
      this.setState({ messageText: 'AdGroup id mismatch, please refresh the page.', showMessage: true }); 
      return;
    }
    if ( editDefaultBidExactAdGroup < 0.02 ) {
      this.setState({ messageText: 'Default bid should be minimum $0.02', showMessage: true });
      return;
    }
    const validStates = ['enabled', 'paused'];
    if ( !validStates.includes(editStateExactAdGroup) ) { 
      this.setState({ messageText: 'AdGroup state should be enabled or paused', showMessage: true }); 
      return;
    }

    // 4 - Convert input data to api supported format.
    const defaultBid = parseFloat(editDefaultBidExactAdGroup);

    // 5 - Prepare data to update ad group
    // i.e Arrary of data to update one or more AdGroups
    // e.g. updateData = [
    //   {
    //      "adGroupId": 0,
    //      "name": "string",
    //      "defaultBid": 0,
    //      "state": "enabled"
    //   }
    // ]
    // @adGroupId - existing ad groups id to update values. (Required)
    // - Pass other fields those value needs to update.
    const updateData1 = { adGroupId: adGroup.adGroupId }
    let anyValueChanged = false;
    if( defaultBid !== adGroup.defaultBid ) { // Changed default bid value within ui, so update this field.
      updateData1['defaultBid'] = defaultBid;
      anyValueChanged = true;
    }
    if( editStateExactAdGroup !== adGroup.state ) { // Changed state value within ui, so update this field
      updateData1['state'] = editStateExactAdGroup;
      anyValueChanged = true;
    }
    const updateDataArray = [ updateData1 ];
    console.log('(Exact AdGroup) updateDataArray:', updateDataArray);
    
    // 6 - At least one value changed within update form (ui) then call api, otherwise not.
    if ( !anyValueChanged ) {
      console.log('(Exact AdGroup) Any input value not changed within ui, So Api call not need.');
      this.setState({
        editModeExactAdGroup: false,
      });
      return; // Important
    }
    
    // 7 - Set processing on
    this.setState({
      isProcessing: true, 
      isUpdatingExactAdGroup: true,
    });
    
    // 8 - Call api to update adGroup
    spUpdateAdGroups(profile_id, updateDataArray, this.spUpdateAdGroups_Exact_Success, this.spUpdateAdGroups_Exact_Error);
  }

  // Called if ad group udpated successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //        "adGroupId": 0,
  //        "code": "string",
  //        "details": "string"
  //     }  
  //   ],
  //   error: null
  // }
  // i.e. adGroupId - The identifier of the ad group.
  // i.e. code - An enumerated success or error code for machine use. SUCCESS etc.
  // i.e. details - A human-readable description of the code.
  spUpdateAdGroups_Exact_Success = (result) => {
    console.log('spUpdateAdGroups_Exact_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      // If result.data array empty, then show error message and return.
      if ( result.data.length === 0 ) { 
        this.showError_ExactAdGroupUpdate();
        return; // Important
      }
      
      // We passed one update data to api, so result.data array consist one element.
      const { code, adGroupId, details } = result.data[0];
      if ( code === 'SUCCESS' ) { // Returned by amazon if updated successfully
        this.fetchAdGroupListViaApi();
        this.closeEditModeAfterDelay_ExactAdGroup();
      } else if ( code === 'INVALID_ARGUMENT' ) {
        this.showError_ExactAdGroupUpdate(details);
      } else {
        this.showError_ExactAdGroupUpdate();
      }
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) { 
      this.showError_ExactAdGroupUpdate(); 
    } 
  } 

  // Called if error occured while update
  spUpdateAdGroups_Exact_Error = (error) => {
    console.log('spUpdateAdGroups_Exact_Error() error:', error);
    this.showError_ExactAdGroupUpdate(); 
  }  
  
  // Show error message 
  showError_ExactAdGroupUpdate = (message) => {
    let msg = 'Can not update ad group this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    
    this.setState({ 
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingExactAdGroup: false, 
      editModeExactAdGroup: false, 
    }); 
  }  
  
  // Close the auto ad group edit mode after x seconds
  closeEditModeAfterDelay_ExactAdGroup = () => {
    setTimeout( () => {
      this.setState({
        isProcessing: false, 
        isUpdatingExactAdGroup: false, 
        editModeExactAdGroup: false, 
      });
    }, 3000);
  } 


  // Exact Ad Group - Clicked Cancel icon (i.e. Update AdGroup cancel)
  onClickEdit_CancelExactAdGroup = (adGroup) => {
    console.log('onClickEdit_CancelExactAdGroup() adGroupId:', adGroup.adGroupId);

    // No need to update any data via api

    // Remove edit mode - Exact Ad Group
    this.setState({
      editModeExactAdGroup: false,
    });
  }

  // Exact AdGroup - User entering Default Bid within text input 
  onChange_DefaultBid_ExactAdGroup = (value) => {
  
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ editDefaultBidExactAdGroup: value, });
  }
  //-----------------------------------------------------------------
  // Start: Update Exact Ad Group Info
  //----------------------------------------------------------------- 


  //-----------------------------------------------------------------
  // Start: Rendering related functions
  //-----------------------------------------------------------------
  renderContent = () => {
    const { classes, flowDocId } = this.props;
    const { profile_id, profile_type, api_mode } = this.state.flowDataDb;
    const { isProcessing } = this.state;

    return(
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1" >
            Running Ad For: { profile_type } profile
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} >
          { this.renderContentAutoFlow() }
        </Grid>
        
        <Grid item xs={12} sm={8} >
          { this.renderContentManualFlow() }
        </Grid>

        { api_mode === 'DUMMY' && 
        <Grid item xs={12} sm={12}>
          <br /><br />
          <Typography variant="h5" >
            Note: DUMMY api mode used to create this flow.
            So more info can not fetch via amazon Ad api.
          </Typography>
        </Grid>
        }

        <Grid item xs={12} sm={12}>
          <br /><br />
        </Grid>

        { this.state.showDebug &&
        <Grid item xs={12} sm={3}>
          <Paper elevation={2} className={classes.paper}>
            { this.debugCommonInfo() }
          </Paper>
        </Grid>
        }

        { this.state.showDebug && 
        <Grid item xs={12} sm={3}> 
          <Paper elevation={2} className={classes.paper}>
            { this.debugEditField() }
          </Paper>
        </Grid>
        }

        { this.state.showDebug && 
        <Grid item xs={12} sm={3}> 
          <Paper elevation={2} className={classes.paper}>
            { this.debugEditField2() }
          </Paper>
        </Grid>
        }

        { this.state.showDebug && 
        <Grid item xs={12} sm={3}> 
          <Paper elevation={2} className={classes.paper}> 
            { this.debugCampaignAuto() }
            { this.debugCampaignManual() }
          </Paper>
        </Grid>
        } 

        { this.state.showDebug && 
        <Grid item xs={12} sm={12}>
          Product Ads Debug:
          { this.debugProductAds() }
        </Grid>
        }

        <br />
        <br /><br />
      </Grid>
    );
  }


  // Render all content related to automatic flow
  renderContentAutoFlow = () => {
    const { classes } = this.props;
    const { auto_ad_group_id } = this.state.flowDataDb;
    const { editModeAutoAdGroup, autoAdGroupChecked } = this.state;

    // highlight ad group if certain condition fulfill.
    let highlightAdGroup = false;
    if ( editModeAutoAdGroup ) { highlightAdGroup = true; } // If edit mode on for the ad group, hightlight it.
    if ( !auto_ad_group_id && autoAdGroupChecked ) { highlightAdGroup = true; } // If ad group not exist, but auto ad group checked by user.

    return(
      <React.Fragment>
        
        { this.renderCampaignInfo_Auto() }

        <div style={{ height: 16 }}></div>

        <Paper elevation={ highlightAdGroup ? 6 : 2 } className={classes.paper}>
          { this.renderAdGroupAuto() }

          { auto_ad_group_id && 
          <b><br />Negative Exact Keywords:</b>
          }
          { this.renderNegativeKeywordAuto() }
        </Paper>

      </React.Fragment>
    );
  }


  // Render auto campaign related basic info
  renderCampaignInfo_Auto = () => {
    const { classes } = this.props;
    const { autoAdGroupChecked, editModeAutoCampaign, isProcessing, isCreatingAutoCampaign } = this.state;
    const { auto_campaign_id } = this.state.flowDataDb;
    const campaign = this.getCampaignInfoFromId(auto_campaign_id);

    const isDisabled = isProcessing ? true : false ;

    // Highlight auto campaign box if certain condition fulfill.
    let highlightAutoCampaignBox = false;
    if ( !auto_campaign_id && autoAdGroupChecked ) { highlightAutoCampaignBox = true; } // i.e If auto campaign not exist and auto ad group checked


    // 1 - If auto campaign info not exist then show label, and campaign create save, cancel icon
    // if auto campaign creation checkbox is checked by the user.
    if (!auto_campaign_id || !campaign) {
      return(
        <Paper elevation={ highlightAutoCampaignBox ? 6 : 2 } className={classes.paper}>

          <Typography variant="h6" style={{ textAlign: 'center' }} >

            Automatic Campaign
            { // If auto campaign checked, i.e. use is trying to create the
            // auto campaign now. so we will show save cancel button to create 
            // auto campaign and auto ad group.
            !auto_campaign_id && autoAdGroupChecked &&
              <IconButton 
              size="small"
              aria-label="Create - Auto Campaign" 
              style={{ marginLeft: 10 }}
              onClick={ (e) => this.onClick_CreateAutoCampaign() }
              disabled={ isDisabled }
              title="Create Auto Campaign"
            >
              <SaveIcon fontSize="small" />
            </IconButton>
            }
            { // If auto campaign checked, i.e. use is trying to create the
            // auto campaign now. so we will show save cancel button to create 
            // auto campaign and auto ad group.
            !auto_campaign_id && autoAdGroupChecked &&
            <IconButton 
              size="small"
              aria-label="Cancel - Auto Campaign" 
              style={{ marginLeft: 10 }}
              onClick={ (e) => this.onClick_CancelAutoCampaign() }
              disabled={ isDisabled }
              title="Cancel"
            >
              <CancelIcon fontSize="small" />
            </IconButton>
            }

            { // If auto campaign creation in progress, then show progress indicator
              isCreatingAutoCampaign && 
              <CircularProgress size={16} style={{ marginLeft: 24 }} />
            }
          </Typography>

          { // Render auto campaign creation related input fields 
            // It will render input field to create auto campaign.
            // If auto campaign exist then it will not render field.
            this.renderAutoCampaignCreateField()
          }

        </Paper>
      ); 
    }

    // 3A - Edit Mode Not Active (auto campaign)
    // i.e. Show information
    if (!editModeAutoCampaign) {

      // Show end date expire related indication icon
      // - If 2 days remain before endDate expire then show grey icon.
      // - If endDate expired then show icon red color icon.
      const expiredBeforeDays = this.diffInDays_CurrentDate_And_YYYYMMDD(campaign.endDate);   // e.g. YYYYMMDD (string)
      //console.log('(Auto Campaign) expiredBeforeDays:', expiredBeforeDays);

      return(
        <Paper elevation={2} className={classes.paper} style={{ textAlign: 'center' }} >

          <Typography variant="h6" style={{ textAlign: 'center' }} >
            Automatic Campaign
            { auto_campaign_id && 
              <IconButton 
                size="small"
                aria-label="Edit - Auto Campaign" 
                style={{ marginLeft: 5 }}
                onClick={ (e) => this.onClickEdit_AutoCampaign(campaign) }
                disabled={ isDisabled }
              >
                <EditIcon fontSize="small" />
              </IconButton>
            }
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
            <Typography variant="subtitle1" >
              Daily Budget: ${ campaign.dailyBudget }
            </Typography>
            <FormControlLabel
              control={<IOSSwitch 
                checked={ campaign.state === 'enabled' ? true : false } 
                name="auto-campaign-state" 
                //disabled={true}
              />}
              //label={campaign.state}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
            <Typography variant="subtitle1" >
              Start Date: { this.convert_YYYYMMDD_YYYY_MM_DD(campaign.startDate) } 
            </Typography>
            <Typography variant="subtitle1" >
              End Date: { this.convert_YYYYMMDD_YYYY_MM_DD(campaign.endDate) } 
              { // If end date Expired within 2 days, show yellow icon
              (expiredBeforeDays >= -2 && expiredBeforeDays <= 0) &&
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 5, color: '#f0ad4e' }} title="Expire Soon" />
              }
              { // If end date expired then show red icon
              expiredBeforeDays >= 1 && 
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 5, color: '#FF0000' }} title="Expired" />
              }
            </Typography>
          </div>

        </Paper>
      );
    } // end - 3A

    // 3B - Edit Mode Active (auto campaign)
    // i.e. Show input fields
    if (editModeAutoCampaign) {
      return(
        <Paper elevation={6} className={classes.paper} style={{ textAlign: 'center' }} >
          
          <Typography variant="h6" style={{ textAlign: 'center' }} >
            Automatic Campaign
            <IconButton 
              size="small"
              aria-label="Save - Auto Campaign" 
              style={{ marginLeft: 10 }}
              onClick={ (e) => this.onClickEdit_SaveAutoCampaign(campaign) }
              disabled={ isDisabled }
              title="Save"
            >
              <SaveIcon fontSize="small" />
            </IconButton>
            <IconButton 
              size="small"
              aria-label="Cancel - Auto Campaign" 
              style={{ marginLeft: 10 }}
              onClick={ (e) => this.onClickEdit_CancelAutoCampaign(campaign) }
              disabled={ isDisabled }
              title="Cancel"
            >
              <CancelIcon fontSize="small" />
            </IconButton>
            { // If auto campaign update in progress, then show progress indicator
            this.state.isUpdatingAutoCampaign && 
            <CircularProgress size={16} style={{ marginLeft: 24 }} />
            }
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
            <TextField 
              variant="standard"
              id="edit-daily-budget-auto" 
              label="Daily Budget (Min. $1)" 
              //placeholder="" 
              value={ this.state.editDailyBudgetAuto } 
              onChange={ (e) => this.onChange_DailyBudget_AutoCampaign(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              //helperText="Minimum $5.0"
              style={{ marginTop: 10 }}
            />
            <FormControlLabel
              control={<IOSSwitch 
                checked={ this.state.editStateAuto === 'enabled' ? true : false } 
                onChange={ (event) => { 
                  this.setState({ editStateAuto: event.target.checked ? 'enabled' : 'paused' }) 
                }} 
                name="auto-campaign-state-edit" 
              />}
              disabled={ isDisabled }
              //label={this.state.editStateAuto}
            /> 
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
            <TextField
              variant="standard"
              id="edit-start-date-auto"
              label="Start Date"
              type="date"
              value={ this.state.editStartDateAuto }
              onChange={(e) => this.setState({ editStartDateAuto: e.target.value })}
              //className={classes.textField}
              InputLabelProps={{ shrink: true, }}
              disabled={ isDisabled }
              style={{ marginTop: 10 }}
            />
            <TextField
              variant="standard"
              id="edit-end-date-auto"
              label="End Date"
              type="date"
              value={ this.state.editEndDateAuto }
              onChange={(e) => this.setState({ editEndDateAuto: e.target.value })}
              //className={classes.textField}
              InputLabelProps={{ shrink: true, }}
              disabled={ isDisabled }
              style={{ marginTop: 10 }}
            />
          </div>

        </Paper>
      );
    } // end - 3B
  }


  // Render Auto Campaign create related input fields. e.g. daily budget, start-end date etc.
  // Note: If auto campaign not created during flow creation time, So in this case 
  // user will check Auto AdGroup checkbox to create auto ad group under auto campaign. But auto 
  // campaign not created then we have to ask auto campaign create related info also. So behind
  // the scene we will create auto campaign, then auto ad group and other entity etc.
  renderAutoCampaignCreateField = () => {

    //const { classes } = this.props;
    const { disableAutomaticCheckbox, autoAdGroupChecked, isProcessing } = this.state;
    const { auto_campaign_id, auto_ad_group_id } = this.state.flowDataDb;
    const isDisabled = isProcessing ? true : false ;

    // 1 - If auto campaign created ( auto_campaign_id found in our db) then do not show auto 
    // campaign create related input field, because no need to create auto campaign.
    if ( auto_campaign_id && auto_campaign_id !== '' && auto_campaign_id !== null ) { return; }

    // 2 - If auto adgroup checkbox disabled (i.e. Auto AdGroup exist) then do not render auto
    // campaign create related input field, because auto ad Group created it means campaign also 
    // created during auto flow creation. So no need to show auto campaign create related field again.
    if ( disableAutomaticCheckbox ) { return null; }
    
    // 3 - If auto ad group not checked by user then do not show auto campaign create related field.
    if ( !autoAdGroupChecked ) { return null; }


    return(
      <React.Fragment>

        <TextField 
          variant="standard"
          id="campaign-budget-automatic" 
          label="Campaign Daily Budget (Min. $5.0)"
          placeholder="enter budget"
          value={ this.state.campaignBudgetAutomatic } 
          onChange={ (e) => this.onChangeCampaignBudgetAutomatic(e.target.value) } 
          size="small" 
          disabled={ isDisabled } 
          InputLabelProps={{ shrink: true, }} 
          InputProps={{ 
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          //helperText="Minimum $5.0"
          style={{ marginTop: 10, minWidth: 260 }}
        />
        
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
          <TextField
            variant="standard"
            id="start-date-auto"
            label="Campaign Start Date"
            type="date"
            value={ this.state.startDate }
            onChange={(e) => this.setState({ startDate: e.target.value })}
            //className={classes.textField}
            InputLabelProps={{ shrink: true, }}
            disabled={ isDisabled }
            style={{ marginTop: 10,  }}
          />
          <TextField
            variant="standard"
            id="end-date-auto"
            label="Campaign End Date"
            type="date"
            value={ this.state.endDate }
            onChange={(e) => this.setState({ endDate: e.target.value })}
            //className={classes.textField}
            InputLabelProps={{ shrink: true, }}
            disabled={ isDisabled }
            style={{ marginTop: 10 }}
          />
        </div>

      </React.Fragment>
    );
  }


  // Render ad group info (for Auto flow)
  renderAdGroupAuto = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { auto_campaign_id, auto_ad_group_id } = this.state.flowDataDb;
    const { autoAdGroupChecked, bidAmountAutomatic, editModeAutoAdGroup,
            showDebug, isProcessing, isCreatingAutoAdGroup, isCreatingAutoCampaign,
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group info if loaded via amazon api
    const adGroupInfo = this.getAdGroupInfoFromId(auto_ad_group_id);

    // 3A - If Ad Group Not created yet then render check box, so user can select it to 
    // create ad group. We will show fields related to create new campaign and ad group.
    if (!auto_ad_group_id) {

      // We have to enable Auto AdGroup checkbox once keyword, negative keyword, product ad list fetched via amazon api
      const { keywordListFetched, negativeKeywordListFetched, productAdListFetched } = this.state;

      return(
        <React.Fragment>

          { // If keyword, negative keyword, or product ad list Not fetched then show label
          ( !keywordListFetched || !negativeKeywordListFetched || !productAdListFetched ) &&
            <FormLabel >Auto Ad Group</FormLabel>
          }

          { // If keyword, negative keyword, and product ad list fetched then show checkbox, otherwise not.
          ( keywordListFetched && negativeKeywordListFetched && productAdListFetched ) &&
          <FormControlLabel
            control={ 
                    <Checkbox
                      color="primary" 
                      checked={this.state.autoAdGroupChecked} 
                      onChange={ (e) => { this.handleChangeAutomatic(e.target.checked) } }
                      name="automatic"
                      disabled={ isDisabled }
                    />
                    }
            label="Auto Ad Group"
          />
          }

          { // If user will check automatic ad group checkbox, it means user  
            // is willing to create automatic campaign + ad Group now, so we will show 
            // automatic campaign creation related input element.
            autoAdGroupChecked &&
          <React.Fragment>
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
              <TextField 
                variant="outlined"
                id="bid-amount-automatic" 
                label="Default Bid Amount" 
                value={ bidAmountAutomatic } 
                onChange={ (e) => this.onChangeBidAmountAutomatic(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                helperText="Minimum $0.02"
                //style={{ marginRight: 10 }}
              />

              { // If auto Campaign created then show save/cancel button near this
              // because in this case we will allow user create auto Ad group onwards entity.
              auto_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Create - Auto Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CreateAutoAdGroup() }
                  disabled={ isDisabled }
                  title="Create Auto Ad Group"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
              }
              { // If auto Campaign created then show save/cancel button near this
              // because in this case we will allow user create auto Ad group onwards entity.
              auto_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Cancel - Auto Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CancelAutoAdGroup() }
                  disabled={ isDisabled }
                  title="Cancel"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              }

              { // If auto ad group creation in progress, then show progress indicator
                isCreatingAutoAdGroup && 
                <CircularProgress size={16} style={{ marginLeft: 24, marginTop: 10 }} />
              }
            </div>
            
            <div style={{ marginTop: 10, marginBottom: 5 }} >
              Please remember to set the same End Date for Manual Campaign to get the 
              full optimization benefit.
            </div>

            { // If keyword hint array exist then show it
            this.state.hintNegativeKeywordAuto.length > 0 && 
            <div style={{ marginTop: 10 }} >
              Below keywords will be added as <b>Negative Exact</b> match.
              <br />
              {  
                this.state.hintNegativeKeywordAuto.map((item, index) => { 
                  return <div key={'hint-auto-negative' + index} ><i>{item}</i><br /></div>
                })
              }
            </div>
            }
          </React.Fragment> 
          }

          { showDebug && 
            <FormHelperText>
              <br />
              Debug: <br />
              AdGroup Id (db): { auto_ad_group_id } <br />
              name: { adGroupInfo && adGroupInfo.name } <br />
              campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
              defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
              state: { adGroupInfo && adGroupInfo.state } <br />
            </FormHelperText>
          }
        </React.Fragment>
      );
    } // end - 3A


    // 3B - If Ad Group exist then show AdGroup info with edit icon.
    if (auto_ad_group_id) {
      return(
        <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
            <FormControlLabel
              control={<Checkbox
                        color="primary" 
                        checked={true} 
                        name="automatic"
                        disabled={ true }
                      />}
              label="Auto Ad Group"
            />
            { // Edit mode off (Show Edit icon)
            adGroupInfo && !editModeAutoAdGroup &&
              <IconButton 
                  size="small"
                  aria-label="Edit Ad Group - Auto Campaign" 
                  style={{ marginLeft: 0 }}
                  onClick={ (e) => this.onClickEdit_AutoAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                >
                <EditIcon fontSize="small" />
              </IconButton>
            } 
            { // Edit mode on (Show Save Cancel icon)
            editModeAutoAdGroup &&
            <div>
              <IconButton 
                size="small"
                aria-label="Save - Auto Campaign" 
                style={{ marginLeft: 10 }}
                onClick={ (e) => this.onClickEdit_SaveAutoAdGroup(adGroupInfo) }
                disabled={ isDisabled }
                title="Save"
              >
                <SaveIcon fontSize="small" />
              </IconButton>
              <IconButton 
                size="small"
                aria-label="Cancel - Auto Campaign" 
                style={{ marginLeft: 10 }}
                onClick={ (e) => this.onClickEdit_CancelAutoAdGroup(adGroupInfo) }
                disabled={ isDisabled }
                title="Cancel"
              >
                <CancelIcon fontSize="small" />
              </IconButton>
              { // If auto ad group update in progress, then show progress indicator
              this.state.isUpdatingAutoAdGroup && 
                <CircularProgress size={16} style={{ marginLeft: 24 }} />
              }
            </div>
            }
          </div>

          { // Edit mode off (Show info)
          adGroupInfo && !editModeAutoAdGroup &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <Typography variant="body2" >
              Default Bid: ${ adGroupInfo.defaultBid }
            </Typography>
            <FormControlLabel
              control={<IOSSwitch 
                checked={ adGroupInfo.state === 'enabled' ? true : false } 
                name="auto-adgroup-state" 
                //disabled={true}
              />}
              //label={adGroupInfo.state}
            />
          </div>
          }

          { // Edit mode On (Show input controls)
          editModeAutoAdGroup && 
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <TextField 
              variant="standard"
              id="edit-defaultbid-auto" 
              label="Default Bid (Min. $0.02)" 
              //placeholder="" 
              value={ this.state.editDefaultBidAutoAdGroup } 
              onChange={ (e) => this.onChange_DefaultBid_AutoAdGroup(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              //helperText="Min. $0.02"
              //style={{ maxWidth: 150 }}
            /> 
            <FormControlLabel
              control={<IOSSwitch 
                checked={ this.state.editStateAutoAdGroup === 'enabled' ? true : false } 
                onChange={ (event) => { 
                  this.setState({ editStateAutoAdGroup: event.target.checked ? 'enabled' : 'paused' }) 
                }} 
                name="auto-adgroup-state-edit" 
              />}
              disabled={ isDisabled }
              //label={this.state.editStateAutoAdGroup}
            /> 
          </div>
          }

          { showDebug && 
            <FormHelperText>
              Debug: <br />
              AdGroup Id (db): { auto_ad_group_id } <br />
              name: { adGroupInfo && adGroupInfo.name } <br />
              campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
              defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
              state: { adGroupInfo && adGroupInfo.state } <br />
            </FormHelperText>
          }

        </React.Fragment>
      );
    } // end - 3B

  }

  // Render ui to show negative keywords (for Auto flow)
  renderNegativeKeywordAuto = () => {
    
    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing } = this.state;
    if (negativeKeywordListApi.length === 0 ) { return null; }
    
    // 2 - Prepare array that consist negative keyword belongs to auto_campaign_id
    const { auto_campaign_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if (item.campaignId === auto_campaign_id) { return true; } else { return false; }
    });
    //console.log('(Auto) Negative keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'negative-keyword-auto-' + index } >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4 }} >
          </TableCell>
        </TableRow>
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Negative Exact keywords - Auto Campaign">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    ); 

  }



  // Render all content related to manual flow
  renderContentManualFlow = () => {
    const { classes } = this.props;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id  } = this.state.flowDataDb;
    const { editModeBroadAdGroup, editModePhraseAdGroup, editModeExactAdGroup } = this.state;
    const { broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked } = this.state;

    // Highlight Broad ad group if certain condition fulfill.
    let highlightBroadAdGroup = false;
    if ( editModeBroadAdGroup ) { highlightBroadAdGroup = true; } // edit mode on for broad ad group
    if ( !manual_broad_ad_group_id && broadAdGroupChecked ) { highlightBroadAdGroup = true; } // Broad ad group not exist, and user checked it to create.
    
    // Highlight Phrase ad group if certain condition fulfill.
    let highlightPhraseAdGroup = false;
    if ( editModePhraseAdGroup ) { highlightPhraseAdGroup = true; } // edit mode on for phrase ad group
    if ( !manual_phrase_ad_group_id && phraseAdGroupChecked ) { highlightPhraseAdGroup = true; } // Phrase ad group not exist, and user checked it to create.

    // Highlight exact ad group if certain condition fulfill.
    let highlightExactAdGroup = false;
    if ( editModeExactAdGroup ) { highlightExactAdGroup = true; } // edit mode on for phrase ad group
    if ( !manual_exact_ad_group_id && exactAdGroupChecked ) { highlightExactAdGroup = true; } // Exact ad group not exist, and user checked it to create.

    return (
      <React.Fragment>
        
        { this.renderCampaignInfo_Manual() }

        <div style={{ height: 16 }}></div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} >
            <Paper elevation={ highlightBroadAdGroup ? 6 : 2 } className={classes.paper}>
              { this.renderAdGroup_ManualBroad() }

              { manual_broad_ad_group_id && 
              <b><br />Broad Keywords:</b>
              }
              { this.renderKeyword_ManualBroad() }

              { manual_broad_ad_group_id && 
              <b><br />Negative Exact Keywords:</b>
              }
              { this.renderNegativeKeyword_ManualBroad() } 
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} >
            <Paper elevation={ highlightPhraseAdGroup ? 6 : 2} className={classes.paper}>
              { this.renderAdGroup_ManualPhrase() }

              { manual_phrase_ad_group_id && 
                <b><br />Phrase Keywords:</b>
              } 
              { this.renderKeyword_ManualPhrase() }
              
              { manual_phrase_ad_group_id && 
                <b><br />Negative Exact Keywords:</b> 
              }
              { this.renderNegativeKeyword_ManualPhrase() }
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} >
            <Paper elevation={ highlightExactAdGroup ? 6: 2 } className={classes.paper}>
              { this.renderAdGroup_ManualExact() }
              
              { manual_exact_ad_group_id && 
              <b><br />Exact Keywords:</b>
              }
              { this.renderKeyword_ManualExact() }
            </Paper>
          </Grid>
        </Grid>

        <div style={{ height: 16 }}></div>

      </React.Fragment>
    );
  }


  // Render manual campaign related basic info
  renderCampaignInfo_Manual = () => {
    const { classes } = this.props;
    const { editModeManualCampaign, isProcessing, isCreatingManualCampaign } = this.state;
    const { broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked } = this.state;
    const { manual_campaign_id } = this.state.flowDataDb;
    const campaign = this.getCampaignInfoFromId(manual_campaign_id);

    const isDisabled = isProcessing ? true : false ;

    // Highlight manual campaign box if certain condition fulfill.
    // i.e If manual campaign not exist AND (Broad/Phrase/Exact) ad group checked then highlight.
    let highlightManualCampaignBox = false;
    if ( !manual_campaign_id && (broadAdGroupChecked || phraseAdGroupChecked || exactAdGroupChecked) ) { 
      highlightManualCampaignBox = true; 
    } 


    // 1 - If manual campaign not exist then show label and campaign Create(save)/cancel button etc.
    // i.e. manual campaign not created yet and user will check Broad/Phrase/Exact AdGroup
    // checkbox, it means we need to create manual campaign and ad group together, so we will 
    // put campaign Create(save)/Cancel button near Manual campaign label, and we will
    // also show manual campaign create related input fields in this box (those fields will 
    // be used to create manual campaign and thereafter we will create adGroup under that campaign)
    if (!manual_campaign_id || !campaign) {
      return(
        <Paper elevation={ highlightManualCampaignBox ? 6 : 2 } className={classes.paper}>

          <Typography variant="h6" style={{ textAlign: 'center' }} >
            Manual Campaign
            { // If any ad group checked (broad, phrase, exact). i.e. user is trying to create the
            // manual campaign with selected ad group. so we will show save/cancel button to create 
            // manual campaign and checked (broad, phrase, exact) adgroup.
            !manual_campaign_id && (broadAdGroupChecked || phraseAdGroupChecked || exactAdGroupChecked) &&
            <React.Fragment>
              <IconButton 
                size="small"
                aria-label="Create - Manual Campaign" 
                style={{ marginLeft: 10 }}
                onClick={ (e) => this.onClick_CreateManualCampaign() }
                disabled={ isDisabled }
                title="Create Manual Campaign"
              >
                <SaveIcon fontSize="small" />
              </IconButton>
              <IconButton 
                size="small"
                aria-label="Cancel - Manual Campaign" 
                style={{ marginLeft: 10 }}
                onClick={ (e) => this.onClick_CancelManualCampaign() }
                disabled={ isDisabled }
                title="Cancel Manual Campaign"
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
            }

            { // If manual campaign creation in progress, then show progress indicator
              isCreatingManualCampaign && 
              <CircularProgress size={16} style={{ marginLeft: 24 }} />
            }
          </Typography>

          { // Render manual campaign creation related input fields 
            // It will render input field to create manual campaign.
            this.renderManualCampaignCreateField() 
          }
        </Paper>
      ); 
    }

    // 2A - Edit Mode Not Active (manual campaign)
    // i.e Manual campaign exist and edit mode Not Active for manual campaign.
    // i.e. Show manual campaign information (no input/edit fields)
    if (!editModeManualCampaign) { 

      // Show end date expire related indication icon
      // - If 2 days remain before endDate expire then show grey icon.
      // - If endDate expired then show icon red color icon.
      const expiredBeforeDays = this.diffInDays_CurrentDate_And_YYYYMMDD(campaign.endDate);   // e.g. YYYYMMDD (string)
      //console.log('(Manual Campaign) expiredBeforeDays:', expiredBeforeDays);

      return(
        <Paper elevation={2} className={classes.paper} style={{ textAlign: 'center' }} >
          
          <Typography variant="h6" style={{ textAlign: 'center' }} >
            Manual Campaign
            { manual_campaign_id && 
              <IconButton 
                size="small"
                aria-label="Edit - Manual Campaign" 
                style={{ marginLeft: 5 }}
                onClick={ (e) => this.onClickEdit_ManualCampaign(campaign) }
                disabled={ isDisabled }
              >
                <EditIcon fontSize="small" />
              </IconButton>
            }
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
            <Typography variant="subtitle1" >
              Daily Budget: ${ campaign.dailyBudget }
            </Typography>
            <Typography variant="subtitle1" >
              Start Date: { this.convert_YYYYMMDD_YYYY_MM_DD(campaign.startDate) } 
            </Typography>
            <Typography variant="subtitle1" >
              End Date: { this.convert_YYYYMMDD_YYYY_MM_DD(campaign.endDate) } 
              { // If end date Expired within 2 days then show yellow icon
              (expiredBeforeDays >= -2 && expiredBeforeDays <= 0) &&
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 5, color: '#f0ad4e' }} color="action" title="Expire Soon" />
              }
              { // If end date expired then show red icon
              expiredBeforeDays >= 1 && 
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 5, color: '#FF0000' }} title="Expired" />
              }
            </Typography>
            <FormControlLabel
              control={<IOSSwitch 
                checked={ campaign.state === 'enabled' ? true : false } 
                name="manual-campaign-state" 
                //disabled={true}
              />}
              //label={campaign.state}
            /> 
          </div>

        </Paper>
      );
    } // end - 2A

    // 2B - Edit Mode Active (manual campaign)
    // i.e. Manual campaign exist and edit mode Active for manual campaign.
    // i.e. Show input fields to edit campaign information.
    if (editModeManualCampaign) {
      return(
      <Paper elevation={6} className={classes.paper} style={{ textAlign: 'center' }} >

        <Typography variant="h6" style={{ textAlign: 'center' }} >
          Manual Campaign
          <IconButton 
            size="small"
            aria-label="Save - Manual Campaign" 
            style={{ marginLeft: 10 }}
            onClick={ (e) => this.onClickEdit_SaveManualCampaign(campaign) }
            disabled={ isDisabled }
            title="Save"
          >
            <SaveIcon fontSize="small" />
          </IconButton>
          <IconButton 
            size="small"
            aria-label="Cancel - Manual Campaign" 
            style={{ marginLeft: 10 }}
            onClick={ (e) => this.onClickEdit_CancelManualCampaign(campaign) }
            disabled={ isDisabled }
            title="Cancel"
          >
            <CancelIcon fontSize="small" />
          </IconButton>
          { // If manual campaign update in progress, then show progress indicator
            this.state.isUpdatingManualCampaign && 
            <CircularProgress size={16} style={{ marginLeft: 24 }} />
          }
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }} >
          <TextField 
            variant='standard'
            id="edit-daily-budget-manual" 
            label="Daily Budget (Min. $1)" 
            //placeholder="" 
            value={ this.state.editDailyBudgetManual } 
            onChange={ (e) => this.onChange_DailyBudget_ManualCampaign(e.target.value) } 
            size="small" 
            disabled={ isDisabled } 
            InputLabelProps={{ shrink: true, }} 
            InputProps={{ 
              startAdornment: <InputAdornment position="start">$</InputAdornment>, 
            }}
            //helperText="Minimum $5.0"
            //style={{ maxWidth: 150 }}
          />
          <TextField
            variant="standard"
            id="edit-start-date-manual"
            label="Start Date"
            type="date"
            value={ this.state.editStartDateManual }
            onChange={(e) => this.setState({ editStartDateManual: e.target.value })}
            //className={classes.textField}
            InputLabelProps={{ shrink: true, }}
            disabled={ isDisabled }
            //style={{ marginRight: 20 }}
          />
          <TextField
            variant="standard"
            id="edit-end-date-manual"
            label="End Date"
            type="date"
            value={ this.state.editEndDateManual }
            onChange={(e) => this.setState({ editEndDateManual: e.target.value })}
            //className={classes.textField}
            InputLabelProps={{ shrink: true, }}
            disabled={ isDisabled }
            //style={{ marginRight: 20 }}
          />
          <FormControlLabel
            control={<IOSSwitch 
              checked={ this.state.editStateManual === 'enabled' ? true : false } 
              onChange={ (event) => { 
                this.setState({ editStateManual: event.target.checked ? 'enabled' : 'paused' }) 
              }} 
              name="manual-campaign-state-edit" 
            />}
            disabled={ isDisabled }
            //label={this.state.editStateManual}
          /> 
        </div>

      </Paper>
      );
    } // end - 2B

  }


  // Render input field related to manual campaign creation. e.g. daily budget, start-end date etc.
  // If manual campaign not created flow creation time, then any ad group (broad/phrase/exact) 
  // also not exist under the manual campaign.
  // So if user will check any checkbox (Broad/Phrase/Exact Ad Group) to create ad group under 
  // manual campaign then we will ask manual campaign create related information also, so behind
  // the scene we can create manual campaign and create ad group under that.
  renderManualCampaignCreateField = () => {
    const { classes } = this.props;

    const { isProcessing, 
            disableBroadAdGroupCheckbox, disablePhraseAdGroupCheckbox, disableExactAdGroupCheckbox,
            broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked, 
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 1 - If any one checkbox disabled (broad or phrase or exact) then do not render manual 
    // campaign create related input field, because campaign was already created during flow 
    // creation so no need to ask campaign create related field.
    if ( disableBroadAdGroupCheckbox || disablePhraseAdGroupCheckbox || disableExactAdGroupCheckbox ) {
      return null;
    }
    
    // 2 - If any ad group not checked by user (broad, phrase, exact) then do not show campaign create related field.
    if ( !broadAdGroupChecked && !phraseAdGroupChecked && !exactAdGroupChecked ) {
      return null;
    }

    // 3 - If atleast one ad group checked by user (broad/phrase/exact) then show campaign create related input field.
    // Note: We have to highlight the box while we need to create manual campaign. Below fields 
    // are used for manual campaign creation purpose so we will highlight this box
    return(
          <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
            <TextField 
              //variant="outlined"
              id="campaign-budget-manual" 
              label="Campaign Daily Budget (Min. $5.0)" 
              placeholder="enter budget" 
              value={ this.state.campaignBudgetManual } 
              onChange={ (e) => this.onChangeCampaignBudgetManual(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              //helperText="Minimum $5.0"
              style={{ minWidth: 280, marginRight: 20 }}
            />
            <TextField
              //variant="outlined"
              id="start-date-manual"
              label="Campaign Start Date"
              type="date"
              value={ this.state.startDate }
              onChange={(e) => this.setState({ startDate: e.target.value })}
              //className={classes.textField}
              InputLabelProps={{ shrink: true, }}
              disabled={ isDisabled }
              style={{ marginRight: 20 }}
            />
            <TextField
              //variant="outlined"
              id="end-date-manual"
              label="Campaign End Date"
              type="date"
              value={ this.state.endDate }
              onChange={(e) => this.setState({ endDate: e.target.value })}
              //className={classes.textField}
              InputLabelProps={{ shrink: true, }}
              disabled={ isDisabled }
            />
          </div>
    );
  }

  // Render ad group info - Manual Broad
  renderAdGroup_ManualBroad = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { manual_campaign_id, manual_broad_ad_group_id } = this.state.flowDataDb;
    const { showDebug, isProcessing, isCreatingBroadAdGroup,
            broadAdGroupChecked,
            editModeBroadAdGroup,
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group info if loaded via amazon api.
    const adGroupInfo = this.getAdGroupInfoFromId(manual_broad_ad_group_id);

    // 3A - If Ad Group Not created yet then render check box, so user can select it to 
    // create ad group. We will show fields related to create new ad group.
    if (!manual_broad_ad_group_id) {

      // We have to enable Broad AdGroup checkbox once keyword, negative keyword, product ad list fetched via amazon api
      const { keywordListFetched, negativeKeywordListFetched, productAdListFetched } = this.state;

      return (
        <React.Fragment>

          { // If keyword, negative keyword, or product ad list Not fetched then show label
          ( !keywordListFetched || !negativeKeywordListFetched || !productAdListFetched ) &&
            <FormLabel >Broad Ad Group</FormLabel>
          }

          { // If keyword, negative keyword, and product ad list fetched then show checkbox, otherwise not.
          ( keywordListFetched && negativeKeywordListFetched && productAdListFetched ) &&
          <FormControlLabel
            control={
              <Checkbox 
                color="primary" 
                checked={broadAdGroupChecked} 
                onChange={ (e) => this.handleChangeBroadAdGroup(e.target.checked)} 
                name="broad-ad-group" 
                disabled={ isProcessing }
              />
            }
            label="Broad Ad Group"
          />
          }
          
          { // If user will check broad adgroup checkbox, it means user  
            // is willing to create Broad Ad Group now, so we will show 
            // Broad Ad Group creation related input element.
          broadAdGroupChecked &&
          <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
              <TextField 
                variant="outlined"
                id="bid-amount-broad" 
                label="Default Bid Amount" 
                value={ this.state.bidAmountBroad } 
                onChange={ (e) => this.onChangeBidAmountBroad(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                helperText="Minimum $0.02"
                style={{ maxWidth: 150 }}
              />
              { // If manual Campaign created then show save/cancel button near this
              // Because in this case we will allow user create individual Ad group.
              manual_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Create - Broad Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CreateBroadAdGroup() }
                  disabled={ isDisabled }
                  title="Create Broad Ad Group"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
              }
              { // If manual Campaign created then show save/cancel button near this
              // Because in this case we will allow user create individual Ad group.
              manual_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Cancel - Broad Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CancelBroadAdGroup() }
                  disabled={ isDisabled }
                  title="Cancel"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              }
              { // If broad ad group creation in progress, then show progress indicator
                isCreatingBroadAdGroup && 
                <CircularProgress size={16} style={{ marginLeft: 24, marginTop: 10 }} />
              }
            </div>

            { // If keyword hint array exist then show it
            this.state.hintKeywordBroad.length > 0 && 
            <div style={{ marginTop: 10, marginBottom: 10 }} >
              Below keywords will be added as <b>Broad</b> match.
              <br />
              {  
                this.state.hintKeywordBroad.map((item, index) => { 
                  return <div key={'hint-phrase' + index} ><i>{item}</i><br /></div>
                })
              }
            </div>
            }
            { // If keyword hint array exist then show it
            this.state.hintNegativeKeywordBroad.length > 0 && 
            <div style={{ marginTop: 10 }} >
              Below keywords will be added as <b>Negative Exact</b> match.
              <br />
              {  
                this.state.hintNegativeKeywordBroad.map((item, index) => { 
                  return <div key={'hint-phrase-negative' + index} ><i>{item}</i><br /></div>
                })
              }
            </div>
            }
          </React.Fragment>
          }

          { showDebug && 
          <FormHelperText>
            <br />
            Debug: <br />
            AdGroup Id (db): { manual_broad_ad_group_id } <br />
            name: { adGroupInfo && adGroupInfo.name } <br />
            campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
            defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
            state: { adGroupInfo && adGroupInfo.state } <br />
          </FormHelperText>
          }

        </React.Fragment>
      ); 
    } // end - 3A

    
    // 3B - If Ad Group exist then show AdGroup info with edit icon.
    if (manual_broad_ad_group_id) { 
      return(
        <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
            <FormControlLabel
              control={<Checkbox
                        color="primary" 
                        checked={true} 
                        name="automatic"
                        disabled={ true }
                      />}
              label="Broad Ad Group"
            />
            
            { // Edit mode off (Show Edit icon)
            adGroupInfo && !editModeBroadAdGroup &&
              <IconButton 
                  size="small"
                  aria-label="Edit Broad Group" 
                  style={{ marginLeft: 0 }}
                  onClick={ (e) => this.onClickEdit_BroadAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                >
                <EditIcon fontSize="small" />
              </IconButton>
            } 

            { // Edit mode on (Show Save Cancel icon)
            editModeBroadAdGroup &&
              <div>
                <IconButton 
                  size="small"
                  aria-label="Save - Broad Ad Group" 
                  style={{ marginLeft: 10 }}
                  onClick={ (e) => this.onClickEdit_SaveBroadAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                  title="Save"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small"
                  aria-label="Cancel - Broad Ad Group" 
                  style={{ marginLeft: 10 }}
                  onClick={ (e) => this.onClickEdit_CancelBroadAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                  title="Cancel"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
                { // If broad ad group update in progress, then show progress indicator
                this.state.isUpdatingBroadAdGroup && 
                  <CircularProgress size={16} style={{ marginLeft: 4 }} />
                }
              </div>
            }
          </div>

          { // Edit mode off (Show info)
          adGroupInfo && !editModeBroadAdGroup &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <Typography variant="body2" >
              Default Bid: ${ adGroupInfo.defaultBid }
            </Typography>
            <FormControlLabel
              control={<IOSSwitch 
                checked={ adGroupInfo.state === 'enabled' ? true : false } 
                name="broad-adgroup-state" 
                //disabled={true}
              />}
              //label={ adGroupInfo.state }
            />
          </div>
          }

          { // Edit mode On (Show input controls)
          editModeBroadAdGroup && 
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <TextField 
              variant="standard"
              id="edit-defaultbid-broad" 
              label="Default Bid" 
              //placeholder="" 
              value={ this.state.editDefaultBidBroadAdGroup } 
              onChange={ (e) => this.onChange_DefaultBid_BroadAdGroup(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              helperText="Minimum $0.02"
              style={{ marginRight: 10, maxWidth: 120 }}
            /> 
            <FormControlLabel
              control={<IOSSwitch 
                checked={ this.state.editStateBroadAdGroup === 'enabled' ? true : false } 
                onChange={ (event) => { 
                  this.setState({ editStateBroadAdGroup: event.target.checked ? 'enabled' : 'paused' }) 
                }} 
                name="broad-adgroup-state-edit" 
              />}
              disabled={ isDisabled }
              //label={this.state.editStateBroadAdGroup}
            /> 
          </div>
          }

          { showDebug && 
          <FormHelperText>
            <br />
            Debug: <br />
            AdGroup Id (db): { manual_broad_ad_group_id } <br />
            name: { adGroupInfo && adGroupInfo.name } <br />
            campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
            defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
            state: { adGroupInfo && adGroupInfo.state } <br />
          </FormHelperText>
          }
        </React.Fragment>
      );
    } // end - 3B
  }


  // Render ad group info - manual Phrase
  renderAdGroup_ManualPhrase = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { manual_campaign_id, manual_phrase_ad_group_id } = this.state.flowDataDb;
    const { showDebug, isProcessing, isCreatingPhraseAdGroup,
            phraseAdGroupChecked,
            editModePhraseAdGroup,
          } = this.state;

    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group name state if loaded via amazon api.
    const adGroupInfo = this.getAdGroupInfoFromId(manual_phrase_ad_group_id);

    // 3A - If Ad Group Not created yet then render check box, so user can select it to 
    // create ad group. We will show fields related to create new ad group.
    if (!manual_phrase_ad_group_id) {

      // We have to enable Phrase AdGroup checkbox once keyword, negative keyword, and product ad list fetched via amazon api
      const { keywordListFetched, negativeKeywordListFetched, productAdListFetched } = this.state;

      return (
        <React.Fragment>

          { // If keyword, negative keyword, or product ad list Not fetched then show label
          ( !keywordListFetched || !negativeKeywordListFetched || !productAdListFetched ) &&
            <FormLabel >Phrase Ad Group</FormLabel>
          }

          { // If keyword, negative keyword, and product list fetched then show checkbox, otherwise not.
          ( keywordListFetched && negativeKeywordListFetched && productAdListFetched ) &&
          <FormControlLabel
            control={
              <Checkbox 
                color="primary" 
                checked={phraseAdGroupChecked} 
                onChange={ (e) => this.handleChangePhraseAdGroup(e.target.checked)} 
                name="phrase-ad-group" 
                disabled={ isProcessing }
              />
            }
            label="Phrase Ad Group"
          />
          }
          
          { // If user will check Phrase adgroup checkbox, it means user  
            // is willing to create Phrase Ad Group now, so we will show 
            // Phrase Ad Group creation related input element.
          phraseAdGroupChecked &&
          <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }} >  
              <TextField 
                variant="outlined"
                id="bid-amount-phrase" 
                label="Default Bid Amount" 
                value={ this.state.bidAmountPhrase } 
                onChange={ (e) => this.onChangeBidAmountPhrase(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                helperText="Minimum $0.02"
                style={{ maxWidth: 150 }}
              />
              { // If manual Campaign created then show save/cancel button near this
              // Because in this case we will allow user create individual Ad group.
              manual_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Create - Phrase Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CreatePhraseAdGroup() }
                  disabled={ isDisabled }
                  title="Create Phrase Ad Group"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
              }
              { // If manual Campaign created then show save/cancel button near this
              // Because in this case we will allow user create individual Ad group.
              manual_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Cancel - Broad Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CancelPhraseAdGroup() }
                  disabled={ isDisabled }
                  title="Cancel"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              }
              { // If Phrase ad group creation in progress, then show progress indicator
                isCreatingPhraseAdGroup && 
                <CircularProgress size={16} style={{ marginLeft: 24, marginTop: 10 }} />
              }
            </div>

            { // If keyword hint array exist then show it
            this.state.hintKeywordPhrase.length > 0 && 
            <div style={{ marginTop: 10, marginBottom: 10 }} >
              Below keywords will be added as <b>Phrase</b> match.
              <br />
              {  
                this.state.hintKeywordPhrase.map((item, index) => { 
                  return <div key={'hint-phrase' + index} ><i>{item}</i><br /></div>
                })
              }
            </div>
            }
            { // If keyword hint array exist then show it
            this.state.hintNegativeKeywordPhrase.length > 0 && 
            <div style={{ marginTop: 10 }} >
              Below keywords will be added as <b>Negative Exact</b> match.
              <br />
              {  
                this.state.hintNegativeKeywordPhrase.map((item, index) => { 
                  return <div key={'hint-phrase-negative' + index} ><i>{item}</i><br /></div>
                })
              }
            </div>
            }
          </React.Fragment>
          }

          { showDebug && 
          <FormHelperText>
            <br />
            Debug: <br />
            AdGroup Id (db): { manual_phrase_ad_group_id } <br />
            name: { adGroupInfo && adGroupInfo.name } <br />
            campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
            defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
            state: { adGroupInfo && adGroupInfo.state } <br />
          </FormHelperText>
          }

        </React.Fragment>
      ); 
    } // end - 3A

    // 3B - If Ad Group exist then show AdGroup info with edit icon.
    if (manual_phrase_ad_group_id) { 
      return(
        <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
            <FormControlLabel
              control={<Checkbox
                        color="primary" 
                        checked={true} 
                        name="automatic"
                        disabled={ true }
                      />}
              label="Phrase Ad Group"
            />

            { // Edit mode off (Show Edit icon)
            adGroupInfo && !editModePhraseAdGroup &&
              <IconButton 
                  size="small"
                  aria-label="Edit Phrase Group" 
                  style={{ marginLeft: 0 }}
                  onClick={ (e) => this.onClickEdit_PhraseAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                >
                <EditIcon fontSize="small" />
              </IconButton>
            } 

            { // Edit mode on (Show Save Cancel icon)
            editModePhraseAdGroup &&
              <div>
                <IconButton 
                  size="small"
                  aria-label="Save - Phrase Ad Group" 
                  style={{ marginLeft: 10 }}
                  onClick={ (e) => this.onClickEdit_SavePhraseAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                  title="Save"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small"
                  aria-label="Cancel - Phrase Ad Group" 
                  style={{ marginLeft: 10 }}
                  onClick={ (e) => this.onClickEdit_CancelPhraseAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                  title="Cancel"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
                { // If phrase ad group update in progress, then show progress indicator
                this.state.isUpdatingPhraseAdGroup && 
                  <CircularProgress size={16} style={{ marginLeft: 4 }} />
                }
              </div>
            }
          </div>

          { // Edit mode off (Show info)
          adGroupInfo && !editModePhraseAdGroup &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <Typography variant="body2" >
              Default Bid: ${ adGroupInfo.defaultBid }
            </Typography>
            <FormControlLabel
              control={<IOSSwitch 
                checked={ adGroupInfo.state === 'enabled' ? true : false } 
                name="phrase-adgroup-state" 
                //disabled={true}
              />}
              //label={ adGroupInfo.state }
            />
          </div>
          }

          { // Edit mode On (Show input controls)
          editModePhraseAdGroup && 
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <TextField 
              variant="standard"
              id="edit-defaultbid-phrase" 
              label="Default Bid" 
              //placeholder="" 
              value={ this.state.editDefaultBidPhraseAdGroup } 
              onChange={ (e) => this.onChange_DefaultBid_PhraseAdGroup(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              helperText="Minimum $0.02"
              style={{ marginRight: 10, maxWidth: 120 }}
            /> 
            <FormControlLabel
              control={<IOSSwitch 
                checked={ this.state.editStatePhraseAdGroup === 'enabled' ? true : false } 
                onChange={ (event) => { 
                  this.setState({ editStatePhraseAdGroup: event.target.checked ? 'enabled' : 'paused' }) 
                }} 
                name="phrase-adgroup-state-edit" 
              />}
              disabled={ isDisabled }
              //label={this.state.editStatePhraseAdGroup}
            /> 
          </div>
          }

          { showDebug && 
          <FormHelperText>
            <br />
            Debug: <br />
            AdGroup Id (db): { manual_phrase_ad_group_id } <br />
            name: { adGroupInfo && adGroupInfo.name } <br />
            campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
            defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
            state: { adGroupInfo && adGroupInfo.state } <br />
          </FormHelperText>
          }
        </React.Fragment>
      );
    } // end - 3B
  }


  // Render ad group info - manual Exact
  renderAdGroup_ManualExact = () => {
    // 1 - Fetch data from state / props
    const { classes } = this.props;
    const { manual_campaign_id, manual_exact_ad_group_id } = this.state.flowDataDb;
    const { showDebug, isProcessing, isCreatingExactAdGroup,
            exactAdGroupChecked,
            editModeExactAdGroup,
          } = this.state;
  
    const isDisabled = isProcessing ? true : false;

    // 2 - Fetch ad group info if loaded via amazon api.
    const adGroupInfo = this.getAdGroupInfoFromId(manual_exact_ad_group_id);
    
    // 3A - If Ad Group Not created yet then render check box, so user can select it to 
    // create ad group. We will show fields related to create new ad group.
    if (!manual_exact_ad_group_id) { 

      // We have to enable Exact AdGroup checkbox once keyword, negative keyword, and product ad list fetched via amazon api
      const { keywordListFetched, negativeKeywordListFetched, productAdListFetched } = this.state;

      return(
        <React.Fragment>
          
          { // If keyword, negative keyword, or product ad list Not fetched then show label
          ( !keywordListFetched || !negativeKeywordListFetched || !productAdListFetched ) &&
            <FormLabel >Exact Ad Group</FormLabel>
          }

          { // If keyword, negative keyword, and product ad list fetched then show checkbox, otherwise not.
          ( keywordListFetched && negativeKeywordListFetched && productAdListFetched ) &&
          <FormControlLabel
            control={
              <Checkbox 
                color="primary" 
                checked={ exactAdGroupChecked } 
                onChange={ (e) => this.handleChangeExactAdGroup(e.target.checked) } 
                name="exact-ad-group" 
                disabled={ isProcessing }
              />
            }
            label="Exact Ad Group"
          />
          }

          { // If user will check Exact adgroup checkbox, it means user  
            // is willing to create Exact Ad Group now, so we will show 
            // Exact Ad Group creation related input element.
          exactAdGroupChecked &&
          <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }} >  
              <TextField 
                variant="outlined"
                id="bid-amount-exact" 
                label="Default Bid Amount" 
                value={ this.state.bidAmountExact } 
                onChange={ (e) => this.onChangeBidAmountExact(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                helperText="Minimum $0.02"
                style={{ maxWidth: 150 }}
              />
              { // If manual Campaign created then show save/cancel button near this
              // Because in this case we will allow user create individual Ad group.
              manual_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Create - Exact Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CreateExactAdGroup() }
                  disabled={ isDisabled }
                  title="Create Exact Ad Group"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
              }
              { // If manual Campaign created then show save/cancel button near this
              // Because in this case we will allow user create individual Ad group.
              manual_campaign_id &&
                <IconButton 
                  size="small"
                  aria-label="Cancel - Exact Ad Group" 
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={ (e) => this.onClick_CancelExactAdGroup() }
                  disabled={ isDisabled }
                  title="Cancel"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              }
              { // If Exact ad group creation in progress, then show progress indicator
                isCreatingExactAdGroup && 
                <CircularProgress size={16} style={{ marginLeft: 24, marginTop: 10 }} />
              }
            </div>

            { // If keyword hint array exist then show it
            this.state.hintKeywordExact.length > 0 && 
            <div style={{ marginTop: 10, marginBottom: 10 }} >
              Below keywords will be added as <b>Exact</b> match.
              <br />
              {  
                this.state.hintKeywordExact.map((item, index) => { 
                  return <div key={'hint-exact' + index} ><i>{item}</i><br /></div>
                })
              }
            </div>
            }
          </React.Fragment>
          }

          { showDebug && 
            <FormHelperText>
              <br />
              Debug: <br />
              AdGroup Id (db): { manual_exact_ad_group_id } <br />
              name: { adGroupInfo && adGroupInfo.name } <br />
              campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
              defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
              state: { adGroupInfo && adGroupInfo.state } <br />
            </FormHelperText>
          }
        </React.Fragment>
      );
    } // end - 3A

    // 3B - If Ad Group exist then show AdGroup info with edit icon.
    if (manual_exact_ad_group_id) { 
      return(
        <React.Fragment>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
            <FormControlLabel
              control={<Checkbox
                        color="primary" 
                        checked={true} 
                        name="automatic"
                        disabled={ true }
                      />}
              label="Exact Ad Group"
            />

            { // Edit mode off (Show Edit icon)
            adGroupInfo && !editModeExactAdGroup &&
              <IconButton 
                  size="small"
                  aria-label="Edit Exact Ad Group" 
                  style={{ marginLeft: 0 }}
                  onClick={ (e) => this.onClickEdit_ExactAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                >
                <EditIcon fontSize="small" />
              </IconButton>
            } 

            { // Edit mode on (Show Save Cancel icon)
            editModeExactAdGroup &&
              <div>
                <IconButton 
                  size="small"
                  aria-label="Save - Exact Ad Group" 
                  style={{ marginLeft: 10 }}
                  onClick={ (e) => this.onClickEdit_SaveExactAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                  title="Save"
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small"
                  aria-label="Cancel - Exact Ad Group" 
                  style={{ marginLeft: 10 }}
                  onClick={ (e) => this.onClickEdit_CancelExactAdGroup(adGroupInfo) }
                  disabled={ isDisabled }
                  title="Cancel"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
                { // If exact ad group update in progress, then show progress indicator
                this.state.isUpdatingExactAdGroup && 
                  <CircularProgress size={16} style={{ marginLeft: 4 }} />
                }
              </div>
            }
          </div>

          { // Edit mode off (Show info)
          adGroupInfo && !editModeExactAdGroup &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <Typography variant="body2" >
              Default Bid: ${ adGroupInfo.defaultBid }
            </Typography>
            <FormControlLabel
              control={<IOSSwitch 
                checked={ adGroupInfo.state === 'enabled' ? true : false } 
                name="exact-adgroup-state" 
                //disabled={true}
              />}
              //label={ adGroupInfo.state }
            />
          </div>
          }

          { // Edit mode On (Show input controls)
          editModeExactAdGroup && 
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }} >
            <TextField 
              variant="standard"
              id="edit-defaultbid-exact" 
              label="Default Bid" 
              //placeholder="" 
              value={ this.state.editDefaultBidExactAdGroup } 
              onChange={ (e) => this.onChange_DefaultBid_ExactAdGroup(e.target.value) } 
              size="small" 
              disabled={ isDisabled } 
              InputLabelProps={{ shrink: true, }} 
              InputProps={{ 
                startAdornment: <InputAdornment position="start">$</InputAdornment>, 
              }}
              helperText="Minimum $0.02"
              style={{ marginRight: 10, maxWidth: 120 }}
            /> 
            <FormControlLabel
              control={<IOSSwitch 
                checked={ this.state.editStateExactAdGroup === 'enabled' ? true : false } 
                onChange={ (event) => { 
                  this.setState({ editStateExactAdGroup: event.target.checked ? 'enabled' : 'paused' }) 
                }} 
                name="exact-adgroup-state-edit" 
              />}
              disabled={ isDisabled }
              //label={this.state.editStateExactAdGroup}
            /> 
          </div>
          }

          { showDebug && 
            <FormHelperText>
              <br />
              Debug: <br />
              AdGroup Id (db): { manual_exact_ad_group_id } <br />
              name: { adGroupInfo && adGroupInfo.name } <br />
              campaignId: { adGroupInfo && adGroupInfo.campaignId } <br />
              defaultBid: { adGroupInfo && adGroupInfo.defaultBid } &nbsp; 
              state: { adGroupInfo && adGroupInfo.state } <br />
            </FormHelperText>
          }
        </React.Fragment>
      );
    } // end - 3B
  } 


  // Render keyword for Manual Broad Ad Group
  renderKeyword_ManualBroad = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_campaign_id and broad match type.
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.matchType === 'broad' ) { return true; } else { return false; }
    });
    //console.log('(Manual Broad) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'keyword-manual-broad-' + index } size="small" >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4, width: 30 }} >${ item.bid }</TableCell>          
          {
          //<TableCell align="right" style={{ padding: 4, width: 80 }} ></TableCell>
          }
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Manual Broad Keywords">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
          //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Render keyword for Manual Phrase Ad Group
  renderKeyword_ManualPhrase = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_campaign_id and phrase match type.
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.matchType === 'phrase' ) { return true; } else { return false; }
    });
    //console.log('(Manual Phrase) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'keyword-manual-phrase-' + index } size="small" >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4, width: 30 }} >${ item.bid }</TableCell>          
          { 
          // <TableCell align="right" style={{ padding: 4, width: 80 }} ></TableCell>
          }
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Manaul Phrase Keywords">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
          //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Render keyword for Manual Exact Ad Group
  renderKeyword_ManualExact = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_campaign_id and exact match type.
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.matchType === 'exact' ) { return true; } else { return false; }
    });
    //console.log('(Manual Exact) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { 
      return (<React.Fragment>Not created</React.Fragment>);
    }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'keyword-manual-exact-' + index } size="small" >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 4, width: 30 }} >${ item.bid }</TableCell>          
          { 
          //<TableCell align="right" style={{ padding: 4, width: 80 }} ></TableCell>
          }
        </TableRow>  
      )
    });

    // 5 - Return ui elements
    return (
      <React.Fragment>
        <TableContainer>
          <Table aria-label="Manaul Exact Keywords">
            {
            // <TableHead>
            //   <TableRow>
            //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
            //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
            //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
            //   </TableRow>
            // </TableHead>
            }
            <TableBody>
              { tableRowEl }
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }


  // Render negative exact keyword for manual broad ad group
  renderNegativeKeyword_ManualBroad = () => {

    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing } = this.state;
    const { manual_broad_ad_group_id } = this.state.flowDataDb;
    if (negativeKeywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist negative keywords belongs to 
    // manual_campaign_id and manual_broad_ad_group_id
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.adGroupId === manual_broad_ad_group_id ) { 
        return true; 
      } else { 
        return false; 
      }
    });
    //console.log('(Negative Exact - Manual Broad) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'negative-keyword-manual-broad-' + index } >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          { 
          //<TableCell align="right" style={{ padding: 4 }} ></TableCell>
          }
        </TableRow>
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer>
        <Table aria-label="Negative Exact keywords - Manual Broad Ad Group">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    ); 

  }

  // Render negative exact keyword for manual phrase ad group
  renderNegativeKeyword_ManualPhrase = () => {

    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing } = this.state;
    const { manual_phrase_ad_group_id } = this.state.flowDataDb;
    if (negativeKeywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist negative keywords belongs to 
    // manual_campaign_id and manual_phrase_ad_group_id
    const { manual_campaign_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if (item.campaignId === manual_campaign_id && item.adGroupId === manual_phrase_ad_group_id ) { 
        return true; 
      } else { 
        return false; 
      }
    });
    //console.log('(Negative Exact - Manual Phrase) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // 4 - Prepare ui element for keywords
    const tableRowEl = keywordList.map( (item, index) => {
      return(
        <TableRow key={'negative-keyword-manual-phrase-' + index } >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText }
            { this.state.showDebug && 
            <FormHelperText>
              keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
            </FormHelperText>
            }
          </TableCell>
          {
          //<TableCell align="right" style={{ padding: 4 }} ></TableCell>
          }
        </TableRow>
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer>
        <Table aria-label="Negative Exact keywords - Manual Phrase Ad Group">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    ); 
  }


  
  // Return campaign info data for given campaignId
  getCampaignInfoFromId = (campaignId) => {
    if (!campaignId) { return null }

    let campaignInfo; 
    this.state.campaignListApi.forEach((item, index) => {
      if (item.campaignId === campaignId) {
        campaignInfo = item;
      }
    });

    return campaignInfo;
  }

  // It will return ad group info if exist within result
  getAdGroupInfoFromId = (adGroupId) => {
    if (!adGroupId) { return null; }

    let adGroupInfo = '';
    this.state.adGroupListApi.forEach((item, index) => {
      if (item.adGroupId === adGroupId) {
        adGroupInfo = item;
      }
    });
    return adGroupInfo;
  }
  

  // Convert given date string from YYYYMMDD to YYYY-MM-DD 
  // i.e. convert from 20201119 to 2020-11-19
  // e.g. @dateString String e.g. 20201119
  convert_YYYYMMDD_YYYY_MM_DD = (dateString) => {
    const dateFormated = dateString.substr(0,4) + '-' + dateString.substr(4,2) + '-' + dateString.substr(6,2); 
    return dateFormated;     // e.g. 2020-11-19
  }

  // Is given YYYYMMDD date string expired or not 
  // e.g. @dateString String(YYYYMMDD) e.g. 20201119
  // Return: true - if expired, false - not expired.
  // Note: We will not consider time element while campare date. Because passed date 
  // string consist only date part, so we will prepare current date using date part and 
  // compare with current date to decide that given date is expired or not.
  isGiven_YYYYMMDD_Expired = (dateString) => {

    // 1 - convert given date string to js date object
    const strYYYY = dateString.substr(0,4);  // e.g. YYYY
    const strMM = dateString.substr(4,2);    // e.g. MM 
    const strDD = dateString.substr(6,2);    // e.g. DD
    const givenDateJS = new Date(strYYYY, strMM - 1, strDD);  // Create JS Date object
    //console.log('givenDateJS:', givenDateJS); 
    
    // 2 - Create java script date object for current date (without time element)
    const date = new Date();          // Current Date JS Object (with time)
    const yyyy = date.getFullYear();  // year e.g. 2018
    const mm = date.getMonth() + 1;   // Month number (0-11)
    const dd = date.getDate();        // Date e.g. 23 (1 to 31)
    const currentDateJs = new Date(yyyy, mm - 1, dd);  // Create JS Date object
    //console.log('currentDateJs:', currentDateJs); 

    // 3 - Validate that given date is expired or not
    if ( givenDateJS < currentDateJs ) { // Expired
      return true;   // i.e. Given date passed current date, so it is expired
    } else { // Not Expired
      return false;
    }
  }

  // Is given YYYYMMDD date string expired or not
  // i.e. It will find Difference in Days between current Date and given YYYYMMDD
  // e.g. @dateString String(YYYYMMDD) e.g. 20201119
  // Return Number (Difference in Days)
  // Positive number means given date expired before X Days.
  // Negative number means given date Not expired yet. i.e. It will be expired after X Days.
  diffInDays_CurrentDate_And_YYYYMMDD = (dateString) => { 

    // 1 - Convert given date string to js date object
    const strYYYY = dateString.substr(0,4);  // e.g. YYYY
    const strMM = dateString.substr(4,2);    // e.g. MM 
    const strDD = dateString.substr(6,2);    // e.g. DD
    const givenDateJS = new Date(strYYYY, strMM - 1, strDD);  // Create JS Date object
    //console.log('givenDateJS:', givenDateJS); 

    // 2 - Create java script date object for current date (without time element)
    const date = new Date();          // Current Date JS Object (with time)
    const yyyy = date.getFullYear();  // year e.g. 2018
    const mm = date.getMonth() + 1;   // Month number (0-11)
    const dd = date.getDate();        // Date e.g. 23 (1 to 31)
    const currentDateJs = new Date(yyyy, mm - 1, dd);  // Create JS Date object
    //console.log('currentDateJs:', currentDateJs); 
    
    // Find a difference between current date and given Date
    // If current date passed it will return positive value, otherwise negative value.
    const diffTime = currentDateJs - givenDateJS;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //console.log('diffDays:', diffDays);
    
    return diffDays;
  }


  // Debug common info
  debugCommonInfo = () => {
    const { flowDocId } = this.props;
    const { 
      campaignBudgetAutomatic, bidAmountAutomatic, 
      createdAutoCampaignId, createdAutoAdGroupId, 

      campaignBudgetManual, bidAmountBroad, bidAmountPhrase, bidAmountExact, 
      createdManualCampaignId, createdBroadAdGroupId, createdPhraseAdGroupId, createdExactAdGroupId, 
      manualBroadAdGroupCreated, manualPhraseAdGroupCreated, manualExactAdGroupCreated, 

      autoAdGroupChecked, broadAdGroupChecked, phraseAdGroupChecked, exactAdGroupChecked, 

      isCreatingBroadAdGroup, isCreatingPhraseAdGroup, isCreatingExactAdGroup,

      startDate, endDate,
    } = this.state;
    
    const { 
      profile_id, profile_type, api_mode, 
      auto_campaign_id, auto_ad_group_id, 
      manual_campaign_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id,
      manualKeywordCreated, manualNegativeKeywordCreated, manualProductAdCreated,
    } = this.state.flowDataDb;


    return (
      <Typography variant="caption" >
        <b>Debug:</b> <br />
        flowDocId (props): { flowDocId } <br /> 
        api_mode (db): { api_mode } <br />
        profile_id (db) : { profile_id } <br />
        profile_type (db): { profile_type } <br />
        <br />
        <b>Used to Create Missing Entity:</b> <br />
        campaignBudgetAutomatic: { campaignBudgetAutomatic } <br />
        bidAmountAutomatic: { bidAmountAutomatic } <br />
        createdAutoCampaignId: { createdAutoCampaignId } <br />
        createdAutoAdGroupId: { createdAutoAdGroupId } <br />
        <br />
        startDate: { startDate } <br />
        endDate: { endDate } <br />
        <br />
        campaignBudgetManual: { campaignBudgetManual } <br />        
        bidAmountBroad: { bidAmountBroad } <br />
        bidAmountPhrase: { bidAmountPhrase } <br />
        bidAmountExact: { bidAmountExact } <br />
        <br />
        createdManualCampaignId: { createdManualCampaignId } <br />
        createdBroadAdGroupId: { createdBroadAdGroupId } <br />
        createdPhraseAdGroupId: { createdPhraseAdGroupId } <br />
        createdExactAdGroupId: { createdExactAdGroupId } <br />
        <br />
        manualBroadAdGroupCreated: { manualBroadAdGroupCreated ? 'true' : 'false' } <br />
        manualPhraseAdGroupCreated: { manualPhraseAdGroupCreated ? 'true' : 'false' } <br />
        manualExactAdGroupCreated: { manualExactAdGroupCreated ? 'true' : 'false' } <br />
        <br />
        manualKeywordCreated: { manualKeywordCreated ? 'true' : 'false' } <br />
        manualNegativeKeywordCreated: { manualNegativeKeywordCreated ? 'true' : 'false' } <br />
        manualProductAdCreated: { manualProductAdCreated ? 'true' : 'false' } <br />
        <br />
        autoAdGroupChecked: { autoAdGroupChecked ? 'true' : 'false' } <br />
        broadAdGroupChecked: { broadAdGroupChecked ? 'true' : 'false' } <br />
        phraseAdGroupChecked: { phraseAdGroupChecked ? 'true' : 'false' } <br />
        exactAdGroupChecked: { exactAdGroupChecked ? 'true' : 'false' } <br />
        <br />
        isCreatingBroadAdGroup: { isCreatingBroadAdGroup ? 'true' : 'false' } <br />
        isCreatingPhraseAdGroup: { isCreatingPhraseAdGroup ? 'true' : 'false' } <br />
        isCreatingExactAdGroup: { isCreatingExactAdGroup ? 'true' : 'false' } <br />
        <br />
        auto_campaign_id (db): { auto_campaign_id } <br />
        auto_ad_group_id: (db): { auto_ad_group_id } <br />
        <br />
        manual_campaign_id (db): { manual_campaign_id } <br /> 
        manual_broad_ad_group_id (db): { manual_broad_ad_group_id } <br />
        manual_phrase_ad_group_id (db): { manual_phrase_ad_group_id } <br />
        manual_exact_ad_group_id (db): { manual_exact_ad_group_id } <br />
      </Typography>  
    );
  }

  // Debug all info related to edit field.
  debugEditField = () => {

    const { 
      editModeAutoCampaign, 
      editDailyBudgetAuto, editStartDateAuto, editEndDateAuto, editStateAuto,

      editModeAutoAdGroup, editDefaultBidAutoAdGroup, editStateAutoAdGroup, 
    } = this.state;

    return (
      <Typography variant="caption" >
        <b>Debug (edit Auto Campaign):</b> <br />
        editModeAutoCampaign: { editModeAutoCampaign ? 'true' : 'false' } <br />
        editDailyBudgetAuto: { editDailyBudgetAuto } <br />  
        editStartDateAuto: { editStartDateAuto } <br />  
        editEndDateAuto: { editEndDateAuto } <br />  
        editStateAuto: { editStateAuto } <br />  
        <br />
        editModeAutoAdGroup: { editModeAutoAdGroup ? 'true' : 'false' } <br />
        editDefaultBidAutoAdGroup: { editDefaultBidAutoAdGroup } <br />
        editStateAutoAdGroup: { editStateAutoAdGroup } <br />
        <br />
      </Typography>  
    ); 
  }

  // Debug all info related to edit field.
  debugEditField2 = () => {

    const { 
      editModeManualCampaign, 
      editDailyBudgetManual, editStartDateManual, editEndDateManual, editStateManual,

      editModeBroadAdGroup, editDefaultBidBroadAdGroup, editStateBroadAdGroup,
      editModePhraseAdGroup, editDefaultBidPhraseAdGroup, editStatePhraseAdGroup,
      editModeExactAdGroup, editDefaultBidExactAdGroup, editStateExactAdGroup,
    } = this.state;
  
    return (
      <Typography variant="caption" >
        <b>Debug (edit Manual Campaign):</b> <br />
        editModeManualCampaign: { editModeManualCampaign ? 'true' : 'false' } <br />
        editDailyBudgetManual: { editDailyBudgetManual } <br />  
        editStartDateManual: { editStartDateManual } <br />  
        editEndDateManual: { editEndDateManual } <br /> 
        editStateManual: { editStateManual } <br /> 
        <br />
        editModeBroadAdGroup: { editModeBroadAdGroup ? 'true' : 'false' } <br />
        editDefaultBidBroadAdGroup: { editDefaultBidBroadAdGroup } <br /> 
        editStateBroadAdGroup: { editStateBroadAdGroup } <br />
        <br />
        editModePhraseAdGroup: { editModePhraseAdGroup ? 'true' : 'false' } <br />
        editDefaultBidPhraseAdGroup: { editDefaultBidPhraseAdGroup } <br /> 
        editStatePhraseAdGroup: { editStatePhraseAdGroup } <br />
        <br />
        editModeExactAdGroup: { editModeExactAdGroup ? 'true' : 'false' } <br />
        editDefaultBidExactAdGroup: { editDefaultBidExactAdGroup } <br /> 
        editStateExactAdGroup: { editStateExactAdGroup } <br />
        <br />
      </Typography>  
    ); 
  }

  // Debug campaign info (for Auto flow)
  debugCampaignAuto = () => {
    const { auto_campaign_id } = this.state.flowDataDb;

    // 1 - Fetch campaign name, state etc. if loaded via amazon api.
    const campaignInfo = this.getCampaignInfoFromId(auto_campaign_id);

    // 2 - Render auto campaign related info
    return (
      <React.Fragment>
        <b>Debug - Auto Campaign:</b> <br /> 
        Campaign Id (DB): { auto_campaign_id } <br />
        name: { campaignInfo && campaignInfo.name } <br />
        dailyBudget: { campaignInfo && campaignInfo.dailyBudget } <br />
        Campaign State: { campaignInfo && campaignInfo.state } <br />
        Start-End Date: { campaignInfo && campaignInfo.startDate } - { campaignInfo && campaignInfo.endDate } <br />
        <br />
      </React.Fragment>
    );
  }

  debugCampaignManual = () => {
    const { manual_campaign_id } = this.state.flowDataDb;

    // 1 - Fetch campaign name, state etc. if loaded via amazon api.
    const campaignInfo = this.getCampaignInfoFromId(manual_campaign_id);

    // 2 - If show manual campaign related info
    return(
      <React.Fragment>
        <b>Debug - Manual Campaign:</b> <br />
        Campaign Id (DB): { manual_campaign_id } <br />
        name: { campaignInfo && campaignInfo.name } <br />
        dailyBudget: { campaignInfo && campaignInfo.dailyBudget } <br />
        Campaign State: { campaignInfo && campaignInfo.state } <br />
        Start-End Date: { campaignInfo && campaignInfo.startDate } { campaignInfo && campaignInfo.endDate } <br />
        <br />
      </React.Fragment>
    )
  }

  // Render product ads for debug purpose
  debugProductAds = () => {

    // 1 - If product ad list empty then return
    const { productAdListApi } = this.state;
    if (productAdListApi.length === 0) { return null; }

    // 2 - Prepare array that consist product ad belongs to manual_campaign_id
    const { 
      manual_campaign_id, auto_campaign_id, 
      auto_ad_group_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id
    } = this.state.flowDataDb
    
    // 3 - Prepare ui element for product
    const tableRowEl = productAdListApi.map( (item, index) => {
      return(
        <TableRow key={'product-ad-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >
            { item.asin ? item.asin : '-' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.sku ? item.sku : '-' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.state }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.adGroupId }
            { auto_ad_group_id === item.adGroupId && ' (Auto)' }
            { manual_broad_ad_group_id === item.adGroupId && ' (Broad)' }
            { manual_phrase_ad_group_id === item.adGroupId && ' (Phrase)' }
            { manual_exact_ad_group_id === item.adGroupId && ' (Exact)' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.campaignId }
            { auto_campaign_id === item.campaignId && ' (Auto)' }
            { manual_campaign_id === item.campaignId && ' (Manual)' }
            </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.adId }</TableCell>
        </TableRow>  
      )
    });

    // 4 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Products - Used for Product Ads">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >ASIN</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >SKU</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >State</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >AdGroupId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >CampaignId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >adId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );

  }


  // Render debug button
  renderDebugButton = () => {
    return(
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.showDebug}
              onChange={ (event) => { this.setState({showDebug: event.target.checked }); } }
              name="show-debug"
              color="primary"
            />
          }
          label="Show Debug"
          labelPlacement="end"
        />
      </FormGroup>
    );
  }
  //-----------------------------------------------------------------
  // End: Rendering related functions
  //-----------------------------------------------------------------


  // Called when message close after time out (snackbar message)
  handleSnackbarClose = () => {
    console.log('handleSnackbarClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }

  
  render() {
    const { classes } = this.props;
    const { isOpen, flowDataDb } = this.state;
    const { isProcessing, isCreatingAutoCampaign, isCreatingManualCampaign } = this.state;

    let isDisabled = false;
    if ( isProcessing || isCreatingAutoCampaign || isCreatingManualCampaign ) {
      isDisabled = true;
    }

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <Dialog disableEscapeKeyDown={true} fullScreen={true} open={isOpen} onClose={this.handleClose} >
        <AppBar className={classes.appBar} color='default' >
          <Toolbar>
            <Typography variant="h6" style={{ flex:1 }} >
              Edit Campaign: { flowDataDb ? flowDataDb.flow_name : ' .... ' } 
            </Typography>
            { 
              this.renderDebugButton() 
            }
            <IconButton 
              edge="end" 
              color="inherit" 
              onClick={this.handleClose} 
              aria-label="close" 
              style={{ marginLeft: 20 }} 
              disabled={ isDisabled }
            >
              <CloseIcon />
            </IconButton>
            {
            // <Button autoFocus color="inherit" onClick={this.handleClose} >Close</Button>
            }
          </Toolbar>
        </AppBar>
        <Paper 
          elevation={0} 
          style={{margin: 0, padding: 20}}
        >
          { flowDataDb &&
            this.renderContent() 
          }
        </Paper>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </Dialog>
    );
  }

}



// define styles
const styles = (theme) => ({
  
  appBar: {
    position: 'relative',
  },
  
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  table: {
    minWidth: 650,
  },

  paper: {
    padding: theme.spacing(1),
    //color: theme.palette.text.secondary,
    //textAlign: 'center',
  },

  formControl: {
    width: '100%',
  },

});

SpAdAutomationEditCampaign.propTypes = {
  flowDocId: PropTypes.string.isRequired,
  onEditCampaignClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

//export default App;
export default withStyles(styles)(SpAdAutomationEditCampaign);


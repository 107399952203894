
//-------------------------------------------------------------------
// Export - Maketplaces Dict
//-------------------------------------------------------------------
// Help: https://docs.developer.amazonservices.com/en_UK/dev_guide/DG_Endpoints.html
export const marketPlaces = {
  'US': {
    country_name: 'US',
    country_code: 'US',
    site_url: 'https://amazon.com',
    market_place_id: 'ATVPDKIKX0DER',
    currency_code: 'USD',
  },
  'CA': {
    country_name: 'Canada',
    country_code: 'CA',
    site_url: 'https://amazon.ca',
    market_place_id: 'A2EUQ1WTGCTBG2',
    currency_code: 'CAD',
  },
  'MX': {
    country_name: 'Mexico',
    country_code: 'MX',
    site_url: 'https://amazon.com.mx',
    market_place_id: 'A1AM78C64UM0Y8',
    currency_code: 'MXN',
  },
  // 'BR': {
  //   country_name: 'Brazil',
  //   country_code: 'BR',
  //   site_url: 'https://amazon.com',
  //   market_place_id: 'A2Q3Y263D00KWC',
  //   currency_code: 'BRL',
  // },
  // 'IN': {
  //   country_name: 'India',
  //   country_code: 'IN',
  //   site_url: 'https://amazon.in',
  //   market_place_id: 'A21TJRUUN4KGV',
  //   currency_code: 'INR',
  // },
};
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Export - config
//-------------------------------------------------------------------
// Development
const dev_config = {
  API_BASE_URL: 'http://localhost:8080',
}

// Production
const prod_config = {
  API_BASE_URL: 'https://plusamaze-cloudrun-yyhferfnpa-uc.a.run.app',
}

// Export config based on NODE_ENV ('development' or 'production')
export const config = process.env.NODE_ENV === 'development' ? dev_config : prod_config ;
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// Export constant for Login with Amazon - Used for Advertising Module
//-------------------------------------------------------------------
export const LOGIN_WITH_AMAZON_CLIENT_ID = 'amzn1.application-oa2-client.14d3126b8db9437588e73da8e4f4fdc0';

export const LOGIN_WITH_AMAZON_REGION = {
  'NA': {
    auth_url_prefix: 'https://www.amazon.com/ap/oa',
    token_url_prefix: 'https://api.amazon.com/auth/o2/token',
    region_name: 'North America', 
  },
  'EU': {
    auth_url_prefix: 'https://eu.account.amazon.com/ap/oa',
    token_url_prefix: 'https://api.amazon.co.uk/auth/o2/token',
    region_name: 'Europe', 
  },
  'FE': {
    auth_url_prefix: 'https://apac.account.amazon.com/ap/oa',
    token_url_prefix: 'https://api.amazon.co.jp/auth/o2/token',
    region_name: 'Far East', 
  }
}

export const LOGIN_WITH_AMAZON_AUTH_RETURN_URL = process.env.NODE_ENV === 'development' ? 
                                  'http://localhost:3000/amazon-auth' : 
                                  'https://plusamaze.com/amazon-auth';

//-------------------------------------------------------------------
// Edit Product (i.e. edit Product Ads) - Modal Screen
// i.e. Manage product (Ads) created under the flow

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
// import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/CheckCircle';
//import CancelIcon from '@material-ui/icons/Cancel';
import { green, grey } from '@material-ui/core/colors';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormLabel from '@material-ui/core/FormLabel';

import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';

// Import custom component
import { IOSSwitch } from '../common/IOSSwitch';


// Import helper funcion
import { 
  spGetProductAds, spUpdateProductAds,
  spCreateProductAds, 
} from '../../helpers/amazonAdHelper';

import { 
  listMatchingProducts, getMatchingProductForId,
} from '../../helpers/mwsHelper';


// Import firebase 
import firebase from '../../helpers/firebaseApp';
const db = firebase.firestore();


class SpAdAutomationEditProduct extends React.Component { 

  // When set DUMMY it will not call amazon ad api but call the logic to return dummy result
  // so we can test whole flow during development. 
  // IMPORTANT: When set REAL it will call amazon ad api, and create data within db also.
  API_MODE_CREATE_AD = 'REAL';  // 'DUMMY' or 'REAL'  (Create product ad)


  // Default state
  state = {

    // true - show debug info, false - hide debug info
    showDebug: false, 

    // Start: ----- Form input -----
    // ....
    // End: ----- Form input -----

    // True - Open current modal, false - hide it
    isOpen: true,

    // We will fetch flow related data from db and set here.
    // e.g. flowDataDb: { key1:value1, key2:{ }, key3:[], key4:value4, ... }
    flowDataDb: null,

    // We will fetch product ads created under automatic and manual 
    // campaign and set it here.
    productAdListApi: [],
    productAdListFetched: false,   // Will be set true once product ad list fetched

    // We will fetch product basic info e.g. thumb image, title etc. via mws api 
    // and set info here. It will be used to show thumb and product title within a 
    // product ad list.
    // e.g. productListBasicInfo = [
    //    { ASIN: '', Title: '', ImageUrl: '', ImageWidth: '', ImageHeight: '' }
    //    ...
    // ]
    productListBasicInfo: [],


    // ---------- Start: Used for Search Product + Result + Create New Ad ---------
    // Below variable used for search product form
    searchProductBy: '',      // e.g. 'SellerSKU' or 'ASIN or 'SearchQuery'
    searchProductLabel: '',   // 
    searchProductValues: '',  // 
    
    // Once user will search product via asin, sku, or Search query we will
    // fillup received data within this array.
    // e.g. searchProductResults = [ { key: value, key: value }, ... ]
    // i.e. searchProductResults: [{
    //   SellerSKU: '',
    //   ASIN: 'ASIN1', 
    //   MarketplaceId: 'MarketplaceId1', 
    //   Title: 'Title1',
    //   SmallImage: null,
    //   productCost: '', 
    // }],
    searchProductResults: null,

    // true - Searching product in progress, false - not searching
    isSearchingProduct: false,

    // true - Creating product ad in progress, false - not creating
    isCreatingProductAd: false,       // Used while create product ad

    // Whenever we proceed to create new product ad, we will save that product
    // related info within this variable temporary until ad created. Once ad 
    // created we will save this value within db under the current flow docId.
    // e.g. createdProductInfo = { 
    //          ASIN: 'B074G17N36',
    //          MarketplaceId: 'A2EUQ1WTGCTBG2',
    //          SellerSKU: '',
    //          market: 'CA',
    //          productCost: 4.99
    //      }
    createdProductInfo: null,      // Used while create product ad

    // Index of the product record within search result.
    // We will use this index to remove product from search result once ad created.
    createdProductIndex: -1,       // Used while create product ad
    // ----------- End: Used for Search Product + Result + Create New Ad ---------
    
    
    // -------------- Start: Used for enable or pause ad ---------
    // When product ad update in progress we will set it true
    // User can toggle product ad enabled or paused states
    isUpdatingProductAd: false,
    updatingProductAdForAsin: '',  // asin for which we are updating product ads
    // -------------- End: Used for enable or pause ad ---------


    // Some processing in progress or not.
    isProcessing: false,

    // Message text
    showMessage: false,
    messageText: "",
  }


  // Called when component mounted
  componentDidMount = () => {
    console.log('SpAdAutomationEditProduct - componentDidMount() flowDocId:', this.props.flowDocId);

    // Fetch flow data from db.
    this.fetchFlowDataDb();
  }

  componentWillUnmount = () => {
    console.log('SpAdAutomationEditProduct - componentWillUnmount()');
  }


  // Called when close button tapped
  // We will close the current model and inform parent about close.
  handleClose = () => {
    //console.log('SpAdAutomationEditProduct - handleClose()');

    // Close current modal
    this.setState({
      isOpen: false,
    });

    // Inform parent component that close button tapped from modal,
    // so it will unload current component from its render list.
    if (this.props.onEditProductClose) {
      this.props.onEditProductClose();
    }
  }; 


  //-----------------------------------------------------------------
  // Start: Data fetch related functions
  //-----------------------------------------------------------------
  // Fetch flow related data from db 
  fetchFlowDataDb = () => {
    //console.log('SpAdAutomationEditProduct - fetchFlowDataDb()');

    const { flowDocId } = this.props;

    // Fetch flow info from db
    db.collection('sponsored_product_ad').doc(flowDocId).get()
    .then( (doc) => {
      
      if (!doc.exists) {
        return;
      }

      // Grab required data
      const flowData = {};
      const data = doc.data();
      flowData['flow_name'] = data.flow_name;
      flowData['profile_id'] = data.profile_id;
      flowData['profile_type'] = data.profile_type;
      flowData['api_mode'] = data.api_mode; // 'DUMMY' or 'REAL'

      flowData['products'] = data.products ? data.products : [];

      flowData['market'] = data.market ? data.market : '';
      flowData['market_place_id'] = data.market_place_id ? data.market_place_id : '';

      flowData['auto_campaign_id'] = data.auto_campaign_id ? data.auto_campaign_id : null;
      flowData['auto_ad_group_id'] = data.auto_ad_group_id ? data.auto_ad_group_id : null;

      flowData['manual_campaign_id'] = data.manual_campaign_id ? data.manual_campaign_id : null;
      flowData['manual_broad_ad_group_id'] = data.manual_broad_ad_group_id ? data.manual_broad_ad_group_id : null;
      flowData['manual_phrase_ad_group_id'] = data.manual_phrase_ad_group_id ? data.manual_phrase_ad_group_id : null;
      flowData['manual_exact_ad_group_id'] = data.manual_exact_ad_group_id ? data.manual_exact_ad_group_id : null;
      
      flowData['auto_status'] = data.auto_status;
      flowData['manual_status'] = data.manual_status;
      flowData['status'] = data.status;

      // Debu
      console.log('flowData:', flowData);

      // Prepare data to update state
      const stateUpdateData = {};
      stateUpdateData['flowDataDb'] = flowData;

      // Update data within state
      this.setState(stateUpdateData);

      // Fetch necessary data via amazon ad api (if campaign created via REAL api mode)
      if (flowData.api_mode === 'REAL') {
        this.fetchDataViaAmazonApi();
      }

    })
    .catch( (error) => {
      console.log("Error fetching flow doc from db. error:", error);
    });
  }

  // Fetch necessary data via amazon ad api
  fetchDataViaAmazonApi = () => {
    //console.log('fetchDataViaAmazonApi()');

    // Fetch product ads via amazon api
    this.fetchProductAdListViaApi();
  }


  // --------- START: Fetch Product Ads -------------------
  // Fetch product ads via amazon api (for auto and manual campaign)
  fetchProductAdListViaApi = () => {
    //console.log('fetchProductAdListViaApi()');

    const { profile_id, 
            auto_campaign_id, auto_ad_group_id, 
            manual_campaign_id,
            manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id, 
          } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Product%20ads/listProductAds
    const queryData = {
      //startIndex: 0,
      //count: 100,
      stateFilter: 'enabled,paused', // 'enabled,paused,archived'
      //campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
    }
    console.log('spGetProductAds - queryData:', queryData);

    // 4 - Call api to Get product ads (list) for selected profile Id.
    spGetProductAds(profile_id, queryData, this.spGetProductAds_Success, this.spGetProductAds_Error);
  }

  // Called when product ads (list) fetched successfully
  spGetProductAds_Success = (result) => {
    console.log('spGetProductAds_Success() result:', result);

    if (result.status === 'success') {
      
      this.setState({
        productAdListApi: result.data,
        productAdListFetched: true,
      }, () => {
        this.fetchProductBasicInfo()
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
    }
  }
  
  // Called if any error while fetch product ads (list)
  spGetProductAds_Error = (error) => {
    console.log('spGetProductAds_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Product Ads ------------


  // --------- START: Fetch Product Basic Info (thumb image, title etc.) ------------
  // This function will fetch product basic info via mws api and set data within state.
  fetchProductBasicInfo = () => {
    //console.log('fetchProductBasicInfo()');

    //const { market, idType, idValues } = this.state;
    const { market, profile_type } = this.state.flowDataDb;

    // Prepare id type
    // e.g. ASIN, GCID, SellerSKU, UPC, EAN, ISBN, JAN
    let idType = 'ASIN';
    if( profile_type === 'vendor' ) { idType = 'ASIN'; }
    if( profile_type === 'seller' ) { idType = 'SellerSKU'; }

    // Prepare unique list of asin/sku (Remove duplicate asin/sku)
    const idsDict = {};
    this.state.productAdListApi.forEach( (item, index) => {
      if(profile_type === 'vendor' && item.asin) {
        idsDict[item.asin] = { type: 'asin' };
      }
      if(profile_type === 'seller' && item.sku) {
          idsDict[item.sku] = { type: 'sku' };
      }
    }); 
    const idList = Object.keys(idsDict);

    // Debug
    console.log('idList:', idList); 
    console.log('profile_type:', profile_type);   // 'seller' or 'vendor'
    console.log('market:', market);               // e.g. 'CA'
    console.log('idType:', idType);               // ASIN, GCID, SellerSKU, UPC, EAN, ISBN, JAN

    // Max 5 ids allowed per request, So Generate multiple api request at certain time interval.
    const maxIdsPerRequest = 5;
    const loopCount = Math.ceil( idList.length / maxIdsPerRequest );
    for (let i = 1; i <= loopCount; i++) {
      const startIndex = i * maxIdsPerRequest - maxIdsPerRequest;
      const endIndex = i * maxIdsPerRequest;
      const idListSliced = idList.slice(startIndex, endIndex);
      const idValues = idListSliced.toString(',');
      setTimeout(() => {
        console.log('Fetching info for idValues:', idValues);
        getMatchingProductForId(market, idType, idValues, this.fetchProductBasicInfo_Success, this.fetchProductBasicInfo_Error);          
      }, 1000 * i);
    }
  }


  // Called when api call success
  fetchProductBasicInfo_Success = (result) => {
    console.log("fetchProductBasicInfo_Success() result:", result);
    
    const { status, data, error } = result;

    // If multiple product received then data will be array of products
    let productsTemp = [];
    if ( Array.isArray(data) ) {
      data.forEach( (singleData, index) => {
        if (singleData.Products) {
          if (singleData.Products.Product) {
            productsTemp.push(singleData.Products.Product);
          }
        }
      });
    } else { // Single product returned within result
      if (data.Products) {
        productsTemp.push(data.Products.Product);
      }
    }
    //console.log('productsTemp:', productsTemp);

    // Prepare general format from the data received via api
    const productListBasicInfoNew = [];
    productsTemp.forEach( (product, index) => {
      const { ItemAttributes } = product.AttributeSets;
      const { MarketplaceASIN } = product.Identifiers;
      const { SmallImage } = ItemAttributes;

      const singleProduct = { 
        ASIN: MarketplaceASIN.ASIN || '', 
        Title: ItemAttributes.Title || '', 
        ImageUrl: SmallImage.URL || '', 
        ImageHeight: SmallImage.Height.Value || '', 
        ImageWidth: SmallImage.Width.Value || '', 
      }
      productListBasicInfoNew.push(singleProduct);
    }) 
    console.log('productListBasicInfoNew:', productListBasicInfoNew);
    console.log('productListBasicInfo (old):', this.state.productListBasicInfo);

    // Append new received basic info to existing array.
    // Do not add duplicate record if asin already exist within list.
    const productListBasicInfoCombined = [ ...this.state.productListBasicInfo ];
    productListBasicInfoNew.forEach( (itemNew, index) => {
      if ( itemNew.ASIN !== '' ) {
        let isAsinExist = false;
        this.state.productListBasicInfo.forEach((itemOld,index) => { 
          if( itemNew.ASIN === itemOld.ASIN) { isAsinExist = true; } 
        });
        // If asin not exist in the list then add to array
        if (!isAsinExist) { productListBasicInfoCombined.push(itemNew); }
      }
    });
    console.log('productListBasicInfoCombined:', productListBasicInfoCombined);
    
    // Set combined product info within state 
    this.setState({
      productListBasicInfo: productListBasicInfoCombined, 
    });
  }

  // Called if error while api call
  fetchProductBasicInfo_Error = (error) => {
    console.log("fetchProductBasicInfo_Error() error: ", error);

    // this.setState({
    //   isProcessing: false,
    // });
  }
  // --------- END: Fetch Product Basic Info (thumb image, title etc.) ------------

  //-----------------------------------------------------------------
  // End: Data fetch related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Toggle Product Ad state (enabled/paused)
  //-----------------------------------------------------------------
  // Enable or disable product ad for given asin
  // @id String (asin or sku)
  // @adList Array i.e. List of product ads exist under that asin
  // @isEnabled Boolean (Current ad state, if atleast one ad enabled it will true, otherwise false)
  toggleProductAd = (id, adList, isEnabled) => {
    console.log('toggleProductAd() id:', id, ' isEnabled:', isEnabled, ' adList:', adList);

    // 1 - Fetch necessary data from state;
    const { profile_id } = this.state.flowDataDb;

    // 1A - If valid data not exist then return
    if (!profile_id || profile_id === '') { return; } // profile id not exist then return
    if (adList.length === 0 ) { return; }             // Ad list not exist under asin, then return 

    // 2 - Decide target state for the product ad. (In which state it will be updated to)
    // If isEnabled true then target will be  'paused'
    // If isEnabled false then target will be 'enabled'
    const targetState = isEnabled ? 'paused' : 'enabled';

    // Debug
    console.log('profile_id:', profile_id);
    console.log('targetState:', targetState);

    // 3 - Prepare data to pass the api
    // i.e Arrary of data to update one or more ProductAds
    // e.g. updateData = [
    //   {
    //      "adId": 0,
    //      "state": "enabled"
    //   }
    // ]
    // IMPORTANT: 
    // @adId - existing product ad identifier. (Required)
    // - Pass other fields those value needs to update.
    const updateDataArray = [];
    adList.forEach( (item, index) => {
      const data = {
        adId: item.adId,
        state: targetState,
      }
      updateDataArray.push(data);
    });
    console.log('updateDataArray:', updateDataArray);
    
    // 4 - Set processing on
    this.setState({ 
      isProcessing: true, 
      isUpdatingProductAd: true,
      updatingProductAdForAsin: id, 
    });

    // 5 - Call api to update product ad
    spUpdateProductAds(profile_id, updateDataArray, this.spUpdateProductAds_Success, this.spUpdateProductAds_Error);
  }

  // Called if Product Ad udpated successfully
  //  result = { 
  //   status: 'success',
  //   data: [
  //     {
  //        "adId": 0,
  //        "code": "string",
  //        "details": "string"
  //     }  
  //   ],
  //   error: null
  // }
  // i.e. adId - The identifier of the product ad
  // i.e. code - An enumerated success or error code for machine use. SUCCESS etc.
  // i.e. details - A human-readable description of the code.
  spUpdateProductAds_Success = (result) => {
    console.log('spUpdateProductAds_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      // If result.data array empty, then show error message and return.
      if ( result.data.length === 0 ) { 
        this.showError_ProductAdUpdate();
        return; // Important
      }
      
      // We passed one update data to api, so result.data array consist one element.
      const { code, adId, details } = result.data[0];
      if ( code === 'SUCCESS' ) { // Returned by amazon if updated successfully
        this.fetchProductAdListViaApi(); // Refresh ad list, so user can see updated ad status
        this.stopUpdateProductAd_AfterDelay();
      } else if ( code === 'INVALID_ARGUMENT' ) {
        this.showError_ProductAdUpdate(details);
      } else {
        this.showError_ProductAdUpdate();
      }
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_ProductAdUpdate();
    } 
  } 

  // Called if error occured while update
  spUpdateProductAds_Error = (error) => {
    console.log('spUpdateProductAds_Error() error:', error);
    this.showError_ProductAdUpdate();
  }
  
  // Show error message 
  showError_ProductAdUpdate = (message) => {
    let msg = 'Can not update product ad state this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    this.setState({ 
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingProductAd: false, 
      updatingProductAdForAsin: '',
    }); 
  }
  
  // Stop ad update opration after x seconds.
  stopUpdateProductAd_AfterDelay = () => {
    setTimeout( () => {
      this.setState({
        isProcessing: false, 
        isUpdatingProductAd: false, 
        updatingProductAdForAsin: '', 
      });
    }, 500);
  } 
  //-----------------------------------------------------------------
  // End: Toggle Product Ad state (enabled/paused)
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Add new product related functions
  //-----------------------------------------------------------------
  // Called when user click add button from product search result
  // We have to create product ad for the given asin, or seller sku
  onClickAddProduct = (product,index) => {
    console.log('onClickAddProduct() index:', index );
    //console.log('onClickAddProduct() index:', index, ' product:', product);

    // 1 - Fetch required data from state
    const { 
      profile_id, profile_type, market, market_place_id,
      auto_campaign_id, auto_ad_group_id, 
      manual_campaign_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id,
    } = this.state.flowDataDb;
    const { productCost, ASIN, SellerSKU } = product;
    const productCostFinal = parseFloat(productCost);

    // Debug
    console.log('profile_id:', profile_id);
    console.log('profile_type:', profile_type);
    console.log('productCost:', productCost);
    console.log('productCostFinal:', productCostFinal);
    console.log('auto_campaign_id:', auto_campaign_id);
    console.log('auto_ad_group_id:', auto_ad_group_id);
    console.log('manual_campaign_id:', manual_campaign_id);
    console.log('manual_broad_ad_group_id:', manual_broad_ad_group_id);
    console.log('manual_phrase_ad_group_id:', manual_phrase_ad_group_id);
    console.log('manual_exact_ad_group_id:', manual_exact_ad_group_id);    

    // 1A - If input not valid then return
    if ( productCost === '' ) { // product cost empty
      this.setState({ messageText: 'Please enter product landing cost', showMessage: true, });
      return;
    }
    if ( productCostFinal === 0 ) { // product cost zero then return
      this.setState({ messageText: 'Product landing cost should be greater than zero', showMessage: true, });
      return;
    }
    if ( profile_type === 'vendor' && ASIN === '' ) {
      this.setState({ messageText: 'Product ASIN not found, so can not proceed.', showMessage: true, });
      return;
    }
    if ( profile_type === 'seller' && SellerSKU === '' ) {
      this.setState({ messageText: 'SellerSKU not found, so can not proceed.', showMessage: true, });
      return;
    }

    // 2A - If asin already exist within current product ad list then return
    if ( profile_type === 'vendor' ) {
      const isAsinExist = false;
      this.state.productAdListApi.forEach((item, index) => {
        if (item.asin) { 
          if (item.asin === ASIN) { isAsinExist = true; } 
        }
      });
      if (isAsinExist) { 
        this.setState({ messageText: 'Product already exist with same asin, can not create again.', showMessage: true, }); 
      }
    }
    
    // 2B - If seller sku already exist within current product ad list then return
    if ( profile_type === 'seller' ) {
      const isSkuExist = false;
      this.state.productAdListApi.forEach((item, index) => {
        if (item.sku) { 
          if (item.sku === SellerSKU ) { isSkuExist = true; } 
        }
      });
      if (isSkuExist) { 
        this.setState({ messageText: 'Product already exist with same sku, can not create again.', showMessage: true, }); 
      }
    }

    // 3 - Prepare data to create product Ads
    // We have to pass product ad creation related data api as an array either 
    // create one or more product ad because server side api expect array data.
    // Note: 
    //  - If seller profile then add 'sku' key and value (sku must for seller profile) 
    //  - If vendor profile then add 'asin' key value (asin must for vendor profile) 
    //  - Do not inclde both 'sku' and 'asin' otherwise api will give error.
    // 
    // e.g. productAdDataArray = [
    //   {
    //      "campaignId": 0,
    //      "adGroupId": 0,
    //      "sku": "string",      // Add this key:value for seller profile (Do not add asin)
    //      "asin": "string",     // Add this key:value for vendor profile (Do not add sku)
    //      "state": "enabled"
    //   }
    // ]
    const productAdDataArray = [];

    // 3A - If vendor profile then use asin list to create products ads
    if ( profile_type === 'vendor' ) {
      // If auto ad group (and auto campaign) exist then create product ad for that
      if ( auto_campaign_id && auto_ad_group_id) {
        productAdDataArray.push({
          campaignId: auto_campaign_id,
          adGroupId: auto_ad_group_id,
          state: 'enabled',
          asin: ASIN, 
        });
      }

      // If manual campaign + broad ad group exist then create product ad for that
      if ( manual_campaign_id && manual_broad_ad_group_id ) {
        productAdDataArray.push({
          campaignId: manual_campaign_id,
          adGroupId: manual_broad_ad_group_id,
          state: 'enabled',
          asin: ASIN, 
        });
      }

      // If manual campaign + phrase ad group exist then create product ad for that
      if ( manual_campaign_id && manual_phrase_ad_group_id ) {
        productAdDataArray.push({
          campaignId: manual_campaign_id,
          adGroupId: manual_phrase_ad_group_id,
          state: 'enabled',
          asin: ASIN, 
        });
      }

      // If manual campaign + exact ad group exist then create product ad for that
      if ( manual_campaign_id && manual_exact_ad_group_id ) {
        productAdDataArray.push({
          campaignId: manual_campaign_id,
          adGroupId: manual_exact_ad_group_id,
          state: 'enabled',
          asin: ASIN, 
        });
      }
    }

    // 3B - If seller profile then use seller sku to create product ads data
    if ( profile_type === 'seller' ) {
      // If auto ad group (and auto campaign) exist then create product ad for that
      if ( auto_campaign_id && auto_ad_group_id) {
        productAdDataArray.push({
          campaignId: auto_campaign_id,
          adGroupId: auto_ad_group_id,
          state: 'enabled',
          sku: SellerSKU, 
        });
      }

      // If manual campaign + broad ad group exist then consider for create product ad
      if ( manual_campaign_id && manual_broad_ad_group_id ) {
        productAdDataArray.push({
          campaignId: manual_campaign_id,
          adGroupId: manual_broad_ad_group_id,
          state: 'enabled',
          sku: SellerSKU, 
        });
      }

      // If manual campaign + phrase ad group exist then consider for create product ad
      if ( manual_campaign_id && manual_phrase_ad_group_id ) {
        productAdDataArray.push({
          campaignId: manual_campaign_id,
          adGroupId: manual_phrase_ad_group_id,
          state: 'enabled',
          sku: SellerSKU, 
        });
      }

      // If manual campaign + exact ad group exist then consider for create product ad
      if ( manual_campaign_id && manual_exact_ad_group_id ) {
        productAdDataArray.push({
          campaignId: manual_campaign_id,
          adGroupId: manual_exact_ad_group_id,
          state: 'enabled',
          sku: SellerSKU,
        });
      }
    }

    // Debug
    console.log('productAdDataArray - ' + profile_type + ' :', productAdDataArray);

    // 4 - Prepare product info that we will save within db once ad created. 
    const createdProductInfo = { 
            ASIN: ASIN,                       // for vendor profile this value exist, otherwise empty string
            SellerSKU: SellerSKU,             // for seller profile this value exist, otherwise empty string
            MarketplaceId: market_place_id,   // e.g. 'A2EUQ1WTGCTBG2'
            market: market,                   // e.g. 'CA'
            productCost: productCostFinal
         }
    console.log('createdProductInfo:', createdProductInfo);

    // 5 - Set processing on 
    this.setState({
      isProcessing: true, 
      isCreatingProductAd: true,
      createdProductInfo: createdProductInfo,
      createdProductIndex: index,
    });

    // 6 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_CREATE_AD === 'DUMMY' ) {
      // 6A - Imitate success callback
      const dummyResult = { 
        status: 'success', 
        data: [ { adId: 5001, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      setTimeout(() => { this.spCreateProductAds_Success(dummyResult); }, 1000);
      return; // Important

      // // 6B - Imitate error callback
      // const error = { code: 'error', message: 'Product Ad create error' }
      // setTimeout(() => { this.spCreateProductAds_Error(error); }, 1000);
      // return; // Important
    }
    // End: dummy callback

    // 6 - Call api to create product ad
    // Important: Once product ad created, we have to add product info within db under current flow.
    spCreateProductAds(profile_id, productAdDataArray, this.spCreateProductAds_Success, this.spCreateProductAds_Error);
  }

  // Called if product ad created successfully
  // e.g. result = { 
  //   status: 'success', 
  //   data: [
  //     { 
  //        "adId": 0,
  //        "code": "string",
  //        "details": "string"
  //     } 
  //   ],
  //   error: null,
  // }
  spCreateProductAds_Success = (result) => {
    console.log('spCreateProductAds_Success() result:', result);

    if (result.status === 'success') {
      if ( result.data.length > 0 ) {
        // Remove created product from search result
        this.removeCreatedProduct_FromSearchResult();

        // Save newly created product info within db
        this.saveCreatedProductToDb();
      } else {
        this.showError_CreateProductAd();
      }
    }

    if (result.status === 'error') {
      this.showError_CreateProductAd();
    }
  }

  // Called if any error while creating product ad (for manual flow)
  spCreateProductAds_Error = (error) => {
    console.log('spCreateProductAds_Error() error:', error);
    this.showError_CreateProductAd();
  }

  // If error occur while product ad create, we will show message 
  // to user, and stop the operation so user can try again it need.
  showError_CreateProductAd = () => {
    this.setState({
      messageText: 'Can not create product this time, please try again.',
      showMessage: true,
      isProcessing: false,
      isCreatingProductAd: false,
      createdProductInfo: null,
      createdProductIndex: -1,
    });
  }

  // This function will remove created product from search result.
  removeCreatedProduct_FromSearchResult = () => {
    console.log('removeCreatedProduct_FromSearchResult()');

    // 1 - Fetch index to remove from search result
    const { createdProductIndex, searchProductResults } = this.state;
    console.log('createdProductIndex:', createdProductIndex);

    // 2 - Invalid index then return
    if ( createdProductIndex < 0 ) { return; }
    if ( createdProductIndex >= searchProductResults.length ) { return; }

    // 3 - Copy search result to temp array
    const searchProductResultsTemp = searchProductResults.map((item, index) => { return item } );

    // 4 - Remove element from temp array at given index 
    searchProductResultsTemp.splice(createdProductIndex, 1);

    // 5 - Save updated result to state
    this.setState({
      searchProductResults: searchProductResultsTemp, 
    });
  }


  // This function will save newly created ads product info within db under current flow
  // and refresh the necessary data, so user can see newly added product ad within ui.
  saveCreatedProductToDb = () => {
    console.log('saveCreatedProductToDb()');

    // 1 - Fetch created product info
    const { createdProductInfo } = this.state;
    console.log('createdProductInfo:', createdProductInfo);

    // 2 - If product info not exist then no need to save anything within db
    // so consider operation done.
    if ( createdProductInfo === null ) {
      console.log('createdProductInfo empty, so return.');
      this.considerProductAdCreated();
      return;
    }

    // 3 - Prepare data to save product info within db 
    // Note: products array may exist within db for current flow, so we have 
    // to append newly created product info within existing products array and 
    // save combined products array within db.
    const { products } = this.state.flowDataDb;  // existing products array
    const productsCombined = products.map((item, index) => { return item; }); // Create a copy of array
    productsCombined.push(createdProductInfo);
    console.log('productsCombined:', productsCombined);
    
    // 4 - Update products within db under current flow
    const { flowDocId } = this.props;
    const updateData = { products: productsCombined }
    db.collection('sponsored_product_ad').doc(flowDocId).update(updateData)
    .then( () => { // doc updated
      console.log("Products updated within db!");
      this.considerProductAdCreated();
    })
    .catch( (error) => { // The document probably doesn't exist.
      console.error("Error updating products within db error:", error);
      this.considerProductAdCreated();
    });
  }

  // Consider product ad created, so we will refresh the flow data from db.
  considerProductAdCreated = () => {
    console.log('considerProductAdCreated()');

    // Refresh flow data from db so it  will refresh flow data from 
    // db and fetch refresh product ad from amazon, so user can see newly 
    // created product ad within ui.
    this.fetchFlowDataDb();

    // Update state after 2 seconds, so user can see loading state and
    // during this waiting time this.fetchFlowDataDb() will refresh flow  
    // data from db and product ads from amazon, so user can see newly created 
    // product ad within ui.
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        isCreatingProductAd: false,
        createdProductInfo: null,
        createdProductIndex: -1,
      }); 
    }, 2000);

  } 

  //-----------------------------------------------------------------
  // End: Add new product related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Search product related functions
  //-----------------------------------------------------------------
  // Called when search product button tapped, we will search product 
  // using mws api and set the received result within state. 
  onClickSearchProduct = () => {
    console.log('onClickSearchProduct()');
    
    const { market } = this.state.flowDataDb;
    const { searchProductBy, searchProductValues } = this.state;
    
    // Debug
    // console.log('market:', market);       // e.g. 'CA'
    // console.log('searchProductBy: ', searchProductBy);
    // console.log('searchProductValues: ', searchProductValues);

    // 1 - Call amazon api ListMatchingProduct
    if (searchProductBy === 'SearchQuery') {
      console.log('Call amazon mws api listMatchingProducts');

      // 1.1 - Set processing on 
      // Also keep the selected product within a list.
      this.setState({
        searchProductResults: [],
        isProcessing: true,
        isSearchingProduct: true,
      });

      // 1.2 - Prepare data to pass the api
      const query = searchProductValues;   // Search terms 'Pen Drive'

      // 1.3 - Call api
      listMatchingProducts(query, market, this.listMatchingProducts_Success, this.listMatchingProducts_Error);
    }

    // OR 
    // 2 - Call amazon api GetMatchingProductForId
    if (searchProductBy === 'SellerSKU' || searchProductBy === 'ASIN') {
      console.log('Call amazon mws api getMatchingProductForId');

      // 2.1 - Prepare data to pass the apo
      const idType = searchProductBy;       // e.g. 'SellerSKU' or 'ASIN'
      const idValues = searchProductValues; // e.g. 'asin1,asin2,asin3' etc.

      // 2.2 - Set processing on 
      this.setState({
        searchProductResults: [],
        isProcessing: true,
        isSearchingProduct: true,
      });

      // 2.3 - Call api
      getMatchingProductForId(market, idType, idValues, this.getMatchingProductForId_Success, this.getMatchingProductForId_Error);
    }
  }

  // Called when api call success (listMatchingProducts)
  // e.g. Sample Data 
  // data = {
  //  Products: { 
  //    Product: [ {Object}, {Object}, ... ]
  //  },
  //  ResponseMetadata: { RequestId: '5b8c173c-7476-4c42-8381-e9241b5d7b78' },
  //  Headers: { 
  //    'x-mws-quota-max': '720.0',
  //    'x-mws-quota-remaining': '720.0',
  //    'x-mws-quota-resetson': '2020-07-13T08:38:00.000Z',
  //    'x-mws-timestamp': '2020-07-13T08:34:01.802Z',
  //    'content-type': 'text/xml',
  //    'content-charset': 'unknown',
  //    'content-length': '23738',
  //    'content-md5': 'unknown',
  //    date: 'Mon, 13 Jul 2020 08:34:01 GMT' 
  //  }
  // }
  listMatchingProducts_Success = (result) => {
    console.log("listMatchingProducts_Success() result:", result);

    // If error then show error messsage and return
    const { status, error } = result;
    if ( status === 'error' ) { 
      this.setState({
        messageText: error,
        showMessage: true, 
        isProcessing: false, 
        isSearchingProduct: false,
      });
      return; // Important
    }

    // 1 - Read data from result
    const { data } = result;
    //console.log('data.Products.Product:', data.Products.Product);

    // 2 - Convert received result to general data format.
    const searchProductResultsNew = this.prepareProductResult(data.Products.Product);
    console.log('searchProductResultsNew:', searchProductResultsNew);

    // 2A - Remove existing asin from search result.
    const existingAsinDict = {};
    this.state.productAdListApi.forEach((item, index) => {
      if (item.asin) { existingAsinDict[item.asin] = item.asin; }
    });
    const existingAsinsArray = Object.keys(existingAsinDict); // e.g. ['asin1','asin2']
    const searchProductResultsFinal = [];
    searchProductResultsNew.forEach((item, index) => {
      let isAsinExist = false;
      existingAsinsArray.forEach((asin, index) => { // Check that asin exist in current product ads
        if (asin === item.ASIN) { isAsinExist = true; }
      });
      if (!isAsinExist) { searchProductResultsFinal.push(item); } // If asin not exists then consider for search result
    });
    console.log('searchProductResultsFinal:', searchProductResultsFinal);

    // 3 - If product not exist within result then show message and return.
    if ( searchProductResultsFinal.length === 0 ) {
      this.setState({
        messageText: 'Product not found for search criteria',
        showMessage: true,
        isProcessing: false,
        isSearchingProduct: false,
      });
      return;
    }

    // 4 - Udpate value within state
    this.setState({
      searchProductResults: searchProductResultsFinal,
      isProcessing: false,
      isSearchingProduct: false,
    });
  }

  // Called if error while api call (listMatchingProducts)
  listMatchingProducts_Error = (error) => {
    console.log("listMatchingProducts_Error() error: ", error);
    
    this.setState({
      messageText: 'Error while search, please try again.',
      showMessage: true,
      isProcessing: false,
      isSearchingProduct: false,
    });
  }

  // Called when api call success
  getMatchingProductForId_Success = (result) => {
    console.log("getMatchingProductForId_Success() result:", result);

    // If error then show error messsage and return
    const { status, error } = result;
    if ( status === 'error' ) { 
      this.setState({
        messageText: error,
        showMessage: true, 
        isProcessing: false, 
        isSearchingProduct: false,
      });
      return; // Important
    }

    // 1 - Read data from result
    const { data } = result;

    // 2 - If multiple product then data will be array of products
    var productsTemp = [];
    if ( Array.isArray(data) ) { // Array
      data.forEach( (singleData, index) => {
        if (singleData.Products) {
          if (singleData.Products.Product) {
            let product = { ...singleData.Products.Product };
            // if IdType is SellerSKU then take value from there, otherwise keep SellerSKU empty.
            if ( singleData.IdType === 'SellerSKU' ) { 
              product['SellerSKU'] = singleData.Id;
            } else {
              product['SellerSKU'] = '';
            }
            productsTemp.push(product);
          }
        }
      });
    } else { // Not array
      if (data.Products) { 
        let product = { ...data.Products.Product };
        // if IdType is SellerSKU then take value from there, otherwise keep SellerSKU empty.        
        if ( data.IdType === 'SellerSKU' ) {
          product['SellerSKU'] = data.Id;
        } else {
          product['SellerSKU'] = '';
        }
        productsTemp = [product];
      }
    }
    console.log('productsTemp:', productsTemp);

    // 3 - Convert received result to general data format.
    const searchProductResultsNew = this.prepareProductResult(productsTemp);

    // 3A - Remove existing asin from search result.
    const existingAsinDict = {};
    this.state.productAdListApi.forEach((item, index) => {
      if (item.asin) { existingAsinDict[item.asin] = item.asin; }
    });
    const existingAsinsArray = Object.keys(existingAsinDict); // e.g. ['asin1','asin2']
    //console.log('existingAsinsArray:', existingAsinsArray);
    const searchProductResultsFinal = [];
    searchProductResultsNew.forEach((item, index) => {
      let isAsinExist = false;
      existingAsinsArray.forEach((asin, index) => { // Check that asin already exist in current product ads
        if (asin === item.ASIN) { isAsinExist = true; }
      });
      if (!isAsinExist) { searchProductResultsFinal.push(item); }
    });
    console.log('searchProductResultsFinal:', searchProductResultsFinal);

    // 4 - If product not exist within result then show message and return.
    if ( searchProductResultsFinal.length === 0 ) {
      this.setState({
        messageText: 'Product not found for search criteria',
        showMessage: true,
        isProcessing: false,
        isSearchingProduct: false,
      });
      return;
    }

    // 5 - Udpate value within state
    this.setState({
      searchProductResults: searchProductResultsFinal,
      isProcessing: false,
      isSearchingProduct: false,
    });
  }

  // Called if error while api call
  getMatchingProductForId_Error = (error) => {
    console.log("getMatchingProductForId_Error() error: ", error);

    this.setState({
      messageText: 'Error while search, please try again.', 
      showMessage: true, 
      isProcessing: false, 
      isSearchingProduct: false,
    });
  }

  // Convert received result to required data format.
  // Note: Mws api will return results in complex format, so we have to grab the 
  // required data from the result and prepare our own generlise data, So we can 
  // use those data while render product list etc.
  prepareProductResult = (products) => {
    console.log('prepareProductResult()');
    //console.log('prepareProductResult() products:', products);

    // 1 - Convert received products in to generalise format
    var productList = []; 
    products.forEach( (product, index) => {
      const { ASIN, MarketplaceId } = product.Identifiers.MarketplaceASIN;
      //const { SalesRank } = product.SalesRankings;
      const { ItemAttributes } = product.AttributeSets;
      const { Title, SmallImage } = ItemAttributes;
      
      // If SellerSKU field exist within data then grab its value, otherwise keep empty.
      let SellerSKU = '';
      if (product.SellerSKU) { SellerSKU = product.SellerSKU; }

      // Prepare product data in generalise format
      const data = { 
        SellerSKU: SellerSKU,
        ASIN: ASIN, 
        MarketplaceId: MarketplaceId, 
        Title: Title,
        //SmallImage: SmallImage,
        ImageUrl: SmallImage.URL || '', 
        ImageWidth: SmallImage.Width.Value || '',
        ImageHeight: SmallImage.Height.Value || '',
        productCost: '',   // Will be entered by user (Wholesale/Manufacturing + Shipping cost)
      }

      // Insert to array
      productList.push(data);
    });

    // Debug
    //console.log('productList:', productList);

    // 2 - Return product list in generalised format
    return productList;
  }
  //-----------------------------------------------------------------
  // End: Search product related functions  
  //-----------------------------------------------------------------
  

  //-----------------------------------------------------------------
  // Start: Rendering related functions
  //-----------------------------------------------------------------
  renderContent = () => {
    const { classes, flowDocId } = this.props;
    const { profile_id, profile_type, api_mode } = this.state.flowDataDb;
    const { productAdListFetched, isSearchingProduct, searchProductResults, isProcessing, isCreatingProductAd } = this.state;

    return(
      <Grid container spacing={2} >

        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1" >
            Running Ad For: { profile_type } profile
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          { this.renderProducts_From_ProductAds() }
          { !productAdListFetched && <LinearProgress /> }
        </Grid>

        <Grid item xs={12} sm={12}>
          { this.renderProductSearchForm() }
          { isSearchingProduct && <LinearProgress /> }
        </Grid>

        <Grid item xs={12} sm={12}>
          { (searchProductResults && searchProductResults.length > 0) &&
            <h4>Note: Include wholesale, manufacturing, and shipping for product landing cost.</h4>
          }
          { isCreatingProductAd && <LinearProgress /> }
          { this.renderProductSearchResult() }
          { (searchProductResults && searchProductResults.length > 0) &&
            <h4>Note: Include wholesale, manufacturing, and shipping for product landing cost.</h4>
          }
        </Grid>

        { api_mode === 'DUMMY' && 
        <Grid item xs={12} sm={12}>
          <br /><br />
          <Typography variant="h5" >
            Note: DUMMY api mode used to create this flow.
            So more info can not fetch via amazon Ad api.
          </Typography>
        </Grid>
        }

        <Grid item xs={12} sm={12}>
          <br /><br /><br />
        </Grid>

        { this.state.showDebug && 
        <Grid item xs={12} sm={12}>
          Product Ads Debug:
          { this.debugProductAds() }
        </Grid>
        }

      </Grid>
    );
  }

  // Render product ads information
  renderProducts_From_ProductAds = () => {

    // 1 - If product ad list empty then return
    const { productAdListApi, productAdListFetched, 
            isProcessing, isUpdatingProductAd, updatingProductAdForAsin 
          } = this.state;

    // 2 - If Product ads empty then show message
    let emptyMessage = '';
    if ( !productAdListFetched ) { 
      emptyMessage = "...";
    } else if ( productAdListApi.length === 0 ) { 
      emptyMessage = 'Product ads not created for this flow.' 
    }

    // 3 - If product ad not fetched/empry then show message
    if ( !productAdListFetched ) {
      return(
        <TableContainer component={Paper}>
          <Table aria-label="Products">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: 100 }} ></TableCell>
                <TableCell align="left" style={{ }} >Product Info</TableCell>
                <TableCell align="left" style={{ width: 250 }} >Status of the Product</TableCell>
                <TableCell align="center" style={{ width: 50 }} >State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={'product-ad-empty-row'} >
                <TableCell align="left" style={{ }} colSpan={4} >
                  { emptyMessage }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    // 4 - Fetch other data from state
    const { 
      manual_campaign_id, auto_campaign_id, 
      auto_ad_group_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id
    } = this.state.flowDataDb;
    const { productListBasicInfo } = this.state;


    // 5 - Prepare unique asin/sku list from product ads
    // e.g. dict = { 
    //     'asin1': [
    //       { adId: 100, adGroupId: 1, campaignId: 1, asin: 'asin1', state: 'enabled' },
    //       { adId: 101, adGroupId: 2, campaignId: 1, asin: 'asin1', state: 'enabled' },
    //     ]
    // } 
    const dict = this.convertProductAdsToDict(productAdListApi);
    
    // 6 - Prepare ui element list for product
    const dictKeys = Object.keys(dict);  // [ 'asi1', 'asin2' ] or ['sku1', 'sku2'] etc.
    const tableRowEl = dictKeys.map( (key, index) => {

      // If product ad found for correspondig ad group, set adgroup id for that.
      let autoAdGroupId; let broadAdGroupId; let phraseAdGroupId; let exactAdGroupId;
      let autoAdId; let broadAdId; let phraseAdId; let exactAdId;
      let autoAdState; let broadAdState; let phraseAdState; let exactAdState;

      // If atleast one ad (for same asin) have state enabled then show toggle button 
      // as enabled state, otherwise show toggle as disabled state.
      let isEnabled = false;

      // Single asin have multiple product ad created depending on how many 
      // ad group created under current flow.
      // i.e. ad created under Auto, Broad, Phrase, and Exact AdGroup (if all ad group exist)
      const adList = dict[key];
      adList.forEach((item, index) => { // Iterate each ad under the asin
        if( item.adGroupId === auto_ad_group_id ) { // ad belongs to auto adgroup
          autoAdGroupId = item.adGroupId; autoAdId = item.adId; autoAdState = item.state;
        } 
        if( item.adGroupId === manual_broad_ad_group_id ) { // ad belongs to broad adgroup
          broadAdGroupId = item.adGroupId; broadAdId = item.adId; broadAdState = item.state;
        } 
        if( item.adGroupId === manual_phrase_ad_group_id ) { // ad belongs to phrase adgroup
          phraseAdGroupId = item.adGroupId; phraseAdId = item.adId; phraseAdState = item.state; 
        } 
        if( item.adGroupId === manual_exact_ad_group_id ) { // ad belongs to exact adgroup
          exactAdGroupId = item.adGroupId; exactAdId = item.adId; exactAdState = item.state; 
        } 
        // If ad have enabled state then consider state as enabled for that asin.
        if ( item.state === 'enabled') { isEnabled = true; }
      });

      // Decide tick color to show with created ad 
      // i.e. if ad state = enabled then green, otherwise grey
      // Note: If ad group not created then we will show x icon
      const autoTickColor = autoAdState === 'enabled' ? green[500] : grey[500];
      const broadTickColor = broadAdState === 'enabled' ? green[500] : grey[500];
      const phraseTickColor = phraseAdState === 'enabled' ? green[500] : grey[500];
      const exactTickColor = exactAdState === 'enabled' ? green[500] : grey[500];

      // Find product info from the list (used to show title, thumb image etc.)
      const productInfo = productListBasicInfo.find( (item, index) => {
        if (item.ASIN === key) { return true; }
      });
      
      return(
        <TableRow key={'product-ad-' + index } >
          <TableCell align="left" style={{ }} >
            { (productInfo && productInfo.ImageUrl !== '' ) && 
              <img src={ productInfo.ImageUrl } width="60" alt='product ' />
            }
            { ( !productInfo || productInfo.ImageUrl === '') && 
              <div style={{ width: 60, height: 60, backgroundColor: '#efefef' }} ></div>
            }
          </TableCell>
          <TableCell align="left" style={{ }} >
            { productInfo && productInfo.Title + ' | ' }
            ASIN: { key } <br />
            { this.state.showDebug && 
              <React.Fragment>
                <br /><br />
                AdGroupIds: 
                { autoAdGroupId && autoAdGroupId + ' (auto) ' }
                { broadAdGroupId && broadAdGroupId + ' (broad) ' }
                { phraseAdGroupId && phraseAdGroupId + ' (phrase) ' }
                { exactAdGroupId && exactAdGroupId + ' (exact) ' }
                <br />
                Ad Ids: 
                { autoAdId && autoAdId + ' (auto) ' } 
                { broadAdId && broadAdId + ' (broad) ' } 
                { phraseAdId && phraseAdId + ' (phrase) ' } 
                { exactAdId && exactAdId + ' (exact) ' } 
                <br />
                State: 
                { autoAdState && autoAdState + ' (auto) '} 
                { broadAdState && broadAdState + ' (broad) '}
                { phraseAdState && phraseAdState + ' (phrase) '}
                { exactAdState && exactAdState + ' (exact) '}
              </React.Fragment>
            }
          </TableCell>
          <TableCell align="left" style={{ }} >
            <div style={{ display: 'flex', flexDirection: 'row' }} >
              
              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }} >
                { autoAdGroupId ?
                <CheckIcon fontSize="small" style={{ color: autoTickColor }} />
                :
                <CloseIcon fontSize="small" style={{ color: grey[500] }} />
                }
                Auto
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }} >
                { broadAdGroupId ?
                  <CheckIcon fontSize="small" style={{ color: broadTickColor }} />
                :
                  <CloseIcon fontSize="small" style={{ color: grey[500] }} />
                }
                Broad
              </div> 

              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }} >
                { phraseAdGroupId ?
                  <CheckIcon fontSize="small" style={{ color: phraseTickColor }} />
                :
                  <CloseIcon fontSize="small" style={{ color: grey[500] }} />
                }
                Phrase
              </div> 

              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }} >
                { exactAdGroupId ?
                  <CheckIcon fontSize="small" style={{ color: exactTickColor }} />
                :
                  <CloseIcon fontSize="small" style={{ color: grey[500] }} />
                }
                Exact
              </div> 

            </div>
          </TableCell>
          <TableCell align="center" style={{ paddingRight: 0 }} >

            <FormControlLabel
              control={<IOSSwitch 
                checked={ isEnabled } 
                onChange={ (event) => { this.toggleProductAd(key, adList, isEnabled) }} 
                name={ 'toggle-product-ad-' + index }
              />}
              disabled={ isProcessing || isUpdatingProductAd }
              style={{ marginLeft: 4, marginBottom: 4 }}
              //label={ isEnabled ? 'enabled ' : 'paused' }
              //labelPlacement="bottom"
            />
            <br />
            { // If updating ad state for current asin then show loader with this record
              (isUpdatingProductAd && updatingProductAdForAsin === key) &&
              <CircularProgress size={20} />
            }
          </TableCell>
        </TableRow>  
      );
    });

    // 7 - Prepare footer row
    const footerRow = (
      <TableRow key={'product-ad-footer' } >
        <TableCell align="left" style={{ }} colSpan={4} >
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }} >
              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 16 }} >
                <CheckIcon fontSize="small" style={{ color: green[500] }} /> Indicates enabled ad
              </div> 
              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 16 }} >
                <CheckIcon fontSize="small" style={{ color: grey[500] }} /> Indicates paused ad
              </div> 
              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 0 }} >
                <CloseIcon fontSize="small" style={{ color: grey[500] }} /> Indicates ad group not created
              </div> 
            </div>
            <div>
            For the '<CloseIcon fontSize="small" style={{ color: grey[500], verticalAlign: 'middle' }} />' status ad group(s), create those ad group(s) in campaign management screen.
            </div>
        </TableCell>
      </TableRow>
    );


    // 8 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Products">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: 100 }} ></TableCell>
              <TableCell align="left" style={{ }} >Product Info</TableCell>
              <TableCell align="left" style={{ width: 250 }} >Status of the Product</TableCell>
              <TableCell align="center" style={{ width: 50 }} >State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
            { footerRow }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  // Prepare unique asin/sku list from product ads
  // e.g. dict = { 
  //     'asin1': [
  //       { adId: 100, adGroupId: 1, campaignId: 1, asin: 'asin1', state: 'enabled' },
  //       { adId: 101, adGroupId: 2, campaignId: 1, asin: 'asin1', state: 'enabled' },
  //     ]
  // } 
  convertProductAdsToDict = (productAds) => {
    
    const dict = { };
    productAds.forEach( (item, index) => {
      
      if (item.asin) { // asin exist then create key with asin
        if ( dict[item.asin] === undefined ) { // If key not exist then create it with empty array
          dict[item.asin] = [];
        }
        dict[item.asin].push(item); // insert current item
      } 
      else if (item.sku) { // sku exist then create key with sku
        if ( dict[item.sku] === undefined ) { // If key not exist then create it with empty array
          dict[item.sku] = []; 
        }
        dict[item.sku].push(item); // insert current item
      }

    }); 

    return dict;
  }


  // This function called whenever user will select option from search by drop down.
  handleChangeSearchProductBy = (value) => {
    this.setState({
      searchProductBy: value,
      searchProductValues: '',
      searchProductResults: [],
    });
  }
 
  // If necessary input not valid then disable search button.
  shouldDisableSearchButton = () => {
    const { isProcessing, searchProductBy, searchProductValues } = this.state;

    let isDisabled = false;

    // 1 - If necessary value empty then disable button
    if ( isProcessing || searchProductBy === '' || searchProductValues === '' ) { 
      isDisabled = true;
      return isDisabled;
    }

    // 2 - If searchProductBy ASIN or SellerSKU, then do not allow more than 5 comma separated value.
    if ( searchProductBy === 'ASIN' || searchProductBy === 'SellerSKU' ) {
      // e.g. searchProductValues = 'asin1,asin2,asin3,asin4,asin5'
      const listArray = searchProductValues.split(',');
      
      // Disable search button if more than 5 comma separated value exist.
      if ( listArray.length > 5 ) { 
        isDisabled = true;
        return isDisabled;
      }
    }

    // 3 - Return status
    return isDisabled;
  }

  // It will render product search form and search result listing content
  renderProductSearchForm = () => {

    const { classes } = this.props;
    const { profile_type, profile_id, market } = this.state.flowDataDb;
    const { searchProductBy, isProcessing } = this.state;
      
    // Search product input field label
    let searchProductLabel = 'Search';
    if ( searchProductBy === 'SearchQuery') { searchProductLabel = 'Search Query (e.g. Pen Drive)' }
    if ( searchProductBy === 'ASIN') { searchProductLabel = 'ASIN (max. 5 comma separated)' }
    if ( searchProductBy === 'SellerSKU') { searchProductLabel = 'Seller SKU (max. 5 comma separated)' }

    let isDisabled = false; 
    if ( isProcessing ) {
      isDisabled = true;
    }

    return(
      <Paper elevation={2} style={{ padding: 10 }} >
        <Typography variant="h6" >Search Product:</Typography>
        <Grid container spacing={2} >

          <Grid item xs={12} sm={2}>
            <FormControl className={classes.formControl} >
              <InputLabel id="market-label">Market: { market }</InputLabel>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl className={classes.formControl} >
              <InputLabel id="searchproductby-label">Search Product By</InputLabel>
              <Select
                id="searchProductBy"
                labelId="searchproductby-label"
                value={ searchProductBy }
                onChange={ (e) => this.handleChangeSearchProductBy(e.target.value) }
                disabled={ isDisabled }
              >
                <MenuItem value="SellerSKU" >SellerSKU</MenuItem> 
                { profile_type === 'vendor' && <MenuItem value="ASIN" >ASIN</MenuItem> }
                { profile_type === 'vendor' && <MenuItem value="SearchQuery" >Search Query</MenuItem> }
              </Select>
            </FormControl>  
          </Grid>

          <Grid item xs={12} sm={5}>
            <FormControl className={classes.formControl} >
              <TextField 
                id="seachProductTerms" 
                //label="Values (max5) Comma Separated" 
                label={ searchProductLabel }
                placeholder={ searchProductLabel }
                value={ this.state.searchProductValues } 
                onChange={(e) => this.setState({ searchProductValues: e.target.value })} 
                size="small" 
                disabled={ isDisabled || this.state.searchProductBy === '' } 
                InputLabelProps={{ shrink: true, }} 
              />
            </FormControl> 
          </Grid>

          <Grid item xs={12} sm={2} align="center" >
            <Button 
              variant="contained" 
              size="medium" 
              onClick={ (e) => this.onClickSearchProduct() } 
              //style={{ marginRight: 20 }}
              disabled={ this.shouldDisableSearchButton() } 
            >Search</Button>
          </Grid>

        </Grid>
      </Paper>
    );
  }


  // Render product search result.
  // User can add product from that.
  renderProductSearchResult = () => {
    
    // 1 - If product search result not exist then return null
    const { searchProductResults } = this.state;
    if ( !searchProductResults ) { return null; }
    if ( searchProductResults.length === 0 ) { return null; }

    // 2 - Fetch data from state etc.
    const { isProcessing, isCreatingProductAd } = this.state;
    const { classes } = this.props;
    
    // 3 - If processing in progress then disable
    let isDisabled = false;
    if ( isProcessing || isCreatingProductAd ) { isDisabled = true; }

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="search result"> 
          { 
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left"></TableCell>
          //     <TableCell align="left">Image</TableCell>
          //     <TableCell align="left">Title</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
          { searchProductResults.map( (product, index) => (
              <TableRow key={product.ASIN + '__' + index}  >
                <TableCell align="left" style={{  }} >
                  { product.ImageUrl !== '' &&
                    <img src={product.ImageUrl} width="50" alt={product.Title} />  
                  }
                </TableCell>
                <TableCell align="left" style={{ }} >
                  { product.Title } <br />
                  { product.ASIN !== '' &&  'ASIN: ' + product.ASIN } &nbsp; &nbsp;
                  { product.SellerSKU !== '' &&  'SellerSKU: ' + product.SellerSKU }
                </TableCell>
                <TableCell align="left" style={{ width: 200 }} > 
                    <TextField 
                      variant="standard"
                      id={"product-cost-" + index}
                      label="Product Landing Cost"
                      placeholder="" 
                      value={ product.productCost } 
                      onChange={ (e) => this.onChangeProductCost(e.target.value, index) }
                      size="small" 
                      disabled={ isDisabled } 
                      InputLabelProps={{ shrink: true, }}
                      //helperText="Include wholesale/manufacturing + shipping cost." 
                      InputProps={{ 
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                </TableCell>
                <TableCell align="left" style={{ }} >
                  <Button 
                    variant="contained" 
                    size="small" 
                    onClick={ (e) => this.onClickAddProduct(product, index) } 
                    //style={{ marginRight: 20 }}
                    disabled={ isDisabled } 
                    title="Add Product" 
                  >Add</Button>
                </TableCell>
              </TableRow>
            ))}      
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  // This function called whenever user will input product cost within a product list.
  onChangeProductCost = (value, index) => {
    const { searchProductResults } = this.state;
    
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { // Not a number
      return;
    }

    // 2 - Copy existing product list to temp array
    const searchProductResultsTemp = searchProductResults.map((item, index) => { return item; });
    
    // 3 - Update product cost within temp array
    searchProductResultsTemp[index].productCost = value;

    // 4 - Set updated product list within state
    this.setState({
      searchProductResults: searchProductResultsTemp,
    });
  }


  // Render product ads for debug purpose
  debugProductAds = () => {

    // 1 - If product ad list empty then return
    const { productAdListApi } = this.state;
    if (productAdListApi.length === 0) { return null; }

    // 2 - Fetch flow data from db
    const { 
      manual_campaign_id, auto_campaign_id, 
      auto_ad_group_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id
    } = this.state.flowDataDb
    
    // 3 - Prepare ui element for product
    const tableRowEl = productAdListApi.map( (item, index) => {
      return(
        <TableRow key={'product-ad-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >
            { item.asin ? item.asin : '-' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.sku ? item.sku : '-' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.state }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.adGroupId }
            { auto_ad_group_id === item.adGroupId && ' (Auto)' }
            { manual_broad_ad_group_id === item.adGroupId && ' (Broad)' }
            { manual_phrase_ad_group_id === item.adGroupId && ' (Phrase)' }
            { manual_exact_ad_group_id === item.adGroupId && ' (Exact)' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.campaignId }
            { auto_campaign_id === item.campaignId && ' (Auto)' }
            { manual_campaign_id === item.campaignId && ' (Manual)' }
            </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.adId }</TableCell>
        </TableRow>  
      )
    });

    // 4 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Products - Used for Product Ads">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >ASIN</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >SKU</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >State</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >AdGroupId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >CampaignId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >adId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );

  }

  // Render debug button
  renderDebugButton = () => {
    return(
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.showDebug}
              onChange={ (event) => { this.setState({showDebug: event.target.checked }); } }
              name="show-debug"
              color="primary"
            />
          }
          label="Show Debug"
          labelPlacement="end"
        />
      </FormGroup>
    );
  }
  //-----------------------------------------------------------------
  // End: Rendering related functions
  //-----------------------------------------------------------------


  // Called when message close after time out (snackbar message)
  handleSnackbarClose = () => {
    console.log('handleSnackbarClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }

  
  render() {
    const { classes } = this.props;
    const { isOpen, flowDataDb } = this.state;
    const { isProcessing } = this.state;

    let isDisabled = false;
    if ( isProcessing ) {
      isDisabled = true;
    }

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <Dialog disableEscapeKeyDown={true} fullScreen={true} open={isOpen} onClose={this.handleClose} >
        <AppBar className={classes.appBar} color='default' >
          <Toolbar>
            <Typography variant="h6" style={{ flex:1 }} >
              Edit Product: { flowDataDb ? flowDataDb.flow_name : ' .... ' } 
            </Typography>
            { 
              this.renderDebugButton() 
            }
            <IconButton 
              edge="end" 
              color="inherit" 
              onClick={this.handleClose} 
              aria-label="close" 
              style={{ marginLeft: 20 }} 
              disabled={ isDisabled }
            >
              <CloseIcon />
            </IconButton>
            {
            // <Button autoFocus color="inherit" onClick={this.handleClose} >Close</Button>
            }
          </Toolbar>
        </AppBar>
        <Paper elevation={0} style={{margin: 0, padding: 20}} >
          { flowDataDb &&
            this.renderContent() 
          }
        </Paper>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </Dialog>
    );
  }

}



// define styles
const styles = (theme) => ({
  
  appBar: {
    position: 'relative',
  },
  
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  table: {
    minWidth: 650,
  },

  paper: {
    padding: theme.spacing(1),
    //color: theme.palette.text.secondary,
    //textAlign: 'center',
  },

  formControl: {
    width: '100%',
  },

});

SpAdAutomationEditProduct.propTypes = {
  flowDocId: PropTypes.string.isRequired,
  onEditProductClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

//export default App;
export default withStyles(styles)(SpAdAutomationEditProduct);


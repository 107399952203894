// Edit flow keywords - Modal Screen
// i.e. Manage keywords created under the flow

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
// import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
//import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';


import { green, grey } from '@material-ui/core/colors';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';

//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
//import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';


// Import helper funcion
import { 
  spGetKeywords, spGetNegativeKeywords, spGetAdGroups, 
  spCreateKeywords, spCreateNegativeKeywords, 
  spUpdateKeywords, spUpdateNegativeKeywords, 
  spGetBidRecommendationsForKeywords, 
} from '../../helpers/amazonAdHelper';


// Import firebase 
import firebase from '../../helpers/firebaseApp';
const db = firebase.firestore();


class SpAdAutomationEditKeyword extends React.Component { 

  // 'DUMMY' - It will do dummy call  OR  'REAL' - Call real amazon ad api
  API_MODE_CREATE_NEGATIVE_KEYWORD_AUTO = 'REAL';   // Create negative keyword under Auto Ad Group
  API_MODE_CREATE_NEGATIVE_KEYWORD_BROAD = 'REAL';  // Create negative keyword under Broad Ad Group
  API_MODE_CREATE_NEGATIVE_KEYWORD_PHRASE = 'REAL'; // Create negative keyword under Phrase Ad Group

  API_MODE_CREATE_FLOW_KEYWORD = 'REAL';            // Create flow (positive) keyword (used during add keyword)
  API_MODE_CREATE_FLOW_NEGATIVE_KEYWORD = 'REAL';   // Create flow negative keyword (used during add keyword)


  // Default state
  state = {

    // true - show debug info, false - hide debug info
    showDebug: false, 

    // Start: ----- Form input (Add Keyword) -----
    inputKeyword: '',      // Add new keyword text input field (For All Ad group)
    inputNegativeKeywordAuto: '',   // Add negative keyword input field (Auto Ad Group)
    inputNegativeKeywordBroad: '',  // Add negative keyword input field (Broad Ad Group)
    inputNegativeKeywordPhrase: '', // Add negative keyword input field (Phrase Ad Group)
    // End: ----- Form input (Add Keyword) -----

    // Start: ----- Edit keyword related fields -----
    editBroad: false,       // true - edit mode on for keyword (broad adgroup)
    editBroadIndex: -1,     // index of keyword in edit mode
    editBroadKeywordId: 0,  // Keyword id which in edit mode
    editBroadBidAmount: 0,  // Bid amount input field
    editBroadInProgress: false, // true - update operation in progress, false - not updating

    editPhrase: false,       // true - edit mode on for keyword (phrase adgroup)
    editPhraseIndex: -1,     // index of keyword in edit mode
    editPhraseKeywordId: 0,  // Keyword id which in edit mode
    editPhraseBidAmount: 0,  // Bid amount input field
    editPhraseInProgress: false, // true - update operation in progress, false - not updating

    editExact: false,       // true - edit mode on for keyword (exact adgroup)
    editExactIndex: -1,     // index of keyword in edit mode
    editExactKeywordId: 0,  // Keyword id which in edit mode
    editExactBidAmount: 0,  // Bid amount input field
    editExactInProgress: false, // true - update operation in progress, false - not updating    
    // End: ----- Edit keyword related fields -----


    // True - Open current modal, false - hide it
    isOpen: true,

    // We will fetch flow related data from db and set here.
    // e.g. flowDataDb: { key1:value1, key2:{ }, key3:[], key4:value4, ... }
    flowDataDb: null,


    // We will fetch keywords created for automatic and manual campaign 
    // and set it here for further use.
    keywordListApi: [],
    keywordListFetched: false,  // Will be set true once keyword list fetched

    // We will fetch negative keywords created for automatic and manual 
    // campaign and set it here for further use.
    negativeKeywordListApi: [],
    negativeKeywordListFetched: false,  // Will be set true once negative keyword list fetched

    // We will fetch more info about ad group created for campaign and 
    // set it here for further use.
    adGroupListApi: [],
    adGroupListFetched: false,  // Will be set true once ad group list fetched
    

    // Bid recommendation result will stored here.
    // Once we fetch keywords we will fetch bid recommendation
    // for keywords belongs to each ad group (broad, phrase, exact) and save data here. 
    // Note: We used dictionary so we can fetch value based on the keyword as key.
    // e.g. bidRecommendationBroad = {
    //    'keyword1': {
    //       "suggested": 0,
    //       "rangeStart": 0,
    //       "rangeEnd": 0
    //    }, 
    //    'keyword2': {
    //       "suggested": 0,
    //       "rangeStart": 0,
    //       "rangeEnd": 0
    //    }, 
    // }
    bidRecommendationBroad: {},   // For keywords belongs to broad ad group
    bidRecommendationPhrase: {},  // For keywords belongs to phrase ad group
    bidRecommendationExact: {},   // For keywords belongs to exact ad group


    // Keyword created under every ad group within a flow is consider as flowKeywords.
    // We will find the flow keyword logically from the postive and negative keyword list.
    // User can enabled/paused flow keyword, so that keyword will be disabled from every ad group.
    // e.g. flowKeywordList = [
    //     {
    //       keywordText: 'Pen Drive',
    //       state: 'enabled'              // or paused
    //     }
    //  ]
    flowKeywordList: null, 

    // Some processing in progress or not.
    // We will disable all input/ui control on screen when this true.
    // So user can not interact with other ui element when some api call in progress.
    isProcessing: false, 


    // Used while Creating Extra Negative keyword
    isCreatingNegativeKeywordAuto: false,   // true - Creating negative keyword under Auto ad group.
    isCreatingNegativeKeywordBroad: false,  // true - Creating negative keyword under Broad ad group.
    isCreatingNegativeKeywordPhrase: false, // true - Creating negative keyword under Phrase ad group.

    // Used while updating state for Extra Negative keyword
    isUpdatingNegativeKeywordAuto: false,   // true - Updating negative keyword state under Auto ad group.
    updatingNegativeKeywordIdAuto: 0,       // currently editing negative keyword id 

    isUpdatingNegativeKeywordBroad: false,  // true - Updating negative keyword state under Broad ad group.
    updatingNegativeKeywordIdBroad: 0,      // currently editing negative keyword id

    isUpdatingNegativeKeywordPhrase: false,  // true - Updating negative keyword state under Phrase ad group.
    updatingNegativeKeywordIdPhrase: 0,      // currently editing negative keyword id

    // Used while updating state for flow keyword
    // Flow keyword means keyword that exist within every ad group in a flow.
    isUpdatingFlowKeywordState: false,          // false - flow keyword state update in progress.
    isUpdatingFlowNegativeKeywordState: false,  // false - flow negative keyword state update in progress.
    updatingFlowKeywordText: '',                // Keyword text whose state is updating at present.
  
    // Used while create new Keyword (flow keyword) 
    // i.e. Creating Keyword and Negative keyword
    isCreatingFlowKeyword: false,           // true - keyword creation in progress
    isCreatingFlowNegativeKeyword: false,   // true - Negative keyword creation in progress
  }


  // Called when component mounted
  componentDidMount = () => {
    console.log('SpAdAutomationEditKeyword - componentDidMount() flowDocId:', this.props.flowDocId)

    // Fetch flow data from db.
    this.fetchFlowDataDb();
  }

  componentWillUnmount = () => {
    console.log('SpAdAutomationEditKeyword - componentWillUnmount()');
  }



  // Called when close button tapped
  // We will close the current model and inform parent about close.
  handleClose = () => {
    //console.log('SpAdAutomationEditKeyword - handleClose()');

    // Close current modal
    this.setState({
      isOpen: false,
    });

    // Inform parent component that close button tapped from modal,
    // so it will unload current component from its render list.
    if (this.props.onEditKeywordClose) {
      this.props.onEditKeywordClose();
    }
  }; 



  //-----------------------------------------------------------------
  // Start: Data fetch related functions
  //-----------------------------------------------------------------
  // Fetch flow related data from db 
  fetchFlowDataDb = () => {
    //console.log('SpAdAutomationEditKeyword - fetchFlowDataDb()');

    const { flowDocId } = this.props;

    // Fetch flow info from db
    db.collection('sponsored_product_ad').doc(flowDocId).get()
    .then( (doc) => {
      
      if (!doc.exists) {
        return;
      }

      // Grab required data
      const flowData = {};
      const data = doc.data();
      flowData['flow_name'] = data.flow_name;
      flowData['profile_id'] = data.profile_id;
      flowData['profile_type'] = data.profile_type;
      flowData['api_mode'] = data.api_mode; // 'DUMMY' or 'REAL'

      flowData['products'] = data.products ? data.products : [];

      flowData['auto_campaign_id'] = data.auto_campaign_id ? data.auto_campaign_id : null;
      flowData['auto_ad_group_id'] = data.auto_ad_group_id ? data.auto_ad_group_id : null;

      flowData['manual_campaign_id'] = data.manual_campaign_id ? data.manual_campaign_id : null;
      flowData['manual_broad_ad_group_id'] = data.manual_broad_ad_group_id ? data.manual_broad_ad_group_id : null;
      flowData['manual_phrase_ad_group_id'] = data.manual_phrase_ad_group_id ? data.manual_phrase_ad_group_id : null;
      flowData['manual_exact_ad_group_id'] = data.manual_exact_ad_group_id ? data.manual_exact_ad_group_id : null;
      
      flowData['auto_status'] = data.auto_status;
      flowData['manual_status'] = data.manual_status;
      flowData['status'] = data.status;

      // Debu
      console.log('flowData:', flowData);

      // Prepare data to update state
      const stateUpdateData = {};
      stateUpdateData['flowDataDb'] = flowData;

      // Update data within state
      this.setState(stateUpdateData);

      // Fetch necessary data via amazon ad api (if campaign created via REAL api mode)
      if (flowData.api_mode === 'REAL') {
        this.fetchDataViaAmazonApi();
      }

    })
    .catch( (error) => {
      console.log("Error fetching flow doc from db. error:", error);
    });
  }

  // Fetch necessary data via amazon ad api
  fetchDataViaAmazonApi = () => {
    //console.log('fetchDataViaAmazonApi()');

    // Fetch keyword list via amazon api
    this.fetchKeywordListViaApi();

    // Fetch negative keyword via amazon api
    this.fetchNegativeKeywordListViaApi();

    // Fetch ad group info via amazon api
    this.fetchAdGroupListViaApi();
  }

   
  // --------- START: Fetch Keywords -------------
  // Fetch keyword list via amazon api (i.e. for given campaignId and adGroupId)  
  fetchKeywordListViaApi = () => {
    //console.log('fetchKeywordListViaApi()');

    const { profile_id, 
            auto_campaign_id, auto_ad_group_id,
            manual_campaign_id,
            manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
          } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/listKeywords
    const queryData = {
      //startIndex: 0,
      //count: 100,
      stateFilter: 'enabled,paused', // 'enabled,paused,archived'
      //campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
    }
    console.log('spGetKeywords - queryData:', queryData);

    // 4 - Call api to Get keywords (list) for selected profile Id.
    spGetKeywords(profile_id, queryData, this.spGetKeywords_Success, this.spGetKeywords_Error);
  }

  // Called when keywords (list) fetched successfully
  spGetKeywords_Success = (result) => {
    console.log('spGetKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        keywordListApi: result.data,
        keywordListFetched: true,
      }, () => {
        this.prepareFlowKeywords();
        this.fetchBidRecommendation_AllKeywords();
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
      this.setState({
        keywordListFetched: true,
      });
    }
  }
  
  // Called if any error while fetch keywords (list)
  spGetKeywords_Error = (error) => {
    console.log('spGetKeywords_Error() error:', error);
    // Show error message etc.
    this.setState({
      keywordListFetched: true,
    });
  }  
  // --------- END: Fetch Keywords -------------


  // --------- START: Fetch Negative Keywords -------------
  // Fetch negative keyword list via amazon api (i.e. for given campaignId and adGroupId)    
  fetchNegativeKeywordListViaApi = () => {
    //console.log('fetchNegativeKeywordListViaApi()');

    const { profile_id, 
      auto_campaign_id, auto_ad_group_id,
      manual_campaign_id,
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
    } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 3 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Negative%20keywords/listNegativeKeywords
    const queryData = {
      // startIndex: 0,
      // count: 100,  
      stateFilter: 'enabled,paused',  // 'enabled,paused,archived'
      //campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
    }
    console.log('spGetNegativeKeywords - queryData:', queryData);

    // 4 - Call api to Get negative keywords (list) for selected profile Id.
    spGetNegativeKeywords(profile_id, queryData, this.spGetNegativeKeywords_Success, this.spGetNegativeKeywords_Error);
  }

  // Called when negative keywords (list) fetched successfully
  spGetNegativeKeywords_Success = (result) => {
    console.log('spGetNegativeKeywords_Success() result:', result);

    if (result.status === 'success') {
      this.setState({
        negativeKeywordListApi: result.data,
        negativeKeywordListFetched: true,
      }, () => {
        this.prepareFlowKeywords();
      });
    }

    if (result.status === 'error') {
      // Show error message etc.
      this.setState({
        negativeKeywordListFetched: true,
      });
    }
  }
  
  // Called if any error while fetch negative keywords (list)
  spGetNegativeKeywords_Error = (error) => {
    console.log('spGetNegativeKeywords_Error() error:', error);
    // Show error message etc.
    this.setState({
      negativeKeywordListFetched: true,
    });
  }
  // --------- END: Fetch Negative Keywords ---------------


  // --------- START: Fetch Ad Groups -------------
  // Fetch ad group list via amazon api (i.e. list of ad group under given campaign id)
  fetchAdGroupListViaApi = () => {
    //console.log('fetchAdGroupListViaApi()');

    const { 
      profile_id, 
      auto_campaign_id, auto_ad_group_id, 
      manual_campaign_id, 
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id,
    } = this.state.flowDataDb;

    // 1 - Prepare Comma separated list of campaign ids
    // e.g. 'campaignId1,campaignId2'
    const campaignIdArray = [];
    if (auto_campaign_id) { campaignIdArray.push(auto_campaign_id) }
    if (manual_campaign_id) { campaignIdArray.push(manual_campaign_id) }
    const campaignIds = campaignIdArray.toString(); // e.g. 'campaignId1,campaignId2'

    // 2 - Prepare comma separated ad group ids
    const adGroupIdArray = [];
    if(auto_ad_group_id) { adGroupIdArray.push(auto_ad_group_id); }
    if(manual_broad_ad_group_id) { adGroupIdArray.push(manual_broad_ad_group_id); }
    if(manual_phrase_ad_group_id) { adGroupIdArray.push(manual_phrase_ad_group_id); }
    if(manual_exact_ad_group_id) { adGroupIdArray.push(manual_exact_ad_group_id); }
    const adGroupIds = adGroupIdArray.toString();  // e.g. 'adGroupId1,adGroupId2,adGroupId3'

    // 2 - Prepare query data to pass the api
    // If there is not speficific query then pass empty data object
    // https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Ad%20groups/getAdGroups
    const queryData = {
      //campaignIdFilter: campaignIds,
      adGroupIdFilter: adGroupIds,
      //stateFilter: 'enabled,paused,archived',
      //startIndex: 0,
      //count: 100,
    }
    console.log('spGetAdGroups - queryData:', queryData);    

    // Call api to Get ad groups (list)
    spGetAdGroups(profile_id, queryData, this.spGetAdGroups_Success, this.spGetAdGroups_Error);
  }
  
  // Called when ad groups (list) fetched successfully
  spGetAdGroups_Success = (result) => {
    console.log('spGetAdGroups_Success() result:', result);

    if ( result.status === 'success' ) {
      this.setState({
        adGroupListApi: result.data,
      });
    }

    if (result.status === 'error') {
      // Show error message etc
    }
  }

  // Called if any error while fetch ad groups (list)
  spGetAdGroups_Error = (error) => {
    console.log('spGetAdGroups_Error() error:', error);
    // Show error message etc.
  }
  // --------- END: Fetch Ad Groups ------------- 



  // --------- START: Fetch Given Keywords -------------
  // Fetch given keyword details via amazon api. (positive keyword)
  // i.e. It will fetch given keyword details via amazon api and once result received 
  // it will update result within this.keywordListApi list, if some keyword not exist 
  // within old list then it will append that keyword.
  // 
  // @keywordIds Array e.g. [keywordId1, keywordId2, keywordId3]
  fetchGivenKeywordListViaApi = (keywordIds) => {
    console.log('fetchGivenKeywordListViaApi() keywordIds: ', keywordIds);

    // 1 - If keyword ids array empty then return.
    if(!keywordIds) { 
      console.log('keywordIds undefined, so return'); 
      return; 
    }
    if(keywordIds.length === 0) { 
      console.log('keywordIds array empty, so return');
      return;
    }

    // 2 - Convert keywordIds array to comma separated string
    // e.g. 'keywordId1,keywordId2,keywordId3'
    const keywordIdsString = keywordIds.toString(); 

    // 3 - Prepare query data to pass the api
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/listKeywords
    const queryData = {
      keywordIdFilter: keywordIdsString
    }
    console.log('fetchGivenKeywordListViaApi - queryData:', queryData);

    // 4 - Call api to Get keywords (list)
    const { profile_id } = this.state.flowDataDb;
    spGetKeywords(profile_id, queryData, this.spGetKeywords_Given_Success, this.spGetKeywords_Given_Error);
  }

  // Called when keywords (list) fetched successfully
  // e.g. result = {
  //     status: 'success',
  //     data: [
  //        {
  //           "keywordId": 0,
  //           "campaignId": 0,
  //           "adGroupId": 0,
  //           "state": "enabled",
  //           "keywordText": "string",
  //           "matchType": "exact",
  //           "bid": 0
  //        }
  //     ],
  //     error: null,
  // }
  spGetKeywords_Given_Success = (result) => {
    console.log('spGetKeywords_Given_Success() result:', result);

    // 1 - If error then return
    if ( result.status === 'error' ) {
      console.log('Error while fetch given keywords, so return');
      return; // Important
    }

    // 2 - If success then replace received keyword value within existing list.
    if ( result.status === 'success' && result.data.length > 0 ) {
      
      // 2A - Fetch old keyword list (array)
      const keywordListTemp = [ ...this.state.keywordListApi ];
      
      // 2B - Replace newly received keyword value within old list
      result.data.forEach((keywordNew, index) => {
        
        // 2B.1 - Fetch index position for keywordId within old list
        const searchedIndex = keywordListTemp.findIndex((keywordOld, index) => {
          if (keywordOld.keywordId === keywordNew.keywordId) { 
            return true; 
          }
        });

        // 2B.2 - If keywordId found within old list then replace new value at that index
        if ( searchedIndex >= 0 ) { // keywordId found at some index
          keywordListTemp[searchedIndex] = keywordNew;
        }
        
        // 2B.3 - If keywordId not found in old list then append keyword to list.
        if ( searchedIndex === -1 ) { // -1 means index not found
          keywordListTemp.push(keywordNew);
        }
      });

      // 2C - Set updated keyword within state
      this.setState({
        keywordListApi: keywordListTemp,
      }, () => {
        this.prepareFlowKeywords();
      });
    }

  }
  
  // Called if any error while fetch keywords (list)
  spGetKeywords_Given_Error = (error) => {
    console.log('spGetKeywords_Given_Error() error:', error);
  }  
  // --------- END: Fetch Given Keywords -------------


  // --------- START: Fetch Given Negative Keywords -------------
  // Fetch given negative keyword details via amazon api.
  // i.e. It will fetch given Negative keyword details via amazon api and once result received 
  // it will update result within this.negativeKeywordListApi list, if some keyword not exist 
  // within old list then it will append that keyword.
  // 
  // @keywordIds Array e.g. [keywordId1, keywordId2, keywordId3]
  fetchGivenNegativeKeywordListViaApi = (keywordIds) => {
    console.log('fetchGivenNegativeKeywordListViaApi() keywordIds: ', keywordIds);

    // 1 - If keyword ids array empty then return.
    if(!keywordIds) { 
      console.log('keywordIds undefined, so return'); 
      return; 
    }
    if(keywordIds.length === 0) { 
      console.log('keywordIds array empty, so return');
      return;
    }

    // 2 - Convert keywordIds array to comma separated string
    // e.g. 'keywordId1,keywordId2,keywordId3'
    const keywordIdsString = keywordIds.toString(); 

    // 3 - Prepare query data to pass the api
    // Help: https://advertising.amazon.com/API/docs/en-us/sponsored-products/2-0/openapi#/Keywords/listKeywords
    const queryData = {
      keywordIdFilter: keywordIdsString
    }
    console.log('fetchGivenNegativeKeywordListViaApi - queryData:', queryData);

    // 4 - Call api to Get negative keywords (for given Ids)
    const { profile_id } = this.state.flowDataDb;
    spGetNegativeKeywords(profile_id, queryData, this.spGetNegativeKeywords_Given_Success, this.spGetNegativeKeywords_Given_Error);
  }

  // Called when negative keywords (list) fetched successfully
  // e.g. result = {
  //     status: 'success',
  //     data: [
  //        {
  //           "keywordId": 0,
  //           "campaignId": 0,
  //           "adGroupId": 0,
  //           "state": "enabled",
  //           "keywordText": "string",
  //           "matchType": "negativeExact",
  //        }
  //     ],
  //     error: null,
  // }
  spGetNegativeKeywords_Given_Success = (result) => {
    console.log('spGetNegativeKeywords_Given_Success() result:', result);

    // 1 - If error then return
    if ( result.status === 'error' ) {
      console.log('Error while fetch given keywords, so return');
      return; // Important
    }

    // 2 - If success then replace received keyword value within existing list.
    if ( result.status === 'success' && result.data.length > 0 ) {
      
      // 2A - Fetch old negative keyword list (array)
      const keywordListTemp = [ ...this.state.negativeKeywordListApi ];
      
      // 2B - Replace newly received keyword value within old list
      result.data.forEach((keywordNew, index) => {
        
        // 2B.1 - Fetch index position for keywordId within old list
        const searchedIndex = keywordListTemp.findIndex((keywordOld, index) => {
          if (keywordOld.keywordId === keywordNew.keywordId) { 
            return true; 
          }
        });

        // 2B.2 - If keywordId found within old list then replace new value at that index
        if ( searchedIndex >= 0 ) { // keywordId found at some index
          keywordListTemp[searchedIndex] = keywordNew;
        }
        
        // 2B.3 - If keywordId not found in old list then append keyword to list.
        if ( searchedIndex === -1 ) { // -1 means index not found
          keywordListTemp.push(keywordNew);
        }
      });

      // 2C - Set updated keyword within state
      this.setState({
        negativeKeywordListApi: keywordListTemp,
      }, () => {
        this.prepareFlowKeywords();
      });
    }

  }
  
  // Called if any error while fetch negative keywords (list)
  spGetNegativeKeywords_Given_Error = (error) => {
    console.log('spGetNegativeKeywords_Given_Error() error:', error);
  }
  // --------- END: Fetch Given Negative Keywords ---------------


  // --------- START: Prepare Flow Keywords --------------- 
  // Keyword created under every ad group in a flow is considered as flowKeywords.
  // When user enabled/paused flow keywords it will be enabled/paused from every ad group it exist. 
  // e.g. flowKeywordsDict = {
  //    'keyword1': {
  //        keywordText: 'Pen Drive',
  //        state: 'enabled'              // 'enabled' or 'paused'
  //    } 
  // }
  prepareFlowKeywords = () => {
    //console.log('prepareFlowKeywords()');

    // 1 - If both positive and negative keyword list not fetched then return.
    const { keywordListFetched, negativeKeywordListFetched } = this.state;
    if ( keywordListFetched === false || negativeKeywordListFetched === false ) {
      return; // Important
    }

    // 2 - Prepare list of unique keywords from positive and negative keywords received via api.
    const { keywordListApi, negativeKeywordListApi } = this.state;
    const commonKeywordsDict = {};
    keywordListApi.forEach((item, index) => { 
      commonKeywordsDict[item.keywordText] = item.keywordText; 
    });
    negativeKeywordListApi.forEach( (item, index) => { 
      commonKeywordsDict[item.keywordText] = item.keywordText; 
    })
    //console.log('commonKeywordsDict:', commonKeywordsDict);

    const { 
      auto_ad_group_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id 
    } = this.state.flowDataDb;
    
    // Decide that how many keywords are part of the flow, and prepare list of those keywords.
    // i.e. which keyword created under all ad group exist in our flow.
    const flowKeywordList = [];
    Object.keys(commonKeywordsDict).forEach((item, index) => {
      const keywordText = item;

      // Check that current keyword (keywordText) exist under how many adgroup.
      let isExistBroad = false;            // Keyword exist with broad match (for Broad AdGroup)
      let isExistPhrase = false;;          // Keyword exist with phrase match (for Phrase AdGroup)
      let isExistExact = false;;           // Keyword exist with exact match (for Exact AdGroup)
      let isExistNegativeAuto = false;;    // Negative Keyword exist with negativeExact match (for Auto AdGroup)
      let isExistNegativeBroad = false;;   // Negative Keyword exist with negativeExact match (for Broad AdGroup)
      let isExistNegativePhrase = false;;  // NegativeKeyword exist with negativeExact match (for Phrase AdGroup)

      let stateBroad = 'paused'; let statePhrase = 'paused'; let stateExact = 'paused';
      let stateNegativeAuto = 'paused'; let stateNegativeBroad = 'paused'; let stateNegativePhrase = 'paused';

      // Check that Keyword exist under Broad ad group (with broad match)
      if ( manual_broad_ad_group_id ) { 
        keywordListApi.forEach((item, index) => { 
          if( item.keywordText === keywordText &&
              item.adGroupId === manual_broad_ad_group_id && 
              item.matchType === 'broad'
            ) { isExistBroad = true; stateBroad = item.state; } 
        });
      } else { // Broad ad group not created, so consider keyword exist under that group
        isExistBroad = true; stateBroad = 'paused';
      }

      // Check that Keyword exist under Phrase ad group (with phrase match)
      if ( manual_phrase_ad_group_id ) {
        keywordListApi.forEach( (item, index) => { 
          if( item.keywordText === keywordText &&
              item.adGroupId === manual_phrase_ad_group_id && 
              item.matchType === 'phrase' 
            ) { isExistPhrase = true; statePhrase = item.state; } 
        });
      } else { // phrase ad group not created yet, so consider keyword exist
        isExistPhrase = true; statePhrase = 'paused';
      }

      // Check that Keyword exist under Exact ad group (with exact match)
      if ( manual_exact_ad_group_id ) {
        keywordListApi.forEach( (item, index) => { 
          if( item.keywordText === keywordText &&
              item.adGroupId === manual_exact_ad_group_id && 
              item.matchType === 'exact' 
            ) { isExistExact = true; stateExact = item.state; } 
        });
      } else { // exact ad group not created yet, so consider keyword exist
        isExistExact = true; stateExact = 'paused';
      }

      // Check that Keyword exist under Auto ad group (with negativeExact match)
      if ( auto_ad_group_id ) { 
        negativeKeywordListApi.forEach( (item, index) => { 
          if( item.keywordText === keywordText && 
              item.adGroupId === auto_ad_group_id && 
              item.matchType === 'negativeExact' 
            ) { isExistNegativeAuto = true; stateNegativeAuto = item.state; } 
        });
      } else { // Auto ad group not created yet, so consider negative keyword exist
        isExistNegativeAuto = true; stateNegativeAuto = 'paused';
      }

      // Check that Keyword exist under Broad ad group (with negativeExact match)
      if ( manual_broad_ad_group_id ) { 
        negativeKeywordListApi.forEach( (item, index) => { 
          if( item.keywordText === keywordText && 
              item.adGroupId === manual_broad_ad_group_id && 
              item.matchType === 'negativeExact' 
            ) { isExistNegativeBroad = true; stateNegativeBroad = item.state; } 
        });
      } else { // Broad ad group not created yet, so consider negative keyword exist
        isExistNegativeBroad = true; stateNegativeBroad = 'paused';
      }

      // Check that Keyword exist under Phrase ad group (with negativeExact match)
      if ( manual_phrase_ad_group_id ) { 
        negativeKeywordListApi.forEach( (item, index) => { 
          if( item.keywordText === keywordText && 
              item.adGroupId === manual_phrase_ad_group_id && 
              item.matchType === 'negativeExact' 
            ) { isExistNegativePhrase = true; stateNegativePhrase = item.state; } 
        });
      } else { // Phrase ad group not created yet, so consider negative keyword exist
        isExistNegativePhrase = true; stateNegativePhrase = 'paused';
      }

      // Debug
      // console.log('isExistBroad:', isExistBroad, 'state:', stateBroad);
      // console.log('isExistPhrase:', isExistPhrase, 'state:', statePhrase);
      // console.log('isExistExact:', isExistExact, 'state:', stateExact);
      // console.log('isExistNegativeAuto:', isExistNegativeAuto, 'state:', stateNegativeAuto );
      // console.log('isExistNegativeBroad:', isExistNegativeBroad, 'state:', stateNegativeBroad );
      // console.log('isExistNegativePhrase:', isExistNegativePhrase, 'state:', stateNegativePhrase);

      // 5 - If current keyword exist under all ad group manged by our flow then consider
      // this keyword as the flow keywords.
      if ( isExistBroad && isExistPhrase && isExistExact & 
          isExistNegativeAuto && isExistNegativeBroad && isExistNegativePhrase ) {
          
          // If atleast one keyword state 'enabled' then consider flow keyword as enabled.
          // Note: If user follow our application then keyword will be in 'enabled' state under 
          // all ad group OR 'paused' state under all adgroup. But if user did some updates
          // via amazon console in that case keyword state may be enabled for some ad group and/or
          // paused under other ad group.
          let state = 'paused';
          if( stateBroad === 'enabled' || statePhrase === 'enabled' || stateExact === 'enabled' ||
              stateNegativeAuto === 'enabled' || stateNegativeBroad === 'enabled' || 
              stateNegativePhrase === 'enabled' 
            ) { 
              state = 'enabled'; 
          }
          
          flowKeywordList.push({
            keywordText: keywordText,
            state: state,
          });
      }
    });

    // Debug
    console.log('flowKeywordList:', flowKeywordList);

    // Save 
    this.setState({
      flowKeywordList: flowKeywordList, 
    });
  }
  // --------- END: Prepare Flow Keywords --------------- 


  // --------- START: Fetch Bid Recommendation for Keywords --------------- 
  // We need to call api three times i.e. for Broad, Phrase, Exact ad group keywords.
  fetchBidRecommendation_AllKeywords = () => {
    console.log('fetchBidRecommendation_AllKeywords()');

    const { keywordListApi } = this.state;
    //console.log('keywordListApi:', keywordListApi);
    
    // If keyword list empty then return, we can not fetch bid recommendation without keywords
    if (keywordListApi.length === 0 ) { return;}

    const { 
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id, 
    } = this.state.flowDataDb;


    // 1 - Get bid recommendation for Broad ad group related keywords
    if ( manual_broad_ad_group_id ) { // If Broad ad group exist
      
      // 1.1 - Separate keywords belong to broad ad group
      const keywordsBroad = keywordListApi.filter((item, index) => {
        if ( item.adGroupId === manual_broad_ad_group_id ) { 
          return item;
        } 
      });

      // 1.2 - Prepare array of keywords text
      // e.g. ['keywordText1','keywordText2','keywordText3']
      const keywordsTextArrayBroad = keywordsBroad.map( (item, index) => { return item.keywordText; });

      // 1.3 - Call api to get bid recommendation for broad keywords
      setTimeout(() => {
        this.getBidRecommendation_BroadKeywords(keywordsTextArrayBroad);
      }, 500);
    }

    // 2 - Get bid recommendation for Phrase ad group related keywords
    if ( manual_phrase_ad_group_id ) { // If Phrase ad group exist
      
      // 2.1 - Separate keywords belong to phrase ad group
      const keywordsPhrase = keywordListApi.filter((item, index) => {
        if ( item.adGroupId === manual_phrase_ad_group_id ) { 
          return item;
        } 
      });

      // 2.2 - Prepare array of keywords text
      // e.g. ['keywordText1','keywordText2','keywordText3']
      const keywordsTextArrayPhrase = keywordsPhrase.map( (item, index) => { return item.keywordText; });

      // 2.3 - Call api to get bid recommendation for phrase keywords
      setTimeout(() => {
        this.getBidRecommendation_PhraseKeywords(keywordsTextArrayPhrase);
      }, 1000);
    }

    // 3 - Get bid recommendation for Exact ad group related keywords
    if ( manual_exact_ad_group_id ) { // If Exact ad group exist
      
      // 3.1 - Separate keywords belong to exact ad group
      const keywordsExact = keywordListApi.filter((item, index) => {
        if ( item.adGroupId === manual_exact_ad_group_id ) { 
          return item;
        } 
      });

      // 3.2 - Prepare array of keywords text
      // e.g. ['keywordText1','keywordText2','keywordText3']
      const keywordsTextArrayExact = keywordsExact.map( (item, index) => { return item.keywordText; });

      // 3.3 - Call api to get bid recommendation for exact keywords
      setTimeout(() => {
        this.getBidRecommendation_ExactKeywords(keywordsTextArrayExact);
      }, 1500);
    }
  }

  // This function will fetch bid recommendation for given single keyword text.
  // Note: we will call this function whenever new flow keyword added, so we will 
  // fetch bid recommendation for newly added keyword and show within ui.
  // 
  // e.g. @keywordText String e.g. 'Pen Drive Red'  (Single keyword)
  fetchBidRecommendation_SingleKeyword = (keywordText) => {
    console.log('fetchBidRecommendation_SingleKeyword() keywordText:', keywordText);

    const { 
      manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id, 
    } = this.state.flowDataDb;

    // Prepare array from single keyword text
    // e.g. ['keywordText1']
    const keywordsTextArray = [keywordText]; 

    // 1 - Get bid recommendation for Broad ad group (if Broad ad group exist)
    // i.e. Call api to get bid recommendation for Broad match keyword
    if ( manual_broad_ad_group_id ) {
      setTimeout(() => {
        this.getBidRecommendation_BroadKeywords(keywordsTextArray);
      }, 500);
    }

    // 2 - Get bid recommendation for Phrase ad group (if Phrase ad group exist)
    // i.e. Call api to get bid recommendation for Phrase match keyword
    if ( manual_phrase_ad_group_id ) { 
      setTimeout(() => {
        this.getBidRecommendation_PhraseKeywords(keywordsTextArray);
      }, 1000);
    }

    // 3 - Get bid recommendation for Exact ad group (if Exact ad group exist)
    // i.e. Call api to get bid recommendation for Exact match keyword
    if ( manual_exact_ad_group_id ) { 
      setTimeout(() => {
        this.getBidRecommendation_ExactKeywords(keywordsTextArray);
      }, 1500);
    }

  }


  // Call api to get bid recommendation for Broad ad group related keywords
  // @keywordsTextArray ['keywordText1','keywordText2','keywordText3']
  getBidRecommendation_BroadKeywords = (keywordsTextArray) => {
    console.log('getBidRecommendation_BroadKeywords() keywordsTextArray:', keywordsTextArray);
    
    const { profile_id, manual_broad_ad_group_id } = this.state.flowDataDb;
    const adGroupId = manual_broad_ad_group_id;

    // If require data not exist then return
    if ( !profile_id || profile_id === '' ) {
      console.log('profile_id not found, so cant proceed (broad bid recommend)');
      return;
    }
    if ( !adGroupId || adGroupId === '' ) {
      console.log('adGroupId not found, so cant proceed (broad bid recommend)');
      return;
    }
    if ( !keywordsTextArray || keywordsTextArray.length === 0 ) {
      console.log('keywordsTextArray empty, so cant proceed (broad bid recommend)');
    }

    // Prepare keywords data to call api
    // @adGroupId: number i.e. The identifier of the ad group that the keywords are associated with.
    // @keywords: Array 
    // e.g. keywords = [
    //   {
    //     "keyword": "string",
    //     "matchType": "broad"
    //   }
    // ]    
    const keywordsData = [];
    keywordsTextArray.forEach((item, index) => {
      keywordsData.push({
        keyword: item,
        matchType: 'broad'
      })
    });

    // Debug
    // console.log('(Broad) profile_id:', profile_id);
    // console.log('(Broad) adGroupId:', adGroupId);
    // console.log('(Broad) keywordsData:', keywordsData);

    // Call api
    spGetBidRecommendationsForKeywords(profile_id, adGroupId, keywordsData, this.getBidRecommendation_Broad_Success, this.getBidRecommendation_Broad_Error)
  }

  // Called once bid recommendation received (Broad ad group)
  // Sample data received within results
  // e.g. results = { 
  //    status: 'success', 
  //    data: {
  //      "adGroupId": "string",
  //      "recommendations": [
  //        {
  //          "code": "SUCCESS",
  //          "keyword": "string",
  //          "matchType": "phrase",
  //          "suggestedBid": {
  //            "suggested": 0,
  //            "rangeStart": 0,
  //            "rangeEnd": 0
  //          }
  //        }
  //      ]
  //    },
  //    error: null
  //  }  
  getBidRecommendation_Broad_Success = (results) => {
    //console.log('getBidRecommendation_Broad_Success() results: ', results);
    
    // Save received result within state.
    if (results.status === 'success' ) {

      // If any recommendation not received then return, do nothing
      if ( results.data.length === 0 ) { return; }

      // Parse received result and udpate within state
      // Grab existing recommendation from state and update received recommendation within it.
      const bidRecommendation = { ...this.state.bidRecommendationBroad }; // Grab a copy of existing recommendation
      results.data.recommendations.forEach((item, index) => {
        if (item.code === 'SUCCESS') {
          // Insert or update value within dict.
          // If key not exist within dictionary, it will create key:value, otherwise replace value for a key.
          bidRecommendation[item.keyword] = item.suggestedBid; 
        }
      });
      //console.log('(Broad) bidRecommendation:', bidRecommendation);

      // Set updated data within state
      this.setState({
        bidRecommendationBroad: bidRecommendation
      });
    }

    if (results.status === 'error' ) {
      // No need to show error message to user
    }
  }

  // Called if error while calling bid recommendation api (Broad ad group)
  getBidRecommendation_Broad_Error = (error) => {
    console.log('getBidRecommendation_Broad_Error() error: ', error);
    // No need to show error message to user
  }


  // Call api to get bid recommendation for Phrase ad group related keywords
  // @keywordsTextArray ['keywordText1','keywordText2','keywordText3']
  getBidRecommendation_PhraseKeywords = (keywordsTextArray) => {
    console.log('getBidRecommendation_PhraseKeywords() keywordsTextArray:', keywordsTextArray);

    const { profile_id, manual_phrase_ad_group_id } = this.state.flowDataDb;
    const adGroupId = manual_phrase_ad_group_id;

    // If require data not exist then return
    if ( !profile_id || profile_id === '' ) {
      console.log('profile_id not found, so cant proceed (phrase bid recommend)');
      return;
    }
    if ( !adGroupId || adGroupId === '' ) {
      console.log('adGroupId not found, so cant proceed (phrase bid recommend)');
      return;
    }
    if ( !keywordsTextArray || keywordsTextArray.length === 0 ) {
      console.log('keywordsTextArray empty, so cant proceed (phrase bid recommend)');
    }

    // Prepare keywords data to call api
    // @adGroupId: number i.e. The identifier of the ad group that the keywords are associated with.
    // @keywords: Array 
    // e.g. keywords = [
    //   {
    //     "keyword": "string",
    //     "matchType": "phrase"
    //   }
    // ]    
    const keywordsData = [];
    keywordsTextArray.forEach((item, index) => {
      keywordsData.push({
        keyword: item,
        matchType: 'phrase'
      })
    });

    // Debug
    // console.log('(Phrase) profile_id:', profile_id);
    // console.log('(Phrase) adGroupId:', adGroupId);
    // console.log('(Phrase) keywordsData:', keywordsData);

    // Call api
    spGetBidRecommendationsForKeywords(profile_id, adGroupId, keywordsData, this.getBidRecommendation_Phrase_Success, this.getBidRecommendation_Phrase_Error)
  }

  // Called once bid recommendation received (Phrase ad group)
  // Sample data received within results
  // e.g. results = { 
  //    status: 'success', 
  //    data: {
  //      "adGroupId": "string",
  //      "recommendations": [
  //        {
  //          "code": "SUCCESS",
  //          "keyword": "string",
  //          "matchType": "phrase",
  //          "suggestedBid": {
  //            "suggested": 0,
  //            "rangeStart": 0,
  //            "rangeEnd": 0
  //          }
  //        }
  //      ]
  //    },
  //    error: null
  //  }  
  getBidRecommendation_Phrase_Success = (results) => {
    //console.log('getBidRecommendation_Phrase_Success() results: ', results);
    
    // Save received result within state.
    if (results.status === 'success' ) {
      
      // If any recommendation not received then return, do nothing
      if ( results.data.length === 0 ) { return; }

      // Parse received result and udpate within state
      // Grab existing recommendation from state and update received recommendation within it.
      const bidRecommendation = { ...this.state.bidRecommendationPhrase }; // Grab a copy of existing recommendation
      results.data.recommendations.forEach((item, index) => {
        if (item.code === 'SUCCESS') {
          // Insert or update value within dict.
          // If key not exist within dictionary, it will create key:value, otherwise replace value for a key.
          bidRecommendation[item.keyword] = item.suggestedBid; 
        }
      });
      //console.log('(Phrase) bidRecommendation:', bidRecommendation);

      // Set updated data within state
      this.setState({
        bidRecommendationPhrase: bidRecommendation
      });
    }

    if (results.status === 'error' ) {
      // No need to show error message to user
    }
  }

  // Called if error while calling bid recommendation api (Phrase ad group)
  getBidRecommendation_Phrase_Error = (error) => {
    console.log('getBidRecommendation_Phrase_Error() error: ', error);
    // No need to show error message to user
  }


  // Call api to get bid recommendation for Exact ad group related keywords
  // @keywordsTextArray ['keywordText1','keywordText2','keywordText3'] 
  getBidRecommendation_ExactKeywords = (keywordsTextArray) => {
    console.log('getBidRecommendation_ExactKeywords() keywordsTextArray:', keywordsTextArray);    
  
    const { profile_id, manual_exact_ad_group_id } = this.state.flowDataDb;
    const adGroupId = manual_exact_ad_group_id;

    // If require data not exist then return
    if ( !profile_id || profile_id === '' ) {
      console.log('profile_id not found, so cant proceed (exact bid recommend)');
      return;
    }
    if ( !adGroupId || adGroupId === '' ) {
      console.log('adGroupId not found, so cant proceed (exact bid recommend)');
      return;
    }
    if ( !keywordsTextArray || keywordsTextArray.length === 0 ) {
      console.log('keywordsTextArray empty, so cant proceed (exact bid recommend)');
    }

    // Prepare keywords data to call api
    // @adGroupId: number i.e. The identifier of the ad group that the keywords are associated with.
    // @keywords: Array 
    // e.g. keywords = [
    //   {
    //     "keyword": "string",
    //     "matchType": "exact"
    //   }
    // ]    
    const keywordsData = [];
    keywordsTextArray.forEach((item, index) => {
      keywordsData.push({
        keyword: item,
        matchType: 'exact'
      })
    });

    // Debug
    // console.log('(Exact) profile_id:', profile_id);
    // console.log('(Exact) adGroupId:', adGroupId);
    // console.log('(Exact) keywordsData:', keywordsData);

    // Call api
    spGetBidRecommendationsForKeywords(profile_id, adGroupId, keywordsData, this.getBidRecommendation_Exact_Success, this.getBidRecommendation_Exact_Error)
  }

  // Called once bid recommendation received (Exact ad group)
  // Sample data received within results
  // e.g. results = { 
  //    status: 'success', 
  //    data: {
  //      "adGroupId": "string",
  //      "recommendations": [
  //        {
  //          "code": "SUCCESS",
  //          "keyword": "string",
  //          "matchType": "exact",
  //          "suggestedBid": {
  //            "suggested": 0,
  //            "rangeStart": 0,
  //            "rangeEnd": 0
  //          }
  //        }
  //      ]
  //    },
  //    error: null
  //  }  
  getBidRecommendation_Exact_Success = (results) => {
    //console.log('getBidRecommendation_Exact_Success() results: ', results);
    
    // Save received result within state.
    if (results.status === 'success' ) {
      
      // If any recommendation not received then return, do nothing
      if ( results.data.length === 0 ) { return; }

      // Parse received result and udpate within state
      // Grab existing recommendation from state and update received recommendation within it.
      const bidRecommendation = { ...this.state.bidRecommendationExact }; // Grab a copy of existing recommendation
      results.data.recommendations.forEach((item, index) => {
        if (item.code === 'SUCCESS') {
          // Insert or update value within dict.
          // If key not exist within dictionary, it will create key:value, otherwise replace value for a key.
          bidRecommendation[item.keyword] = item.suggestedBid; 
        }
      });
      //console.log('(Exact) bidRecommendation:', bidRecommendation);

      // Set updated data within state
      this.setState({
        bidRecommendationExact: bidRecommendation
      });
    }

    if (results.status === 'error' ) {
      // No need to show error message to user
    }
  }

  // Called if error while calling bid recommendation api (Phrase ad group)
  getBidRecommendation_Exact_Error = (error) => {
    console.log('getBidRecommendation_Exact_Error() error: ', error);
    // No need to show error message to user
  }
  // --------- END: Fetch Bid Recommendation for Keywords --------------- 

  //-----------------------------------------------------------------
  // End: Data fetch related functions
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Edit keyword (from Broad Ad Group)
  //-----------------------------------------------------------------
  // Edit button tapped from Single Keyword - Broad Match
  onClickEdit_Keyword_Broad = (keywordInfo, index) => {
    console.log('onClickEdit_Keyword_Broad() index:', index);

    // Enable edit mode for clicked keyword
    this.setState({
      editBroad: true, 
      editBroadIndex: index,
      editBroadKeywordId: keywordInfo.keywordId,
      editBroadBidAmount: keywordInfo.bid,
      editBroadInProgress: false,
    });
  }

  // Called when user change bid amount for keyword (broad)
  onChange_BidAmount_Broad_Keyword = (value) => {
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ 
      editBroadBidAmount: value
    });
  }

  // Called when save button tapped (save change keyword)
  onClickEdit_Save_Keyword_Broad = (keywordInfo, index) => {
    console.log('onClickEdit_Save_Keyword_Broad() index:', index);
    //console.log('onClickEdit_Save_Keyword_Broad() index:', index, ' keywordInfo:', keywordInfo);

    // 1 - Fetch necessary data from state
    const { profile_id } = this.state.flowDataDb;
    const { editBroadBidAmount } = this.state;

    // 2 - If valid data not exist then return
    if (!editBroadBidAmount || editBroadBidAmount === '' ) {
      this.setState({ 
        messageText: 'Please enter bid amount', 
        showMessage: true, 
      });
      return;
    }
    if ( parseFloat(editBroadBidAmount) < 0.02 ) {
      this.setState({ 
        messageText: 'Minumum bid should be $0.02', 
        showMessage: true, 
      });
      return;
    }
    if ( !keywordInfo ) {
      console.log('Keyword info not exist, so return');
      this.setState({
        messageText: 'Keyword info not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if ( !keywordInfo.keywordId ) {
      console.log('keywordId not exist, so return');
      this.setState({
        messageText: 'keywordId not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id || profile_id === '' ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Set Processing on 
    this.setState({
      isProcessing: true,
      editBroadInProgress: true, 
    });

    // 4 - Prepare Arrary of data to update one or more Keywords
    // Note: We have to update 'bid' only, so pass the 'bid' key:value 
    // e.g. updateData = [
    //   {
    //      "keywordId": 0,
    //      "state": "enabled",
    //      "bid": 0,
    //   }
    // ]
    const updateDataArray = [];
    const updateData1 = {
      "keywordId": keywordInfo.keywordId,
      "bid": parseFloat(editBroadBidAmount),
    }
    updateDataArray.push(updateData1);
    console.log('Update Keyword Bid (Broad) - updateDataArray:', updateDataArray);

    // 8 - Call api to update keyword bid amount
    spUpdateKeywords(profile_id, updateDataArray, this.updateKeywordsBid_Broad_Success, this.updateKeywordsBid_Broad_Error);
  }

  // Called once keyword bid amount updated successfully
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  updateKeywordsBid_Broad_Success = async (result) => {
    console.log('updateKeywordsBid_Broad_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
    
      // Refresh updated keyword info, so updated bid amount shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenKeywordListViaApi(keywordIds); }, 10);

      // Consider keyword updated
      this.setAsDone_UpdateKeywordsBid_Broad();
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_UpdateKeywordsBid_Broad();
    }
  }
  
  // Called if any error while update keyword bid amount
  updateKeywordsBid_Broad_Error = () => {
    console.log('updateKeywordsBid_Broad_Error() error:', error);
    
    this.showError_UpdateKeywordsBid_Broad();
  }

  // Show error message 
  showError_UpdateKeywordsBid_Broad = (message) => {
    let msg = 'Can not update bid amount this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
  
    this.setState({
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 

      editBroad: false, 
      editBroadIndex: -1,
      editBroadKeywordId: 0,
      editBroadBidAmount: 0,
      editBroadInProgress: false,
    }); 
  }

  // Consider keyword bid updated successfully.
  // Set processing and other parameter within state to false.
  setAsDone_UpdateKeywordsBid_Broad = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        
        editBroad: false, 
        editBroadIndex: -1,
        editBroadKeywordId: 0,
        editBroadBidAmount: 0,
        editBroadInProgress: false,
      });
    }, 3000);
  } 

  // Called when cancel button tapped (cancel keyword)
  onClickEdit_Cancel_Keyword_Broad = (keywordInfo, index) => {
    console.log('onClickEdit_Cancel_Keyword_Broad() index: ', index);

    // Enable edit mode for clicked keyword
    this.setState({
      editBroad: false, 
      editBroadIndex: -1,
      editBroadKeywordId: 0,
      editBroadBidAmount: 0,
      editBroadInProgress: false,
    });
  }

  //-----------------------------------------------------------------
  // End: Edit keyword (from Broad Ad Group)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Edit keyword (from Phrase Ad Group)
  //-----------------------------------------------------------------
  // Edit button tapped from Single Keyword - Phrase Match  
  onClickEdit_Keyword_Phrase = (keywordInfo, index) => {
    console.log('onClickEdit_Keyword_Phrase() index:', index);

    // Enable edit mode for clicked keyword
    this.setState({
      editPhrase: true, 
      editPhraseIndex: index,
      editPhraseKeywordId: keywordInfo.keywordId,
      editPhraseBidAmount: keywordInfo.bid,
      editPhraseInProgress: false,
    });
  }

  // Called when user change bid amount for keyword (phrase)
  onChange_BidAmount_Phrase_Keyword = (value) => {
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ 
      editPhraseBidAmount: value
    });
  }

  // Called when save button tapped (save change keyword)
  onClickEdit_Save_Keyword_Phrase = (keywordInfo, index) => {
    console.log('onClickEdit_Save_Keyword_Phrase() index:', index);
    //console.log('onClickEdit_Save_Keyword_Phrase() index:', index, ' keywordInfo:', keywordInfo);

    // 1 - Fetch necessary data from state
    const { profile_id } = this.state.flowDataDb;
    const { editPhraseBidAmount } = this.state;

    // 2 - If valid data not exist then return
    if (!editPhraseBidAmount || editPhraseBidAmount === '' ) {
      this.setState({ 
        messageText: 'Please enter bid amount', 
        showMessage: true, 
      });
      return;
    }
    if ( parseFloat(editPhraseBidAmount) < 0.02 ) {
      this.setState({ 
        messageText: 'Minumum bid should be $0.02', 
        showMessage: true, 
      });
      return;
    }
    if ( !keywordInfo ) {
      console.log('Keyword info not exist, so return');
      this.setState({
        messageText: 'Keyword info not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if ( !keywordInfo.keywordId ) {
      console.log('keywordId not exist, so return');
      this.setState({
        messageText: 'keywordId not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id || profile_id === '' ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Set Processing on 
    this.setState({
      isProcessing: true,
      editPhraseInProgress: true, 
    });

    // 4 - Prepare Arrary of data to update one or more Keywords
    // Note: We have to update 'bid' only, so pass the 'bid' key:value 
    // e.g. updateData = [
    //   {
    //      "keywordId": 0,
    //      "state": "enabled",
    //      "bid": 0,
    //   }
    // ]
    const updateDataArray = [];
    const updateData1 = {
      "keywordId": keywordInfo.keywordId,
      "bid": parseFloat(editPhraseBidAmount),
    }
    updateDataArray.push(updateData1);
    console.log('Update Keyword Bid (Phrase) - updateDataArray:', updateDataArray);

    // 8 - Call api to update keyword bid amount
    spUpdateKeywords(profile_id, updateDataArray, this.updateKeywordsBid_Phrase_Success, this.updateKeywordsBid_Phrase_Error);
  }

  // Called once keyword bid amount updated successfully
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  updateKeywordsBid_Phrase_Success = async (result) => {
    console.log('updateKeywordsBid_Phrase_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
    
      // Refresh updated keyword info, so updated bid amount shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenKeywordListViaApi(keywordIds); }, 10);

      // Consider keyword updated
      this.setAsDone_UpdateKeywordsBid_Phrase();
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_UpdateKeywordsBid_Phrase();
    }
  }
  
  // Called if any error while update keyword bid amount
  updateKeywordsBid_Phrase_Error = () => {
    console.log('updateKeywordsBid_Phrase_Error() error:', error);
    
    this.showError_UpdateKeywordsBid_Phrase();
  }

  // Show error message 
  showError_UpdateKeywordsBid_Phrase = (message) => {
    let msg = 'Can not update bid amount this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
  
    this.setState({
      messageText: msg, 
      showMessage: true, 
      isProcessing: false, 

      editPhrase: false, 
      editPhraseIndex: -1,
      editPhraseKeywordId: 0,
      editPhraseBidAmount: 0,
      editPhraseInProgress: false,
    }); 
  }

  // Consider keyword updated successfully.
  // Set processing and other parameter within state to false.
  setAsDone_UpdateKeywordsBid_Phrase = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        
        editPhrase: false, 
        editPhraseIndex: -1,
        editPhraseKeywordId: 0,
        editPhraseBidAmount: 0,
        editPhraseInProgress: false,
      });
    }, 3000);
  } 

  // Called when cancel button tapped (cancel keyword)
  onClickEdit_Cancel_Keyword_Phrase = (keywordInfo, index) => {
    console.log('onClickEdit_Cancel_Keyword_Phrase() index: ', index);

    // Enable edit mode for clicked keyword
    this.setState({
      editPhrase: false, 
      editPhraseIndex: -1,
      editPhraseKeywordId: 0,
      editPhraseBidAmount: 0,
      editPhraseInProgress: false,
    });
  }

  //-----------------------------------------------------------------
  // End: Edit keyword (from Phrase Ad Group)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Edit keyword (from Exact Ad Group)
  //-----------------------------------------------------------------
  // Edit button tapped from Single Keyword - Exact Match
  onClickEdit_Keyword_Exact = (keywordInfo, index) => {
    console.log('onClickEdit_Keyword_Exact() index:', index);

    // Enable edit mode for clicked keyword
    this.setState({
      editExact: true, 
      editExactIndex: index,
      editExactKeywordId: keywordInfo.keywordId,
      editExactBidAmount: keywordInfo.bid,
      editExactInProgress: false,
    });
  }

  // Called when user change bid amount for keyword (phrase)
  onChange_BidAmount_Exact_Keyword = (value) => {
    // 1 - If entered value is not a number then return.
    const isNotNumber = isNaN(value);
    if (isNotNumber) { return; }  // Not a number

    // 2 - Do not allow more than 99
    if ( value > 99 ) { return; }

    // 3 - Set value within state
    this.setState({ 
      editExactBidAmount: value
    });  
  }

  // Called when save button tapped (save change keyword)
  onClickEdit_Save_Keyword_Exact = (keywordInfo, index) => {
    console.log('onClickEdit_Save_Keyword_Exact() index:', index);
    //console.log('onClickEdit_Save_Keyword_Exact() index:', index, ' keywordInfo:', keywordInfo);

    // 1 - Fetch necessary data from state
    const { profile_id } = this.state.flowDataDb;
    const { editExactBidAmount } = this.state;

    // 2 - If valid data not exist then return
    if (!editExactBidAmount || editExactBidAmount === '' ) {
      this.setState({ 
        messageText: 'Please enter bid amount', 
        showMessage: true, 
      });
      return;
    }
    if ( parseFloat(editExactBidAmount) < 0.02 ) {
      this.setState({ 
        messageText: 'Minumum bid should be $0.02', 
        showMessage: true, 
      });
      return;
    }
    if ( !keywordInfo ) {
      console.log('Keyword info not exist, so return');
      this.setState({
        messageText: 'Keyword info not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if ( !keywordInfo.keywordId ) {
      console.log('keywordId not exist, so return');
      this.setState({
        messageText: 'keywordId not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id || profile_id === '' ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Set Processing on 
    this.setState({
      isProcessing: true,
      editExactInProgress: true, 
    });

    // 4 - Prepare Arrary of data to update one or more Keywords
    // Note: We have to update 'bid' only, so pass the 'bid' key:value 
    // e.g. updateData = [
    //   {
    //      "keywordId": 0,
    //      "state": "enabled",
    //      "bid": 0,
    //   }
    // ]
    const updateDataArray = [];
    const updateData1 = {
      "keywordId": keywordInfo.keywordId,
      "bid": parseFloat(editExactBidAmount),
    }
    updateDataArray.push(updateData1);
    console.log('Update Keyword Bid (Exact) - updateDataArray:', updateDataArray);

    // 8 - Call api to update keyword bid amount
    spUpdateKeywords(profile_id, updateDataArray, this.updateKeywordsBid_Exact_Success, this.updateKeywordsBid_Exact_Error);
  }

  // Called once keyword bid amount updated successfully
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  updateKeywordsBid_Exact_Success = async (result) => {
    console.log('updateKeywordsBid_Exact_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
    
      // Refresh updated keyword info, so updated bid amount shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenKeywordListViaApi(keywordIds); }, 10);

      // Consider keyword updated
      this.setAsDone_UpdateKeywordsBid_Exact();
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_UpdateKeywordsBid_Exact();
    }
  }

  // Called if any error while update keyword bid amount
  updateKeywordsBid_Exact_Error = () => {
    console.log('updateKeywordsBid_Exact_Error() error:', error);
    
    this.showError_UpdateKeywordsBid_Exact();
  }

  // Show error message 
  showError_UpdateKeywordsBid_Exact = (message) => {
    let msg = 'Can not update bid amount this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
  
    this.setState({
      messageText: msg, 
      showMessage: true, 
      isProcessing: false, 

      editExact: false, 
      editExactIndex: -1,
      editExactKeywordId: 0,
      editExactBidAmount: 0,
      editExactInProgress: false,
    }); 
  }

  // Consider keyword bid updated successfully.
  // Set processing and other parameter within state to false.
  setAsDone_UpdateKeywordsBid_Exact = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        
        editExact: false, 
        editExactIndex: -1,
        editExactKeywordId: 0,
        editExactBidAmount: 0,
        editExactInProgress: false,
      });
    }, 3000);
  } 

  // Called when cancel button tapped (cancel keyword)
  onClickEdit_Cancel_Keyword_Exact = (keywordInfo, index) => {
    console.log('onClickEdit_Cancel_Keyword_Exact() index: ', index);

    // Enable edit mode for clicked keyword
    this.setState({
      editExact: false, 
      editExactIndex: -1,
      editExactKeywordId: 0,
      editExactBidAmount: 0,
      editExactInProgress: false,
    });
  }
  //-----------------------------------------------------------------
  // End: Edit keyword (from Exact Ad Group)
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Edit Negative keyword (from Auto Ad Group)
  //-----------------------------------------------------------------
  
  // Called when keyword state toggle done (for negative keyword - Auto ad group)
  // Note: state toggle switch shows with negative keyword if it is not a flow keyword.
  toggleState_NegativeKeyword_Auto = (checked, keywordInfo, index) => {
    console.log('toggleState_NegativeKeyword_Auto() checked:', checked, 'index:', index);
    //console.log('toggleState_NegativeKeyword_Auto() checked:', checked, 'index:', index, 'keywordInfo:', keywordInfo);

    // 1 - Fetch necessary data from state
    const { profile_id } = this.state.flowDataDb;
    //console.log('profile_id:', profile_id);

    // 2 - If valid data not exist then return
    if ( !keywordInfo ) {
      console.log('Keyword info not exist, so return');
      this.setState({
        messageText: 'Keyword info not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if ( !keywordInfo.keywordId ) {
      console.log('keywordId not exist, so return');
      this.setState({
        messageText: 'keywordId not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Decide new state for keyword based on the toogle state.
    const newState = checked ? 'enabled' : 'paused';
    //console.log('newState:', newState);

    // 4 - Set Processing on 
    this.setState({
      isProcessing: true,
      isUpdatingNegativeKeywordAuto: true,
      updatingNegativeKeywordIdAuto: keywordInfo.keywordId,
    })

    // 5 - Prepare data to update keyword
    // i.e Arrary of data to update one or more Negative Keywords
    // e.g. updateData = [
    //   {
    //      "keywordId": 0,
    //      "state": "enabled",
    //   }
    // ]
    const updateDataArray = [];
    const updateData1 = {
      "keywordId": keywordInfo.keywordId,
      "state": newState ,
    }
    updateDataArray.push(updateData1);
    console.log('toggleState NegativeKeyword Auto - updateDataArray:', updateDataArray);

    // 8 - Call api to update negative keyword state
    spUpdateNegativeKeywords(profile_id, updateDataArray, this.toggleState_NegativeKeyword_Auto_Success, this.toggleState_NegativeKeyword_Auto_Error);
  }
  
  // Called once negative keyword state updated successfully
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  toggleState_NegativeKeyword_Auto_Success = async (result) => {
    console.log('toggleState_NegativeKeyword_Auto_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
    
      // Refresh negative keyword list, so update state shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);

      // Consider negative keyword created
      this.setAsDone_ToggleState_NegativeKeyword_Auto();
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_ToggleState_NegativeKeyword_Auto();
    }
  }
  
  // Called if any error while update state
  toggleState_NegativeKeyword_Auto_Error = () => {
    console.log('toggleState_NegativeKeyword_Auto_Error() error:', error);
    this.showError_ToggleState_NegativeKeyword_Auto();
  }

 // Show error message 
  showError_ToggleState_NegativeKeyword_Auto = (message) => {
    let msg = 'Can not update state this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
  
    this.setState({
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingNegativeKeywordAuto: false,
      updatingNegativeKeywordIdAuto: 0,
    }); 
  }

  // Consider negative keyword state updated successfully (under auto ad group).
  // Set processing and other parameter within state to false.
  setAsDone_ToggleState_NegativeKeyword_Auto = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        isUpdatingNegativeKeywordAuto: false,
        updatingNegativeKeywordIdAuto: 0,
      });
    }, 3000);
  } 

  //-----------------------------------------------------------------
  // End: Edit Negative keyword (from Auto Ad Group)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Edit Negative keyword (from Broad Ad Group)
  //-----------------------------------------------------------------

  // Called when keyword state toggle done (for negative keyword - Broad ad group)
  // Note: state toggle switch shows with negative keyword if it is not a flow keyword.
  toggleState_NegativeKeyword_Broad = (checked, keywordInfo, index) => {
    console.log('toggleState_NegativeKeyword_Broad() checked:', checked, 'index:', index);
    //console.log('toggleState_NegativeKeyword_Broad() checked:', checked, 'index:', index, 'keywordInfo:', keywordInfo);

    // 1 - Fetch necessary data from state
    const { profile_id } = this.state.flowDataDb;
  
    // 2 - If valid data not exist then return
    if ( !keywordInfo ) {
      console.log('Keyword info not exist, so return');
      this.setState({
        messageText: 'Keyword info not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if ( !keywordInfo.keywordId ) {
      console.log('keywordId not exist, so return');
      this.setState({
        messageText: 'keywordId not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
  
    // 3 - Decide new state for keyword based on the toogle state.
    const newState = checked ? 'enabled' : 'paused';
    
    // 4 - Set Processing on 
    this.setState({
      isProcessing: true,
      isUpdatingNegativeKeywordBroad: true,
      updatingNegativeKeywordIdBroad: keywordInfo.keywordId,
    })
    
    // 5 - Prepare data to update keyword
    // i.e Arrary of data to update one or more Negative Keywords
    // e.g. updateData = [
    //   {
    //      "keywordId": 0,
    //      "state": "enabled",
    //   }
    // ]
    const updateDataArray = [];
    const updateData1 = {
      "keywordId": keywordInfo.keywordId,
      "state": newState ,
    }
    updateDataArray.push(updateData1);
    console.log('toggleState NegativeKeyword Broad - updateDataArray:', updateDataArray);

    // 8 - Call api to update negative keyword state
    spUpdateNegativeKeywords(profile_id, updateDataArray, this.toggleState_NegativeKeyword_Broad_Success, this.toggleState_NegativeKeyword_Broad_Error);
  }

  // Called once negative keyword state updated successfully
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  toggleState_NegativeKeyword_Broad_Success = async (result) => {
    console.log('toggleState_NegativeKeyword_Broad_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
    
      // Refresh negative keyword list, so update state shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);

      // Consider negative keyword created
      this.setAsDone_ToggleState_NegativeKeyword_Broad();
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_ToggleState_NegativeKeyword_Broad();
    }
  }
  
  // Called if any error while update state
  toggleState_NegativeKeyword_Broad_Error = () => {
    console.log('toggleState_NegativeKeyword_Broad_Error() error:', error);
    
    this.showError_ToggleState_NegativeKeyword_Broad();
  }

 // Show error message 
  showError_ToggleState_NegativeKeyword_Broad = (message) => {
    let msg = 'Can not update state this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
  
    this.setState({
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingNegativeKeywordBroad: false,
      updatingNegativeKeywordIdBroad: 0,
    }); 
  }

  // Consider negative keyword state updated successfully (under broad ad group).
  // Set processing and other parameter within state to false.
  setAsDone_ToggleState_NegativeKeyword_Broad = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        isUpdatingNegativeKeywordBroad: false,
        updatingNegativeKeywordIdBroad: 0,
      });
    }, 3000);
  } 

  //-----------------------------------------------------------------
  // End: Edit Negative keyword (from Broad Ad Group)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Edit Negative keyword (from Phrase Ad Group)
  //-----------------------------------------------------------------
  // Called when keyword state toggle done (for negative keyword - Phrase ad group)
  // Note: state toggle switch shows with negative keyword if it is not a flow keyword.
  toggleState_NegativeKeyword_Phrase = (checked, keywordInfo, index) => {
    console.log('toggleState_NegativeKeyword_Phrase() checked:', checked, 'index:', index);
    //console.log('toggleState_NegativeKeyword_Phrase() checked:', checked, 'index:', index, 'keywordInfo:', keywordInfo);

    // 1 - Fetch necessary data from state
    const { profile_id } = this.state.flowDataDb;
  
    // 2 - If valid data not exist then return
    if ( !keywordInfo ) {
      console.log('Keyword info not exist, so return');
      this.setState({
        messageText: 'Keyword info not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if ( !keywordInfo.keywordId ) {
      console.log('keywordId not exist, so return');
      this.setState({
        messageText: 'keywordId not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
  
    // 3 - Decide new state for keyword based on the toogle state.
    const newState = checked ? 'enabled' : 'paused';
    
    // 4 - Set Processing on 
    this.setState({
      isProcessing: true,
      isUpdatingNegativeKeywordPhrase: true,
      updatingNegativeKeywordIdPhrase: keywordInfo.keywordId,
    })
    
    // 5 - Prepare data to update keyword
    // i.e Arrary of data to update one or more Negative Keywords
    // e.g. updateData = [
    //   {
    //      "keywordId": 0,
    //      "state": "enabled",
    //   }
    // ]
    const updateDataArray = [];
    const updateData1 = {
      "keywordId": keywordInfo.keywordId,
      "state": newState ,
    }
    updateDataArray.push(updateData1);
    console.log('toggleState NegativeKeyword Phrase - updateDataArray:', updateDataArray);

    // 8 - Call api to update negative keyword state
    spUpdateNegativeKeywords(profile_id, updateDataArray, this.toggleState_NegativeKeyword_Phrase_Success, this.toggleState_NegativeKeyword_Phrase_Error);
  }

  // Called once negative keyword state updated successfully
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  toggleState_NegativeKeyword_Phrase_Success = async (result) => {
    console.log('toggleState_NegativeKeyword_Phrase_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
    
      // Refresh negative keyword list, so update state shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);

      // Consider negative keyword created
      this.setAsDone_ToggleState_NegativeKeyword_Phrase();
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_ToggleState_NegativeKeyword_Phrase();
    }
  }
  
  // Called if any error while update state
  toggleState_NegativeKeyword_Phrase_Error = () => {
    console.log('toggleState_NegativeKeyword_Phrase_Error() error:', error);
    
    this.showError_ToggleState_NegativeKeyword_Phrase();
  }

 // Show error message 
  showError_ToggleState_NegativeKeyword_Phrase = (message) => {
    let msg = 'Can not update state this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
  
    this.setState({
      messageText: msg,
      showMessage: true, 
      isProcessing: false, 
      isUpdatingNegativeKeywordPhrase: false,
      updatingNegativeKeywordIdPhrase: 0,
    }); 
  }

  // Consider negative keyword state updated successfully (under broad ad group).
  // Set processing and other parameter within state to false.
  setAsDone_ToggleState_NegativeKeyword_Phrase = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        isUpdatingNegativeKeywordPhrase: false,
        updatingNegativeKeywordIdPhrase: 0,
      });
    }, 3000);
  } 

  //-----------------------------------------------------------------
  // End: Edit Negative keyword (from Phrase Ad Group)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Add new keyword (Flow Keyword)
  //-----------------------------------------------------------------
  // Called when add button tapped from add keyword form
  // It will add new keyword to all appropriate ad group and match type.
  // i.e. Create keyword under broad, phrase, exact ad group and 
  //          negative keyword under auto, broad, phrase ad group.
  // 
  // IMPORTANT: We have to follow below rules
  // - If Exact Ad Group not created then do not create negative keyword under any ad group.
  // - If new keyword missing under some ad group then create missing keyword to 
  // fill up the gap, so that keyword become a flow keyword. But for this also take care 
  // that if exact ad group not created then do not create negative keyword under 
  // any ad group.
  // 
  onClickAddKeyword = () => {
    console.log('onClickAddKeyword()');

    // 1 - If input empty then return
    const { inputKeyword } = this.state;
    if ( inputKeyword.trim().length === 0 ) { 
      console.log('inputKeyword empty, so return');
      return; 
    }
    // Trim all white space also replace multiple space between words with single space etc.
    const newKeyword = this.removeExtraWhiteSpace(inputKeyword);
    console.log('newKeyword:', newKeyword);

    // 2 - Read other value
    const { keywordListApi, negativeKeywordListApi } = this.state;
    const {
      profile_id,
      auto_campaign_id, auto_ad_group_id, 
      manual_campaign_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id, 
    } = this.state.flowDataDb;

    // 2A - If profile id not exist within data, then return 
    // We can not call api without profile_id
    console.log('profile_id:', profile_id);
    if ( !profile_id || profile_id === '' ) {
      console.log('profile_id empty or not found, so return');
      this.setState({
        messageText: 'profile_id not exist, so can not proceed further.',
        showMessage: true,
      });
      return; // Important
    }

    // 3 - We have to check that given keyword exist under how many adgroup.
    // If keyword exist under all ad group then no need to add again.
    let isExistBroad = false;  // Keyword exist with broad match (for Broad AdGroup)
    let isExistPhrase = false; // Keyword exist with phrase match (for Phrase AdGroup)
    let isExistExact = false;  // Keyword exist with exact match (for Exact AdGroup)
    let isExistNegativeAuto = false; // Negative Keyword exist with negativeExact match (for Auto AdGroup)
    let isExistNegativeBroad = false; // Negative Keyword exist with negativeExact match (for Broad AdGroup)
    let isExistNegativePhrase = false; // NegativeKeyword exist with negativeExact match (for Phrase AdGroup)

    // 4A - Check that Keyword exist with broad match (for Broad AdGroup)
    if ( manual_broad_ad_group_id ) { // Broad ad group exist or created
      const keywordList = keywordListApi.filter( (item, index) => { 
        if( item.keywordText.toLowerCase() === newKeyword.toLowerCase() && 
            item.adGroupId === manual_broad_ad_group_id && 
            item.matchType === 'broad'
          ) { return true; } else { return false; } 
      });
      //console.log("(Broad) keywordList:", keywordList);
      if (keywordList.length > 0) { isExistBroad = true; }
    } else { // Broad ad group not created yet, so consider keyword exist
      isExistBroad = true;
    }

    // 4B - Check that Keyword exist with phrase match (for Phrase AdGroup)
    if ( manual_phrase_ad_group_id ) { // Phrase ad group exist or created
      const keywordList = keywordListApi.filter( (item, index) => { 
        if ( item.keywordText.toLowerCase() === newKeyword.toLowerCase() &&
              item.adGroupId === manual_phrase_ad_group_id && 
              item.matchType === 'phrase' 
            ) { return true; } else { return false; } 
      });
      //console.log("(Phrase) keywordList:", keywordList);
      if (keywordList.length > 0) { isExistPhrase = true; }
    } else { // phrase ad group not created yet, so consider keyword exist
      isExistPhrase = true;
    }

    // 4C - Check that Keyword exist with exact match (for Phrase AdGroup)
    if ( manual_exact_ad_group_id ) { // Exact ad group exist or created
      const keywordList = keywordListApi.filter( (item, index) => { 
        if( item.keywordText.toLowerCase() === newKeyword.toLowerCase() &&
            item.adGroupId === manual_exact_ad_group_id && 
            item.matchType === 'exact' 
          ) { return true; } else { return false; } 
      });
      //console.log("(Exact) keywordList:", keywordList);
      if (keywordList.length > 0) { isExistExact = true; }
    } else { // exact ad group not created yet, so consider keyword exist
      isExistExact = true;
    }

    // 4D - Negative Keyword exist with negativeExact match (for Auto AdGroup)
    if ( auto_ad_group_id ) { // Auto ad group exist or created
      const keywordList = negativeKeywordListApi.filter( (item, index) => { 
        if( item.keywordText.toLowerCase() === newKeyword.toLowerCase() && 
            item.adGroupId === auto_ad_group_id && 
            item.matchType === 'negativeExact' 
          ) { return true; } else { return false; }
      });
      //console.log("(Auto) NegativeKeywordList:", keywordList); 
      if (keywordList.length > 0) { isExistNegativeAuto = true; }
    } else { // Auto ad group not created yet, so consider negative keyword exist
      isExistNegativeAuto = true;
    }

    // 4E - Negative Keyword exist with negativeExact match (for Broad AdGroup)
    if ( manual_broad_ad_group_id ) { // Broad ad group exist or created
      const keywordList = negativeKeywordListApi.filter( (item, index) => { 
        if( item.keywordText.toLowerCase() === newKeyword.toLowerCase() && 
            item.adGroupId === manual_broad_ad_group_id && 
            item.matchType === 'negativeExact' 
          ) { return true; } else { return false; }
      });
      //console.log("(Broad) NegativeKeywordList:", keywordList); 
      if (keywordList.length > 0) { isExistNegativeBroad = true; }
    } else { // Broad ad group not created yet, so consider negative keyword exist
      isExistNegativeBroad = true;
    }

    // 4F - Negative Keyword exist with negativeExact match (for Phrase AdGroup)
    if ( manual_phrase_ad_group_id ) { // Phrase ad group exist or created
      const keywordList = negativeKeywordListApi.filter( (item, index) => { 
        if( item.keywordText.toLowerCase() === newKeyword.toLowerCase() && 
            item.adGroupId === manual_phrase_ad_group_id && 
            item.matchType === 'negativeExact' 
          ) { return true; } else { return false; }
      });
      //console.log("(Phrase) NegativeKeywordList:", keywordList); 
      if (keywordList.length > 0) { isExistNegativePhrase = true; }
    } else { // Phrase ad group not created yet, so consider negative keyword exist
      isExistNegativePhrase = true;
    }

    // Debug
    console.log('isExistBroad:', isExistBroad);
    console.log('isExistPhrase:', isExistPhrase);
    console.log('isExistExact:', isExistExact);
    console.log('isExistNegativeAuto:', isExistNegativeAuto);
    console.log('isExistNegativeBroad:', isExistNegativeBroad);
    console.log('isExistNegativePhrase:', isExistNegativePhrase);

    // 5 - If keyword already exist under all ad group (that exist at moment) 
    // then show message that keyword already exist, so can not ad again.
    if ( isExistBroad && isExistPhrase && isExistExact & 
         isExistNegativeAuto && isExistNegativeBroad && isExistNegativePhrase ) {
      this.setState({
        messageText: 'Keyword already exist, so can not add again.',
        showMessage: true,
      });
      return; // Important
    }

    // 6 - At this step keyword not exist under all ad group, so we are going to 
    // create necessary positive and negative keywords under existing ad group.
    // Also set processing on, so user can not tap button again.
    this.setState({
      isProcessing: true,
      isCreatingFlowKeyword: true, 
      isCreatingFlowNegativeKeyword: true, 
    });

    // 7 - Prepare data to create (positive) keywords
    // We have to pass data as an array either create one or more keyword, 
    // because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "exact",
    //     "bid": 0
    //   }
    // ] 
    const keywordDataArray = []; 

    // 7A - If keyword not exist under Broad ad group then create it.
    if( !isExistBroad ) {
      const bidAmountBroad = this.getDefaultBidForAdGroup(manual_broad_ad_group_id);
      const keywordData = {
        campaignId: manual_campaign_id,
        adGroupId: manual_broad_ad_group_id,
        state: 'enabled',
        keywordText: newKeyword,
        matchType: 'broad',
        bid: parseFloat(bidAmountBroad)
      } 
      keywordDataArray.push(keywordData);
    } 

    // 7B - If keyword not exist under Phrase ad group then keyword for that.
    if ( !isExistPhrase ) {
      const bidAmountPhrase = this.getDefaultBidForAdGroup(manual_phrase_ad_group_id);
      const keywordData = {
        campaignId: manual_campaign_id,
        adGroupId: manual_phrase_ad_group_id,
        state: 'enabled',
        keywordText: newKeyword,
        matchType: 'phrase',
        bid: parseFloat(bidAmountPhrase)
      } 
      keywordDataArray.push(keywordData);
    }

    // 7C - If keyword not exist under Phrase ad group then create keyword for that.
    if ( !isExistExact ) {
      const bidAmountExact = this.getDefaultBidForAdGroup(manual_exact_ad_group_id);
      const keywordData = {
        campaignId: manual_campaign_id,
        adGroupId: manual_exact_ad_group_id,
        state: 'enabled',
        keywordText: newKeyword,
        matchType: 'exact',
        bid: parseFloat(bidAmountExact)
      } 
      keywordDataArray.push(keywordData);
    } 

    // Debug
    console.log('Create (Flow) Keyword - keywordDataArray:', keywordDataArray);

    // 7D - Call api to create (positive) keyword
    // If Keyword create array data exist then call api to create keyword
    if ( keywordDataArray.length > 0 ) { 
      // Start: dummy callback
      if ( this.API_MODE_CREATE_FLOW_KEYWORD === 'DUMMY' ) { 
        // Imitate success callaback
        const dummyResult = { 
          status: 'success', 
          data: [ { keywordId: 3001, code: 'SUCCESS', details: '', description: ''} ],
          error: null,
        }
        setTimeout(() => { this.createFlowKeywords_Success(dummyResult); }, 2000);

        // // Imitate error callaback
        // const error = { code: 'error', message: 'keyword create error' }
        // setTimeout(() => { this.createFlowKeywords_Error(error); }, 1000);
      } 
      // End: dummy callback
      
      // Call real api to create keyword
      if ( this.API_MODE_CREATE_FLOW_KEYWORD === 'REAL' ) {
        spCreateKeywords(profile_id, keywordDataArray, this.createFlowKeywords_Success, this.createFlowKeywords_Error);
      }
    } else { 
      // Keyword create data array empty i.e. no need to create keyword, 
      // i.e. consider as keyword created.
      this.setState({
        isCreatingFlowKeyword: false, 
      }, () => {
        // Check that (flow) keyword created or not
        this.checkFlowKeywordCreatedAll();
      });
    }


    // 8 - IMPORTANT RULE: If exact ad group exist then we have to create negative keyword otherwise not.
    // So if exact ad group not created yet, then do not proceed to create negative keyword and consider 
    // that negative keyword created.
    if ( !manual_exact_ad_group_id ) {
      console.log('Exact ad group not exist, so no need to create negative keyword');

      // Consider flow negative keyword created
      this.setState({
        isCreatingFlowNegativeKeyword: false, 
      }, () => {
        // Check that (flow) keyword created
        this.checkFlowKeywordCreatedAll();
      });

      return; // IMPORTANT
    } 


    // 9 - Prepare data to create Negative keywords.
    // We have to pass data as an array either create one or more keyword, 
    // because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ] 
    const negativeKeywordDataArray = []; 

    // 9A - If negative keyword not exist under Auto ad group then create it.
    if ( !isExistNegativeAuto ) {
      const keywordData = {
        campaignId: auto_campaign_id,
        adGroupId: auto_ad_group_id,
        state: 'enabled',
        keywordText: newKeyword,
        matchType: 'negativeExact',
      } 
      negativeKeywordDataArray.push(keywordData);
    }

    // 9B - If negative keyword not exist under Broad ad group then create it.
    if ( !isExistNegativeBroad ) {
      const keywordData = {
        campaignId: manual_campaign_id,
        adGroupId: manual_broad_ad_group_id,
        state: 'enabled',
        keywordText: newKeyword,
        matchType: 'negativeExact',
      } 
      negativeKeywordDataArray.push(keywordData);
    }

    // 9C - If negative keyword not exist under Phrase ad group then create it.
    if ( !isExistNegativePhrase ) {
      const keywordData = {
        campaignId: manual_campaign_id,
        adGroupId: manual_phrase_ad_group_id,
        state: 'enabled',
        keywordText: newKeyword,
        matchType: 'negativeExact',
      } 
      negativeKeywordDataArray.push(keywordData);
    }

    // Debug
    console.log('Create (flow) Negative Keyword negativeKeywordDataArray:', negativeKeywordDataArray);

    // 9D - Call api to create negative keyword
    // Keyword create data exist, so create it.
    if ( negativeKeywordDataArray.length > 0 ) { 
      // Start: dummy callback
      if ( this.API_MODE_CREATE_FLOW_NEGATIVE_KEYWORD === 'DUMMY' ) { 
        // Imitate success callback
        const dummyResult = { 
          status: 'success', 
          data: [ { keywordId: 4001, code: 'SUCCESS', details: '', description: ''} ], 
          error: null, 
        }
        setTimeout(() => { this.createFlowNegativeKeywords_Success(dummyResult); }, 2000);

        // // Imitate error callback
        // const error = { code: 'error', message: 'Negative keyword create error' }
        // setTimeout(() => { this.createFlowNegativeKeywords_Error(error); }, 1000);
      }
      // End: dummy callback

      // Call real api to create negative keyword
      if ( this.API_MODE_CREATE_FLOW_NEGATIVE_KEYWORD === 'REAL' ) {
        spCreateNegativeKeywords(profile_id, negativeKeywordDataArray, this.createFlowNegativeKeywords_Success, this.createFlowNegativeKeywords_Error);
      }
    } else { 
      // Keyword create data array empty i.e. no need to create negative keyword
      // i.e. Consider negative keyword created
      this.setState({
        isCreatingFlowNegativeKeyword: false, 
      }, () => {
        // Check that (flow) keyword created or not
        this.checkFlowKeywordCreatedAll();
      });
    }

  }

  // Called when flow (positive) keyword created successfully 
  // e.g. result: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //        }], 
  //        error: null 
  //      }
  createFlowKeywords_Success = (result) => {
    console.log('createFlowKeywords_Success() result:', result);

    if (result.status === 'success') {
      // Fetch newly created keyword info and insert/update it within existing negative keyword list.
      if ( result.data.length > 0 ) {
        const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
        setTimeout(() => { this.fetchGivenKeywordListViaApi(keywordIds); }, 10);
      }

      // Consider keyword created after 3 seconds, so during this delay time above step 
      // will fetch new keyword info and display within ui.
      setTimeout(() => { this.setAsDone_CreateFlowKeywords(); }, 2000);
    }

    if (result.status === 'error') {
      this.showError_CreateFlowKeywords();
    }
  }

  // Called if any error while creating keywords (flow keyword)
  createFlowKeywords_Error = (error) => {
    console.log('createFlowKeywords_Error() error:', error);
    
    this.showError_CreateFlowKeywords();
  }

  showError_CreateFlowKeywords = (message) => {
    let msg = 'Can not create some keyword this time, please try later on.';
    if ( message && message !== '' ) { msg = message }

    this.setState({
      messageText: msg,
      showMessage: true,
      isCreatingFlowKeyword: false, 
    }, () => {
      this.checkFlowKeywordCreatedAll();
    });
  }

  // Consider flow (positive) keyword created
  setAsDone_CreateFlowKeywords = () => {
    // Set keyword created
    this.setState({
      isCreatingFlowKeyword: false,
    }, () => {
      this.checkFlowKeywordCreatedAll();
    });
  }


  // Called when flow negative keyword created successfully 
  // e.g. result: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //        }], 
  //        error: null 
  //      }
  createFlowNegativeKeywords_Success = (result) => {
    console.log('createFlowNegativeKeywords_Success() result:', result);

    if (result.status === 'success') {
      // Fetch newly created keyword info and insert/update it within existing keyword list.
      if ( result.data.length > 0 ) {
        const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
        setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);
      }
      
      // Consider negative keyword created after 3 seconds, so during this delay time above step 
      // will fetch new keyword info and display within ui.
      setTimeout(() => { this.setAsDone_CreateFlowNegativeKeywords(); }, 2000);
    }

    if (result.status === 'error') {
      this.showError_CreateFlowNegativeKeywords();
    }
  }

  // Called if any error while creating keywords (flow keyword)
  createFlowNegativeKeywords_Error = (error) => {
    console.log('createFlowNegativeKeywords_Error() error:', error);
    
    this.showError_CreateFlowNegativeKeywords();
  }

  showError_CreateFlowNegativeKeywords = (message) => {
    let msg = 'Can not create negative keyword this time, please try later on.';
    if ( message && message !== '' ) { msg = message }

    this.setState({
      messageText: msg, 
      showMessage: true, 
      isCreatingFlowNegativeKeyword: false, 
    }, () => {
      this.checkFlowKeywordCreatedAll();
    });
  }

  // Consider flow negative keyword created
  setAsDone_CreateFlowNegativeKeywords = () => {
    // Set negative keyword created
    this.setState({
      isCreatingFlowNegativeKeyword: false,
    }, () => {
      this.checkFlowKeywordCreatedAll();
    });
  }

  // It will check that both positive and negative (flow) keyword create api call finished or not.
  // If both api call finished then it will stop processing.
  checkFlowKeywordCreatedAll = () => {  
    console.log('checkFlowKeywordCreatedAll()');
    
    // If both api call (create keyword and negative keyword) finish then stop processing.
    const { isCreatingFlowKeyword, isCreatingFlowNegativeKeyword } = this.state; 
    if ( isCreatingFlowKeyword === false && isCreatingFlowNegativeKeyword === false ) {
      
      // Fetch bid recommendation for newly added single keyword
      const newKeyword = this.state.inputKeyword;
      this.fetchBidRecommendation_SingleKeyword(newKeyword);

      // Set processing off
      this.setState({
        inputKeyword: '', 
        isProcessing: false,
      });

    }

  }


  // Remove extra white space from string
  // - It will remove leading and trailing white space.
  // - In between word if there is multiplw whie space then it wil make single space
  removeExtraWhiteSpace = (keywordText) => {

    // Remove leading and trailing white space
    let temp = keywordText.trim();

    // Split string to array
    const tempArray = temp.split(' ');
    //console.log('tempArray:', tempArray);
    
    // Remove empty word from array
    const newArray = tempArray.filter( (word, index) => {
      if ( word !== '' ) { 
        return true;
      } else { 
        return false; 
      }
    });

    // Join element (word) with single space, so new string  
    // have all words with single spacing.
    const newString = newArray.join(' ');

    // Retured string
    return newString;
  }

  // This function will return default bid amount for given ad group id.
  // If bid amount not found for given adGroupId then it will return 0.02 as default value.
  getDefaultBidForAdGroup = (adGroupId) => {

    // 1 - Default value for bid amount
    let bidAmount = 0.02;
    const { adGroupListApi } = this.state;
    
    // 2 - If ad group list empty then return default value.
    if ( adGroupListApi.length === 0 ) { return bidAmount; }
  
    // 3 - Find info for given ad group
    // It will return data object if adGrouId found within array
    const adGroupInfo = adGroupListApi.find( (item, index) => {
      if (item.adGroupId === adGroupId) { 
        return true; 
      } else { 
        return false; 
      }
    });
    //console.log('adGroupInfo:', adGroupInfo);

    // 4 - If ad group info found then return .defaultBid value, 
    // otherwise return default amount 0.02 
    if (adGroupInfo) {
      return adGroupInfo.defaultBid;
    } else { 
      return bidAmount; 
    } 
  }

  //-----------------------------------------------------------------
  // End: Add new keyword (Flow Keyword)
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Add Negative keyword - Under Auto Ad Group
  //-----------------------------------------------------------------
  // Called when Add button clicked - Add negative keyword form (auto ad group)
  // i.e. We will add negtive keyword under auto ad group (if keyword not exist)
  onClickAddNegativeKeyword_Auto = () => {
    console.log('onClickAddNegativeKeyword_Auto()');

    // 1 - Read value from state
    const { inputNegativeKeywordAuto, negativeKeywordListApi } = this.state;
    const { auto_ad_group_id, auto_campaign_id, profile_id } = this.state.flowDataDb;
    const newKeyword = this.removeExtraWhiteSpace(inputNegativeKeywordAuto);
    console.log('newKeyword:', newKeyword);

    // 2 - If data not valid then return
    if ( newKeyword.length === 0 ) { // Keyword text empty
      console.log('newKeyword empty, so return');
      return; 
    }
    if( !auto_ad_group_id ) { // auto ad group not exist
      console.log('Auto ad group not exist, so return');
      this.setState({
        messageText: 'Auto ad group not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !auto_campaign_id ) { // auto campaign not exist
      console.log('Auto campaign not exist, so return');
      this.setState({
        messageText: 'Auto campaign not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Check that newKeyword already exist under auto ad group with negativeExact match
    let isKeywordExist = false;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if( item.keywordText.trim().toLowerCase() === newKeyword.toLowerCase() && 
          item.adGroupId === auto_ad_group_id && 
          item.matchType === 'negativeExact' 
        ) { return true; } else { return false; }
    });
    if ( keywordList.length > 0 ) { isKeywordExist = true; }

    // 4 - If negative keyword already exist then show message and return.
    if (isKeywordExist) {
      this.setState({
        messageText: `${newKeyword} negative keyword already exist, so can not add again.`,
        showMessage: true,
      });
      return; // Important
    }

    // 5 - Set processing on 
    this.setState({
      isProcessing: true,
      isCreatingNegativeKeywordAuto: true,
    });

    // 6 - Prepare data to create negative keyword
    // We have to pass data as an array although we have to create one 
    // keyword, because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ] 
    const keywordDataArray = [];
    const keywordData1 = {
      campaignId: auto_campaign_id,
      adGroupId: auto_ad_group_id,
      state: 'enabled',
      keywordText: newKeyword,
      matchType: 'negativeExact'
    }  
    keywordDataArray.push(keywordData1);

    // Debug
    console.log('(Auto) Negative keywordDataArray:', keywordDataArray); 
    console.log('profile_id:',profile_id);

    // 7 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_CREATE_NEGATIVE_KEYWORD_AUTO === 'DUMMY' ) { 
      
      // 7A - Imitate success callback 
      const dummyResult = { 
        status: 'success', 
        data: [ { keywordId: 401, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      setTimeout(() => { this.spCreateNegativeKeywords_Auto_Success(dummyResult); }, 500);
      return; // Important

      // // 7B - Imitate error callback
      // const error = { code: 'error', message: 'Negative keyword create error' }
      // setTimeout(() => { this.spCreateNegativeKeywords_Auto_Error(error); }, 1000);
      // return; // Important
    }
    // End: dummy callback

    // 8 - Call api to create negative keywords
    spCreateNegativeKeywords(profile_id, keywordDataArray, this.spCreateNegativeKeywords_Auto_Success, this.spCreateNegativeKeywords_Auto_Error);
  }

  // Called when negative keyword created successfully (under auto ad group)
  // e.g. jsonBody: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //        }], 
  //        error: null 
  //      }
  spCreateNegativeKeywords_Auto_Success = (result) => {
    console.log('spCreateNegativeKeywords_Auto_Success() result:', result);

    if (result.status === 'success') {
      // Refresh negative keyword list, so newly added keyword shows within ui
      // setTimeout(() => { this.fetchNegativeKeywordListViaApi(); }, 10);

      // Fetch created keyword info and insert within existing list, so newly added keyword shows within ui.
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);

      // Consider negative keyword created
      this.setAsDone_CreateNegativeKeyword_Auto();
    }

    if (result.status === 'error') {
      this.showError_CreateNegativeKeywords_Auto(result.error);
    }
  }
  
  // Called if any error while creating negative keyword (for automatic flow)
  spCreateNegativeKeywords_Auto_Error = (error) => {
    console.log('spCreateNegativeKeywords_Auto_Error() error:', error);
    
    this.showError_CreateNegativeKeywords_Auto();
  }

  showError_CreateNegativeKeywords_Auto = (message) => {
    let msg = 'Can not create negative keyword this time, please try again.';
    if ( message && message !== '' ) { msg = message; }

    this.setState({ 
      messageText: msg, 
      showMessage: true, 
      isProcessing: false, 
      isCreatingNegativeKeywordAuto: false, 
    }); 
  }
 
  // Consider negative keyword created successfully (under auto ad group).
  // Set processing and other parameter within state to false
  setAsDone_CreateNegativeKeyword_Auto = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        isCreatingNegativeKeywordAuto: false,
        inputNegativeKeywordAuto: '',
      });
    }, 2000);
  } 

  //-----------------------------------------------------------------
  // End: Add Negative keyword - Under Auto Ad Group
  //-----------------------------------------------------------------

  
  //-----------------------------------------------------------------
  // Start: Add Negative keyword - Under Broad Ad Group
  //-----------------------------------------------------------------
  // Called when Add button clicked - Add negative keyword form (broad ad group)
  // i.e. We will add negtive keyword under broad ad group (if keyword not exist)
  onClickAddNegativeKeyword_Broad = () => {
    console.log('onClickAddNegativeKeyword_Broad()');

    // 1 - Read value from state
    const { inputNegativeKeywordBroad, negativeKeywordListApi } = this.state;
    const { manual_broad_ad_group_id, manual_campaign_id, profile_id } = this.state.flowDataDb;
    const newKeyword = this.removeExtraWhiteSpace(inputNegativeKeywordBroad);
    console.log('newKeyword:', newKeyword);

    // 2 - If data not valid then return
    if ( newKeyword.length === 0 ) { 
      console.log('newKeyword empty, so return');
      return; 
    }
    if( !manual_broad_ad_group_id ) { // broad ad group not exist
      console.log('Broad ad group not exist, so return');
      this.setState({
        messageText: 'Broad ad group not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !manual_campaign_id ) { // auto campaign not exist
      console.log('Manual campaign not exist, so return');
      this.setState({
        messageText: 'Manual campaign not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Check that newKeyword already exist under broad ad group with negativeExact match
    let isKeywordExist = false;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if( item.keywordText.trim().toLowerCase() === newKeyword.toLowerCase() && 
          item.adGroupId === manual_broad_ad_group_id && 
          item.matchType === 'negativeExact' 
        ) { return true; } else { return false; }
    });
    if ( keywordList.length > 0 ) { isKeywordExist = true; }

    // 4 - If negative keyword already exist then show message and return.
    if (isKeywordExist) {
      this.setState({
        messageText: `${newKeyword} negative keyword already exist, so can not add again.`,
        showMessage: true,
      });
      return; // Important
    }

    // 5 - Set processing on 
    this.setState({
      isProcessing: true,
      isCreatingNegativeKeywordBroad: true,
    });

    // 6 - Prepare data to create negative keyword
    // We have to pass data as an array although we have to create one 
    // keyword, because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ] 
    const keywordDataArray = [];
    const keywordData1 = {
      campaignId: manual_campaign_id,
      adGroupId: manual_broad_ad_group_id,
      state: 'enabled',
      keywordText: newKeyword,
      matchType: 'negativeExact'
    }  
    keywordDataArray.push(keywordData1);

    // Debug
    console.log('(Broad) Negative keywordDataArray:', keywordDataArray); 
    console.log('profile_id:',profile_id);

    // 7 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_CREATE_NEGATIVE_KEYWORD_BROAD === 'DUMMY' ) { 
      
      // 7A - Imitate success callback 
      const dummyResult = { 
        status: 'success', 
        data: [ { keywordId: 402, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      setTimeout(() => { this.spCreateNegativeKeywords_Broad_Success(dummyResult); }, 500);
      return; // Important

      // // 7B - Imitate error callback
      // const error = { code: 'error', message: 'Negative keyword create error' }
      // setTimeout(() => { this.spCreateNegativeKeywords_Broad_Error(error); }, 1000);
      // return; // Important
    }
    // End: dummy callback

    // 8 - Call api to create negative keywords
    spCreateNegativeKeywords(profile_id, keywordDataArray, this.spCreateNegativeKeywords_Broad_Success, this.spCreateNegativeKeywords_Broad_Error);
  }

  // Called when negative keyword created successfully (under Broad Ad Group)
  // e.g. jsonBody: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //        }], 
  //        error: null 
  //      }
  spCreateNegativeKeywords_Broad_Success = (result) => {
    console.log('spCreateNegativeKeywords_Broad_Success() result:', result);

    if (result.status === 'success') {
      // Refresh negative keyword list, so newly added keyword shows within ui
      //setTimeout(() => { this.fetchNegativeKeywordListViaApi(); }, 10);

      // Fetch created keyword info and insert within existing list, so newly added keyword shows within ui.
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);

      // Consider negative keyword created
      this.setAsDone_CreateNegativeKeyword_Broad();
    }

    if (result.status === 'error') {
      this.showError_CreateNegativeKeywords_Broad(result.error);
    }
  }  

  // Called if any error while creating negative keyword (for automatic flow)
  spCreateNegativeKeywords_Broad_Error = (error) => {
    console.log('spCreateNegativeKeywords_Broad_Error() error:', error);
    
    this.showError_CreateNegativeKeywords_Broad();
  }

  showError_CreateNegativeKeywords_Broad = (message) => {
    let msg = 'Can not create negative keyword this time, please try again.';
    if ( message && message !== '' ) { msg = message; }

    this.setState({ 
      messageText: msg, 
      showMessage: true, 
      isProcessing: false, 
      isCreatingNegativeKeywordBroad: false, 
    }); 
  }

  // Consider negative keyword created successfully (under Broad ad group).
  // Set processing and other parameter within state to false
  setAsDone_CreateNegativeKeyword_Broad = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        isCreatingNegativeKeywordBroad: false,
        inputNegativeKeywordBroad: '',
      });
    }, 2000);
  } 

  //-----------------------------------------------------------------
  // End: Add Negative keyword - Under Broad Ad Group
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Add Negative keyword - Under Phrase Ad Group
  //-----------------------------------------------------------------
  // Called when Add button clicked - Add negative keyword form (phrase ad group)
  // i.e. We will add negtive keyword under phrase ad group (if keyword not exist)
  onClickAddNegativeKeyword_Phrase = () => {
    console.log('onClickAddNegativeKeyword_Phrase()');

    // 1 - Read value from state
    const { inputNegativeKeywordPhrase, negativeKeywordListApi } = this.state;
    const { manual_phrase_ad_group_id, manual_campaign_id, profile_id } = this.state.flowDataDb;
    const newKeyword = this.removeExtraWhiteSpace(inputNegativeKeywordPhrase);
    console.log('newKeyword:', newKeyword);

    // 2 - If data not valid then return
    if ( newKeyword.length === 0 ) { 
      console.log('newKeyword empty, so return');
      return; 
    }
    if( !manual_phrase_ad_group_id ) { // broad ad group not exist
      console.log('Phrase ad group not exist, so return');
      this.setState({
        messageText: 'Phrase ad group not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !manual_campaign_id ) { // auto campaign not exist
      console.log('Manual campaign not exist, so return');
      this.setState({
        messageText: 'Manual campaign not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Check that newKeyword already exist under phrase ad group with negativeExact match
    let isKeywordExist = false;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if( item.keywordText.trim().toLowerCase() === newKeyword.toLowerCase() && 
          item.adGroupId === manual_phrase_ad_group_id && 
          item.matchType === 'negativeExact' 
        ) { return true; } else { return false; }
    });
    if ( keywordList.length > 0 ) { isKeywordExist = true; }

    // 4 - If negative keyword already exist then show message and return.
    if (isKeywordExist) {
      this.setState({
        messageText: `${newKeyword} negative keyword already exist, so can not add again.`, 
        showMessage: true,
      });
      return; // Important
    }
    
    // 5 - Set processing on 
    this.setState({ 
      isProcessing: true,
      isCreatingNegativeKeywordPhrase: true,
    }); 
    
    // 6 - Prepare data to create negative keyword
    // We have to pass data as an array although we have to create one 
    // keyword, because server side api expect data as an array. 
    // e.g. keywordDataArray = [
    //   {
    //     "campaignId": 0,
    //     "adGroupId": 0,
    //     "state": "enabled",
    //     "keywordText": "string",
    //     "matchType": "negativeExact",
    //   }
    // ] 
    const keywordDataArray = [];
    const keywordData1 = {
      campaignId: manual_campaign_id,
      adGroupId: manual_phrase_ad_group_id,
      state: 'enabled',
      keywordText: newKeyword,
      matchType: 'negativeExact'
    }  
    keywordDataArray.push(keywordData1);

    // Debug
    console.log('(Phrase) Negative keywordDataArray:', keywordDataArray); 
    console.log('profile_id:',profile_id);

    // 7 - Run success callback with dummy result
    // Start: dummy callback
    if ( this.API_MODE_CREATE_NEGATIVE_KEYWORD_PHRASE === 'DUMMY' ) { 
      
      // 7A - Imitate success callback 
      const dummyResult = { 
        status: 'success', 
        data: [ { keywordId: 403, code: 'SUCCESS', details: '', description: ''} ],
        error: null,
      }
      setTimeout(() => { this.spCreateNegativeKeywords_Phrase_Success(dummyResult); }, 500);
      return; // Important

      // // 7B - Imitate error callback
      // const error = { code: 'error', message: 'Negative keyword create error' }
      // setTimeout(() => { this.spCreateNegativeKeywords_Phrase_Error(error); }, 1000);
      // return; // Important
    }
    // End: dummy callback

    // 8 - Call api to create negative keywords
    spCreateNegativeKeywords(profile_id, keywordDataArray, this.spCreateNegativeKeywords_Phrase_Success, this.spCreateNegativeKeywords_Phrase_Error);
  }

  // Called when negative keyword created successfully (under Phrase Ad Group)
  // e.g. jsonBody: { 
  //        status: 'success', 
  //        data:[{
  //          "keywordId": 108382386491952,
  //          "code": "SUCCESS",
  //          "details": "",
  //        }], 
  //        error: null 
  //      }
  spCreateNegativeKeywords_Phrase_Success = (result) => {
    console.log('spCreateNegativeKeywords_Phrase_Success() result:', result);

    if (result.status === 'success') {
      // Refresh negative keyword list, so newly added keyword shows within ui
      //setTimeout(() => { this.fetchNegativeKeywordListViaApi(); }, 10);

      // Fetch created keyword info and insert within existing list, so newly added keyword shows within ui.
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);

      // Consider negative keyword created
      this.setAsDone_CreateNegativeKeyword_Phrase();
    }

    if (result.status === 'error') {
      this.showError_CreateNegativeKeywords_Phrase(result.error);
    }
  }  

  // Called if any error while creating negative keyword (for automatic flow)
  spCreateNegativeKeywords_Phrase_Error = (error) => {
    console.log('spCreateNegativeKeywords_Phrase_Error() error:', error);
    
    this.showError_CreateNegativeKeywords_Phrase();
  }

  showError_CreateNegativeKeywords_Phrase = (message) => {
    let msg = 'Can not create negative keyword this time, please try again.';
    if ( message && message !== '' ) { msg = message; }

    this.setState({ 
      messageText: msg, 
      showMessage: true, 
      isProcessing: false, 
      isCreatingNegativeKeywordPhrase: false, 
    }); 
  }
  
  // Consider negative keyword created successfully (under Broad ad group).
  // Set processing and other parameter within state to false
  setAsDone_CreateNegativeKeyword_Phrase = () => {
    setTimeout(() => {
      this.setState({
        isProcessing: false,
        isCreatingNegativeKeywordPhrase: false,
        inputNegativeKeywordPhrase: '',
      });
    }, 2000);
  } 
 
  //-----------------------------------------------------------------
  // End: Add Negative keyword - Under Phrase Ad Group
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Toggle flow keyword state (enabled/paused)
  //-----------------------------------------------------------------
  // This function will toggle 'enabled' or 'paused' state for flow keyword.
  // i.e. we have to set 'enabled' or 'paused' state for given keywordText 
  // under all ad group exist within our flow.
  toggleFlowKeywordState = (checked, keywordInfo, index) => {
    console.log('toggleFlowKeywordState() index:', index, 'checked:', checked, ' keywordInfo:', keywordInfo);

    // 1 - Fetch data from state 
    const { profile_id } = this.state.flowDataDb;
    const { keywordListApi, negativeKeywordListApi } = this.state;

    // 2 - If require data not available then return 
    if ( !keywordInfo ) {
      console.log('Keyword info not exist, so return');
      this.setState({
        messageText: 'Keyword info not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if ( !keywordInfo.keywordText ) {
      console.log('keywordText not found, so return');
      this.setState({
        messageText: 'keywordText not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }
    if( !profile_id || profile_id === '' ) { // profile not exist
      console.log('Profile Id not exist, so return');
      this.setState({
        messageText: 'Profile Id not exist, so can not proceed.',
        showMessage: true,
      });
      return;
    }

    // 3 - Fetch keyword text for which we have to update state. 
    const keywordText = keywordInfo.keywordText.trim();
    const newState = checked ? 'enabled' : 'paused';

    // 4 - Prepare keyword (positive) Ids those match with keywordText
    // We have to update state for all matched keywords.
    // e.g. keywordIds = ['keywordId1', 'keywordId2', 'keywordId3' ]
    const keywordIds = []; 
    keywordListApi.forEach((item, index) => {
      if ( item.keywordText.trim() === keywordText ) {
        keywordIds.push(item.keywordId);
      }
    });
    //console.log('keywordIds:', keywordIds);

    // 5 - Prepare Negative keyword Ids those match with given keywordText
    // We have to update state for all matched negative keywords.
    // e.g. negativeKeywordIds = [keywordId1, keywordId2, keywordId3]
    const negativeKeywordIds = [];
    negativeKeywordListApi.forEach((item, index) => {
      if ( item.keywordText.trim() === keywordText ) {
        negativeKeywordIds.push(item.keywordId);
      }
    });
    //console.log('negativeKeywordIds:', negativeKeywordIds);

    // 6 - Prepare data to update (positive) keyword state.
    // Note: We have to update 'state' only, so pass 'state' key:value to api
    // e.g. updateData = [
    //   {
    //      "keywordId": 0,
    //      "state": "enabled",
    //      "bid": 0,
    //   }
    // ]
    const updateDataArray1 = [];
    keywordIds.forEach((keywordId, index) => {
      const updateData = {
        "keywordId": keywordId,
        "state": newState,
      }
      updateDataArray1.push(updateData);
    })
    console.log('Keywords - updateDataArray1:', updateDataArray1);

    // 7 - Prepare data to update Negative keyword state.
    const updateDataArray2 = [];
    negativeKeywordIds.forEach((keywordId, index) => {
      const updateData = {
        "keywordId": keywordId,
        "state": newState,
      }
      updateDataArray2.push(updateData);
    })
    console.log('Negative Keywords - updateDataArray2:', updateDataArray2);

    // 8- If both array empty then return, no need to update anything
    if ( updateDataArray1.length === 0 && updateDataArray2.length === 0 ) {
      console.log('Flow keyword state update not need, so return');
      return;
    }

    // 9 - Set processing on for both positive and negative keyword that 
    // match with flow keyword text.
    this.setState({
      isProcessing: true,
      isUpdatingFlowKeywordState: true, 
      isUpdatingFlowNegativeKeywordState: true,
      updatingFlowKeywordText: keywordText,
    });

    // 10 - Call amazon ad api to update (positive) keyword state 
    if (updateDataArray1.length > 0 ) {
      spUpdateKeywords(profile_id, updateDataArray1, this.updateFlowKeywordsState_Success, this.updateFlowKeywordsState_Error);
    } else {
      this.setState({
        isUpdatingFlowKeywordState: false,
      });
    }

    // 11 - Call amazon ad api to update Negative keyword state 
    if (updateDataArray2.length > 0 ) {
      spUpdateNegativeKeywords(profile_id, updateDataArray2, this.updateFlowNegativeKeywordsState_Success, this.updateFlowNegativeKeywordsState_Error);
    } else {
      this.setState({
        isUpdatingFlowNegativeKeywordState: false, 
      });
    }

  }

  // Called once  keyword state updated successfully (Positive Keyword)
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  updateFlowKeywordsState_Success = async (result) => {
    console.log('updateFlowKeywordsState_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      // Refresh updated keyword list, so updated state shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenKeywordListViaApi(keywordIds); }, 10);

      // Consider positive keyword updated
      this.setState({
        isUpdatingFlowKeywordState: false,
      }, () => {
        this.checkFlowKeywordUpdateDone();
      });
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_UpdateFlowKeywordsState();
    }
  }

  // Called if any error while update keyword state
  updateFlowKeywordsState_Error = () => {
    console.log('updateFlowKeywordsState_Error() error:', error);
    this.showError_UpdateFlowKeywordsState();
  }

  // Show error message 
  showError_UpdateFlowKeywordsState = (message) => {
    let msg = 'Can not update all keyword state, please try later on.';
    if ( message && message !== '' ) { msg = message }

    this.setState({
      messageText: msg, 
      showMessage: true, 
      isUpdatingFlowKeywordState: false,
    }, () => {
      this.checkFlowKeywordUpdateDone();
    }); 
  }


  // Called once keyword state updated successfully (Negative Keyword)
  // e.g. 
  // jsonBody: { 
  //    status: 'success', 
  //    data:[ 
  //      {
  //         "keywordId": 0,
  //         "code": "string",
  //         "details": "string"
  //      }
  //    ], 
  //    error: null 
  //  }
  // 
  // i.e. keywordId - The identifer of the keyword.
  // i.e. code - The success or error code for the operation. e.g. SUCCESS
  // i.e. details - The human-readable description of the error.
  updateFlowNegativeKeywordsState_Success = (result) => {
    console.log('updateFlowNegativeKeywordsState_Success() result:', result);

    // status = 'success' returned by server side endpoint.
    if ( result.status === 'success' ) {
      // Refresh updated negative keyword list, so updated state shows within ui
      const keywordIds = result.data.map((item,index) => { return item.keywordId; }); // [keywordId1,keywordId2, ...]
      setTimeout(() => { this.fetchGivenNegativeKeywordListViaApi(keywordIds); }, 10);
  
      // Consider Negative keyword updated
      this.setState({
        isUpdatingFlowNegativeKeywordState: false, 
      }, () => {
        this.checkFlowKeywordUpdateDone();
      });
    }

    // If any error while processing request on server side it will return status = 'error'
    if ( result.status === 'error' ) {
      this.showError_UpdateFlowNegativeKeywordsState();
    }
  }
  
  // Called if any error while update negative keyword state
  updateFlowNegativeKeywordsState_Error = () => {
    console.log('updateFlowNegativeKeywordsState_Error() error:', error);
    this.showError_UpdateFlowNegativeKeywordsState();
  }
  
  // Show error message 
  showError_UpdateFlowNegativeKeywordsState = (message) => {
    let msg = 'Can not update all state this time, please try later on.';
    if ( message && message !== '' ) { msg = message }
    
    this.setState({
      messageText: msg, 
      showMessage: true, 
      isUpdatingFlowNegativeKeywordState: false,
    }, () => {
      this.checkFlowKeywordUpdateDone();
    }); 
  }
  
  // It will check that both positive and negative keyword update api call finish or not.
  // If both api call finished then it will stop processing.
  checkFlowKeywordUpdateDone = () => {  
    console.log('checkFlowKeywordUpdateDone()');
  
    // If both api call (update keyword and negative keyword) finish then stop processing.
    const { isUpdatingFlowKeywordState, isUpdatingFlowNegativeKeywordState } = this.state;    
    if ( isUpdatingFlowKeywordState === false && isUpdatingFlowNegativeKeywordState === false) {
      setTimeout(() => {
        this.setState({
          isProcessing: false,
          updatingFlowKeywordText: '',
        });
      }, 3000);
    }
  }

  //-----------------------------------------------------------------
  // End: Toggle flow keyword state (enabled/paused)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render Flow keyword list 
  //-----------------------------------------------------------------
  // We have to render keyword list that is part of the flow as common 
  // keyword. i.e. Keyword created under all ad group exist in our flow.
  renderFlowKeywordList = () => {

    const { classes } = this.props;
    const { keywordListFetched, negativeKeywordListFetched } = this.state;

    // 1 - If both positive and negative keyword list not fetched then show loading.
    if ( keywordListFetched === false || negativeKeywordListFetched === false ) {
      return null;
    }

    // 2 - If flow keyword list null then show message
    // OR If keyword list array empty then show messsage
    const { flowKeywordList } = this.state;
    if ( !flowKeywordList ) {
      return(<Paper elevation={2} style={{ padding: 12 }} >Keywords not added yet.</Paper>);
    }
    if ( flowKeywordList.length === 0 ) {
      return(<Paper elevation={2} style={{ padding: 12 }} >Keywords not added yet.</Paper>);
    }

    // 3 - Render flow keyword list with toogle button
    const { isProcessing, isUpdatingFlowKeywordState, isUpdatingFlowNegativeKeywordState, updatingFlowKeywordText } = this.state;
    let isDisabled = false;
    if ( isProcessing ) { 
      isDisabled = true; 
    }
    const tableRowEl = flowKeywordList.map( (item, index) => {

      let isUpdatingCurrentKeyword = false;
      if ( (isUpdatingFlowKeywordState || isUpdatingFlowNegativeKeywordState) && 
            updatingFlowKeywordText === item.keywordText) {
        isUpdatingCurrentKeyword = true;
      }

      return(
        <TableRow key={'flow-keyword-' + index } >
          <TableCell align="left" style={{ padding: 4 }} >
            { item.keywordText } ({ item.state })
            { // If current keyword is updating then show indicator
              isUpdatingCurrentKeyword &&
              <CircularProgress size={16} style={{ marginLeft: 8, verticalAlign: "middle" }} />
            }
          </TableCell>
          <TableCell align="right" style={{ padding: 0, width: 50 }} >
            <Switch
              checked={ item.state === 'enabled' ? true : false }
              onChange={ (event) => { 
                this.toggleFlowKeywordState(event.target.checked, item, index); 
              }} 
              name={ "toggle-flow-keyword-state-" + index } 
              disabled={ isDisabled } 
              inputProps={{ 'aria-label': 'toggle state' }}
              //style={{ color: '#52d869' }}
            />
          </TableCell>
        </TableRow>
      )
    });

    // 5 - Return ui elements
    return (
      <Paper elevation={2} style={{ padding: 12 }} >
        <Typography variant="h6" >Flow Keywords:</Typography>
        <TableContainer >
          <Table aria-label="Flow keyword list">
            {
            // <TableHead>
            //   <TableRow>
            //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
            //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
            //   </TableRow>
            // </TableHead>
            }
            <TableBody>
              { tableRowEl }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  //-----------------------------------------------------------------
  // End: Render Flow keyword list 
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render Add keyword form (flow keyword)
  //-----------------------------------------------------------------
  renderForm_AddKeyword = () => {
    
    const { classes } = this.props;
    const { isProcessing } = this.state;

    let isDisabled = false;
    if ( isProcessing ) { 
      isDisabled = true; 
    }

    return(
      <Paper elevation={2} style={{ padding: 12 }} >
        
        <Grid container spacing={2} >

          <Grid item xs={12} sm={3}>
            <Typography variant="h6" >Add Keyword:</Typography>
          </Grid>

          <Grid item xs={12} sm={7}>
            <FormControl className={classes.formControl} >
              <TextField 
                id="id-add-keyword" 
                label='Enter Keyword (single)'
                placeholder='e.g. Pen Drive'
                value={ this.state.inputKeyword } 
                onChange={(e) => this.setState({ inputKeyword: e.target.value })} 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                //helperText="New keyword will be added under existing ad group with appropriate match type."
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2} align="left" >
            <Button 
              variant="contained" 
              size="medium" 
              onClick={ (e) => this.onClickAddKeyword() } 
              //style={{ marginRight: 20 }}
              disabled={ isDisabled } 
            >Add</Button>
          </Grid>

          <Grid item xs={12} sm={12} align="left" >
            New keyword will be added under existing ad group with appropriate match type.
          </Grid>
        </Grid>

      </Paper>
    )
  }
  //-----------------------------------------------------------------
  // End: Render Add keyword form (flow keyword)
  //-----------------------------------------------------------------


  //-----------------------------------------------------------------
  // Start: Render Add negative keyword form (auto, broad, phrase ad group)
  //-----------------------------------------------------------------
  // Render add negative keyword form (auto ad group)
  renderForm_AddNegativeKeyword_Auto = () => {
    const { classes } = this.props;
    const { isProcessing } = this.state;

    let isDisabled = false;
    if ( isProcessing ) { 
      isDisabled = true; 
    }

    return(
      <div style={{ marginTop: 20, display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
        <FormControl className={classes.formControl} >
          <TextField 
            variant="outlined" 
            id="id-negative-keyword-auto" 
            label='Add Negative Keyword'
            placeholder='e.g. Pen Drive Red'
            value={ this.state.inputNegativeKeywordAuto } 
            onChange={(e) => this.setState({ inputNegativeKeywordAuto: e.target.value })} 
            size="small" 
            disabled={ isDisabled } 
            InputLabelProps={{ shrink: true, }} 
            //helperText="Keyword will be added as negative exact under auto ad group"
          />
        </FormControl>
        <Button 
          variant="contained" 
          size="small" 
          onClick={ (e) => { this.onClickAddNegativeKeyword_Auto() } } 
          style={{ marginLeft: 10 }}
          disabled={ isDisabled } 
        >Add</Button>
      </div>
    );
  }

  // Render add negative keyword form (broad ad group)  
  renderForm_AddNegativeKeyword_Broad = () => {
    const { classes } = this.props;
    const { isProcessing } = this.state;

    let isDisabled = false;
    if ( isProcessing ) { 
      isDisabled = true; 
    }

    return(
      <div style={{ marginTop: 20, display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
        <FormControl className={classes.formControl} >
          <TextField 
            variant="outlined" 
            id="id-negative-keyword-broad" 
            label='Add Negative Keyword'
            placeholder='e.g. Pen Drive'
            value={ this.state.inputNegativeKeywordBroad } 
            onChange={(e) => this.setState({ inputNegativeKeywordBroad: e.target.value })} 
            size="small" 
            disabled={ isDisabled } 
            InputLabelProps={{ shrink: true, }} 
            //helperText="Keyword will be added as negative exact under broad ad group"
          />
        </FormControl>
        <Button 
          variant="contained" 
          size="small" 
          onClick={ (e) => { this.onClickAddNegativeKeyword_Broad() } } 
          style={{ marginLeft: 10 }}
          disabled={ isDisabled } 
        >Add</Button>
      </div>
    );
  }

  // Render add negative keyword form (phrase ad group) 
  renderForm_AddNegativeKeyword_Phrase = () => {
    const { classes } = this.props;
    const { isProcessing } = this.state;

    let isDisabled = false;
    if ( isProcessing ) { 
      isDisabled = true; 
    }

    return(
      <div style={{ marginTop: 20, display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
        <FormControl className={classes.formControl} >
          <TextField 
            variant="outlined" 
            id="id-negative-keyword-phrase" 
            label='Add Negative Keyword'
            placeholder='e.g. Pen Drive '
            value={ this.state.inputNegativeKeywordPhrase } 
            onChange={(e) => this.setState({ inputNegativeKeywordPhrase: e.target.value })} 
            size="small" 
            disabled={ isDisabled } 
            InputLabelProps={{ shrink: true, }} 
            //helperText="Keyword will be added as negative exact under phrase ad group"
          />
        </FormControl>
        <Button 
          variant="contained" 
          size="small" 
          onClick={ (e) => { this.onClickAddNegativeKeyword_Phrase() } } 
          style={{ marginLeft: 10 }}
          disabled={ isDisabled } 
        >Add</Button>
      </div>
    );
  }

  //-----------------------------------------------------------------
  // End: Render Add negative keyword form (auto, broad, phrase ad group)
  //-----------------------------------------------------------------



  //-----------------------------------------------------------------
  // Start: Rendering related functions
  //-----------------------------------------------------------------
  renderContent = () => {
    const { classes, flowDocId } = this.props;
    const { profile_id, profile_type, api_mode } = this.state.flowDataDb;
    const { isProcessing, keywordListFetched, negativeKeywordListFetched } = this.state;
    const { isCreatingFlowKeyword, isCreatingFlowNegativeKeyword } = this.state;

    return(
      <Grid container spacing={2} >
        
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1" >
            Running Ad For: { profile_type } profile
          </Typography>
        </Grid>

        { (keywordListFetched === false || negativeKeywordListFetched === false) && 
        <Grid item xs={12} sm={12} >
          <LinearProgress />
        </Grid>
        }

        <Grid item xs={12} sm={3} >
          { this.renderContentAutoFlow() }
        </Grid>

        <Grid item xs={12} sm={9} >
          { this.renderContentManualFlow() }
        </Grid>

        <Grid item xs={12} sm={12} >
        </Grid>

        <Grid item xs={12} sm={6} >
          { this.renderFlowKeywordList() }
        </Grid>

        <Grid item xs={12} sm={6}>
          { (keywordListFetched && negativeKeywordListFetched) &&
            this.renderForm_AddKeyword() 
          }
          { // If flow keyword creation in progress then show loader
            (isCreatingFlowKeyword || isCreatingFlowNegativeKeyword)  &&
            <LinearProgress /> 
          }
        </Grid>

        { api_mode === 'DUMMY' && 
        <Grid item xs={12} sm={12}>
          <br /><br />
          <Typography variant="h5" >
            Note: DUMMY api mode used to create this flow.
            So more info can not fetch via amazon Ad api.
          </Typography>
        </Grid>
        }

        <Grid item xs={12} sm={12}>
          <br /><br />
        </Grid>

        { this.state.showDebug && 
        <Grid item xs={12} sm={12}>
          Keyword List Debug:
          { this.debugKeywordList() }
        </Grid>
        }

        { this.state.showDebug && 
        <Grid item xs={12} sm={12}>
          Negative Keyword List Debug:
          { this.debugNegativeKeywordList() }
        </Grid>
        }

        { this.state.showDebug && 
        <Grid item xs={12} sm={4}>
          Bid Recommendation - Broad:
          { this.debugBidRecommendationBroad() }
        </Grid>
        }

        { this.state.showDebug && 
        <Grid item xs={12} sm={4}>
          Bid Recommendation - Phrase:
          { this.debugBidRecommendationPhrase() }
        </Grid>
        }

        { this.state.showDebug && 
        <Grid item xs={12} sm={4}>
          Bid Recommendation - Exact:
          { this.debugBidRecommendationExact() }
        </Grid>
        }


        { this.state.showDebug && 
        <Grid item xs={12} sm={12}>
          { this.debug_Info() }
        </Grid>
        }

      </Grid>
    );
  }


  // Render all content related to automatic flow
  renderContentAutoFlow = () => {
    const { classes } = this.props;
    const { auto_ad_group_id } = this.state.flowDataDb;
    const { negativeKeywordListFetched, isCreatingNegativeKeywordAuto, adGroupListApi } = this.state;

    const adGroupAuto = adGroupListApi.find((item, index) => { return item.adGroupId === auto_ad_group_id });
    let defaultBidAuto = adGroupAuto ? adGroupAuto.defaultBid : ''; 

    return(
      <React.Fragment>
        
        <Paper elevation={ 2 } className={classes.paper}>
          <Typography variant="h6" style={{ textAlign: 'center' }} >
            Automatic Campaign
          </Typography>
        </Paper>

        <div style={{ height: 16 }}></div>
        
        <Paper elevation={2} className={classes.paper}>
          <div align="center">
            <FormLabel>Auto Ad Group</FormLabel> <br/>
            { defaultBidAuto !== '' && 
              <Typography variant="caption" >Default Bid ${defaultBidAuto}</Typography>
            }
          </div>
          <br />

          { !auto_ad_group_id && 'You can create Auto AdGroup from Edit Campaign screen.' } 

          { auto_ad_group_id && <b><br />NegativeExact Keywords:</b> }
          { this.renderNegativeKeywordAuto() }

          { auto_ad_group_id && negativeKeywordListFetched && 
            this.renderForm_AddNegativeKeyword_Auto() 
          } 
        </Paper>
        { // If negative keyword creation in progress then show progress indicator
          isCreatingNegativeKeywordAuto && <LinearProgress />
        }
      </React.Fragment>
    );
  }

  // Render ui to show negative keywords (for Auto flow)
  renderNegativeKeywordAuto = () => {
    
    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing, isUpdatingNegativeKeywordAuto, updatingNegativeKeywordIdAuto } = this.state;
    if (negativeKeywordListApi.length === 0 ) { return null; }
    
    // 2 - Prepare array that consist negative keyword belongs to auto_ad_group_id
    const { auto_campaign_id, auto_ad_group_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if( item.campaignId === auto_campaign_id && item.adGroupId === auto_ad_group_id && item.matchType === 'negativeExact') { return true; } else { return false; }
    });
    //console.log('(Auto) Negative keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // Note: We have to render all flow keyword together and then all extra added 
    // keyword together, so we are making separete array of flow and extra added keyword.


    // 4A - Prepare separate array of flow keywords.
    const keywordListFlow = keywordList.filter((item, index) => {
      // If current keyword is flow keyword then include within list
      const flowKeyword = this.isFlowKeyword(item.keywordText);
      if (flowKeyword) { return true; }
    });
    
    // 4B- Sort array alphabetically by keywordText
    const keywordListFlowSorted = keywordListFlow.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('keywordListFlowSorted:', keywordListFlowSorted);
    
    // 4C - Render ui rows for flow keywords
    // Note: We will not show toggle state icon with flow keyword because 
    // user can disable/enable state for flow keyword globally.
    const tableRowEl1 = keywordListFlowSorted.map( (item, index) => {
      // Show enabled state in green color, otherwise grey
      let stateColor = item.state === 'enabled' ? green[500] : grey[500];

      return(
        <React.Fragment key={'negative-keyword-auto-cover1-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 4 }} >
              <CircleIcon style={{ marginRight: 4, color: stateColor, verticalAlign: 'middle', fontSize: 14 }} /> 
              { item.keywordText }
            </TableCell>
            <TableCell align="right" style={{ padding: 0, width: 30 }} >
            </TableCell>
          </TableRow>
          { this.state.showDebug && 
          <TableRow>
            <TableCell align="left" colSpan={2} style={{ padding: 4 }} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      );
    });


    // 5A - Prepare separate array of extra added keywords.
    const keywordListExtra = keywordList.filter((item, index) => {
      // If current keyword not a flow keyword then include within list
      const flowKeyword = this.isFlowKeyword(item.keywordText);
      if (!flowKeyword) { return true; }
    });
    
    // 5B - Sort array alphabetically by keywordText
    const keywordListExtraSorted = keywordListExtra.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('keywordListExtraSorted:', keywordListExtraSorted);

    // 5C - Render ui rows for extra added keywords
    // Show switch to toggle state for extra added keyword.
    const tableRowEl2 = keywordListExtraSorted.map( (item, index) => {
      return(
        <React.Fragment key={'negative-keyword-auto-cover2-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 4 }} >
              { item.keywordText }
              { // If current keyword updates in progress then show progress
              (isUpdatingNegativeKeywordAuto && updatingNegativeKeywordIdAuto === item.keywordId) &&
                <CircularProgress size={14} style={{marginLeft: 5}} /> 
              }
            </TableCell>
            <TableCell align="right" style={{ padding: 0, width: 30 }} >
              <Switch
                checked={ item.state === 'enabled' ? true : false }
                onChange={ (event) => { 
                  this.toggleState_NegativeKeyword_Auto(event.target.checked, item, index);
                }} 
                name={ "toggle-state-negative-auto-" + index }
                disabled={ isProcessing }
                inputProps={{ 'aria-label': 'toggle state' }}
                //style={{ color: '#52d869' }}
              />
            </TableCell>
          </TableRow>
          { this.state.showDebug && 
          <TableRow>
            <TableCell align="left" colSpan={2} style={{ padding: 4 }} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      );
    });

    // 6 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Negative Exact keywords - Auto Campaign">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl1 }
            { tableRowEl2 }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Render all content related to manual flow
  renderContentManualFlow = () => {
    const { classes } = this.props;
    const { manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id } = this.state.flowDataDb;
    const { keywordListFetched, isCreatingNegativeKeywordBroad, isCreatingNegativeKeywordPhrase } = this.state;
    const { adGroupListApi } = this.state;
    
    const adGroupBroad = adGroupListApi.find((item, index) => { return item.adGroupId === manual_broad_ad_group_id });
    let defaultBidBroad = adGroupBroad ? adGroupBroad.defaultBid : ''; 
    
    const adGroupPhrase = adGroupListApi.find((item, index) => { return item.adGroupId === manual_phrase_ad_group_id });
    let defaultBidPhrase = adGroupPhrase ? adGroupPhrase.defaultBid : ''; 

    const adGroupExact = adGroupListApi.find((item, index) => { return item.adGroupId === manual_exact_ad_group_id });
    let defaultBidExact = adGroupExact ? adGroupExact.defaultBid : ''; 

    return(
      <React.Fragment>
        
        <Paper elevation={ 2 } className={classes.paper}>
          <Typography variant="h6" style={{ textAlign: 'center' }} >
            Manual Campaign
          </Typography>
        </Paper>

        <div style={{ height: 16 }}></div>
        
        <Grid container spacing={2}>
          
          <Grid item xs={12} sm={4} >
            <Paper elevation={2} className={classes.paper}>
              <div align="center">
                <FormLabel>Broad Ad Group</FormLabel> <br/>
                { defaultBidBroad !== '' && 
                  <Typography variant="caption" >Default Bid ${defaultBidBroad}</Typography>
                }
              </div>
            
              { !manual_broad_ad_group_id && 'You can create Broad AdGroup from Edit Campaign screen.' } 

              { manual_broad_ad_group_id && <b><br />Broad Keywords:</b> } 
              { this.renderKeyword_ManualBroad() } 

              { manual_broad_ad_group_id && <b><br />NegativeExact Keywords:</b> } 
              { this.renderNegativeKeyword_ManualBroad() } 

              { manual_broad_ad_group_id && keywordListFetched && 
                this.renderForm_AddNegativeKeyword_Broad() 
              }
            </Paper>
            { // If negative keyword creation in progress then show progress indicator
              isCreatingNegativeKeywordBroad && <LinearProgress />
            }
          </Grid>

          <Grid item xs={12} sm={4} >
            <Paper elevation={2} className={classes.paper}>
              <div align="center">
                <FormLabel>Phrase Ad Group</FormLabel> <br/>
                { defaultBidPhrase !== '' && 
                  <Typography variant="caption" >Default Bid ${defaultBidPhrase}</Typography>
                }
              </div>
            
              { !manual_phrase_ad_group_id && 'You can create Phrase AdGroup from Edit Campaign screen.' } 

              { manual_phrase_ad_group_id && <b><br />Phrase Keywords:</b> } 
              { this.renderKeyword_ManualPhrase() } 

              { manual_phrase_ad_group_id && <b><br />NegativeExact Keywords:</b> } 
              { this.renderNegativeKeyword_ManualPhrase() } 

              { manual_broad_ad_group_id && keywordListFetched &&
                this.renderForm_AddNegativeKeyword_Phrase() 
              }
            </Paper>
            { // If negative keyword creation in progress then show progress indicator
              isCreatingNegativeKeywordPhrase && <LinearProgress />
            }
          </Grid>

          <Grid item xs={12} sm={4} >
            <Paper elevation={2} className={classes.paper}>
              <div align="center">
                <FormLabel>Exact Ad Group</FormLabel> <br/>
                { defaultBidExact !== '' && 
                  <Typography variant="caption" >Default Bid ${defaultBidExact}</Typography>
                }
              </div>
            
              { !manual_exact_ad_group_id && 'You can create Exact AdGroup from Edit Campaign screen.' } 

              { manual_exact_ad_group_id && <b><br />Exact Keywords:</b> } 
              { this.renderKeyword_ManualExact() } 
            </Paper>
          </Grid>

        </Grid>

      </React.Fragment>
    );
  }


  // Render keyword for Manual Broad Ad Group
  renderKeyword_ManualBroad = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, bidRecommendationBroad, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_broad_ad_group_id and broad match type.
    const { manual_campaign_id, manual_broad_ad_group_id } = this.state.flowDataDb; 
    const keywordList = keywordListApi.filter( (item, index) => { 
      if ( item.campaignId === manual_campaign_id && item.adGroupId === manual_broad_ad_group_id && item.matchType === 'broad' ) { return true; } else { return false; } 
    });
    //console.log('(Manual Broad) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { 
      return null;
    }

    // 3A - Sort keywordList array alphabetically by keywordText
    const keywordListSorted = keywordList.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('(Broad) keywordListSorted:', keywordListSorted);

    // 4 - Read other data from state
    const { editBroad, editBroadIndex, editBroadKeywordId, editBroadInProgress } = this.state;
    let isDisabled = false;
    if (isProcessing) { 
      isDisabled = true;
    }

    // 5 - Prepare rows for keyword list
    const tableRowEl = keywordListSorted.map( (item, index) => {

      // Decide that show edit mode for current keyword or not
      // If current keyword in edit mode then render edit input for this row
      let showEditMode = false;
      if ( editBroad === true && editBroadIndex === index && editBroadKeywordId === item.keywordId ) { // Current record in edit mode
        showEditMode = true;
      }

      // Show enabled state in green color, otherwise grey
      let stateColor = grey[500];
      if (item.state === 'enabled') { stateColor = green[500]; } 

      // Prepare data to display bid recommendation (i.e. suggested bid)
      const bidRecommendation = bidRecommendationBroad[item.keywordText]; 
      let suggestedBid = ''; let rangeStartBid = ''; let rangeEndBid = ''; 
      if ( bidRecommendation ) { // If bid recommendation exist 
        const { suggested, rangeStart, rangeEnd } = bidRecommendation; 
        suggestedBid = suggested ? suggested : ''; 
        rangeStartBid = rangeStart ? rangeStart : ''; 
        rangeEndBid = rangeEnd? rangeEnd : ''; 
      }

      return (
        <React.Fragment key={'keyword-broad-cover-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 0, }} >
              <CircleIcon style={{ marginRight: 4, color: stateColor, verticalAlign: 'middle', fontSize: 14 }} />
              { item.keywordText }
            </TableCell>

            { // Edit mode off - Show info 
            !showEditMode &&
            <TableCell align="right" style={{ padding: 0, width: 40 }} >
              ${item.bid}
            </TableCell>
            }
            { // Edit mode on - show bid amount input
            showEditMode &&
            <TableCell align="right" style={{ padding: 0, width: 120 }} >
              <TextField 
                variant="standard" 
                id="edit-bid-amount-broad" 
                label="Bid (min. $0.02)" 
                value={ this.state.editBroadBidAmount } 
                onChange={ (e) => this.onChange_BidAmount_Broad_Keyword(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                //helperText="min. $0.02" 
                //style={{ marginRight: 10 }} 
              />
            </TableCell> 
            }
            
            { // Edit mode off - Show edit icon
            !showEditMode && 
            <TableCell align="right" style={{ padding: 0, width: 30 }} > 
              <IconButton 
                size="small" 
                aria-label="Edit Keyword Broad" 
                style={{ marginLeft: 10, marginTop: 0 }} 
                onClick={ (e) => this.onClickEdit_Keyword_Broad(item, index) } 
                disabled={ isProcessing } 
                title="Edit Keyword - Broad" 
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </TableCell>
            }
            { // Edit mode on - Show save/cancel icon
            showEditMode && 
            <TableCell align="center" style={{ padding: 0, width: 80 }} > 
              { // If bid amount update in progress (Api call is running) then show loader
                editBroadInProgress && 
                <CircularProgress size={14} />
              }
              { // If bid amount update Not in progress then show save cancel button.
                !editBroadInProgress && 
                <React.Fragment>
                  <IconButton 
                    size="small" 
                    aria-label="Save Keyword Broad" 
                    style={{ marginLeft: 0, marginTop: 0 }} 
                    onClick={ (e) => this.onClickEdit_Save_Keyword_Broad(item, index) } 
                    disabled={ isProcessing } 
                    title="Save Keyword - Broad" 
                  >
                    <SaveIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    aria-label="Cancel Keyword Broad" 
                    style={{ marginLeft: 10, marginTop: 0 }} 
                    onClick={ (e) => this.onClickEdit_Cancel_Keyword_Broad(item, index) } 
                    disabled={ isProcessing } 
                    title="Cancel" 
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            </TableCell>
            }
          </TableRow>

          { bidRecommendation &&
          <TableRow>
            <TableCell align="left" style={{ padding: 0, }} colSpan={3} >
              <Typography variant="caption" style={{ marginLeft: 16, }} >
                <SubdirectoryArrowRightIcon fontSize="small" style={{ color: "#cdcdcd", verticalAlign: "bottom"  }} />
                Suggested Bid ${suggestedBid} ({rangeStartBid} - {rangeEndBid})
              </Typography>
            </TableCell>
            {
            // <TableCell align="right" style={{ padding: 0, width: 40 }} >$ ??</TableCell>
            // <TableCell align="right" style={{ padding: 0, width: 30 }} ></TableCell>
            }
          </TableRow>
          }

          { this.state.showDebug &&
          <TableRow>
            <TableCell align="left" style={{ padding: 0, }} colSpan={3} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      )
    });

    // 6 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Broad Keywords">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >State</TableCell>          
          //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Render keyword for Manual Phrase Ad Group
  renderKeyword_ManualPhrase = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, bidRecommendationPhrase, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_phrase_ad_group_id and phrase match type.
    const { manual_campaign_id, manual_phrase_ad_group_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if ( item.campaignId === manual_campaign_id && item.adGroupId === manual_phrase_ad_group_id && item.matchType === 'phrase' ) { return true; } else { return false; }
    });
    //console.log('(Manual Phrase) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { 
      return null; 
    }

    // 3A - Sort keywordList array alphabetically by keywordText
    const keywordListSorted = keywordList.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('(Phrase) keywordListSorted:', keywordListSorted);

    // 4 - Read other data from state
    const { editPhrase, editPhraseIndex, editPhraseKeywordId, editPhraseInProgress } = this.state;
    let isDisabled = false;
    if (isProcessing) { 
      isDisabled = true;
    }

    // 5 - Prepare rows for keyword list
    const tableRowEl = keywordListSorted.map( (item, index) => {

      // Decide that show edit mode for current keyword or not
      // If current keyword in edit mode then render edit input for this row
      let showEditMode = false;
      if ( editPhrase === true && editPhraseIndex === index && editPhraseKeywordId === item.keywordId ) { // Current record in edit mode
        showEditMode = true;
      }

      // Show enabled state in green color, otherwise grey
      let stateColor = grey[500];
      if (item.state === 'enabled') { stateColor = green[500]; } 

      // Prepare data to display bid recommendation (i.e. suggested bid)
      const bidRecommendation = bidRecommendationPhrase[item.keywordText]; 
      let suggestedBid = ''; let rangeStartBid = ''; let rangeEndBid = ''; 
      if ( bidRecommendation ) { // If bid recommendation exist 
        const { suggested, rangeStart, rangeEnd } = bidRecommendation; 
        suggestedBid = suggested ? suggested : ''; 
        rangeStartBid = rangeStart ? rangeStart : ''; 
        rangeEndBid = rangeEnd? rangeEnd : ''; 
      }

      return(
        <React.Fragment key={'keyword-phrase-cover-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 0 }} >
              <CircleIcon style={{ marginRight: 4, color: stateColor, verticalAlign: 'middle', fontSize: 14 }} />
              { item.keywordText }
            </TableCell>

            { // Edit mode off - Show info 
            !showEditMode &&
            <TableCell align="right" style={{ padding: 0, width: 40 }} >
              ${ item.bid } 
            </TableCell>
            }
            { // Edit mode on - show bid amount input
            showEditMode &&
            <TableCell align="right" style={{ padding: 0, width: 120 }} >
              <TextField 
                variant="standard" 
                id="edit-bid-amount-phrase" 
                label="Bid (min. $0.02)" 
                value={ this.state.editPhraseBidAmount } 
                onChange={ (e) => this.onChange_BidAmount_Phrase_Keyword(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                //helperText="min. $0.02" 
                //style={{ marginRight: 10 }} 
              />
            </TableCell> 
            }

            { // Edit mode off - Show edit icon
            !showEditMode && 
            <TableCell align="right" style={{ padding: 0, width: 30 }} > 
              <IconButton 
                size="small" 
                aria-label="Edit Keyword Phrase" 
                style={{ marginLeft: 10, marginTop: 0 }} 
                onClick={ (e) => this.onClickEdit_Keyword_Phrase(item, index) } 
                disabled={ isProcessing } 
                title="Edit Keyword - Phrase" 
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </TableCell>
            }
            { // Edit mode on - Show save/cancel icon
            showEditMode && 
            <TableCell align="center" style={{ padding: 0, width: 80 }} > 
              { // If bid amount update in progress (Api call is running) then show loader
                editPhraseInProgress && 
                <CircularProgress size={14} />
              }
              { // If update bid amount update not in progress then show save cancel button.
                !editPhraseInProgress && 
                <React.Fragment>
                  <IconButton 
                    size="small" 
                    aria-label="Save Keyword Phrase" 
                    style={{ marginLeft: 0, marginTop: 0 }} 
                    onClick={ (e) => this.onClickEdit_Save_Keyword_Phrase(item, index) } 
                    disabled={ isProcessing } 
                    title="Save Keyword - Phrase" 
                  >
                    <SaveIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    aria-label="Cancel Keyword Phrase" 
                    style={{ marginLeft: 10, marginTop: 0 }} 
                    onClick={ (e) => this.onClickEdit_Cancel_Keyword_Phrase(item, index) } 
                    disabled={ isProcessing } 
                    title="Cancel" 
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            </TableCell>
            }
          </TableRow>

          { bidRecommendation &&
          <TableRow>
            <TableCell align="left" style={{ padding: 0, }} colSpan={3} >
              <Typography variant="caption" style={{ marginLeft: 16, }} >
                <SubdirectoryArrowRightIcon fontSize="small" style={{ color: "#cdcdcd", verticalAlign: "bottom" }} />
                Suggested Bid ${suggestedBid}  ({rangeStartBid} - {rangeEndBid})
              </Typography>
            </TableCell>
            {
            // <TableCell align="right" style={{ padding: 0, width: 40 }} >$ ??</TableCell>
            // <TableCell align="right" style={{ padding: 0, width: 30 }} ></TableCell>
            }
          </TableRow>
          }

          { this.state.showDebug &&
          <TableRow >
            <TableCell align="left" style={{ padding: 0, }} colSpan={3} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      )
    });

    // 5 - Return ui elements
    return (
      <TableContainer >
        <Table aria-label="Manaul Phrase Keywords">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
          //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >State</TableCell>
          //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Render keyword for Manual Exact Ad Group
  renderKeyword_ManualExact = () => {

    // 1 - If keyword list empty then return
    const { keywordListApi, bidRecommendationExact, isProcessing } = this.state;
    if (keywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist keyword belongs to manual_exact_ad_group_id and exact match type.
    const { manual_campaign_id, manual_exact_ad_group_id } = this.state.flowDataDb;
    const keywordList = keywordListApi.filter( (item, index) => { 
      if ( item.campaignId === manual_campaign_id && item.adGroupId === manual_exact_ad_group_id && item.matchType === 'exact' ) { return true; } else { return false; }
    });
    //console.log('(Manual Exact) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { 
      return null;
    }

    // 3A - Sort keywordList array alphabetically by keywordText
    const keywordListSorted = keywordList.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('(Exact) keywordListSorted:', keywordListSorted);

    // 4 - Read other data from state
    const { editExact, editExactIndex, editExactKeywordId, editExactInProgress } = this.state;
    let isDisabled = false;
    if (isProcessing) { 
      isDisabled = true;
    }

    // 5 - Prepare ui element for keywords
    const tableRowEl = keywordListSorted.map( (item, index) => {

      // Decide that show edit mode for current keyword or not
      // If current keyword in edit mode then render edit input for this row
      let showEditMode = false;
      if ( editExact === true && editExactIndex === index && editExactKeywordId === item.keywordId ) { // Current record in edit mode
        showEditMode = true;
      }

      // Show enabled state in green color, otherwise grey
      let stateColor = grey[500];
      if (item.state === 'enabled') { stateColor = green[500]; } 

      // Prepare data to display bid recommendation (i.e. suggested bid)
      const bidRecommendation = bidRecommendationExact[item.keywordText]; 
      let suggestedBid = ''; let rangeStartBid = ''; let rangeEndBid = ''; 
      if ( bidRecommendation ) { // If bid recommendation exist 
        const { suggested, rangeStart, rangeEnd } = bidRecommendation; 
        suggestedBid = suggested ? suggested : ''; 
        rangeStartBid = rangeStart ? rangeStart : ''; 
        rangeEndBid = rangeEnd? rangeEnd : ''; 
      }

      return(
        <React.Fragment key={'keyword-broad-exact-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 0 }} >
              <CircleIcon style={{ marginRight: 4, color: stateColor, verticalAlign: 'middle', fontSize: 14 }} /> 
              { item.keywordText }
            </TableCell>

            { // Edit mode off - Show info 
            !showEditMode &&
            <TableCell align="right" style={{ padding: 0, width: 40 }} >
              ${ item.bid }
            </TableCell>
            }
            { // Edit mode on - show bid amount input
            showEditMode &&
            <TableCell align="right" style={{ padding: 0, width: 120 }} >
              <TextField 
                variant="standard" 
                id="edit-bid-amount-exact" 
                label="Bid (min. $0.02)" 
                value={ this.state.editExactBidAmount } 
                onChange={ (e) => this.onChange_BidAmount_Exact_Keyword(e.target.value) } 
                size="small" 
                disabled={ isDisabled } 
                InputLabelProps={{ shrink: true, }} 
                InputProps={{ 
                  startAdornment: <InputAdornment position="start">$</InputAdornment>, 
                }}
                //helperText="min. $0.02" 
                //style={{ marginRight: 10 }} 
              />
            </TableCell> 
            }

            { // Edit mode off - Show edit icon
            !showEditMode && 
            <TableCell align="right" style={{ padding: 0, width: 30 }} > 
              <IconButton 
                size="small" 
                aria-label="Edit Keyword Exact" 
                style={{ marginLeft: 10, marginTop: 0 }} 
                onClick={ (e) => this.onClickEdit_Keyword_Exact(item, index) } 
                disabled={ isProcessing } 
                title="Edit Keyword - Exact" 
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </TableCell>
            }
            { // Edit mode on - Show save/cancel icon
            showEditMode && 
            <TableCell align="center" style={{ padding: 0, width: 80 }} > 
              { // If bid amount update in progress (Api call is running) then show loader
                editExactInProgress && 
                <CircularProgress size={14} />
              }
              { // If update bid amount update not in progress then show save cancel button.
                !editExactInProgress && 
                <React.Fragment>
                  <IconButton 
                    size="small" 
                    aria-label="Save Keyword Exact" 
                    style={{ marginLeft: 0, marginTop: 0 }} 
                    onClick={ (e) => this.onClickEdit_Save_Keyword_Exact(item, index) } 
                    disabled={ isProcessing } 
                    title="Save Keyword - Exact" 
                  >
                    <SaveIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    aria-label="Cancel Keyword Exact" 
                    style={{ marginLeft: 10, marginTop: 0 }} 
                    onClick={ (e) => this.onClickEdit_Cancel_Keyword_Exact(item, index) } 
                    disabled={ isProcessing } 
                    title="Cancel" 
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            </TableCell>
            }
          </TableRow>

          { bidRecommendation &&
          <TableRow>
            <TableCell align="left" style={{ padding: 0, }} colSpan={3} >
              <Typography variant="caption" style={{ marginLeft: 16, }} >
                <SubdirectoryArrowRightIcon fontSize="small" style={{ color: "#cdcdcd", verticalAlign: "bottom"  }} />
                Suggested Bid ${suggestedBid}  ({rangeStartBid} - {rangeEndBid})
              </Typography>
            </TableCell>
            {
            // <TableCell align="right" style={{ padding: 0, width: 40 }} >$ ??</TableCell>
            // <TableCell align="right" style={{ padding: 0, width: 30 }} ></TableCell>
            }
          </TableRow>
          }

          { this.state.showDebug &&
          <TableRow>
            <TableCell align="left" style={{ padding: 0, }} colSpan={3} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      )
    });

    // 5 - Return ui elements
    return (
      <React.Fragment>
        <TableContainer>
          <Table aria-label="Manual Exact Keywords">
            {
            // <TableHead>
            //   <TableRow>
            //     <TableCell align="left" style={{ padding: 4, lineHeight: 1 }} >Keyword</TableCell>
            //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >Bid</TableCell>
            //     <TableCell align="center" style={{ padding: 4, lineHeight: 1 }} >State</TableCell>            
            //     <TableCell align="right" style={{ padding: 4, lineHeight: 1 }} ></TableCell>
            //   </TableRow>
            // </TableHead>
            }
            <TableBody>
              { tableRowEl }
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }


  // Render negative exact keyword for manual broad ad group
  renderNegativeKeyword_ManualBroad = () => {

    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing, isUpdatingNegativeKeywordBroad, updatingNegativeKeywordIdBroad } = this.state;
    if (negativeKeywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist negative keywords belongs to manual_broad_ad_group_id
    const { manual_campaign_id, manual_broad_ad_group_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if( item.campaignId === manual_campaign_id && item.adGroupId === manual_broad_ad_group_id && item.matchType === 'negativeExact' ) { 
        return true; 
      } else { 
        return false; 
      } 
    });
    //console.log('(Negative Exact - Manual Broad) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // Note: We have to render all flow keyword together and then all extra added 
    // keyword together, so we are making separate array of flow and extra added keyword.


    // 4A - Prepare separate array of flow keywords.
    const keywordListFlow = keywordList.filter((item, index) => {
      // If current keyword is flow keyword then include within list
      const flowKeyword = this.isFlowKeyword(item.keywordText);
      if (flowKeyword) { return true; }
    });
    
    // 4B - Sort array alphabetically by keywordText
    const keywordListFlowSorted = keywordListFlow.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('keywordListFlowSorted:', keywordListFlowSorted);

    // 4C - Render ui rows for flow keywords
    // Note: We will not show toggle state icon with flow keyword because 
    // user can disable/enable state for flow keyword globally.
    const tableRowEl1 = keywordListFlowSorted.map( (item, index) => {
      // Show enabled state in green color, otherwise grey
      let stateColor = item.state === 'enabled' ? green[500] : grey[500];

      return(
        <React.Fragment key={'negative-keyword-broad-cover1-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 4 }} >
              <CircleIcon style={{ marginRight: 4, color: stateColor, verticalAlign: 'middle', fontSize: 14 }} /> 
              { item.keywordText }
            </TableCell>
            <TableCell align="right" style={{ padding: 0, width: 30 }} >
            </TableCell>
          </TableRow>
          { this.state.showDebug && 
          <TableRow>
            <TableCell align="left" colSpan={2} style={{ padding: 4 }} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      );
    });


    // 5A - Prepare separate array of extra added keywords.
    const keywordListExtra = keywordList.filter((item, index) => {
      // If current keyword not a flow keyword then include within list
      const flowKeyword = this.isFlowKeyword(item.keywordText);
      if (!flowKeyword) { return true; }
    });
    
    // 5B - Sort array alphabetically by keywordText
    const keywordListExtraSorted = keywordListExtra.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('keywordListExtraSorted:', keywordListExtraSorted);

    // 5C - Render ui rows for extra added keywords
    // Show switch to toggle state for extra added keyword.
    const tableRowEl2 = keywordListExtraSorted.map( (item, index) => {
      return(
        <React.Fragment key={'negative-keyword-broad-cover2-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 4 }} >
              { item.keywordText }
              { // If current keyword updates in progress then show progress
              (isUpdatingNegativeKeywordBroad && updatingNegativeKeywordIdBroad === item.keywordId) &&
                <CircularProgress size={14} style={{marginLeft: 5}} /> 
              }
            </TableCell>
            <TableCell align="right" style={{ padding: 0, width: 30 }} >
              <Switch
                checked={ item.state === 'enabled' ? true : false }
                onChange={ (event) => { 
                  this.toggleState_NegativeKeyword_Broad(event.target.checked, item, index);
                }} 
                name={ "toggle-state-negative-broad-" + index }
                disabled={ isProcessing }
                inputProps={{ 'aria-label': 'toggle state' }}
                //style={{ color: '#52d869' }}
              />
            </TableCell>
          </TableRow>
          { this.state.showDebug && 
          <TableRow>
            <TableCell align="left" colSpan={2} style={{ padding: 4 }} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      );
    });

    // 6 - Return ui elements
    return (
      <TableContainer>
        <Table aria-label="Negative Exact keywords - Manual Broad Ad Group">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >State</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl1 }
            { tableRowEl2 }
          </TableBody>
        </Table>
      </TableContainer>
    ); 
  }


  // Render negative exact keyword for manual phrase ad group
  renderNegativeKeyword_ManualPhrase = () => {

    // 1 - If negative keyword list empty then return
    const { negativeKeywordListApi, isProcessing, isUpdatingNegativeKeywordPhrase, updatingNegativeKeywordIdPhrase } = this.state;
    if (negativeKeywordListApi.length === 0 ) { return null; }

    // 2 - Prepare array that consist negative keywords belongs to 
    // manual_campaign_id and manual_phrase_ad_group_id
    const { manual_campaign_id, manual_phrase_ad_group_id } = this.state.flowDataDb;
    const keywordList = negativeKeywordListApi.filter( (item, index) => { 
      if( item.campaignId === manual_campaign_id && item.adGroupId === manual_phrase_ad_group_id && item.matchType === 'negativeExact' ) { return true; } else { return false; }
    });
    //console.log('(Negative Exact - Manual Phrase) keywordList:', keywordList);

    // 3 - If keyword list empty then return
    if (keywordList.length === 0) { return null; }

    // Note: We have to render all flow keyword together and then all extra added 
    // keyword together, so we are making separate array of flow and extra added keyword.


    // 4A - Prepare separate array of flow keywords.
    const keywordListFlow = keywordList.filter((item, index) => {
      // If current keyword is flow keyword then include within list
      const flowKeyword = this.isFlowKeyword(item.keywordText);
      if (flowKeyword) { return true; }
    });
    
    // 4B - Sort array alphabetically by keywordText
    const keywordListFlowSorted = keywordListFlow.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('keywordListFlowSorted:', keywordListFlowSorted);

    // 4C - Render ui rows for flow keywords
    // Note: We will not show toggle state icon with flow keyword because 
    // user can disable/enable state for flow keyword globally.
    const tableRowEl1 = keywordListFlowSorted.map( (item, index) => {
      // Show enabled state in green color, otherwise grey
      let stateColor = item.state === 'enabled' ? green[500] : grey[500];

      return(
        <React.Fragment key={'negative-keyword-phrase-cover1-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 4 }} >
              <CircleIcon style={{ marginRight: 4, color: stateColor, verticalAlign: 'middle', fontSize: 14 }} /> 
              { item.keywordText }
            </TableCell>
            <TableCell align="right" style={{ padding: 0, width: 30 }} >
            </TableCell>
          </TableRow>
          { this.state.showDebug && 
          <TableRow>
            <TableCell align="left" colSpan={2} style={{ padding: 4 }} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      );
    });


    // 5A - Prepare separate array of extra added keywords.
    const keywordListExtra = keywordList.filter((item, index) => {
      // If current keyword not a flow keyword then include within list
      const flowKeyword = this.isFlowKeyword(item.keywordText);
      if (!flowKeyword) { return true; }
    });

    // 5B - Sort array alphabetically by keywordText
    const keywordListExtraSorted = keywordListExtra.sort((itemA, itemB) =>{
      if (itemA.keywordText < itemB.keywordText) { return -1; }
      if (itemA.keywordText > itemB.keywordText) { return 1; }
      if (itemA.keywordText === itemB.keywordText) { return 0; }
    });
    //console.log('keywordListExtraSorted:', keywordListExtraSorted);

    // 5C - Render ui rows for extra added keywords
    // Show switch to toggle state for extra added keyword.
    const tableRowEl2 = keywordListExtraSorted.map( (item, index) => {
      return(
        <React.Fragment key={'negative-keyword-phrase-cover2-' + index } >
          <TableRow>
            <TableCell align="left" style={{ padding: 4 }} >
              { item.keywordText }
              { // If current keyword updates in progress then show progress
              (isUpdatingNegativeKeywordPhrase && updatingNegativeKeywordIdPhrase === item.keywordId) &&
                <CircularProgress size={14} style={{marginLeft: 5}} /> 
              }
            </TableCell>
            <TableCell align="right" style={{ padding: 0, width: 30 }} >
              <Switch
                checked={ item.state === 'enabled' ? true : false }
                onChange={ (event) => { 
                  this.toggleState_NegativeKeyword_Phrase(event.target.checked, item, index);
                }} 
                name={ "toggle-state-negative-phrase-" + index }
                disabled={ isProcessing }
                inputProps={{ 'aria-label': 'toggle state' }}
                //style={{ color: '#52d869' }}
              />
            </TableCell>
          </TableRow>
          { this.state.showDebug && 
          <TableRow>
            <TableCell align="left" colSpan={2} style={{ padding: 4 }} >
              <FormHelperText>
                keywordId: { item.keywordId } | { item. matchType } | { item. state } | adGroupId: { item.adGroupId } | campaignId: { item.campaignId }
              </FormHelperText>
            </TableCell>
          </TableRow>
          }
        </React.Fragment>
      );
    });


    // 6 - Return ui elements
    return (
      <TableContainer>
        <Table aria-label="Negative Exact keywords - Manual Phrase Ad Group">
          {
          // <TableHead>
          //   <TableRow>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Keyword</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >State</TableCell>
          //     <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Action</TableCell>
          //   </TableRow>
          // </TableHead>
          }
          <TableBody>
            { tableRowEl1 }
            { tableRowEl2 }
          </TableBody>
        </Table>
      </TableContainer>
    ); 
  }

  // If given keyword text exist within flow keword list then it is flow keyword 
  // so return true, otherwise not a flow keyword so return false.
  isFlowKeyword = (keywordText) => {

    const { flowKeywordList } = this.state;
    
    // If flow keyword list not exist, then consider given keyword as flow keyword,
    if ( !flowKeywordList ) { return true; }

    // If flow keyword list exist then check that given keyword found within it.
    let isExist = false;
    flowKeywordList.forEach( (item, index) => {
      if ( item.keywordText === keywordText ) {
        isExist = true;
      }
    });

    return isExist;
  }



  // Render keyword list for debug purpose
  debugKeywordList = () => {

    // 1 - If list empty then return
    const { keywordListApi } = this.state;
    if (keywordListApi.length === 0) { return null; }

    // 2 - Fetch flow data from db
    const { 
      manual_campaign_id, auto_campaign_id, 
      auto_ad_group_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id
    } = this.state.flowDataDb
    
    // 3 - Prepare ui rows from data
    const tableRowEl = keywordListApi.map( (item, index) => {
      return(
        <TableRow key={'keyword-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >{ item.keywordId }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.keywordText }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >${item.bid}</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.matchType }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.state }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.adGroupId }
            { auto_ad_group_id === item.adGroupId && ' (Auto)' }
            { manual_broad_ad_group_id === item.adGroupId && ' (Broad)' }
            { manual_phrase_ad_group_id === item.adGroupId && ' (Phrase)' }
            { manual_exact_ad_group_id === item.adGroupId && ' (Exact)' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.campaignId }
            { auto_campaign_id === item.campaignId && ' (Auto)' }
            { manual_campaign_id === item.campaignId && ' (Manual)' }
          </TableCell>
        </TableRow>  
      )
    });

    // 4 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Keyword List - Debug">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >keywordId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >keywordText</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Bid</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >matchType</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >state</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >AdGroupId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >CampaignId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Render negative keyword list for debug purpose
  debugNegativeKeywordList = () => {

    // 1 - If list empty then return
    const { negativeKeywordListApi  } = this.state;
    if ( negativeKeywordListApi.length === 0 ) { return null; }

    // 2 - Fetch flow data from db
    const { 
      manual_campaign_id, auto_campaign_id, 
      auto_ad_group_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id
    } = this.state.flowDataDb
    
    // 3 - Prepare ui rows from data
    const tableRowEl = negativeKeywordListApi.map( (item, index) => {
      return(
        <TableRow key={'keyword-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >{ item.keywordId }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.keywordText }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.matchType }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >{ item.state }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.adGroupId }
            { auto_ad_group_id === item.adGroupId && ' (Auto)' }
            { manual_broad_ad_group_id === item.adGroupId && ' (Broad)' }
            { manual_phrase_ad_group_id === item.adGroupId && ' (Phrase)' }
            { manual_exact_ad_group_id === item.adGroupId && ' (Exact)' }
          </TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
            { item.campaignId }
            { auto_campaign_id === item.campaignId && ' (Auto)' }
            { manual_campaign_id === item.campaignId && ' (Manual)' }
          </TableCell>
        </TableRow>  
      )
    });

    // 4 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Neagative Keyword List - Debug">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >keywordId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >keywordText</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >matchType</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >state</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >AdGroupId</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >CampaignId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  // Debug bid recommendation (Broad Ad Group)
  debugBidRecommendationBroad = () => {

    // 1 - If list empty then return
    const { bidRecommendationBroad } = this.state;
    
    // 3 - Prepare ui rows from data
    const tableRowEl = Object.keys(bidRecommendationBroad).map( (key, index) => {
      const suggestion = bidRecommendationBroad[key];
      const { rangeEnd, rangeStart, suggested } = suggestion;

      return(
        <TableRow key={'bid-recommend-broad-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >{ key }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
              ${ suggested } ({ rangeStart } - { rangeEnd })
          </TableCell>
        </TableRow>  
      )
    });

    // 4 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >keyword</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Suggestion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );

  }


  // Debug bid recommendation (Phrase Ad Group)
  debugBidRecommendationPhrase = () => {

    // 1 - If list empty then return
    const { bidRecommendationPhrase } = this.state;
    
    // 3 - Prepare ui rows from data
    const tableRowEl = Object.keys(bidRecommendationPhrase).map( (key, index) => {
      const suggestion = bidRecommendationPhrase[key];
      const { rangeEnd, rangeStart, suggested } = suggestion;

      return(
        <TableRow key={'bid-recommend-phrase-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >{ key }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
              ${ suggested } ({ rangeStart } - { rangeEnd })
          </TableCell>
        </TableRow>  
      )
    });

    // 4 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >keyword</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Suggestion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
    
  }

  // Debug bid recommendation (Exact Ad Group)
  debugBidRecommendationExact = () => {

    // 1 - If list empty then return
    const { bidRecommendationExact } = this.state;
    
    // 3 - Prepare ui rows from data
    const tableRowEl = Object.keys(bidRecommendationExact).map( (key, index) => {
      const suggestion = bidRecommendationExact[key];
      const { rangeEnd, rangeStart, suggested } = suggestion;

      return(
        <TableRow key={'bid-recommend-exact-' + index } >
          <TableCell align="left" style={{ padding: 2 }} >{ key }</TableCell>
          <TableCell align="left" style={{ padding: 2 }} >
              ${ suggested } ({ rangeStart } - { rangeEnd })
          </TableCell>
        </TableRow>  
      )
    });

    // 4 - Return ui elements
    return (
      <TableContainer component={Paper}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >keyword</TableCell>
              <TableCell align="left" style={{ padding: 2, lineHeight: 1 }} >Suggestion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tableRowEl }
          </TableBody>
        </Table>
      </TableContainer>
    );
    
  }



  debug_Info = () => {

    const { 
      manual_campaign_id, auto_campaign_id, 
      auto_ad_group_id, manual_broad_ad_group_id, manual_phrase_ad_group_id, manual_exact_ad_group_id
    } = this.state.flowDataDb

    return(
      <Paper elevation={2} style={{ padding: 10 }} >
        auto_campaign_id (db): { auto_campaign_id } <br />
        auto_ad_group_id (db): { auto_ad_group_id } <br />
        <br />
        manual_campaign_id (db): { manual_campaign_id } <br />
        manual_broad_ad_group_id (db): { manual_broad_ad_group_id } <br />
        manual_phrase_ad_group_id (db): { manual_phrase_ad_group_id } <br />
        manual_exact_ad_group_id (db): { manual_exact_ad_group_id } <br />
        <br />
      </Paper>
    );
  }


  // Render debug button
  renderDebugButton = () => {
    return(
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.showDebug}
              onChange={ (event) => { this.setState({showDebug: event.target.checked }); } }
              name="show-debug"
              color="primary"
            />
          }
          label="Show Debug"
          labelPlacement="end"
        />
      </FormGroup>
    );
  }
  //-----------------------------------------------------------------
  // End: Rendering related functions
  //-----------------------------------------------------------------

  // Called when message close after time out (snackbar message)
  handleSnackbarClose = () => {
    console.log('handleSnackbarClose()');
    
    this.setState({
      showMessage: false,
      messageText: '',
    });
  }

  
  render() {
    const { classes } = this.props;
    const { isOpen, flowDataDb } = this.state;
    const { isProcessing } = this.state;

    let isDisabled = false;
    if ( isProcessing ) {
      isDisabled = true;
    }

    const { showMessage, messageText } = this.state;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
      <Dialog disableEscapeKeyDown={true} fullScreen={true} open={isOpen} onClose={this.handleClose} >
        <AppBar className={classes.appBar} color='default' >
          <Toolbar>
            <Typography variant="h6" style={{ flex:1 }} >
              Edit Keywords: { flowDataDb ? flowDataDb.flow_name : ' .... ' } 
            </Typography>
            { 
              this.renderDebugButton() 
            }
            <IconButton 
              edge="end" 
              color="inherit" 
              onClick={this.handleClose} 
              aria-label="close" 
              style={{ marginLeft: 20 }} 
              disabled={ isDisabled }
            >
              <CloseIcon />
            </IconButton>
            {
            // <Button autoFocus color="inherit" onClick={this.handleClose} >Close</Button>
            }
          </Toolbar>
        </AppBar>
        <Paper elevation={0} style={{margin: 0, padding: 20}} >
          { flowDataDb &&
            this.renderContent() 
          }
        </Paper>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          message={messageText}
          severity="success"
          key={vertical + horizontal}
        />

      </Dialog>
    );
  }

}



// define styles
const styles = (theme) => ({
  
  appBar: {
    position: 'relative',
  },
  
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  table: {
    minWidth: 650,
  },

  paper: {
    padding: theme.spacing(1),
    //color: theme.palette.text.secondary,
    //textAlign: 'center',
  },

  formControl: {
    width: '100%',
  },

});

SpAdAutomationEditKeyword.propTypes = {
  flowDocId: PropTypes.string.isRequired,
  onEditKeywordClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

//export default App;
export default withStyles(styles)(SpAdAutomationEditKeyword);


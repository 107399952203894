// Inventory tracker for Admin user.
// From this page admin can see inventory tracker created by all user.

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Inventory tracker for admin related components
import TrackerListAdmin from './TrackerListAdmin';
import RawDataModal from './RawDataModal';
import SalesDataModal from './SalesDataModal';

// Import helper functions, constant etc.
import { getTrackerListAllUser, getUserType } from '../../helpers/firebaseHelper';


class InventoryTrackerAdmin extends React.Component {
  
  state = {

    userType: '',

    // Tracker list created by all user.
    trackerList: [],

    // true - processing in progress, false - not processing
    isProcessing: false,

    // Whenever user click Tracking data (Raw) icon for a single record (from the list). 
    // we will fill up this variable and show modal with Tracking Data (Raw).
    // This modal will show data from tracking_data collection
    // NOTE: We will remove this once development done. This is for admin purpose to view raw data.
    showRawDataModal: false,
    clickedIndex: -1,   // -1 means not clicked any record
    clickedId: '',      // requestId
    clickedAsin: '',    // e.g. ABD86SDFS45DG
    clickedMarket: '',  // e.g. US

    
    // This data we will show to user
    // Whenever user click sales data icon from a single record (from the list). 
    // we will fill up this variable and show modal with sales data for asin+market.
    // This modal will show data from tracking_data_sales collection.
    showSalesDataModal: false,
    salesClickedIndex: -1,   // -1 means not clicked any record
    salesClickedId: '',      // requestId
    salesClickedAsin: '',    // e.g. ABD86SDFS45DG
    salesClickedMarket: '',  // e.g. US
    salesClickedImageUrl: '',  // e.g. small image url 

  }

  componentDidMount = () => {
    console.log('InventoryTrackerAdmin - componentDidMount()');

    getUserType(this.getUserTypeSuccess, this.getUserTypeError);
  }

  getUserTypeSuccess = (userType) => { 
    
    this.setState({ 
      userType: userType 
    });

    // Fetch data if admin user
    if (userType === 'admin') {
      this.fetchData();
    }
  }
  
  getUserTypeError = (error) => { 
    /* Do nothing */ 
  }

  // Fetch tracker created by all user from db.
  fetchData = () => {
    getTrackerListAllUser(this.getTrackerListAllUser_Success, this.getTrackerListAllUser_Error);
  }

  // Fetch tracker list success
  getTrackerListAllUser_Success = (list) => {
    //console.log('InventoryTrackerAdmin - getTrackerListAllUser_Success() list:', list);

    this.setState({
      trackerList: list,
    });
  }

  // Error while fetch tracker list
  getTrackerListAllUser_Error = (error) => {
    console.log('InventoryTrackerAdmin - getTrackerListAllUser_Error()');
  }


  //-------------------------------------------------------------------
  // START: Show tracker raw data modal related functions
  //-------------------------------------------------------------------
  // Called by child component when tracker row data button tapped for any record
  onClickRawData = (id, index) => {
    //console.log('onClickRawData() id:', id, ' index:', index);
    const { asin, market, small_image_url } = this.state.trackerList[index];
    this.setState({
      clickedIndex: index,
      clickedId: id,
      clickedAsin: asin,
      clickedMarket: market,
      clickedImageUrl: small_image_url,
      showRawDataModal: true,
    });
  }

  // This function will be called via child component using callback pattern.
  onCloseRawDataModal = () => {
    this.setState({
      clickedIndex: -1,
      clickedId: '',
      clickedAsin: '',
      clickedMarket: '',
      clickedImageUrl: '',
      showRawDataModal: false,
    });
  }
  //-------------------------------------------------------------------
  // END: Show tracker raw data modal related functions
  //-------------------------------------------------------------------


  //-------------------------------------------------------------------
  // START: Show sales data related functions
  //-------------------------------------------------------------------
  // Called by child component when sales data button tapped from any record
  onClickSalesData = (id, index) => {
    //console.log('onClickSalesData() id:', id, ' index:', index);
    const { asin, market, small_image_url } = this.state.trackerList[index];
    this.setState({
      salesClickedIndex: index,
      salesClickedId: id,
      salesClickedAsin: asin,
      salesClickedMarket: market,
      salesClickedImageUrl: small_image_url,
      showSalesDataModal: true,
    });
  }

  // This function will be called via child component using callback pattern.
  onCloseSalesDataModal = () => {
    this.setState({
      salesClickedIndex: -1,
      salesClickedId: '',
      salesClickedAsin: '',
      salesClickedMarket: '',
      salesClickedImageUrl: '',
      showSalesDataModal: false,
    });
  }
  //-------------------------------------------------------------------
  // END: Show sales data related functions
  //-------------------------------------------------------------------
 

  render() {
    //const { classes } = this.props;
    const { trackerList, userType } = this.state;
    const { clickedId, clickedAsin, clickedMarket, showRawDataModal, clickedImageUrl } = this.state;
    const { salesClickedId, salesClickedAsin, salesClickedMarket, showSalesDataModal, salesClickedImageUrl } = this.state;

    if (userType !== 'admin') { return <></>; }

    return (
      <div>

        <TrackerListAdmin 
          trackerList={trackerList}
          onClickRawData={this.onClickRawData}
          onClickSalesData={this.onClickSalesData}
        />
        
        { showRawDataModal && 
          <RawDataModal 
            show={showRawDataModal}
            onClose={this.onCloseRawDataModal}
            id={clickedId}
            asin={clickedAsin}
            market={clickedMarket}
            imageUrl={clickedImageUrl}
          />
        }

        { showSalesDataModal && 
          <SalesDataModal 
            show={showSalesDataModal}
            onClose={this.onCloseSalesDataModal}
            id={salesClickedId}
            asin={salesClickedAsin}
            market={salesClickedMarket}
            imageUrl={salesClickedImageUrl}
          />
        }

      </div>
    );
  }

}


const styles = (theme) => ({
  root: {
    width: '100%',
    //padding: theme.spacing(2),
    //border: '2px solid #efefef',
  },

  formControl: {
    width: '100%',
  },

});


InventoryTrackerAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(InventoryTrackerAdmin);
// Modal dialogue that shows sales history
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


// Import helper functions, constant etc.
import { getSalesData } from '../../helpers/firebaseHelper';
import { marketPlaces } from '../../helpers/constants';
//import { getMediumDate } from '../helpers/utility';

class SalesDataModal extends React.Component {

  state = {
    salesData: [],
    isProcessing: false,
    dataFetched: false,
  }

  componentDidMount = () => {
    //console.log('SalesDataModal - componentDidMount()');

    // Set processing on when component load
    this.setState({
      isProcessing: true,
    });

    // Load data
    this.loadData();
  }

  componentWillUnmount = () => {
    //console.log('SalesDataModal - componentWillUnmount()');
  }


  // Fetch data for given market + asin
  loadData = () => {
    console.log('SalesDataModal - loadData()');

    const { asin, market } = this.props;
    getSalesData(asin, market, this.getSalesData_Success, this.getSalesData_Error);
  }

  getSalesData_Success = (data) => {
    //console.log('SalesDataModal - getSalesData_Success()');
    console.log('SalesDataModal - getSalesData_Success() data:', data);

    this.setState({
      salesData: data,
      isProcessing: false,
      isRefreshing: false,
      dataFetched: true,
    });
  }

  getSalesData_Error = (err) => {
    console.log('SalesDataModal - getSalesData_Error() err:', err);
    
    this.setState({
      isProcessing: false,
      isRefreshing: false,
    });
  }

  // Refresh the data
  handleRefresh = () => {
    
    this.setState({
      isRefreshing: true,
    });

    // Load data
    this.loadData();
  }


  // Inform parent to close the dialogue
  handleClose = () => {
    this.props.onClose();
  };

  renderSalesData = (salesData) => {
    const { classes } = this.props;
    const lastRecordIndex = salesData.length-1;

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="sales history">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sales Date</TableCell>
              <TableCell align="right">Best Seller Rank</TableCell>
              <TableCell align="right">Available Qty</TableCell>
              <TableCell align="right">Sold Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { salesData.map( (row, index) => (
                <TableRow key={row.id} >
                  <TableCell align="left">{ row.sales_date.toDateString() }</TableCell>
                  <TableCell align="right">{ row.bsr }</TableCell>
                  <TableCell align="right">{ row.qty }</TableCell>
                  <TableCell align="right">
                    { // If last record in the list (first date processed record) 
                      // and have qty_sold is 0 then show -- otherwise show qty_sold value as it.
                      index === lastRecordIndex && row.qty_sold === 0 ?
                      <>--</>
                      :
                      row.qty_sold
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  render() {
    //const { classes } = this.props;
    const { show, imageUrl } = this.props;
    const { isProcessing, isRefreshing, dataFetched, salesData } = this.state;

    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'xl'}
          open={show}
          onClose={this.handleClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}  
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title" style={{ verticalAlign: 'text-top' }} >
            <img src={imageUrl} width="50" alt="..." /> Sales Data - Market: { marketPlaces[this.props.market].country_name }  ASIN: {this.props.asin}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              { isProcessing && 
                <React.Fragment>Loading...</React.Fragment>
              }
              { isRefreshing && 
                <React.Fragment>Refreshing...</React.Fragment>
              }
              { dataFetched && salesData.length === 0 && !isRefreshing &&
                <React.Fragment>
                  Sales data not available, please try again...
                </React.Fragment>
              }
            </DialogContentText>
            { salesData.length > 0 &&
              this.renderSalesData(salesData) 
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRefresh} color="primary" >
              Refresh
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}


const styles = (theme) => ({

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },

});


SalesDataModal.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  asin: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
};

export default withStyles(styles)(SalesDataModal);
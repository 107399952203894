import React from "react";
import { withStyles } from '@material-ui/core/styles';

// Import custom component
import TopNavBar from './common/TopNavBar';
import PotentialProduct from './potentialproduct/PotentialProduct';


class PotentialProductPage extends React.Component { 

  componentDidMount = () => {
    //console.log('PotentialProductPage - componentDidMount()');
  }

  componentWillUnmount = () => {
    //console.log('PotentialProductPage - componentWillUnmount()');
  }

  render() {
    return(
      <React.Fragment>
        <TopNavBar activeTab='potential-product' />
        <PotentialProduct />
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({

});


//export default App;
export default withStyles(styles)(PotentialProductPage);


// This file consist functions to call mws api via cloud run server

import firebase from './firebaseApp';
import { config } from './constants';

// Get api base url from config constant
const API_BASE_URL = config.API_BASE_URL;

// Debug
// console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
// console.log('API_BASE_URL:', API_BASE_URL);


//-------------------------------------------------------------------
// listMatchingProducts()
//-------------------------------------------------------------------
// This function will call server side api to list matching product 
// for a given query and market. Server will use amazon mws api to
// fetch matching product list.
export const listMatchingProducts = async (query, market, successCallback, errorCallback) => { 
  console.log('listMatchingProducts()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    query: query,     // e.g. 'Pen drive'
    market: market,   // e.g. CA
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/listMatchingProducts';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('listMatchingProducts - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('listMatchingProducts - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getMatchingProductForId()
//-------------------------------------------------------------------
// This function will call server side api to get matching product 
// for a given asin and market. Server will use amazon mws api to
// get matching product list.
export const getMatchingProductForId = async (market, idType, idValues, successCallback, errorCallback) => { 
  //console.log('getMatchingProductForId()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    idType: idType,     // e.g. ASIN | GCID | SellerSKU | UPC | EAN | ISBN | JAN
    idValues: idValues, // e.e. 'B015CH1PJU,B015CH1JIW'  (comma separated)
    market: market,     // e.g. 'CA' or 'US' or 'MX' etc.
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/getMatchingProductForId';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getMatchingProductForId - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getMatchingProductForId - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getCompetitivePricingForASIN()
//-------------------------------------------------------------------
// This function will call server side api to get competitive price
// for given asins and market.
export const getCompetitivePricingForASIN = async (market, asins, successCallback, errorCallback) => { 
  //console.log('getCompetitivePricingForASIN()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    asins: asins,   // e.g. 'B015CH1PJU,B015CH1JIW'
    market: market, // e.g. CA
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/getCompetitivePricingForASIN';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getCompetitivePricingForASIN - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getCompetitivePricingForASIN - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getLowestOfferListingsForASIN()
//-------------------------------------------------------------------
// This function will call server side api to get lowest offer price
// for given asins, market and condition.
export const getLowestOfferListingsForASIN = async (market, asins, itemCondition, successCallback, errorCallback) => { 
  //console.log('getLowestOfferListingsForASIN()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    market: market, // e.g. CA
    itemCondition: itemCondition, // New | Used | Collectible | Refurbished | Club
    asins: asins,   // e.g. 'B015CH1PJU,B015CH1JIW'
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/getLowestOfferListingsForASIN';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getLowestOfferListingsForASIN - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getLowestOfferListingsForASIN - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getLowestPricedOffersForASIN()
//-------------------------------------------------------------------
// This function will call server side api to get lowest priced offer for asin.
export const getLowestPricedOffersForASIN = async (market, asin, itemCondition, successCallback, errorCallback) => { 
  //console.log('getLowestPricedOffersForASIN()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    market: market, // e.g. CA
    itemCondition: itemCondition, // New | Used | Collectible | Refurbished | Club
    asin: asin,   // e.g. 'B015CH1PJU' (single asin)
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/getLowestPricedOffersForASIN';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    console.log('getLowestPricedOffersForASIN - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getLowestPricedOffersForASIN - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getMyFeesEstimate()
//-------------------------------------------------------------------
// Returns the estimated fees for a list of products.
// Help: 
// https://docs.developer.amazonservices.com/en_UK/products/Products_GetMyFeesEstimate.html
// https://fullstacktechnology.com/nodejs/amazon-mws/#getmyfeesestimate
export const getMyFeesEstimate = async (data, successCallback, errorCallback) => { 
  console.log('getMyFeesEstimate()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  // e.g. data = { 
  //   market: 'CA, 
  //   idType: 'ASIN',
  //   asin: 'B015CH1PJU', 
  //   isAmazonFulfilled: true, 
  //   listPriceAmount: 30, 
  //   listPriceCurrency: 'CAD', 
  //   shippingAmount: 3.99, 
  //   shippingCurrency: 'CAD', 
  //   identifier: 'Hello', 
  //   pointsNumber: 0, 
  // };
  const postBody = {
    ...data,
  }
  //console.log('postBody:', postBody);

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/getMyFeesEstimate';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getMyFeesEstimate - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getMyFeesEstimate - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getMyPriceForASIN()
//-------------------------------------------------------------------
// This function will call server side api that returns pricing information 
// for your (Seller) own active offer listings, based on ASIN.
export const getMyPriceForASIN = async (market, asins, itemCondition, successCallback, errorCallback) => { 
  //console.log('getMyPriceForASIN()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    market: market, // e.g. CA
    itemCondition: itemCondition, // New | Used | Collectible | Refurbished | Club
    asins: asins,   // e.g. 'B015CH1PJU,B015CH1JIW'
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/getMyPriceForASIN';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getMyPriceForASIN - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getMyPriceForASIN - error:', error);
    errorCallback(error);
  });

}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// getProductCategoriesForASIN()
//-------------------------------------------------------------------
// This function will call server side api to get the parent product categories 
// that a product belongs to, based on ASIN.
export const getProductCategoriesForASIN = async (market, asin, successCallback, errorCallback) => { 
  //console.log('getProductCategoriesForASIN()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    market: market, // e.g. CA
    asin: asin,   // e.g. 'B015CH1PJU' (single asin)
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/mws/products/getProductCategoriesForASIN';
  //console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    //console.log('getProductCategoriesForASIN - jsonBody:', jsonBody);
    successCallback(jsonBody);
  })
  .catch( error => {
    console.log('getProductCategoriesForASIN - error:', error);
    errorCallback(error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// createAsinTrackingInfo()
//-------------------------------------------------------------------
// This function will create asin info within tracking_data collection
// if asin not exist in it. When create record It also fetch category list 
// for asin and save root category name, root category id, and category tree
// within tracking_data for that asin.
export const createAsinTrackingInfo = async (market, asin) => { 
  console.log('createAsinTrackingInfo()');

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    market: market, // e.g. CA
    asin: asin,   // e.g. 'B015CH1PJU' (single asin)
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/createAsinTrackingInfo';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    console.log('createAsinTrackingInfo Success jsonBody:', jsonBody);
  })
  .catch( error => {
    console.log('createAsinTrackingInfo - error:', error);
  });
}
//-------------------------------------------------------------------


//-------------------------------------------------------------------
// processTrackingDataOnce()
//-------------------------------------------------------------------
// This function will trigger server side function, that will create
// cloud task and push task to queue. So it will immediately fetch
// tracking data for given asin + market + requestId and put data within 
// db under tracking_data collection.
export const processTrackingDataOnce = async (market, asin, requestId) => { 
  console.log('processTrackingDataOnce() market:', market, ' asin:', asin);

  // 1 - Fetch id token for current logged in user
  const idToken = await firebase.auth().currentUser.getIdToken();
  //console.log('idToken:', idToken);

  // 2 - Prepare post data
  const postBody = {
    market: market, // e.g. CA
    asin: asin,   // e.g. 'B015CH1PJU' (single asin)
    request_id: requestId,
  }

  // 3 - Create api options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user-id-token': idToken,
    },
    body: JSON.stringify(postBody)
  }

  // 4 - Create api endpoint url
  const apiUrl = API_BASE_URL + '/processTrackingDataOnce';
  console.log('apiUrl:', apiUrl);

  // 5 - Call api
  fetch(apiUrl, options)
  .then( response => response.json())
  .then( jsonBody => {
    console.log('processTrackingDataOnce Success jsonBody:', jsonBody);
  })
  .catch( error => {
    console.log('processTrackingDataOnce - error:', error);
  });
}
//-------------------------------------------------------------------
